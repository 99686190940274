import React from "react";
import { connect } from 'react-redux';
import { removeVerifyConsult } from '../../../redux/actions/videoconference.action';
import ReasonCheck from "../../../Components/Doctor/ReasonCheck";

const Reason = ({ token, history, removeVerifyConsult, verifyVideo }) => {
  return (
    <ReasonCheck
      token={token}
      history={history}
      removeVerifyConsult={removeVerifyConsult}
      verifyVideo={verifyVideo}
    />
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  verifyVideo: state.videoconference.verifyVideo,
})

export default connect(mapStateToProps, { removeVerifyConsult })(Reason);
