import React, {useState} from 'react';
import {searchServices} from '../../../api/services';

const AddServicePresale = ({addService, services, token}) => {
  const [datasServices, setDataServices] = useState([]);
  const [loading, setLoading] = useState(false);
  return (
    <div className="collapse" id="collapseServices">
      <h4 className="text-secondarymdv mb-4 mt-4">
        Agregar Servicios
        <button
          type="button"
          data-toggle="collapse"
          data-target="#collapseServices"
          aria-expanded="false"
          aria-controls="collapseServices"
          style={{
            borderRadius: '0.8rem',
          }}
          className="btn btn-secondary btn-sm float-right"
        >
          Cerrar
        </button>
      </h4>
      <div className="sales report">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const name = e.target.search.value;
            setLoading(true);
            searchServices({token, name}).then((res) => {
              const {data} = res;
              setDataServices(data);
              setLoading(false);
            }).catch(() => {
              setLoading(false);
            });
          }}
          className="input-group"
        >
          <input
            type="text"
            className="form-control"
            id="Search"
            name="search"
            placeholder="&nbsp;Buscar Servicio"
            aria-label="&nbsp;Buscar Servicio"
            aria-describedby="button-addon2"
          />
          <div className="input-group-append">
            <button
              className="btn btn-light border border-right-rounded"
              type="submit"
              id="button-addon2"
            >
              {' '}
              <i className="fas fa-search text-secondarymdv" />
            </button>
          </div>
        </form>
        {
          loading ? (
            <div className="d-flex justify-content-center mt-2">
              <div className="spinner-border" role="status" />
            </div>
          ) : (
            <ul className="list-group list-group-flush mt-3">
              {
                datasServices.length === 0 ? (
                  <p className="text-center text-black-50">
                    No se encontraron servicios.
                  </p>
                ) : datasServices.map((item) => (
                  <li key={item._id} className="list-group-item p-1">
                    {item.name}
                    <div className="float-right">
                      <div className="d-flex">
                        <small className="badge bg-primarymdv m-1">Servicio</small>
                        {
                          !services.find((data) => data._id === item._id) && (
                            <button
                              type="button"
                              className="btn btn-light btn-sm text-secondarymdv font-weight-bold ml-2"
                              onClick={() => {
                                addService({
                                  code: item.code,
                                  _id: item._id,
                                  name: item.name,
                                });
                              }}
                            >
                              Agregar
                            </button>
                          )
                        }
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    </div>
  );
};

export default AddServicePresale;
