import React, { useState } from 'react';
import { changePresalePlan } from '../../../api/presale';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import { getPlanReferenceById } from '../../../redux/actions/references';

const PresalePlanCard = () => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.presential);

  const handleSubmit = () => {
    setIsLoading(true);
    changePresalePlan({
      code,
      userId: user?._id,
    })
      .then(() => {
        dispatch(
          getPlanReferenceById({
            patientId: user?._id,
          })
        );
        setIsLoading(false);
        Swal.fire({
          title: 'Has canjeado un plan',
          text: 'El plan vinculado a la preventa ha sido canjeado',
          icon: 'success',
        }).then(() => {
          setCode('');
        });
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error al canjear el plan',
          text: err?.message ?? 'Error al canejar el plan',
          icon: 'error',
        }).then(() => {
          setCode('');
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='sales'>
      <h4 className='text-center text-primary mt-3 mb-3'>
        Canjear plan mediante preventa
      </h4>
      <div className='form-group'>
        <input
          type='text'
          className='form-control text-center'
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder='Ingrese los ultimos digitos validos de la preventa'
        />
      </div>
      <div className='form-group text-center'>
        {code !== '' ? (
          <button className='btn btn-primary' onClick={handleSubmit}>
            Canjear plan
            {isLoading && (
              <Spinner
                animation='border'
                size='sm'
                style={{ marginLeft: '10px' }}
              />
            )}
          </button>
        ) : (
          <button className='btn btn-primary' disabled>
            Canjear plan
          </button>
        )}
      </div>
    </div>
  );
};

export default PresalePlanCard;
