import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { 
  getGraphicType,
  getGraphicDuration, 
  getGraphicCountry } from '../../../redux/actions/graphic.action'
import CallDetails from "../../../Components/Doctor/CallDetails";

const Calls = ({
  getGraphicType,
  getGraphicDuration,
  getGraphicCountry
}) => {
  useEffect(() =>{
    getGraphicType();
    getGraphicDuration();
    getGraphicCountry();
  }, [])
  return <CallDetails />;
};

export default connect(null, {
  getGraphicType,
  getGraphicDuration,
  getGraphicCountry
})(Calls);
