/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
  Spinner,
  Form,
  InputGroup,
  FormControl,
  Tabs,
  Tab,
} from 'react-bootstrap';
import createAlert from '../../redux/actions/alert.action';
import {
  registerPatient,
  checkcellphone,
} from '../../redux/actions/auth.action';
import ModalTermConditions from '../Modal/ModalTermConditions';
import { countries } from '../../utils/countries';
import ModalVerifyPhone from '../Modal/ModalCellphoneVerify';
import { useLoaderButton } from './state/useLoaderButton';
import { usePasswordShown } from './state/usePasswordShown';
import { useFormData } from './state/useFormData';
import './pacientFormStyles.css';
import CompanyUserRegisterForm from './CompanyUserRegisterForm/CompanyUserRegisterForm';
import { passRegex } from '../../utils/regex';

const PacientForm = ({ registerPatient }) => {
  const dispatch = useDispatch();
  const { loaderButton, setLoaderButton } = useLoaderButton();
  const { passwordShown, togglePassword } = usePasswordShown();
  const {
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    role,
    gender,
    dni,
    idType,
    bornAt,
    registerType,
    cellphone,
    country,
    dialCode,
    formData,
    handleChange,
  } = useFormData();

  const [terms, setTerms] = useState(false);

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };
  const setFormatPhone = () => {
    return '########';
  };

  // agregar usuario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderButton(true);

    // revisar si el formulario no esta vacio
    if (registerType === 'cellphone') {
      if (
        firstName === '' ||
        lastName === '' ||
        password === '' ||
        passwordConfirm == '' ||
        cellphone === '' ||
        dialCode === '' ||
        country === '' ||
        gender === '' ||
        dni === '' ||
        idType === '' ||
        bornAt === undefined
      ) {
        await createAlert('¡Error!', 'Rellene todos los campos.', 'error');
        setLoaderButton(false);
        return;
      }
    } else if (
      firstName === '' ||
      lastName === '' ||
      password === '' ||
      passwordConfirm === '' ||
      email === '' ||
      gender === '' ||
      dni === '' ||
      idType === '' ||
      bornAt === undefined
    ) {
      await createAlert('¡Error!', 'Rellene todos los campos.', 'error');
      setLoaderButton(false);
      return;
    }

    // validar si la  cumple requisitos

    const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    if (
      (idType === 'national' && tempDni.length !== 13) ||
      (idType === 'rtn' && tempDni.length !== 14) ||
      (idType === 'foreign' && dni.length < 8)
    ) {
      await createAlert('¡Error!', 'Número de identidad incorrecto.', 'error');
      setLoaderButton(false);
      return;
    }

    // verificar que el paciente tenga ingresado los datos de terminos y condiciones
    if (!terms) {
      await createAlert(
        '¡Error!',
        'Debe de aceptar los términos y condiciones.',
        'error'
      );
      setLoaderButton(false);
      return;
    }

    // validar si la scontraseñas sonaceptadas
    if (password !== passwordConfirm) {
      await createAlert('¡Error!', 'Las contraseñas no coinciden ', 'error');
      setLoaderButton(false);
      return;
    }

    // validar si la contrasea cumple con la regex
    if (!password.match(passRegex)) {
      await createAlert(
        '¡Error!',
        'Las no cumple con los requisitos establecidos',
        'error'
      );
      setLoaderButton(false);
      return;
    }

    // validar que el telefono no este en uso
    if (registerType === 'cellphone') {
      dispatch(checkcellphone(formData));
      setLoaderButton(false);
    } else {
      console.log({
        formData,
      });

      await registerPatient(formData);
      setLoaderButton(false);
    }
  };
  const disableRegister = () => {
    var ret = false;
    if (registerType === 'cellphone') {
      if (
        firstName === '' ||
        lastName === '' ||
        password === '' ||
        passwordConfirm == '' ||
        cellphone === '' ||
        dialCode === '' ||
        country === '' ||
        gender === '' ||
        dni === '' ||
        idType === '' ||
        bornAt === undefined ||
        loaderButton
      ) {
        ret = true;
      }
    } else if (
      firstName === '' ||
      lastName === '' ||
      password === '' ||
      passwordConfirm === '' ||
      email === '' ||
      gender === '' ||
      dni === '' ||
      idType === '' ||
      bornAt === undefined ||
      loaderButton
    ) {
      ret = true;
    }
    return ret;
  };

  return (
    <div className="container-fluid pt-5 pb-5">
      <ModalTermConditions />
      <ModalVerifyPhone formData={formData} />
      <div className="grid-layout-dashboard">
        {/* seccion de texto */}
        <div className="box-text animate__animated animate__fadeInLeft">
          <div className="row">
            <div className="col-12">
              <h2 className="text-primary font-weight-bold display-4 pt-5">
                ¿Cómo Empezar?
              </h2>
            </div>
            <div className="col-12">
              <h4 className="text-primary mb-5 font-weight-light text-responsive">
                Regístrate llenando información y comienza a recibir orientación
                médica en segundos, con médicos generales, médicos
                especialistas, acreditados y certificados.
              </h4>
            </div>
          </div>
        </div>
        {/* fin seccion de texto */}
        {/* formulario de registro */}
        <div>
          <div
            className="card border-0 shadow-sm"
            style={{
              borderRadius: '2rem',
            }}
          >
            <div className="card-image">
              <Link to="/">
                <img
                  src={require('../../assets/img/KCL.png')}
                  alt="imagen-kcl"
                  width="450"
                  className="img-fluid pt-5 pb-5 m-auto d-block"
                />
              </Link>
            </div>
            <div className="card-body-sub">
              <Tabs defaultActiveKey="register" id="uncontrolled-tab-example">
                <Tab
                  eventKey="register"
                  title="Registro paciente"
                  className="text-primary tabContent"
                  tabClassName="text-primary tabsHeader"
                >
                  <div className="row registerContent">
                    <div className="col-sm-12">
                      <form id="Login">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Nombre
                              </label>
                              <input
                                type="email"
                                className="form-control bg-light"
                                id="inputEmail"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Apellido
                              </label>
                              <input
                                type="email"
                                className="form-control bg-light"
                                id="inputEmail"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Fecha de Nacimiento
                              </label>
                              <input
                                type="date"
                                className="form-control bg-light"
                                placeholder="Fecha de Nacimiento"
                                name="bornAt"
                                value={bornAt}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Identidad
                              </label>
                              <select
                                className="form-control bg-light"
                                id="exampleFormControlSelect1"
                                name="idType"
                                value={idType}
                                onChange={handleChange}
                                placeholde="Tipo de identificación"
                              >
                                <option value="national">ID nacional</option>
                                <option value="foreign">ID extranjero</option>
                                <option value="rtn">RTN</option>
                              </select>
                            </div>

                            <div className="form-group">
                              {idType === 'foreign' ? (
                                <input
                                  type="text"
                                  className="form-control bg-light"
                                  name="dni"
                                  value={dni}
                                  onChange={handleChange}
                                />
                              ) : (
                                <NumberFormat
                                  className="form-control bg-light"
                                  format={`${setFormat()}`}
                                  mask="_"
                                  name="dni"
                                  value={dni}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Género
                              </label>
                              <select
                                className="form-control bg-light"
                                id="exampleFormControlSelect1"
                                name="gender"
                                value={gender}
                                onChange={(e) => handleChange(e)}
                                placeholde="Género"
                              >
                                <option value="female">Femenino</option>
                                <option value="male">Masculino</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="text-primary font-weight-bold float-left">
                                Tipo de registro
                              </label>
                              <select
                                className="form-control bg-light"
                                id="exampleFormControlSelect1"
                                name="registerType"
                                value={registerType}
                                onChange={(e) => handleChange(e)}
                                placeholde="Tipo de registro"
                              >
                                <option value="email">
                                  Correo electrónico
                                </option>
                                <option value="cellphone"> Teléfono</option>
                              </select>
                            </div>

                            {registerType === 'cellphone' && (
                              <>
                                <label className="text-primary font-weight-bold float-left">
                                  Pais
                                </label>
                                <div>
                                  <Form.Control
                                    as="select"
                                    style={{ marginBottom: 15 }}
                                    onChange={(e) => handleChange(e)}
                                    name="country"
                                    value={country}
                                  >
                                    {countries.map((country, key) => (
                                      <option key={country.code}>
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </div>
                                <div className="form-group">
                                  <InputGroup>
                                    <InputGroup.Prepend
                                      value={dialCode}
                                      name="dialCode"
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <InputGroup.Text className="border-left-rounded bg-light">
                                        {dialCode}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <NumberFormat
                                      placeholder="Telefono"
                                      className="form-control bg-light"
                                      format={`${setFormatPhone()}`}
                                      mask="_"
                                      name="cellphone"
                                      value={cellphone}
                                      onChange={handleChange}
                                    />
                                  </InputGroup>
                                </div>
                              </>
                            )}

                            {registerType === 'email' && (
                              <>
                                <label className="text-primary font-weight-bold float-left">
                                  Correo Electrónico
                                </label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text border-left-rounded bg-light"
                                      id="basic-addon1"
                                    >
                                      <img
                                        src={require('../../assets/img/icons/@.png')}
                                        alt="imagen"
                                        width="18"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </div>
                                  <input
                                    type="email"
                                    className="form-control bg-light"
                                    id="inputEmail"
                                    name="email"
                                    value={email}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              </>
                            )}

                            <label className="text-primary font-weight-bold float-left mt-3">
                              Contraseña
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text border-left-rounded bg-light"
                                  id="basic-addon2"
                                >
                                  <img
                                    src={require('../../assets/img/icons/candado.png')}
                                    alt="imagen"
                                    width="18"
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                              <input
                                type={passwordShown ? 'text' : 'password'}
                                className="form-control bg-light"
                                id="inputPassword1"
                                name="password"
                                value={password}
                                onChange={handleChange}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn eye-pass"
                                  type="button"
                                  onClick={togglePassword}
                                >
                                  <img
                                    src={require('../../assets/img/icons/ojo.png')}
                                    alt="imagen"
                                    width="20"
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>

                            <span className="text-dark font12">
                              - Debe contener al menos 8 catacteres, 1 dígito, 1
                              minúscula, 1 mayúscula y un caracter especial
                            </span>
                            <label className="text-primary font-weight-bold float-left mt-4">
                              Confirmar Contraseña
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text border-left-rounded bg-light"
                                  id="basic-addon2"
                                >
                                  <img
                                    src={require('../../assets/img/icons/candado.png')}
                                    alt="imagen"
                                    width="18"
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                              <input
                                type={passwordShown ? 'text' : 'password'}
                                className="form-control bg-light"
                                id="inputPassword"
                                name="passwordConfirm"
                                value={passwordConfirm}
                                onChange={(e) => handleChange(e)}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn eye-pass"
                                  type="button"
                                  onClick={togglePassword}
                                >
                                  <img
                                    src={require('../../assets/img/icons/ojo.png')}
                                    alt="imagen"
                                    width="20"
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-check float-left">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                name="terms"
                                onChange={() => {
                                  setTerms(!terms);
                                }}
                                checked={terms}
                              />
                              <label
                                className="form-check-label text-primary pointer"
                                htmlFor="inlineCheckbox1"
                                data-toggle="modal"
                                data-target="#ModalTermConditions"
                                data-whatever="@getbootstrap"
                              >
                                He leído y aceptado los
                                <b>Términos y Condiciones</b>.
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6" style={{ marginTop: 20 }}>
                            <button
                              type="submit"
                              className="btn btn-primary font-weight-bold btn-block"
                              onClick={handleSubmit}
                              disabled={disableRegister()}
                              style={{
                                opacity: disableRegister() ? '70%' : '100%',
                              }}
                            >
                              {loaderButton ? (
                                <Spinner
                                  style={{
                                    width: 20,
                                    height: 20,
                                    color: '#ffff',
                                    fontSize: 8,
                                  }}
                                  animation="border"
                                />
                              ) : (
                                'REGISTRARSE'
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="company"
                  title="¿Perteneces alguna Institución o Empresa?"
                  tabClassName="text-primary tabsHeader"
                  className="text-primary tabContent"
                >
                  <CompanyUserRegisterForm />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {/* fin formulario de registro */}
      </div>
    </div>
  );
};

export default connect(null, { registerPatient })(PacientForm);
