import React, { useState, useEffect } from 'react';
import Peer from 'peerjs';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import createAlert from '../../redux/actions/alert.action';
import socket from '../../websokect/socket';
import {
  endCallVideconference,
  getOneVideoconference,
  cancelVideoconference,
  updateResumenConsultPaciente,
} from '../../api/videoconference';
import {
  videoconferencesSeconds,
  sendIndicationPatient,
  sendDiagnosisConsult,
  sendSchedulePatient,
  sendReasonConsult,
} from '../../websokect/videoconferenceWebsocket';
import {
  observerDiagnosisConsult,
  observerReasonConsult,
  observerIndicationConsult,
  observerScheduleConsult,
} from '../../websokect/patientWebSocket';
import textMinute from '../../extra/minutes';
import Loader from '../../Components/Spinner/Spinner';
import ShowHeaModal from '../../Components/Modal/ShowHeaModal';
import AddHistoryModal from '../../Components/Modal/AddHistoryModal';
import ShowHistoryModal from '../../Components/Modal/ShowHistoryModal';
import AddDiagnosis from '../../Components/Modal/AddDiagnosis';
import AddSchedule from '../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import AddIndications from '../../Components/Modal/AddIndications';
import AddReason from '../../Components/Modal/AddReason';
import RatingModal from '../../Components/Modal/RatingModal';
import { getListMessages } from '../../api/chat';
import {
  addVerifyConsult,
  addVideoconference,
} from '../../redux/actions/videoconference.action';
import {
  getDoctorReference,
  getReference,
} from '../../redux/actions/references';
import {
  openModalAddScheduling,
  openModalPhysicalExam,
} from '../../redux/actions/ui.action';
import ModalMedicalHistory from '../../Components/Modal/ModalMedicalHistory';
// NEW MODALS
import ModalVitalSigns from '../../Components/Modal/ModalVitalSigns';
import ModalNursingNotes from '../../Components/Modal/ModalNursingNotes';
import ModalMedicalOrders from '../../Components/Modal/ModalMedicalOrders';
import AddHeaModal from '../../Components/Modal/addHEA';
import { addConsult } from '../../utils/isConsult';
import { addSignsVital } from '../../redux/actions/sign.vital.action';
import { uploadChatFile } from '../../api/chat';
import {
  sendMessage,
  observerMessagePatient,
  observerMessageDoctor,
} from '../../websokect/chatWebsockers';
import MedicalAppointmentResumeModal from '../../Components/Patient/meets/modal-medical-appointment-resume';
import useJitsi from '../../Components/jitsi-meet';
import { createStatusVideconferenceLog } from '../../api/status-videconference-logs/index';
import { statusvideconferenceEnum } from '../../enums/status-videconference-logs.enum';

let one = 1;
let myPeer;
let connected = false;

const Videoconference = ({
  token,
  user,
  match,
  history,
  addVerifyConsult,
  openModalPhysicalExam,
  addVideoconference,
  physicalExam,
  addSignsVital,
  doctorReference,
  openAddSchedule,
  openModalAddScheduling,
  getReference,
  getDoctorReference,
  videoconferenceRef,
}) => {
  const { params } = match;
  const { roomId: ROOM_ID } = params;
  const [myVideoStream, setMyVideoStream] = useState(undefined);
  const [seconds, setSeconds] = useState(0);
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('');
  const [hea, setHEA] = useState('');
  const [nursingNote, setNursingNote] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [indications, setIndications] = useState([]);
  const [historyPatient, setHistoryPatient] = useState(undefined);
  const [heaHistory, setHeaHistory] = useState([]);
  const [doctor, setDoctor] = useState();
  const [patient, setPatient] = useState();
  const [loading, setLoading] = useState(true);
  const [limitSeconds, setLimitSeconds] = useState();
  const [scheduleVideo, setScheduleVideo] = useState();
  const [peerConnected, setPeerConnected] = useState();
  const [signsVital, setSignsVital] = useState({
    temperatura: '',
    presion: '',
    frecuencia_card: '',
    frecuencia_resp: '',
    saturacion: '',
    peso: '',
    estatura: '',
    IMC: '',
  });
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [openResumeDoctorModal, setOpenResumeDoctorModal] = useState(false);
  const [openResumePatientModal, setOpenResumePatientModal] = useState(false);
  const [patienGoneJitsi, setPatientGoneJitsi] = useState(false);
  const {
    temperatura,
    presion,
    frecuencia_resp,
    frecuencia_card,
    saturacion,
    peso,
    estatura,
    IMC,
  } = signsVital;
  const { openjitsiMeet: jitsiMeet } = useJitsi();

  const handleOpenJitsiMeet = () => {
    if (user?.role === 'patient') {
      createStatusVideconferenceLog({
        patientId: videoconferenceRef.patient,
        doctorId: videoconferenceRef.doctor,
        videoconferenceId: ROOM_ID,
        action: statusvideconferenceEnum.patientJoinToJitsiByButton,
        message:
          'El paciente inicio llamada de jitsi mediante el boton tengo problemas con el video',
      });
    } else {
      createStatusVideconferenceLog({
        patientId: videoconferenceRef.patient,
        doctorId: videoconferenceRef.doctor,
        videoconferenceId: ROOM_ID,
        action: statusvideconferenceEnum.doctorJoinJitsiByButton,
        message:
          'El doctor inicio llamada de jitsi mediante el boton tengo problemas con el video',
      });
    }

    setUnmuteButton();
    muteUnmute();
    playStop();
    jitsiMeet(ROOM_ID, user?.role);
  };

  useEffect(() => {
    let interval;
    if (user.role === 'doctor' && localStorage.getItem('patientIntoJitsi')) {
      setPatientGoneJitsi(true);
    }

    getListMessages({ token: token, idVideo: ROOM_ID })
      .then((response) => {
        const { message } = response;
        setMessages(message);
      })
      .catch((error) => {
        console.log(error);
      });
    if (user?.role === 'doctor') {
      observerMessageDoctor({ idVideo: ROOM_ID }, (data) => {
        const messagesRef = messages;
        messagesRef.push(data);
        setMessages(messagesRef);
      });
    } else if (user?.role === 'patient') {
      observerMessagePatient({ idVideo: ROOM_ID }, (data) => {
        const messagesRef = messages;
        messagesRef.push(data);
        setMessages(messagesRef);
      });
    }
    getOneVideoconference({ token: token, idVideo: ROOM_ID })
      .then((response) => {
        const {
          videoconference,
          historyPatient: dataHistory,
          heaHistory: dataHea,
          patient: dataPatient,
          doctor: dataDoctor,
          maxSecondPatient,
          dataSchedule,
        } = response;

        // agregnado videconferencia a redux
        addVideoconference(videoconference);

        const { secondsPatient, secondsDoctor } = videoconference;
        setScheduleVideo(dataSchedule);
        setHistoryPatient(dataHistory);
        setHeaHistory(dataHea);
        setPatient(dataPatient);
        setDoctor(dataDoctor);
        setLimitSeconds(maxSecondPatient);
        setLoading(false);

        if (user?.role === 'patient') {
          setSeconds(secondsPatient);
        } else {
          setSeconds(secondsDoctor);
        }
        interval = setInterval(() => {
          setSeconds((seconds2) => {
            const total = seconds2 + 1;
            if (maxSecondPatient > total) {
              videoconferencesSeconds({
                roomId: ROOM_ID,
                second: total,
                userId: user?._id,
              });
            } else {
              const body = window.document.getElementById('video-grid');
              body.innerHTML = '';
            }
            return total;
          });
        }, 1000);
      })
      .catch(() => {
        createAlert(
          '¡Error!',
          'Error al ingresar a la videconferencia.',
          'error'
        );
        window.location.href = '/dashboard';
      });
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleSendMessage = () => {
    if (user.role === 'doctor') {
      sendMessageDoctor();
    } else {
      sendMessagePatient();
    }
  };
  const sendMessageDoctor = () => {
    if (message) {
      sendMessage(
        {
          idVideo: ROOM_ID,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            const messagesRef = messages;
            messagesRef.push(data.data);
            setMessages(messagesRef);

            const container =
              window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
            setMessage('');
          }
        }
      );
    }
  };
  const sendMessagePatient = () => {
    if (message) {
      sendMessage(
        {
          idVideo: ROOM_ID,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            const messagesRef = messages;
            messagesRef.push(data.data);
            setMessages(messagesRef);
            const container =
              window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
            setMessage('');
          }
        }
      );
    }
  };
  const handleUploadFile = () => {
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      uploadChatFile({ token: token, file: formData })
        .then((response) => {
          const { file: dataFile } = response;
          sendMessage(
            {
              idVideo: ROOM_ID,
              type: 'file',
              sendUser: user._id,
              idFile: dataFile.id,
              uniqueNameFile: dataFile.filename,
              nameFile: dataFile.originalname,
            },
            (data) => {
              if (data.status === 'success') {
                const messagesRef = messages;
                messagesRef.push(data.data);
                setMessages(messagesRef);
                const container =
                  window.document.getElementById('container-messages');
                container.scrollTo(0, container.scrollHeight);
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    input.click();
  };
  // declarando la funcion para la videoconferencia
  const handlePeerConnection = () => {
    const videoGrid = document.getElementById('video-grid');
    myPeer = new Peer(undefined, {
      path: '/peerjs',
      host: 'kielsaclinica.com',
      port: 443,
      secure: true,
      config: {
        iceServers: [
          {
            urls: ['stun:stun.l.google.com:19302'],
          },
          {
            urls: [
              'turn:kielsaclinica.com:3478?transport=udp',
              'turn:kielsaclinica.com:3478?transport=tcp',
              'turns:kielsaclinica.com:5349?transport=udp',
              'turns:kielsaclinica.com:5349?transport=tcp',
            ],
            username: 'kielsaclinicaturnserver',
            credential: '4but3GR9vOm4qNQ',
          },
        ],
      },
      debug: 3,
    });
    const myVideo = document.getElementById(user._id);
    if (myVideo) {
      myVideo.muted = true;
    }
    const peers = {};
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        setMyVideoStream(stream);
        addVideoStream(myVideo, stream);
        myPeer.on('call', (call) => {
          call.answer(stream);
          setPeerConnected(true);
          connected = true;
          const video = document.getElementById(ROOM_ID);
          call.on('stream', (userVideoStream) => {
            addVideoStream(video, userVideoStream);
          });
        });

        socket.on('user-connected', (userId) => {
          connectToNewUser(userId, stream);
        });
        // input value
        const text = $('input');
        // when press enter send message
        $('html').keydown((e) => {
          if (e.which === 13 && text.val().length !== 0) {
            socket.emit('message', {
              message: text.val(),
              userId: user._id,
            });
            text.val('');
          }
        });
        socket.on('createMessage', (doc) => {
          const { message, sendUser } = doc;
          let name = '';
          if (patient.publicData._id === sendUser) {
            name = `${patient.publicData.firstName} ${patient.publicData.lastName}`;
          } else {
            const dr = doctor.publicData.gender === 'male' ? 'Dr.' : 'Dra.';
            name = `${dr} ${doctor.publicData.firstName} ${doctor.publicData.lastName}`;
          }
          $('ul').append(
            `<li class="message"><b class="text-secondarymdv">${name}</b><br/>${message}</li>`
          );
          scrollToBottom();
        });
      })
      .catch(() => {});

    socket.on('user-disconnected', (userId) => {
      if (user?.role === 'patient') {
        Swal.fire({
          position: 'top',
          icon: 'info',
          text: 'El médico ha salido de la videollamada.',
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (user?.role === 'doctor') {
        Swal.fire({
          position: 'top',
          icon: 'info',
          text: 'El paciente ha salido de la videollamada.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      if (peers[userId]) peers[userId].close();
    });

    myPeer.on('open', (id) => {
      socket.emit('join-room', ROOM_ID, id);
      getListMessages({ token: token, idVideo: ROOM_ID }).then((res) => {
        const { message: dataMessages } = res;
        dataMessages.forEach((doc) => {
          const { message, sendUser } = doc;
          let name = '';
          if (patient.publicData._id === sendUser) {
            name = `${patient.publicData.firstName} ${patient.publicData.lastName}`;
          } else {
            const dr = doctor.publicData.gender === 'male' ? 'Dr.' : 'Dra.';
            name = `${dr} ${doctor.publicData.firstName} ${doctor.publicData.lastName}`;
          }
          $('ul').append(
            `<li class="message"><b class="text-primary">${name}</b><br/>${message}</li>`
          );
          scrollToBottom();
        });
      });
    });

    myPeer.on('disconnected', (id) => {
      if (peerConnected) {
        Swal.fire({
          icon: 'info',
          title: 'Usted se ha desconectado de la consulta ¿Desea reintentar?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            window.location.reload();
          }
        });
      }
    });

    myPeer.on('connection', (id) => {
      console.log('Conexion');
    });

    myPeer.on('close', (id) => {
      console.log('Cerrando');
    });

    myPeer.on('error', (id) => {
      console.log('Error al conectarse a la llamada');
      Swal.fire({
        icon: 'info',
        title:
          'Ha ocurrido un error al intentar conectarse a la consulta, ¿Desea reintentar?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          window.location.reload();
        }
      });
    });

    function connectToNewUser(userId, stream) {
      const call = myPeer.call(userId, stream);
      const video = document.getElementById(ROOM_ID);

      call.on('stream', (userVideoStream) => {
        addVideoStream(video, userVideoStream);
        setPeerConnected(true);
        connected = true;
      });
      call.on('error', (err) => {
        console.log('Error cuando se conecto el otro usuario', err);
      });
      call.on('close', () => {
        // video.remove();
      });
      //console.log(call);
      peers[userId] = call;
    }

    function addVideoStream(video, stream) {
      video.srcObject = stream;
      video.addEventListener('loadedmetadata', () => {
        video.play();
      });
    }

    const scrollToBottom = () => {
      const d = $('.main__chat_window');
      d.scrollTop(d.prop('scrollHeight'));
    };
  };
  setTimeout(() => {
    if (one === 1) {
      if (user.role === 'patient') {
        observerReasonConsult({ idVideo: ROOM_ID }, (reason) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Motivo',
            text: reason,
            showConfirmButton: false,
            timer: 5000,
          });
        });
        observerDiagnosisConsult({ idVideo: ROOM_ID }, (diagnosis) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Diagnóstico',
            text: diagnosis,
            showConfirmButton: false,
            timer: 5000,
          });
        });
        observerScheduleConsult({ idVideo: ROOM_ID }, (schedule) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Cita Próxima',
            text: `${schedule.reason}, ${schedule.date}, ${schedule.hour}`,
            showConfirmButton: false,
            timer: 5000,
          });
        });
        observerIndicationConsult({ idVideo: ROOM_ID }, (indication) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Indicación',
            text: `${indication.medicine}, ${indication.dose}, ${indication.frequency}`,
            showConfirmButton: false,
            timer: 5000,
          });
        });
      }
      handlePeerConnection();
      const intervalPeerConnection = setInterval(() => {
        if (!connected) {
          myPeer.destroy();
          const videoGrid = document.getElementById('video-grid');
          myPeer = null;
          handlePeerConnection();
        } else {
          clearInterval(intervalPeerConnection);
        }
      }, 10000);
      one = 2;
    }
  }, 2000);

  const setPlayVideo = () => {
    const html = `
  <i class="fas fa-video-slash mr-1"></i>
    <span class="font-weight-bold">Activar Video</span>
  `;
    document.querySelector('.main__video_button').innerHTML = html;
  };
  const setStopVideo = () => {
    const html = `
    <i class="fas fa-video mr-1"></i>
    <span>Quitar Video</span>
  `;
    document.querySelector('.main__video_button').innerHTML = html;
  };

  const setMuteButton = () => {
    const html = `
    <i class="fas fa-microphone mr-1"></i>
    <span>Silenciar</span>
  `;
    document.querySelector('.main__mute_button').innerHTML = html;
  };

  const setUnmuteButton = () => {
    const html = `
    <i class="fas fa-microphone-slash mr-1"></i>
    <span class="font-weight-bold">Dejar de silenciar</span>
  `;
    document.querySelector('.main__mute_button').innerHTML = html;
  };

  const muteUnmute = () => {
    if (myVideoStream) {
      const { enabled } = myVideoStream.getAudioTracks()[0];
      if (enabled) {
        myVideoStream.getAudioTracks()[0].enabled = false;
        setUnmuteButton();
      } else {
        setMuteButton();
        myVideoStream.getAudioTracks()[0].enabled = true;
      }
    }
  };

  const playStop = () => {
    if (myVideoStream) {
      const { enabled } = myVideoStream.getVideoTracks()[0];
      if (enabled) {
        myVideoStream.getVideoTracks()[0].enabled = false;
        setPlayVideo();
      } else {
        setStopVideo();
        myVideoStream.getVideoTracks()[0].enabled = true;
      }
    }
  };

  const endCall = () => {
    if (user?.role === 'doctor') {
      if (!reason) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún motivo de la consulta.',
          'error'
        );
        return;
      } else if (!diagnosis) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún diagnóstico de la consulta.',
          'error'
        );
        return;
      } else if (!hea) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún HEA de la consulta.',
          'error'
        );
        return;
      }
      Swal.fire({
        icon: 'info',
        title: '¿Estás seguro de salir de la consulta?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((res) => {
        const { isConfirmed } = res;
        if (isConfirmed) {
          createStatusVideconferenceLog({
            patientId: videoconferenceRef.patient,
            doctorId: videoconferenceRef.doctor,
            videoconferenceId: ROOM_ID,
            action: statusvideconferenceEnum.doctorFinishVideconference,
            message: 'El doctor finalizó la llamada',
          });

          localStorage.removeItem('patientIntoJitsi');
          endCallVideconference({
            token: token,
            endCallDate: new Date(),
            idVideoconference: ROOM_ID,
            diagnosis: diagnosis,
            indications: indications,
            reason: reason,
            hea: hea,
            nursingNote,
          })
            .then((res) => {
              setOpenResumeDoctorModal(true);
              addConsult();
            })
            .catch((error) => {
              console.log(error);
              alert('Ocurrió un error al finalizar la videconferencia.');
            });
        }
      });
      addSignsVital(signsVital, ROOM_ID);
    } else {
      Swal.fire({
        icon: 'info',
        title: '¿Estás seguro de salir de la consulta?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((res) => {
        const { isConfirmed } = res;
        if (isConfirmed) {
          createStatusVideconferenceLog({
            patientId: videoconferenceRef.patient,
            doctorId: videoconferenceRef.doctor,
            videoconferenceId: ROOM_ID,
            action: statusvideconferenceEnum.patientFinishVideconference,
            message: 'El paciente finalizó la llamada',
          });
          getOneVideoconference({ token, idVideo: ROOM_ID })
            .then((res) => {
              const { videoconference } = res;
              addVerifyConsult(videoconference);
              setIndications(videoconference?.indications);
              setDiagnosis(videoconference?.diagnosis);
              setReason(videoconference?.reason);
              setHEA(videoconference?.hea);
              setOpenResumePatientModal(true);
            })
            .catch((error) => {
              alert('Ocurrió un error al finalizar la videollamada.');
            });
        }
      });
    }
  };

  const handleSubmitReason = (text) => {
    setReason(text);
    sendReasonConsult({ idVideo: ROOM_ID, reason: text });
    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: diagnosis,
      indications: indications,
      reason: text,
      hea: hea,
      nursingNote: nursingNote,
    });
    $('#Modalmotivo').modal('hide');
  };

  const handleChangeRating = (number) => {
    setRating(parseFloat(number));
  };

  const handleSubmitDiagnosis = (text) => {
    setDiagnosis(text);
    sendDiagnosisConsult({ idVideo: ROOM_ID, diagnosis: text });
    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: text,
      indications: indications,
      reason: reason,
      hea: hea,
      nursingNote: text,
    });
    $('#Modaldiagnostico').modal('hide');
  };

  const handleSubmitnursingNote = (text) => {
    setNursingNote(text);
    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: diagnosis,
      indications: indications,
      reason: reason,
      hea: hea,
      nursingNote: text,
    });
    $('#ModalNursingNotes').modal('hide');
  };

  const handleSubmitSignsVital = (e) => {
    setSignsVital({
      temperatura: e.target.temperatura.value,
      presion: e.target.presion.value,
      frecuencia_resp: e.target.frecuencia_resp.value,
      frecuencia_card: e.target.frecuencia_card.value,
      saturacion: e.target.saturacion.value,
      peso: e.target.peso.value,
      estatura: e.target.estatura.value,
      IMC: e.target.peso.value / Math.pow(e.target.estatura.value, 2),
    });

    $('#ModalVitalSigns').modal('hide');
  };

  const handleSubmitHea = (text) => {
    setHEA(text);
    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: diagnosis,
      indications: indications,
      reason: reason,
      hea: text,
      nursingNote,
    });
    $('#ModalHeaForm').modal('hide');
  };

  const handleSubmitIndications = (indication) => {
    sendIndicationPatient({ idVideo: ROOM_ID, indication: indication });
    indications.push(indication);
    setIndications(indications);

    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: diagnosis,
      indications: indications,
      reason: reason,
      hea: hea,
      nursingNote,
    });
  };

  const handleRemoveIndications = (index) => {
    indications.splice(index, 1);
    setIndications(indications);
    updateResumenConsultPaciente({
      token: token,
      idVideoconference: ROOM_ID,
      diagnosis: diagnosis,
      indications: indications,
      reason: reason,
      hea: hea,
      nursingNote,
    });
  };

  const handleSendSchedule = (schedule) => {
    sendSchedulePatient({ idVideo: ROOM_ID, schedule: schedule });
    setScheduleVideo(schedule);
  };

  const handleSubmitRating = (e) => {
    const commentPatient = e.target.commentPatient.value;
    if (rating) {
      endCallVideconference({
        token: token,
        endCallDate: new Date(),
        idVideoconference: ROOM_ID,
        commentPatient: commentPatient,
        rating: rating,
      })
        .then(() => {
          $('#ratingModal').modal('hide');
          history.push('/dashboard');
        })
        .catch((error) => {
          console.log(error);
          alert('Ocurrió un error al finalizar la videconferencia');
        });
    }
  };

  const handleUpdateData = () => {
    getOneVideoconference({ token: token, idVideo: ROOM_ID }).then((res) => {
      const { historyPatient } = res;
      setHistoryPatient(historyPatient);
    });
  };

  const handleCancelVideoconference = () => {
    Swal.fire({
      title: 'Escriba el motivo de la cancelación.',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Si',
      showLoaderOnConfirm: true,
      cancelButtonText: 'No',
      preConfirm: (reasonCancel) => {
        localStorage.removeItem('patientIntoJitsi');
        return cancelVideoconference({
          token: token,
          reasonCancel: reasonCancel,
          _id: ROOM_ID,
        })
          .then((res) => res)
          .catch((error) => {
            if (error.info) {
              Swal.showValidationMessage(error.info);
            } else {
              Swal.showValidationMessage(
                'Error al cancelar la consulta presencial.'
              );
            }
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        createStatusVideconferenceLog({
          patientId: videoconferenceRef.patient,
          doctorId: videoconferenceRef.doctor,
          videoconferenceId: videoconferenceRef._id,
          action: statusvideconferenceEnum.doctorCancelVideconference,
          message: 'El doctor cancelo la llamada',
        });
        addConsult();
        window.location.href = '/dashboard';
      }
    });
  };

  return (
    <div className='main'>
      {openAddSchedule && doctorReference !== null && <AddSchedule />}
      <div className='main__left'>
        <div className='main__videos'>
          {/* Web View */}
          <div className='d-none d-sm-block'>
            <div className='timer'>
              {' '}
              <span>
                {limitSeconds > seconds
                  ? textMinute(seconds)
                  : 'La consulta ya alcanzó el tiempo estimado.'}
              </span>
            </div>
            {!peerConnected && <p>Conectando ...</p>}
          </div>
          {/* Fin Web View */}
          <div id='video-grid'>
            {user.role === 'doctor' ? (
              <>
                <video id={user._id} playsInline autoPlay />
                <video id={ROOM_ID} playsInline autoPlay />
              </>
            ) : (
              <>
                <video id={user._id} playsInline autoPlay />
                <video id={ROOM_ID} playsInline autoPlay />
              </>
            )}
          </div>
        </div>
        {user.role === 'doctor' && patienGoneJitsi && patient && (
          <div
            style={{
              paddingTop: '10px',
              padding: '10px',
              border: '1px solid #e57373',
              borderRadius: '5px',
              backgroundColor: '#ffebee',
              color: '#d32f2f',
            }}
          >
            El paciente {patient?.fullName} esta esperando en la sala, presionar
            sobre el botón 'Tengo problemas con el video'
          </div>
        )}
        <ModalMedicalHistory
          updateData={handleUpdateData}
          historyPatient={historyPatient}
          patientId={patient?.publicData._id}
          idVideo={ROOM_ID}
        />
        <AddHeaModal hea={hea} handleHea={handleSubmitHea} />
        <div className='container-fluid mt-4 mb-4'>
          {/* Mobile View */}
          <div className='d-block d-sm-none'>
            <p className='text-center'>
              Minutos de Llamada{' '}
              {limitSeconds > seconds
                ? textMinute(seconds)
                : 'La consulta ya alcanzó el tiempo estimado.'}
            </p>
          </div>
          {/* Fin Mobile View */}
          <div className='row row-cols-2 row-cols-sm-4 row-cols-md-4 justify-content-center'>
            <div className='col mb-2'>
              <button
                type='button'
                onClick={() => muteUnmute()}
                className='btn btn-info btn-block main__mute_button'
                style={{ borderRadius: '0.8rem', height: '77px' }}
              >
                <i className='fas fa-microphone fa-2x mr-2' />
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small> Silenciar</small>
                  </span>
                </div>
                {/* Fin Web View */}
              </button>
            </div>
            <div className='col mb-2'>
              <button
                type='button'
                onClick={() => playStop()}
                className='btn btn-info btn-block main__video_button'
                style={{ borderRadius: '0.8rem', height: '77px' }}
              >
                <i className='fas fa-video fa-2x mr-2' />
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small>Quitar Video</small>
                  </span>
                </div>
                <div className='d-none d-sm-block'></div>
                {/* Fin Web View */}
              </button>
            </div>
            <div className='col mb-2'>
              <button
                type='button'
                className='btn btn-info btn-block main__video_button'
                style={{ borderRadius: '0.8rem', height: '77px' }}
                onClick={handleOpenJitsiMeet}
              >
                <i className='fas fa-up-right-from-square fa-2x mr-2'></i>
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small>Tengo problemas con el video</small>
                  </span>
                </div>
                <div className='d-none d-sm-block'></div>
                {/* Fin Web View */}
              </button>
            </div>
            {user?.role === 'doctor' && (
              <>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#Modalmotivo'
                    data-whatever='@getbootstrap'
                  >
                    {reason && (
                      <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/motivo-consulta.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#ModalHeaForm'
                    data-whatever='@getbootstrap'
                  >
                    {hea && (
                      <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/HEA.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#Modaldiagnostico'
                    data-whatever='@getbootstrap'
                  >
                    {diagnosis && (
                      <i className='fas fa-check-square check-doctor fa-2x text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/Diagnóstico.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    type='button'
                    data-target='#Modalindicaciones'
                    data-whatever='@getbootstrap'
                  >
                    {indications.length > 0 && (
                      <i className='fas fa-check-square check-doctor fa-2x text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/Indicación.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    onClick={() => {
                      openModalAddScheduling();
                      getReference(videoconferenceRef.patient);
                      getDoctorReference(videoconferenceRef.doctor);
                    }}
                  >
                    <img
                      src={require('../../assets/img/icons/agendar-cita.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    type='button'
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    data-target='#ModalMedicalPatient'
                    onClick={() => {
                      getReference(videoconferenceRef.patient);
                    }}
                  >
                    <img
                      src={require('../../assets/img/icons/Historial.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
              </>
            )}
            <div className='col mb-2'>
              <button
                type='button'
                onClick={() => endCall()}
                className='btn btn-light btn-block border bg-danger text-white'
                style={{ borderRadius: '0.8rem', height: '77px' }}
              >
                <i className='fas fa-sign-out-alt fa-2x text-white mr-2' />
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small> Finalizar </small>
                  </span>
                </div>
                {/* Fin Web View */}
              </button>
            </div>
            {user?.role === 'doctor' && (
              <div className='col mb-2'>
                <button
                  type='button'
                  onClick={() => handleCancelVideoconference()}
                  className='btn btn-light btn-block border bg-secondary text-white'
                  style={{ borderRadius: '0.8rem', height: '77px' }}
                >
                  <i className='fas fa-power-off fa-2x text-white mr-2' />
                  {/* Web View */}
                  <div className='d-none d-sm-block'>
                    <span>
                      <small>Cancelar</small>
                    </span>
                  </div>
                  {/* Fin Web View */}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className='main__right'
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <div className='main__header'>
          {/* icono de mdv solo se muestra en web */}
          <div className='d-none d-sm-block'>
            <img
              src={require('../../assets/img/KCL.png')}
              alt='logo'
              className='img-fluid'
              style={{ width: '128px' }}
            />
          </div>
          <h5 className='text-primary font-weight-bold mt-2 mb-3'>Chat</h5>
        </div>
        <div
          className='main__chat_window'
          style={{ flex: 1, overflowY: 'auto' }}
        >
          <div id='container-messages' className='msg_history'>
            {messages.map((doc) => {
              if (doc.sendUser === user._id) {
                if (doc.type === 'text') {
                  return (
                    <div className='outgoing_msg'>
                      <div className='sent_msg'>
                        <p>{doc.message}</p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='outgoing_msg'>
                      <div className='sent_msg'>
                        <p>
                          <i className='fas fa-file-alt' />{' '}
                          <a
                            target='_blank'
                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                          >
                            {doc.nameFile}
                          </a>
                        </p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  );
                }
              } else if (doc.type === 'text') {
                return (
                  <div className='incoming_msg'>
                    <div className='incoming_msg_img'>
                      {' '}
                      <img
                        className='img-chat'
                        src={
                          patient?.publicData.image
                            ? `/api/profile/image/${patient?.publicData.image}`
                            : require('../../assets/img/avatars/avatar-doc-male.png')
                        }
                        alt='sunil'
                      />{' '}
                    </div>
                    <div className='received_msg'>
                      <div className='received_withd_msg'>
                        <p>{doc.message}</p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className='incoming_msg'>
                    <div className='incoming_msg_img'>
                      {' '}
                      <img
                        className='img-chat'
                        src={
                          patient?.publicData.image
                            ? `/api/profile/image/${patient?.publicData.image}`
                            : require('../../assets/img/avatars/avatar-doc-male.png')
                        }
                        alt='sunil'
                      />{' '}
                    </div>
                    <div className='received_msg'>
                      <div className='received_withd_msg'>
                        <p>
                          <i className='fas fa-file-alt' />{' '}
                          <a
                            target='_blank'
                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                          >
                            {doc.nameFile}
                          </a>
                        </p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className='type_msg pt-3' style={{ display: 'flex' }}>
          <div className='align-i' style={{ flex: 0.1 }}>
            <button type='button' className='btn' onClick={handleUploadFile}>
              <i className='fas fa-paperclip text-secondarymdv fa-2x' />
            </button>
          </div>
          <div style={{ flex: 0.8 }}>
            <input
              id='chat_message'
              className='form-control form-control-lg'
              type='text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Escriba un mensaje...'
            />
            <button className='btn msg_send_btn' onClick={handleSendMessage}>
              <i className='far fa-paper-plane text-white' />
            </button>
          </div>
          <div style={{ flex: 0.1, paddingTop: 30 }}>
            <button className='btn msg_send_btn' onClick={handleSendMessage}>
              <i className='far fa-paper-plane text-white' />
            </button>
          </div>
        </div>
      </div>
      <ModalVitalSigns
        hadleSignsVital={handleSubmitSignsVital}
        temperatura={temperatura}
        presion={presion}
        frecuencia_resp={frecuencia_resp}
        frecuencia_card={frecuencia_card}
        saturacion={saturacion}
        peso={peso}
        estatura={estatura}
        IMC={IMC}
      />
      <ModalNursingNotes
        nursingNote={nursingNote}
        handleNursingNote={handleSubmitnursingNote}
      />
      <ModalMedicalOrders />
      <ShowHeaModal heaHistory={heaHistory} />
      <AddHistoryModal />
      <ShowHistoryModal historyPatient={historyPatient} />
      <AddDiagnosis
        handleDiagnosis={handleSubmitDiagnosis}
        diagnosis={diagnosis}
      />
      <AddIndications
        indications={indications}
        handleSubmitIndication={handleSubmitIndications}
        handleRemoveIndications={handleRemoveIndications}
      />
      <MedicalAppointmentResumeModal
        isOpen={openResumeDoctorModal}
        handleHide={() => setOpenResumeDoctorModal(false)}
        diagnosis={diagnosis}
        hea={hea}
        indications={indications}
        reason={reason}
        role='doctor'
      />
      <MedicalAppointmentResumeModal
        isOpen={openResumePatientModal}
        handleHide={() => setOpenResumePatientModal(false)}
        diagnosis={diagnosis}
        hea={hea}
        indications={indications}
        reason={reason}
        role='patient'
      />
      <AddReason handleReason={handleSubmitReason} reason={reason} />
      <RatingModal
        handleSubmit={handleSubmitRating}
        ratingChanged={handleChangeRating}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  physicalExam: state.physicalExam.exam,
  doctorReference: state.reference.doctor,
  openAddSchedule: state.ui.schedulingModal.open,
  videoconferenceRef: state.videoconference.videoconference,
});

let oneFather = 0;

const Father = (props) => {
  const { token, user } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  if (oneFather === 0) {
    if (user?.role === 'patient') {
      navigator.getUserMedia(
        { video: true, audio: true },
        () => {
          setLoading(false);
          oneFather = 1;
        },
        () => {
          createAlert(
            '¡Error!',
            'Su computadora no posee cámara o micrófono.'
          ).then(() => {
            setError(true);
          });
        }
      );
    } else if (user?.role === 'doctor') {
      navigator.getUserMedia(
        { audio: true, video: true },
        () => {
          setTimeout(() => {
            setLoading(false);
            oneFather = 1;
          }, 4000);
        },
        () => {
          createAlert(
            '¡Error!',
            'Su computadora no posee cámara o micrófono.'
          ).then(() => {
            setError(true);
          });
        }
      );
    }
  }
  if (!token || error) {
    return <Redirect to='/' />;
  }
  if (token && !user) {
    return <Loader />;
  }
  if (loading) {
    return <Loader />;
  }
  return <Videoconference {...props} />;
};

export default connect(mapStateToProps, {
  addVerifyConsult,
  openModalPhysicalExam,
  addVideoconference,
  addSignsVital,
  openModalAddScheduling,
  getReference,
  getDoctorReference,
})(Father);
