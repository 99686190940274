import axios from 'axios';

export const url = '/api/labfiles';

export const addLabFile = ({ token, formData }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .post(`${url}/create-file`, formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject({
        status: 'error',
        info: 'Error en la sesión del usuario',
      });
    }
  });

export const addLabFileById = ({ token, formData, id }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .post(`${url}/create-file/${id}`, formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject({
        status: 'error',
        info: 'Error en la sesión del usuario',
      });
    }
  });

export const getLabFiles = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/read-file/`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (res.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticación del usuario',
      });
    }
  });

export const getLabFilesById = ({ token, id }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/read-file/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (res.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticación del usuario',
      });
    }
  });

export const getLabFileShow = ({ token, filename }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/read-file-show/${filename}`, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        })
        .then((res) => {
          const { data } = res;
          if (res.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticación del usuario',
      });
    }
  });

export const getLabFileShowOlder = ({ token, filename }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`/api/patient/history/exam/show/${filename}`, {
          headers: {
            Authorization: token,
          },
          responseType: 'blob',
        })
        .then((res) => {
          const { data } = res;
          if (res.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticación del usuario',
      });
    }
  });

export const deleteFile = async (file) => {
  try {
    if (file) {
      const res = await axios.delete(`${url}/${file.filename}`);
    }
  } catch (error) {
    console.log(error);
  }
};
export const deleteFileOlder = async (file) => {
  try {
    if (file) {
      const res = await axios.delete(
        `/api/patient/history/exam/${file.filename}`
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateFileName = async (file, name) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      userFileName: name,
    });

    if (file) {
      const res = await axios.put(`${url}/${file.filename}`, body, config);
    }
  } catch (error) {
    console.log(error);
  }
};
