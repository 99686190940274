import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { 
  getGraphicAge,
  getGraphicGender } from '../../../redux/actions/graphic.action'
import PatientDetails from "../../../Components/Doctor/PatientDetails";

const Patients = ({
  getGraphicAge,
  getGraphicGender
}) => {
  useEffect(() =>{
    getGraphicAge();
    getGraphicGender();
  }, [])
  return <PatientDetails />;
};

export default connect(null, {
  getGraphicAge,
  getGraphicGender,
})(Patients);
