import { useSelector } from "react-redux";

export const useReferences = () => {
  const {
    userRef,
    profileRef,
    timetable,
    consultories,
    addUserFlag,
    planRef,
    dependentsRef,
  } = useSelector((state) => state.reference);

  return {
    userRef,
    profileRef,
    timetable,
    consultories,
    addUserFlag,
    planRef,
    dependentsRef,
  };
};
