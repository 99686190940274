const physicalExam = [
  {
    id: 1,
    label: 'Neurológico',
    name: 'neurological',
    description: null,
  },
  {
    id: 2,
    label: 'Corazón',
    name: 'heart',
    description: null,
  },
  {
    id: 3,
    label: 'Cabeza',
    name: 'head',
    description: null,
  },
  {
    id: 4,
    label: 'Ojos',
    name: 'eyes',
    description: null,
  },
  {
    id: 5,
    label: 'Cuello',
    name: 'neck',
    description: null,
  },
  {
    id: 6,
    label: 'ORL',
    name: 'orl',
    description: null,
  },
  {
    id: 7,
    label: 'Pulmones',
    name: 'lugs',
    description: null,
  },
  {
    id: 8,
    label: 'Abdomen',
    name: 'abdomen',
    description: null,
  },
  {
    id: 9,
    label: 'Genitales',
    name: 'genitals',
    description: null,
  },
  {
    id: 10,
    label: 'Extremidades',
    name: 'extremities',
    description: null,
  },
  {
    id: 11,
    label: 'Piel y Faneras',
    name: 'skin',
    description: null,
  },
  {
    id: 12,
    label: 'Otros órganos/aparatos/sistemas',
    name: 'others',
    normal: true,
  },
];

export default physicalExam;
