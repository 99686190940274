import axios from 'axios';

export const url = '/api/post/consult';

export const endPostConsult = ({token, nurseNote, _id}) => new Promise((resolve, reject) => {
  if (token && nurseNote && _id) {
    const identificador=_id;
    axios
      .put(
        `${url}/end/${_id}`,
        {
          nurseNote,
          identificador
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((res) => {
        const {data} = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => reject(err));
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  } else if (!nurseNote) {
    reject({
      status: 'error',
      info: 'Nota de enfermeria no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  }
});

export const getOnePostConsult = ({token, _id}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios
      .get(`${url}/one/${_id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const {data} = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => reject(err));
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  }
});
