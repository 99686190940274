/* eslint-disable global-require */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import toastr from 'toastr';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, Nav, Dropdown, Badge, Spinner } from 'react-bootstrap';
import logo from '../assets/img/KCL.png';
import { logout } from '../redux/actions/auth.action';
import { deleteShoppingItem } from '../redux/actions/shopping.cart.action';
import {
  searchPatients,
  searchPatientID,
} from '../redux/actions/searchPatient.action';
import { disconnectDoctor } from '../websokect/doctorWebsockets';
import { logoutUser } from '../api/user';
import DNotifications from './Notifications/DropDown';
import '../stylesheets/nav.css';
import './Patient/History/history.css';
import '../stylesheets/dashboard.css';
import '../stylesheets/consultations.css';
import SearchElement from './SearchElement';
import SearchModal from './commons/SearchModal.js/SearchModal';
import { useUI } from '../hooks/useUI';
import CarnetModal from './commons/Carnet/CarnetModal';

const DashboardNav = ({
  logout,
  user: { email, firstName, lastName, _id, role },
  shoppingCart,
  deleteShoppingItem,
  searchPatients,
  searchPatientID,
  patients,
  token,
  total,
  profile: { consultationGeneral, consultationSpecialty },
  notifications,
  planRef,
}) => {
  const { handleOpenSearchModal, modalSearchPatient, modalCarnet } = useUI();
  const [loaderButton, setLoaderButton] = useState(false);

  const handlePay = () => {
    window.location.href = '/dashboard/consulta/invoice';
  };

  return (
    <Navbar
      bg='light'
      expand='lg'
      className='nav-container shadow-sm fixed-top-navbar'
    >
      {modalSearchPatient && <SearchModal />}
      {modalCarnet && <CarnetModal />}
      {/* Web View */}
      <div className='d-none d-sm-block'>
        <NavLink to='/dashboard'>
          <img
            src={logo}
            alt='logo clínicas y lab kielsa'
            className='img-fluid center-logo'
            loading='lazy'
          />
        </NavLink>
      </div>
      {role === 'company' && (
        <span className='badge-type-profile d-none d-sm-none d-md-block d-lg-block'>
          <img
            src={require('../assets/img/vectores/check-profile.svg')}
            className='img-fluid mr-1'
            alt='check profile plan'
            loading='lazy'
          />
          Perfil empresarial
        </span>
      )}
      {/* Fin Web View */}
      <Nav className='ml-auto nav-items-container'>
        <ul className='list-inline header-top pull-right'>
          {role !== 'patient' &&
            role !== 'callcenter' &&
            role !== 'company' &&
            role !== 'checker' && (
              <>
                <li onClick={handleOpenSearchModal}>
                  <img
                    src={require('../assets/img/carnet/carnet-paciente.svg')}
                    className='img-fluid mr-3'
                    alt='icono de carnet de paciente kielsa clinicas lab'
                    loading='lazy'
                    width={38}
                    style={{ cursor: 'pointer' }}
                  />
                </li>
              </>
            )}
          {role === 'patient' &&
            planRef &&
            planRef.plan?.type === 'medical' && (
              <li>
                <NavLink
                  to='/dashboard/carnet-paciente'
                  className='d-none d-sm-none d-md-none d-lg-block'
                >
                  <img
                    src={require('../assets/img/carnet/carnet-paciente.svg')}
                    className='img-fluid mr-3'
                    alt='icono de carnet de paciente kielsa clinicas lab'
                    loading='lazy'
                    width={38}
                  />
                </NavLink>
              </li>
            )}

          {role === 'patient' &&
            planRef &&
            planRef.plan?.type === 'sessions_telemedicine' && (
              <li>
                <img
                  src={require('../assets/img/vectores/llamar.svg')}
                  className='img-fluid'
                  alt='icono de llamadas'
                  loading='lazy'
                  width={38}
                />
                <Badge className='bg-yellow rounded-pill badge-notification'>
                  {planRef.sessions}
                </Badge>
              </li>
            )}
          <li>
            <Link
              to={{ pathname: 'https://www.kielsa.com' }}
              target='_blank'
              className='mr-3 ml-3 d-none d-sm-none d-md-block'
            >
              <img
                src={require('../assets/img/icons/farmaciakielsaacceso.png')}
                className='img-fluid'
                alt='farmacias kielsa acceso'
                width={170}
                loading='lazy'
              />
            </Link>
          </li>
          {(role === 'doctor' || role === 'nurse') && (
            <li>
              <SearchElement />
            </li>
          )}
          {planRef && role === 'patient' && (
            <li
              data-toggle='modal'
              data-target='#ModalDescriptionProduct'
              data-whatever='@getbootstrap'
              role='button'
            >
              <span
                className='plan-medico text-dark badge rounded-pill shadow-sm'
                style={{ padding: '7px' }}
              >
                {`Plan ${planRef && planRef?.plan?.name}`}
              </span>
            </li>
          )}

          <li>
            <DNotifications />
          </li>
          {/* Web View */}
          <li>
            <Dropdown className='d-none d-sm-block'>
              <Dropdown.Toggle className='toggle-shopping'>
                <img
                  src={require('../assets/img/vectores/logout.svg')}
                  className='img-fluid'
                  alt='logout user'
                  width={28}
                  loading='lazy'
                />
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                <div
                  style={{
                    display: 'flex',
                    padding: 10,
                    flexDirection: 'column',
                  }}
                >
                  <span className='text-primary'>
                    {`${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
                  </span>
                  <p className='text-secondarymdv small b-w'>{email}</p>
                  <div className='divider' />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      type='button'
                      className='btn btn-primary btn-sm btn-block'
                      onClick={() => {
                        setLoaderButton(true);
                        logoutUser({ token })
                          .then(() => {
                            disconnectDoctor({
                              doctorId: _id,
                              role: role,
                            });
                            logout();
                          })
                          .catch(() => {
                            toastr.error('No se pudo cerrar la sesión.');
                          });
                      }}
                    >
                      {loaderButton ? (
                        <Spinner
                          style={{
                            width: 20,
                            height: 20,
                            color: '#ffff',
                            fontSize: 8,
                          }}
                          animation='border'
                        />
                      ) : (
                        'Salir'
                      )}
                    </button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/* Fin Mobile View */}
        </ul>
      </Nav>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  profile: state.profile.profile,
  shoppingCart: state.shopping.items,
  total: state.shopping.total,
  notifications: state.notifications.items,
  patients: state.profile.searchPatien,
  planRef: state.reference.planRef,
});
export default connect(mapStateToProps, {
  logout,
  deleteShoppingItem,
  searchPatients,
  searchPatientID,
})(DashboardNav);
