/* eslint-disable no-shadow */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {doctorAsignPatientPackageKTM} from '../../api/cash.packagektm';
import {validUserPatientId} from '../../api/user';

const CardDoctorCashPackageKtm = ({
  token, userRef,
}) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [validPatient, setValidPatient] = useState(false);
  useEffect(() => {
    validUserPatientId({patientId: userRef?._id}).then(() => {
      setValidPatient(true);
      setLoading(false);
    }).catch(() => {
      setValidPatient(false);
      setLoading(false);
    });
  }, []);
  const handleSubmit = () => {
    doctorAsignPatientPackageKTM({code, token, idPatient: userRef?._id}).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se canjeo el paquete de manera exitosa.',
        showConfirmButton: false,
        timer: 1500,
      });
      validUserPatientId({patientId: userRef?._id}).then(() => {
        setValidPatient(true);
        setLoading(false);
        window.location.reload();
      }).catch(() => {
        setValidPatient(false);
        window.location.reload();
        setLoading(false);
      });
    }).catch((error) => {
      if (error.info) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: error.info,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Lo sentimos ocurrió un error al canjear el código. Por favor comunicarse con soporte.',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  if (loading) {
    return (
      <p className="text-center">Cargando ...</p>
    );
  }
  if (validPatient) {
    return <></>;
  }
  return (
    <div className="sales">
      <h4 className="text-center text-primary mt-3 mb-3">
        <i className="fas fa-coins" />
        {' '}
        Canjear Plan Médico
      </h4>
      <div className="form-group">
        <input
          type="text"
          className="form-control text-center"
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          placeholder="Ingrese el codigo aqui"
        />
      </div>
      <div className="form-group text-center">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="button"
          className="btn btn-primary"
          style={{width: '80%'}}
        >
          Canjear
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(CardDoctorCashPackageKtm);
