import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { toPng } from 'html-to-image';
import jspdf from 'jspdf';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import createAlert from '../../redux/actions/alert.action';
import {
  endCallVideconference,
  getOneVideoconference,
  skipVideoconference,
} from '../../api/videoconference';
import Loader from '../Spinner/Spinner';
import { removeVerifyConsult } from '../../redux/actions/videoconference.action';
import { addConsult } from '../../utils/isConsult';
import { getProfile } from '../../redux/actions/profile.action';

function RateDoctor({
  token,
  history,
  verifyVideo,
  removeVerifyConsult,
  getProfileApp,
}) {
  const [doctor, setDoctor] = useState();
  const [patient, setPatient] = useState();
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [ratingChanged, setRatingNumber] = useState(0);
  const [ratingPresentation, setRatingPresentation] = useState(0);
  const [ratingComunication, setRatingComunication] = useState(0);
  const [numberVideo, setNumberVideo] = useState(0);
  const [schedule, setSchedule] = useState();
  useEffect(() => {
    getOneVideoconference({
      token: token,
      idVideo: verifyVideo?._id,
    })
      .then((res) => {
        const {
          doctor: dataDoctor,
          patient: dataPatient,
          numberVideoconference,
          dataSchedule,
        } = res;
        setDoctor(dataDoctor);
        setPatient(dataPatient);
        setLoading(false);
        setNumberVideo(numberVideoconference);
        setSchedule(dataSchedule);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [verifyVideo, token]);

  // si no hay video

  if (!verifyVideo) {
    return <Redirect to='/dashboard' />;
  }
  // seccion de carga
  if (loading) {
    return <Loader />;
  }

  const handleSubmit = () => {
    if (ratingChanged > 0) {
      endCallVideconference({
        token: token,
        idVideoconference: verifyVideo._id,
        rating: ratingChanged,
        ratingComunication: ratingComunication,
        ratingPresentation: ratingPresentation,
        commentPatient: comment,
        endCallDate: new Date(),
      })
        .then(() => {
          removeVerifyConsult();
          addConsult();
          getProfileApp('patient');
          createAlert(
            '¡Correcto!',
            'Se calificó de manera exitosa.',
            'success'
          );
          history.push('/dashboard');
        })
        .catch((error) => {
          console.log(error);
          createAlert(
            '¡Error!',
            'Lo sentimos no se puedo completar la calificación del médico.',
            'error'
          );
        });
    } else {
      createAlert(
        '¡Error!',
        'Por favor califique la atención del médico.',
        'error'
      );
    }
  };

  const handleSkip = () => {
    skipVideoconference({ token: token, idVideoconference: verifyVideo._id })
      .then(() => {
        removeVerifyConsult();
        addConsult();
        getProfileApp('patient');
        createAlert(
          '¡Correcto!',
          'Se omitio la calificación del médico.',
          'success'
        );

        history.push('/dashboard');
      })
      .catch(() => {
        createAlert(
          '¡Error!',
          'Lo sentimos no se puedo omitir la calificación del médico.',
          'error'
        );
      });
  };

  const handleDownload = () => {
    toPng(document.getElementById('card-resume-consult'))
      .then((dataUrl) => {
        const doc = new jspdf();
        doc.addImage(dataUrl, 'PNG', 15, 5, 165, 250);
        doc.save('Resumen.pdf');
      })
      .catch((error) => {
        console.log(error);
        alert('Lo sentimos ocurrio un error al descargar el HEA.');
      });
  };

  return (
    <div className='container pt-5'>
      <div className='row'>
        <div className='col-md-4 col-12'>
          <div id='card-resume-consult' className='sales  mt-2'>
            <div className='row  mar-2'>
              <div className='card-r'>
                <div className='card-body bbbb'>
                  <h5 className='white center'>Resumen de Consulta</h5>
                  <h4 className='white center'>#{numberVideo}</h4>
                  <h6 className='white center'>
                    Paciente{' '}
                    <b>{`${patient?.publicData.firstName} ${patient?.publicData.lastName}`}</b>
                  </h6>
                  <center className='m-t-30'>
                    {' '}
                    <img
                      src={
                        patient?.publicData.image
                          ? `/api/profile/image/${patient?.publicData.image}`
                          : require('../../assets/img/avatars/avatar-doc-male.png')
                      }
                      className='img-circle-2 img-fluid'
                      width='80'
                    />
                    <div className='row text-center justify-content-md-center'>
                      <div className='col-6'>
                        <font className='font-medium'>
                          {moment(verifyVideo?.createdAt).format('DD/MM/YYYY')}
                        </font>
                      </div>
                      <div className='col-6'>
                        <font className='font-medium'>
                          {moment(verifyVideo?.createdAt).format('h:mm a')}
                        </font>
                      </div>
                    </div>
                  </center>
                </div>
                <div className='cont-r'>
                  {' '}
                  <h5 className='font-blue-r'>Motivo</h5>
                  <h5 className='font-blue-l'>
                    {' '}
                    {verifyVideo?.reason
                      ? verifyVideo?.reason
                      : 'El médico no lo ha ingresado.'}
                  </h5>{' '}
                  <hr />
                  <h5 className='font-blue-r'>Su Diagnóstico</h5>
                  <h5 className='font-blue-l'>
                    {' '}
                    {verifyVideo?.diagnosis
                      ? verifyVideo?.diagnosis
                      : 'El médico no lo ha ingresado.'}
                  </h5>
                  <hr />
                  <h5 className='font-blue-r'>Indicaciones Médicas</h5>
                  <h5 className='font-blue-l'>
                    {verifyVideo?.indications?.length === 0
                      ? 'El médico no lo ha ingresado'
                      : verifyVideo?.indications.map((element) => (
                          <p>{`${element.medicine}, ${element.dose}, ${element.frequency}`}</p>
                        ))}
                  </h5>
                  <hr />
                  <h5 className='font-blue-r'>Próxima Cita</h5>
                  <h5 className='font-blue-l'>
                    {' '}
                    {schedule
                      ? `${schedule.reason}, ${schedule.date}, ${schedule.hour}`
                      : 'El médico no lo ha ingresado.'}
                  </h5>{' '}
                  <button
                    type='button'
                    className='btn btn-primary3 mt-4'
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    Descargar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8 col-12'>
          <div className='sales mt-2'>
            <div className='center-div'>
              <div className='inbox_people mb-4'>
                <div className='row chatblue'>
                  <div className='div-cnt-med center-div'>
                    <h3 className='white m-2 pt-3'>Evalúa a tu médico</h3>
                    <img
                      className='media-object img-circle img-doc-3 center-div'
                      src={
                        doctor?.publicData.image
                          ? `/api/profile/image/${doctor?.publicData.image}`
                          : require('../../assets/img/avatars/avatar-doc-male.png')
                      }
                      alt='Imagen de Perfil'
                    />
                    <div className='center pb-3'>
                      <h4 className='white mar-2'>
                        {`${
                          doctor?.publicData.gender === 'male' ? 'Dr.' : 'Dra.'
                        } ${doctor?.publicData.lastName}`}
                      </h4>
                      <h5 className='text-secondarymdv badge badge-light rounded-pill p-2'>
                        {doctor?.profileData.specialty}
                      </h5>
                    </div>
                    <div className='row'>&nbsp;</div>
                  </div>
                </div>
              </div>
              <div class='row center-div center'>
                <h4 className='text-primary mb-4'>
                  Ayudanos a seguir mejorando tu opinión es muy valiosa
                </h4>
                <div className='row'>
                  <div className='col-6'>
                    <h5 className='padding1 f-right text-secondarymdv'>
                      Atención
                    </h5>
                  </div>
                  <div className='col-6'>
                    <ReactStars
                      count={5}
                      onChange={(number) => {
                        setRatingNumber(number);
                      }}
                      size={24}
                      emptyIcon={<i className='far fa-star' />}
                      halfIcon={<i className='fa fa-star-half-alt' />}
                      fullIcon={<i className='fa fa-star' />}
                      activeColor='#ffd700'
                    />
                  </div>
                  <div className='col-6'>
                    <h5 className='padding1 f-right text-secondarymdv'>
                      Presentación
                    </h5>
                  </div>
                  <div className='col-6'>
                    <ReactStars
                      count={5}
                      onChange={(number) => {
                        setRatingPresentation(number);
                      }}
                      size={24}
                      emptyIcon={<i className='far fa-star' />}
                      halfIcon={<i className='fa fa-star-half-alt' />}
                      fullIcon={<i className='fa fa-star' />}
                      activeColor='#ffd700'
                    />
                  </div>
                  <div className='col-6'>
                    <h5 className='padding1 f-right text-secondarymdv'>
                      Comunicación
                    </h5>
                  </div>
                  <div className='col-6'>
                    <ReactStars
                      count={5}
                      onChange={(number) => {
                        setRatingComunication(number);
                      }}
                      size={24}
                      emptyIcon={<i className='far fa-star' />}
                      halfIcon={<i className='fa fa-star-half-alt' />}
                      fullIcon={<i className='fa fa-star' />}
                      activeColor='#ffd700'
                    />
                  </div>
                  <div className='col-6'>
                    <h5 className='padding1 f-right text-secondarymdv'>
                      Comentario
                    </h5>
                  </div>
                  <div className='col-6'>
                    <textarea
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      className='form-control left'
                    />
                  </div>
                </div>
              </div>
              <div className='container padding1'>
                <div className='row'>
                  <div className='col-6 text-center'>
                    <button
                      onClick={handleSkip}
                      className='btn btn-primary3 mt-2'
                    >
                      Omitir
                    </button>
                  </div>
                  <div className='col-6 text-center'>
                    <button
                      onClick={handleSubmit}
                      className='btn btn-primary3 mt-2'
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  verifyVideo: state.videoconference.verifyVideo,
});

export default connect(mapStateToProps, {
  removeVerifyConsult,
  getProfileApp: getProfile,
})(RateDoctor);
