import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { login } from '../../redux/actions/auth.action';
import createAlert from '../../redux/actions/alert.action';
import { useLoaderButton } from './state/useLoaderButton';
import { usePasswordShown } from './state/usePasswordShown';
import { useFormData } from './state/useFormData';

const FormLogin = ({ login }) => {
  const { loaderButton, setLoaderButton } = useLoaderButton();
  const { passwordShown, togglePassword } = usePasswordShown();
  const { userInput, password, formData, handleChange } = useFormData();

  const handleSubmit = async (e) => {
    setLoaderButton(true);
    e.preventDefault();
    if (password !== '' && userInput !== '') {
      await login(formData);
      setLoaderButton(false);
    } else {
      createAlert(
        '¡Error!',
        'Rellene todos los campos para continuar.',
        'error'
      ).then(() => {
        setLoaderButton(false);
      });
    }
  };

  return (
    <div className="container-fluid pt-5 pb-5">
      <div className="grid-layout-dashboard">
        {/* seccion de texto */}
        <div className="box-text animate__animated animate__fadeInLeft">
          <div className="row">
            <div className="col-12">
              <h2 className="text-primary font-weight-bold display-4 pt-5">
                Empieza la Orientación Médica
              </h2>
            </div>
            <div className="col-12">
              <h4 className="text-primary mb-5 font-weight-light text-responsive">
                Inicia sesión y comienza a recibir orientación médica en
                segundos, con médicos generales, médicos especialistas,
                acreditados y certificados.
              </h4>
            </div>
          </div>
        </div>
        {/* fin seccion de texto */}
        {/* formulario de ingreso */}
        <div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-md-7">
              <div
                className="card border-0 shadow-sm"
                style={{
                  borderRadius: '2rem',
                }}
              >
                <div className="card-image">
                  <Link to="/">
                    <img
                      src={require('../../assets/img/KCL.png')}
                      alt="imagen-kcl"
                      className="img-fluid pt-5 pb-5"
                    />
                  </Link>
                </div>
                <div className="card-body-sub">
                  <div className="panel">
                    <h4 className="center mt-2 text-primary mb-4">
                      Iniciar Sesión
                    </h4>
                  </div>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <label className="text-primary font-weight-bold ml-2 float-left">
                      Correo electrónico / Número de teléfono
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text border-left-rounded bg-light"
                          id="basic-addon1"
                        >
                          <img
                            src={require('../../assets/img/icons/@.png')}
                            alt="imagen"
                            width="18"
                            className="img-fluid"
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control bg-light"
                        id="inputuserInput"
                        placeholder="Email o número de teléfono"
                        name="userInput"
                        value={userInput}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <label className="text-primary font-weight-bold ml-2 float-left">
                      Contraseña
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text border-left-rounded bg-light"
                          id="basic-addon2"
                        >
                          <img
                            src={require('../../assets/img/icons/candado.png')}
                            alt="imagen"
                            width="18"
                            className="img-fluid"
                          />
                        </span>
                      </div>
                      <input
                        type={passwordShown ? 'text' : 'password'}
                        className="form-control bg-light"
                        id="inputPassword"
                        placeholder="*****"
                        name="password"
                        value={password}
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn eye-pass"
                          type="button"
                          id="inputGroupFileAddon04"
                          onClick={togglePassword}
                        >
                          <img
                            src={require('../../assets/img/icons/ojo.png')}
                            alt="imagen"
                            width="20"
                            className="img-fluid"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="center forgot">
                      <Link to="/forgotpassword">
                        ¿Olvidaste tu Contraseña?
                      </Link>
                    </div>
                    <div className="pr-2 pl-2">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={(e) => handleSubmit(e)}
                        type="submit"
                        disabled={loaderButton}
                        style={{ opacity: '100%' }}
                      >
                        {loaderButton ? (
                          <Spinner
                            style={{
                              width: 20,
                              height: 20,
                              color: '#ffff',
                              fontSize: 8,
                            }}
                            animation="border"
                          />
                        ) : (
                          'Ingresar'
                        )}
                      </button>
                    </div>
                  </form>
                  <br />
                  <div className="center">
                    <Link
                      to="/registerPacient"
                      style={{ textDecoration: 'underline' }}
                      className="btn btn-link text-primary font-weight-bold h4"
                    >
                      Crear Cuenta
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* fin formulario de ingreso */}
      </div>
    </div>
  );
};

export default connect(null, { login })(FormLogin);
