/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deletelifestyle,
  addlifestyle,
} from '../../../redux/actions/lifestyle.action';
import {
  setTrueDefLifeStyles,
  setFalseDefLifeStyles,
} from '../../../redux/actions/patient.history.action';
import datosEstilo from '../../../utils/estilo.vida';

const LifeStyle = ({
  estilo_vida,
  deletelifestyle,
  addlifestyle,
  userRef,
  defaultLifeStyles,
  setTrueDefLifeStyles,
  setFalseDefLifeStyles,
  userLifeStyles,
}) => {
  const [lifestyle, setlifestyle] = useState(datosEstilo);

  useEffect(() => {
    userLifeStyles.forEach((actual) => {
      const item = userLifeStyles.find((def) => actual.name === def.name);
      if (item) {
        item.checked = true;
        setTrueDefLifeStyles(item);
      }
      if (item.name === 'Otros') {
        // setSelectedAllergie(item);
      }
    });
  }, []);

  const handleChange = (e) => {
    const tempSelectedItem = defaultLifeStyles.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      tempSelectedItem.checked = !tempSelectedItem.checked;
      setTrueDefLifeStyles(tempSelectedItem);
      // setSelectedAllergie(tempSelectedItem);
    }
  };

  const handleUpdate = (e) => {
    // encontrar el indice del state que decidira si se elimina o no un elemento de la base de datos
    const tempSelectedItem = defaultLifeStyles.find(
      (item) => item.name === e.target.name
    );

    if (tempSelectedItem) {
      if (tempSelectedItem.checked === true) {
        addlifestyle(e.target.name, userRef?._id);
      } else {
        userLifeStyles.forEach((element) => {
          if (element.name === e.target.name) {
            // if (e.target.name === 'Otros') {
            //   setSelectedAllergie(null);
            // }
            deletelifestyle(element._id);
          }
        });
      }
    }
  };

  return (
    <div>
      <div className="sales mt-3 pr-0 pl-0">
        <div className="media">
          <div className="media-body">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h5 className="text-primary font-weight-bold mt-2 mb-3 text-center">
                  Agregar estilo de vida
                </h5>
              </div>

              <ul className="list-group list-group-flush">
                {defaultLifeStyles.map((vida) => (
                  <li className="list-group-item" key={vida.id}>
                    {vida.name}
                    <div className="material-switch pull-right">
                      <input
                        id={vida.name}
                        name={vida.name}
                        type="checkbox"
                        checked={vida.checked}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate(e);
                        }}
                      />
                      <label htmlFor={vida.name} className="label-default" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userLifeStyles: state.history.lifeStyle.items,
  userRef: state.reference.userRef,
  defaultLifeStyles: state.history.lifeStyle.def,
});

export default connect(mapStateToProps, {
  deletelifestyle,
  addlifestyle,
  setTrueDefLifeStyles,
  setFalseDefLifeStyles,
})(LifeStyle);
