/* eslint-disable import/prefer-default-export */
import socket from './socket';

export const videoconferencesSeconds = (
  { roomId, userId, second },
  callback
) => {
  socket.emit('videoconferencesSeconds', { roomId, userId, second }, callback);
};

export const sendReasonConsult = ({ idVideo, reason }, callback) => {
  socket.emit('sendReasonPatient', { idVideo, reason }, callback);
};

export const sendDiagnosisConsult = ({ idVideo, diagnosis }, callback) => {
  socket.emit('senDiagnosisPatient', { idVideo, diagnosis }, callback);
};

export const sendIndicationPatient = ({ idVideo, indication }, callback) => {
  socket.emit('sendIndicationPatient', { idVideo, indication }, callback);
};

export const sendSchedulePatient = ({ idVideo, schedule }, callback) => {
  socket.emit('sendSchedulePatient', { idVideo, schedule }, callback);
};

export const openJitsiMeet = ({ roomId }) => {
  socket.emit('open-jitsi-meet', { roomId });
};
