import axios from 'axios';

const uri = '/api/plans/createByBalance';
export const paymentByUserBalance = (planId) =>
  new Promise((resolve, reject) => {
    axios
      .post(uri, {
        planId,
      })
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((error) => reject(error));
  });
