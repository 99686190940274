import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { changeModalDependentEmployee } from "../../../redux/actions/auth.action";
import { Table } from "react-bootstrap";
const ListDependentsEmployeeModal = ({
  show,
  onClose,
  changeModalDependentEmployee,
  dataEmployee,
}) => {
  const changeModal = () => {
    onClose(!show);
    changeModalDependentEmployee(true);
  };
  const getRelation = (relation) => {
    switch (relation) {
      case "son":
        return "Hij@"
      case "grandpa":
        return "Abuelo"
      case "grandma":
        return "Abuela"
      case "mother":
        return "Madre"
      case "father":
        return "Padre"
      case "uncle":
        return "Tí@"
      case "other":
        return "otro"
    }
  }

  return (
    <Modal size="xl" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center ">
          Usuarios dependientes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dataEmployee.length === 0 ? (
          <h2 className="text-center mt-2 mb-1">
            Este usuario no posee dependientes {dataEmployee.length}
          </h2>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Parentesco</th>
                <th>Nombres</th>
                <th>Apellidos</th>
              </tr>
            </thead>
            <tbody>
              {dataEmployee.map((dependent) => (
                <tr>
                  <td>{dependent.data.dni}</td>
                  <td>{getRelation(dependent.relation)}</td>
                  <td>{dependent.data.firstName}</td>
                  <td>{dependent.data.lastName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-info ml-3"
          type="button"
          style={{ borderRadius: "0.8rem" }}
          onClick={() => changeModal()}
        >
          Agregar nuevo usuario
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showModalDependents: state.enterprise.usingAddDependentModal,
  token: state.auth.token,
  dataEmployee: state.enterprise.listEmployeeDependent,
});

export default connect(mapStateToProps, { changeModalDependentEmployee })(
  ListDependentsEmployeeModal
);
