import React from 'react';
import createAlert from '../../redux/actions/alert.action';

const ModalNursingNotes = ({handleNursingNote, nursingNote}) => (
  <div
    className="modal fade"
    id="ModalNursingNotes"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-primary mx-auto" id="exampleModalLabel">
            NOTAS DE ENFERMERÍA
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNursingNote(e.target.nursingNote.value);
                createAlert(
                  '¡Correcto!',
                  'Se almacenó la nota de enfermería.',
                  'success',
                );
              }}
            >
              <div className="form-group">
                <textarea
                  type="text"
                  defaultValue={nursingNote}
                  name="nursingNote"
                  className="form-control"
                  id="recipient-name"
                  rows={5}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{width: '200px'}}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalNursingNotes;
