import React from "react"
import { Link } from 'react-router-dom';

const MyAppoints = () =>{
    return(
        <div className="sales report marb3">
        <div className="row mar-2">
          <h2 className="text-primary">Mis Consultas</h2>
        </div>
        <div className="bggray">
          <div className="row mar-2">
            <Link className="btn-udoc" to="/dashboard/agenda">
              <img
                src={require('../../../assets/img/icons/cita-medica.svg')}
                alt="loginlogo"
                className="icon-hm"
                data-aos="fade-right"
              />
              <span className="hidden-xs hidden-sm pad-left">
                Ver Agenda
              </span>
              <i className="fas fa-angle-right f-right icon-hm" />
            </Link>
          </div>

          <div className="row  mar-2">
            <Link className="btn-udoc" to="/dashboard/details">
              <img
                src={require('../../../assets/img/icons/interview.png')}
                alt="loginlogo"
                className="icon-hm"
                data-aos="fade-right"
              />
              <span className="hidden-xs hidden-sm pad-left">
                Detalle Consulta
              </span>
              <i className="fas fa-angle-right f-right icon-hm" />
            </Link>
          </div>
          <div className="row  mar-2">
            <Link className="btn-udoc" to="/dashboard/calls">
              <img
                src={require('../../../assets/img/icons/hablar.svg')}
                alt="loginlogo"
                className="icon-hm"
                data-aos="fade-right"
              />
              <span className="hidden-xs hidden-sm pad-left">
                Detalle Llamadas
              </span>
              <i className="fas fa-angle-right f-right icon-hm" />
            </Link>
          </div>
          <div className="row  mar-2">
            <Link className="btn-udoc" to="/dashboard/patients">
              <img
                src={require('../../../assets/img/icons/informe-medico.svg')}
                alt="loginlogo"
                className="icon-hm"
                data-aos="fade-right"
              />
              <span className="hidden-xs hidden-sm pad-left">
                Detalle Pacientes
              </span>
              <i className="fas fa-angle-right f-right icon-hm" />
            </Link>
          </div>
        </div>
      </div>
    )
}
export default MyAppoints;