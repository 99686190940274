/* eslint-disable no-console */
/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import moment from 'moment';
import {
  GET_SCHEDULE,
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  SHOW_DETAILS,
  HIDE_DETAILS,
  SET_STATUS_SCHEDULE,
  SELECTED_DATE,
  SOCKET_UPDATE_SCHEDULE,
  SELECT_ONLINE_CONSULTATION,
  SELECT_PRESENTIAL_CONSULTATION,
  REAGENDAR,
} from '../types';
import createAlert from './alert.action';
import { addNotification } from './notifications.action';
import {
  sendNewAppointmentToDoctor,
  sendReScheduleAppointmentPatient,
  changeStatusOfAppointmentToDoctor,
  socketDeleteConsultationToPatient,
} from '../../websokect/scheduleWebSocket';

export const getSchedule = (role) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/schedule/${role}/all`);
    dispatch({
      type: GET_SCHEDULE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSchedule =
  (
    {
      reason,
      date,
      hour,
      doctorId,
      consultationType,
      consultoryName,
      consultoryId,
      firstName,
      lastName,
      patientId,
      token,
      idVideo,
      bySchedule,
    },
    role
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      reason: reason,
      date: date,
      hour: hour,
      consultationType: consultationType,
      consultoryName: consultoryName,
      consultoryId: consultoryId,
      idVideo: idVideo,
      bySchedule: bySchedule,
    });
    try {
      // intentar guardar la cita

      if (role === 'patient') {
        const res = await axios.post(`/api/schedule/${doctorId}`, body, config);
        dispatch({
          type: ADD_SCHEDULE,
          payload: res.data,
        });
        createAlert(
          '¡Correcto!',
          'Su cita ha sido registrada. Pendiente de confirmación.',
          'success'
        );

        // agregar hora al formato de fecha
        const tempDate = new Date(moment(res.data.date));
        tempDate.setHours(Number(res.data.hour.substr(0, 2)));
        tempDate.setMinutes(Number(res.data.hour.substr(3, 4)));

        const doc = {
          title: 'Nueva solicitud cita médica',
          // eslint-disable-next-line max-len
          message: `Hola ${firstName.split(' ', 1)} ${lastName.split(
            ' ',
            1
          )} un paciente te ha enviado una nueva solicitud de cita médica con fecha ${moment(
            tempDate
          ).format('LLLL')}`,
          schedule_ref: res.data._id,
          send_to: res.data.doctor,
          types: 'addSchedule',
        };
        dispatch(addNotification(doc));
        const response = await axios.get(`/api/users/id/${res.data.doctor}`);
        const doctorFull = response.data;
        const doc1 = {
          title: 'Nueva solicitud cita médica',
          // eslint-disable-next-line max-len
          message: `Hola ${firstName.split(
            ' ',
            1
          )} tu cita médica para el ${moment(tempDate).format('LLLL')} con ${
            doctorFull?.data?.gender === 'male' ? 'el Dr.' : 'la Dra. '
          } ${doctorFull?.firstName} ${
            doctorFull?.lastName
          } ha sido ingresada exitosamente y está pendiente de confirmación`,
          schedule_ref: res.data._id,
          send_to: patientId,
          types: 'addSchedulePatient',
        };
        dispatch(addNotification(doc1));
        sendNewAppointmentToDoctor(res.data);
      } else {
        const res = await axios.post(
          `/api/schedule/by/${patientId}`,
          body,
          config
        );
        dispatch({
          type: ADD_SCHEDULE,
          payload: res.data,
        });
        createAlert('¡Correcto!', 'Su cita ha sido registrada.', 'success');

        // agregar hora al formato de fecha
        const tempDate = new Date(moment(res.data.date));
        tempDate.setHours(Number(res.data.hour.substr(0, 2)));
        tempDate.setMinutes(Number(res.data.hour.substr(3, 4)));

        const response = await axios.get(`/api/users/id/${res.data.doctor}`);
        const dataUserSendTo = response.data;
        const response1 = await axios.get(`/api/users/id/${res.data.doctor}`);
        const doctorFull = response1.data;
        const tempDate1 = new Date(moment(res.data.date));
        tempDate1.setHours(Number(res.data.hour.substr(0, 2)));
        tempDate1.setMinutes(Number(res.data.hour.substr(3, 4)));

        const tempMessage = `Hola ${dataUserSendTo.firstName} ${
          dataUserSendTo.lastName
        }, ${doctorFull.gender === 'male' ? 'El Dr.' : 'La Dra.'} ${
          doctorFull.firstName
        } ${doctorFull.lastName} ha agendado una cita con tigo el día ${moment(
          tempDate
        )
          .locale('es')
          .format('LLLL')}`;
        const doc = {
          title: 'Nueva solicitud cita médica',
          message: tempMessage,
          schedule_ref: res.data._id,
          send_to: patientId,
          types: 'addSchedule',
        };
        dispatch(addNotification(doc));
        sendNewAppointmentToDoctor(res.data);
      }
    } catch (error) {
      if (error.response.data) {
        createAlert('¡Error!', error.response.data.message, 'error');
      } else {
        console.log(error);
      }
    }
  };

export const deleteScheduleItem =
  (schedule, firstName, lastName) => async (dispatch) => {
    try {
      await axios.delete(`/api/schedule/${schedule._id}`);
      socketDeleteConsultationToPatient({
        userId: schedule.doctor,
        idCita: schedule._id,
        firstName: firstName,
        lastName: lastName,
      });
      const doc = {
        title: 'Cancelación de cita',
        message: `${firstName.split(' ', 1)} ${lastName.split(
          ' ',
          1
        )} ha cancelado una cita médica.`,
        schedule_ref: schedule._id,
        send_to: schedule.doctor,
        types: 'deleteSchedule',
      };
      dispatch(addNotification(doc));
      createAlert('¡Correcto!', 'se elimino la cita.', 'success');
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: DELETE_SCHEDULE,
      payload: schedule._id,
    });
  };

export const showDetails = (item) => (dispatch) => {
  dispatch({
    type: SHOW_DETAILS,
    payload: item,
  });
};

export const hideDetails = () => (dispatch) => {
  dispatch({
    type: HIDE_DETAILS,
    payload: null,
  });
};

export const setStatusSchedule =
  (id, status, role, firstName, lastName, gender, date, hour) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      status,
    });
    try {
      const res = await axios.put(`/api/schedule/status/${id}`, body, config);
      changeStatusOfAppointmentToDoctor(
        res.data.patient,
        res.data.status,
        res.data._id
      );

      if (res.data.status === 'reject') {
        if (role === 'doctor') {
          dispatch({
            type: DELETE_SCHEDULE,
            payload: res.data._id,
          });
        } else {
          dispatch({
            type: SET_STATUS_SCHEDULE,
            payload: res.data,
          });
        }
      } else {
        dispatch({
          type: SET_STATUS_SCHEDULE,
          payload: res.data,
        });
      }
      const resp = await axios.get(`/api/users/id/${res.data.patient}`);
      const patientFull = resp.data;
      // crear una notificacion
      if (res.data.status === 'accept') {
        const doc = {
          title: 'Cita médica aceptada',
          message: `Hola ${patientFull.data.firstName}, ${
            gender === 'male' ? 'El Dr. ' : 'la Dra. '
          } ${firstName.split(' ', 1)} ${lastName.split(
            ' ',
            1
          )} ha aceptado su cita el ${date} ${hour}`,
          schedule_ref: res.data._id,
          send_to: res.data.patient,
          types: 'agreeSchedule',
        };
        dispatch(addNotification(doc));
      } else if (res.data.status === 'reject') {
        const doc = {
          title: 'Cita médica rechazada',
          message: `Hola ${patientFull.data.firstName}, ${
            gender === 'male' ? 'El Dr. ' : 'la Dra. '
          } ${firstName.split(' ', 1)} ${lastName.split(
            ' ',
            1
          )} no podrá atender su cita el ${date}, ${hour};
         sugerimos reagendar con horario o médico diferente. https://kielsaclinica.com/dashboard/telellamada`,
          schedule_ref: res.data._id,
          send_to: res.data.patient,
          types: 'rejectSchedule',
        };
        dispatch(addNotification(doc));
      }
    } catch (err) {
      console.log({
        error: err,
        message: 'Error al intentar actualizar el estado de las citas.',
      });
      // if (err.response.data !== undefined) {
      //   const errors = err.response.data;
      //   createAlert('Error!', errors.message, 'error');
      // }
    }
  };

export const addSelectedDate = (date) => (dispatch) => {
  dispatch({
    type: SELECTED_DATE,
    payload: date,
  });
};

// SCHEDULE SOCKETS
export const addScheduleBySocket = (schedule) => (dispatch) => {
  dispatch({
    type: ADD_SCHEDULE,
    payload: schedule,
  });
};

export const reScheduleBySocket = (schedule) => (dispatch) => {
  dispatch({
    type: REAGENDAR,
    payload: schedule,
  });
};

// actualizar el estado de una cita mediante sockets
export const socketUpdateStateToAppointment = (doc) => (dispatch) => {
  dispatch({
    type: SOCKET_UPDATE_SCHEDULE,
    payload: doc,
  });
};

// actualizar el tipo de consulta que el usuario desea agendar
export const selectPresential = () => (dispatch) => {
  dispatch({
    type: SELECT_PRESENTIAL_CONSULTATION,
    payload: true,
  });
};
export const selectOnline = () => (dispatch) => {
  dispatch({
    type: SELECT_ONLINE_CONSULTATION,
    payload: true,
  });
};

export const resetPresential = () => (dispatch) => {
  dispatch({
    type: SELECT_PRESENTIAL_CONSULTATION,
    payload: false,
  });
};
export const resetOnline = () => (dispatch) => {
  dispatch({
    type: SELECT_ONLINE_CONSULTATION,
    payload: false,
  });
};

export const reschedule =
  ({ id, date, hour, firstName, lastName }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      date: date,
      hour: hour,
      status: 'waiting',
    });

    try {
      const res = await axios.put(
        `/api/schedule/update/date/${id}`,
        body,
        config
      );
      dispatch({
        type: REAGENDAR,
        payload: res.data,
      });

      const doc = {
        title: 'Reagendación cita médica',
        message: `${firstName.split(' ', 1)} ${lastName.split(
          ' ',
          1
        )} ha reagendado su cita medica`,
        schedule_ref: res.data._id,
        send_to: res.data.doctor,
        types: 'reSchedule',
      };
      dispatch(addNotification(doc));
      sendReScheduleAppointmentPatient(res.data);
    } catch (error) {
      if (error.response.data) {
        createAlert('¡Error!', error.response.data.message, 'error');
      } else {
        console.log(error);
      }
    }
  };

// action socket to delete of doctor schedule deleted from patient

export const socketActionToDeleteScheduleToPatient =
  ({ idCita, userId, firstName, lastName }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: DELETE_SCHEDULE,
        payload: idCita,
      });
      const doc = {
        title: 'Eliminación de cita',
        message: `${firstName.split(' ', 1)} ${lastName.split(
          ' ',
          1
        )} ha eliminado una cita.`,
        schedule_ref: idCita,
        send_to: userId,
        types: 'deleteSchedule',
      };
      dispatch(addNotification(doc));
    } catch (error) {
      console.log(error);
    }
  };
