import React from 'react';
import { useRef } from 'react';
import { Row, Col, Container, Table } from 'react-bootstrap';
import logo from '../../../assets/img/KCL.png';
import NumberFormat from 'react-number-format';

const GiftCardCodeDetails = ({ giftCards, handlePrintGiftCards }) => {
  const gifCardRef = useRef(null);

  return (
    <>
      <Container
        style={{
          backgroundColor: 'white',
          marginTop: 40,
          borderRadius: 10,
        }}
        ref={gifCardRef}
      >
        <Row style={{ padding: 50 }}>
          <Row>
            <Col xs={6}>
              <img className="w-50" src={logo} alt="logo" />
            </Col>
          </Row>
        </Row>
        <Row
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Col xs={4}>
            <div className="h5">Nombre del Cliente</div>
          </Col>
          <Col xs={4}>
            <div className="h5 font-weight-light">{giftCards[0]?.name}</div>
          </Col>
        </Row>

        <Row
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Col xs={4}>
            <div className="h5">Correo Electronico:</div>
          </Col>
          <Col xs={4}>
            <div className="h5 font-weight-light"> {giftCards[0]?.email}</div>
          </Col>
        </Row>

        <Row
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Col xs={4}>
            <div className="h5">Telefono</div>
          </Col>
          <Col xs={4}>
            <div className="h5 font-weight-light">
              {giftCards[0]?.cellphone}
            </div>
          </Col>
        </Row>

        <Row
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 5,
            paddingBottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <Col
            xs={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              textAlign: 'center',
            }}
          >
            <div className="h4">{`${
              giftCards.length > 1 ? 'Códigos:' : 'Su código es:'
            }`}</div>
          </Col>
        </Row>

        <Row
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 5,
            paddingBottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 50,
          }}
        >
          <Col
            xs={12}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
              textAlign: 'center',
            }}
          >
            <div
              className=" font-weight-light"
              style={{
                fontSize: 20,
              }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Precio</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {giftCards.map((card) => (
                    <tr key={card._id}>
                      <td>{card.code}</td>
                      <td>
                        <NumberFormat
                          value={card.price}
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'L.'}
                          renderText={(value, props) => (
                            <div {...props}>{value}</div>
                          )}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          value={card.price}
                          className="foo"
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'L. '}
                          renderText={(value, props) => (
                            <div {...props}>{value}</div>
                          )}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row
          className="row bg-dark text-white pt-4 pb-4"
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <div className="col-sm-4">
            <div className="mb-2">Total</div>
            <div className="h2 font-weight-light">{`L. ${
              giftCards.length > 1
                ? giftCards
                    .map((card) => card.price)
                    .reduce((prev, current) => prev + current, 0)
                : giftCards[0].price
            }`}</div>
          </div>

          <div className="col-sm-4">
            <div className="mb-2">Descuento</div>
            <div className="h2 font-weight-light">0%</div>
          </div>

          <div className="col-sm-4">
            <div className="mb-2">Sub Total</div>
            <div className="h2 font-weight-light">{`L. ${
              giftCards.length > 1
                ? giftCards
                    .map((card) => card.price)
                    .reduce((prev, current) => prev + current, 0)
                : giftCards[0].price
            }`}</div>
          </div>
        </Row>
      </Container>
      <Container>
        <Row
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}
        >
          <button
            className="btn btn-primary"
            onClick={() => handlePrintGiftCards(gifCardRef)}
          >
            imprimir
          </button>
        </Row>
      </Container>
    </>
  );
};

export default GiftCardCodeDetails;
