import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPlanTelemedicineCallCenter } from '../../../../api/telemedicine.plan';
import {
  addSelectedPlan,
  deleteReference,
} from '../../../../redux/actions/references';
import { setModalCallCenterPlan } from '../../../../redux/actions/ui.action';
import createAlert from '../../../../redux/actions/alert.action';

const useCallCenterPlanModalState = () => {
  const dispatch = useDispatch();
  const { modalCallCenterPlan } = useSelector((state) => state.ui);
  const [company, setCompany] = useState('tigo');
  const { selectedPlan, userRef } = useSelector((state) => state.reference);

  const handleCloseModal = () => {
    dispatch(setModalCallCenterPlan(false));
    dispatch(addSelectedPlan(null));
  };

  const handleOpenModal = () => {
    dispatch(setModalCallCenterPlan(true));
  };
  const handleChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleSubmit = () => {
    addPlanTelemedicineCallCenter(selectedPlan, company, userRef)
      .then((res) => {
        createAlert('Correcto!', res.message, 'success');
        dispatch(deleteReference());
        handleCloseModal();
      })
      .catch((err) => {
        createAlert('Error!', err.response?.data?.message, 'error');
        dispatch(deleteReference());
        handleCloseModal();
      });
  };

  return {
    open: modalCallCenterPlan,
    handleCloseModal,
    handleOpenModal,
    handleSubmit,
    company,
    handleChangeCompany,
  };
};

export default useCallCenterPlanModalState;
