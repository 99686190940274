/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '../../stylesheets/scheduling.css';

import { useHistory } from 'react-router-dom';
import {
  openModalScheduleDoctorList,
  closeModalScheduleDoctorList,
  openModalAddScheduling,
  closeModalAddScheduling,
} from '../../redux/actions/ui.action';

import {
  getDoctorReference,
  getReference,
} from '../../redux/actions/references';
import { Spinner } from 'react-bootstrap';
const CardContactDoctor = ({
  name,
  specialty,
  handleVideoCall,
  handleChat,
  status,
  handleDataDoctor,
  image,
  doctorId,
  closeModalScheduleDoctorList,
  openModalAddScheduling,
  getReference,
  getDoctorReference,
  handleCall,
  isOnline,
  user,
  loadingCall,
  calling,
}) => {
  useEffect(() => {}, [loadingCall, calling]);
  const history = useHistory();
  const validColor = () => {
    if (status === 'available') {
      return (
        <img
          className='img-fluid img-circle img-doc-p state-online'
          src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
          alt='Perfil'
        />
      );
    } else if (status === 'occupied') {
      return (
        <img
          className='img-fluid img-circle img-doc-p state-busy'
          src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
          alt='Perfil'
        />
      );
    } else {
      return (
        <div className='overlay-doc img-fluid mx-auto'>
          <img
            id='overlay-doctor'
            className='img-fluid img-circle img-doc-p state-offline'
            src={
              image || require('../../assets/img/avatars/avatar-doc-male.png')
            }
            alt='Perfil'
          />
        </div>
      );
    }
  };
  return (
    <>
      {/* Web View */}
      <div className='d-none d-sm-block'>
        <div className='mb-2 mt-2'>
          <div className='row no-gutters'>
            <div className='col-md-3 text-center'>
              {validColor(status)}
              <button
                type='button'
                className='btn btn-link font-weight-bold text-secondarymdv'
                data-toggle='modal'
                data-target='#perfildoctor'
                onClick={handleDataDoctor}
                data-whatever='@getbootstrap'
              >
                Ver Perfil
              </button>
            </div>
            <div className='col-md-9'>
              <div className='d-flex w-100 justify-content-between'>
                <h5 className='m-0 text-primary'>{name}</h5>
                <div className='d-flex'>
                  <img
                    className='img-flag mr-1'
                    src={require('../../assets/img/hn-circle.png')}
                    alt='Es'
                  />
                  <img
                    className='img-flag'
                    src={require('../../assets/img/en-circle.png')}
                    alt='En'
                  />
                </div>
              </div>
              <p className='m-0 text-black-50'>
                {specialty}
                {/* <span className="ml-2">
                      <i className="fas fa-star c-star" /> {rating || 0}
                      /5
                    </span> */}
              </p>
              <div className='row no-gutters mt-3'>
                {' '}
                {/*inicio contenedor de llamadas */}
                {loadingCall ? (
                  <div
                    className='col-12 col-sm-6 col-md-4'
                    style={{
                      padding: '5%',
                      paddingLeft: '45%',
                      paddingRight: '25%',
                    }}
                  >
                    <Spinner animation='border' variant='primary' />
                    <div
                      style={{
                        paddingRight: '45%',
                      }}
                    >
                      Procesando llamada...
                    </div>
                  </div>
                ) : (
                  <>
                    {(isOnline ||
                      history.location.pathname ===
                        '/dashboard/telellamada') && (
                      <div className='col-12 mb-3'>
                        <button
                          disabled={calling}
                          type='button'
                          onClick={() => {
                            handleVideoCall();
                          }}
                          className='btn btn-light btn-block border bg-white'
                          to='/dashboard/waitingcall'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <small className='text-secondarymdv font-weight-bold'>
                            <i className='fas fa-video mr-2' />
                            Videollamada
                          </small>
                        </button>
                      </div>
                    )}
                    {(isOnline ||
                      history.location.pathname ===
                        '/dashboard/telellamada') && (
                      <div className='col-12 col-sm-6 col-md-4'>
                        <div className='mr-2'>
                          <button
                            disabled={calling}
                            type='button'
                            onClick={() => {
                              handleCall();
                            }}
                            className='btn btn-light btn-block border mr-1'
                            to='/dashboard/waitingcall'
                            style={{ borderRadius: '0.8rem' }}
                          >
                            <small className='text-secondarymdv'>
                              <i className='fas fa-phone-alt mr-2' />
                              Llamada
                            </small>
                          </button>
                        </div>
                      </div>
                    )}
                    {(isOnline ||
                      history.location.pathname ===
                        '/dashboard/telellamada') && (
                      <div className='col-12 col-sm-6 col-md-4'>
                        <div className='mr-2'>
                          <button
                            disabled={calling}
                            type='button'
                            onClick={() => {
                              handleChat();
                            }}
                            className='btn btn-light btn-block border'
                            style={{ borderRadius: '0.8rem' }}
                          >
                            <small className='text-secondarymdv'>
                              <i className='far fa-comment mr-2' />
                              Chat
                            </small>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className='col-12 col-sm-6 col-md-4'>
                      <button
                        className='btn btn-light btn-block border'
                        type='button'
                        onClick={() => {
                          getDoctorReference(doctorId);
                          getReference(user._id);
                          closeModalScheduleDoctorList();
                          openModalAddScheduling();
                        }}
                        style={{ borderRadius: '0.8rem' }}
                      >
                        <small className='text-secondarymdv'>
                          <i className='far fa-calendar-alt mr-2' />
                          Agendar
                        </small>
                      </button>
                    </div>
                  </>
                )}
              </div>
              {/*fin de contenedor de opciones de llamadas */}
            </div>
          </div>
        </div>
        <div className='hr' />
      </div>
      {/* Fin Web View */}
      {/* Mobile View */}
      <div className='d-block d-sm-none'>
        <div className='list-group m-0'>
          <div className='list-group-item border-top-0 border-right-0 border-left-0'>
            <div className='d-flex w-100 justify-content-between'>
              <div className='d-flex'>
                <div className='text-center'>
                  {validColor(status)}
                  <button
                    type='button'
                    className='btn btn-link text-secondarymdv btn-sm mt-1'
                    data-toggle='modal'
                    data-target='#perfildoctorMovil'
                    onClick={handleDataDoctor}
                    data-whatever='@getbootstrap'
                  >
                    <small>Ver Perfil</small>
                  </button>
                </div>
                <small className='text-left ml-2 text-primary'>
                  {name}
                  <br />
                  <p className='m-0 text-black-50'>{specialty}</p>
                </small>
              </div>
              <div className='d-flex'>
                <img
                  className='img-flag mr-1'
                  src={require('../../assets/img/hn-circle.png')}
                  alt='Es'
                />
                <img
                  className='img-flag'
                  src={require('../../assets/img/en-circle.png')}
                  alt='En'
                />
              </div>
            </div>
            <div className='row no-gutters mt-2'>
              {loadingCall ? (
                <div
                  className='col-12 col-sm-6 col-md-4'
                  style={{
                    padding: '5%',
                    paddingLeft: '45%',
                    paddingRight: '25%',
                  }}
                >
                  <Spinner animation='border' variant='primary' />
                  <div
                    style={{
                      paddingRight: '45%',
                    }}
                  >
                    Procesando llamada...
                  </div>
                </div>
              ) : (
                <>
                  {(isOnline ||
                    history.location.pathname === '/dashboard/telellamada') && (
                    <div className='col-3'>
                      <div className='mr-2'>
                        <button
                          type='button'
                          onClick={() => {
                            handleVideoCall();
                          }}
                          className='btn btn-light btn-sm btn-block border bg-white'
                          to='/dashboard/waitingcall'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <small className='text-secondarymdv font-weight-bold'>
                            <i className='fas fa-video mr-2' />
                          </small>
                        </button>
                      </div>
                    </div>
                  )}
                  {(isOnline ||
                    history.location.pathname === '/dashboard/telellamada') && (
                    <div className='col-3'>
                      <div className='mr-2'>
                        <button
                          type='button'
                          onClick={() => {
                            handleCall();
                          }}
                          className='btn btn-light btn-sm btn-block border bg-white'
                          to='/dashboard/waitingcall'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <i className='fas fa-phone-alt text-secondarymdv' />
                        </button>
                      </div>
                    </div>
                  )}
                  {(isOnline ||
                    history.location.pathname === '/dashboard/telellamada') && (
                    <div className='col-3'>
                      <div className='mr-2'>
                        <button
                          type='button'
                          onClick={() => {
                            handleChat();
                          }}
                          className='btn btn-light btn-sm btn-block border'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <i className='far fa-comment text-secondarymdv' />
                        </button>
                      </div>
                    </div>
                  )}
                  <div className='col-3'>
                    <button
                      className='btn btn-light btn-sm btn-block border'
                      type='button'
                      onClick={() => {
                        getReference(doctorId);
                        closeModalScheduleDoctorList();
                        openModalAddScheduling();
                      }}
                      style={{ borderRadius: '0.8rem' }}
                    >
                      <i className='far fa-calendar-alt text-secondarymdv' />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Fin Mobile View */}
    </>
  );
};
const mapStateToProps = (state) => ({
  isPresential: state.scheduling.presentialConsultation,
  isOnline: state.scheduling.onlineConsultation,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  closeModalScheduleDoctorList,
  openModalScheduleDoctorList,
  openModalAddScheduling,
  closeModalAddScheduling,
  getReference,
  getDoctorReference,
})(CardContactDoctor);
