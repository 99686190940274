import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import useFarinterSelector from '../../../../hooks/useFarinterSelector';
import useFollowUpAppointmentState from './state/useFollowUpAppointmentState';
import moment from 'moment';

const FollowUpAppointment = () => {
  const {
    open,
    handleCloseModal,
    isFollowUp,
    handleChangeIsFolowUp,
    isLoadingAppointment,
    handleGetAppointmentData,
    handleChangeSelectedAppointment,
    handleAddToOrder,
    tempAppointment,
    handleGetType,
  } = useFollowUpAppointmentState();
  const {
    reference: { appointments },
  } = useFarinterSelector();

  return (
    <Modal show={open} onHide={handleCloseModal} centered>
      <Modal.Header closeButton className="align-items-center">
        <h3 className="mt-2 text-primary ml-4">Cita de seguimiento</h3>
      </Modal.Header>
      <Modal.Body style={{ padding: 40 }}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label className="text-secondarymdv font-weight-light">
              ¿Es una cita de seguimiento?
            </label>
            <div style={{ display: 'flex' }}>
              <div>
                <label className="text-secondarymdv font-weight-light">
                  Si
                </label>
                <input
                  type="checkbox"
                  name="followUp"
                  style={{
                    width: 'auto',
                    marginLeft: 10,
                    fontSize: 20,
                    transform: 'scale(1.3)',
                  }}
                  onChange={() => {
                    handleChangeIsFolowUp();
                    handleGetAppointmentData();
                  }}
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                <label className="text-secondarymdv font-weight-light">
                  No
                </label>
                <input
                  type="checkbox"
                  name="followUp"
                  style={{
                    width: 'auto',
                    marginLeft: 10,
                    fontSize: 20,
                    transform: 'scale(1.3)',
                  }}
                  onChange={handleCloseModal}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            {isFollowUp ? (
              isLoadingAppointment ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner
                    style={{
                      width: 30,
                      height: 30,
                      fontSize: 8,
                      marginRight: 10,
                    }}
                    animation="border"
                  />
                  Espere por favor..
                </div>
              ) : appointments.length > 0 ? (
                <select
                  className="form-control form-control-user"
                  id="exampleFormControlSelect1"
                  style={{
                    margin: 0,
                    width: '100%',
                  }}
                  onChange={handleChangeSelectedAppointment}
                >
                  <option value="" selected disabled hidden>
                    Seleccione una cita
                  </option>
                  {appointments.map((item) => (
                    <option key={item._id} value={item._id}>
                      {`${moment(item.createdAt).format('llll')} ${
                        item.followRef ? ' - seguimiento' : ''
                      } - Dr. ${item.doctor.firstName.split(' ')[0]} ${
                        item.doctor.lastName.split(' ')[0]
                      } - ${handleGetType(item.type)}`}
                    </option>
                  ))}
                </select>
              ) : (
                'Aún no posee ninguna cita previa'
              )
            ) : null}
          </div>
          <Button
            className="mt-4"
            onClick={handleAddToOrder}
            disabled={tempAppointment === null}
          >
            Vincular datos a la orden
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FollowUpAppointment;
