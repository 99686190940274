/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import 'moment/locale/es';
import { AiFillDelete } from 'react-icons/all';
import { IconContext } from 'react-icons';
import CardModal from './History/CardModal';
import ResumenModal from '../Payment/PaymentResumeModal';
import {
  setDefaultCreditCard,
  deleteCreditCard,
} from '../../redux/actions/credit.card.action';
import visaImg from '../../assets/img/visa.svg';
import mastercardImg from '../../assets/img/mastercard.svg';
import '../../stylesheets/credit.cards.css';
import '../../stylesheets/consultations.css';

function Invoice({
  shopping: { items, total },
  cards,
  setDefaultCreditCard,
  deleteCreditCard,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openResumeModal, setOpenResumenModal] = useState(false);

  const handleCheckChange = (id) => {
    setDefaultCreditCard(id);
  };

  return (
    <div>
      <CardModal open={openModal} setOpen={setOpenModal} />
      <ResumenModal open={openResumeModal} setOpen={setOpenResumenModal} />
      <div className="container-fluid">
        <div className="p-2">
          {/* Web View */}
          <div className="d-none d-sm-block">
            <h2 className="text-primary mt-5 mb-4">
              Resumen de la compra
              {' '}
            </h2>
          </div>
          {/* Fin Web View */}
          {/* Mobile View */}
          <div className="d-block d-sm-none text-center">
            <h5 className="text-primary mt-5 mb-4 font-weight-bold">
              Resumen de la compra
            </h5>
          </div>
          {/* Fin Mobile View */}
          <div className="row">
            <div className="col-12 col-md-6 col-sm-12 col-xs-12">
              <div className="sales">
                <div className="row center-div">
                  <div className="row mt-4">
                    <div className="col-6">
                      <h6 className="pad3 text-secondarymdv">FAC#45674</h6>
                    </div>
                    <div className="col-6">
                      <span className="f-right text-black-50">
                        {moment().locale('es').format('L')}
                      </span>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead className="text-uppercase text-primary">
                        <tr>
                          <th>Tipo de Consulta</th>
                          <th>Cantidad</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item._id}>
                            <td className="col-12 col-md-6">
                              {item.consult.type === 'specialty'
                                ? 'Especialidad'
                                : 'Medicina General'}
                            </td>
                            <td className="col-12 col-md-6 text-center">
                              {item.consult.quantity}
                            </td>
                            <td className="col-12 col-md-6 text-center">
                              L.
                              {' '}
                              {item.consult.price}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td />
                          <td className="text-right">
                            <p>
                              Sub Total:
                            </p>
                            <p>
                              Descuento:
                            </p>
                          </td>
                          <td>
                            <p>
                              L.
                              {' '}
                              {total}
                            </p>
                            <p>
                              L. 0
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="text-right text-secondarymdv">
                            <p className="text-secondarymdv font-weight-bold">
                              Monto a Pagar:
                            </p>
                          </td>
                          <td className="text-left">
                            <p>
                              <strong>
                                L.
                                {' '}
                                {total}
                              </strong>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="f-right">
                    <button
                      type="button"
                      className="btn btn-info mb-2"
                      data-target="#exampleModal"
                      data-whatever="@getbootstrap"
                      onClick={() => setOpenModal(true)}
                      >
                      Añadir Tarjeta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-12 col-xs-12">
              <h5 className="text-secondarymdv mt-4 mb-3 font-weight-bold">
                Seleccionar Tarjeta
              </h5>
              {/* Start credit card info */}
              {cards.length > 0 ? (
                cards.map((item) => (
                  <div
                    key={item._id}
                    className={`credit-card-container ${
                      item.selected ? 'credit-card-selected' : null
                    }`}
                    >
                    <img
                      src={item.type === 'visa' ? visaImg : mastercardImg}
                      alt="card"
                      className="credit-card-image"
                      />
                    <h6 className="card-item-name text-primary">{item.name}</h6>
                    <p className="card-item-prefix">{`termina en   *** ${item.numberPrefix}`}</p>
                    <label
                      className="checkbox-card-item"
                      id="card-item-checkbox"
                      >
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleCheckChange(item._id)}
                        />
                    </label>
                    <IconContext.Provider
                      value={{
                        size: '20px',
                        color: 'red',
                      }}
                      >
                      <AiFillDelete
                        onClick={() => deleteCreditCard(item._id)}
                        />
                    </IconContext.Provider>
                  </div>
                ))
              ) : (
                <p className="text-center">
                  No tienes agregado ningun metodo de pago.
                </p>
              )}
              {/* end credit card info */}
              <button
                className="btn btn-primary mt-4 mb-4 f-right"
                type="button"
                onClick={() => setOpenResumenModal(true)}
                disabled={total === 0 || cards.length === 0}
                >
                Realizar Pago
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  shopping: state.shopping,
  cards: state.creditCards.items,
});
export default connect(mapStateToProps, {
  setDefaultCreditCard,
  deleteCreditCard,
})(Invoice);
