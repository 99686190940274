/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { connect } from "react-redux";
import createAlert from "../../../redux/actions/alert.action";
import { Spinner } from "react-bootstrap";
import useFarinterSelector from "../../../hooks/useFarinterSelector";
import moment from "moment";
import { Table } from "react-bootstrap";
import $ from "jquery";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { countries } from "../../../utils/countries";
import { registerMasiveEmployedCompany } from "../../../redux/actions/auth.action";

function Recordatorio({ registerMasiveEmployedCompany, Plans }) {
  const { auth } = useFarinterSelector();
  const { user } = auth;
  //const [packagektm, setPackagektm]= useState("");
  const [show, setShow] = useState(true);
  const [registros, setRegistros] = useState([]);
  const [verificado, setVerificado] = useState(false);
  const [progreso, setProgreso] = useState(0);
  const [cargado, setCargado] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);
  const [existenFallas, setExistenFallas] = useState(false);
  const [final, setfinal] = useState(false);
  const [headers, setHeaders] = useState([
    { label: "ID", key: "ID" },
    { label: "Email", key: "Email" },
    { label: "Nombres", key: "Nombres" },
    { label: "Apellidos", key: "Apellidos" },
    { label: "Nacimiento", key: "Nacimiento" },
  ]);
  const [headers2, setHeaders2] = useState([
    {
      ID: "",
      Email: "",
      Nombres: "",
      Apellidos: "",
      Nacimiento: "",
      Status: "",
      Numero_de_plan: "",
      Telefono: "",
    },
  ]);
  const [dataformat, setDataformat] = useState([]);
  const [sobras, setSobras] = useState([]);

  //------------------------------------------------------
  const onImportExcel = async (file) => {
    try {
      // Obtener el objeto del archivo cargado
      const { files } = file.target;
      // Leer el archivo a través del objeto FileReader
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        try {
          const { result } = event.target;
          // Leer en secuencia binaria para obtener todo el objeto de tabla de Excel
          const workbook = XLSX.read(result, { type: "binary" });
          // Almacenar los datos obtenidos
          let data = [];
          // recorre cada hoja de trabajo para leer (aquí solo se lee la primera tabla por defecto)
          for (const sheet in workbook.Sheets) {
            // esline-disable-next-line
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              // usa el método sheet_to_json para convertir Excel a datos json
              data = data.concat(
                XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
              );
              // break; // Si solo se toma la primera tabla, descomenta esta línea
            }
          }
          // Finalmente obtener y formatear los datos json

          // console.log(data);
          setRegistros(data);
        } catch (e) {
          // Aquí puede lanzar una solicitud relacionada para un error de tipo de archivo incorrecto
          createAlert("¡Error!", "Tipo de archivo incorrecto", "error");
        }
      };
      // Abre el archivo en modo binario
      fileReader.readAsBinaryString(files[0]);
    } catch (e) {
      await createAlert(
        "¡Error!",
        "Erro al cargar registros, verifique que sean formatos aceptables",
        "error"
      );
    }
  };
  //------------------------------------------------------
  const analizarNacimiento = (reg, fecha) => {
    if (fecha.substring(5, 7) === "10")
      return reg.test(`${fecha.substring(0, 4)}/11/${fecha.substring(8, 10)}`);
    else return reg.test(fecha);
  };
  const analizar = () => {
    if (registros.length > 0) {
      let observaciones =
        "Datos invalidos\n Se presentaron los siguientes problemas: ";
      setLoaderButton(true);
      const datos = registros;
      // console.log(registros)
      const temporales = datos;
      let contador = 0;
      let errores = 0;
      const emailTest =
        /[@](?:\w(?:[-\w]{0,61}\w)?\.)*?(\w(?:[-\w]{0,61}\w)?\.(?:\w{2,3}\.)?\w{1,10})/;
      const bornTest =
        /^\d{4}([\-/.])(0?[1-9]|1[1-2])\1(3[01]|[12][0-9]|0?[1-9])$/i;
      try {
        //-------------verificamos que los usuarios no tengan la misma ID o Email---------------------
        for (var i = 0; i < temporales.length; i++) {
          const temp = temporales[i];
          for (var j = 0; j < temporales.length; j++) {
            const temp2 = temporales[j];
            if (temp.ID === temp2.ID || temp.Email === temp2.Email) {
              contador++;
            }
          }
        }
        if (contador > datos.length) {
          observaciones += "\nID o email repetidos";
          errores++;
        }
        let contadorReferente = errores;
        // console.log("primera fase, errorrs: ", errores);
        //----------------------verificamos que el correo este bien escrito---------------------------
        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];
          const emailForTest = String(temp.Email);
          if (emailTest.test(emailForTest)) {
            //console.log("Correo valido");
          } else {
            // console.log("Correo invalido");
            errores++;
          }
        }
        if (errores > contadorReferente) {
          contadorReferente = errores;
          observaciones += "\nformato de Email no valido";
        }
        //-------------verificamos la fecha de nacimiento------------------
        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];

          const fecha = moment(temp.Nacimiento).format("YYYY-MM-DD");
          if (analizarNacimiento(bornTest, temp.Nacimiento)) {
            //console.log("paso test");
          } else {
            errores++;
          }
        }
        if (errores > contadorReferente) {
          contadorReferente = errores;
          observaciones +=
            "\nformato de fecha de nacimiento no valido\n Procure usar los formados \nYYYY/MM/DD, YYYY-MM-DD";
        }

        //-------------------------------------------------------------------
        //---------verificamos que el id sea de trece digitos
        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];
          const DNI = temp.ID;
          //-----------usamos version nacional que consta de 13 digitos la idendidad--------------------
          let tempDni = DNI.replace(/-/g, "").replace(/_/g, "");
          if (tempDni.length < 13 || tempDni.length > 13) {
            errores++;
          }
        }
        if (errores > contadorReferente) {
          contadorReferente = errores;
          observaciones +=
            "\n numero de de digitos del ID excede los 13 digitos";
        }
        //-------------------------------------------------------------------
        //---------verificamos que el status sea activo o inactivo

        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];
          const status = temp.Status;
          if (status === "activo" || status === "inactivo") {
          } else {
            errores++;
          }
        }
        if (errores > contadorReferente) {
          contadorReferente = errores;
          observaciones += "\n errores en status de los usuarios";
        }
        //-------------------------------------------------------------------
        //---------verificamos el telefono de los usuarios
        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];
          const cellphone = `${temp.Telefono}`;
          console.log("el cel ", cellphone);
          if (cellphone != "" || cellphone != undefined || cellphone != null) {
            if (cellphone.length === 8) {
            } else {
              errores++;
            }
          }
        }
        if (errores > contadorReferente) {
          contadorReferente = errores;
          observaciones += "\n errores en telefono de los usuarios";
        }

        //console.log("segunda fase, errorrs: ", errores);
        if (errores === 0) {
          createAlert("Exito", "Datos Validos", "success");
          setVerificado(true);
        } else {
          $("#archivo").val(null);
          createAlert("Erro", observaciones, "error");
        }
        setLoaderButton(false);
      } catch (error) {
        $("#archivo").val(null);
        setLoaderButton(false);
        createAlert(
          "Error",
          "Error en la validacion de datos\nVerifique que los formatos esten correctos\nNo deben existir campos vacios en los registros",
          "error"
        );
      }
      setLoaderButton(false);
    } else {
      setLoaderButton(false);
      createAlert("error", "Debe cargar un archivo primero", "error");
    }
  };
  const registrar = async () => {
    try {
      if (verificado) {
        setCargado(true);
        let formularios = [];
        let fallidos = [];
        const datos = registros;
        for (var i = 0; i < datos.length; i++) {
          const temp = datos[i];
          const registro = {
            firstName: temp.Nombres,
            lastName: temp.Apellidos,
            email: temp.Email,
            dni: temp.ID,
            password: "EmpresarialK2022",
            role: "patient",
            //  job: "",
            gender: "male",
            idType: "national",
            idCompany: user._id,
            bornAt: temp.Nacimiento,
            registerType: "email",
            cellphone: temp.Telefono,
            status: temp.Status,
            country: countries[0].name,
            dialCode: countries[0].dial_code,
            numberReference: temp.Numero_de_plan,
          };
          //console.log("registro creado: ", registro);
          formularios.push(registro);
        }

        //procedemos a enviar registros, si hay error en alguno, se guardara y se mostraran
        for (var i = 0; i < registros.length; i++) {
          const medida = ((i + 1) / registros.length) * 100;
          setProgreso(medida);
          const temp = formularios[i];
          const res = await registerMasiveEmployedCompany(temp);
          //console.log("mensaje de res: ", res.data.message);
          if (res.data.message === "fallo") {
            fallidos.push(registros[i]);
          }
        }
        if (fallidos.length > 0) {
          setExistenFallas(true);
          setSobras(fallidos);
        }
        setfinal(true);
      } else {
        createAlert(
          "error",
          "Se deben analizar los datos del archivo primero",
          "error"
        );
      }
    } catch (e) {}
  };

  const exportExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(headers2);

    XLSX.utils.book_append_sheet(wb, ws, "Mysheet");
    XLSX.writeFile(wb, "formato.xlsx");
  };
  const exportFallidos = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(sobras);

    XLSX.utils.book_append_sheet(wb, ws, "Mysheet");
    XLSX.writeFile(wb, "registros_fallidos.xlsx");
  };

  const exportExcel2 = () => {
    const Planes = [];
    Plans.forEach((element) => {
      if (
        element.name != "Plan Empresarial 20% descuento" &&
        element.name != "Plan Empresarial 20% descuento para test"
      ) {
        Planes.push({
          Numero_de_plan: `${element.numberReference}`,
          Nombre_plan: element.name,
        });
      }
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(Planes);

    XLSX.utils.book_append_sheet(wb, ws, "Mysheet");
    XLSX.writeFile(wb, "Nomenclatura_planes.xlsx");
  };

  function cerrar() {
    setExistenFallas(false);
    setSobras([]);
    setCargado(false);
    setfinal(false);
    setRegistros([]);
    setVerificado(false);
    $("#archivo").val(null);
    $("#ModalMenuCarga").modal("hide");
  }
  return (
    <div>
      <div
        className="modal fade bd-example-modal-xl"
        id="Recordatorio"
        tabIndex="-1"
        role="dialog"
        show={show}
        backdrop="static"
        keyboard={false}
        data-backdrop="static"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title mx-auto text-primary"
                id="exampleModalLabel"
              >
                Carga de empleados
              </h5>
            </div>
            <div className="modal-body">
              <div style={{ color: "red" }}>
                <p>
                  <br /> Recuerde que el archivo Excel debe seguir el siguiente
                  formato:
                  <br />
                  -PRIMERO: Asegúrese de que el formato de las celdas sea texto
                  (para evitar mutación de datos)
                  <br />
                  -La identidad debe tener 13 dígitos
                  <br />
                  -Asegúrese del formato del correo sea el correcto (@gmail.com,
                  @outlook.es, etc.)
                  <br />
                  -El formato de fecha de nacimiento debe ser YYYY/MM/DD
                  <br />
                  -No deben existir campos vacíos
                  <br />
                  -Se creara la contraseña 'EmpresarialK2022' por defecto para
                  cada empleado registrado
                  <br />
                  -El status unicamente acepta "activo" o "inactivo"
                  <br />
                  -Refierase al plan médico según la nomenclatura,{" "}
                  <br/>
                  sino va a a agregar un plan llene con un 0 la casilla
                  <br/>
                  <strong>
                    Descargar la nomenclatura completa para ver mejor
                  </strong>
                  <br />
                  {"----- Plan médicos empleados anual: 6716001531 -----"}
                  <br />
                  {"----- Plan médicos empleados anual FARINTER: 6716001551 -----"}
                  <br />
                  {"----- Plan médicos empleados anual DRORISA: 6716001552 -----"}
                  <br />
                  {"----- Plan médicos empleados anual DRORISA: 6716001553 -----"}
                  <br />
                  <br />
                  -El número de telefono debe de ser de 8 digitos
                </p>
              </div>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Nacimiento</th>
                    <th>Status</th>
                    <th>Numero de plan</th>
                    <th>Telefono</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0320111101234</td>
                    <td>jose@gmail.com</td>
                    <td>Jose Antonio</td>
                    <td>Lopez Lopez</td>
                    <td>1990/09/01</td>
                    <td>activo</td>
                    <td>6716001553</td>
                    <td>33112224</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={exportExcel2}
              >
                Descargar Nomenclatura de planes
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={exportExcel}
              >
                Descargar formato
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-info"
                href="#ModalMenuCarga"
                data-toggle="modal"
                data-dismiss="modal"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*Modal Menu Carga*/}
      <div
        className="modal"
        id="ModalMenuCarga"
        tabIndex="-1"
        role="dialog"
        show={show}
        backdrop="static"
        keyboard={false}
        data-backdrop="static"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title mx-auto text-primary"
                id="exampleModalLabel"
              >
                Carga de empleados
              </h5>
            </div>
            <div className="modal-body">
              <form>
                <input
                  id="archivo"
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={onImportExcel}
                />
                <span> subir archivo (admite .xlsx, .xls)</span>
                {/*<Button variant="info" onClick={analizar}>{loaderButton ? (
                                    <Spinner
                                        style={{
                                            width: 20,
                                            height: 20,
                                            color: "#ffff",
                                            fontSize: 8,
                                        }}
                                        animation="border"
                                    />
                                ) : (
                                    "ANALIZAR ARCHIVO"
                                )}</Button>*/}
              </form>
              <div>
                {cargado ? (
                  <ProgressBar now={progreso} />
                ) : (
                  "Los registros se analizaran e intentaran registrar"
                )}
              </div>
            </div>
            <div className="modal-footer">
              {existenFallas ? (
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={exportFallidos}
                >
                  Descargar registros fallidos
                </button>
              ) : (
                <></>
              )}
              {final ? (
                <></>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cerrar}
                >
                  CANCELAR
                </button>
              )}
              {verificado ? (
                <>
                  {final ? (
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={cerrar}
                    >
                      Terminar
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={registrar}
                    >
                      {loaderButton ? (
                        <Spinner
                          style={{
                            width: 20,
                            height: 20,
                            color: "#ffff",
                            fontSize: 8,
                          }}
                          animation="border"
                        />
                      ) : (
                        "REGISTRAR"
                      )}
                    </button>
                  )}
                </>
              ) : (
                <Button variant="info" onClick={analizar}>
                  {loaderButton ? (
                    <Spinner
                      style={{
                        width: 20,
                        height: 20,
                        color: "#ffff",
                        fontSize: 8,
                      }}
                      animation="border"
                    />
                  ) : (
                    "ANALIZAR ARCHIVO"
                  )}
                </Button>
              )}
              {/*{final?( <button type="button" className="btn btn-info" onClick={cerrar}>Terminar
                                </button>):( <button type="button" className="btn btn-info" onClick={registrar}>{loaderButton ? (
                                    <Spinner
                                        style={{
                                            width: 20,
                                            height: 20,
                                            color: "#ffff",
                                            fontSize: 8,
                                        }}
                                        animation="border"
                                    />
                                ) : (
                                    "REGISTRAR"
                                )}</button>)}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  Plans: state.plans.plans,
});
export default connect(mapStateToProps, { registerMasiveEmployedCompany })(
  Recordatorio
);
