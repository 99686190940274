import React, { useEffect } from 'react';
import { Button, InputGroup, Modal, Form, FormControl } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import useCallCenterPlanModalState from './state/useCallCenterPlanModalState';

const CallCenterPlanModal = () => {
  const { open, handleCloseModal, handleSubmit, company, handleChangeCompany } =
    useCallCenterPlanModalState();
  return (
    <Modal
      show={open}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="align-items-center">
        <h3 className="mt-2 text-primary ml-4">Agregar plan telemedicina</h3>
      </Modal.Header>
      <Modal.Body style={{ padding: 40 }}>
        <div className="form-group">
          <label>Seleccione la Compañia:</label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            name="company"
            value={company}
            onChange={handleChangeCompany}
            placeholde="Seleccione la compañia"
          >
            <option value="tigo">Tigo</option>
            <option value="claro">Claro</option>
          </select>
        </div>
        <Button
          onClick={handleSubmit}
          disabled={company === '' || company.length < 3}
          className="mt-4"
        >
          Agregar
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CallCenterPlanModal;
