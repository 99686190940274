import { useState } from "react";
export const useShowModal = () => {
  const [showModalAddDependents, setShowModalAddDependents] = useState(false);
  const [modalDataEmployee, setModalDataEmployee] = useState({});
  return {
    modalDataEmployee,
    setModalDataEmployee,
    showModalAddDependents,
    setShowModalAddDependents,
  };
};
