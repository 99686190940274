/* eslint-disable arrow-parens */
/* eslint-disable func-names */
import {
  GET_SHOPPING_ITEMS,
  DELETE_SHOPPING_ITEM,
  ADD_SHOPPING_ITEM,
  ADD_TOTAL_ITEM,
  DELETE_TOTAL_ITEM,
  DELETE_ALL_SHOPPING_ITEMS,
} from '../types';

const initialState = {
  items: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SHOPPING_ITEMS:
      return {
        ...state,
        items: payload,
      };
    case ADD_SHOPPING_ITEM:
      return {
        ...state,
        items: [...state.items, payload],
      };

    case DELETE_SHOPPING_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload),
      };
    case ADD_TOTAL_ITEM:
      return {
        ...state,
        total: state.total + payload,
      };

    case DELETE_TOTAL_ITEM:
      return {
        ...state,
        total: state.total - payload,
      };
    case DELETE_ALL_SHOPPING_ITEMS:
      return {
        ...state,
        items: payload,
        total: 0,
      };
    default:
      return state;
  }
}
