import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import avatarTemp from '../../assets/img/avatar1.jpg';

const LabUserDetails = () => {
  const { userRef } = useSelector((state) => state.reference);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 'auto',
          borderRadius: '50%',
          border: '4px solid #011688',
        }}
      >
        <Avatar
          round
          src={
            userRef?.image ? `/api/profile/image/${userRef?.image}` : avatarTemp
          }
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
        <p
          style={{ margin: 0, fontSize: 20, color: '#011688', fontWeight: 500 }}
        >
          {`${userRef?.firstName} ${userRef?.lastName}`}
        </p>
        <p style={{ margin: 0, fontSize: 18, fontWeight: 400 }}>
          {userRef?.email}
        </p>
        <p style={{ margin: 0, fontWeight: 400 }}>{userRef?.dni}</p>
      </div>
    </div>
  );
};

export default LabUserDetails;
