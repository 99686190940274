import React from 'react';
import {Link} from 'react-router-dom';

const NoValidPatient = () => (
  <div className="h-100 text-center mt-4 pt-4">
    <div className="container">
      <h1 className="pt-2 text-center text-primary font-weight-bold pb-5">¡No posees plan médico!</h1>
      <div className="row justify-content-center" style={{paddingTop: '410px'}}>
        <img
          src={require('../assets/img/bg-doc.png')}
          alt="doctor"
          width="350"
          className="img-fluid image-overlay"
        />
        <div className="col-12 mb-3">
          <div className="bg-secondary-light p-3 w-75 m-auto shadow d-none d-sm-none d-md-block" style={{borderRadius: '1rem'}}>
            <h3 className="text-center text-primary">
              Adquiere éstos
              beneficios con la compra de cualquier plan.
            </h3>
          </div>
          <div className="bg-secondary-light p-3 m-auto shadow d-block d-sm-block d-md-none" style={{borderRadius: '1rem'}}>
            <h5 className="text-center text-primary">
              Adquiere éstos
              beneficios con la compra de cualquier plan.
            </h5>
          </div>
        </div>
        <div className="col-12 col-sm-auto col-md-4 mb-3">
          <div className="card border-0 shadow h-100" style={{borderRadius: '1rem'}}>
            <div className="card-body d-flex border-0 m-auto pb-1">
              <img
                src={require('../assets/img/icons/camera.svg')}
                alt="doctor"
                width="50"
                className="img-fluid mr-3"
              />
              <h5 className="m-auto">
                <span className="badge bg-secondary-light text-primary rounded-pill p-2 shadow-sm text-primary">Telemedicina 24/7</span>
              </h5>
            </div>
            <small className="text-dash mb-4">
              Orientación médica virtual un solo clic.
            </small>
          </div>
        </div>
        <div className="col-12 col-sm-auto col-md-4 mb-3">
          <div className="card border-0 shadow h-100" style={{borderRadius: '1rem'}}>
            <div className="card-body d-flex border-0 m-auto pb-1">
              <img
                src={require('../assets/img/icons/add-heart.svg')}
                alt="doctor"
                width="50"
                className="img-fluid mr-3"
              />
              <h5 className="m-auto">
                <span className="badge bg-secondary-light rounded-pill p-2 shadow-sm text-primary">Consulta Presencial</span>
              </h5>
            </div>
            <small className="text-dash mb-4">
              Atención médica exclusiva.
            </small>
          </div>
        </div>
        <div className="col-12 col-sm-auto col-md-4 mb-3">
          <div className="card border-0 shadow h-100" style={{borderRadius: '1rem'}}>
            <div className="card-body d-flex border-0 m-auto pb-1">
              <img
                src={require('../assets/img/icons/calendar.svg')}
                alt="doctor"
                width="50"
                className="img-fluid mr-3"
              />
              <h5 className="m-auto">
                <span className="badge bg-secondary-light rounded-pill p-2 shadow-sm text-primary">Agendar Cita</span>
              </h5>
            </div>
            <small className="text-dash mb-4">
              Programa citas a tu propio ritmo.
            </small>
          </div>
        </div>
        <div className="col-12 col-sm-auto col-md-4 mb-3">
          <div className="card border-0 shadow h-100" style={{borderRadius: '1rem'}}>
            <div className="card-body d-flex border-0 m-auto pb-1">
              <img
                src={require('../assets/img/icons/credit-card.svg')}
                alt="doctor"
                width="50"
                className="img-fluid mr-3"
              />
              <h5 className="m-auto">
                <span className="badge bg-secondary-light rounded-pill p-2 shadow-sm text-primary">Facilidad de pago</span>
              </h5>
            </div>
            <small className="text-dash mb-4">
              Paga en cuotas a tarifas accesibles.
            </small>
          </div>
        </div>
        <div className="col-12 col-sm-auto col-md-4 mb-3">
          <div className="card border-0 shadow h-100" style={{borderRadius: '1rem'}}>
            <div className="card-body d-flex border-0 m-auto pb-1">
              <img
                src={require('../assets/img/vectores/porcentaje.svg')}
                alt="doctor"
                width="50"
                className="img-fluid mr-3"
              />
              <h5 className="m-auto">
                <span className="badge bg-secondary-light rounded-pill p-2 shadow-sm text-primary">Descuentos Especiales</span>
              </h5>
            </div>
            <small className="text-dash mb-4">
              Recibe descuentos increíbles en todos los planes.
            </small>
          </div>
        </div>
      </div>
      <Link className="btn btn-primary col-12 col-md-4 btn-lg mt-4 font-weight-bold shadow mb-5" to="/dashboard/consulta">
        Comprar plan
        {' '}
        <img
          src={require('../assets/img/vectores/comprobado.svg')}
          alt="doctor"
          width="25"
          className="img-fluid ml-2"
        />
      </Link>
    </div>
  </div>
);

export default NoValidPatient;
