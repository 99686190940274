/* eslint-disable global-require */
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import './styles.css';
import { exportComponentAsJPEG } from 'react-component-export-image';

const PatientCard = ({ user, planRef, dependenRef }) => {
  const carnet = useRef(null);
  const carnetBack = useRef(null);

  const handlePrintCarnet = () => {
    exportComponentAsJPEG(carnet, {
      fileName: `Carnet frontal plan ${user?.firstName.split(' ')[0]} ${
        user?.lastName.split(' ')[0]
      }`,
    });
  };

  const handlePrintCarnetBack = () => {
    exportComponentAsJPEG(carnetBack, {
      fileName: `Carnet reverso plan ${user?.firstName.split(' ')[0]} ${
        user?.lastName.split(' ')[0]
      }`,
    });
  };

  const handleGetRelation = (relation) => {
    if (relation === 'son') {
      return 'Hijo(a)';
    } else if (relation === 'father') {
      return 'Padre';
    } else if (relation === 'mother') {
      return 'Madre';
    } else if (relation === 'grandpa') {
      return 'Abuelo';
    } else if (relation === 'grandma') {
      return 'Abuela';
    } else if (relation === 'uncle') {
      return 'Tío';
    }

    return 'Amigo(a)';
  };

  if (!planRef && user?.role === 'patient') {
    return (
      <div className='container-user-not-plan'>
        <Redirect to='/dashboard' />
      </div>
    );
  }
  return (
    <>
      <div className='container mt-5 d-none d-sm-none d-md-none d-lg-block mb-5'>
        <button
          type='button'
          className='btn btn-primary font-weight-bold mb-3 btn-lg'
          onClick={handlePrintCarnet}
        >
          <i className='fa fa-print mr-2' />
          Imprimir carnet frontal
        </button>

        {/* primera parte carnet */}
        <div className='position-relative ' ref={carnet}>
          <img
            src={require('../../../assets/img/carnet/carnet-02.png')}
            className='img-fluid image-card'
            alt='carnet 02 farmacias kielsa'
            loading='lazy'
          />
          <div className='datos-carnet-patient'>
            <div className='background-title-plan'>
              <h1 className='text-primary text-uppercase'>
                Plan <b>{`${planRef?.plan?.name}`}</b>
              </h1>
            </div>
            <h4 className='text-primary'>
              <b>{`Nombre: ${user?.firstName} ${user?.lastName}`}</b>
            </h4>
            <h4 className='text-primary'>
              <b>{`Número ID: ${user?.dni}`}</b>
            </h4>
            <h4 className='text-primary'>
              <b>{`Alta: ${moment(planRef?.createdAt).format(
                'DD/MM/YYYY'
              )}`}</b>
            </h4>
            <h4 className='text-primary'>
              <b>
                {`Vigencia: ${moment(planRef?.dueDate).format('DD/MM/YYYY')}`}
              </b>
            </h4>
          </div>
        </div>
        <br />
        <button
          type='button'
          className='btn btn-primary font-weight-bold mb-3 btn-lg'
          onClick={handlePrintCarnetBack}
        >
          <i className='fa fa-print mr-2' />
          Imprimir reverso carnet
        </button>
        {/* segunda parte carnet */}
        <div className='position-relative mt-1' ref={carnetBack}>
          <img
            src={require('../../../assets/img/carnet/carnet-03.png')}
            className='img-fluid  image-card'
            alt='carnet 03 farmacias kielsa'
            loading='edge'
          />
          <div className='datos-carnet-02-patient'>
            <div className='background-benefits'>
              <h4 className='text-primary font-weight-bold h4'>Beneficios:</h4>
            </div>
            <ul className='text-black pl-4 modal-benefits-list'>
              {planRef?.plan?.products?.map((item) => (
                <li className='benefits-item' key={item}>
                  {item}
                </li>
              ))}
            </ul>
            {dependenRef?.length > 0 && (
              <div className='background-dependents'>
                <h4 className='text-primary font-weight-bold h4'>
                  Dependientes:
                </h4>
              </div>
            )}

            {dependenRef?.map((item) => (
              <div
                className='d-flex  justify-content-between align-items-center text-uppercase  text-primary h5'
                key={item?._id}
                style={{ width: 400 }}
              >
                {`${item?.dependentPatient?.firstName?.split(' ')[0]} ${
                  item?.dependentPatient?.lastName?.split(' ')[0]
                }`}
                <span>{handleGetRelation(item?.relation)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='text-center mt-5 d-block d-sm-block d-md-block d-lg-none'>
        <h4 className='text-dark'>
          Carnet disponible para impresión solo desde una PC o laptop.
        </h4>
      </div>
    </>
  );
};
const mapStatetoProps = (state) => ({
  user: state.auth.user,
  planRef: state.reference.planRef,
  dependenRef: state.reference.dependentsRef,
});

export default connect(mapStatetoProps)(PatientCard);
