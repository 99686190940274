import axios from 'axios';
import {
  GET_ALL_DOCTORS_BY_CONSULTORY,
  DELETE_ALL_DOCTORS_BY_CONSULTORY,
  SELECT_CONSULTORY,
  RESET_SELECT_CONSULTORY,
} from '../types';

export const getAllConsultoryDoctors = (consultoryId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/doctor/office/all/doctors/${consultoryId}`
    );

    if (res.data.status === 'success') {
      dispatch({
        type: GET_ALL_DOCTORS_BY_CONSULTORY,
        payload: res.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllConsultoryDoctors = () => (dispatch) => {
  try {
    dispatch({
      type: DELETE_ALL_DOCTORS_BY_CONSULTORY,
      payload: [],
    });
  } catch (error) {
    console.log(error);
  }
};

export const selectConsultory = (consultory) => (dispatch) => {
  try {
    dispatch({
      type: SELECT_CONSULTORY,
      payload: consultory,
    });
    dispatch(getAllConsultoryDoctors(consultory._id));
  } catch (error) {
    console.log(error);
  }
};

export const resetConsultory = () => (dispatch) => {
  try {
    dispatch({
      type: RESET_SELECT_CONSULTORY,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};
