import axios from "axios";

export const getAllEnterprises = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/companies/getListCompaniesWithDomains`)
      .then((res) => {
        const { data } = res;
        if (data.status === "success") {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((error) => reject(error));
  });
