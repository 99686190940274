/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import createAlert from '../../redux/actions/alert.action';
import { addProfileImage } from '../../redux/actions/profile.action';
import imgAvatarTemp from '../../assets/img/avatar1.jpg';
import '../../stylesheets/profile.css';
import CardUpcomingScheduling from '../Card/CardUpcomingScheduling';
import { upcomingScheduling } from '../../api/scheduling';
import { getListAvailableDoctor } from '../../api/doctor';
import {
  observerDoctorOnline,
  sendCall,
} from '../../websokect/patientWebSocket';
import { createVideoconference } from '../../api/videoconference';
import {
  searchPatient,
  createDependentPatient,
  isDependentPatient,
} from '../../api/dependentPatient';
import { changeConsultDoctorOffice } from '../../websokect/consultDoctorOfficeWebsocket';
import { validUserPatient, validGroupPackageUser } from '../../api/user';
import CardCashPackageKtm from '../CardCashPackageKtm';
import { url, showAdsFile } from '../../api/ads';
import AddDepedentModal from './AddDependentModal';

const HomePacientMovil = ({
  user: { firstName, lastName, email, gender, image, role },
  profile: { cellphone, dni },
  addProfileImage,
  token,
}) => {
  const [upcommingSchedule, setUpcomingScheduling] = useState([]);
  const [validPatient, setValidPatient] = useState(true);
  const [doctorList, setDoctorList] = useState([]);
  const [dataAds, setDataAds] = useState([]);
  const genero = gender === 'male' ? 'Masculino' : 'Femenino';
  const [patients, setPatients] = useState([]);
  const [validGroup, setValidGroup] = useState(false);
  const [isDepedent, setisDepedent] = useState(false);
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [callingDoctor, setCallingDoctor] = useState(false);
  const [open, setOpen] = useState(false);
  const tel = cellphone === undefined ? '' : `Cel. ${cellphone}`;
  const identidad = dni === undefined ? '' : dni;

  useEffect(() => {
    /* traendo las proximas consultas del paciente */
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        setUpcomingScheduling(data);
      })
      .catch((error) => {
        console.log(error);
      });
    getListAvailableDoctor({ token })
      .then((res) => {
        const { doctors } = res;
        setDoctorList(doctors);
      })
      .catch((error) => {
        console.log(error);
      });
    observerDoctorOnline(() => {
      getListAvailableDoctor({ token })
        .then((res) => {
          const { doctors } = res;
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    showAdsFile({ token }).then((res) => {
      const { data } = res;
      setDataAds(data);
    });
    validUserPatient({ token })
      .then(() => {
        setValidPatient(true);
      })
      .catch(() => setValidPatient(false));
    isDependentPatient({ token }).then(() => {
      setisDepedent(true);
    });
    validGroupPackageUser({ token }).then(() => {
      setValidGroup(true);
    });
  }, [
    token,
    setValidPatient,
    setDoctorList,
    setUpcomingScheduling,
    setValidGroup,
    setisDepedent,
  ]);

  const handleuploadImage = () => {
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileImage(formData, image);
    };
    input.click();
  };

  const handleVideoconference = (doctor) => {
    if (doctor.profile.status === 'available') {
      createVideoconference({
        token: token,
        doctor: doctor._id,
        type: 'videocall',
      })
        .then((response) => {
          const { idVideo, idDoctorConsult } = response;
          changeConsultDoctorOffice({
            idConsult: idDoctorConsult,
          });
          sendCall(
            {
              doctorId: doctor._id,
              role: role,
              idVideo: idVideo,
              type: 'videocall',
            },
            (data) => {
              if (data.status === 'success') {
                window.location = `/dashboard/waitingcall/${idVideo}`;
              } else {
                setCallingDoctor(false);
                createAlert(
                  '¡Error!',
                  'Lo sentimos no pudimos crear la videollamada.',
                  'error'
                );
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          setCallingDoctor(false);
          createAlert(
            '¡Error!',
            'Lo sentimos no pudimos crear la videollamada.',
            'error'
          );
        });
    } else {
      setCallingDoctor(false);
      createAlert(
        '¡Error!',
        'Lo sentimos, el médico no se encuentra disponible.',
        'error'
      );
    }
  };

  return (
    <div className='container'>
      <h5 className='text-primary mt-4 mb-4'>
        {`Bienvenid${gender === 'male' ? 'o' : 'a'}`}{' '}
        <b className='text-secondarymdv'>
          {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
        </b>
      </h5>
      <div
        id='carouselExampleControls2'
        className='carousel slide shadow-sm'
        data-ride='carousel'
      >
        {dataAds.length === 0 ? (
          <div className='carousel-inner justify-content-center'>
            <div className='carousel-item active'>
              <img
                src={require('../../assets/img/banner-inicial.jpg')}
                className='d-block w-100'
                alt='...'
              />
            </div>
          </div>
        ) : (
          <div className='carousel-inner justify-content-center'>
            {dataAds.map((doc, i) => (
              <div
                key={doc._id}
                className={`carousel-item ${i === 0 ? 'active' : ''}`}
              >
                <img
                  src={`${url}/file/${doc.filename}`}
                  className='d-block w-100'
                  alt='...'
                />
              </div>
            ))}
          </div>
        )}
        <a
          className='carousel-control-prev'
          href='#carouselExampleControls2'
          role='button'
          data-slide='prev'
        >
          <i
            className='fas fa-caret-square-left arrow-left-app'
            aria-hidden='true'
          />
          <span className='sr-only'>Previous</span>
        </a>
        <a
          className='carousel-control-next'
          href='#carouselExampleControls2'
          role='button'
          data-slide='next'
        >
          <i
            className='fas fa-caret-square-right arrow-right-app'
            aria-hidden='true'
          />
          <span className='sr-only'>Next</span>
        </a>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div className='card mb-3 p-1 border-0 shadow-sm'>
            <div className='d-flex'>
              {/* imagen de perfil */}
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={<Tooltip id='tooltip-bottom'>Cambiar foto</Tooltip>}
              >
                <button
                  type='button'
                  className='avatar-button-container'
                  onClick={handleuploadImage}
                >
                  <div
                    style={{
                      backgroundImage: `${
                        image === null || image === '' || image === undefined
                          ? `url(${imgAvatarTemp})`
                          : `url(/api/profile/image/${image})`
                      }`,
                    }}
                    className='image-containerMovil'
                  />
                </button>
              </OverlayTrigger>
              <div className='ml-4'>
                <span className='badge bg-light text-secondarymdv'>
                  Dependiente
                </span>
                <br />
                <small className='text-muted ml-1'>
                  Plan empresarial / Empleado Ficohsa
                </small>
                <div className='hr' />
                {email ? (
                  <small>
                    <i className='fas fa-envelope-open-text' />
                    &nbsp;
                    {email}
                  </small>
                ) : (
                  ''
                )}
                <br />
                {identidad ? (
                  <small>
                    <i className='far fa-id-badge' />
                    &nbsp;
                    {identidad}
                  </small>
                ) : (
                  ''
                )}
                <br />
                {genero ? (
                  <small>
                    <i className='fas fa-venus-mars' />
                    &nbsp;
                    {genero}
                  </small>
                ) : (
                  ''
                )}
                <br />
                {tel ? (
                  <small>
                    <i className='fas fa-phone-alt' />
                    &nbsp;
                    {tel}
                  </small>
                ) : (
                  ''
                )}
                {validPatient && (
                  <Link
                    className='btn btn-info btn-sm mt-1 mb-1'
                    to='/dashboard/details'
                    style={{
                      borderRadius: '0.8rem',
                    }}
                  >
                    <i className='fas fa-eye mr-1' />
                    Historial de Consultas
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {!validPatient && (
          <>
            <div />
            <CardCashPackageKtm />
            <br />
          </>
        )}
        {validPatient && (
          <div className='col-12'>
            <h5 className='text-secondarymdv mt-3 mb-3'>
              Médicos a un solo clic
            </h5>
            {callingDoctor ? (
              <div className='row flex-row flex-nowrap scroll-h mar-2'>
                <div className='col-12 col-sm-6 col-md-4'>
                  <Spinner animation='border' variant='primary' />
                  <div>Procesando llamada...</div>
                </div>
              </div>
            ) : (
              <div className='scrollMovil-h text-center'>
                <div className='d-flex'>
                  {doctorList.length > 0 ? (
                    doctorList.some(
                      (doctor) => doctor.profile.status === 'available'
                    ) ? (
                      doctorList.map((doctor) => {
                        if (doctor.profile.status === 'available') {
                          return (
                            <div
                              key={doctor._id}
                              onClick={() => {
                                setCallingDoctor(true);
                                handleVideoconference(doctor);
                              }}
                              className='pointer ml-3 d-inline'
                            >
                              <img
                                className='rounded-circle doctor-clic-movil'
                                src={require('../../assets/img/avatars/avatar-doc-male.png')}
                              />
                              <br />
                              <small className='text-black-50'>
                                Dr. {doctor.lastName}
                              </small>
                              <br />
                              <small className='text-black-50'>
                                {doctor.specialty}
                              </small>
                            </div>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <div className='col-12 text-center'>
                        No hay médicos disponibles...
                      </div>
                    )
                  ) : (
                    <div className='text-center'>
                      <small className='text-black-50'>
                        No hay médicos disponibles...
                      </small>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {validPatient && (
          <div className='col-12'>
            <h5 className='text-secondarymdv mb-3 mt-3'>Próximas citas</h5>
            {upcommingSchedule.length > 0 ? (
              upcommingSchedule.map(({ doctor, day, month, year, hour }) => (
                <>
                  <CardUpcomingScheduling
                    image={
                      doctor.image
                        ? `/api/profile/image/${doctor.image}`
                        : undefined
                    }
                    lastName={doctor.lastName}
                    date={`${moment(new Date(day, month - 1, year)).format(
                      'LL'
                    )}, ${hour}`}
                  />
                </>
              ))
            ) : (
              <p>No hay próximas citas</p>
            )}
          </div>
        )}
        {validPatient && !isDepedent && validGroup && (
          <div className='col-12'>
            <h5 className='text-secondarymdv mb-3 mt-3'>
              Búsqueda de Pacientes Dependientes
            </h5>
            <div className='row'>
              <div className='col-8'>
                <form
                  className='input-group mb-3 input-group-sm mt-3'
                  onSubmit={(e) => {
                    e.preventDefault();
                    setLoadingPatient(true);
                    searchPatient({
                      token: token,
                      text: e.target.searchPatient.value,
                    })
                      .then((res) => {
                        const { data } = res;
                        setPatients(data);
                        setLoadingPatient(false);
                      })
                      .catch((error) => {
                        if (error.info) {
                          toastr.error(error.info);
                        } else {
                          createAlert(
                            '¡Error!',
                            'Lo sentimos, no se pudo realizar la búsqueda de los pacientes.',
                            'error'
                          );
                        }
                        setLoadingPatient(false);
                      });
                  }}
                >
                  <input
                    type='text'
                    className='form-control'
                    id='Search'
                    // onChange=""
                    // value="1"
                    name='searchPatient'
                    placeholder='&nbsp;Buscar Dependiente'
                    aria-label='&nbsp;Buscar Dependiente'
                    aria-describedby='button-addon2'
                  />
                  <div className='input-group-append'>
                    <button
                      className='btn btn-light border border-right-rounded btn-sm'
                      type='button'
                      id='button-addon2'
                    >
                      {' '}
                      <i className='fas fa-search text-secondarymdv' />
                    </button>
                  </div>
                </form>
              </div>
              <div className='col-2'>
                <button
                  onClick={() => setOpen(true)}
                  className='btn btn-sm btn-primary mt-3'
                  type='button'
                >
                  <i className='fas fa-user-plus' />
                </button>
              </div>
            </div>
            <AddDepedentModal show={open} onClose={() => setOpen(!open)} />
            {loadingPatient ? (
              <Spinner color='primary' />
            ) : (
              <ul className='list-group list-group-flush container-dependent'>
                {patients.length > 0 ? (
                  patients.map((user) => (
                    <li className='list-group-item p-1'>
                      <img
                        src='https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png'
                        className='rounded-circle mr-1'
                        alt='...'
                        width='35'
                      />
                      {`${user.firstName} ${user.lastName}`}
                      <div className='float-right'>
                        <div className='d-flex'>
                          <select
                            id='relationPatient'
                            className='form-control form-control-sm mr-1'
                          >
                            <option value='son'>Hij@</option>
                            <option value='grandpa'>Abuelo</option>
                            <option value='grandma'>Abuela</option>
                            <option value='mother'>Madre</option>
                            <option value='father'>Padre</option>
                            <option value='uncle'>Tí@</option>
                            <option value='other'>Otro</option>
                          </select>
                          <button
                            type='button'
                            className='btn btn-light btn-sm text-primary font-weight-bold'
                            onClick={() => {
                              const relation =
                                window.document.getElementById(
                                  'relationPatient'
                                ).value;
                              if (relation === 'other') {
                                Swal.fire({
                                  title: 'Ingrese la relación',
                                  input: 'text',
                                  inputAttributes: {
                                    autocapitalize: 'off',
                                  },
                                  showCancelButton: true,
                                  confirmButtonText: 'Agregar',
                                  cancelButtonText: 'Cancelar',
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    createDependentPatient({
                                      dependentPatient: user._id,
                                      relation:
                                        window.document.getElementById(
                                          'relationPatient'
                                        ).value,
                                      token: token,
                                      otherName: result.value,
                                    })
                                      .then(() => {
                                        createAlert(
                                          '¡Listo!',
                                          'Se creo la relación.',
                                          'success'
                                        );
                                      })
                                      .catch((error) => {
                                        if (error.info) {
                                          createAlert(
                                            '¡Error!',
                                            error.info,
                                            'error'
                                          );
                                        } else {
                                          createAlert(
                                            '¡Error!',
                                            'Lo sentimos no se pudo crear el registro.',
                                            'error'
                                          );
                                        }
                                      });
                                  }
                                });
                              } else {
                                createDependentPatient({
                                  dependentPatient: user._id,
                                  relation:
                                    window.document.getElementById(
                                      'relationPatient'
                                    ).value,
                                  token: token,
                                })
                                  .then(() => {
                                    createAlert(
                                      '¡Listo!',
                                      'Se creo la relación.',
                                      'success'
                                    );
                                  })
                                  .catch((error) => {
                                    if (error.info) {
                                      createAlert(
                                        '¡Error!',
                                        error.info,
                                        'error'
                                      );
                                    } else {
                                      createAlert(
                                        '¡Error!',
                                        'Lo sentimos no se pudo crear el registro.',
                                        'error'
                                      );
                                    }
                                  });
                              }
                            }}
                          >
                            <i className='fas fa-plus' />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className='list-group-item' />
                )}
              </ul>
            )}
          </div>
        )}
      </div>{' '}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
});

export default connect(mapStateToProps, { addProfileImage })(HomePacientMovil);
