localStorage.setItem('arrayConnections', []);

export const addConnection = connection => {
  const data = JSON.parse(localStorage.getItem('arrayConnections'));
  data.push(connection);
  localStorage.setItem('arrayConnections', data);
};

export const getListConnections = () => {
  if (localStorage.getItem('arrayConnections')) {
    const data = JSON.parse(localStorage.getItem('arrayConnections'));
    return data;
  } else {
    return [];
  }
};

export const deleteAllConnections = () => {
  localStorage.setItem('arrayConnections', []);
};

export const addCurrentConnection = connection => {
  localStorage.setItem('connectionString', connection);
};

export const deleteCurrentConnection = () => {
  localStorage.removeItem('connectionString');
};

export const getCurrentConnection = () => localStorage.getItem('connectionString');
