import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Tabular from '../../Components/Tabular';
import {getCheckerCashPackage} from '../../api/cash.packagektm';

const CashPackageChecker = ({token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    getCheckerCashPackage({token}).then((res) => {
      const {data: otherData} = res;
      setData(otherData.map((item) => ({
        nameClient: item.namePatient,
        emailClient: item.emailPatient,
        packageName: item.package.name,
        code: item.code,
        active: item.active ? 'Activo' : 'Usado',
      })));
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);
  const columns = [
    {
      title: 'Nombre del Cliente',
      field: 'nameClient',
    },
    {
      title: 'Codigo',
      field: 'code',
    },
    {
      title: 'Estatus',
      field: 'active',
    },
    {
      title: 'Correo del Cliente',
      field: 'emailClient',
    },
    {
      title: 'Paquete',
      field: 'packageName',
    },
  ];
  return (
    <div className="container-fluid mt-4">
      <h2 className="text-center text-primary">
        Historial de Venta
      </h2>
      <Tabular
        columns={columns}
        data={data}
        title="Historial"
        ready={loading}
      />
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CashPackageChecker);
