import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUserByCheckerFromSearch } from '../../../redux/actions/auth.action';
import {
  changeAddUserFlag,
  deleteReference,
} from '../../../redux/actions/references';
import { countries } from '../../../utils/countries';

const useCallCenterState = () => {
  const [formData, setFormData] = useState({
    email: '',
    dni: '',
    idType: 'national',
    cellphone: '',
    country: '',
    dialCode: countries[0].dial_code,
    searchType: 'cellphone',
  });

  useEffect(
    () => () => {
      dispatch(deleteReference());
    },
    []
  );

  const dispatch = useDispatch();
  const { userRef, addUserFlag } = useSelector((state) => state.reference);

  const { email, dni, idType, cellphone, country, dialCode, searchType } =
    formData;

  const handleChange = (e) => {
    if (userRef) {
      dispatch(deleteReference());
    }
    if (addUserFlag) {
      dispatch(changeAddUserFlag(false));
    }

    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
        email: '',
        password: '',
        passwordConfirm: '',
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClickCleanButton = () => {
    if (userRef) {
      dispatch(deleteReference());
    }
    if (addUserFlag) {
      dispatch(changeAddUserFlag(false));
    }

    setFormData({
      email: '',
      dni: '',
      idType: 'national',
      cellphone: '',
      country: '',
      dialCode: countries[0].dial_code,
      searchType: 'cellphone',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addUserByCheckerFromSearch({
        dialCode,
        cellphone,
      })
    );
  };

  return {
    email,
    dni,
    idType,
    cellphone,
    country,
    dialCode,
    searchType,
    handleChange,
    handleClickCleanButton,
    handleSubmit,
    countries,
    addUserFlag,
    userRef,
  };
};

export default useCallCenterState;
