/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { banks } from '../../utils/banks';
import createAlert from '../../redux/actions/alert.action';
import {
  addBankAccount,
  deleteSelectedItem,
  updateBankAccount,
} from '../../redux/actions/bank.accounts.action';
import { updateProfile } from '../../redux/actions/profile.action';

// eslint-disable-next-line arrow-body-style
const ModalAccount = ({
  show,
  setShow,
  addBankAccount,
  selectedItem,
  deleteSelectedItem,
  updateBankAccount,
}) => {
  const [formData, setFormData] = useState({
    bank: 'Banco Atlantida',
    type: 'Ahorro',
    number: '',
  });
  useEffect(() => {
    setFormData({
      bank: selectedItem === null ? 'Banco Atlantida' : selectedItem.bank,
      type: selectedItem === null ? 'Ahorro' : selectedItem.type,
      number: selectedItem === null ? '' : selectedItem.number,
    });
  }, [selectedItem]);

  const { type, bank, number } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (number === '' || bank === '' || number === '') {
      createAlert('Error', 'Rellene todos los campos', 'error');
    } else {
      addBankAccount(bank, number, type);
      setFormData({
        bank: '',
        type: '',
        number: '',
      });
      setShow(false);
    }
  };

  const handleClose = () => {
    setFormData({
      bank: '',
      number: '',
      type: '',
    });
    setShow(false);
    deleteSelectedItem();
  };

  const handleUpdate = () => {
    if (number === '' || bank === '' || number === '') {
      createAlert('Error', 'Rellene todos los campos', 'error');
    } else {
      const doc = {
        bank,
        number,
        type,
      };
      updateBankAccount(doc, selectedItem._id);
      setFormData({
        bank: '',
        type: '',
        number: '',
      });
      setShow(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <h5>Datos de la cuenta</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">
              Tipo cuenta:
            </label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              placeholder="Especialidad Principal"
              onChange={(e) => handleChange(e)}
              name="type"
              value={type}
            >
              <option>Ahorro</option>
              <option>Cheques</option>
            </select>
          </div>
          <label htmlFor="recipient-name" className="col-form-label">
            Banco:
          </label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            placeholder="Especialidad Principal"
            name="bank"
            value={bank}
            onChange={(e) => handleChange(e)}
          >
            {banks.map((item, i) => (
              <option key={i}>{item.name}</option>
            ))}
          </select>
          <label htmlFor="recipient-name" className="col-form-label">
            Número de la cuenta:
          </label>
          <input
            type="text"
            className="form-control"
            id="recipient-name"
            name="number"
            value={number}
            onChange={(e) => handleChange(e)}
          />
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div className="buttons-container">
          {selectedItem !== null ? (
            <Button
              variant="primary"
              className="add-button"
              type="button"
              onClick={handleUpdate}
            >
              Actualizar
            </Button>
          ) : (
            <Button
              variant="primary"
              className="add-button"
              type="button"
              onClick={handleSubmit}
            >
              Agregar
            </Button>
          )}

          <Button
            type="button"
            variant="secondary"
            className="close-button"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  selectedItem: state.accounts.selectedItem,
});
export default connect(mapStateToProps, {
  addBankAccount,
  updateBankAccount,
  deleteSelectedItem,
})(ModalAccount);
