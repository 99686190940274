import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { addShoppingItem } from '../../../redux/actions/shopping.cart.action';
import { addPackageKtm } from '../../../redux/actions/packagektm.action';
import { addTelemedicinePlan } from '../../../redux/actions/telemedicine.plan.action';
import useGifcardState from './state/useGifcardState';
import CodeValidationSuccess from './CodeValidationSuccess';
import GiftCardCodeDetails from './GiftCardCodeDetails';
import { Col, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const GiftCards = () => {
  const {
    orderNumber,
    handleChangeOrderNumber,
    handleValidOrderNumber,
    isLoading,
    giftCards,
    handleClearGiftCardsState,
    handleChangeDisbleButtons,
    isDisableInputOrderNumber,
    isDisableValidButton,
    handleGetCode,
    isLoadingGetCode,
    giftCard,
    name,
    cellphone,
    email,
    handleChangeName,
    handleChangeCellphone,
    handleChangeEmail,
    handlePrintGiftCards,
  } = useGifcardState();

  if (giftCard) {
    return (
      <GiftCardCodeDetails
        giftCards={giftCard}
        handlePrintGiftCards={handlePrintGiftCards}
      />
    );
  }

  return (
    <div className="user-dashboard mt-4">
      <div className="row mt-3">
        <div className="col-md-6 col-xs-12">
          <div className="sales mb-4">
            <div className="profile-header">
              <h3 className="text-white text-center pt-4">Validar factura</h3>
            </div>
            <div className="container mt-3">
              <form style={{ padding: 20 }} onSubmit={handleValidOrderNumber}>
                <div className="form-group">
                  <label className="text-primary font-weight-bold">
                    Ingrese N° de orden
                  </label>
                  <Col
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Form style={{ width: '60%' }}>
                      <Form.Group>
                        {/* <Form.Control
                          type="text"
                          name={orderNumber}
                          onChange={handleChangeOrderNumber}
                          disabled={isDisableInputOrderNumber}
                        /> */}
                        <NumberFormat
                          onChange={handleChangeOrderNumber}
                          value={orderNumber}
                          type="text"
                          format="###-###-##-########"
                          name={orderNumber}
                          mask="_"
                          className="form-control"
                          disabled={isDisableInputOrderNumber}
                        />
                        <Form.Text style={{ textAlign: 'left' }}>
                          Ingrese el número de factura el cual puede tener el
                          siguiente formato: 999-999-9-99999999 /
                          999-999-99-9999999
                        </Form.Text>
                      </Form.Group>
                    </Form>
                  </Col>
                </div>
                <div
                  style={{
                    marginTop: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold btn-block"
                    onClick={handleValidOrderNumber}
                    disabled={
                      isDisableValidButton || orderNumber.trim().length === 0
                    }
                  >
                    {isLoading ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation="border"
                      />
                    ) : (
                      'VALIDAR'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          {giftCards.length > 0 && (
            <CodeValidationSuccess
              giftCards={giftCards}
              handleClearGiftCardsState={handleClearGiftCardsState}
              handleChangeDisbleButtons={handleChangeDisbleButtons}
              isLoading={isLoadingGetCode}
              handleSubmit={handleGetCode}
              name={name}
              cellphone={cellphone}
              email={email}
              handleChangeName={handleChangeName}
              handleChangeCellphone={handleChangeCellphone}
              handleChangeEmail={handleChangeEmail}
            />
          )}
        </div>
        <></>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  prices: state.prices.items,
  token: state.auth.token,
  packagektm: state.packagektm.packageKtm,
});
export default connect(mapStateToProps, {
  addShoppingItem,
  addPackageKtm,
  addTelemedicinePlan,
})(GiftCards);
