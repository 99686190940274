import { GET_PLANS } from '../types';
import axios from 'axios';

export const getAllPlans = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/packagektm/all');

    dispatch({
      type: GET_PLANS,
      payload: res.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};
