import axios from 'axios';

export const urlChat = '/api/chat';

export const getListMessages = ({token, idVideo}) => new Promise((resolve, reject) => {
  if (token && idVideo) {
    axios.get(`${urlChat}/messages/${idVideo}`, {
      headers: {
        Authorization: token,
      },
    }).then((response) => {
      const {data} = response;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  } else if (!idVideo) {
    reject({
      status: 'error',
      info: 'Identificador de la videollamada no esta definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Ninguno de los parametros esta definido',
    });
  }
});

export const uploadChatFile = ({token, file}) => new Promise((resolve, reject) => {
  if (token && file) {
    axios.post(`${urlChat}/upload`, file, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      const {data} = response;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => {
      reject(error);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no esta definido',
    });
  } else if (!file) {
    reject({
      status: 'error',
      info: 'Archivo no esta definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error verifique los parametros de la función',
    });
  }
});

export const getChatFile = ({token, filename}) => new Promise((resolve, reject) => {
  if (token && filename) {
    axios.get(`${urlChat}/file/:filename`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no esta definido',
    });
  } else if (!filename) {
    reject({
      status: 'error',
      info: 'Error el nombre del archivo no esta definido',
    });
  }
});
