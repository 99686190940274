/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SelectUser from '../../Components/SelectUser';
import Layout from '../../layouts/PrincipalLayout/PrincipalLayout';

const Select = ({ isAuth }) => {
  if (isAuth) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Layout>
      <SelectUser />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});
export default connect(mapStateToProps)(Select);
