import axios from 'axios';
import { getProfileReference, getReference } from './references';
import {
  GET_SEARCH_PATIENT,
  SEARCH_PATIENT,
  GET_QUERY_HISTORY,
  HISTORY_SELECT,
  SEARCH_PATIENT_PROFILE,
  SEARCH_PATIENT_IMG,
  CHANGE_SEARCH_PATIENT,
  GET_SEARCH_PATIENT_COUNT,
} from '../types';

export const changeSearch = (searching) => async (dispatch) => {
  dispatch({
    type: CHANGE_SEARCH_PATIENT,
    payload: searching,
  });
};
export const searchPatients = (text) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const mySearch = text.trim().split(' ');
    mySearch.forEach(async (element) => {
      const doc = JSON.stringify({
        search: element,
      });
      const res = await axios.post(
        '/api/patient/history/searchPatient',
        doc,
        config
      );
      dispatch({
        type: GET_SEARCH_PATIENT,
        payload: res.data,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const searchPatientsPagination = (text, page) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const mySearch = text.trim();

    const doc = JSON.stringify({
      search: mySearch,
      page: page,
    });
    const res = await axios.post(
      '/api/patient/history/searchPatientPagination',
      doc,
      config
    );
    dispatch({
      type: GET_SEARCH_PATIENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const clearSearchPatientsPagination = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SEARCH_PATIENT,
      payload: [],
    });
    dispatch({
      type: GET_SEARCH_PATIENT_COUNT,
      payload: null,
    });
  } catch (error) {
    console.log(error);
  }
};

export const searchPatientsCount = (text) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const mySearch = text.trim();

    const doc = JSON.stringify({
      search: mySearch,
    });
    const res = await axios.post(
      '/api/patient/history/searchPatientCount',
      doc,
      config
    );
    dispatch({
      type: GET_SEARCH_PATIENT_COUNT,
      payload: res.data.Numero,
    });
  } catch (error) {
    console.log(error);
  }
};

export const searchPatientID =
  (id, profile, image, label, gender, history) => async (dispatch) => {
    profile.name = label;
    profile.gender = gender;
    dispatch({
      type: SEARCH_PATIENT,
      payload: id,
    });
    dispatch({
      type: SEARCH_PATIENT_PROFILE,
      payload: profile,
    });
    dispatch({
      type: SEARCH_PATIENT_IMG,
      payload: image,
    });
    await dispatch(getReference(id));
    await dispatch(getProfileReference(id));
    if (history.location.pathname === '/dashboard/historial/') {
      history.push(`?q=${id}`);
    } else {
      history.push(`/dashboard/historial/?q=${id}`);
    }
  };

export const getQueryHistory = (desde, hasta, id) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(
      `/api/patient/history/medicalHistory/${desde}/${hasta}/${id}`
    );
    dispatch({
      type: GET_QUERY_HISTORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const SelectItemHistory = (item) => async (dispatch) => {
  dispatch({
    type: HISTORY_SELECT,
    payload: item,
  });
};
