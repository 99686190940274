import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useFarinterSelector from '../../../../../hooks/useFarinterSelector';
import {
  getAllAppointmentByUser,
  addSelectedAppointment,
  clearAllAppointment,
} from '../../../../../redux/actions/references';
import { handleChangeFollowUpModal } from '../../../../../redux/actions/ui.action';

const useFollowUpAppointmentState = () => {
  const dispatch = useDispatch();
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [isLoadingAppointment, seIsLoadingAppointment] = useState(false);
  const [tempAppointment, setTempAppoinment] = useState(null);
  const {
    ui,
    presential,
    reference: { appointments },
  } = useFarinterSelector();
  const { user } = presential;

  const { modalFollowUpAppointment } = ui;

  const handleOpenmodal = () => {
    dispatch(handleChangeFollowUpModal(true));
  };
  const handleCloseModal = () => {
    dispatch(handleChangeFollowUpModal(false));
    setIsFollowUp(false);
    setTempAppoinment(null);
    dispatch(clearAllAppointment());
  };
  const handleChangeIsFolowUp = () => {
    setIsFollowUp(!isFollowUp);
  };

  const handleGetAppointmentData = () => {
    handleLoadingAppointmentChange(true);
    user && dispatch(getAllAppointmentByUser(user._id));
    handleLoadingAppointmentChange(false);
  };

  const handleLoadingAppointmentChange = (value) => {
    seIsLoadingAppointment(value);
  };

  const handleChangeSelectedAppointment = (e) => {
    const appointment = appointments.filter(
      (item) => item._id === e.target.value
    );

    if (appointment.length === 1) {
      setTempAppoinment(appointment[0]);
    }
  };

  const handleGetType = (type) => {
    if (type === 'lab') {
      return 'servicio laboratorio';
    } else if (type === 'call') {
      return 'Llamada telefónica';
    } else if (type === 'videocall') {
      return 'Videollamada';
    } else if (type === 'presencial') {
      return 'Presencial';
    } else {
      return 'whatsapp';
    }
  };

  const handleAddToOrder = () => {
    dispatch(addSelectedAppointment(tempAppointment));
    handleCloseModal();
    setIsFollowUp(false);
    setTempAppoinment(null);
  };

  return {
    handleOpenmodal,
    handleCloseModal,
    open: modalFollowUpAppointment,
    isFollowUp,
    handleChangeIsFolowUp,
    isLoadingAppointment,
    handleLoadingAppointmentChange,
    handleGetAppointmentData,
    handleChangeSelectedAppointment,
    handleAddToOrder,
    tempAppointment,
    setTempAppoinment,
    handleGetType,
  };
};

export default useFollowUpAppointmentState;
