/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  GET_PRESENTIAL_USER_REFERENCE,
  DELETE_PRESENTIAL_USER_REFERENCE,
  SET_TRUE_PRESENTIAL_VITAL_SIGNS,
  SET_FALSE_PRESENTIAL_VITAL_SIGNS,
  SET_TRUE_PHYSICAL_EXAM,
  SET_FALSE_PHYSICAL_EXAM,
  SET_TRUE_PRESENTIAL_ADD_USER,
  SET_FALSE_PRESENTIAL_ADD_USER,
  SET_TRUE_COMPLETE,
  SET_FALSE_COMPLETE,
  RESET_PRESENTIAL_STATE,
  SET_TRUE_PRESENTIAL_PROFILE,
  SET_FALSE_PRESENTIAL_PROFILE,
  ADD_PRESENTIAL_USER,
  DELETE_PRESENTIAL_NURSE_DETAILS,
  DELETE_PRESENTIAL_VITALS,
  DELETE_PRESENTIAL_PHYSICAL,
  ADD_PRESENTIAL_VITALS,
  ADD_PRESENTIAL_PHYSICAL,
  ADD_PRESENTIAL_NURSE_DETAILS,
  SET_TRUE_PRESENTIAL_NURSE_DETAILS,
  SET_FALSE_PRESENTIAL_NURSE_DETAILS,
  TRUE_LOADING_PACKAGE,
  FALSE_LOADING_PACKAGE,
  HANDLE_CHANGE_SERVICE,
  ADD_USER_REFERENCE,
  ADD_USER_FLAG_BY_CHECKER,
} from '../types';
import createAlert from './alert.action';

export const getPresentialUserReference = (dni, idType) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    dni,
    idType,
  });

  try {
    const res = await axios.post('/api/users/select/dni', body, config);
    if (res.status === 200 && res.data.status === 'success') {
      dispatch({
        type: GET_PRESENTIAL_USER_REFERENCE,
        payload: res.data.user,
      });
      dispatch({
        type: SET_TRUE_PRESENTIAL_PROFILE,
      });
    }

    if (res.status === 200 && res.data.status === 'error') {
      Swal.fire({
        title: 'Usuario no encontrado',
        text: 'El usuario no se encuentra registrado. ¿Deseas registrarlo?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch({
            type: SET_TRUE_PRESENTIAL_ADD_USER,
          });
        }
      });
    }

    if (res.status === 200 && res.data.status === 'found') {
      createAlert('Error', res.data.message, 'error');
    }
  } catch (error) {
    console.log(error);
  }
};

export const addUserByCheker =
  ({
    idType,
    dni,
    firstName,
    lastName,
    email,
    gender,
    bornAt,
    cellphone,
    country,
    dialCode,
    registerType,
    ischecker,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      if (registerType === 'email') {
        const tempDni = dni.replace(/-/g, '').replace(/_/g, '');

        const body = JSON.stringify({
          idType: idType,
          gender: gender,
          email: email,
          dni: tempDni,
          firstName: firstName,
          lastName: lastName,
          bornAt: bornAt,
          cellphone: cellphone,
          country: country,
          dialCode: dialCode,
          registerType: registerType,
        });

        const res = await axios.post('/api/users/presential', body, config);

        dispatch({
          type: ADD_USER_REFERENCE,
          payload: res.data,
        });

        dispatch({
          type: ADD_USER_FLAG_BY_CHECKER,
          payload: false,
        });
        createAlert('Completado!', 'Se agrego el usuario', 'success').then(
          () => {
            if (ischecker) {
              window.location.reload();
            }
          }
        );
      } else if (registerType === 'cellphone') {
        const tempDni = dni.replace(/-/g, '').replace(/_/g, '');

        const body = JSON.stringify({
          idType: idType,
          gender: gender,
          email: email,
          dni: tempDni,
          firstName: firstName,
          lastName: lastName,
          bornAt: bornAt,
          cellphone: cellphone,
          country: country,
          dialCode: dialCode,
          registerType: registerType,
        });

        const res = await axios.post('/api/users/presential', body, config);
        dispatch({
          type: ADD_USER_REFERENCE,
          payload: res.data,
        });

        createAlert('Completado!', 'Se agrego el usuario', 'success').then(
          () => {
            if (ischecker) {
              window.location.reload();
            }
          }
        );
      }
    } catch (error) {
      createAlert('Error', error.response.data.message, 'error');
    }
  };

export const addPresentialPatient =
  ({
    idType,
    dni,
    firstName,
    lastName,
    email,
    gender,
    bornAt,
    cellphone,
    otherContact,
    country,
    dialCode,
    registerType,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      idType: idType,
      gender: gender,
      email: email,
      dni: dni.replace(/-/g, '').replace(/_/g, ''),
      firstName: firstName,
      lastName: lastName,
      bornAt: bornAt,
      cellphone: cellphone,
      otherContact: otherContact,
      country: country,
      dialCode: dialCode,
      registerType: registerType,
    });
    try {
      const res = await axios.post('/api/users/presential', body, config);

      dispatch({
        type: ADD_PRESENTIAL_USER,
        payload: res.data,
      });
      dispatch({
        type: SET_TRUE_PHYSICAL_EXAM,
      });

      dispatch({
        type: SET_TRUE_PRESENTIAL_PROFILE,
      });
      dispatch({
        type: SET_FALSE_PRESENTIAL_ADD_USER,
      });

      createAlert('Completado!', 'Se agrego el usuario', 'success');
    } catch (error) {
      createAlert('Error', error.response.data.message, 'error');
    }
  };

export const deletePresentialUserReference = () => (dispatch) => {
  dispatch({
    type: DELETE_PRESENTIAL_USER_REFERENCE,
  });
};

export const trueLoadingPresential = () => (dispatch) => {
  dispatch({
    type: TRUE_LOADING_PACKAGE,
  });
};

export const falseLoadingPresential = () => (dispatch) => {
  dispatch({
    type: FALSE_LOADING_PACKAGE,
  });
};

export const setTruePresentialAddUser = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_PRESENTIAL_ADD_USER,
  });
};

export const setFalsePresentialAddUser = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_PRESENTIAL_ADD_USER,
  });
};

export const setTruePresentialVitalSigns = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_PRESENTIAL_VITAL_SIGNS,
  });
};

export const setFalsePresentialVitalSigns = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_PRESENTIAL_VITAL_SIGNS,
  });
};

export const setTruePresentialPhysicalExam = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_PHYSICAL_EXAM,
  });
};

export const setFalsePresentialPhysicalExam = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_PHYSICAL_EXAM,
  });
};

export const setTruePresentialComplete = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_COMPLETE,
  });
};

export const setFalsePresentialComplete = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_COMPLETE,
  });
};

export const resetPresentialState = () => (dispatch) => {
  dispatch({
    type: RESET_PRESENTIAL_STATE,
  });
};

export const setTruePresentialProfile = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_PRESENTIAL_PROFILE,
  });
};
export const setTruePresentialNurseDetails = () => (dispatch) => {
  dispatch({
    type: SET_TRUE_PRESENTIAL_NURSE_DETAILS,
  });
};

export const setFalsePresentialNurseDetails = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_PRESENTIAL_NURSE_DETAILS,
  });
};

export const setFalsePresentialProfile = () => (dispatch) => {
  dispatch({
    type: SET_FALSE_PRESENTIAL_PROFILE,
  });
};

// delete reference steps

export const deletePresentialNurseDetails = () => (dispatch) => {
  dispatch({
    type: DELETE_PRESENTIAL_NURSE_DETAILS,
  });
};

export const deletePresentialPhysical = () => (dispatch) => {
  dispatch({
    type: DELETE_PRESENTIAL_PHYSICAL,
  });
};

export const deletePresentialVitals = () => (dispatch) => {
  dispatch({
    type: DELETE_PRESENTIAL_VITALS,
  });
};

// add reference steps
export const addPresentialPhysical = (formData) => (dispatch) => {
  dispatch({
    type: ADD_PRESENTIAL_PHYSICAL,
    payload: formData,
  });
  dispatch({
    type: SET_TRUE_PRESENTIAL_VITAL_SIGNS,
  });
};

export const addPresentialVitals = (formData) => (dispatch) => {
  dispatch({
    type: ADD_PRESENTIAL_VITALS,
    payload: formData,
  });
  dispatch({
    type: SET_TRUE_COMPLETE,
  });
};

export const addPresentialNurseDetails = (formData) => (dispatch) => {
  dispatch({
    type: ADD_PRESENTIAL_NURSE_DETAILS,
    payload: formData,
  });
  dispatch({
    type: SET_TRUE_COMPLETE,
  });
};

export const addPreclinic =
  ({ user, preClinic: { vitalSings }, service, selectedAppointment }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      if (service === 'presential') {
        const tempVitals = {
          temperature: parseFloat(vitalSings.temperature),
          pressure: {
            initial: parseInt(vitalSings.initialPressure, 10),
            final: parseInt(vitalSings.finalPressure, 10),
          },
          breathingFrequency: parseInt(vitalSings.breathingFrequency, 10),
          heartRate: parseInt(vitalSings.heartRate, 10),
          saturation: parseInt(vitalSings.saturation, 10),
          weight: parseFloat(vitalSings.weight),
          height: parseFloat(vitalSings.height),
          imc: parseFloat(vitalSings.imc),
        };

        const body = JSON.stringify({
          patient: user._id,
          vitalSings: tempVitals,
          consultationType: service,
          selectedAppointment,
        });

        await axios.post('/api/preclinic/add', body, config);

        createAlert(
          'Completado!!',
          'La preclínica se agrego de forma correcta!!',
          'success'
        );
      } else {
        const body = JSON.stringify({
          patient: user._id,
          consultationType: service,
          selectedAppointment,
        });

        await axios.post('/api/preclinic/add', body, config);
        createAlert(
          'Completado!!',
          'Se creo la orden de forma correcta!!',
          'success'
        );
      }

      dispatch({
        type: RESET_PRESENTIAL_STATE,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

export const handleChangeService = (text) => ({
  type: HANDLE_CHANGE_SERVICE,
  payload: text,
});
