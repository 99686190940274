import { useDispatch, useSelector } from "react-redux";
import {
  setModalSearchPatient,
  setModalCarnet,
} from "../redux/actions/ui.action";

export const useUI = () => {
  const dispatch = useDispatch();
  const { modalCarnet, modalSearchPatient } = useSelector((state) => state.ui);

  const handleOpenSearchModal = () => {
    dispatch(setModalSearchPatient(true));
  };
  const handleCloseSearchModal = () => {
    dispatch(setModalSearchPatient(false));
  };

  const handleOpenModalCarnet = () => {
    dispatch(setModalCarnet(true));
  };
  const handleCloseModalCarnet = () => {
    dispatch(setModalCarnet(false));
  };

  return {
    modalCarnet,
    modalSearchPatient,
    handleOpenSearchModal,
    handleCloseSearchModal,
    handleOpenModalCarnet,
    handleCloseModalCarnet,
  };
};
