import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useFarinterSelector from '../../../../hooks/useFarinterSelector';
import {
  addSelectedAppointment,
  getAllAppointmentByUser,
} from '../../../../redux/actions/references';

const useModalAddConsultingState = () => {
  const dispatch = useDispatch();
  const { reference } = useFarinterSelector();
  const { userRef, appointments } = reference;
  const [isChecked, setIsChecked] = useState(false);
  const [tempAppointment, setTempAppoinment] = useState(null);

  const getAllUserAppointment = () => {
    if (userRef) {
      dispatch(getAllAppointmentByUser(userRef._id));
    }
  };

  const handleChangeSelectedAppointment = (e) => {
    const appointment = appointments.filter(
      (item) => item._id === e.target.value
    );

    if (appointment.length === 1) {
      dispatch(addSelectedAppointment(appointment[0]));
    }
  };

  const handleGetType = (type) => {
    if (type === 'lab') {
      return 'Servicio laboratorio';
    } else if (type === 'call') {
      return 'Llamada telefónica';
    } else if (type === 'videocall') {
      return 'Videollamada';
    } else if (type === 'presencial') {
      return 'Presencial';
    } else {
      return 'whatsapp';
    }
  };
  const handleIsChecked = () => {
    setIsChecked(!isChecked);
  };
  const handleDeleteAppointmentReference = () => {
    dispatch(addSelectedAppointment(null));
  };

  return {
    getAllUserAppointment,
    handleChangeSelectedAppointment,
    handleGetType,
    handleIsChecked,
    isChecked,
    handleDeleteAppointmentReference,
  };
};

export default useModalAddConsultingState;
