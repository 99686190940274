import socket from 'socket.io-client';
import { createConnection, closeConnection } from '../api/connections';
import {
  addConnection,
  addCurrentConnection,
  deleteAllConnections,
  deleteCurrentConnection,
  getListConnections,
} from '../extra/arrayConnection';

const io = socket();

io.on('connect', function connect() {
  const token = localStorage.getItem('token');
  const socketId = this.id;
  // verificando conexines anteriores
  const listConnections = getListConnections();
  if (listConnections.length > 0) {
    listConnections.forEach((element) => {
      closeConnection({ socketId: element, token: token });
    });
    deleteAllConnections();
  }
  // almacenando la nueva conexxion activa

  if (token) {
    createConnection({
      token,
      socketId,
    }).then(() => {
      addCurrentConnection(socketId);
    });
  } else {
    addCurrentConnection(socketId);
  }
});

io.on('disconnect', function disconnect() {
  const socketId = this.id;
  addConnection(socketId);
  deleteCurrentConnection();
});

export default io;
