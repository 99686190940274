/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  GET_INCOME,
  GET_TYPE,
  GET_GENDER,
  GET_COUNTRY,
  GET_DURATION,
  GET_RATING,
  GET_AGE,
  GET_QUERY_DETAIL,
  DETAIL_SELECT,
  DETAIL_HISTORY,
} from '../types';

export const getGraphicIncome = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/income/${desde}/${hasta}`);
    dispatch({
      type: GET_INCOME,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicType = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/typeCall/${desde}/${hasta}`);
    dispatch({
      type: GET_TYPE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicGender = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/genderCall/${desde}/${hasta}`);
    dispatch({
      type: GET_GENDER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicCountry = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/countryCall/${desde}/${hasta}`);
    dispatch({
      type: GET_COUNTRY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicDuration = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/durationCall/${desde}/${hasta}`);
    dispatch({
      type: GET_DURATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicRating = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/rating/${desde}/${hasta}`);
    dispatch({
      type: GET_RATING,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGraphicAge = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/age/${desde}/${hasta}`);
    dispatch({
      type: GET_AGE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getQueryDetail = (desde, hasta, role) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/graphic/queryDetail/${role}/${desde}/${hasta}`);
    dispatch({
      type: GET_QUERY_DETAIL,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getdetailHistory = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/graphic/detailHistory/${id}`);
    dispatch({
      type: DETAIL_HISTORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSelectItem = (item) => async (dispatch) => {
  dispatch({
    type: DETAIL_SELECT,
    payload: item,
  });
};
