// types para alertas
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// auth types
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_SPECIALTIES = 'GET_SPECIALTIES';
export const ERROR_SPECIALTIES = 'ERROR_SPECIALTIES';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const UPDATE_USER = 'UPDATE_USER';

// ------------- HISTORIAL DE PACIENTES ---------------------------

// clear history

export const CLEAR_HISTORY = 'CLEAR_HISTORY';

// medicinas
export const GET_MEDICINES = 'GET_MEDICINES';
export const ADD_MEDICINE = 'ADD_MEDICINE';
export const ERROR_ADD_MEDICINE = 'ERROR_ADD_MEDICINE';
export const DELETE_MEDICINE = 'DELETE_MEDICINE';
export const UPDATE_MEDICINE = 'UPDATE_MEDICINE';
export const ADD_SELECTED_ITEM = 'ADD_SELECTED_ITEM';
export const DELETE_SELECTED_ITEM = 'DELETE_SELECTED_ITEM';

// antecedestes salud
export const GET_HEALTH = 'GET_HEALTH';
export const ADD_HEALTH = 'ADD_HEALTH';
export const DELETE_HEALTH = 'DELETE_HEALTH';
export const GET_HEALTH_DEF = 'GET_HEALTH_DEF';
export const UPDATE_DEF_HEALTH_CHECK = 'UPDATE_DEF_HEALTH_CHECK';
export const SET_SELECTED_HEALTH = 'SET_SELECTED_HEALTH';
export const UPDATE_HEALTH = 'UPDATE_HEALTH';

// alergias
export const GET_ALLERGIES = 'GET_ALLERGIES';
export const ADD_ALLERGIE = 'ADD_ALLERGIE';
export const DELETE_ALLERGIE = 'DELETE_ALLERGIE';
export const UPDATE_ALLERGIE = 'UPDATE_ALLERGIE';
export const SELECTED_ALLERGIE = 'SELECTED_ALLERGIE';
export const DELETE_OTHER_ALLERGIE = 'DELETE_OTHER_ALLERGIE';
export const GET_ALLERGIES_DEF = 'GET_ALLERGIES_DEF';
export const UPDATE_DEF_ALLERGIES_CHECK = 'UPDATE_DEF_ALLERGIES_CHECK';

// quirutgicos
export const GET_SURGERIES = 'GET_SURGERIES';
export const ADD_SURGERIES = 'ADD_SURGERIES';
export const DELETE_SURGERIES = 'DELETE_SURGERIES';
export const UPDATE_SURGERIES = 'UPDATE_SURGERIES';
export const ERROR_SURGERIES = 'ERROR_SURGERIES';
export const ADD_ITEM_SURGERIES = 'ADD_ITEM_SURGERIES';
export const DELETE_ITEM_SURGERIES = 'DELETE_ITEM_SURGERIES';

// vaccinate
export const GET_VACCINATE = 'GET_VACCINATE';
export const ADD_VACCINATE = 'ADD_VACCINATE';
export const DELETE_VACCINATE = 'DELETE_VACCINATE';
export const UPDATE_VACCINATE = 'UPDATE_VACCINATE';
export const SELECTED_VACCINATE = 'SELECTED_VACCINATE';
export const DELETE_OTHER_VACCINATE = 'DELETE_OTHER_VACCINATE';
export const GET_VACCINATE_DEF = 'GET_VACCINATE_DEF';
export const UPDATE_DEF_VACCINATE_CHECK = 'UPDATE_DEF_VACCINATE_CHECK';

// clinical
export const GET_CLINICAL = 'GET_CLINICAL';
export const ADD_CLINICAL = 'ADD_CLINICAL';
export const DELETE_CLINICAL = 'DELETE_CLINICAL';

// lifestyle
export const GET_LIFESTYLE = 'GET_LIFESTYLE';
export const ADD_LIFESTYLE = 'ADD_LIFESTYLE';
export const DELETE_LIFESTYLE = 'DELETE_LIFESTYLE';
export const GET_ALL_LIFE_STYLE_DEF = 'GET_ALL_LIFE_STYLE_DEF';
export const UPDATE_DEF_LIFESTYLE_CHECK = 'UPDATE_DEF_LIFESTYLE_CHECK';

// antecedentes familiares
export const GET_FAMILIAR = 'GET_FAMILIAR';
export const ADD_FAMILIAR = 'ADD_FAMILIAR';
export const DELETE_FAMILIAR = 'DELETE_FAMILIAR';
export const UPDATE_FAMILIAR = 'UPDATE_FAMILIAR';
export const ERROR_ADD_FAMILIAR = 'ERROR_ADD_FAMILIAR';
export const ADD_SELECTED_FAMILIAR = 'ADD_SELECTED_FAMILIAR';
export const DELETE_SELECTED_FAMILIAR = 'DELETE_SELECTED_FAMILIAR';
export const UPDATE_OTHER_FAMILIAR = 'UPDATE_OTHER_FAMILIAR';
export const ENABLE_OTHER_FAMILIAR = 'ENABLE_OTHER_FAMILIAR';
export const DISABLE_OTHER_FAMILIAR = 'DISABLE_OTHER_FAMILIAR';
export const GET_FAMILIAR_DEF = 'GET_FAMILIAR_DEF';
export const UPDATE_DEF_FAMILIAR_CHECK = 'UPDATE_DEF_FAMILIAR_CHECK';

export const ADD_ITEMS_FRONTEND_FAMILIAR = 'ADD_ITEMS_FRONTEND_FAMILIAR';
export const UPDATE_ITEMS_FRONTEND_FAMILIAR = 'ADD_ITEMS_FRONTEND_FAMILIAR';

// historial de consultas medicas
export const GET_QUERY_HISTORY = 'GET_QUERY_HISTORY';
export const HISTORY_SELECT = 'HISTORY_SELECT';

// consulting prices
export const GET_PRICES_CONSULTING = 'GET_PRICES_CONSULTING';
export const DEFAULT_PRICE_SELECTED = 'DEFAULT_PRICE_SELECTED';
export const DELETE_DEFAULT_PRICE_SELECTED = 'DELETE_DEFAULT_PRICE_SELECTED';

// shopping cart
export const GET_SHOPPING_ITEMS = 'GET_SHOPPING_ITEMS';
export const DELETE_SHOPPING_ITEM = 'DELETE_SHOPPING_ITEMS';
export const ADD_SHOPPING_ITEM = 'ADD_SHOPPING_ITEM';
export const ADD_TOTAL_ITEM = 'ADD_TOTAL_ITEM';
export const DELETE_TOTAL_ITEM = 'DELETE_TOTAL_ITEM';
export const DELETE_ALL_SHOPPING_ITEMS = 'DELETE_ALL_SHOPPING_ITEMS';

// credit cards
export const GET_CREDIT_CARDS = 'GET_CREDIT_CARDS';
export const DELETE_CREDIT_CARDS = 'DELETE_CREDIT_CARDS';
export const ADD_CREDIT_CARDS = 'ADD_CREDIT_CARDS';
export const SET_DEFAULT_CREDIT_CARD = 'SET_DEFAULT_CREDIT_CARD';

// CONSULTATIONS
export const GET_CONSULTATION = 'GET_CONSULTATION';
export const REDUCE_CONSULTATION = 'REDUCE_CONSULTATION';
export const ADD_TOTAL_ITEM_CONSULTATION = 'ADD_TOTAL_ITEM_CONSULTATION';
export const DELETE_ITEM_CONSULTATION = 'DELETE_ITEM_CONSULTATION';
export const ADD_CONSULTATION = 'ADD_CONSULTATION';

// profile photo

export const UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO';
export const DELETE_PROFILE_PHOTO = 'DELETE_PROFILE_PHOTO';
export const GET_PROFILE_PHOTO = 'GET_PROFILE_PHOTO';

// banks accounts
export const GET_BANKS_ACCOUNTS = 'GET_BANKS_ACCOUNTS';
export const DELETE_BANKS_ACCOUNTS = 'DELETE_BANKS_ACCOUNTS';
export const UPDATE_BANKS_ACCOUNTS = 'UPDATE_BANKS_ACCOUNTS';
export const ADD_BANKS_ACCOUNTS = 'ADD_BANKS_ACCOUNTS';
export const ADD_SELECTED_BANK = 'ADD_SELECTED-BANK';
export const DELETE_SELECTED_BANK = 'DELETE_SELECTED-BANK';

//  profile doctor CV
export const UPLOAD_PROFILE_DOCTOR_CV = 'UPLOAD_PROFILE_DOCTOR_CV';
export const DELETE_PROFILE_DOCTOR_CV = 'DELETE_PROFILE_DOCTOR_CV';

// Graficas
export const GET_INCOME = 'GET_INCOME';
export const ERROR_INCOME = 'ERROR_INCOME';
export const GET_TYPE = 'GET_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';
export const GET_GENDER = 'GET_GENDER';
export const ERROR_GENDER = 'ERROR_GENDER';
export const GET_COUNTRY = 'GET_COUNTRY';
export const ERROR_COUNTRY = 'ERROR_COUNTRY';
export const GET_DURATION = 'GET_DURATION';
export const ERROR_DURATION = 'ERROR_DURATION';
export const GET_RATING = 'GET_RATING';
export const ERROR_RATING = 'ERROR_RATING';
export const GET_AGE = 'GET_AGE';
export const ERROR_AGE = 'ERROR_AGE';
export const GET_QUERY_DETAIL = 'GET_QUERY_DETAIL';
export const ERROR_QUERY_DETAIL = 'ERROR_QUERY_DETAIL';
export const DETAIL_SELECT = 'DETAIL_SELECT';
export const DETAIL_HISTORY = 'DETAIL_HISTORY';

//
export const UPLOAD_EXAM = 'UPLOAD_EXAM';
export const GET_EXAMS = 'GET_EXAMS';
export const DELETE_EXAM = 'DELETE_EXAM';

// scheduling
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const SHOW_DETAILS = 'SHOW_DETAILS';
export const HIDE_DETAILS = 'HIDE_DETAILS';
export const SET_STATUS_SCHEDULE = 'SET_STATUS_SCHEDULE';
export const SELECTED_DATE = 'SELECTED_DATE';
export const REAGENDAR = 'REAGENDAR';

// references

export const GET_PROFILE_REFERENCE = 'GET_PROFILE_REFERENCE';
export const DELETE_PROFILE_REFERENCE = 'DELETE_PROFILE_REFERENCE';

export const ADD_USER_REFERENCE = 'ADD_USER_REFERENCE';
export const DELETE_USER_REFERENCE = 'DELETE_USER_REFERENCE';

export const ADD_CONSULTORY_REFERENCE_OF_DOCTOR =
  'ADD_CONSULTORY_REFERENCE_OF_DOCTOR';

export const DELETE_CONSULTORY_REFERENCE_OF_DOCTOR =
  'DELETE_CONSULTORY_REFERENCE_OF_DOCTOR';

export const GET_ALL_APPOINTMENT_REFERENCE = 'GET_ALL_APPOINTMENT_REFERENCE';
export const ADD_SELECTED_APPOINTMENT = 'ADD_SELECTED_APPOINTMENT';

// PLAN REFERENCES

export const GET_PLAN_REFERENCE = 'GET_PLAN_REFERENCE';
export const CLEAN_PLAN_REFERENCE = 'CLEAN_PLAN_REFERENCE';

export const ADD_SELECTED_PLAN = 'ADD_SELECTED_PLAN';

// DEPENDENTS REFERENCE

export const GET_ALL_DEPENDENTS = 'GET_ALL_DEPENDENTS';
export const CLEAN_DEPENDENTS = 'CLEAN_DEPENDENTS';

// add user by checker when search a user and this doesn't exist
export const ADD_USER_FLAG_BY_CHECKER = 'ADD_USER_FLAG_BY_CHECKER';

// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

// Retiros

export const GET_AMOUNT_AVAILABLE = 'GET_AMOUNT_AVAILABLE';
export const GET_QUERY_RETREATS = 'GET_QUERY_RETREATS';

// videoconference
export const LOADING_VERIFY_CONSULT = 'LOADING_VERIFY_CONSULT';
export const ADD_VERIFY_CONSULT = 'ADD_VERIFY_CONSULT';
export const END_LOADING_VERIFY_CONSULT = 'END_LOADING_VERIFY_CONSULT';
export const REMOVE_VERIFY_CONSULT = 'REMOVE_VERIFY_CONSULT';
export const ADD_VIDEOCONFERENCE = 'ADD_VIDEOCONFERENCE';
export const DELETE_VIDEOCONFERENCE = 'DELETE_VIDEOCONFERENCE';

// horario del Doctor
export const ADDTIMETABLE = 'ADDTIMETABLE';
export const DELETETIMETABLE = 'DELETETIMETABLE';

// seleccionar que tipo de cita se desea
export const SELECT_PRESENTIAL_CONSULTATION = 'SELECT_PRESENTIAL_CONSULTATION';
export const SELECT_ONLINE_CONSULTATION = 'SELECT_ONLINE_CONSULTATION';

// UI

export const OPEN_MODAL_SCHEDULE_DOCTOR_LIST =
  'OPEN_MODAL_SCHEDULE_DOCTOR_LIST';
export const CLOSE_MODAL_SCHEDULE_DOCTOR_LIST =
  'CLOSE_MODAL_SCHEDULE_DOCTOR_LIST';
export const OPEN_MODAL_PROFILE_IMAGE = 'OPEN_MODAL_PROFILE_IMAGE';
export const CLOSE_MODAL_PROFILE_IMAGE = 'CLOSE_MODAL_PROFILE_IMAGE';

export const OPEN_MODAL_CANCEL_APPOINTMENT = 'OPEN_MODAL_CANCEL_APPOINTMEN';
export const CLOSE_MODAL_CANCEL_APPOINTMENT = 'CLOSE_MODAL_CANCEL_APPOINTMEN';

export const OPEN_MODAL_ADD_SCHEDULING = 'OPEN_MODAL_ADD_SCHEDULING';
export const CLOSE_MODAL_ADD_SCHEDULING = 'CLOSE_MODAL_ADD_SCHEDULING';
export const OPEN_MODAL_REAGENDAR = 'OPEN_MODAL_REAGENDAR';
export const CLOSE_MODAL_REAGENDAR = 'CLOSE_MODAL_REAGENDAR';
export const OPEN_MODAL_SCHEDULING_DETAILS = 'OPEN_MODAL_SCHEDULING_DETAILS';
export const CLOSE_MODAL_SCHEDULING_DETAILS = 'CLOSE_MODAL_SCHEDULING_DETAILS';

export const SET_MODAL_SEARCH_PATIENT = 'SET_MODAL_SEARCH_PATIENT';
export const SET_MODAL_CARNET = 'SET_MODAL_CARNET';

// modal examen fisico
export const OPEN_MODAL_PHYSICAL_EXAM = 'OPEN_MODAL_PHYSICAL_EXAM';

export const OPEN_MODAL_CALLCENTER_PLAN = 'OPEN_MODAL_CALLCENTER_PLAN';

// obtener los timestables del doctor como referencia del paciente cuando desea seleccionar un horario
export const GET_DOCTOR_TIMETABLE = 'GET_DOCTOR_TIMETABLE';
export const DELETE_REFERENCE_TIMETABLE = 'DELETE_REFERENCE_TIMETABLE';

// SOCKETS TYPES

export const SOCKET_UPDATE_SCHEDULE = 'SOCKET_UPDATE_SCHEDULE';

// obtener el estado del horario del doctor  si esta ocupado un horario o no dependiendo de las citas
export const GET_STATUS_TIME_DOCTOR = 'GET_STATUS_TIME_DOCTOR';
export const DISABLE_LOADED_STATUS_TIME = 'DISABLE_LOADED_STATUS_TIME';

// obtener busqueda de pacientes
export const GET_SEARCH_PATIENT = 'GET_SEARCH_PATIENT';
export const GET_SEARCH_PATIENT_COUNT = 'GET_SEARCH_PATIENT_COUNT';
export const SEARCH_PATIENT = 'SEARCH_PATIENT';
export const CHANGE_SEARCH_PATIENT = 'CHANGE_SEARCH_PATIENT';

// physical Exams
export const ADD_PHYSICAL_EXAM = 'ADD_PHYSICAL_EXAM';
export const GET_PHYSICAL_EXAM = 'GET_PHYSICAL_EXAM';
export const SEARCH_PATIENT_PROFILE = 'SEARCH_PATIENT_PROFILE';
export const SEARCH_PATIENT_IMG = 'SEARCH_PATIENT_IMG';

// signs vitals
export const ADD_SIGNS_VITAL = 'ADD_SIGNS_VITAL';

// presential
export const GET_PRESENTIAL_USER_REFERENCE = 'GET_PRESENTIAL_USER_REFERENCE';
export const DELETE_PRESENTIAL_USER_REFERENCE =
  'DELETE_PRESENTIAL_USER_REFERENCE';
export const TRUE_LOADING_PACKAGE = 'TRUE_LOADING_PACKAGE';
export const FALSE_LOADING_PACKAGE = 'FALSE_LOADING_PACKAGE';

//clean all references

export const CLEAN_ALL_REFERENCES = 'CLEAN_ALL_REFERENCES';

export const SET_TRUE_PRESENTIAL_ADD_USER = 'SET_TRUE_PRESENTIAL_ADD_USER';
export const SET_FALSE_PRESENTIAL_ADD_USER = 'SET_FALSE_PRESENTIAL_ADD_USER';

export const SET_TRUE_PRESENTIAL_NURSE_DETAILS =
  'SET_TRUE_PRESENTIAL_NURSE_DETAILS';

export const SET_FALSE_PRESENTIAL_NURSE_DETAILS =
  'SET_FALSE_PRESENTIAL_NURSE_DETAILS';

export const SET_TRUE_PRESENTIAL_VITAL_SIGNS =
  'SET_TRUE_PRESENTIAL_VITAL_SIGNS';
export const SET_FALSE_PRESENTIAL_VITAL_SIGNS =
  'SET_FALSE_PRESENTIAL_VITAL_SIGNS';

export const SET_TRUE_PHYSICAL_EXAM = 'SET_TRUE_PHYSICAL_EXAM';
export const SET_FALSE_PHYSICAL_EXAM = 'SET_FALSE_PHYSICAL_EXAM';

export const SET_TRUE_COMPLETE = 'SET_TRUE_COMPLETE';
export const SET_FALSE_COMPLETE = 'SET_FALSE_COMPLETE';

export const RESET_PRESENTIAL_STATE = 'RESET_PRESENTIAL_STATE';

export const SET_TRUE_PRESENTIAL_PROFILE = 'SET_TRUE_PRESENTIAL_PROFILE';
export const SET_FALSE_PRESENTIAL_PROFILE = 'SET_FALSE_PRESENTIAL_PROFILE';

export const ADD_PRESENTIAL_USER = 'ADD_PRESENTIAL_USER';

export const DELETE_PRESENTIAL_VITALS = 'DELETE_PRESENTIAL_VITALS';
export const DELETE_PRESENTIAL_PHYSICAL = 'DELETE_PRESENTIAL_PHYSICAL';
export const DELETE_PRESENTIAL_NURSE_DETAILS =
  'DELETE_PRESENTIAL_NURSE_DETAILS';

export const ADD_PRESENTIAL_PHYSICAL = 'ADD_PRESENTIAL_PHYSICAL';
export const ADD_PRESENTIAL_VITALS = 'ADD_PRESENTIAL_VITALS';
export const ADD_PRESENTIAL_NURSE_DETAILS = 'ADD_PRESENTIAL_NURSE_DETAILS';

// Tipos para manejar los planes medicos
export const ADD_PACKAGEKTM = 'ADD_PACKAGEKTM';
export const REMOVE_PACKAGEKTM = 'REMOVE_PACKAGEKTM';

export const ADD_PRECLINIC = 'ADD_PRECLINIC';

export const GET_PLANS_REFERENCE = 'GET_PLANS_REFERENCE';

// CONSULTORY
export const GET_ALL_DOCTORS_BY_CONSULTORY = 'GET_ALL_DOCTORS_BY_CONSULTORY';
export const DELETE_ALL_DOCTORS_BY_CONSULTORY =
  'DELETE_ALL_DOCTORS_BY_CONSULTORY';

export const GET_CONSULTORIES = 'GET_CONSULTORIES';

export const DELETE_CONSULTORIES = 'DELETE_CONSULTORIES';

export const SELECT_CONSULTORY = 'SELECT_CONSULTORY';
export const RESET_SELECT_CONSULTORY = 'RESET_SELECT_CONSULTORY';

// para los planes de telemedicina

export const ADD_TELEMEDICINE = 'ADD_TELEMEDICINE';
export const DELETE_TELEMEDICINE = 'DELETE_TELEMEDICINE';

// SEARCH PATIENT
export const DELETE_REFERENCE_SEARCH_PATIENT =
  'DELETE_REFERENCE_SEARCH_PATIENT';

// REGISTER
export const CHECK_CELLLPHONE_NUMBER = 'CHECK_CELLPHONE_NUMBER';
export const OPEN_MODAL_CELLPHONE_VERIFICATION =
  'OPEN_MODAL_CELLPHONE_VERIFICATION';
export const CLOSE_MODAL_CELLPHONE_VERIFICATION =
  'CLOSE_MODAL_CELLPHONE_VERIFICATION';

export const SET_FOLLOW_UP = 'SET_FOLLOW_UP';
export const TYPE_FOLLOW_UP = 'TYPE_FOLLOW_UP';

// PRESENTIAL
export const HANDLE_CHANGE_SERVICE = 'HANDLE_CHANGE_SERVICE';

// PAGINATION
export const HANDLE_CHANGE_PAGINATION = 'HANDLE_CHANGE_PAGINATION';
export const HANDLE_NEXT_PAGINATION = 'HANDLE_NEXT_PAGINATION';
export const HANDLE_BACK_PAGINATION = 'HANDLE_BACK_PAGINATION';

// PLANS
export const GET_PLANS = 'GET_PLANS';

// ENTERPRISE

export const LIST_EMPLOYEES = 'LIST_EMPLOYEES';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const GIVE_EMPLOYEE = 'GIVE_EMPLOYEE';
export const DOWN_EMPLOYEE = 'DOWN_EMPLOYEE';
export const GIVE_ALL_EMPLOYEES = 'GIVE_ALL_EMPLOYEES';
export const DOWN_ALL_EMPLOYEES = 'DOWN_ALL_EMPLOYEES';
export const CHANGE_MODAL_DEPENDENTS = 'CHANGE_MODAL_DEPENDENTS';
export const LIST_DEPENDENTS_EMPLOYEE = 'LIST_DEPENDENTS_EMPLOYEE';

// Modal follow up
export const HANDLE_CHANGE_FOLLOW_UP_MODAL = 'HANDLE_CHANGE_FOLLOW_UP_MODAL';

// APPOINTMENT
export const CLEAR_ALL_APPOINTMENT = 'CLEAR_ALL_APPOINTMENT';
export const CLEAR_SELECTED_APPOINTMENT = 'CLEAR_SELECTED_APPOINTMENT';

// DOCTOR REFERENCE
export const GET_DOCTOR_REFERENCE = 'GET_DOCTOR_REFERENCE';
export const DELETE_DOCTOR_REFERENCE = 'DELETE_DOCTOR_REFERENCE';

//SUBSCRIPTION
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
