/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import moment from 'moment';
import {
  setFalsePresentialProfile,
  setTruePresentialVitalSigns,
  setTruePresentialComplete,
} from '../../../redux/actions/presential.action';
import '../../../stylesheets/presential.css';
import avatarTemp from '../../../assets/img/avatar1.jpg';
import useFollowUpAppointmentState from '../../../Components/Patient/Presential/FollowUpModal/state/useFollowUpAppointmentState';
import { getPlanReferenceById } from '../../../redux/actions/references';
import { changeStatusDoctor } from '../../../websokect/doctorWebsockets';

const ActivePlan = ({ patientId }) => {
  const { planRef } = useSelector((state) => state.reference);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlanReferenceById({ patientId }));
  }, []);

  if (planRef) {
    return (
      <div className='mt-4 d-flex flex-column align-items-center'>
        <h6 className='text-primary'>Detalles del Plan</h6>
        <h6 className='profile-secondary-text'>
          <label style={{ fontWeight: 500 }}>Nombre del plan</label>
          {` / ${planRef?.name}`}
        </h6>
        <h6 className='profile-secondary-text'>
          <label style={{ fontWeight: 500 }}>Fecha de vencimiento</label>
          {` / ${moment(planRef?.dueDate).format('LLLL')}`}
        </h6>
      </div>
    );
  } else {
    return <>No cuenta con plan activo</>;
  }
};

const PresentialUserDetails = ({
  userRef,
  setTruePresentialVitalSigns,
  loading,
  service,
  setTruePresentialComplete,
}) => {
  const { handleOpenmodal } = useFollowUpAppointmentState();
  const { user } = useSelector((state) => state.auth);

  const handleNextStep = () => {
    changeStatusDoctor({
      doctorId: user._id,
      status: 'occupied',
      role: 'doctor',
    });
    handleOpenmodal();
    if (service === 'presential') {
      setTruePresentialVitalSigns();
    } else {
      setTruePresentialComplete();
    }
  };

  const generateIdType = () => {
    if (userRef?.idType === 'national') {
      return 'ID nacional';
    } else if (userRef?.idType === 'foreign') {
      return 'ID extranjero';
    } else {
      return 'RTN';
    }
  };

  const generateUserRole = () => {
    if (userRef?.role === 'doctor') {
      return 'Doctor';
    } else if (userRef?.role === 'nurse') {
      return 'Enfermera';
    } else if (userRef?.role === 'checker') {
      return 'Cajero';
    } else {
      return 'Paciente';
    }
  };

  return (
    <div>
      <div className='profile-header'>
        <h3 className='text-white text-center pt-4'>Perfil de usuarios</h3>
      </div>
      <Row style={{ paddingTop: 15 }}>
        <Container>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: 'auto',
                borderRadius: '50%',
                border: '4px solid #011688',
              }}
            >
              <Avatar
                round
                src={
                  userRef?.image
                    ? `/api/profile/image/${userRef?.image}`
                    : avatarTemp
                }
                size='110'
              />
            </div>
            <h4 className='pt-3 profile-text'>
              {`${userRef?.firstName.split(' ', 1)} ${userRef?.lastName.split(
                ' ',
                1
              )}`}
            </h4>
            {userRef?.email && userRef?.email !== ' ' ? (
              <>
                <h6 className='profile-secondary-text'>
                  <label style={{ fontWeight: 500 }}>Correo</label>
                  {` / ${userRef?.email}`}
                </h6>
              </>
            ) : (
              <></>
            )}

            <h6 className='profile-secondary-text'>
              <label style={{ fontWeight: 500 }}>{generateIdType()}</label>
              {` / ${userRef?.dni}`}
            </h6>
            <h6 className='profile-secondary-text'>
              <label style={{ fontWeight: 500 }}>Género</label>
              {` / ${userRef?.gender === 'male' ? 'masculino' : 'femenino'}`}
            </h6>
            <h6 className='profile-secondary-text'>
              <label style={{ fontWeight: 500 }}>Tipo de usuario</label>
              {` / ${generateUserRole()}`}
            </h6>
            {loading ? (
              <p className='mt-2 text-center'>Cargando ...</p>
            ) : (
              <ActivePlan patientId={userRef?._id} />
            )}
          </div>
        </Container>
      </Row>
      <div className='text-center' style={{ marginBottom: 20 }}>
        <button
          type='submit'
          className='btn btn-primary mt-4 mb-4 m-auto col-8 col-md-5'
          onClick={handleNextStep}
        >
          Vincular a la orden
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRef: state.presential.user,
  loading: state.presential.loadingPackage,
  service: state.presential.service,
});

export default connect(mapStateToProps, {
  setTruePresentialVitalSigns,
  setFalsePresentialProfile,
  setTruePresentialComplete,
})(PresentialUserDetails);
