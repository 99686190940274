/* eslint-disable global-require */
/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PacientForm from '../../Components/PatientForm/PacientForm';
// import Layout from '../../layouts/PrincipalLayout/PrincipalLayout';
import FooterLogin from './FooterLogin';
import Navbar from '../../Components/Navbar';

const RegisterPatient = ({ isAuth }) => {
  if (isAuth) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <Navbar
        image={
          <img
            src={require('../../assets/img/foto-fondo-gris.png')}
            alt="fondo-gris"
            className="img-fluid imga d-none d-sm-none d-md-block"
          />
        }
      />
      <PacientForm />
      <FooterLogin />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});
export default connect(mapStateToProps)(RegisterPatient);
