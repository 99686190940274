/* eslint-disable arrow-parens */
import axios from 'axios';
import { GET_SPECIALTIES, ERROR_SPECIALTIES } from '../types';

export default function getSpecialties() {
  return async (dispatch) => {
    try {
      const res = await axios.get('/api/specialties');
      dispatch({
        type: GET_SPECIALTIES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_SPECIALTIES,
        payload: null,
      });
    }
  };
}
