import React from 'react';

const ModalMedicalOrders = () => (
  <div
    className="modal fade"
    id="ModalMedicalOrders"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-primary mx-auto" id="exampleModalLabel">
            ÓRDENES MÉDICAS
          </h5>
        </div>
        <div className="modal-body">
          <div className="list-group list-group-flush pl-0 pr-0">
            <div className="list-group-item">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">Receta Médica</h5>
                <small>2 medicamentos</small>
              </div>
              <p className="mb-1">3 ampollas, acetaminofen.</p>
              <small>Tratamiento  de 5 días</small>
            </div>
            <div className="list-group-item">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">Receta Médica</h5>
                <small>2 medicamentos</small>
              </div>
              <p className="mb-1">3 ampollas, acetaminofen.</p>
              <small>Tratamiento de 5 días</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ModalMedicalOrders;
