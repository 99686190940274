import React, { useState } from 'react';
import { paymentByUserBalance } from '../../../api/patient/payments';
import createAlert from '../../../redux/actions/alert.action';

export const usePackageInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUserPayByBalance = (planId) => {
    setIsLoading(true);
    paymentByUserBalance(planId)
      .then((res) => {
        createAlert(
          'Exito!',
          'El plan se ha activado de forma correcta',
          'success'
        ).then(() => window.location.reload());
      })
      .catch((error) => {
        createAlert('Error', error?.response?.data?.message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    handleUserPayByBalance,
  };
};
