import {ADD_TELEMEDICINE, DELETE_TELEMEDICINE} from '../types';

const initialState = {
  telemedicinePlan: localStorage.getItem('telemedicinePlan')
    ? JSON.parse(localStorage.getItem('telemedicinePlan')) : undefined,
};

export default (state = initialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ADD_TELEMEDICINE:
      localStorage.setItem('telemedicinePlan', JSON.stringify(payload));
      return {
        ...state,
        telemedicinePlan: payload,
      };
    case DELETE_TELEMEDICINE:
      localStorage.removeItem('telemedicinePlan');
      return {
        ...state,
        telemedicinePlan: undefined,
      };
    default:
      return state;
  }
};
