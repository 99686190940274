/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable global-require */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getOneVideoconference,
  getWaitingCallParameter,
} from '../../api/videoconference';
import Spinner from '../Spinner/Spinner';
import {
  cancelVideoconferencePatient,
  observerAcceptVideoconferencePatient,
  observerCancelVideoconferenceDoctor,
  patientInJitsi,
} from '../../websokect/notificationsWebsocket';
import createAlert from '../../redux/actions/alert.action';
import useJitsi from '../../Components/jitsi-meet';
import Swal from 'sweetalert2';
import { createStatusVideconferenceLog } from '../../api/status-videconference-logs/index';
import { statusvideconferenceEnum } from '../../enums/status-videconference-logs.enum';

const WaitingScreen = ({ token, user, match, history }) => {
  const { params } = match;
  const { roomId: ROOM_ID } = params;
  const [loading, setLoading] = useState(true);
  const [doctor, setDoctor] = useState(undefined);
  const { openjitsiMeet: jitsiMeet } = useJitsi();
  const timerRef = useRef(null);

  useEffect(() => {
    const { roomId: idVideo } = match.params;
    const defaultWaitingTime = 20; // Valor por defecto en caso de error
    let waitingTime = 20; // Valor inicial por defecto

    // Obtener el tiempo de espera
    getWaitingCallParameter({ token })
      .then((res) => {
        waitingTime = parseInt(res) || defaultWaitingTime; // Asignar el valor obtenido o el valor por defecto
      })
      .catch((error) => {
        console.error('Error al obtener los parámetros de espera:', error);
        waitingTime = defaultWaitingTime; // Si hay error, usar el valor por defecto de 20 segundos
      })
      .finally(() => {
        // Iniciar el temporizador con el tiempo de espera correcto
        timerRef.current = setTimeout(() => {
          Swal.fire({
            title: 'Continuar llamada',
            text: 'No has recibido respuesta, ¿Desea continuar con la llamada?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              createStatusVideconferenceLog({
                patientId: user._id,
                doctorId: user._id,
                videoconferenceId: idVideo,
                action:
                  statusvideconferenceEnum.patientJoinByJitsiAndWaitingTimeToJoin,
                message:
                  'El paciente supero el tiempo de espera, no canceló la llamada, decidió continuar con Jitsi',
              });

              patientInJitsi({ idVideo });
              jitsiMeet(ROOM_ID, user?.role);
              history.push('/dashboard');
            } else {
              createStatusVideconferenceLog({
                patientId: user._id,
                doctorId: user._id,
                videoconferenceId: idVideo,
                action:
                  statusvideconferenceEnum.patientCancelAndWaitingTimeToCancel,
                message:
                  'El paciente supero el tiempo de espera, luego de esto canceló la llamada',
              });

              clearTimeout(timerRef.current);
              const { roomId } = match.params;
              cancelVideoconferencePatient({ idVideo: roomId }, (data) => {
                if (data.status === 'success') {
                  history.push('/dashboard');
                } else {
                  createAlert(
                    '¡Error!',
                    'Lo sentimos no se pudo cancelar la videollamada.',
                    'error'
                  );
                }
              });
            }
          });
        }, waitingTime * 1000); // Convertir a milisegundos
      });

    // Iniciar videoconferencia
    getOneVideoconference({ token, idVideo })
      .then((res) => {
        const { doctor, videoconference } = res;
        setDoctor(doctor);
        setLoading(false);

        // Observar si el doctor acepta la llamada
        observerAcceptVideoconferencePatient({ idVideo }, () => {
          if (videoconference.type === 'videocall') {
            window.location.href = `/videoconference/${idVideo}`;
          } else if (videoconference.type === 'chat') {
            history.push(`/dashboard/chat/${idVideo}`);
          } else if (videoconference.type === 'call') {
            window.location.href = `/call/${idVideo}`;
          }
        });

        // Observar si el doctor cancela la videoconferencia
        observerCancelVideoconferenceDoctor({ idVideo }, () => {
          createAlert(
            '¡Atención!',
            'El doctor ha cancelado la llamada.',
            'info'
          );
          history.push('/dashboard');
        });
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          '¡Error!',
          'Lo sentimos hubo un error al realizar la llamada.',
          'error'
        );
        history.push('/dashboard');
      });

    // Limpiar el temporizador cuando el componente se desmonta
    return () => clearTimeout(timerRef.current);
  }, [token, match, history]);

  // Función para cancelar la videollamada
  const handleCancelVideoconference = () => {
    const { roomId } = match.params;
    Swal.fire({
      title: 'Continuar llamada',
      text: 'Antes de cancelar, ¿Desea continuar con la consulta virtual aquí?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        createStatusVideconferenceLog({
          patientId: user._id,
          doctorId: doctor._id,
          videoconferenceId: roomId,
          action: statusvideconferenceEnum.patientJoinByJitsi,
          message:
            'El paciente cancelo la llamada, pero decidio continuarla en Jitsi',
        });

        patientInJitsi({ idVideo: roomId });
        jitsiMeet(ROOM_ID, user?.role);
        history.push('/dashboard');
      } else {
        createStatusVideconferenceLog({
          patientId: user._id,
          doctorId: doctor._id,
          videoconferenceId: roomId,
          action: statusvideconferenceEnum.patientCancelVideconference,
          message: 'El paciente canceló la videollamada',
        });

        clearTimeout(timerRef.current);
        cancelVideoconferencePatient({ idVideo: roomId }, (data) => {
          if (data.status === 'success') {
            history.push('/dashboard');
          } else {
            createAlert(
              '¡Error!',
              'Lo sentimos no se pudo cancelar la videollamada.',
              'error'
            );
          }
        });
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className='container pt-5'>
      <div className='row'>
        <div className='col-12 col-md-12'>
          <div className='card shadow-sm profile-card-1 border-0'>
            <img
              src={require('../../assets/img/Doctor-stock.jpg')}
              alt='profile-sample1'
              className='background img-fluid'
            />
            <img
              src={require('../../assets/img/female.png')}
              alt='profile-image'
              className='profile call-animation img-fluid mb-5'
            />
            {doctor && (
              <div className='text-center card-content card-body border-top-0'>
                <h2 className='text-dark mt-5'>
                  ¡Espera, en un momento
                  {`${
                    doctor.publicData?.gender === 'male'
                      ? ' el Dr. '
                      : ' la Dra. '
                  } `}
                  {doctor.publicData?.lastName} te atenderá!
                </h2>
                <div className='icon-block pb-5'>
                  <span>
                    <i
                      onClick={handleCancelVideoconference}
                      className='fas fa-phone-slash'
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(WaitingScreen);
