import React from "react";

const ModalContact = () => (
  <div
    className="modal fade"
    id="ModalContact"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="ModalContact"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-dialog-centered modal-lg"
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-primary m-auto" id="ModalContact">
            INFORMACIÓN DE CONTACTO
          </h3>
        </div>
        <div className="modal-body text-center">
          <div className="grid-layout-auto">
            <div>
              <p className="p-0 m-0 text-primary">
                {" "}
                <img
                  src={require("../../assets/img/vectores/phone.svg")}
                  alt="kielsa-tienda"
                  width="30"
                  className="img-fluid mr-2"
                />
                2207-1000
              </p>
            </div>
            <div>
              <p className="p-0 m-0 text-primary">
                {" "}
                <img
                  src={require("../../assets/img/vectores/whatsapp.svg")}
                  alt="kielsa-tienda"
                  width="30"
                  className="img-fluid mr-2"
                />
                9800-2020
              </p>
            </div>
            <div>
              <p className="p-0 m-0 text-primary">
                <img
                  src={require("../../assets/img/vectores/email.svg")}
                  alt="kielsa-tienda"
                  width="30"
                  className="img-fluid mr-2"
                />{" "}
                infokielsaclinica.com
                {/* <br />
                servicioalcliente@kielsa.hn */}
              </p>
            </div>
          </div>
        </div>
        <div className="modal-footer p-2">
          <button
            type="button"
            className="btn btn-info mr-3"
            data-dismiss="modal"
            style={{ borderRadius: "0.8rem" }}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ModalContact;
