/* eslint-disable no-shadow */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {
  getGraphicRating,
  getQueryDetail,
} from '../../../redux/actions/graphic.action';
import Medicaldetails from '../../../Components/Doctor/Medicaldetails';

const Details = ({getGraphicRating, getQueryDetail, role}) => {
  useEffect(() => {
    getGraphicRating();
    getQueryDetail('', '', role);
  }, []);
  return (
    <>
      <Medicaldetails />
    </>
  );
};

const mapStateToprops = (state) => ({
  role: state.auth.user.role,
});

export default connect(mapStateToprops, {
  getGraphicRating,
  getQueryDetail,
})(Details);
