/* eslint-disable arrow-parens */
import React, { useEffect } from 'react';
import './stylesheets/style.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadUser } from './redux/actions/auth.action';
import store from './redux/store';

// paginas
import Home from './pages/Home/Home';
import Login from './pages/Auth/Login';
import Select from './pages/Auth/Select';
import forgotpassword from './pages/Auth/ForgotPass';
import changepass from './pages/Auth/ChangePass';
import RegisterDoctor from './pages/Auth/RegisterDoctor';
import RegisterPacient from './pages/Auth/RegisterPacient';
import NotFound from './pages/NotFound/NotFoundPublic';
import Videoconference from './pages/Videoconference/videoconference';
import OnlyCall from './pages/OnlyCall/OnlyCall';
import setAuthToken from './utils/auth.token.config';
import Dashboard from './layouts/DashboardLayout/Dashboardlayout';
import Planes from './Components/Patient/CheckPlansPublic';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/planes' component={Planes} />
        <Route path='/dashboard' component={Dashboard} />
        <Route exact path='/login' component={Login} />
        <Route
          exact
          path='/videoconference/:roomId'
          component={Videoconference}
        />
        <Route exact path='/call/:roomId' component={OnlyCall} />
        <Route exact path='/select' component={Select} />
        <Route exact path='/registerDoctor' component={RegisterDoctor} />
        <Route exact path='/registerPacient' component={RegisterPacient} />
        <Route exact path='/forgotpassword' component={forgotpassword} />
        <Route exact path='/changepass/:token' component={changepass} />
        <Route path='*' component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
