import React, { useEffect } from "react";
import { connect } from 'react-redux';
import HomeDoctor from "../../../Components/Doctor/HomeDoctor/HomeDoctor";
import HomeDoctorMovil from "../../../Components/Doctor/HomeDoctorMovil";
import { removeConsult, isConsult } from '../../../utils/isConsult';
import { changeStatusDoctor } from '../../../websokect/doctorWebsockets';

const Home = ({ user, token }) => {
  useEffect(() => {
    if (isConsult()) {
      changeStatusDoctor({ doctorId: user._id, role: user.role, status: 'available' });
      removeConsult();
    }
  }, [])
  return (
    <>
      {/* Web View */}
      <div className="d-none d-sm-block">
        <HomeDoctor />
      </div>
      {/* Fin Web View */}
      {/* Mobile View */}
      <div className="d-block d-sm-none">
        <HomeDoctorMovil />
      </div>
      {/* Fin Mobile View */}
    </>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(Home);
