import {ADD_TELEMEDICINE, DELETE_TELEMEDICINE} from '../types';

export const addTelemedicinePlan = (payload) => (dispatch) => dispatch({
  payload: payload,
  type: ADD_TELEMEDICINE,
});

export const deleteTelemedicinePlan = () => (dispatch) => dispatch({
  type: DELETE_TELEMEDICINE,
});
