/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const AddHistoryModal = () => (
  <div
    className="modal fade"
    id="Modalnuevohistorial"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            HISTORIAL MÉDICO
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
            >
            <div className="carousel-inner row w-100 mx-auto" role="listbox">
              <div className="carousel-item active">
                <form>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nombre del Medicamento</label>
                      <input
                        type="text"
                        className="form-control-user center"
                        name="name"
                        />
                    </div>
                    <div className="form-group">
                      <label>Dosis (Opcional)</label>
                      <input
                        type="text"
                        className="form-control-user center"
                        name="dose"
                        />
                    </div>
                    <div className="form-group">
                      <label>Frecuencia</label>
                      <input
                        type="text"
                        className="form-control-user center"
                        name="frequency"
                        />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: '40%' }}
                      >
                      AÑADIR +
                    </button>
                  </div>
                </form>
              </div>
              <div className="carousel-item">Antecedentes de Salud</div>
              <div className="carousel-item">Antecedentes familiares</div>
              <div className="carousel-item">Alergias</div>
              <div className="carousel-item">Vacunas</div>
              <div className="carousel-item">Historial clinico</div>
              <div className="carousel-item">Estilo de Vida</div>
            </div>

            <a
              className="carousel-control-prev"
              href="#carouselExample"
              role="button"
              data-slide="prev"
              >
              <i className="fa fa-chevron-circle-left fa-2x black-i" />

              <span className="sr-only">Previous</span>
            </a>

            <a
              className="carousel-control-next text-faded"
              href="#carouselExample"
              role="button"
              data-slide="next"
              >
              <i className="fa fa-chevron-circle-right fa-2x black-i" />

              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AddHistoryModal;
