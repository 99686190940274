import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, InputGroup, FormControl, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { forgoPassword } from '../redux/actions/auth.action';
import { countries } from '../utils/countries';

const ForgotPassword = ({ forgoPassword }) => {
  const [formData, setFormData] = useState({
    email: '',
    registerType: 'email',
    cellphone: '',
    dialCode: countries[0].dial_code,
    country: countries[0].name,
  });
  const [loaderButton, setLoaderButton] = useState(false);
  const { email, registerType, cellphone, dialCode, country } = formData;

  const handleChange = (e) => {
    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
        email: '',
        cellphone: '',
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    setLoaderButton(true);
    e.preventDefault();
    await forgoPassword(formData);
    setLoaderButton(false);
  };
  return (
    <section className='hero-section-beat-registro'>
      <div className='container forgot-pass-overlapping'>
        <div className='row center-on-tablets'>
          <div className='col-12 col-sm-5 col-md-5 col-lg-5'>
            <div
              className='card border-0 shadow-sm'
              style={{
                borderBottomLeftRadius: '2rem',
                borderBottomRightRadius: '2rem',
              }}
            >
              <Link to='/'>
                <img
                  src={require('../assets/img/loginKCL.png')}
                  alt='imagen'
                  className='img-fluid loginlogo2'
                  data-aos='fade-right'
                />
              </Link>
              <div className='card-body border-0'>
                <h4 className='center text-primary mb-3'>
                  Solicitar nueva contraseña
                </h4>
                <form id='reset'>
                  <div className='form-group'>
                    <label className='text-primary font-weight-bold float-left'>
                      Tipo de cuenta
                    </label>
                    <select
                      className='form-control bg-light'
                      id='exampleFormControlSelect1'
                      name='registerType'
                      value={registerType}
                      onChange={(e) => handleChange(e)}
                      placeholde='Tipo de registro'
                    >
                      <option value='email'>Correo electrónico</option>
                      <option value='cellphone'> Teléfono</option>
                    </select>
                  </div>

                  {registerType === 'email' && (
                    <>
                      <label className='text-primary font-weight-bold float-left'>
                        Correo Electrónico
                      </label>
                      <div className='input-group'>
                        <div className='input-group-prepend'>
                          <span
                            className='input-group-text border-left-rounded bg-light'
                            id='basic-addon1'
                          >
                            <img
                              src={require('../assets/img/icons/@.png')}
                              alt='imagen'
                              width='18'
                              className='img-fluid'
                            />
                          </span>
                        </div>
                        <input
                          type='email'
                          className='form-control bg-light'
                          id='inputEmail'
                          name='email'
                          value={email}
                          onChange={handleChange}
                          style={{
                            borderTopRightRadius: '2rem',
                            borderBottomRightRadius: '2rem',
                          }}
                        />
                        <small className='text-black-50 m-0 p-0'>
                          Te enviaremos un enlace a tu correo electrónico para
                          crear una nueva contraseña.
                        </small>
                      </div>
                    </>
                  )}

                  {registerType === 'cellphone' && (
                    <>
                      <label className='text-primary font-weight-bold float-left'>
                        Pais
                      </label>
                      <div>
                        <Form.Control
                          as='select'
                          style={{ marginBottom: 15 }}
                          onChange={handleChange}
                          name='country'
                          value={country}
                        >
                          {countries.map((country, key) => (
                            <option key={country.code}>{country.name}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <div className='form-group'>
                        <InputGroup>
                          <InputGroup.Prepend
                            value={dialCode}
                            name='dialCode'
                            onChange={handleChange}
                          >
                            <InputGroup.Text
                              style={{
                                borderTopLeftRadius: 20,
                                borderBottomLeftRadius: 20,
                              }}
                            >
                              {dialCode}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            className='form-control'
                            placeholder='Telefono'
                            name='cellphone'
                            value={cellphone}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        <small className='text-black-50 m-0 p-0'>
                          Te enviaremos un enlace a tu número de teléfono para
                          crear una nueva contraseña.
                        </small>
                      </div>
                    </>
                  )}

                  <div className='pr-2 pl-2'>
                    <button
                      type='submit'
                      className='btn btn-primary col-md-10 col-12'
                      onClick={handleSubmit}
                      style={{
                        opacity:
                          ((registerType === 'email' &&
                            email.trim().length < 3) ||
                            (registerType === 'cellphone' &&
                              cellphone.trim().length < 3 &&
                              cellphone.trim().length < 6) ||
                            loaderButton) &&
                          '80%',
                      }}
                      disabled={
                        (registerType === 'email' && email.trim().length < 3) ||
                        (registerType === 'cellphone' &&
                          cellphone.trim().length < 3 &&
                          cellphone.trim().length < 6) ||
                        loaderButton
                      }
                    >
                      {loaderButton ? (
                        <Spinner
                          style={{
                            width: 20,
                            height: 20,
                            color: '#ffff',
                            fontSize: 8,
                          }}
                          animation='border'
                        />
                      ) : (
                        'Restablecer'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  forgoPassword,
})(ForgotPassword);
