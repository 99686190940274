import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import {
  closeModalCellphone,
  validateCellphoneCode,
} from '../../redux/actions/auth.action';

const ModalCellphoneVerify = ({ formData, presential }) => {
  const [code, setCode] = useState('');
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.register);

  const handleClose = () => {
    setCode('');
    dispatch(closeModalCellphone());
  };

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      await dispatch(validateCellphoneCode(formData, code, presential));
    } catch (error) {
      console.error('Error validating cellphone code:', error);
    }
    setLoad(false);
    setCode('');
  };

  return (
    <Modal show={open} centered scrollable backdrop='static'>
      <Modal.Header>
        <Modal.Title>Código confirmación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            Ingresa el código de verificación enviado a tu numero de teléfono
            (el código tiene una duración de 15 minutos)
          </p>
          <input
            type='text'
            style={{ outline: 'none', width: '60%' }}
            name='code'
            value={code}
            onChange={handleChangeCode}
            maxLength={6}
          />
        </div>
      </Modal.Body>
      <div
        style={{
          padding: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!load && (
          <Button
            variant='danger'
            onClick={handleClose}
            style={{ width: '40%', borderRadius: 45 }}
          >
            Cancelar
          </Button>
        )}
        <Button
          variant='primary'
          onClick={handleSubmit}
          disabled={code.length !== 6}
          style={{ width: '40%' }}
        >
          {load ? (
            <Spinner
              style={{
                width: 20,
                height: 20,
                color: '#ffff',
                fontSize: 8,
              }}
              animation='border'
            />
          ) : (
            'COMPLETAR REGISTRO'
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCellphoneVerify;
