/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import createAlert from '../../../redux/actions/alert.action';
import { Spinner } from 'react-bootstrap';
import { UpdateEmployedCompany } from '../../../redux/actions/auth.action';
import useFarinterSelector from '../../../hooks/useFarinterSelector';
import moment from 'moment';
import {
  deleteReference,
  deleteProfileReference,
} from '../../../redux/actions/references';
import $ from 'jquery';
function ModalEditEmployee({ UpdateEmployedCompany }) {
  const dispatch = useDispatch();
  const { reference } = useFarinterSelector();
  const { userRef, profileRef } = reference;
  const [loaderButton, setLoaderButton] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dni, setDni] = useState('');
  const [bornAt, setBornAt] = useState('');
  const [position, setPosition] = useState('');
  const [gender, setGender] = useState('');
  const [idCompany, setIdCompany] = useState('');
  const [idType, setIdType] = useState('national');
  const [usuarioRef, setUsuarioRef] = useState('');
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (userRef && profileRef) {
      setFirstName(userRef?.firstName);
      setLastName(userRef?.lastName);
      setDni(userRef?.dni);
      setEmail(userRef?.email);
      setGender(userRef?.gender);
      setPosition(userRef?.position);
      setBornAt(moment(profileRef?.bornAt).format('YYYY-MM-DD'));
      setIdCompany(userRef?.idCompany);
      setUsuarioRef(userRef?._id);
    }

    return () => {
      dispatch(deleteReference());
      dispatch(deleteProfileReference());
    };
  }, [profileRef]);

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };

  // agregar usuario
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoaderButton(true);

      // revisar si el formulario no esta vacio

      if (
        firstName === '' ||
        lastName === '' ||
        dni === '' ||
        idType === '' ||
        position === '' ||
        bornAt === ''
      ) {
        await createAlert('¡Error!', 'Rellene todos los campos', 'error');
        setLoaderButton(false);
        return;
      }

      const actualTime = moment(new Date()).format('YYYY-MM-DD');
      const timeReference = moment(bornAt).format('YYYY-MM-DD');
      if (timeReference > actualTime) {
        await createAlert(
          '¡Error!',
          'La fecha de nacimiento debe ser menor a la fecha actual',
          'error'
        );
        setLoaderButton(false);
        return;
      }

      // validar si la identidad cumple requisitos

      const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
      if (
        (idType === 'national' && tempDni.length !== 13) ||
        (idType === 'rtn' && tempDni.length !== 14) ||
        (idType === 'foreign' && dni.length < 8)
      ) {
        await createAlert(
          '¡Error!',
          'Número de identidad incorrecto.',
          'error'
        );
        setLoaderButton(false);
        return;
      }

      const formData = {
        usuarioRef,
        firstName,
        lastName,
        email,
        gender,
        dni,
        idType,
        bornAt,
        position,
        idCompany,
      };

      //console.log("VAMOS A REGISTER EMPLOYED");
      const res = await UpdateEmployedCompany(formData);
      setLoaderButton(false);

      if (res.status === 200) {
        setShow(false);
        cerrar();
      }
    } catch (e) {
      await createAlert('Error', 'No se pudo registrar el usuario', 'error');
      setLoaderButton(false);
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.name === 'firstName') {
        setFirstName(e.target.value);
      }
      if (e.target.name === 'lastName') {
        setLastName(e.target.value);
      }
      if (e.target.name === 'dni') {
        setDni(e.target.value);
      }
      if (e.target.name === 'email') {
        setEmail(e.target.value);
      }
      if (e.target.name === 'bornAt') {
        setBornAt(e.target.value);
      }
      if (e.target.name === 'gender') {
        setGender(e.target.value);
      }
      if (e.target.name === 'job') {
        setPosition(e.target.value);
      }
    } catch (error) {}
  };
  function cerrar() {
    $('#ModalEditEmployee').modal('hide');
  }
  return (
    <div
      className='modal'
      id='ModalEditEmployee'
      tabIndex='-1'
      role='dialog'
      show={show}
    >
      <div
        className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5
              className='modal-title mx-auto text-primary'
              id='exampleModalLabel'
            >
              EDITAR EMPLEADO
            </h5>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='float-left'>
                      Identidad actual: {dni}
                    </label>
                    <NumberFormat
                      className='form-control bg-light'
                      format={`${setFormat()}`}
                      mask='_'
                      name='dni'
                      value={dni}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Nombre</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputEmail'
                      name='firstName'
                      value={firstName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left' visibility='hide'>
                      Apellido
                    </label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputLastName'
                      name='lastName'
                      value={lastName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Fecha de Nacimiento</label>
                    <input
                      type='date'
                      className='form-control'
                      placeholder='Fecha de Nacimiento'
                      name='bornAt'
                      value={bornAt}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='float-left'>Género</label>
                    <select
                      className='form-control'
                      id='exampleFormControlSelect1'
                      name='gender'
                      value={gender}
                      onChange={(e) => handleChange(e)}
                      placeholde='Género'
                    >
                      <option value='female'>Femenino</option>
                      <option value='male'>Masculino</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Puesto</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputEmail'
                      name='job'
                      value={position}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Correo Electrónico</label>
                    <input
                      type='email'
                      className='form-control bg-light'
                      id='inputEmail'
                      name='email'
                      value={email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-12 mt-3'>
                  <div className='col-md-6' style={{ marginTop: 20 }}>
                    <button
                      type='submit'
                      className='btn btn-primary font-weight-bold btn-block'
                      onClick={handleSubmit}
                      disabled={loaderButton}
                      style={{ opacity: '100%' }}
                    >
                      {loaderButton ? (
                        <Spinner
                          style={{
                            width: 20,
                            height: 20,
                            color: '#ffff',
                            fontSize: 8,
                          }}
                          animation='border'
                        />
                      ) : (
                        'ACTUALIZAR'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { UpdateEmployedCompany })(ModalEditEmployee);
