import axios from 'axios';

export const urlScheduling = '/api/schedule';

export const addScheduleDoctor = ({
  reason, date, hour, patient, token,
  idVideo
}) => new Promise((resolve, reject) => {
  if (reason && date && hour && token && patient) {
    axios.post(`${urlScheduling}/add/doctor`, {
      reason, patient, hour, date, idVideo
    }, {
      headers: {
        Authorization: token,
      },
    }).then(response => {
      const { data } = response;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => {
      reject(error);
    });
  } else if (!reason) {
    reject({
      status: 'error',
      info: 'Motivo de la consulta no esta definida',
    });
  } else if (!date) {
    reject({
      status: 'error',
      info: 'Fecha no definido',
    });
  } else if (!hour) {
    reject({
      status: 'error',
      info: 'Hora no definida',
    });
  } else if (!patient) {
    reject({
      status: 'error',
      info: 'Paciente no esta definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique todos los parametros',
    });
  }
});

export const upcomingScheduling = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${urlScheduling}/upcoming/scheduling`,{
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => {
      reject(error);
    })
  } else {
    reject({
      status: 'error',
      info: 'El usuario no esta autenticado'
    });
  }
});
