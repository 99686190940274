export const statusvideconferenceEnum = Object.freeze({
  patientStartVideoconference: 'patient-start-videconference',
  patientCancelVideconference: 'patient-cancel-videconference',
  patientJoinByJitsi: 'patient-join-by-jitsi',
  patientCancelAndWaitingTimeToCancel:
    'patient-cancel-and-waiting-time-to-cancel',
  patientJoinByJitsiAndWaitingTimeToJoin:
    'patient-join-by-jitsi-and-waiting-time-to-join',
  patientFinishVideconference: 'patient-finish-videconference',
  patientJoinToJitsiByButton: 'patient-join-to-jitsi-by-button',
  doctorAcceptVideconference: 'doctor-accept-videconference',
  doctorNotAcceptVideconference: 'doctor-not-accept-videconference',
  doctorCancelVideconference: 'doctor-cancel-videconference',
  doctorFinishVideconference: 'doctor-finish-videconference',
  doctorJoinJitsiByButton: 'doctor-join-jitsi-by-button',
});
