import { useRef } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useDispatch } from "react-redux";
import { useReferences } from "../../../../hooks/useReferences";
import { useUI } from "../../../../hooks/useUI";
import { cleanAllReferences } from "../../../../redux/actions/references";

export const useCarnetModalState = () => {
  const dispatch = useDispatch();
  const carnet = useRef(null);
  const carnetBack = useRef(null);
  const { userRef } = useReferences();
  const { handleCloseModalCarnet } = useUI();

  const handlePrintCarnet = () => {
    exportComponentAsJPEG(carnet, {
      fileName: `Carnet frontal plan ${userRef?.firstName.split(" ")[0]} ${
        userRef?.lastName.split(" ")[0]
      }`,
    });
  };

  const handlePrintCarnetBack = () => {
    exportComponentAsJPEG(carnetBack, {
      fileName: `Carnet reverso plan ${userRef?.firstName.split(" ")[0]} ${
        userRef?.lastName.split(" ")[0]
      }`,
    });
  };

  const handleGetRelation = (relation) => {
    if (relation === "son") {
      return "Hijo(a)";
    } else if (relation === "father") {
      return "Padre";
    } else if (relation === "mother") {
      return "Madre";
    } else if (relation === "grandpa") {
      return "Abuelo";
    } else if (relation === "grandma") {
      return "Abuela";
    } else if (relation === "uncle") {
      return "Tío";
    }

    return "Amigo(a)";
  };

  const handleClose = () => {
    handleCloseModalCarnet();
    dispatch(cleanAllReferences());
  };
  return {
    handlePrintCarnet,
    handlePrintCarnetBack,
    handleGetRelation,
    carnet,
    carnetBack,
    handleClose,
  };
};
