/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Presale from './Presale';
import ModalNursingNotes from '../Modal/ModalNursingNotes';
import {
  addVerifyConsult,
  addVideoconference,
} from '../../redux/actions/videoconference.action';
import { getOneVideoconference } from '../../api/videoconference';
import { addUpdatePresale } from '../../api/presale';
import AddProductPresale from './presale/addProductPresale';
import AddServicesPresale from './presale/addServicesPresale';
import {
  getOnePostConsult,
  endPostConsult,
} from '../../api/post.videoconference';
import { validProductKielsa } from '../../api/productKielsa';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
const textGender = (gender) => (gender === 'male' ? 'Masculino' : 'Femenino');

const imageGender = (gender) =>
  gender === 'male'
    ? require('../../assets/img/male.png')
    : require('../../assets/img/female.png');

const PostConsultation = ({
  token,
  match,
  addVideoconference,
  history,
  user,
}) => {
  const { params } = match;
  const { roomId } = params;
  const [, setSeconds] = useState(0);
  const [nursingNote, setNursingNote] = useState('');
  const [, setHistoryPatient] = useState(undefined);
  const [, setHeaHistory] = useState([]);
  const [doctor, setDoctor] = useState();
  const [patient, setPatient] = useState();
  const [, setLoading] = useState(true);
  const [, setLimitSeconds] = useState();
  const [, setScheduleVideo] = useState();
  const [consultDoctoroOffice, setConsultDoctorOffice] = useState();
  const [arrayProducts, setArrayProducts] = useState([]);
  const [arrayServices, setArrayServices] = useState([]);
  const [idVideo, setIdVideo] = useState('');
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [signsVital, setSignsVital] = useState({
    temperature: '',
    pressure: {
      initial: '',
      final: '',
    },
    heartRate: '',
    breathingFrequency: '',
    saturation: '',
    weight: '',
    height: '',
    imc: '',
  });
  const {
    temperature,
    pressure,
    heartRate,
    breathingFrequency,
    saturation,
    weight,
    height,
    imc,
  } = signsVital ?? {};
  useEffect(() => {
    getOnePostConsult({ token, _id: roomId })
      .then((res) => {
        setIdVideo(res.data.idVideoconference);
        getOneVideoconference({ idVideo: res.data.idVideoconference, token })
          .then((response) => {
            const {
              videoconference,
              historyPatient: dataHistory,
              heaHistory: dataHea,
              patient: dataPatient,
              doctor: dataDoctor,
              maxSecondPatient,
              dataSchedule,
              dataConsultDoctorOffice,
              dataPresale,
            } = response;

            // agregnado videconferencia a redux
            addVideoconference(videoconference);

            const { secondsDoctor } = videoconference;
            setScheduleVideo(dataSchedule);
            setHistoryPatient(dataHistory);
            setHeaHistory(dataHea);
            setPatient(dataPatient);
            setDoctor(dataDoctor);
            setLimitSeconds(maxSecondPatient);
            setSeconds(secondsDoctor);
            setConsultDoctorOffice(dataConsultDoctorOffice);
            setSignsVital(videoconference.vitalSings);
            if (dataPresale) {
              setArrayProducts(dataPresale.arrayProducts);
              setArrayServices(dataPresale.arrayServices);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            alert('Error al ingresar a la Post consulta.');
          });
      })
      .catch((error) => {
        console.log(error);
        alert('Error al ingresar a la Post consulta.');
      });
  }, []);

  const handleSubmitnursingNote = (text) => {
    setNursingNote(text);
    $('#ModalNursingNotes').modal('hide');
  };

  const endConsult = () => {
    setLoadingEnd(true);
    endPostConsult({
      token,
      nurseNote: nursingNote,
      _id: roomId,
    })
      .then((res) => {
        const { preventaId } = res;
        if (preventaId) {
          Swal.fire({
            icon: 'success',
            title: `Se creo la preventa No. ${preventaId} de manera exitosa`,
          }).then(() => {
            changeStatusDoctor({
              doctorId: user._id,
              role: user.role,
              status: 'available',
            });
            history.push('/dashboard');
          });
        } else {
          changeStatusDoctor({
            doctorId: user._id,
            role: user.role,
            status: 'available',
          });
          history.push('/dashboard');
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se finalizó la consulta.',
            showConfirmButton: false,
            timer: 1500,
          });
          setLoadingEnd(false);
        }
      })
      .catch((error) => {
        if (error.info) {
          Swal.fire({
            icon: 'error',
            title: error.info,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Lo sentimos no se pudo finalizar la post consulta.',
          });
        }
        setLoadingEnd(false);
      });
  };
  return (
    <div className='container-fluid'>
      <div className='user-dashboard'>
        <h2 className='text-primary mb-5 mt-5'>
          Post&nbsp;
          <b className='text-secondarymdv'>Consulta</b>{' '}
        </h2>
        {/* modal */}
        <ModalNursingNotes
          nursingNote={nursingNote}
          handleNursingNote={handleSubmitnursingNote}
        />
        {/* fin modal */}
        <div className='row'>
          <div className='col-12 col-md-5'>
            <div className='sales report mb-4 text-center'>
              <div className='profile-header'>
                <h3 className='text-white text-center pt-4'>
                  Perfil de Paciente
                </h3>
              </div>
              <h4 className='text-primary font-weight-bold mt-3'>
                {`${patient?.publicData.firstName} ${patient?.publicData.lastName}`}
              </h4>
              <img
                src={
                  patient?.publicData.image
                    ? `/api/profile/image/${patient?.publicData.image}`
                    : imageGender(patient?.publicData.gender)
                }
                alt='foto-paciente'
                width='110'
                className='img-fluid mt-2'
              />
              <div className='container mt-3'>
                <div className='hr' />
                <div className='row no-gutters justify-content-center'>
                  <div className='col-12 col-md-4 col-sm-12 border-right'>
                    <p>
                      <i className='far fa-id-badge mr-1' />
                      {patient?.publicData.dni}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12 border-right'>
                    <p>
                      <i className='fas fa-venus-mars mr-1' />
                      {textGender(patient?.publicData.gender)}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12'>
                    <p>
                      <i className='fas fa-phone-alt mr-1' />
                      {patient?.profileData.cellphone
                        ? patient?.profileData.cellphone
                        : 'No posee'}
                    </p>
                  </div>
                  <div className='col-12 col-md-4 col-sm-12'>
                    <p>
                      <i className='fas fa-envelope-open-text mr-1' />
                      {patient?.publicData.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-7'>
            <div className='sales report mb-4 text-center'>
              <div className='profile-header'>
                <h3 className='text-white text-center pt-4'>Consulta</h3>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <div
                    className='card mb-3'
                    style={{ height: '323px', borderRadius: '0.8rem' }}
                  >
                    <div className='card-body border-0'>
                      <p>{nursingNote}</p>
                    </div>
                  </div>
                  <button
                    type='button'
                    data-toggle='modal'
                    data-target='#ModalNursingNotes'
                    data-whatever='@getbootstrap'
                    className='btn btn-secondary btn-block bg-secondarymdv text-white'
                    style={{ borderRadius: '0.8rem', height: '77px' }}
                  >
                    <h5 className='text-white mt-2'>
                      <i className='far fa-edit' /> Actualizar Notas
                    </h5>
                  </button>
                </div>
                <div className='col-md-6'>
                  <h5 className='text-secondarymdv font-weight-bold text-center mb-3'>
                    Signos Vitales
                  </h5>
                  <table className='table table-sm table-striped mb-3'>
                    <tbody>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Temperatura
                        </th>
                        <td>
                          {temperature ? `${temperature} C°` : 'No definido'}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Presión Arterial
                        </th>
                        <td>
                          {pressure
                            ? `${pressure?.initial} mmhg / ${pressure?.final} mmhg`
                            : 'No definido'}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Frecuencia Respiratoria
                        </th>
                        <td>
                          {breathingFrequency
                            ? `${breathingFrequency} rpm`
                            : 'No definido'}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Frecuencia Cardíaca
                        </th>
                        <td>
                          {heartRate ? `${heartRate} ppm` : 'No definido'}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Saturación de Oxígeno
                        </th>
                        <td>
                          {saturation ? `${saturation} %` : 'No definido'}
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Peso
                        </th>
                        <td>{weight ? `${weight} Kg` : 'No definido'}</td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Estatura
                        </th>
                        <td>{height ? `${height} Mts` : 'No definido'}</td>
                      </tr>
                      <tr>
                        <th
                          scope='row'
                          className='text-secondarymdv font-weight-light'
                        >
                          Índice de masa corporal
                        </th>
                        <td>{imc ? `${imc} IMC` : 'No definido'}</td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    type='button'
                    className='btn btn-light btn-block border bg-danger text-white'
                    style={{ borderRadius: '0.8rem', height: '77px' }}
                    onClick={() => endConsult()}
                    disabled={loadingEnd}
                  >
                    {loadingEnd ? (
                      <h5 className='text-white mt-2'>Procesando ...</h5>
                    ) : (
                      <h5 className='text-white mt-2'>
                        <i className='fas fa-sign-out-alt' /> Finalizar Post
                        Consulta
                      </h5>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <Presale
              products={arrayProducts}
              services={arrayServices}
              patient={patient}
              doctor={doctor}
              hour={consultDoctoroOffice?.hour}
              removeLastProduct={() => {
                if (arrayProducts.length > 0) {
                  const index = arrayProducts.length - 1;
                  arrayProducts.splice(index, 1);
                  const newArray = arrayProducts.map((item) => item);
                  setArrayProducts(newArray);
                }
              }}
              removeLastService={() => {
                if (arrayServices.length > 0) {
                  const index = arrayServices.length - 1;
                  arrayServices.splice(index, 1);
                  const newArray = arrayProducts.map((item) => item);
                  setArrayServices(newArray);
                }
              }}
              registerPresale={() => {
                addUpdatePresale({
                  token: token,
                  idVideo: idVideo,
                  arrayProducts: arrayProducts,
                  arrayServices: arrayServices,
                })
                  .then(() => {
                    if (arrayProducts.length > 0 || arrayServices.length > 0) {
                      Swal.fire({
                        icon: 'success',
                        title: 'Se actualizó la preventa de manera exitosa.',
                      });
                    } else {
                      Swal.fire({
                        icon: 'success',
                        title: 'Se agrego la preventa de manera exitosa.',
                      });
                    }
                  })
                  .catch((error) => {
                    if (error.info) {
                      Swal.fire({
                        icon: 'error',
                        title: error.info,
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos no se pudo crear la preventa.',
                      });
                    }
                  });
              }}
            />
          </div>
          <div className='col-12 col-md-5'>
            <AddProductPresale
              addProduct={(doc) => {
                validProductKielsa({
                  token: token,
                  articuloId: doc.code,
                  quantity: doc.amount,
                  idVideoconference: idVideo,
                })
                  .then((res) => {
                    if (res.valid) {
                      arrayProducts.push(doc);
                      const newArray = arrayProducts.map((item) => item);
                      setArrayProducts(newArray);
                    } else if (res.noExists) {
                      Swal.fire({
                        icon: 'error',
                        text: `El producto con el codigo ${doc.code} no existe en la farmacia`,
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: `El producto con el codigo ${doc.code} solo posee ${res.existence} en existencia`,
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: 'error',
                      text: 'Por favor contacte con servicio tecnico',
                    });
                  });
              }}
              showDetail={(doc) => {
                validProductKielsa({
                  token: token,
                  articuloId: doc.code,
                  quantity: doc.amount,
                  idVideoconference: idVideo,
                })
                  .then((res) => {
                    if (res.noExists) {
                      Swal.fire({
                        icon: 'info',
                        text: `El producto con el codigo ${doc.code} no existe en la farmacia`,
                      });
                    } else {
                      Swal.fire({
                        icon: 'info',
                        text: `El producto con el codigo ${doc.code} posee ${res.existence} en existencia`,
                      });
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: 'error',
                      text: 'Por favor contacte con servicio tecnico',
                    });
                  });
              }}
              products={arrayProducts}
              token={token}
            />
            <AddServicesPresale
              services={arrayServices}
              addService={(doc) => {
                arrayServices.push(doc);
                const newArray = arrayServices.map((doc) => doc);
                setArrayServices(newArray);
              }}
              token={token}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => state.auth;

export default connect(mapStatetoProps, {
  addVerifyConsult,
  addVideoconference,
})(PostConsultation);
