/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../../stylesheets/home.css';

function Features() {
  return (
    <>
      {/* vista web */}

      <section
        id="quienesomos"
        className="section-features d-none d-sm-none d-md-block"
      >
        <div className="container animate__animated animate__fadeIn">
          <h1 className="text-center text-primary font-weight-bold display-4">
            ¿Quiénes Somos?
          </h1>
          <p className="center" style={{ fontSize: '20px' }}>
            <b className="text-primary">"Kielsa Clínica & Lab" </b>
            Es la primera solución de orientación médica virtual en tiempo real
            y de atención médica presencial en un solo lugar para Honduras y
            Centroamérica, disponible desde cualquier ordenador o dispositivo
            móvil.
          </p>
          <h4
            data-aos="fade-right"
            className="text-center text-secondarymdv font-weight-light mb-3"
          >
            Nuestros Servicios
          </h4>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 justify-content-center">
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 text-center pr-1 pl-1">
                  <img
                    src={require('../../assets/img/icons/camera.svg')}
                    alt="doctor"
                    width="95"
                    className="img-fluid"
                  />
                  <h5 className="text-primary mt-2 mb-0 pb-0 text-responsive">
                    Telemedicina 24/7
                  </h5>
                  <small className="text-secondarymdv">
                    Orientación médica virtual.
                  </small>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/add-heart.svg')}
                    alt="paypal"
                    width="95"
                    className="img-fluid"
                  />
                  <h5 className="text-primary mt-2 mb-0 pb-0 text-responsive">
                    Consulta Presencial
                  </h5>
                  <small className="text-secondarymdv">
                    Atención médica en vivo.
                  </small>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/microscopio2.svg')}
                    alt="paypal"
                    width="95"
                    className="img-fluid"
                  />
                  <h5 className="text-primary mt-2 mb-0 pb-0 text-responsive">
                    Laboratorio
                  </h5>
                  <small className="text-secondarymdv">
                    Exámenes de todo tipo.
                  </small>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/farmaciakielsa.svg')}
                    alt="paypal"
                    width="95"
                    className="img-fluid"
                  />
                  <h5 className="text-primary mt-2 mb-0 pb-0 text-responsive">
                    Farmacias
                  </h5>
                  <small className="text-secondarymdv">
                    Medicamentos y productos.
                  </small>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/clipboard.svg')}
                    alt="paypal"
                    width="95"
                    className="img-fluid"
                  />
                  <h5 className="text-primary mt-2 mb-0 pb-0 text-responsive">
                    Planes Médicos
                  </h5>
                  <small className="text-secondarymdv">
                    Tárifas accesibles.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="location" className="clinic_map_section">
        <div>
          <img
            src={require('../../assets/img/search_clinic.jpg')}
            alt="search_clinic"
            width="500px"
            className="img-fluid"
          />
        </div>
        <div className="main_container">
          <div className="main_text_container">
            <div className="header">ZONA CENTRO SUR</div>
            <ul>
              <li>
                Blvr. Morazán contiguo a Toño Rosa, frente a BAC Credomatic,
                Tegucigalpa.
              </li>
              <li>
                Blvr. Suyapa frente a torre libertad contiguo a Laboratorio
                Bueso Arias, Tegucigalpa.
              </li>
              <li>
                Colonia Modelo, calle Los Alcaldes, frente a gasolinera Puma Las
                Torres, Tegucigalpa.
              </li>
              <li>
                Colonia Miraflores, ubicada en la lotificación Miraflores, Lote
                445, Tegucigalpa.
              </li>
            </ul>

            <div className="header">ZONA NORTE</div>
            <ul>
              <li>
                Bo. Los Andes Ave. Circunvalación contigua a Paper Depot, 9
                calle N.O frente a Banco Lafise, San Pedro Sula.
              </li>
              <li>
                Blvr. Juan Pablo I, entrada a col. Honduras, frente a Sarita,
                San Pedro Sula.
              </li>
              <li>
                Interplaza, blvr. del este contiguo a Ofibodegas Premier
                Warehouse Complex, San Pedro Sula.
              </li>
              <li>
                Colonia Satélite, blvr. después de Supermercado Satélite, San
                Pedro Sula.
              </li>
              <li>
                Colonia Las Palmas, frente a Maxi Despensa, Avenida Juan Pablo,
                San Pedro sula.
              </li>
              <li>Plaza La Curva, Choloma.</li>
              <li>
                Bo. Las Mercedes frente al parque central, calle principal
                9800-2020 al par de pollos la Espiga, El Progreso.
              </li>
            </ul>
          </div>
          <div className="clinic_map_container">
            <img
              src={require('../../assets/img/clinic_map.jpg')}
              alt="clinic_map"
              height={'auto'}
              width={'500px'}
              className="clinic_map"
            />
          </div>
        </div>
        <div>
          <img
            src={require('../../assets/img/clinic_lab.jpg')}
            alt="clinic_lab"
            width="200px"
            className="img-fluid"
          />
        </div>
      </section>

      {/* vista móvil */}
      <section id="quienesomos" className="d-sm-block d-md-none mb-5">
        <div className="container animate__animated animate__fadeIn">
          <h3 className="pt-5 text-center text-primary font-weight-bold">
            ¿Quiénes Somos?
          </h3>
          <p className="center" style={{ fontSize: '15px' }}>
            <b className="text-primary">"Kielsa Clínica & Lab" </b>
            Es la primera solución de orientación médica virtual en tiempo real
            y de atención médica presencial en un solo lugar para Honduras y
            Centroamérica, disponible desde cualquier ordenador o dispositivo
            móvil.
          </p>
          <h4 className="text-center text-secondarymdv font-weight-light mb-3">
            Nuestros Servicios
          </h4>
          <div className="row row-cols-2 justify-content-center">
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 text-center pr-1 pl-1">
                  <img
                    src={require('../../assets/img/icons/camera.svg')}
                    alt="doctor"
                    width="50"
                    className="img-fluid"
                  />
                  <h6 className="text-primary mt-2 mb-0 pb-0">
                    Telemedicina 24/7
                  </h6>
                  <p
                    className="text-secondarymdv mb-0 pb-0"
                    style={{ fontSize: '13px' }}
                  >
                    Orientación médica virtual.
                  </p>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/add-heart.svg')}
                    alt="paypal"
                    width="50"
                    className="img-fluid"
                  />
                  <h6 className="text-primary mt-2 mb-0 pb-0">
                    Consulta Presencial
                  </h6>
                  <p
                    className="text-secondarymdv mb-0 pb-0"
                    style={{ fontSize: '13px' }}
                  >
                    Atención médica en vivo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/microscopio2.svg')}
                    alt="paypal"
                    width="50"
                    className="img-fluid"
                  />
                  <h6 className="text-primary mt-2 mb-0 pb-0">Laboratorio</h6>
                  <p
                    className="text-secondarymdv mb-0 pb-0"
                    style={{ fontSize: '13px' }}
                  >
                    Exámenes de todo tipo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/farmaciakielsa.svg')}
                    alt="paypal"
                    width="50"
                    className="img-fluid"
                  />
                  <h6 className="text-primary mt-2 mb-0 pb-0">Farmacias</h6>
                  <p
                    className="text-secondarymdv mb-0 pb-0"
                    style={{ fontSize: '13px' }}
                  >
                    Medicamentos y productos.
                  </p>
                </div>
              </div>
            </div>
            <div className="col p-2">
              <div
                className="card shadow-sm border-0 h-100"
                style={{ borderRadius: '1rem' }}
              >
                <div className="card-body border-0 pr-1 pl-1 text-center">
                  <img
                    src={require('../../assets/img/icons/clipboard.svg')}
                    alt="paypal"
                    width="50"
                    className="img-fluid"
                  />
                  <h6 className="text-primary mt-2 mb-0 pb-0">
                    Planes Médicos
                  </h6>
                  <p
                    className="text-secondarymdv mb-0 pb-0"
                    style={{ fontSize: '13px' }}
                  >
                    Tárifas accesibles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;
