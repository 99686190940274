/* eslint-disable no-shadow */
/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import createAlert from '../../redux/actions/alert.action';
import {
  endCallVideconference,
  getOneVideoconference,
} from '../../api/videoconference';
import ShowHeaModal from '../Modal/ShowHeaModal';
import { searchProduct } from '../../api/productKielsa';
import { addConsult } from '../../utils/isConsult';

function ReasonCheck({ token, verifyVideo, history, removeVerifyConsult }) {
  const [reason, setReason] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [hea, setHea] = useState('');
  const [indications, setIndications] = useState([]);
  const [dataVideo, setDataVideo] = useState({});
  const [dataPatient, setDataPatient] = useState({});
  const [dataHeaPatient, setDataHeaPatient] = useState([]);
  const [numberVideoconference, setNumberVideoconference] = useState(0);
  const [dataProducts, setDataProduct] = useState([]);

  useEffect(() => {
    getOneVideoconference({ token, idVideo: verifyVideo._id })
      .then((res) => {
        const { patient, heaHistory, videoconference, numberVideoconference } =
          res;
        setDataVideo(videoconference);
        setDataPatient(patient);
        setDataHeaPatient(heaHistory);
        setNumberVideoconference(numberVideoconference);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, verifyVideo._id]);

  const handleChangeMedicine = (e) => {
    searchProduct({ token: token, name: e.target.value }).then((res) => {
      const { data } = res;
      setDataProduct(data);
    });
  };

  // si no esta definido el video
  if (!verifyVideo) {
    return <Redirect to='/dashboard' />;
  }

  const handleSubmitMedicine = (e) => {
    e.preventDefault();
    const doc = {
      medicine: e.target.name.value,
      dose: e.target.dose.value,
      frequency: e.target.frequency.value,
    };
    if (!doc.medicine) {
      createAlert('¡Error!', 'Por favor ingrese la medicina.', 'error');
      return;
    } else if (!doc.dose) {
      createAlert('¡Error!', 'Por favor ingrese la dosis.', 'error');
      return;
    } else if (!doc.frequency) {
      createAlert(
        '¡Error!',
        'Por favor ingrese la frecuencia del medicamento.',
        'error'
      );
      return;
    }
    setIndications((indications) => indications.concat(doc));
    document.getElementById('medicines-form').reset();
  };

  const handleRemove = (number) => {
    setIndications(indications.filter((doc, i) => i !== number));
  };

  const handleEncCall = () => {
    if (!reason) {
      createAlert(
        '¡Error!',
        'Por favor escriba el motivo de la consulta.',
        'error'
      );

      return;
    } else if (!diagnosis) {
      createAlert(
        '¡Error!',
        'Por favor escriba el diagnóstico de la consulta.',
        'error'
      );
      return;
    } else if (!hea) {
      createAlert(
        '¡Error!',
        'Por favor escriba el HEA de la consulta.',
        'error'
      );
    }
    endCallVideconference({
      token: token,
      endCallDate: new Date(),
      idVideoconference: verifyVideo._id,
      diagnosis: diagnosis,
      reason: reason,
      indications: indications,
      hea: hea,
    })
      .then(() => {
        createAlert(
          '¡Correcto!',
          'Se ha enviado los datos exitosamente.',
          'success'
        );
        removeVerifyConsult();
        addConsult();
        history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          '¡Correcto!',
          'Lo sentimos no se pudo enviar los datos.',
          'error'
        );
      });
  };

  return (
    <div>
      <div className='container-fluid'>
        <h4 className='text-primary center padding1 mt-5'>
          Notamos que cerraste sesión y no llenaste los siguientes campos
          requeridos.
        </h4>

        <div className='row'>
          <div className='col-md-8 col-sm-12 display-table-cell v-align'>
            <div className='user-dashboard'>
              <div className='sales'>
                <h5 className='center m-0 text-primary' id='exampleModalLabel'>
                  MOTIVO DE LA CONSULTA
                </h5>
                <div
                  id='carouselExample'
                  className='carousel slide mar-2'
                  data-ride='carousel'
                  data-interval='2000'
                >
                  <div className='form-group'>
                    <input
                      type='text'
                      name='reason'
                      className='form-control center'
                      id='recipient-name'
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <h5 className='center m-0 text-primary' id='exampleModalLabel'>
                  DIAGNÓSTICO
                </h5>
                <div
                  id='carouselExample'
                  className='carousel slide mar-2'
                  data-ride='carousel'
                  data-interval='2000'
                >
                  <div className='form-group'>
                    <textarea
                      type='text'
                      name='reason'
                      className='form-control center'
                      id='recipient-name'
                      value={diagnosis}
                      onChange={(e) => {
                        setDiagnosis(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <h5 className='center m-0 text-primary' id='exampleModalLabel'>
                  HEA
                </h5>
                <div
                  id='carouselExample'
                  className='carousel slide mar-2'
                  data-ride='carousel'
                  data-interval='2000'
                >
                  <div className='form-group'>
                    <textarea
                      type='text'
                      name='reason'
                      className='form-control center'
                      id='recipient-name'
                      value={hea}
                      onChange={(e) => {
                        setHea(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <form id='medicines-form' onSubmit={handleSubmitMedicine}>
                  <div className='form-group'>
                    <h5
                      className='center m-0 text-primary'
                      id='exampleModalLabel'
                    >
                      AGREGAR PRESCRIPCIÓN
                    </h5>
                    <input
                      type='text'
                      className='form-control center'
                      name='name'
                      autoComplete='off'
                      list='suggestions'
                      onChange={handleChangeMedicine}
                    />
                    <datalist key='suggestions' id='suggestions'>
                      {dataProducts.map(({ Articulo_Nombre, _id }) => (
                        <option key={_id} value={Articulo_Nombre}>
                          {Articulo_Nombre}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className='form-group'>
                    <h5 className='center m-0 text-primary'>DOSIS</h5>
                    <input
                      type='text'
                      className='form-control center'
                      name='dose'
                    />
                  </div>
                  <div className='form-group'>
                    <h5 className='center m-0 text-primary'>FRECUENCIA</h5>
                    <input
                      type='text'
                      className='form-control-user center'
                      name='frequency'
                    />
                  </div>
                </form>
                <div className='row mt-3'>
                  {indications.map((doc, i) => (
                    <div key={i} className='btn-lg-modal-2'>
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRemove(i)}
                        className='fas fa-times i-close'
                      />
                      <span>
                        {doc.medicine}
                        <br />
                        <small>{doc.dose}</small>
                        <br />
                        <small>{doc.frequency}</small>
                      </span>
                    </div>
                  ))}
                </div>
                <div className='row mt-3'>
                  <button
                    type='submit'
                    form='medicines-form'
                    className='btn btn-primary3'
                  >
                    AÑADIR +
                  </button>
                  <button
                    onClick={handleEncCall}
                    type='button'
                    className='btn btn-primary3'
                  >
                    Ver
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 col-sm-12 col-xs-12 gutter'>
            <div className='bg-detail'>
              <div className='sales'>
                <div className='row  mar-2'>
                  <div className='card-r'>
                    <div className='card-body bbbb'>
                      <h5 className='white center'>Resumen de Consulta</h5>
                      <h4 className='white center'>#{numberVideoconference}</h4>
                      <h6 className='white center'>
                        {`${dataPatient?.publicData?.firstName} ${dataPatient?.publicData?.lastName}`}
                      </h6>
                      <center className='m-t-30'>
                        {' '}
                        <img
                          src={require('../../assets/img/avatars/avatar-pat-female.png')}
                          className='img-circle-2 img-fluid'
                          width='80'
                          alt='avatar'
                        />
                        <div className='row text-center justify-content-md-center'>
                          <div className='col-4'>
                            <font className='font-medium'>
                              {moment(dataVideo).format('DD/MM/YYYY')}
                            </font>
                          </div>
                          <div className='col-4'>
                            <font className='font-medium'>
                              {moment(dataVideo.createAlert).format('LT')}
                            </font>
                          </div>
                        </div>
                      </center>
                    </div>
                    <div className='cont-r'>
                      {' '}
                      <h5 className='font-blue-r'>Motivo</h5>
                      <h5 className='font-blue-l'>
                        {reason || 'Ninguno'}
                      </h5>{' '}
                      <hr />
                      <h5 className='font-blue-r'>Diagnostico</h5>
                      <h5 className='font-blue-l'>{diagnosis || 'Ninguno'}</h5>
                      <hr />
                      <h5 className='font-blue-r'>Indicaciones Médicas</h5>
                      <h5 className='font-blue-l'>
                        {indications.length === 0
                          ? 'Ninguno'
                          : indications.map((element) => (
                              <p>{`${element.medicine}, ${element.dose}, ${element.frequency}`}</p>
                            ))}
                      </h5>
                      <hr />
                      <h5 className='font-blue-r'>Próxima Cita</h5>
                      <h5 className='font-blue-l'>proxima semana</h5>{' '}
                      <button
                        type='button'
                        className='btn btn-primary3'
                        data-toggle='modal'
                        data-target='#ModalHEA'
                      >
                        Ver HEA
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShowHeaModal heaHistory={dataHeaPatient} />
    </div>
  );
}

export default ReasonCheck;
