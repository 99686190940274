import React from 'react';
import {Link} from 'react-router-dom';
import ChangePassword from '../../Components/ChangePassword';
import FooterLogin from './FooterLogin';

const ChangePass = ({match}) => (
  <>
    <div className="d-block d-sm-block d-md-none">
      <div className="container text-center pt-3 login-menu">
        <Link to={{pathname: 'https://www.kielsa.com'}} target="_blank">
          <img
            src={require('../../assets/img/icons/farmaciakielsaacceso.png')}
            className="img-fluid mr-2"
            alt="farmacias kielsa"
            style={{width: '130px'}}
          />
        </Link>
        <Link to="/login" className="text-white text-uppercase ml-1 mr-1">
          Iniciar Sesión
        </Link>
        <Link to="/registerPacient" className="text-white text-uppercase ml-2">
          Registro
        </Link>
      </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light d-none d-sm-none d-md-block border-0 nav-overlapping bg-primary-movil">
      <div className="container-fluid">
        <ul className="nav ml-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link text-white text-uppercase">
              Inicio
            </Link>
          </li>
          <li className="nav-item">
            <Link to={{pathname: 'https://www.kielsa.com'}} target="_blank">
              <img
                src={require('../../assets/img/icons/farmaciakielsaacceso.png')}
                className="img-fluid ml-2"
                alt="farmacias kielsa"
                style={{width: '160px'}}
              />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/login" className="nav-link text-white text-uppercase">
              Iniciar Sesión
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/registerPacient"
              className="nav-link text-white text-uppercase"
            >
              Registro
            </Link>
          </li>
        </ul>
      </div>
    </nav>
    <ChangePassword match={match} />
    <FooterLogin />
  </>
);
export default ChangePass;
