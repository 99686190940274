import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import { patientGetPlan } from '../api/packagektm';
const CardCashPackageKtm = ({ token }) => {
  const [code, setCode] = useState('');
  const handleSubmit = () => {
    //patientGetPackageKtm({code, token}).then(() => {
    patientGetPlan({ code, token })
      .then(() => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Se canjeo el paquete de manera exitosa.',
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        if (error.info) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: error.info,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title:
              'Lo sentimos ocurrió un error al canjear el código. Por favor comunicarse con soporte.',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };
  return (
    <div className="sales h-100">
      <h4 className="text-center text-primary mt-3 mb-3">
        <i className="fas fa-coins" /> Canjear Plan Médico
      </h4>
      <div className="form-group">
        <input
          type="text"
          className="form-control text-center m-auto col-12 col-md-7"
          value={code}
          onChange={(e) => setCode(e.currentTarget.value)}
          placeholder="Ingrese el código aqui"
        />
      </div>
      <div className="form-group text-center">
        <button
          onClick={() => {
            handleSubmit();
          }}
          type="button"
          className="btn btn-primary btn-block m-auto col-12 col-md-7"
        >
          Canjear
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CardCashPackageKtm);
