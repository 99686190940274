/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { CgClose } from 'react-icons/all';
import Swal from 'sweetalert2';
import createAlert from '../../../redux/actions/alert.action';
import { reschedule } from '../../../redux/actions/scheduling.action';
import { meses, dias } from '../../../utils/calendar';
import {
  closeModalReagendar,
  closeModalScheduleDetails,
} from '../../../redux/actions/ui.action';
import {
  getStatusTimeDoctor,
  disableLoadedStatusTime,
} from '../../../redux/actions/time.status.action';
import {
  deleteReferenceTimeDoctor,
  getReferenceTimeDoctor,
} from '../../../redux/actions/references';
import '../../../stylesheets/scheduling.css';

const ModalReagendar = ({
  closeModalReagendar,
  doctorTimetable,
  openReagendar,
  reschedule,
  details,
  closeModalScheduleDetails,
  getStatusTimeDoctor,
  disableLoadedStatusTime,
  timeStatusDoctor,
  loaded,
  doctorReference,
  deleteReferenceTimeDoctor,
  user: { firstName, lastName },
}) => {
  const [formData, setFormData] = useState({
    reason: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
    hour: '',
  });

  const { date } = formData;
  const [weekDays, setWeekDays] = useState([]);
  const [horarios, setHorarios] = useState([]);

  useEffect(() => {
    if (doctorTimetable !== null) {
      // recorrer horario de doctor
      const tempHorario = [];
      doctorTimetable.forEach((horario) => {
        // initial hour
        let initial = parseInt(horario.from, 10);
        const final = parseInt(horario.to, 10);

        while (initial < final) {
          // para los primeros 20 minutos
          const primerosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:00`
              : `${initial}:00`;
          tempHorario.push({
            day: horario.day,
            hour: primerosVeinteMinutos,
          });
          // para los siguientes 20 minutos
          const segundosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:20`
              : `${initial}:20`;
          tempHorario.push({
            day: horario.day,
            hour: segundosVeinteMinutos,
          });
          // ultimos 20 minutos
          const ultimosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:40`
              : `${initial}:40`;
          tempHorario.push({
            day: horario.day,
            hour: ultimosVeinteMinutos,
          });
          initial += 1;
        }
      });
      setHorarios(tempHorario);

      // colocar hora del dia seleccionado
      disableLoadedStatusTime();
      const tempDays = [];
      let temp = moment(date);

      // let temp = moment(new Date(pivot.clone().add(pivot.day() * -1, 'day')));
      tempDays.push(temp);

      for (let i = 0; i < 6; i++) {
        temp = moment(new Date(temp.clone().add(1, 'day')));
        tempDays.push(temp);
      }
      setWeekDays(tempDays);
      getStatusTimeDoctor(tempDays, doctorReference._id);
    }
  }, [doctorTimetable]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === 'date') {
      disableLoadedStatusTime();
      const tempDays = [];
      let temp = moment(e.target.value);

      // let temp = moment(new Date(pivot.clone().add(pivot.day() * -1, 'day')));
      tempDays.push(temp);

      for (let i = 0; i < 6; i++) {
        temp = moment(new Date(temp.clone().add(1, 'day')));
        tempDays.push(temp);
      }
      setWeekDays(tempDays);
      getStatusTimeDoctor(tempDays, doctorReference._id);
    }
  };

  const handleCloseReagendar = () => {
    closeModalReagendar();
    deleteReferenceTimeDoctor();
  };

  const handleSubmit = (hour, day) => {
    // const temp = moment(weekDays[day], 'YYYY-MM-DD');
    const index = weekDays.findIndex(
      (item) => item.day().toString() === day.toString()
    );
    const temp = moment(weekDays[index], 'YYYY-MM-DD');

    const toDay = new Date();
    const dia = temp.format('D');
    const month = temp.format('M');
    const year = temp.format('YYYY');
    const selectedDate = new Date(
      year,
      parseInt(month, 10) - 1,
      dia,
      parseInt(hour, 10)
    );

    if (selectedDate.getTime() <= toDay.getTime()) {
      createAlert(
        '¡Error!',
        'La fecha seleccionada ya no se encuentra disponible.',
        'error'
      );
    } else {
      Swal.fire({
        title: '¿Reagendar Cita?',
        text: `Desea actualizar su cita al día ${moment(temp).format(
          'LL'
        )} a las ${hour} ${hour.substr(0, 2) >= 12 ? 'PM' : 'AM'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          const doc = {
            id: details._id,
            hour: hour,
            date: temp,
            firstName: firstName,
            lastName: lastName,
          };
          reschedule(doc);
          closeModalReagendar();
          closeModalScheduleDetails();
          Swal.fire('¡Confirmado!', 'Su cita ha sido actualizada.', 'success');
        }
      });
    }
  };

  return (
    <Modal
      show={openReagendar}
      centered
      onHide={handleCloseReagendar}
      dialogClassName='reagendar-dialog'
      backdropClassName='backdrop-style'
    >
      <Modal.Header className='border-bottom-0 mt-2 mx-auto'>
        <Modal.Title>
          <h5 className='modal-title text-primary font-weight-bold'>
            Reagendar cita
          </h5>
        </Modal.Title>
        <CgClose
          size={24}
          onClick={handleCloseReagendar}
          style={{
            marginRight: 5,
            marginTop: 3,
            right: 15,
            cursor: 'pointer',
            position: 'absolute',
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div style={{ margin: 20 }}>
          <form>
            <div className='form-group'>
              <span style={{ display: 'flex' }}>Fecha de cita</span>
              <input
                type='date'
                className='form-control center'
                placeholder='Fecha de cita'
                value={date}
                onChange={(e) => handleChange(e)}
                name='date'
                min={moment(new Date()).format('YYYY-MM-DD')}
              />
            </div>
            <div className='section-scrollable mt-4'>
              <div>Horario disponible</div>
              {weekDays.length !== 0 ? (
                <>
                  {!loaded ? (
                    <Spinner animation='border' variant='primary' />
                  ) : (
                    <table className='table table-sm table-borderless'>
                      <thead>
                        <tr className='text-primary text-center'>
                          {weekDays.map((day, index) => (
                            <th scope='col' key={index}>
                              {dias.map((dia) => {
                                if (dia.number === index) {
                                  return dia.abbr;
                                }
                                return null;
                              })}
                              <br />
                              <small className='text-black-50'>
                                {day.date()}
                                {meses.map((mes) => {
                                  if (mes.number === day.month()) {
                                    return mes.abbr;
                                  }
                                  return null;
                                })}
                              </small>
                            </th>
                          ))}
                        </tr>
                      </thead>

                      {/* MAPEADO DE LOS HORARIOS DEL DOCTOR */}
                      <tbody className='text-center text-black-50'>
                        <tr>
                          {weekDays.map((week) => (
                            <td key={week.day()}>
                              {horarios.map(
                                (horario, index) =>
                                  parseInt(week.day(), 10) + 1 ===
                                    parseInt(horario.day, 10) && (
                                    <tr key={index}>
                                      <td scope='row'>
                                        <button
                                          type='button'
                                          className={`btn btn-light btn-sm text-secondarymdv font-weight-bold bg-blue-light button-disabled-hour
                                            ${
                                              timeStatusDoctor.some(
                                                (item) =>
                                                  item.hour.toString() ===
                                                    horario.hour &&
                                                  item.day.toString() ===
                                                    week.day().toString()
                                              ) && 'disable-button-hour'
                                            }`}
                                          disabled={timeStatusDoctor.some(
                                            (item) =>
                                              item.hour.toString() ===
                                                horario.hour &&
                                              item.day.toString() ===
                                                week.day().toString()
                                          )}
                                          onClick={() =>
                                            handleSubmit(
                                              horario.hour,
                                              week.day().toString()
                                            )
                                          }
                                        >
                                          {horario.hour}
                                        </button>
                                      </td>
                                    </tr>
                                  )
                              )}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              ) : (
                <div>
                  seleccione una fecha para poder visualizar los horarios
                  disponibles del Doctor
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedItem: state.accounts.selectedItem,
  modalAddScheduling: state.ui.schedulingModal.open,
  user: state.auth.user,
  doctorReference: state.reference.userRef,
  doctorTimetable: state.reference.timeTable,
  presential: state.scheduling.presentialConsultation,
  online: state.scheduling.onlineConsultation,
  openReagendar: state.ui.modalReagendar.open,
  details: state.scheduling.details,
  loaded: state.statusTime.loaded,
  timeStatusDoctor: state.statusTime.items,
  consultories: state.reference.consultories,
  selectedConsultory: state.consultories.selectedConsultory,
});

export default connect(mapStateToProps, {
  closeModalReagendar,
  closeModalScheduleDetails,
  reschedule,
  getStatusTimeDoctor,
  disableLoadedStatusTime,
  deleteReferenceTimeDoctor,
  getReferenceTimeDoctor,
})(ModalReagendar);
