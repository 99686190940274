import axios from 'axios';

export const url = '/api/product/kielsa/';

export const searchProduct = ({ token, name, laboratorio }) =>
  new Promise((resolve, reject) => {
    if (token && name) {
      axios
        .post(
          `${url}/search`,
          {
            name,
            laboratorio,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Permiso denegado',
      });
    }
  });

export const validProductKielsa = ({
  token,
  articuloId,
  quantity,
  idVideoconference,
}) =>
  new Promise((resolve, reject) => {
    if (token && articuloId && quantity && idVideoconference) {
      axios
        .post(
          `${url}/valid/product`,
          {
            articuloId,
            quantity,
            idVideoconference,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else if (!articuloId) {
      reject({
        status: 'error',
        info: 'Identificador del articulo no definido',
      });
    } else if (!quantity) {
      reject({
        status: 'error',
        info: 'Cantidad del producto no definida',
      });
    } else {
      reject({
        status: 'error',
        info: 'Consulta no definida',
      });
    }
  });
