/* eslint-disable func-names */
/* eslint-disable arrow-parens */
import {
  GET_BANKS_ACCOUNTS,
  DELETE_BANKS_ACCOUNTS,
  UPDATE_BANKS_ACCOUNTS,
  ADD_BANKS_ACCOUNTS,
  ADD_SELECTED_BANK,
  DELETE_SELECTED_BANK,
} from '../types';

const initialState = {
  items: [],
  selectedItem: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BANKS_ACCOUNTS:
      return {
        ...state,
        items: payload,
      };

    case ADD_BANKS_ACCOUNTS:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case DELETE_BANKS_ACCOUNTS:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload),
      };
    case UPDATE_BANKS_ACCOUNTS:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === payload._id) {
            return {
              ...item,
              bank: payload.bank,
              number: payload.number,
              type: payload.type,
            };
          }
          return item;
        }),
      };
    case ADD_SELECTED_BANK:
      return {
        ...state,
        selectedItem: payload,
      };
    case DELETE_SELECTED_BANK:
      return {
        ...state,
        selectedItem: null,
      };
    default:
      return state;
  }
}
