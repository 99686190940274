import React from 'react';
import EnterpriseLog from '../../Components/Enterprise/EnterpriseLog';

const EnterpriseLogPage = () => (

  <EnterpriseLog />

);

export default EnterpriseLogPage;
