/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Link} from 'react-router-dom';

function Hero() {
  return (
    <>
      {/* vista web */}
      <div className="container-fluid animate__animated animate__fadeInLeft d-none d-sm-none d-md-block">
        <div className="grid-layout-hero">
          <div className="box-text">
            <div className="row">
              <div className="col-12">
                <h2 className="text-primary font-weight-bold display-4 pt-5">¿Necesitas Orientación Médica?</h2>
              </div>
              <div className="col-12">
                <h4
                  className="text-primary mb-5 font-weight-light text-responsive"
                >
                  Regístrate en nuestra plataforma y comienza a recibir
                  orientación médica en segundos,
                  con médicos generales,
                  médicos especialistas, acreditados y certificados.
                </h4>
              </div>
              <div className="col-12 col-sm-5 col-md-5">
                <Link to="/registerpacient" className="btn btn-primary btn-lg font-weight-bold btn-block mb-5">Regístrar</Link>
              </div>
              <div className="col-12">
                <div className="importante">
                  <h3 className="text-dark font-weight-bold">
                    Importante:
                  </h3>
                  <h4 className="text-dark text-responsive">
                    Si usted tiene una emergencia llame al 911.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* vista móvil */}
      <div className="bg-primarymdv animate__animated animate__fadeIn d-block d-sm-block d-md-none">
        <div className="container pt-4 text-center">
          <img
            src={require('../assets/img/doctora-movil.png')}
            className="img-fluid rounded-pill"
            width="260"
          />
          <h3 className="text-white mt-4 font-weight-bold">¿Necesitas Orientación Médica?</h3>
          <p
            className="mb-4"
          >
            Regístrate en nuestra plataforma y comienza a recibir
            orientación médica en segundos,
            {' '}
            <br />
            con médicos generales,
            médicos especialistas, acreditados y certificados.
          </p>
          <div className="d-inline">
            <Link
              type="button"
              className="btn bg-yellow mr-2 font-weight-bold"
              to="/login"
              style={{borderRadius: '0.5rem'}}
            >
              Iniciar Sesión
            </Link>
            O
            <Link
              type="button"
              className="btn btn-light ml-2 text-primary font-weight-bold"
              to="/registerPacient"
              style={{borderRadius: '0.5rem'}}
            >
              Resgistrarse
            </Link>
          </div>
          <br />
          {' '}
          <br />
          <br />
          <h5 className="text-yellow font-weight-bold mb-0">
            Importante
          </h5>
          <h6 className="text-white">
            Si usted tiene una emergencia llame al 911.
          </h6>
          <br />
        </div>
      </div>
    </>
  );
}

export default Hero;
