import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import specialties from './specialties';
import history from './history';
import graphic from './graphic';
import prices from './prices';
import shopping from './shopping';
import creditCards from './creditCards';
import accounts from './banks.accounts';
import scheduling from './scheduling';
import reference from './references';
import notifications from './notifications';
import retreat from './retreats';
import videoconference from './videoconference';
import ui from './ui';
import statusTime from './statusTime';
import physicalExam from './physicalExam';
import presential from './presential';
import packagektm from './packagektm';
import physicalTest from './physicalTest';
import consultories from './consultories';
import telemedicinePlan from './telemedicinePlan';
import register from './register';
import followUp from './follow.up';
import pagination from './pagination';
import plans from './plans';
import enterprise from './enterprise';
import searchingPatient from './searchingPatient';
export default combineReducers({
  register,
  auth,
  profile,
  specialties,
  history,
  graphic,
  prices,
  followUp,
  shopping,
  creditCards,
  accounts,
  scheduling,
  statusTime,
  reference,
  consultories,
  notifications,
  retreat,
  videoconference,
  physicalExam,
  presential,
  ui,
  packagektm,
  physicalTest,
  telemedicinePlan,
  pagination,
  plans,
  enterprise,
  searchingPatient,
});
