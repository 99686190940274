import { GET_PLANS } from '../types';

const initialState = {
  plans: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PLANS:
      return {
        ...state,
        plans: payload,
      };

    default:
      return state;
  }
}
