import axios from 'axios';
import {
  ADD_VACCINATE,
  GET_VACCINATE,
  DELETE_VACCINATE,
  UPDATE_VACCINATE,
  SELECTED_VACCINATE,
} from '../types';

export const getvaccinate = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/vaccinate/${patient}`);
    dispatch({
      type: GET_VACCINATE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addvaccinate = (name, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    name: name,
    patientID: patientID,
  });

  try {
    const res = await axios.post('/api/patient/history/vaccinate', doc, config);

    dispatch({
      type: ADD_VACCINATE,
      payload: res.data,
    });
    if (res.data.name === 'Otros') {
      dispatch({
        type: SELECTED_VACCINATE,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedVaccinate = (item) => (dispatch) => {
  dispatch({
    type: SELECTED_VACCINATE,
    payload: item,
  });
};
export const updateVaccinate = (id, other) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    other: other,
  });

  try {
    const res = await axios.put(
      `/api/patient/history/vaccinate/${id}`,
      doc,
      config
    );
    dispatch({
      type: UPDATE_VACCINATE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const updateDeleteVaccinate = (id, other) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    other: other,
  });

  try {
    const res = await axios.put(
      `/api/patient/history/vaccinate/delete/other/${id}`,
      doc,
      config
    );
    dispatch({
      type: UPDATE_VACCINATE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deletevaccinate = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/vaccinate/${id}`);
    dispatch({
      type: DELETE_VACCINATE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};
