import axios from 'axios';

export const url = '/api/payment';

export const payConsult = ({ token, shoppingCart }) =>
  new Promise((resolve, reject) => {
    if (token && shoppingCart && shoppingCart.length > 0) {
      axios
        .post(
          `${url}/pay`,
          {
            shoppingCart,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticacion del usuario',
      });
    } else if (shoppingCart.length === 0) {
      reject({
        status: 'error',
        info: 'El carrito de compras esta vacio',
      });
    } else {
      reject({
        status: 'error',
        info: 'Carrito de compras no esta definido',
      });
    }
  });

export const payCreditCardTelemedicinePlan = ({ token, idTelemedicinePlan }) =>
  new Promise((resolve, reject) => {
    if (token && idTelemedicinePlan) {
      axios
        .post(
          `${url}/pay/credit/telemedicine`,
          {
            idTelemedicinePlan,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else if (!idTelemedicinePlan) {
      reject({
        status: 'error',
        info: 'Plan no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const payCreditCardPackageEnterprise = ({ token, employees, total }) =>
  new Promise((resolve, reject) => {
    if (token && employees.length > 0) {
      const ruta = '/api/companies/';
      axios
        .post(
          `${ruta}/payPackages`,
          { employees, total },
          { headers: { Authorization: token } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else if (employees.length == 0) {
      reject({
        status: 'error',
        info: 'Lista vacia',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const payCreditCardSubscription = ({ token, selectedPackage, total }) =>
  new Promise((resolve, reject) => {
    if (token) {
      const ruta = '/api/subscription';
      axios
        .post(
          `${ruta}/addSubscription`,
          { selectedPackage, total },
          { headers: { Authorization: token } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            //console.log("ESTADO: ",data.status);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const payCreditCardPackage = ({ token, idPackage }) =>
  new Promise((resolve, reject) => {
    if (token && idPackage) {
      axios
        .post(
          `${url}/pay/credit/package`,
          {
            idPackage,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    } else if (!idPackage) {
      reject({
        status: 'error',
        info: 'Paquete no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const payPaypalPackage = ({
  token,
  paypalPayerId,
  paypalOrderId,
  paypalEmail,
  idPackage,
  amount,
}) =>
  new Promise((resolve, reject) => {
    if (
      token &&
      paypalPayerId &&
      paypalOrderId &&
      paypalEmail &&
      idPackage &&
      amount !== undefined
    ) {
      axios
        .post(
          `${url}/pay/paypal/package`,
          {
            paypalPayerId,
            paypalOrderId,
            paypalEmail,
            idPackage,
            amount,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!paypalPayerId) {
      reject({
        status: 'error',
        info: 'Identificador del usuario paypal no definido',
      });
    } else if (!paypalOrderId) {
      reject({
        status: 'error',
        info: 'Identificador de la orden de paypal no definido',
      });
    } else if (!paypalEmail) {
      reject({
        status: 'error',
        info: 'Correo del usuario paypal no definido',
      });
    } else if (!idPackage) {
      reject({
        status: 'error',
        info: 'Paquete no definido',
      });
    } else if (!amount) {
      reject({
        status: 'error',
        info: 'Monto no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const payKielsaCashPackage = ({ token, idPackage }) =>
  new Promise((resolve, reject) => {
    if (token && idPackage) {
      axios
        .post(
          `${url}/pay/kielsacash/package`,
          {
            idPackage,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Paquete no definido',
      });
    }
  });

export const getKielsaCash = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/kielsacash`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });
