/* eslint-disable react/jsx-indent */
/* eslint-disable function-paren-newline */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'moment/locale/es';
import moment from 'moment';
import Spinner from '../Spinner/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getProfileDoctorLoggedIn } from '../../api/doctor';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import {
  addProfileDoctorCvFile,
  addProfileImage,
} from '../../redux/actions/profile.action';
import { openModalProfileImage } from '../../redux/actions/ui.action';
import imgAvatarTemp from '../../assets/img/avatar1.jpg';
import { upcomingScheduling } from '../../api/scheduling';
import { totalVideoconference } from '../../api/videoconference';
import ModalProfileImage from '../../Components/Modal/AddProfileImageModal';

class HomeNurse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      statusDoctor: '',
      listUpcomingScheduling: [],
      total: 0,
      totalVideocall: 0,
      totalChat: 0,
    };
  }

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        this.setState({
          listUpcomingScheduling: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getProfileDoctorLoggedIn({ token })
      .then((res) => {
        const { profile } = res;
        this.setState({
          statusDoctor: profile.status,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
        alert('Error al obtener los datos');
      });
  }

  handleClickStatus = (text) => {
    const {
      auth: { user, token },
    } = this.props;
    const { _id, role } = user;
    changeStatusDoctor({ doctorId: _id, role: role, status: text }, () => {
      getProfileDoctorLoggedIn({ token })
        .then((res) => {
          const { profile } = res;
          this.setState({
            statusDoctor: profile.status,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  handleUploadCvFile = () => {
    const { documents } = this.props;
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileDoctorCvFile(formData, documents);
    };
    input.click();
  };

  handleUploadImage = () => {
    const {
      profile: { image },
      addProfileImage,
      openModalProfileImage,
    } = this.props;
    openModalProfileImage();
  };

  render() {
    const {
      loading,
      statusDoctor,
      listUpcomingScheduling,
      total,
      totalChat,
      totalVideocall,
    } = this.state;
    const {
      auth: { user },
      profile: {
        dni,
        country,
        cellphone,
        bornAt,
        university,
        experience,
        college,
        languages,
        specialty,
        subSpecialties,
        about,
        master,
        documents,
        rating,
      },
    } = this.props;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div>
        <ModalProfileImage />
        <div className="container-fluid">
          <div className="col-md-12 col-sm-12">
            <div className="user-dashboard">
              <h2 className="text-primary mt-5 mb-5">
                {`Bienvenid${user.gender === 'male' ? 'o' : 'a'}`}{' '}
                {user.gender === 'male' ? 'Enfro.' : 'Enfra.'}&nbsp;
                <b className="text-secondarymdv">{`${user.firstName.split(
                  ' ',
                  1
                )} ${user.lastName.split(' ', 1)}`}</b>
              </h2>
              <div className="row">
                <div className="col-md-7 col-sm-12 col-xs-12 gutter">
                  <div className="sales report">
                    <div className="row center">
                      <div className="col-12">
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">Cambiar foto</Tooltip>
                          }
                        >
                          <button
                            type
                            className="avatar-doctor-button-container"
                            onClick={this.handleUploadImage}
                          >
                            <div
                              style={{
                                backgroundImage: `${
                                  user.image === null ||
                                  user.image === '' ||
                                  user.image === undefined
                                    ? `url(${imgAvatarTemp})`
                                    : `url(/api/profile/image/${user.image})`
                                }`,
                                backgroundSize: 'cover',
                                borderColor: '#001689',
                              }}
                              className="media-object img-fluid img-circle img-doc-p"
                            />
                          </button>
                        </OverlayTrigger>
                      </div>
                      <div className="col-12">
                        <h3 className="text-primary">
                          {`${user.firstName.split(
                            ' ',
                            1
                          )} ${user.lastName.split(' ', 1)}`}
                        </h3>
                        <h5>
                          <span className="badge bg-light text-secondarymdv mt-2">
                            {specialty}
                          </span>
                        </h5>
                        <p className="text-dark">{about}</p>
                      </div>
                    </div>
                    <div className="row mt-2 no-gutters">
                      <div className="col-12 col-sm-12 col-md-6">
                        <div
                          className="card card-body bg-light m-1 border-0 h-100"
                          style={{ borderRadius: '1rem' }}
                        >
                          <h5 className="font-weight-bold mt-2 text-primary">
                            Perfil Personal
                          </h5>
                          <h6 className="text-primary">
                            Nombre Completo: &nbsp;
                            <small className="text-secondarymdv">{`${user.firstName.split(
                              ' ',
                              1
                            )} ${user.lastName.split(' ', 1)}`}</small>
                          </h6>
                          <h6 className="text-primary">
                            Correo Electrónico: &nbsp;
                            <small
                              className="text-secondarymdv text-wrap"
                              style={{ maxWidth: '125px' }}
                            >
                              {user.email}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            N° Identidad: &nbsp;
                            <small className="text-secondarymdv">{dni}</small>
                          </h6>
                          <h6 className="text-primary">
                            Fecha de Nacimiento: &nbsp;
                            <small className="text-secondarymdv">
                              {moment(bornAt).locale('es').format('L')}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Nacionalidad: &nbsp;
                            <small className="text-secondarymdv">
                              {country}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Género: &nbsp;
                            <small className="text-secondarymdv">
                              {user.gender === 'male'
                                ? 'Masculino'
                                : 'Femenino'}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            País: &nbsp;
                            <small className="text-secondarymdv">
                              {country}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Tel.: &nbsp;
                            <small className="text-secondarymdv">
                              {cellphone}
                            </small>
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div
                          className="card card-body bg-light m-1 border-0 h-100"
                          style={{ borderRadius: '1rem' }}
                        >
                          <h5 className="font-weight-bold mt-2 text-primary">
                            Perfil Profesional
                          </h5>
                          {/* <div className="row font-blue">
                          <h1>Perfil Profesional</h1>
                        </div> */}
                          <h6 className="text-primary">
                            Universidad: &nbsp;
                            <small className="text-secondarymdv">
                              {university}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Experiencia: &nbsp;
                            <small className="text-secondarymdv">
                              {' '}
                              {`${experience} ${
                                Number(experience) === 1 ? 'año' : 'años'
                              }`}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            N° Colegiatura: &nbsp;
                            <small className="text-secondarymdv">
                              {college}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Idiomas: &nbsp;
                            <small className="text-secondarymdv">
                              {' '}
                              {languages.length > 0
                                ? languages.map((language, index) => (
                                    <span key={index}>
                                      {`${language}${
                                        index !== languages.length - 1
                                          ? ', '
                                          : ''
                                      }`}
                                    </span>
                                  ))
                                : 'Sin especificar'}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Maestría: &nbsp;
                            <small className="text-secondarymdv">
                              {' '}
                              {master}
                            </small>
                          </h6>
                          <h6 className="text-primary">
                            Sub especialidades: &nbsp;
                            <small className="text-secondarymdv">
                              {' '}
                              {subSpecialties.length > 0
                                ? subSpecialties.map((sub, index) => (
                                    <span key={index}>
                                      {`${sub}${
                                        index !== subSpecialties.length - 1
                                          ? ', '
                                          : ''
                                      }`}
                                    </span>
                                  ))
                                : 'Sin especificar'}
                            </small>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 col-xs-12 gutter">
                  <div className="sales report marb3">
                    <div className="row  mar-2">
                      <h2 className="text-primary">Mis Consultas</h2>
                    </div>
                    <div className="bggray">
                      <div className="row  mar-2">
                        <Link className="btn-udoc" to="/dashboard/agenda">
                          <img
                            src={require('../../assets/img/icons/cita-medica.svg')}
                            alt="loginlogo"
                            className="icon-hm"
                            data-aos="fade-right"
                          />
                          <span className="hidden-xs hidden-sm pad-left">
                            Ver Agenda
                          </span>
                          <i className="fas fa-angle-right f-right icon-hm" />
                        </Link>
                      </div>
                      {/* <div className="row  mar-2">
                        <Link className="btn-udoc" to="/dashboard/profits">
                          <img
                            src={require("../../assets/img/icons/incomes.png")}
                            alt="loginlogo"
                            className="icon-hm"
                            data-aos="fade-right"
                          />
                          <span className="hidden-xs hidden-sm pad-left">
                            Ingresos
                          </span>
                          <i className="fas fa-angle-right f-right" />
                        </Link>
                      </div> */}
                      <div className="row mar-2">
                        <Link className="btn-udoc" to="/dashboard/details">
                          <img
                            src={require('../../assets/img/icons/interview.png')}
                            alt="loginlogo"
                            className="icon-hm"
                            data-aos="fade-right"
                          />
                          <span className="hidden-xs hidden-sm pad-left">
                            Detalle Consulta
                          </span>
                          <i className="fas fa-angle-right f-right icon-hm" />
                        </Link>
                      </div>
                      <div className="row  mar-2">
                        <Link className="btn-udoc" to="/dashboard/calls">
                          <img
                            src={require('../../assets/img/icons/hablar.svg')}
                            alt="loginlogo"
                            className="icon-hm"
                            data-aos="fade-right"
                          />
                          <span className="hidden-xs hidden-sm pad-left">
                            Detalle Llamadas
                          </span>
                          <i className="fas fa-angle-right f-right icon-hm" />
                        </Link>
                      </div>
                      <div className="row  mar-2">
                        <Link className="btn-udoc" to="/dashboard/patients">
                          <img
                            src={require('../../assets/img/icons/informe-medico.svg')}
                            alt="loginlogo"
                            className="icon-hm"
                            data-aos="fade-right"
                          />
                          <span className="hidden-xs hidden-sm pad-left">
                            Detalle Pacientes
                          </span>
                          <i className="fas fa-angle-right f-right icon-hm" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  addProfileDoctorCvFile,
  addProfileImage,
  openModalProfileImage,
})(HomeNurse);
