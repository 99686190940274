import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { openModalProfileImage } from '../../../redux/actions/ui.action';
import imgAvatarTemp from '../../../assets/img/avatar1.jpg';
import { useStatusDoctor } from './state/useStatusDoctor';
import { useLoading } from './state/useLoading';
import { changeStatusDoctor } from '../../../websokect/doctorWebsockets';
import { getProfileDoctorLoggedIn } from '../../../api/doctor';
import { getProfile } from '../../../redux/actions/profile.action';
import { useDispatch } from 'react-redux';
import moment from 'moment';

export const CardDetails = ({ auth, openModalProfileImage, profile }) => {
  const { statusDoctor, setStatusDoctor } = useStatusDoctor();
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = auth.token;

    getProfileDoctorLoggedIn({ token })
      .then((res) => {
        const { profile } = res;
        setStatusDoctor(profile.status);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert('Error al obtener los datos');
        setLoading(false);
      });
  }, [auth, setLoading, setStatusDoctor]);

  const handleUploadImage = () => {
    openModalProfileImage();
  };

  const handleClickStatus = (text) => {
    const token = auth.token;
    const _id = auth.user._id;
    const role = auth.user.role;
    changeStatusDoctor({ doctorId: _id, role: role, status: text }, () => {
      getProfileDoctorLoggedIn({ token })
        .then((res) => {
          const { profile } = res;
          setStatusDoctor(profile.status);
          dispatch(getProfile('doctor'));
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  return (
    <div className='sales report'>
      <div className='row center'>
        <div className='col-12'>
          <OverlayTrigger
            key='bottom'
            placement='bottom'
            overlay={<Tooltip id='tooltip-bottom'>Cambiar foto</Tooltip>}
          >
            <button
              type
              className='avatar-doctor-button-container'
              onClick={handleUploadImage}
            >
              <div
                style={{
                  backgroundImage: `${
                    auth.user.image === null ||
                    auth.user.image === '' ||
                    auth.user.image === undefined
                      ? `url(${imgAvatarTemp})`
                      : `url(/api/profile/image/${auth.user.image})`
                  }`,
                  backgroundSize: 'cover',
                  borderColor: '#001689',
                }}
                className='media-object img-fluid img-circle img-doc-p'
              />
            </button>
          </OverlayTrigger>
        </div>
        <div className='col-12'>
          <h3 className='text-primary'>
            {`${auth.user.firstName.split(' ', 1)} ${auth.user.lastName.split(
              ' ',
              1
            )}`}
          </h3>
          <h5>
            <span className='badge bg-light text-secondarymdv mt-2'>
              {profile.specialty}
            </span>
          </h5>
          <p className='text-dark'>{profile.about}</p>
          <div className='row center-div'>
            <label className='check-green margr'>
              <span>Disponible &nbsp;</span>
              {statusDoctor === 'available' ? (
                <i color='green' className='fas fa-check-circle' />
              ) : (
                <input
                  type='radio'
                  name='options1'
                  value='available'
                  checked={statusDoctor === 'available'}
                  onClick={() => handleClickStatus('available')}
                />
              )}
            </label>
            <label className='check-red margr'>
              <span>Ocupado &nbsp;</span>
              {statusDoctor === 'occupied' ? (
                <i color='green' className='fas fa-check-circle' />
              ) : (
                <input
                  type='radio'
                  name='options2'
                  value='occupied'
                  checked={statusDoctor === 'occupied'}
                  onClick={() => handleClickStatus('occupied')}
                />
              )}
            </label>
            <label className='check-yellow'>
              <span>No Disponible &nbsp;</span>
              {statusDoctor === 'not available' ? (
                <i color='green' className='fas fa-check-circle' />
              ) : (
                <input
                  type='radio'
                  name='options3'
                  value='not available'
                  checked={statusDoctor === 'not available'}
                  onClick={() => handleClickStatus('not available')}
                />
              )}
            </label>
            <h5 className='text-primary mt-2'>
              {profile.rating}/5 <i className='fas fa-star text-yellow'></i>
            </h5>
          </div>
        </div>
      </div>
      <div className='row mt-2 no-gutters'>
        <div className='col-12 col-sm-12 col-md-6'>
          <div
            className='card card-body bg-light m-1 border-0 h-100'
            style={{ borderRadius: '1rem' }}
          >
            <h5 className='font-weight-bold mt-2 text-primary'>
              Perfil Personal
            </h5>
            <div className='hr' />
            <h6 className='text-primary'>
              Nombre Completo: &nbsp;
              <small className='text-secondarymdv'>{`${auth.user.firstName.split(
                ' ',
                1
              )} ${auth.user.lastName.split(' ', 1)}`}</small>
            </h6>
            <h6 className='text-primary'>
              Correo Electrónico: &nbsp;
              <small
                className='text-secondarymdv text-wrap'
                style={{ maxWidth: '125px' }}
              >
                {auth.user.email}
              </small>
            </h6>
            <h6 className='text-primary'>
              N° Identidad:&nbsp;
              <small className='text-secondarymdv'>{auth.user.dni}</small>
            </h6>
            <>{profile.dni}</>
            <h6 className='text-primary'>
              Fecha de Nacimiento:&nbsp;
              <small className='text-secondarymdv'>
                {moment(profile.bornAt).locale('es').format('L')}
              </small>
            </h6>
            <h6 className='text-primary'>
              Nacionalidad:&nbsp;
              <small className='text-secondarymdv'>{profile.country}</small>
            </h6>
            <h6 className='text-primary'>
              Género:&nbsp;
              <small className='text-secondarymdv'>
                {auth.user.gender === 'male' ? 'Masculino' : 'Femenino'}
              </small>
            </h6>
            <h6 className='text-primary'>
              País:&nbsp;
              <small className='text-secondarymdv'>{profile.country}</small>
            </h6>
            <h6 className='text-primary'>
              Tel.:&nbsp;
              <small className='text-secondarymdv'>
                {profile.cellphone ? profile.cellphone : 'Sin especificar'}
              </small>
            </h6>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-6 '>
          <div
            className='card card-body bg-light m-1 border-0 h-100'
            style={{ borderRadius: '1rem' }}
          >
            <h5 className='font-weight-bold mt-2 text-primary'>
              Perfil Profesional
            </h5>
            <div className='hr' />
            {profile.university && (
              <h6 className='text-primary'>
                Universidad:&nbsp;
                <small className='text-secondarymdv'>
                  {profile.university}
                </small>
              </h6>
            )}
            {profile.experience && (
              <h6 className='text-primary'>
                Experiencia:&nbsp;
                <small className='text-secondarymdv'>
                  {`${profile.experience} ${
                    Number(profile.experience) === 1 ? 'año' : 'años'
                  }`}
                </small>
              </h6>
            )}
            {profile.college && (
              <h6 className='text-primary'>
                N° Colegiatura:&nbsp;
                <small className='text-secondarymdv'>{profile.college}</small>
              </h6>
            )}
            {profile.languages.length > 0 && (
              <h6 className='text-primary'>
                Idiomas:&nbsp;
                <small className='text-secondarymdv'>
                  {profile.languages.length > 0
                    ? profile.languages.map((language, index) => (
                        <span key={index}>
                          {`${language}${
                            index !== profile.languages.length - 1 ? ', ' : ''
                          }`}
                        </span>
                      ))
                    : 'Sin especificar'}
                </small>
              </h6>
            )}
            {profile.master && (
              <h6 className='text-primary'>
                Maestría:&nbsp;
                <small className='text-secondarymdv'>{profile.master}</small>
              </h6>
            )}
            {profile.subSpecialties.length > 0 && (
              <h6 className='text-primary'>
                Sub especialidades:&nbsp;
                <small className='text-secondarymdv'>
                  {profile.subSpecialties.length > 0
                    ? profile.subSpecialties.map((sub, index) => (
                        <span key={index}>
                          {`${sub}${
                            index !== profile.subSpecialties.length - 1
                              ? ', '
                              : ''
                          }`}
                        </span>
                      ))
                    : 'Sin especificar'}
                </small>
              </h6>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
});

export default connect(mapStateToProps, {
  openModalProfileImage,
})(CardDetails);
