/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import {
  getGraphicType,
  getGraphicCountry,
  getGraphicDuration,
} from '../../redux/actions/graphic.action';

function CallDetails({
  type,
  country,
  duration,
  getGraphicCountry,
  getGraphicDuration,
  getGraphicType,
}) {
  // grafica de tipo de llamadas
  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });
  let chat = 0;
  let video = 0;
  let llamada = 0;
  type.data.forEach((element) => {
    chat = element.chat;
    video = element.video;
    llamada = element.llamada;
  });
  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getGraphicCountry(desde, hasta);
    getGraphicDuration(desde, hasta);
    getGraphicType(desde, hasta);
  };
  const datatype = {
    labels: ['Videollamada', 'Chat', 'llamada'],
    datasets: [
      {
        label: 'Tipo de llamadas',
        data: [video, chat, llamada],
        backgroundColor: [
          'rgb(24, 106, 59)',
          'rgb(21, 67, 96)',
          'rgb(233, 25, 19)',
        ],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  // grafica de tiempo de llamada
  let lunes = 0;
  let martes = 0;
  let miercoles = 0;
  let jueves = 0;
  let viernes = 0;
  let sabado = 0;
  let domingo = 0;
  duration.data.forEach((element) => {
    if (element._id === 1) {
      domingo = element.totalpatient / 60;
    }
    if (element._id === 2) {
      lunes = element.totalpatient / 60;
    }
    if (element._id === 3) {
      martes = element.totalpatient / 60;
    }
    if (element._id === 4) {
      miercoles = element.totalpatient / 60;
    }
    if (element._id === 5) {
      jueves = element.totalpatient / 60;
    }
    if (element._id === 6) {
      viernes = element.totalpatient / 60;
    }
    if (element._id === 7) {
      sabado = element.totalpatient / 60;
    }
  });
  const datatime = {
    labels: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    datasets: [
      {
        label: 'Tiempo llamada',
        data: [domingo, lunes, martes, miercoles, jueves, viernes, sabado],
        fill: false,
        backgroundColor: 'rgb(5, 17, 17)',
        borderColor: 'rgb(146, 43, 33)',
      },
    ],
  };

  // grafica de pais
  let honduras = 0;
  let salvador = 0;
  let nicaragua = 0;
  country.data.forEach((element) => {
    honduras = element.Honduras;
    salvador = element.Salvador;
    nicaragua = element.Nicaragua;
  });
  const datacountry = {
    labels: ['Honduras', 'El Salvador', 'Nicaragua'],
    datasets: [
      {
        label: '# of Votes',
        data: [honduras, salvador, nicaragua],
        backgroundColor: [
          'rgb(91, 44, 111)',
          'rgb(24, 106, 59)',
          'rgb(21, 67, 96)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className='container-fluid'>
      <div className='user-dashboard'>
        <div className='row mt-4'>
          <div className='col-12 col-md-6 col-sm-12 col-xs-12 gutter mb-2'>
            <div className='sales'>
              <div className='row mb-4 mt-2'>
                <div className='col-12 col-md-5 mb-2'>
                  <div className='d-flex'>
                    <label className='text-black-50'>Desde: </label>
                    <input
                      type='date'
                      name='desde'
                      value={desde}
                      className='form-control'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-5 mb-2'>
                  <div className='d-flex'>
                    <label className='text-black-50'>Hasta: </label>
                    <input
                      type='date'
                      name='hasta'
                      value={hasta}
                      className='form-control'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <div className='d-flex'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      onClick={handleSubmit}
                      style={{ height: '33px' }}
                    >
                      Ver
                    </button>
                  </div>
                </div>
              </div>
              <div className='container'>
                {/* <span className="center font-blue">Tipo de llamada</span> */}
                <Bar data={datatype} options={options} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-sm-12 col-xs-12 gutter mb-2'>
            <div className='sales report'>
              <div className='container'>
                {/* <span className="center font-blue">Tiempo de llamada</span> */}
                <Line data={datatime} options={options} />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-sm-12 col-xs-12 gutter mb-2'>
            <div className='sales report'>
              <div className='container'>
                {/* <span className="center font-blue">Paises</span> */}
                <Pie data={datacountry} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToprops = (state) => ({
  type: state.graphic.graphicType,
  country: state.graphic.graphicCountry,
  duration: state.graphic.graphicDuration,
});

export default connect(mapStateToprops, {
  getGraphicCountry,
  getGraphicDuration,
  getGraphicType,
})(CallDetails);
