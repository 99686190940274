import axios from 'axios';

export const url = '/api/giftcard';

export const veryfyOrderNumber = async (orderNumber) => {
  return await axios.get(`${url}/verify/order/${orderNumber}`);
};

export const createGiftCard = async (giftCards, name, cellphone, email) => {
  const body = JSON.stringify({
    giftCards,
    name,
    cellphone,
    email,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(`${url}/create`, body, config);
};

export const getProductByCode = async (code) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.get(`${url}/check/code/${code}`, config);
};
export const getGiftCardValueByCode = async (code) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.get(`${url}/getGiftCardValue/code/${code}`, config);
};

export const createGiftPackageLog = async (code, packageId) => {
  const body = JSON.stringify({
    code,
    packageId,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return await axios.post(`${url}/add/package`, body, config);
};
