import socket from './socket';

export const observerDoctorOnline = (callback) => {
  socket.on('doctor.change', callback);
};

export const sendCall = ({ doctorId, role, idVideo, type }, callback) => {
  socket.emit(
    'sendCallNotification',
    {
      doctorId,
      role,
      idVideo,
      type,
    },
    callback
  );
};

export const observerReasonConsult = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/reason/patient`, callback);
};

export const observerDiagnosisConsult = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/diagnosis/patient`, callback);
};

export const observerIndicationConsult = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/indication/patient`, callback);
};

export const observerScheduleConsult = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/schedule/patient`, callback);
};

export const observeJitseMeet = (callback) => {
  socket.on('open-jitsi-meet', callback);
};

export const openJitsiMeetByPatient = (roomId) =>
  socket.emit('open-jitsi-meet', { roomId });
