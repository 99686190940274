/* eslint-disable arrow-parens */
import axios from 'axios';
import {
  ADD_USER_REFERENCE,
  DELETE_USER_REFERENCE,
  GET_DOCTOR_TIMETABLE,
  DELETE_REFERENCE_TIMETABLE,
  ADD_CONSULTORY_REFERENCE_OF_DOCTOR,
  DELETE_CONSULTORY_REFERENCE_OF_DOCTOR,
  GET_PROFILE_REFERENCE,
  DELETE_PROFILE_REFERENCE,
  ADD_USER_FLAG_BY_CHECKER,
  CLEAR_HISTORY,
  GET_PLAN_REFERENCE,
  CLEAN_PLAN_REFERENCE,
  GET_ALL_DEPENDENTS,
  CLEAN_ALL_REFERENCES,
  GET_PLANS_REFERENCE,
  ADD_SELECTED_PLAN,
  ADD_SELECTED_APPOINTMENT,
  GET_ALL_APPOINTMENT_REFERENCE,
  CLEAR_ALL_APPOINTMENT,
  CLEAR_SELECTED_APPOINTMENT,
  GET_DOCTOR_REFERENCE,
  DELETE_DOCTOR_REFERENCE,
} from '../types';

const url = '/api/users';
const plans_uri = '/api/plans';

export const getDoctorReference = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/${id}`);
    dispatch({
      type: GET_DOCTOR_REFERENCE,
      payload: res.data,
    });
    if (res.data.role !== 'patient') {
      dispatch(getAllConsultoriesOfDoctorByPatient(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteDoctorReference = () => ({
  type: DELETE_DOCTOR_REFERENCE,
});

export const getAllAppointmentByUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/public/${id}`);

    dispatch({
      type: GET_ALL_APPOINTMENT_REFERENCE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSelectedAppointment = (appointment) => ({
  type: ADD_SELECTED_APPOINTMENT,
  payload: appointment,
});

export const clearAllAppointment = () => ({
  type: CLEAR_ALL_APPOINTMENT,
  payload: [],
});

export const clearSelectedAppointment = () => ({
  type: CLEAR_SELECTED_APPOINTMENT,
});

export const cleanAllReferences = () => ({
  type: CLEAN_ALL_REFERENCES,
});

export const addSelectedPlan = (plan) => ({
  type: ADD_SELECTED_PLAN,
  payload: plan,
});

export const getPlansReference = (data) => ({
  type: GET_PLANS_REFERENCE,
  payload: data,
});

export const getAllDependents = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/all/dependents/${_id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_ALL_DEPENDENTS,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPlanReference = () => async (dispatch) => {
  try {
    const res = await axios.get(plans_uri);
    if (res.status === 200) {
      dispatch({
        type: GET_PLAN_REFERENCE,
        payload: res.data,
      });

      dispatch(getAllDependents(res.data?.plan.user));
    }
  } catch (error) {
    console.error(error);
  }
};
export const getPlanReferenceById =
  ({ patientId: userId }) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`${plans_uri}/${userId}`);

      if (res.status === 200) {
        dispatch({
          type: GET_PLAN_REFERENCE,
          payload: res.data?.plan,
        });

        dispatch(getAllDependents(res.data?.user));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const cleanPlanReference = () => async (dispatch) => ({
  type: CLEAN_PLAN_REFERENCE,
});

export const changeAddUserFlag = (data) => ({
  type: ADD_USER_FLAG_BY_CHECKER,
  payload: data,
});

export const clearHistory = () => ({
  type: CLEAR_HISTORY,
});

export const getAllConsultoriesOfDoctorByPatient =
  (doctorId) => async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/doctor/office/all/by/patient/${doctorId}`
      );
      dispatch({
        type: ADD_CONSULTORY_REFERENCE_OF_DOCTOR,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getReference = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/${id}`);
    dispatch({
      type: ADD_USER_REFERENCE,
      payload: res.data,
    });
    if (res.data.role !== 'patient') {
      dispatch(getAllConsultoriesOfDoctorByPatient(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProfileReference = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/reference/${id}`);
    dispatch({
      type: GET_PROFILE_REFERENCE,
      payload: res.data.profile,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteProfileReference = () => ({
  type: DELETE_PROFILE_REFERENCE,
  payload: null,
});

export const deleteReference = () => async (dispatch) => {
  dispatch({
    type: DELETE_USER_REFERENCE,
    payload: null,
  });
};

export const deleteReferenceTimeDoctor = () => async (dispatch) => {
  dispatch({
    type: DELETE_REFERENCE_TIMETABLE,
    payload: [],
  });
};
export const openModalCancelAppointment = () => async (dispatch) => {
  dispatch({
    type: DELETE_REFERENCE_TIMETABLE,
    payload: [],
  });
};

export const deleteConsultoriesReference = () => (dispatch) => {
  dispatch({
    type: DELETE_CONSULTORY_REFERENCE_OF_DOCTOR,
    payload: [],
  });
};

export const getReferenceTimeDoctor =
  (doctorId, consultoryId) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/timetable/${consultoryId}/${doctorId}`);

      // reestructurar los horarios antes de guardarlos en el state
      const tempDataTimetable = [];

      if (res.data.status === 'success') {
        const doctorTimetable = res.data.data;

        // ###########   INICIO GENERAR NUEVOS HORARIOS ##########

        // ------   DOMINGO -------

        // convertir todas las horas a numeros
        const initialHourDomingo = parseInt(
          doctorTimetable.shiftHours.initialHourDomingo,
          10
        );

        const finalHourDomingo = parseInt(
          doctorTimetable.shiftHours.finalHourDomingo,
          10
        );

        const initialHourFreeDomingo = parseInt(
          doctorTimetable.shiftHours.initialHourFreeDomingo,
          10
        );

        const finalHourFreeDomingo = parseInt(
          doctorTimetable.shiftHours.finalHourFreeDomingo,
          10
        );

        // am pm cada variable
        const {
          initialAmPmDomingo,
          finalAmPmDomingo,
          initialAmPmFreeDomingo,
          finalAmPmFreeDomingo,
          freeHoursDomingo,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursDomingo) {
          // si el horario cuenta con horas libres
          if (initialAmPmDomingo === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmDomingo === 'am') {
              tempDataTimetable.push({
                day: '1',
                from:
                  initialHourDomingo > 9
                    ? `${initialHourDomingo}:00`
                    : `0${initialHourDomingo}:00`,
                to:
                  initialHourFreeDomingo > 9
                    ? `${initialHourFreeDomingo}:00`
                    : `0${initialHourFreeDomingo}:00`,
              });
              tempDataTimetable.push({
                day: '1',
                from:
                  finalHourFreeDomingo > 9
                    ? `${finalHourFreeDomingo}:00`
                    : `0${finalHourFreeDomingo}:00`,
                to:
                  finalHourDomingo > 9
                    ? `${finalHourDomingo}:00`
                    : `0${finalHourDomingo}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeDomingo === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '1',
                from:
                  initialHourDomingo > 9
                    ? `${initialHourDomingo}:00`
                    : `0${initialHourDomingo}:00`,
                to:
                  initialHourFreeDomingo > 9
                    ? `${initialHourFreeDomingo}:00`
                    : `0${initialHourFreeDomingo}:00`,
              });
              if (finalAmPmFreeDomingo === 'am') {
                tempDataTimetable.push({
                  day: '1',
                  from:
                    finalHourFreeDomingo > 9
                      ? `${finalHourFreeDomingo}:00`
                      : `0${finalHourFreeDomingo}:00`,
                  to:
                    finalHourDomingo === 12
                      ? `${finalHourDomingo}:00`
                      : `${finalHourDomingo + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '1',
                  from:
                    finalHourFreeDomingo === 12
                      ? `${finalHourFreeDomingo}:00`
                      : `${finalHourFreeDomingo + 12}:00`,
                  to:
                    finalHourDomingo === 12
                      ? `${finalHourDomingo}:00`
                      : `${finalHourDomingo + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeDomingo === 'pm') {
              tempDataTimetable.push({
                day: '1',
                from:
                  initialHourDomingo > 9
                    ? `${initialHourDomingo}:00`
                    : `0${initialHourDomingo}:00`,
                to:
                  initialHourFreeDomingo === 12
                    ? `${initialHourFreeDomingo}:00`
                    : `${initialHourFreeDomingo + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '1',
                from:
                  finalHourFreeDomingo === 12
                    ? `${finalHourFreeDomingo}:00`
                    : `${finalHourFreeDomingo + 12}:00`,
                to:
                  finalHourDomingo === 12
                    ? `${finalHourDomingo}:00`
                    : `${finalHourDomingo + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmDomingo === 'pm') {
            tempDataTimetable.push({
              day: '1',
              from:
                initialHourDomingo === 12
                  ? `${initialHourDomingo}:00`
                  : `${initialHourDomingo + 12}:00`,
              to:
                initialHourFreeDomingo === 12
                  ? `${initialHourFreeDomingo}:00`
                  : `${initialHourFreeDomingo + 12}:00`,
            });

            tempDataTimetable.push({
              day: '1',
              from:
                finalHourFreeDomingo === 12
                  ? `${finalHourFreeDomingo}:00`
                  : `${finalHourFreeDomingo + 12}:00`,
              to:
                finalHourDomingo === 12
                  ? `${finalHourDomingo}:00`
                  : `${finalHourDomingo + 12}:00`,
            });
          }
        } else if (initialAmPmDomingo === 'am') {
          if (finalAmPmDomingo === 'am') {
            tempDataTimetable.push({
              day: '1',
              from:
                initialHourDomingo > 9
                  ? `${initialHourDomingo}:00`
                  : `0${initialHourDomingo}:00`,
              to:
                finalHourDomingo > 9
                  ? `${finalHourDomingo}:00`
                  : `0${finalHourDomingo}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '1',
              from:
                initialHourDomingo > 9
                  ? `${initialHourDomingo}:00`
                  : `0${initialHourDomingo}:00`,
              to:
                finalHourDomingo === 12
                  ? `${finalHourDomingo}:00`
                  : `${finalHourDomingo + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '1',
            from:
              initialHourDomingo === 12
                ? `${initialHourDomingo}:00`
                : `${initialHourDomingo + 12}:00`,
            to:
              finalHourDomingo === 12
                ? `${finalHourDomingo}:00`
                : `${finalHourDomingo + 12}:00`,
          });
        } // fin de configuracion de hora domingo

        // ------   Lunes -------

        // convertir todas las horas a numeros
        const initialHourLunes = parseInt(
          doctorTimetable.shiftHours.initialHourLunes,
          10
        );

        const finalHourLunes = parseInt(
          doctorTimetable.shiftHours.finalHourLunes,
          10
        );

        const initialHourFreeLunes = parseInt(
          doctorTimetable.shiftHours.initialHourFreeLunes,
          10
        );

        const finalHourFreeLunes = parseInt(
          doctorTimetable.shiftHours.finalHourFreeLunes,
          10
        );

        // am pm cada variable
        const {
          initialAmPmLunes,
          finalAmPmLunes,
          initialAmPmFreeLunes,
          finalAmPmFreeLunes,
          freeHoursLunes,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursLunes) {
          // si el horario cuenta con horas libres
          if (initialAmPmLunes === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmLunes === 'am') {
              tempDataTimetable.push({
                day: '2',
                from:
                  initialHourLunes > 9
                    ? `${initialHourLunes}:00`
                    : `0${initialHourLunes}:00`,
                to:
                  initialHourFreeLunes > 9
                    ? `${initialHourFreeLunes}:00`
                    : `0${initialHourFreeLunes}:00`,
              });
              tempDataTimetable.push({
                day: '2',
                from:
                  finalHourFreeLunes > 9
                    ? `${finalHourFreeLunes}:00`
                    : `0${finalHourFreeLunes}:00`,
                to:
                  finalHourLunes > 9
                    ? `${finalHourLunes}:00`
                    : `0${finalHourLunes}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeLunes === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '2',
                from:
                  initialHourLunes > 9
                    ? `${initialHourLunes}:00`
                    : `0${initialHourLunes}:00`,
                to:
                  initialHourFreeLunes > 9
                    ? `${initialHourFreeLunes}:00`
                    : `0${initialHourFreeLunes}:00`,
              });
              if (finalAmPmFreeLunes === 'am') {
                tempDataTimetable.push({
                  day: '2',
                  from:
                    finalHourFreeLunes > 9
                      ? `${finalHourFreeLunes}:00`
                      : `0${finalHourFreeLunes}:00`,
                  to:
                    finalHourLunes === 12
                      ? `${finalHourLunes}:00`
                      : `${finalHourLunes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '2',
                  from:
                    finalHourFreeLunes === 12
                      ? `${finalHourFreeLunes}:00`
                      : `${finalHourFreeLunes + 12}:00`,
                  to:
                    finalHourLunes === 12
                      ? `${finalHourLunes}:00`
                      : `${finalHourLunes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeLunes === 'pm') {
              tempDataTimetable.push({
                day: '2',
                from:
                  initialHourLunes > 9
                    ? `${initialHourLunes}:00`
                    : `0${initialHourLunes}:00`,
                to:
                  initialHourFreeLunes === 12
                    ? `${initialHourFreeLunes}:00`
                    : `${initialHourFreeLunes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '2',
                from:
                  finalHourFreeLunes === 12
                    ? `${finalHourFreeLunes}:00`
                    : `${finalHourFreeLunes + 12}:00`,
                to:
                  finalHourLunes === 12
                    ? `${finalHourLunes}:00`
                    : `${finalHourLunes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmLunes === 'pm') {
            tempDataTimetable.push({
              day: '2',
              from:
                initialHourLunes === 12
                  ? `${initialHourLunes}:00`
                  : `${initialHourLunes + 12}:00`,
              to:
                initialHourFreeLunes === 12
                  ? `${initialHourFreeLunes}:00`
                  : `${initialHourFreeLunes + 12}:00`,
            });

            tempDataTimetable.push({
              day: '2',
              from:
                finalHourFreeLunes === 12
                  ? `${finalHourFreeLunes}:00`
                  : `${finalHourFreeLunes + 12}:00`,
              to:
                finalHourLunes === 12
                  ? `${finalHourLunes}:00`
                  : `${finalHourLunes + 12}:00`,
            });
          }
        } else if (initialAmPmLunes === 'am') {
          if (finalAmPmLunes === 'am') {
            tempDataTimetable.push({
              day: '2',
              from:
                initialHourLunes > 9
                  ? `${initialHourLunes}:00`
                  : `0${initialHourLunes}:00`,
              to:
                finalHourLunes > 9
                  ? `${finalHourLunes}:00`
                  : `0${finalHourLunes}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '2',
              from:
                initialHourLunes > 9
                  ? `${initialHourLunes}:00`
                  : `0${initialHourLunes}:00`,
              to:
                finalHourLunes === 12
                  ? `${finalHourLunes}:00`
                  : `${finalHourLunes + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '2',
            from:
              initialHourLunes === 12
                ? `${initialHourLunes}:00`
                : `${initialHourLunes + 12}:00`,
            to:
              finalHourLunes === 12
                ? `${finalHourLunes}:00`
                : `${finalHourLunes + 12}:00`,
          });
        } // fin de configuracion de hora Lunes

        // ------   Martes -------

        // convertir todas las horas a numeros
        const initialHourMartes = parseInt(
          doctorTimetable.shiftHours.initialHourMartes,
          10
        );

        const finalHourMartes = parseInt(
          doctorTimetable.shiftHours.finalHourMartes,
          10
        );

        const initialHourFreeMartes = parseInt(
          doctorTimetable.shiftHours.initialHourFreeMartes,
          10
        );

        const finalHourFreeMartes = parseInt(
          doctorTimetable.shiftHours.finalHourFreeMartes,
          10
        );

        // am pm cada variable
        const {
          initialAmPmMartes,
          finalAmPmMartes,
          initialAmPmFreeMartes,
          finalAmPmFreeMartes,
          freeHoursMartes,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursMartes) {
          // si el horario cuenta con horas libres
          if (initialAmPmMartes === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmMartes === 'am') {
              tempDataTimetable.push({
                day: '3',
                from:
                  initialHourMartes > 9
                    ? `${initialHourMartes}:00`
                    : `0${initialHourMartes}:00`,
                to:
                  initialHourFreeMartes > 9
                    ? `${initialHourFreeMartes}:00`
                    : `0${initialHourFreeMartes}:00`,
              });
              tempDataTimetable.push({
                day: '3',
                from:
                  finalHourFreeMartes > 9
                    ? `${finalHourFreeMartes}:00`
                    : `0${finalHourFreeMartes}:00`,
                to:
                  finalHourMartes > 9
                    ? `${finalHourMartes}:00`
                    : `0${finalHourMartes}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeMartes === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '3',
                from:
                  initialHourMartes > 9
                    ? `${initialHourMartes}:00`
                    : `0${initialHourMartes}:00`,
                to:
                  initialHourFreeMartes > 9
                    ? `${initialHourFreeMartes}:00`
                    : `0${initialHourFreeMartes}:00`,
              });
              if (finalAmPmFreeMartes === 'am') {
                tempDataTimetable.push({
                  day: '3',
                  from:
                    finalHourFreeMartes > 9
                      ? `${finalHourFreeMartes}:00`
                      : `0${finalHourFreeMartes}:00`,
                  to:
                    finalHourMartes === 12
                      ? `${finalHourMartes}:00`
                      : `${finalHourMartes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '3',
                  from:
                    finalHourFreeMartes === 12
                      ? `${finalHourFreeMartes}:00`
                      : `${finalHourFreeMartes + 12}:00`,
                  to:
                    finalHourMartes === 12
                      ? `${finalHourMartes}:00`
                      : `${finalHourMartes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeMartes === 'pm') {
              tempDataTimetable.push({
                day: '3',
                from:
                  initialHourMartes > 9
                    ? `${initialHourMartes}:00`
                    : `0${initialHourMartes}:00`,
                to:
                  initialHourFreeMartes === 12
                    ? `${initialHourFreeMartes}:00`
                    : `${initialHourFreeMartes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '3',
                from:
                  finalHourFreeMartes === 12
                    ? `${finalHourFreeMartes}:00`
                    : `${finalHourFreeMartes + 12}:00`,
                to:
                  finalHourMartes === 12
                    ? `${finalHourMartes}:00`
                    : `${finalHourMartes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmMartes === 'pm') {
            tempDataTimetable.push({
              day: '3',
              from:
                initialHourMartes === 12
                  ? `${initialHourMartes}:00`
                  : `${initialHourMartes + 12}:00`,
              to:
                initialHourFreeMartes === 12
                  ? `${initialHourFreeMartes}:00`
                  : `${initialHourFreeMartes + 12}:00`,
            });

            tempDataTimetable.push({
              day: '3',
              from:
                finalHourFreeMartes === 12
                  ? `${finalHourFreeMartes}:00`
                  : `${finalHourFreeMartes + 12}:00`,
              to:
                finalHourMartes === 12
                  ? `${finalHourMartes}:00`
                  : `${finalHourMartes + 12}:00`,
            });
          }
        } else if (initialAmPmMartes === 'am') {
          if (finalAmPmMartes === 'am') {
            tempDataTimetable.push({
              day: '3',
              from:
                initialHourMartes > 9
                  ? `${initialHourMartes}:00`
                  : `0${initialHourMartes}:00`,
              to:
                finalHourMartes > 9
                  ? `${finalHourMartes}:00`
                  : `0${finalHourMartes}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '3',
              from:
                initialHourMartes > 9
                  ? `${initialHourMartes}:00`
                  : `0${initialHourMartes}:00`,
              to:
                finalHourMartes === 12
                  ? `${finalHourMartes}:00`
                  : `${finalHourMartes + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '3',
            from:
              initialHourMartes === 12
                ? `${initialHourMartes}:00`
                : `${initialHourMartes + 12}:00`,
            to:
              finalHourMartes === 12
                ? `${finalHourMartes}:00`
                : `${finalHourMartes + 12}:00`,
          });
        } // fin de configuracion de hora Martes

        // ------   Miércoles -------

        // convertir todas las horas a numeros
        const initialHourMiércoles = parseInt(
          doctorTimetable.shiftHours.initialHourMiércoles,
          10
        );

        const finalHourMiércoles = parseInt(
          doctorTimetable.shiftHours.finalHourMiércoles,
          10
        );

        const initialHourFreeMiércoles = parseInt(
          doctorTimetable.shiftHours.initialHourFreeMiércoles,
          10
        );

        const finalHourFreeMiércoles = parseInt(
          doctorTimetable.shiftHours.finalHourFreeMiércoles,
          10
        );

        // am pm cada variable
        const {
          initialAmPmMiércoles,
          finalAmPmMiércoles,
          initialAmPmFreeMiércoles,
          finalAmPmFreeMiércoles,
          freeHoursMiércoles,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursMiércoles) {
          // si el horario cuenta con horas libres
          if (initialAmPmMiércoles === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmMiércoles === 'am') {
              tempDataTimetable.push({
                day: '4',
                from:
                  initialHourMiércoles > 9
                    ? `${initialHourMiércoles}:00`
                    : `0${initialHourMiércoles}:00`,
                to:
                  initialHourFreeMiércoles > 9
                    ? `${initialHourFreeMiércoles}:00`
                    : `0${initialHourFreeMiércoles}:00`,
              });
              tempDataTimetable.push({
                day: '4',
                from:
                  finalHourFreeMiércoles > 9
                    ? `${finalHourFreeMiércoles}:00`
                    : `0${finalHourFreeMiércoles}:00`,
                to:
                  finalHourMiércoles > 9
                    ? `${finalHourMiércoles}:00`
                    : `0${finalHourMiércoles}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeMiércoles === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '4',
                from:
                  initialHourMiércoles > 9
                    ? `${initialHourMiércoles}:00`
                    : `0${initialHourMiércoles}:00`,
                to:
                  initialHourFreeMiércoles > 9
                    ? `${initialHourFreeMiércoles}:00`
                    : `0${initialHourFreeMiércoles}:00`,
              });
              if (finalAmPmFreeMiércoles === 'am') {
                tempDataTimetable.push({
                  day: '4',
                  from:
                    finalHourFreeMiércoles > 9
                      ? `${finalHourFreeMiércoles}:00`
                      : `0${finalHourFreeMiércoles}:00`,
                  to:
                    finalHourMiércoles === 12
                      ? `${finalHourMiércoles}:00`
                      : `${finalHourMiércoles + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '4',
                  from:
                    finalHourFreeMiércoles === 12
                      ? `${finalHourFreeMiércoles}:00`
                      : `${finalHourFreeMiércoles + 12}:00`,
                  to:
                    finalHourMiércoles === 12
                      ? `${finalHourMiércoles}:00`
                      : `${finalHourMiércoles + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeMiércoles === 'pm') {
              tempDataTimetable.push({
                day: '4',
                from:
                  initialHourMiércoles > 9
                    ? `${initialHourMiércoles}:00`
                    : `0${initialHourMiércoles}:00`,
                to:
                  initialHourFreeMiércoles === 12
                    ? `${initialHourFreeMiércoles}:00`
                    : `${initialHourFreeMiércoles + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '4',
                from:
                  finalHourFreeMiércoles === 12
                    ? `${finalHourFreeMiércoles}:00`
                    : `${finalHourFreeMiércoles + 12}:00`,
                to:
                  finalHourMiércoles === 12
                    ? `${finalHourMiércoles}:00`
                    : `${finalHourMiércoles + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmMiércoles === 'pm') {
            tempDataTimetable.push({
              day: '4',
              from:
                initialHourMiércoles === 12
                  ? `${initialHourMiércoles}:00`
                  : `${initialHourMiércoles + 12}:00`,
              to:
                initialHourFreeMiércoles === 12
                  ? `${initialHourFreeMiércoles}:00`
                  : `${initialHourFreeMiércoles + 12}:00`,
            });

            tempDataTimetable.push({
              day: '4',
              from:
                finalHourFreeMiércoles === 12
                  ? `${finalHourFreeMiércoles}:00`
                  : `${finalHourFreeMiércoles + 12}:00`,
              to:
                finalHourMiércoles === 12
                  ? `${finalHourMiércoles}:00`
                  : `${finalHourMiércoles + 12}:00`,
            });
          }
        } else if (initialAmPmMiércoles === 'am') {
          if (finalAmPmMiércoles === 'am') {
            tempDataTimetable.push({
              day: '4',
              from:
                initialHourMiércoles > 9
                  ? `${initialHourMiércoles}:00`
                  : `0${initialHourMiércoles}:00`,
              to:
                finalHourMiércoles > 9
                  ? `${finalHourMiércoles}:00`
                  : `0${finalHourMiércoles}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '4',
              from:
                initialHourMiércoles > 9
                  ? `${initialHourMiércoles}:00`
                  : `0${initialHourMiércoles}:00`,
              to:
                finalHourMiércoles === 12
                  ? `${finalHourMiércoles}:00`
                  : `${finalHourMiércoles + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '4',
            from:
              initialHourMiércoles === 12
                ? `${initialHourMiércoles}:00`
                : `${initialHourMiércoles + 12}:00`,
            to:
              finalHourMiércoles === 12
                ? `${finalHourMiércoles}:00`
                : `${finalHourMiércoles + 12}:00`,
          });
        } // fin de configuracion de hora Miércoles

        // ------   Jueves -------

        // convertir todas las horas a numeros
        const initialHourJueves = parseInt(
          doctorTimetable.shiftHours.initialHourJueves,
          10
        );

        const finalHourJueves = parseInt(
          doctorTimetable.shiftHours.finalHourJueves,
          10
        );

        const initialHourFreeJueves = parseInt(
          doctorTimetable.shiftHours.initialHourFreeJueves,
          10
        );

        const finalHourFreeJueves = parseInt(
          doctorTimetable.shiftHours.finalHourFreeJueves,
          10
        );

        // am pm cada variable
        const {
          initialAmPmJueves,
          finalAmPmJueves,
          initialAmPmFreeJueves,
          finalAmPmFreeJueves,
          freeHoursJueves,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursJueves) {
          // si el horario cuenta con horas libres
          if (initialAmPmJueves === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmJueves === 'am') {
              tempDataTimetable.push({
                day: '5',
                from:
                  initialHourJueves > 9
                    ? `${initialHourJueves}:00`
                    : `0${initialHourJueves}:00`,
                to:
                  initialHourFreeJueves > 9
                    ? `${initialHourFreeJueves}:00`
                    : `0${initialHourFreeJueves}:00`,
              });
              tempDataTimetable.push({
                day: '5',
                from:
                  finalHourFreeJueves > 9
                    ? `${finalHourFreeJueves}:00`
                    : `0${finalHourFreeJueves}:00`,
                to:
                  finalHourJueves > 9
                    ? `${finalHourJueves}:00`
                    : `0${finalHourJueves}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeJueves === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '5',
                from:
                  initialHourJueves > 9
                    ? `${initialHourJueves}:00`
                    : `0${initialHourJueves}:00`,
                to:
                  initialHourFreeJueves > 9
                    ? `${initialHourFreeJueves}:00`
                    : `0${initialHourFreeJueves}:00`,
              });
              if (finalAmPmFreeJueves === 'am') {
                tempDataTimetable.push({
                  day: '5',
                  from:
                    finalHourFreeJueves > 9
                      ? `${finalHourFreeJueves}:00`
                      : `0${finalHourFreeJueves}:00`,
                  to:
                    finalHourJueves === 12
                      ? `${finalHourJueves}:00`
                      : `${finalHourJueves + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '5',
                  from:
                    finalHourFreeJueves === 12
                      ? `${finalHourFreeJueves}:00`
                      : `${finalHourFreeJueves + 12}:00`,
                  to:
                    finalHourJueves === 12
                      ? `${finalHourJueves}:00`
                      : `${finalHourJueves + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeJueves === 'pm') {
              tempDataTimetable.push({
                day: '5',
                from:
                  initialHourJueves > 9
                    ? `${initialHourJueves}:00`
                    : `0${initialHourJueves}:00`,
                to:
                  initialHourFreeJueves === 12
                    ? `${initialHourFreeJueves}:00`
                    : `${initialHourFreeJueves + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '5',
                from:
                  finalHourFreeJueves === 12
                    ? `${finalHourFreeJueves}:00`
                    : `${finalHourFreeJueves + 12}:00`,
                to:
                  finalHourJueves === 12
                    ? `${finalHourJueves}:00`
                    : `${finalHourJueves + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmJueves === 'pm') {
            tempDataTimetable.push({
              day: '5',
              from:
                initialHourJueves === 12
                  ? `${initialHourJueves}:00`
                  : `${initialHourJueves + 12}:00`,
              to:
                initialHourFreeJueves === 12
                  ? `${initialHourFreeJueves}:00`
                  : `${initialHourFreeJueves + 12}:00`,
            });

            tempDataTimetable.push({
              day: '5',
              from:
                finalHourFreeJueves === 12
                  ? `${finalHourFreeJueves}:00`
                  : `${finalHourFreeJueves + 12}:00`,
              to:
                finalHourJueves === 12
                  ? `${finalHourJueves}:00`
                  : `${finalHourJueves + 12}:00`,
            });
          }
        } else if (initialAmPmJueves === 'am') {
          if (finalAmPmJueves === 'am') {
            tempDataTimetable.push({
              day: '5',
              from:
                initialHourJueves > 9
                  ? `${initialHourJueves}:00`
                  : `0${initialHourJueves}:00`,
              to:
                finalHourJueves > 9
                  ? `${finalHourJueves}:00`
                  : `0${finalHourJueves}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '5',
              from:
                initialHourJueves > 9
                  ? `${initialHourJueves}:00`
                  : `0${initialHourJueves}:00`,
              to:
                finalHourJueves === 12
                  ? `${finalHourJueves}:00`
                  : `${finalHourJueves + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '5',
            from:
              initialHourJueves === 12
                ? `${initialHourJueves}:00`
                : `${initialHourJueves + 12}:00`,
            to:
              finalHourJueves === 12
                ? `${finalHourJueves}:00`
                : `${finalHourJueves + 12}:00`,
          });
        } // fin de configuracion de hora Jueves

        // ------   Viernes -------

        // convertir todas las horas a numeros
        const initialHourViernes = parseInt(
          doctorTimetable.shiftHours.initialHourViernes,
          10
        );

        const finalHourViernes = parseInt(
          doctorTimetable.shiftHours.finalHourViernes,
          10
        );

        const initialHourFreeViernes = parseInt(
          doctorTimetable.shiftHours.initialHourFreeViernes,
          10
        );

        const finalHourFreeViernes = parseInt(
          doctorTimetable.shiftHours.finalHourFreeViernes,
          10
        );

        // am pm cada variable
        const {
          initialAmPmViernes,
          finalAmPmViernes,
          initialAmPmFreeViernes,
          finalAmPmFreeViernes,
          freeHoursViernes,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursViernes) {
          // si el horario cuenta con horas libres
          if (initialAmPmViernes === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmViernes === 'am') {
              tempDataTimetable.push({
                day: '6',
                from:
                  initialHourViernes > 9
                    ? `${initialHourViernes}:00`
                    : `0${initialHourViernes}:00`,
                to:
                  initialHourFreeViernes > 9
                    ? `${initialHourFreeViernes}:00`
                    : `0${initialHourFreeViernes}:00`,
              });
              tempDataTimetable.push({
                day: '6',
                from:
                  finalHourFreeViernes > 9
                    ? `${finalHourFreeViernes}:00`
                    : `0${finalHourFreeViernes}:00`,
                to:
                  finalHourViernes > 9
                    ? `${finalHourViernes}:00`
                    : `0${finalHourViernes}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeViernes === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '6',
                from:
                  initialHourViernes > 9
                    ? `${initialHourViernes}:00`
                    : `0${initialHourViernes}:00`,
                to:
                  initialHourFreeViernes > 9
                    ? `${initialHourFreeViernes}:00`
                    : `0${initialHourFreeViernes}:00`,
              });
              if (finalAmPmFreeViernes === 'am') {
                tempDataTimetable.push({
                  day: '6',
                  from:
                    finalHourFreeViernes > 9
                      ? `${finalHourFreeViernes}:00`
                      : `0${finalHourFreeViernes}:00`,
                  to:
                    finalHourViernes === 12
                      ? `${finalHourViernes}:00`
                      : `${finalHourViernes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '6',
                  from:
                    finalHourFreeViernes === 12
                      ? `${finalHourFreeViernes}:00`
                      : `${finalHourFreeViernes + 12}:00`,
                  to:
                    finalHourViernes === 12
                      ? `${finalHourViernes}:00`
                      : `${finalHourViernes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeViernes === 'pm') {
              tempDataTimetable.push({
                day: '6',
                from:
                  initialHourViernes > 9
                    ? `${initialHourViernes}:00`
                    : `0${initialHourViernes}:00`,
                to:
                  initialHourFreeViernes === 12
                    ? `${initialHourFreeViernes}:00`
                    : `${initialHourFreeViernes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '6',
                from:
                  finalHourFreeViernes === 12
                    ? `${finalHourFreeViernes}:00`
                    : `${finalHourFreeViernes + 12}:00`,
                to:
                  finalHourViernes === 12
                    ? `${finalHourViernes}:00`
                    : `${finalHourViernes + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmViernes === 'pm') {
            tempDataTimetable.push({
              day: '6',
              from:
                initialHourViernes === 12
                  ? `${initialHourViernes}:00`
                  : `${initialHourViernes + 12}:00`,
              to:
                initialHourFreeViernes === 12
                  ? `${initialHourFreeViernes}:00`
                  : `${initialHourFreeViernes + 12}:00`,
            });

            tempDataTimetable.push({
              day: '6',
              from:
                finalHourFreeViernes === 12
                  ? `${finalHourFreeViernes}:00`
                  : `${finalHourFreeViernes + 12}:00`,
              to:
                finalHourViernes === 12
                  ? `${finalHourViernes}:00`
                  : `${finalHourViernes + 12}:00`,
            });
          }
        } else if (initialAmPmViernes === 'am') {
          if (finalAmPmViernes === 'am') {
            tempDataTimetable.push({
              day: '6',
              from:
                initialHourViernes > 9
                  ? `${initialHourViernes}:00`
                  : `0${initialHourViernes}:00`,
              to:
                finalHourViernes > 9
                  ? `${finalHourViernes}:00`
                  : `0${finalHourViernes}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '6',
              from:
                initialHourViernes > 9
                  ? `${initialHourViernes}:00`
                  : `0${initialHourViernes}:00`,
              to:
                finalHourViernes === 12
                  ? `${finalHourViernes}:00`
                  : `${finalHourViernes + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '6',
            from:
              initialHourViernes === 12
                ? `${initialHourViernes}:00`
                : `${initialHourViernes + 12}:00`,
            to:
              finalHourViernes === 12
                ? `${finalHourViernes}:00`
                : `${finalHourViernes + 12}:00`,
          });
        } // fin de configuracion de hora Viernes

        // ------   Sábado -------

        // convertir todas las horas a numeros
        const initialHourSábado = parseInt(
          doctorTimetable.shiftHours.initialHourSábado,
          10
        );

        const finalHourSábado = parseInt(
          doctorTimetable.shiftHours.finalHourSábado,
          10
        );

        const initialHourFreeSábado = parseInt(
          doctorTimetable.shiftHours.initialHourFreeSábado,
          10
        );

        const finalHourFreeSábado = parseInt(
          doctorTimetable.shiftHours.finalHourFreeSábado,
          10
        );

        // am pm cada variable
        const {
          initialAmPmSábado,
          finalAmPmSábado,
          initialAmPmFreeSábado,
          finalAmPmFreeSábado,
          freeHoursSábado,
        } = doctorTimetable.shiftHours;

        // si la hora inicial del dia es inicial

        if (freeHoursSábado) {
          // si el horario cuenta con horas libres
          if (initialAmPmSábado === 'am') {
            // si la hora final del doctor es por la mañana
            if (finalAmPmSábado === 'am') {
              tempDataTimetable.push({
                day: '7',
                from:
                  initialHourSábado > 9
                    ? `${initialHourSábado}:00`
                    : `0${initialHourSábado}:00`,
                to:
                  initialHourFreeSábado > 9
                    ? `${initialHourFreeSábado}:00`
                    : `0${initialHourFreeSábado}:00`,
              });
              tempDataTimetable.push({
                day: '7',
                from:
                  finalHourFreeSábado > 9
                    ? `${finalHourFreeSábado}:00`
                    : `0${finalHourFreeSábado}:00`,
                to:
                  finalHourSábado > 9
                    ? `${finalHourSábado}:00`
                    : `0${finalHourSábado}:00`,
              });
              // si entra a este if es porque la hora final es pm
            } else if (initialAmPmFreeSábado === 'am') {
              /* sabemos que la hora inicial siempre es am y que la hora inicial libre es tambien am por tanto
               abemos que siempre tenemos que agregar este elemento */
              tempDataTimetable.push({
                day: '7',
                from:
                  initialHourSábado > 9
                    ? `${initialHourSábado}:00`
                    : `0${initialHourSábado}:00`,
                to:
                  initialHourFreeSábado > 9
                    ? `${initialHourFreeSábado}:00`
                    : `0${initialHourFreeSábado}:00`,
              });
              if (finalAmPmFreeSábado === 'am') {
                tempDataTimetable.push({
                  day: '7',
                  from:
                    finalHourFreeSábado > 9
                      ? `${finalHourFreeSábado}:00`
                      : `0${finalHourFreeSábado}:00`,
                  to:
                    finalHourSábado === 12
                      ? `${finalHourSábado}:00`
                      : `${finalHourSábado + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              } else {
                tempDataTimetable.push({
                  day: '7',
                  from:
                    finalHourFreeSábado === 12
                      ? `${finalHourFreeSábado}:00`
                      : `${finalHourFreeSábado + 12}:00`,
                  to:
                    finalHourSábado === 12
                      ? `${finalHourSábado}:00`
                      : `${finalHourSábado + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
                });
              }
            } else if (initialAmPmFreeSábado === 'pm') {
              tempDataTimetable.push({
                day: '7',
                from:
                  initialHourSábado > 9
                    ? `${initialHourSábado}:00`
                    : `0${initialHourSábado}:00`,
                to:
                  initialHourFreeSábado === 12
                    ? `${initialHourFreeSábado}:00`
                    : `${initialHourFreeSábado + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
              tempDataTimetable.push({
                day: '7',
                from:
                  finalHourFreeSábado === 12
                    ? `${finalHourFreeSábado}:00`
                    : `${finalHourFreeSábado + 12}:00`,
                to:
                  finalHourSábado === 12
                    ? `${finalHourSábado}:00`
                    : `${finalHourSábado + 12}:00`, // porque la hora es amyor   que 12 entonces se debe de sumar este valor
              });
            }
          } else if (initialAmPmSábado === 'pm') {
            tempDataTimetable.push({
              day: '7',
              from:
                initialHourSábado === 12
                  ? `${initialHourSábado}:00`
                  : `${initialHourSábado + 12}:00`,
              to:
                initialHourFreeSábado === 12
                  ? `${initialHourFreeSábado}:00`
                  : `${initialHourFreeSábado + 12}:00`,
            });

            tempDataTimetable.push({
              day: '7',
              from:
                finalHourFreeSábado === 12
                  ? `${finalHourFreeSábado}:00`
                  : `${finalHourFreeSábado + 12}:00`,
              to:
                finalHourSábado === 12
                  ? `${finalHourSábado}:00`
                  : `${finalHourSábado + 12}:00`,
            });
          }
        } else if (initialAmPmSábado === 'am') {
          if (finalAmPmSábado === 'am') {
            tempDataTimetable.push({
              day: '7',
              from:
                initialHourSábado > 9
                  ? `${initialHourSábado}:00`
                  : `0${initialHourSábado}:00`,
              to:
                finalHourSábado > 9
                  ? `${finalHourSábado}:00`
                  : `0${finalHourSábado}:00`,
            });
          } else {
            tempDataTimetable.push({
              day: '7',
              from:
                initialHourSábado > 9
                  ? `${initialHourSábado}:00`
                  : `0${initialHourSábado}:00`,
              to:
                finalHourSábado === 12
                  ? `${finalHourSábado}:00`
                  : `${finalHourSábado + 12}:00`,
            });
          }
        } else {
          tempDataTimetable.push({
            day: '7',
            from:
              initialHourSábado === 12
                ? `${initialHourSábado}:00`
                : `${initialHourSábado + 12}:00`,
            to:
              finalHourSábado === 12
                ? `${finalHourSábado}:00`
                : `${finalHourSábado + 12}:00`,
          });
        } // fin de configuracion de hora Sábado
      }

      // FIN DE DIAS ENCIANDO EL DISPATCH

      dispatch({
        type: GET_DOCTOR_TIMETABLE,
        payload: tempDataTimetable,
      });
    } catch (error) {
      console.log(error);
    }
  };
