/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import ModalTermConditions from './Modal/ModalTermConditions';
import ModalPrivacyPolicies from './Modal/ModalPrivacyPolicies';
import ModalContact from './Modal/ModalContact';

function Footer() {
  const date = new Date();
  const yyyy = date.getFullYear();
  return (
    <>
      <ModalTermConditions />
      <ModalPrivacyPolicies />
      <ModalContact />
      <footer className="bg-white border-top border-secondary d-none d-sm-none d-md-block">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 border-right text-center">
              <h5 className="text-primary text-uppercase mt-5 mb-4">
                Oficina Principal
              </h5>
              <p className="text-justify mb-5">
                <i className="fas fa-map-pin mr-1" />
                <b>Tegucigalpa, Honduras</b>
                <br />
                Edificio Farinter, atrás de la Toyota, Barrio la Granja.
                <br />
                <i className="fas fa-phone-alt mr-1" /> (504) 2289-6544.
              </p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 border-right text-center">
              <h5 className="text-primary text-uppercase mt-5 mb-4">
                Información{' '}
              </h5>
              <ul className="list-unstyled list-group mb-5">
                <li className="text-left ml-3">
                  <span className="icon la la-angle-right">
                    <i className="fas fa-angle-right mr-1" />
                  </span>
                  <a
                    data-toggle="modal"
                    data-target="#ModalTermConditions"
                    data-whatever="@getbootstrap"
                    type="button"
                  >
                    Términos y Condiciones
                  </a>
                </li>
                <li className="text-left ml-3">
                  <span className="icon la la-angle-right">
                    <i className="fas fa-angle-right mr-1" />
                  </span>
                  <a
                    data-toggle="modal"
                    data-target="#ModalPrivacyPolicies"
                    data-whatever="@getbootstrap"
                    type="button"
                  >
                    Políticas de Privacidad
                  </a>
                </li>
                {/* <li className="text-left ml-3">
                  {' '}
                  <span className="icon la la-angle-right">
                    <i className="fas fa-angle-right mr-1" />
                  </span>
                  <a href="#">Preguntas Frecuentes</a>
                </li> */}
                <li className="text-left ml-3">
                  {' '}
                  <span className="icon la la-angle-right">
                    <i className="fas fa-angle-right mr-1" />
                  </span>
                  <a
                    data-toggle="modal"
                    data-target="#ModalContact"
                    data-whatever="@getbootstrap"
                    type="button"
                  >
                    Contáctanos
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-2 col-md-2 col-lg-2 border-right text-center">
              <h5 className="text-primary text-uppercase mt-5 mb-4">
                SíGUENOS{' '}
              </h5>
              <p className="social mb-5">
                <Link
                  to={{
                    pathname:
                      'https://www.facebook.com/Farmacia.Kielsa.Honduras',
                  }}
                  target="_blank"
                  type="button"
                >
                  <span className="icofont-facebook">
                    <i className="fab fa-facebook-f" />
                  </span>
                </Link>
                <Link
                  to={{ pathname: 'https://www.instagram.com/farmaciakielsa/' }}
                  target="_blank"
                  type="button"
                >
                  <span className="icofont-instagram">
                    <i className="fab fa-instagram" />
                  </span>
                </Link>
                <Link
                  to={{
                    pathname: 'https://www.youtube.com/user/FarmaciasKielsaHN',
                  }}
                  target="_blank"
                  type="button"
                >
                  <span className="icofont-youtube">
                    <i className="fab fa-youtube" />
                  </span>
                </Link>
              </p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 text-center">
              <h5 className="text-primary text-uppercase mt-5 mb-4">
                Métodos de pago
              </h5>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mb-5">
                <div className="col">
                  <img
                    src={require('../assets/img/vectores/paypal.svg')}
                    alt="paypal"
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <div className="d-flex">
                    <img
                      src={require('../assets/img/vectores/visa.svg')}
                      alt="visa"
                      width="60"
                      className="img-fluid mr-2"
                    />
                    <img
                      src={require('../assets/img/vectores/mastercard.svg')}
                      alt="mastercard"
                      width="50"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col">
                  <img
                    src={require('../assets/kielsacash.png')}
                    alt="kielsacash"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-primarymdv">
        <div className="container p-4 pb-4">
          <div className="text-center">
            Copyright © {yyyy} <b>KIELSA Clínica & Lab</b>. Todos los Derechos
            Reservados.
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
