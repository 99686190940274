import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Row, Col, InputGroup, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from '../../utils/countries';
import { getUserByEmailOrCellphoneOrDni } from '../../redux/actions/auth.action';
import { deleteReference } from '../../redux/actions/references';
import LabUserDetails from './LabUserDetails';

const LabDoctorAddUserData = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    dni: '',
    idType: 'national',
    typeSearch: 'email',
    dialCode: countries[0].dial_code,
    email: '',
    country: countries[0].name,
    cellphone: '',
  });
  const { userRef } = useSelector((state) => state.reference);

  const handleChange = (e) => {
    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
      });
    } else {
      if (userRef) {
        dispatch(deleteReference(null));
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const { dni, idType, typeSearch, email, country, dialCode, cellphone } =
    formData;

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    } else {
      return null;
    }
  };

  const handleSubmit = () => {
    if (typeSearch === 'email') {
      dispatch(
        getUserByEmailOrCellphoneOrDni({
          email,
        })
      );
    }
    if (typeSearch === 'cellphone') {
      dispatch(
        getUserByEmailOrCellphoneOrDni({
          dialCode,
          cellphone,
        })
      );
    }
    if (typeSearch === 'dni') {
      dispatch(
        getUserByEmailOrCellphoneOrDni({
          dni,
          idType,
        })
      );
    }
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        <div className="form-group">
          <label
            className="text-secondarymdv font-weight-light"
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            buscar por:
          </label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            name="typeSearch"
            value={typeSearch}
            placeholde="Tipo de identificación"
            onChange={handleChange}
          >
            <option value="email">Correo electrónico</option>
            <option value="dni">Identidad</option>
            <option value="cellphone">Teléfono</option>
          </select>
        </div>
        {typeSearch === 'email' && (
          <Col xs={12} style={{ padding: 0 }}>
            <div className="form-group">
              <input
                className="form-control"
                id="exampleFormControlSelect1"
                name="email"
                value={email}
                onChange={(e) => handleChange(e)}
                placeholder="Escriba su correo electrónico"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mb-3 font-weight-bold btn-block col-9 col-sm-5 col-md-5 mx-auto"
                onClick={handleSubmit}
                disabled={email.trim().length <= 0}
              >
                Buscar
              </button>
            </div>
          </Col>
        )}
        {typeSearch === 'cellphone' && (
          <Col xs={12} style={{ padding: 0 }}>
            <div>
              <Form.Control
                as="select"
                style={{ marginBottom: 15 }}
                onChange={handleChange}
                name="country"
                value={country}
              >
                {countries.map((count) => (
                  <option key={count.code}>{count.name}</option>
                ))}
              </Form.Control>
            </div>
            <div className="form-group">
              <InputGroup>
                <InputGroup.Prepend
                  value={dialCode}
                  name="dialCode"
                  onChange={handleChange}
                >
                  <InputGroup.Text
                    style={{
                      borderTopLeftRadius: 20,
                      borderBottomLeftRadius: 20,
                    }}
                  >
                    {dialCode}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Telefono"
                  name="cellphone"
                  value={cellphone}
                  onChange={handleChange}
                />
              </InputGroup>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mb-3 font-weight-bold btn-block col-9 col-sm-5 col-md-5 mx-auto"
                onClick={handleSubmit}
                disabled={cellphone.trim().length <= 0}
              >
                Buscar
              </button>
            </div>
          </Col>
        )}
        {typeSearch === 'dni' && (
          <Col xs={12} style={{ padding: 0 }}>
            <div className="form-group">
              <label
                className="text-secondarymdv font-weight-light"
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                Identidad
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="idType"
                value={idType}
                onChange={(e) => handleChange(e)}
                placeholde="Tipo de identificación"
              >
                <option value="national">ID nacional</option>
                <option value="foreign">ID extranjero</option>
                <option value="rtn">RTN</option>
              </select>
            </div>
            <div className="form-group">
              {idType === 'foreign' ? (
                <input
                  type="text"
                  className="form-control center"
                  name="dni"
                  value={dni}
                  onChange={handleChange}
                  placeholder="Identidad paciente"
                />
              ) : (
                <NumberFormat
                  className="form-control center"
                  format={`${setFormat()}`}
                  mask="_"
                  name="dni"
                  value={dni}
                  onChange={(e) => handleChange(e)}
                  placeholder="Identidad paciente"
                />
              )}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mb-3 font-weight-bold btn-block col-9 col-sm-5 col-md-5 mx-auto"
                onClick={handleSubmit}
                disabled={dni.trim().length <= 0}
              >
                Buscar
              </button>
            </div>
          </Col>
        )}
      </Col>
      <Col
        xs={12}
        md={6}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {userRef && <LabUserDetails />}
      </Col>
    </Row>
  );
};

export default LabDoctorAddUserData;
