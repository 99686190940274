import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {
  cancelSubscription,
  updateToSubscription,
} from '../../redux/actions/auth.action';

const ModalDescriptionProduct = ({
  planRef,
  cancelSubscription,
  updateToSubscription,
  user,
}) => {
  const dateOrder = (date) => {
    return (
      date?.substring(8, 10) +
      ' - ' +
      date?.substring(5, 7) +
      ' - ' +
      date?.substring(0, 4)
    );
  };
  const unSuscribe = (date) => {
    let text1 =
      'Te perderas los múltiples beneficios que ofrecemos \n Tu fecha de expiración sera hasta: ' +
      date;
    Swal.fire({
      title: '¿Estas seguro que deseas cancelar la subscripción?',
      text: text1,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'si',
      cancelButtonText: 'no',
    }).then((result) => {
      if (result.isConfirmed) {
        cancelSubscription(planRef._id);
      }
    });
  };

  const suscribe = () => {
    updateToSubscription(planRef._id);
  };

  return (
    <div
      className='modal fade'
      id='ModalDescriptionProduct'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='ModalDescriptionProduct'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title text-primary m-auto'>
              {planRef?.isSuscription ? <>Suscripción de </> : <></>}
              Plan {planRef?.plan?.name}
            </h3>
          </div>
          <div className='modal-body text-justify'>
            <label
              className='d-flex p-2'
              style={{ fontSize: '15px', color: '#797979' }}
            >
              {planRef?.isSuscription ? (
                <>
                  La fecha del próximo pago es:{' '}
                  {dateOrder(planRef?.dueDate?.substring(0, 10))}
                </>
              ) : (
                <>
                  La fecha de caducidad del pago es:{' '}
                  {dateOrder(planRef?.dueDate?.substring(0, 10))}
                </>
              )}
            </label>
            {planRef?.plan?.products?.map((item) => (
              <li
                className='list-group-item d-flex border-0 p-2'
                style={{ fontSize: '15px', color: '#797979' }}
                key={item._id}
              >
                <img
                  src={require('../../assets/img/vectores/comprobado2.svg')}
                  alt='check'
                  width='20'
                  className='img-fluid mr-2'
                />
                {item}
              </li>
            ))}
            <div style={{ marginTop: 15 }}>
              <>
                {!user?.idCompany &&
                  !user?.position &&
                  !user.isRegisterByDomain && (
                    <>
                      {planRef?.isSuscription ? (
                        <>
                          {planRef?.isSuscription === true ? (
                            <button
                              onClick={() =>
                                unSuscribe(
                                  dateOrder(planRef?.dueDate?.substring(0, 10))
                                )
                              }
                              type='button'
                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                            >
                              Cancelar suscripción
                            </button>
                          ) : (
                            <button
                              onClick={() => suscribe()}
                              type='button'
                              className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                            >
                              Suscribirse
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          onClick={() => suscribe()}
                          type='button'
                          className='btn btn-primary col-md-8 mb-3 m-auto d-block'
                        >
                          Suscribirse
                        </button>
                      )}
                    </>
                  )}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  planRef: state.reference.planRef,
  user: state.auth.user,
});
export default connect(mapStateToProps, {
  cancelSubscription,
  updateToSubscription,
})(ModalDescriptionProduct);
