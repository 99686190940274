/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import ChatDetails from '../Patient/ChatDetails';
import ChatHistory from '../Patient/ChatHistory';
import createAlert from '../../redux/actions/alert.action';
import {
  getOneVideoconference,
  endCallVideconference,
} from '../../api/videoconference';
import Loader from '../Spinner/Spinner';
import {
  sendMessage,
  observerMessageDoctor,
  observerEndConsult,
  endChatConsult,
} from '../../websokect/chatWebsockers';
import { uploadChatFile, getListMessages } from '../../api/chat';
import textMinute from '../../extra/minutes';
import {
  videoconferencesSeconds,
  sendDiagnosisConsult,
  sendReasonConsult,
  sendIndicationPatient,
  sendSchedulePatient,
} from '../../websokect/videoconferenceWebsocket';
import ChatHEA from '../Patient/ChatHEA';
import ChatIndications from '../Patient/ChatIndications';
import AddSchedule from '../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import ModalMedicalHistory from '../Modal/ModalMedicalHistory';
// NEW MODALS
import ModalVitalSigns from '../Modal/ModalVitalSigns';
import ModalNursingNotes from '../Modal/ModalNursingNotes';
import ModalPhysicalExam from '../Modal/ModalPhysicalExam';
import ModalMedicalOrders from '../Modal/ModalMedicalOrders';
import { addConsult } from '../../utils/isConsult';

import {
  getDoctorReference,
  getReference,
} from '../../redux/actions/references';
import { openModalAddScheduling } from '../../redux/actions/ui.action';

import { addVideoconference } from '../../redux/actions/videoconference.action';

class ChatScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      message: '',
      patient: undefined,
      dataVideoconference: undefined,
      loading: true,
      seconds: 0,
      section: 0,
      indications: [],
      reason: '',
      diagnosis: '',
      historyPatient: undefined,
      heaHistory: [],
      limitSeconds: 0,
      sendedReason: '',
      sendedDiagnosis: '',
      hea: '',
      schedule: undefined,
    };
  }

  componentDidMount() {
    const { user, token, match, addVideoconference } = this.props;
    const { messages } = this.state;
    const { params } = match;
    const { roomId } = params;
    getListMessages({ token: token, idVideo: roomId })
      .then((response) => {
        const { message } = response;
        this.setState({
          messages: message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    getOneVideoconference({ token: token, idVideo: roomId })
      .then((res) => {
        const {
          patient: dataPatient,
          videoconference,
          historyPatient,
          heaHistory,
          maxSecondPatient,
          dataSchedule,
        } = res;

        // agregnado videconferencia a redux
        addVideoconference(videoconference);

        const { secondsPatient, secondsDoctor } = videoconference;
        this.setState({
          historyPatient: historyPatient,
          heaHistory: heaHistory,
          limitSeconds: maxSecondPatient,
          schedule: dataSchedule,
        });
        let dataSeconds = 0;
        if (user?.role === 'patient') {
          this.setState({
            seconds: secondsPatient,
          });
          dataSeconds = secondsPatient;
        } else {
          this.setState({
            seconds: secondsDoctor,
          });
          dataSeconds = secondsDoctor;
        }
        setInterval(() => {
          dataSeconds += 1;
          if (dataSeconds < maxSecondPatient) {
            this.setState({
              seconds: dataSeconds,
            });
            videoconferencesSeconds({
              roomId: roomId,
              second: dataSeconds,
              userId: user?._id,
            });
          }
        }, 1000);
        this.setState({
          patient: dataPatient,
          loading: false,
          dataVideoconference: videoconference,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
    observerMessageDoctor({ idVideo: roomId }, (data) => {
      messages.push(data);
      this.setState({
        messages,
      });
    });
    observerEndConsult({ idVideo: roomId }, () => {
      Swal.fire({
        position: 'top',
        icon: 'info',
        text: 'El paciente ha salido de la consulta',
        showConfirmButton: false,
        timer: 3000,
      });
    });
  }

  handleSubmitIndication = (doc) => {
    const { indications } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { roomId } = params;
    sendIndicationPatient({ idVideo: roomId, indication: doc });
    indications.push(doc);
    this.setState({
      indications,
    });
  };

  handleRemoveIndication = (index) => {
    const { indications } = this.state;
    indications.splice(index, 1);
    this.setState({
      indications,
    });
  };

  handleChangeReason = (reason) => {
    this.setState({
      reason,
    });
  };

  handleChangeDiagnosis = (diagnosis) => {
    this.setState({
      diagnosis,
    });
  };

  sendMessageDoctor = (event) => {
    const { user, match } = this.props;
    const { messages } = this.state;
    const { params } = match;
    const { roomId } = params;
    event.preventDefault();
    const message = event.target.message.value;
    if (message) {
      sendMessage(
        {
          idVideo: roomId,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            messages.push(data.data);
            this.setState({
              messages: messages,
              message: '',
            });
            const container =
              window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
          }
        }
      );
    }
  };

  handleChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  handleCloseVideoconference = () => {
    const { match, token } = this.props;
    const { params } = match;
    const { roomId } = params;
    const { diagnosis, reason, indications, hea } = this.state;
    if (!diagnosis) {
      createAlert('¡Error!', 'Diagnóstico no esta definido.', 'error');
      return;
    }
    if (!reason) {
      createAlert('¡Error!', 'Motivo no esta definido.', 'error');
      return;
    }
    if (!hea) {
      createAlert('¡Error!', 'Hea no esta definido.', 'error');
      return;
    }
    endCallVideconference({
      token: token,
      endCallDate: new Date(),
      idVideoconference: roomId,
      reason: reason,
      diagnosis: diagnosis,
      indications: indications,
      hea: hea,
    })
      .then(() => {
        createAlert('¡Terminado!', 'Ha finalizado el chat', 'success');
        endChatConsult({ idVideo: roomId });
        addConsult();
        window.location.href = '/dashboard';
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          '¡Error!',
          'Ocurrió un error al finalizar la videoconferencia.',
          'error'
        );
      });
  };

  handleSendSchedule = (schedule) => {
    const { match } = this.props;
    const { params } = match;
    const { roomId } = params;
    sendSchedulePatient({ idVideo: roomId, schedule: schedule });
  };

  handleUploadFile = () => {
    const { document } = window;
    const { token, match, user } = this.props;
    const { params } = match;
    const { roomId } = params;
    const { messages } = this.state;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      uploadChatFile({ token: token, file: formData })
        .then((response) => {
          const { file: dataFile } = response;
          sendMessage(
            {
              idVideo: roomId,
              type: 'file',
              sendUser: user._id,
              idFile: dataFile.id,
              uniqueNameFile: dataFile.filename,
              nameFile: dataFile.originalname,
            },
            (data) => {
              if (data.status === 'success') {
                messages.push(data.data);
                this.setState({
                  messages: messages,
                  message: '',
                });
                const container =
                  window.document.getElementById('container-messages');
                container.scrollTo(0, container.scrollHeight);
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    input.click();
  };

  handleSection = (section) => {
    this.setState({
      section,
    });
  };

  handleOnBlurReason = () => {
    const { reason, sendedReason } = this.state;
    const { match } = this.props;
    const { params } = match;
    const { roomId } = params;
    if (reason !== sendedReason) {
      sendReasonConsult({ idVideo: roomId, reason }, () => {
        this.setState({
          sendedReason: reason,
        });
      });
    }
  };

  handleOnBlurDiagnosis = () => {
    const { match } = this.props;
    const { params } = match;
    const { roomId } = params;
    const { diagnosis, sendedDiagnosis } = this.state;
    if (diagnosis !== sendedDiagnosis) {
      sendDiagnosisConsult({ idVideo: roomId, diagnosis }, () => {
        this.setState({
          sendedDiagnosis: diagnosis,
        });
      });
    }
  };

  handleUpdateData = () => {
    const { match, token } = this.props;
    const { params } = match;
    const { roomId } = params;
    getOneVideoconference({ token: token, idVideo: roomId }).then((res) => {
      const { historyPatient } = res;
      this.setState({ historyPatient });
    });
  };

  render() {
    const {
      user,
      match,
      getReference,
      openAddSchedule,
      openModalAddScheduling,
      doctorReference,
      videoconferenceRef,
      getDoctorReference,
    } = this.props;

    const { params } = match;
    const { roomId } = params;
    const { seconds, historyPatient, limitSeconds } = this.state;
    const {
      loading,
      messages,
      patient,
      message,
      dataVideoconference,
      section,
      reason,
      indications,
      diagnosis,
      hea,
    } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className='container-fluid display-table'>
          {openAddSchedule && doctorReference !== null && <AddSchedule />}
          <div className='col-md-12 col-sm-12 display-table-cell v-align'>
            <div className='user-dashboard'>
              <ModalVitalSigns />
              <ModalNursingNotes />
              <ModalPhysicalExam />
              <ModalMedicalOrders />
              <ModalMedicalHistory
                updateData={this.handleUpdateData}
                historyPatient={historyPatient}
                patientId={patient?.publicData._id}
                idVideo={roomId}
              />
              <div className='row'>
                <div className='col-md-6 col-sm-12 col-xs-12 gutter'>
                  <div className='sales mt-3'>
                    {' '}
                    <div className='container'>
                      {/* Web View */}
                      <div className='d-none d-sm-block'>
                        <div className='row'>
                          <div className='col-6'>
                            {' '}
                            <h1 className='text-primary ml-2'>Chat</h1>
                          </div>
                          <div className='col-6'>
                            {' '}
                            <h3 className='f-right text-primary mt-3 mr-2'>
                              {limitSeconds > seconds
                                ? textMinute(seconds)
                                : 'Tiempo Límite'}
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* Fin Web View */}
                      {/* Mobile View */}
                      <div className='d-block d-sm-none'>
                        <h5 className='text-primary text-center'>
                          Chat{' '}
                          {limitSeconds > seconds
                            ? textMinute(seconds)
                            : 'Tiempo Limite'}
                        </h5>
                      </div>
                      {/* Fin Mobile View */}
                    </div>
                    <div className='row padding5'>
                      {section === 0 && (
                        <ChatDetails
                          patient={patient}
                          reason={reason}
                          diagnosis={diagnosis}
                          handleChangeDiagnosis={this.handleChangeDiagnosis}
                          handleChangeReason={this.handleChangeReason}
                          dataVideoconference={dataVideoconference}
                          handleOnBlurDiagnosis={this.handleOnBlurDiagnosis}
                          handleOnBlurReason={this.handleOnBlurReason}
                        />
                      )}
                      {section === 1 && (
                        <ChatHEA
                          hea={hea}
                          handleHea={(t) => this.setState({ hea: t })}
                        />
                      )}
                      {section === 2 && (
                        <ChatIndications
                          indications={indications}
                          handleSubmitIndication={this.handleSubmitIndication}
                          handleRemoveIndications={this.handleRemoveIndication}
                        />
                      )}

                      {section === 4 && (
                        <ChatHistory historyPatient={historyPatient} />
                      )}
                    </div>
                  </div>
                  <div className='sales mt-3'>
                    <div className='row row-cols-2 row-cols-sm-4 row-cols-md-4 justify-content-center'>
                      <div className='col mb-2'>
                        <button
                          onClick={() => this.handleSection(1)}
                          type='button'
                          className='btn btn-link btn-block border text-secondarymdv bg-white'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <img
                            src={require('../../assets/img/icons/HEA.png')}
                            alt='logo'
                            className='img-fluid'
                            style={{ width: '100px' }}
                          />
                        </button>
                      </div>
                      <div className='col mb-2'>
                        <button
                          onClick={() => {
                            this.handleSection(3);
                            openModalAddScheduling();
                            getDoctorReference(videoconferenceRef.doctor);
                            getReference(videoconferenceRef.patient);
                          }}
                          type='button'
                          className='btn btn-link btn-block border text-secondarymdv bg-white'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <img
                            src={require('../../assets/img/icons/agendar-cita.png')}
                            alt='logo'
                            className='img-fluid'
                            style={{ width: '100px' }}
                          />
                        </button>
                      </div>
                      <div className='col mb-2'>
                        <button
                          onClick={() => this.handleSection(2)}
                          type='button'
                          className='btn btn-link btn-block border text-secondarymdv bg-white'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <img
                            src={require('../../assets/img/icons/Indicación.png')}
                            alt='logo'
                            className='img-fluid'
                            style={{ width: '100px' }}
                          />
                        </button>
                      </div>
                      <div className='col mb-2'>
                        <button
                          type='button'
                          className='btn btn-link btn-block border text-secondarymdv bg-white'
                          style={{ borderRadius: '0.8rem' }}
                          data-toggle='modal'
                          data-target='#ModalMedicalPatient'
                          onClick={() => {
                            getReference(videoconferenceRef.patient);
                          }}
                        >
                          <img
                            src={require('../../assets/img/icons/Historial.png')}
                            alt='logo'
                            className='img-fluid'
                            style={{ width: '100px' }}
                          />
                        </button>
                      </div>
                      <div className='col mb-2'>
                        <button
                          data-toggle='modal'
                          data-target='#ModalMedicalOrders'
                          data-whatever='@getbootstrap'
                          type='button'
                          className='btn btn-link btn-block border text-secondarymdv bg-white'
                          style={{ borderRadius: '0.8rem' }}
                        >
                          <img
                            src={require('../../assets/img/icons/ordenes-medicas.png')}
                            alt='logo'
                            className='img-fluid'
                            style={{ width: '100px' }}
                          />
                        </button>
                      </div>
                      <div className='col'>
                        <button
                          onClick={() => this.handleSection(0)}
                          type='button'
                          className='btn btn-info btn-block'
                          style={{ borderRadius: '0.8rem', height: '77px' }}
                        >
                          Detalle Cita
                        </button>
                      </div>
                      <div className='col'>
                        <button
                          type='button'
                          className='btn btn-light btn-block border bg-danger text-white'
                          data-toggle='modal'
                          onClick={this.handleCloseVideoconference}
                          data-target='#exampleModal'
                          style={{ borderRadius: '0.8rem', height: '77px' }}
                          data-whatever='@getbootstrap'
                        >
                          <i className='fas fa-sign-out-alt fa-2x' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 col-xs-12 gutter'>
                  <div className='sales mt-3 mb-5'>
                    {' '}
                    <div className='mesgs'>
                      {limitSeconds > seconds ? (
                        <>
                          <div id='container-messages' className='msg_history'>
                            {messages.map((doc) => {
                              if (doc.sendUser === user._id) {
                                if (doc.type === 'text') {
                                  return (
                                    <div className='outgoing_msg'>
                                      <div className='sent_msg'>
                                        <p>{doc.message}</p>
                                        <span className='time_date'>
                                          {' '}
                                          11:01 AM | Hoy{' '}
                                          <i className='fas fa-check-double doublecheck' />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className='outgoing_msg'>
                                      <div className='sent_msg'>
                                        <p>
                                          <i className='fas fa-file-alt' />{' '}
                                          <a
                                            target='_blank'
                                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                                            rel='noopener noreferrer'
                                          >
                                            {doc.nameFile}
                                          </a>
                                        </p>
                                        <span className='time_date'>
                                          {' '}
                                          11:01 AM | Hoy{' '}
                                          <i className='fas fa-check-double doublecheck' />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (doc.type === 'text') {
                                return (
                                  <div className='incoming_msg'>
                                    <div className='incoming_msg_img'>
                                      {' '}
                                      <img
                                        className='img-chat'
                                        src={
                                          patient?.publicData.image
                                            ? `/api/profile/image/${patient?.publicData.image}`
                                            : require('../../assets/img/avatars/avatar-doc-male.png')
                                        }
                                        alt='sunil'
                                      />{' '}
                                    </div>
                                    <div className='received_msg'>
                                      <div className='received_withd_msg'>
                                        <p>{doc.message}</p>
                                        <span className='time_date'>
                                          {' '}
                                          11:01 AM | Hoy{' '}
                                          <i className='fas fa-check-double doublecheck' />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className='incoming_msg'>
                                    <div className='incoming_msg_img'>
                                      {' '}
                                      <img
                                        className='img-chat'
                                        src={
                                          patient?.publicData.image
                                            ? `/api/profile/image/${patient?.publicData.image}`
                                            : require('../../assets/img/avatars/avatar-doc-male.png')
                                        }
                                        alt='sunil'
                                      />{' '}
                                    </div>
                                    <div className='received_msg'>
                                      <div className='received_withd_msg'>
                                        <p>
                                          <i className='fas fa-file-alt' />{' '}
                                          <a
                                            target='_blank'
                                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                                            rel='noopener noreferrer'
                                          >
                                            {doc.nameFile}
                                          </a>
                                        </p>
                                        <span className='time_date'>
                                          {' '}
                                          11:01 AM | Hoy{' '}
                                          <i className='fas fa-check-double doublecheck' />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className='type_msg pt-3'>
                            <div className='align-i'>
                              <button
                                type='button'
                                className='btn'
                                onClick={this.handleUploadFile}
                              >
                                <i className='fas fa-paperclip text-secondarymdv fa-2x' />
                              </button>
                            </div>
                            <form
                              onSubmit={this.sendMessageDoctor}
                              className='input_msg_write align-input ml-3'
                            >
                              <input
                                type='text'
                                value={message}
                                onChange={this.handleChangeMessage}
                                className='form-control form-control-lg'
                                name='message'
                                placeholder='Escribe un mensaje'
                              />
                              <button
                                className='btn msg_send_btn'
                                type='submit'
                              >
                                <i className='far fa-paper-plane text-white' />
                              </button>
                            </form>
                          </div>
                        </>
                      ) : (
                        <div>
                          <h3 className='text-center'>
                            Se llego al tiempo límite.
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  physicalExam: state.physicalExam.exam,
  doctorReference: state.reference.doctor,
  openAddSchedule: state.ui.schedulingModal.open,
  videoconferenceRef: state.videoconference.videoconference,
});

export default connect(mapStateToProps, {
  addVideoconference,
  openModalAddScheduling,
  getReference,
  getDoctorReference,
})(ChatScreen);
