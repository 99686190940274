import axios from 'axios';

export const url = '/api/consult/office';

export const createConsult = ({ token, idPatient, hour }) =>
  new Promise((resolve, reject) => {
    if (token && idPatient && hour) {
      axios
        .post(
          `${url}/create`,
          {
            idPatient,
            hour,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!idPatient) {
      reject({
        status: 'error',
        info: 'Paciente no definido',
      });
    } else if (!hour) {
      reject({
        status: 'error',
        info: 'Hora no definida',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const addDoctor = ({
  token,
  idDoctor,
  status,
  _id,
  consultationType,
  bySchedule,
  followUpReference,
}) =>
  new Promise((resolve, reject) => {
    if (token && idDoctor && status && _id) {
      axios
        .put(
          `${url}/doctor/${_id}`,
          {
            idDoctor,
            status,
            consultationType,
            bySchedule,
            followUpReference,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!idDoctor) {
      reject({
        status: 'error',
        info: 'Doctor no definido',
      });
    } else if (!status || status !== 'virtual' || status !== 'attending') {
      reject({
        status: 'error',
        info: 'Estatus no valido',
      });
    } else if (!_id) {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const finishedConsult = ({ token, _id }) =>
  new Promise((resolve, reject) => {
    if (token && _id) {
      axios
        .delete(`${url}/finished/:_id`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    }
  });

export const monitorConsults = ({ token, _id }) =>
  new Promise((resolve, reject) => {
    if (token && _id) {
      axios
        .get(`${url}/monitor/${_id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    }
  });

export const getPatientToReview = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    if (token && _id) {
      console.log('Entrando a getPatientToReview');
      axios
        .get(`${url}/reviewInfoPatient/${_id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    }
  });
};

export const cancelConsultDoctorOffice = ({ token, _id, reasonCancel }) =>
  new Promise((resolve, reject) => {
    if (token && _id && reasonCancel) {
      axios
        .put(
          `${url}/cancel/${_id}`,
          {
            reasonCancel,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else if (!_id) {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'El motivo de la cancelacion es requerido',
      });
    }
  });

export const getVideoconference = ({ token, _id }) =>
  new Promise((resolve, reject) => {
    if (token && _id) {
      axios
        .get(`${url}/videoconference/${_id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no valido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Identificador no definido',
      });
    }
  });
