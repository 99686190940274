/* eslint-disable react/no-unescaped-entities */
import React from 'react';

function HowWorks() {
  return (
    <>
      {/* vista web */}
      <section
        className='section-howworks d-none d-sm-none d-md-block'
        id='comofunciona'
      >
        <div className='container animate__animated animate__fadeIn'>
          <div className='row'>
            <div className='col-md-6'>
              <h1 className='text-white font-weight-bold mb-2 display-4'>
                ¿Cómo Funciona?
              </h1>
              <p className='m-0 p-0' style={{ fontSize: '20px' }}>
                Conecta con médicos generales y especialistas, encuentra la
                atención médica indicada para tu bienestar desde cualquier
                lugar.
              </p>
              <div className='row mt-4'>
                <div className='col-md-12' data-aos='fade-up' data-aos-delay=''>
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-toggle-on'>
                        <img
                          src={require('../../assets/img/vectores/logout.svg')}
                          alt='doctor'
                          width='24'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-white'>Regístrate</h3>
                    <p>
                      Ingresa tus datos personales y crea tu cuenta en nuestra
                      plataforma.
                    </p>
                  </div>
                </div>
                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-users'>
                        <img
                          src={require('../../assets/img/vectores/shopping-cart.svg')}
                          alt='shoppingcart'
                          width='26'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-white'>Pago en línea</h3>
                    <p>
                      Selecciona el plan que mejor te convenga y realiza la
                      transacción de forma 100% segura.
                    </p>
                  </div>
                </div>
                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-clock-o'>
                        <img
                          src={require('../../assets/img/vectores/calendario.svg')}
                          alt='shoppingcart'
                          width='26'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-white'>Agenda una cita</h3>
                    <p>Puedes agendar una consulta virtual o presencial.</p>
                  </div>
                </div>

                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-users'>
                        <img
                          src={require('../../assets/img/vectores/atentionmedical.svg')}
                          alt='shoppingcart'
                          width='30'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-white'>Consulta médica</h3>
                    <p>Prepárate para recibir tu cita médica.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6' data-aos='fade-left'>
              <img
                src={require('../../assets/img/doctora-circle2.png')}
                alt='quienessomos'
                className='img-fluid mt-5'
              />
            </div>
          </div>
        </div>
      </section>
      {/* vista móvil */}
      <section className='d-block d-sm-block d-md-none' id='comofunciona'>
        <div className='container animate__animated animate__fadeIn'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='text-primary text-center font-weight-bold mb-2'>
                ¿Cómo Funciona?
              </h3>
              <p className='m-0 p-0 text-center' style={{ fontSize: '15px' }}>
                Conecta con médicos generales y especialistas, encuentra la
                atención médica indicada para tu bienestar desde cualquier
                lugar.
              </p>
              <div className='row mt-3'>
                <div className='col-12'>
                  <img
                    src={require('../../assets/img/doctora-circle.png')}
                    alt='quienessomos'
                    width='180'
                    className='img-fluid m-auto d-block'
                  />
                </div>
                <div className='col-12'>
                  <div className='feature-1 mt-4'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-toggle-on'>
                        <img
                          src={require('../../assets/img/vectores/logout.svg')}
                          alt='doctor'
                          width='24'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-primary'>Regístrate</h3>
                    <p>
                      Ingresa tus datos personales y crea tu cuenta en nuestra
                      plataforma.
                    </p>
                  </div>
                </div>
                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='100'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-clock-o'>
                        <img
                          src={require('../../assets/img/vectores/calendario.svg')}
                          alt='shoppingcart'
                          width='26'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-primary'>Agenda una cita</h3>
                    <p>Puedes agendar una consulta virtual o presencial.</p>
                  </div>
                </div>
                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-users'>
                        <img
                          src={require('../../assets/img/vectores/shopping-cart.svg')}
                          alt='shoppingcart'
                          width='26'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-primary'>Pago en línea</h3>
                    <p>
                      Selecciona el plan que mejor te convenga y realiza la
                      transacción de forma 100% segura.
                    </p>
                  </div>
                </div>
                <div
                  className='col-md-12'
                  data-aos='fade-up'
                  data-aos-delay='200'
                >
                  <div className='feature-1'>
                    <div className='wrap-icon icon-1'>
                      <span className='icon la la-users'>
                        <img
                          src={require('../../assets/img/vectores/atentionmedical.svg')}
                          alt='shoppingcart'
                          width='30'
                          className='img-fluid'
                        />
                      </span>
                    </div>
                    <h3 className='mb-3 text-primary'>Consulta médica</h3>
                    <p>Prepárate para recibir tu cita médica.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWorks;
