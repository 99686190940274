/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Scheduling from '../../../Components/Doctor/scheduling/Scheduling';
import Spinner from '../../../Components/Spinner/Spinner';
import {getSchedule} from '../../../redux/actions/scheduling.action';

import '../../../stylesheets/scheduling.css';

const SchedulingPage = ({loaded, getSchedule}) => {
  useEffect(() => {
    getSchedule('doctor');
  }, []);
  if (loaded) {
    return (
      <div>
        <Scheduling />
      </div>
    );
  } else {
    return <Spinner />;
  }
};
const mapStateToProps = (state) => ({
  loaded: state.scheduling.loaded,
});
export default connect(mapStateToProps, {getSchedule})(SchedulingPage);
