import { CHANGE_SEARCH_PATIENT } from "../types";

const initialState = {
    searching: "",
};

export default function (state = initialState, action) {
    const { payload, type } = action;
  
    switch (type) {
      case CHANGE_SEARCH_PATIENT:
        return {
          ...state,
          searching: payload,
        };
  
      default:
        return state;
    }
  };
  