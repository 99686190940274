import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '../../assets/img/vectores/404notfound.svg';

const NotFoundPrivate = () => (
  <div className="container" style={{paddingTop: '60px', paddingBottom: '60px'}}>
    <img alt="notfound" src={logo} className="img-fluid" />
  </div>
);

export default NotFoundPrivate;
