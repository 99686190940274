/* eslint-disable func-names */
import { GET_STATUS_TIME_DOCTOR, DISABLE_LOADED_STATUS_TIME } from '../types';

const initialState = {
  items: [],
  loaded: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATUS_TIME_DOCTOR:
      return {
        ...state,
        items: payload,
        loaded: true,
      };
    case DISABLE_LOADED_STATUS_TIME:
      return {
        ...state,
        loaded: payload,
      };

    default:
      return state;
  }
}
