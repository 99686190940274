/* eslint-disable func-names */
import { GET_SPECIALTIES, ERROR_SPECIALTIES } from '../types';

const initialState = {
  specialties: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SPECIALTIES:
      return {
        ...state,
        specialties: payload,
      };
    case ERROR_SPECIALTIES:
      return {
        ...state,
        specialties: null,
      };
    default:
      return state;
  }
}
