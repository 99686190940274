import socket from './socket';

export const acceptVideoconferenceDoctor = ({ idVideo }, callback) => {
  socket.emit('acceptVideoconferenceDoctor', { idVideo }, callback);
};

export const cancelVideoconferenceDoctor = ({ idVideo }, callback) => {
  socket.emit('cancelVideoconferenceDoctor', { idVideo }, callback);
};

export const cancelVideoconferencePatient = ({ idVideo }, callback) => {
  socket.emit('cancelVideoconferencePatient', { idVideo }, callback);
};

export const patientInJitsi = ({ idVideo }, callback) => {
  socket.emit('patientInJitsi', { idVideo }, callback);
};

export const observerAcceptVideoconferencePatient = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/patient/accept`, callback);
};

export const observerCancelVideoconferenceDoctor = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/patient/cancel`, callback);
};

export const observerCancelVideoconferencePatient = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/doctor/cancel`, callback);
};

export const observerPatientInJitsi = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/doctor/patientInJitsi`, callback);
};

// NOTIFICATION SCHEDULE

// enviar notificaciones
export const sendNotification = (notification) => {
  socket.emit('sendNotification', notification);
};

// socket para observar notificaiones nuevas
export const observeNotifications = ({ uid }, callback) => {
  socket.on(uid, callback);
};

// cerrar todas las sesiones en todos los dispotiivos

export const closeAllConnections = (userId) => {
  socket.emit('closeAllSessions', userId);
};

// socket para observar si se debe de cerrar sesion en los dispotivos
export const observeCloseSession = ({ userId }, callback) => {
  socket.on(userId, callback);
};
