/* eslint-disable func-names */
/* eslint-disable arrow-parens */
import {
  GET_CREDIT_CARDS,
  DELETE_CREDIT_CARDS,
  ADD_CREDIT_CARDS,
  SET_DEFAULT_CREDIT_CARD,
} from '../types';

const initialState = {
  items: [],
};
export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_CREDIT_CARDS:
      return {
        ...state,
        items: payload,
      };
    case DELETE_CREDIT_CARDS:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload),
      };

    case ADD_CREDIT_CARDS:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case SET_DEFAULT_CREDIT_CARD:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === payload) {
            return {
              ...item,
              selected: true,
            };
          } else {
            return {
              ...item,
              selected: false,
            };
          }
        }),
      };
    default:
      return state;
  }
}
