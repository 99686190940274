/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { addProfileImage } from '../../redux/actions/profile.action';
import '../../stylesheets/profile.css';
import { openModalProfileImage } from '../../redux/actions/ui.action';
import BasicInfo from './ComponetsHome/BasicInfo';
import MedicalOptions from './ComponetsHome/MedicalOptions';
import Doctors from './ComponetsHome/Doctors';
import DependentPatiens from './ComponetsHome/DependentPatiens';
const HomePacient = ({ user: { firstName, lastName, gender } }) => {
  const nombre = `${firstName.split(' ')[0]} ${lastName.split(' ')[0]}`;

  return (
    <div className='container-fluid'>
      <h2 className='text-primary mb-4 mt-5'>
        {`Bienvenid${gender === 'male' ? 'o' : 'a'} `}
        <b className='text-secondarymdv'>{nombre}</b>
      </h2>
      <br />
      <div className='grid-layout-dashboard'>
        {/* informacion del paciente */}
        <BasicInfo />
        {/* consultas medicas */}
        <MedicalOptions />
        {/* médicos a un clic */}
        <Doctors />
        {/* búsqueda de pacientes dependientes */}
        <DependentPatiens />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
  planRef: state.reference.planRef,
});

export default connect(mapStateToProps, {
  addProfileImage,
  openModalProfileImage,
})(HomePacient);
