import { useCallback } from 'react';

const useJitsi = () => {
  const openjitsiMeet = useCallback((roomId, userRole) => {
    const domain = 'meet.jit.si';
    const url = `https://${domain}/${roomId}`;

    const configParams = `#config.startWithAudioMuted=true&config.startScreenSharing=true&config.disableDeepLinking=true`;

    let interfaceConfigParams = '';
    if (userRole === 'doctor') {
      interfaceConfigParams =
        '&interfaceConfigOverwrite={ "DEFAULT_REMOTE_DISPLAY_NAME": "Moderador" }';
    }

    const completeUrl = url + configParams + interfaceConfigParams;

    window.open(completeUrl, '_blank');
  }, []);

  return { openjitsiMeet };
};

export default useJitsi;
