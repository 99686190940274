import axios from 'axios';
import createAlert from './alert.action';
import { GET_AMOUNT_AVAILABLE, GET_QUERY_RETREATS } from '../types';

export const retreats = (monto, cuenta) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = {
    monto,
    cuenta,
  };
  try {
    const res = await axios.post('/api/retreats', doc, config);
    window.location.href = '/dashboard';
  } catch (err) {
    const errors = err.response.data;
    createAlert('¡Error!', errors.message, 'error');
  }
};

export const getAmountAvailable = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/retreats/amount');
    dispatch({
      type: GET_AMOUNT_AVAILABLE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getQueryRetreats = (desde, hasta) => async (dispatch) => {
  try {
    if (!desde && !hasta) {
      desde = new Date().toISOString().slice(0, 10);
      hasta = new Date().toISOString().slice(0, 10);
    }
    const res = await axios.get(`/api/retreats/query/${desde}/${hasta}`);
    dispatch({
      type: GET_QUERY_RETREATS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
