/* eslint-disable func-names */
import { GET_PRICES_CONSULTING } from '../types';

const initialState = {
  user: null,
  steps: 0,
  preClinic: {
    vitalSings: null,
    physicalTest: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRICES_CONSULTING:
      return {
        ...state,
        items: payload,
      };

    default:
      return state;
  }
}
