import React, { useState, useEffect } from 'react';
import { searchProduct } from '../../../api/productKielsa';

const ItemProduct = ({ addProduct, products, item, showDetail }) => {
  const [amount, setAmount] = useState(1);
  const [aplicar, setAplicar] = useState(false);
  return (
    <li className="list-group-item p-1">
      <small className="text-secondarymdv ml-1">
        {item.Articulo_Id}
        <span className="badge bg-light text-primary float-right">
          {item.Categoria_Nombre}
        </span>
      </small>
      <br />
      <button
        type="button"
        className="btn btn-link btn-sm text-primary font-weight-bold mr-1"
        onClick={() => {
          if (amount > 1) {
            setAmount(amount - 1);
          }
        }}
      >
        <i className="fas fa-minus" />
      </button>
      <small className="badge bg-light text-back-50">{amount}</small>
      <button
        type="button"
        className="btn btn-link btn-sm text-primary font-weight-bold ml-1 mr-2"
        onClick={() => {
          setAmount(amount + 1);
        }}
      >
        <i className="fas fa-plus" />
      </button>
      <small className="text-black-50 font-weight-bold">
        {item.Articulo_Nombre}
      </small>
      <div className="float-right">
        <div className="d-flex">
          <small className="text-black-50 mt-1 font-weight-bold">
            L.
            {item.Precio.toFixed(2)}
          </small>
          {!products.find((data) => data.code === item.Articulo_Id) && (
            <><button
              type="button"
              className="btn btn-primary btn-sm ml-3"
              style={{ height: '25px', fontSize: '11px' }}
              onClick={() => {
                addProduct({
                  code: item.Articulo_Id,
                  name: item.Articulo_Nombre,
                  amount: amount,
                  aplicado: aplicar?"Aplicado al paciente":"No aplicado al paciente",
                });
              }}
            >
              Agregar
            </button>
            <button
              type="button"
              className={aplicar?"btn btn-warning btn-sm ml-3":"btn btn-success btn-sm ml-3"}
              style={{ height: '25px', fontSize: '11px' }}
              onClick={() => {
                setAplicar(!aplicar)
              }}
            >
              {aplicar?"No aplicar al paciente":"Aplicar al paciente"}
            </button>
            </>
            
          )}
          {showDetail && (
            <button
              type="button"
              className="btn btn-primary btn-sm ml-3"
              style={{ height: '25px', fontSize: '11px' }}
              onClick={() => {
                showDetail({
                  code: item.Articulo_Id,
                  name: item.Articulo_Nombre,
                  amount: amount,
                });
              }}
            >
              Detalle
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

const AddProductPresale = ({ addProduct, products, token, showDetail }) => {
  const [loading, setLoading] = useState(false);
  const [dataProducts, setDataProducts] = useState([]);
  const [labFilter, setLabFilter] = useState(false);
  return (
    <div className="collapse" id="collapseProduct">
      <h4 className="text-secondarymdv mb-4 mt-4">
        Agregar Productos
        <button
          type="button"
          data-toggle="collapse"
          data-target="#collapseProduct"
          aria-expanded="false"
          aria-controls="collapseProduct"
          style={{
            borderRadius: '0.8rem',
          }}
          className="btn btn-secondary btn-sm float-right"
        >
          Cerrar
        </button>
      </h4>
      <div className="sales report">
        <form
          className="input-group"
          onSubmit={(e) => {
            e.preventDefault();
            const name = e.target.search.value;
            setLoading(true);
            searchProduct({ token, name, laboratorio: labFilter })
              .then((res) => {
                setDataProducts(res.data);
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        >
          <input
            type="text"
            className="form-control"
            id="Search"
            name="search"
            placeholder="&nbsp;Buscar medicamento o producto"
            aria-label="&nbsp;Buscar medicamento o producto"
            aria-describedby="button-addon2"
          />
          <div className="input-group-append">
            <button
              className="btn btn-light border border-right-rounded"
              type="submit"
              id="button-addon2"
            >
              <i className="fas fa-search text-secondarymdv" />
            </button>
          </div>
        </form>
        <div
          style={{
            marginTop: 10,
          }}
        >
          <h6>filtrar por:</h6>
          <button
            type="button"
            onClick={() => setLabFilter(!labFilter)}
            style={{
              backgroundColor: labFilter && '#011688',
              color: labFilter && '#fff',
              border: labFilter && 'none',
              fontSize: 14,
            }}
          >
            Laboratorio
          </button>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center mt-2">
            <div className="spinner-border" role="status" />
          </div>
        ) : (
          <ul
            className="list-group list-group-flush mt-3"
            style={{
              maxHeight: 300,
              overflowY: 'auto',
            }}
          >
            {dataProducts.length === 0 ? (
              <p className="text-center text-black-50">No hay productos</p>
            ) : (
              dataProducts.map((item) => (
                <ItemProduct
                  key={item._id}
                  products={products}
                  addProduct={addProduct}
                  item={item}
                  showDetail={showDetail}
                />
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddProductPresale;
