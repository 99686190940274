/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const CardLastConsult = ({ date, diagnosis, image }) => (
  <>
    {/* Web View */}
    <div className="d-none d-sm-block">
      <div className="list-group">
        <div className="list-group-item border-top-0 border-right-0 border-left-0">
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex">
              <img
                className="media-object img-circle img-doc-2 img-fluid"
                src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
                alt="Perfil"
                />
              <h5 className="mb-1 ml-3 text-secondarymdv mt-2">
                {' '}
                {date}
                <br />
                <small className="text-black-50">
                  Diagnostico:
                  {' '}
                  {''}
                  {diagnosis}
                </small>
              </h5>
            </div>
            <Link to="/dashboard/agenda">
              <i className="fas fa-plus-circle i-plus text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* Mobile View */}
    <div className="d-block d-sm-none">
      <div className="list-group m-0">
        <div className="list-group-item border-top-0 border-right-0 border-left-0">
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex">
              <img
                className="img-fluid rounded-circle"
                style={{ width: '40px', height: '40px' }}
                src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
                alt="Perfil"
                />
              <small className="text-left mb-1 ml-2 text-secondarymdv">
                {' '}
                {date}
                <br />
                <small className="text-black-50">
                  Diagnostico:
                  {' '}
                  {''}
                  {diagnosis}
                </small>
              </small>
            </div>
            <Link to="/dashboard/agenda">
              <i className="fas fa-plus-circle text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* Fin Mobile View */}
  </>
);

export default CardLastConsult;
