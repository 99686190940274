import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Spinner, Form, FormControl, InputGroup } from 'react-bootstrap';
import InforPatient from '../../Components/Checker/InfoPatient';
import CardDoctorCashPackageKtm from '../../Components/Checker/CardCheckerCashPackage';
import AddPatientChecker from '../../Components/Checker/AddPatientChecker';
import { countries } from '../../utils/countries';
import { addUserByCheckerFromSearch } from '../../redux/actions/auth.action';
import {
  deleteReference,
  changeAddUserFlag,
} from '../../redux/actions/references';

const SearchPatients = () => {
  const [loading] = useState(false);
  const [loaderButton] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    dni: '',
    idType: 'national',
    cellphone: '',
    country: '',
    dialCode: countries[0].dial_code,
    searchType: 'email',
  });
  const dispatch = useDispatch();
  const { userRef, addUserFlag } = useSelector((state) => state.reference);

  useEffect(
    () => () => {
      dispatch(deleteReference());
    },
    [dispatch]
  );

  const { email, dni, idType, searchType, cellphone, dialCode, country } =
    formData;

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };

  const handleClickCleanButton = () => {
    if (userRef) {
      dispatch(deleteReference());
    }
    if (addUserFlag) {
      dispatch(changeAddUserFlag(false));
    }

    setFormData({
      email: '',
      dni: '',
      idType: 'national',
      cellphone: '',
      country: '',
      dialCode: countries[0].dial_code,
      searchType: 'email',
    });
  };

  const handleChange = (e) => {
    if (userRef) {
      dispatch(deleteReference());
    }
    if (addUserFlag) {
      dispatch(changeAddUserFlag(false));
    }

    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
        email: '',
        password: '',
        passwordConfirm: '',
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchType === 'email') {
      dispatch(
        addUserByCheckerFromSearch({
          email,
        })
      );
    } else if (searchType === 'cellphone') {
      dispatch(
        addUserByCheckerFromSearch({
          dialCode,
          cellphone,
        })
      );
    } else {
      dispatch(
        addUserByCheckerFromSearch({
          dni: dni.replace(/-/g, '').replace(/_/g, ''),
          idType,
        })
      );
    }
  };

  return (
    <div className='user-dashboard mt-4'>
      <h2 className='text-primary '>
        Buscar
        <b className='text-secondarymdv'>{` ${'Paciente'}`}</b>
      </h2>
      <div className='row mt-3'>
        <div className='col-md-6 col-xs-12'>
          <div className='sales mb-4'>
            <div className='profile-header'>
              <h3 className='text-white text-center pt-4'>Paciente</h3>
            </div>
            <div className='container mt-3'>
              <form style={{ padding: 20 }} onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='text-primary font-weight-bold float-left'>
                    Buscar por:
                  </label>
                  <select
                    className='form-control bg-light'
                    id='exampleFormControlSelect1'
                    name='searchType'
                    value={searchType}
                    onChange={handleChange}
                    placeholde='Tipo de registro'
                  >
                    <option value='email'>Correo electrónico</option>
                    <option value='dni'>Identidad</option>
                    <option value='cellphone'>Teléfono</option>
                  </select>
                </div>

                {searchType === 'dni' && (
                  <>
                    <div className='form-group'>
                      <label className='text-primary font-weight-bold float-left'>
                        Identidad
                      </label>
                      <select
                        className='form-control bg-light'
                        id='exampleFormControlSelect1'
                        name='idType'
                        value={idType}
                        onChange={handleChange}
                        placeholde='Tipo de identificación'
                      >
                        <option value='national'>ID nacional</option>
                        <option value='foreign'>ID extranjero</option>
                        <option value='rtn'>RTN</option>
                      </select>
                    </div>
                    <div className='form-group'>
                      {idType === 'ID extranjero' ? (
                        <input
                          type='text'
                          className='form-control bg-light'
                          name='dni'
                          value={dni}
                          onChange={handleChange}
                        />
                      ) : (
                        <NumberFormat
                          className='form-control bg-light'
                          format={`${setFormat()}`}
                          mask='_'
                          name='dni'
                          value={dni}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </>
                )}

                {searchType === 'cellphone' && (
                  <>
                    <label className='text-primary font-weight-bold float-left'>
                      Pais
                    </label>
                    <div>
                      <Form.Control
                        as='select'
                        style={{
                          marginBottom: 15,
                        }}
                        onChange={handleChange}
                        name='country'
                        value={country}
                      >
                        {countries.map((co) => (
                          <option key={co.code}>{co.name}</option>
                        ))}
                      </Form.Control>
                    </div>
                    <div className='form-group'>
                      <InputGroup>
                        <InputGroup.Prepend
                          value={dialCode}
                          name='dialCode'
                          onChange={handleChange}
                        >
                          <InputGroup.Text
                            style={{
                              borderTopLeftRadius: 20,
                              borderBottomLeftRadius: 20,
                            }}
                          >
                            {dialCode}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className='form-control'
                          placeholder='Telefono'
                          name='cellphone'
                          value={cellphone}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </div>
                  </>
                )}

                {searchType === 'email' && (
                  <>
                    <label className='text-primary font-weight-bold float-left'>
                      Correo Electrónico
                    </label>
                    <div className='input-group'>
                      <input
                        type='email'
                        className='form-control bg-light'
                        placeholder='Ingrese su correo electrónico'
                        id='inputEmail'
                        name='email'
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bold btn-block'
                    onClick={handleSubmit}
                    disabled={
                      (email === '' && searchType === 'email') ||
                      (cellphone === '' && searchType === 'cellphone') ||
                      (dni === '' && searchType === 'dni') ||
                      addUserFlag ||
                      userRef
                    }
                    style={{
                      opacity:
                        ((email === '' && searchType === 'email') ||
                          (cellphone === '' && searchType === 'cellphone') ||
                          (dni === '' && searchType === 'dni') ||
                          addUserFlag ||
                          userRef) &&
                        '80%',
                      width: 'auto',
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    {loaderButton ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation='border'
                      />
                    ) : (
                      'BUSCAR'
                    )}
                  </button>
                  {(userRef || addUserFlag) && (
                    <button
                      type='button'
                      className='btn btn-primary font-weight-bold btn-block btn btn-danger'
                      style={{
                        width: 'auto',
                        marginTop: 0,
                        margin: 5,
                        backgroundColor: '#FF6E61',
                      }}
                      onClick={handleClickCleanButton}
                    >
                      LIMPIAR
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {/* Para agregar el canje */}
          {userRef && <CardDoctorCashPackageKtm userRef={userRef} />}
        </div>
        {/* Mostrando resultado de la busqueda */}
        <div className='col-md-6 col-xs-12'>
          {addUserFlag && (
            <AddPatientChecker
              emailPatient={email}
              dialCodePatient={dialCode}
              cellphonePatient={cellphone}
              idTypePatient={idType}
              dniPatient={dni}
              searchType={searchType}
            />
          )}
          {/* show information of patient */}
          {userRef && <InforPatient userRef={userRef} loading={loading} />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SearchPatients);
