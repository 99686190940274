import React from "react";
import HomeNurse from "../../../Components/Nurse/HomeNurse";
import HomeNurseMobile from "../../../Components/Nurse/HomeNurseMobile";

const Home = () => (
  <>
    {/* Web View */}
    <div className="d-none d-sm-block">
      <HomeNurse />
    </div>
    {/* Fin Web View */}
    {/* Mobile View */}
    <div className="d-block d-sm-none">
      <HomeNurseMobile />
    </div>
    {/* Fin Mobile View */}
  </>
);

export default Home;
