import React from 'react';

function PatientBenefits() {
  return (
    <>
      {/* vista web */}
      <section className="section-benefits d-none d-sm-none d-md-block" id="benefits">
        <div className="container">
          <h1 className="text-center text-primary font-weight-bold display-4 animate__animated animate__fadeIn">Soluciones para tí</h1>
          <p className="center animate__animated animate__fadeIn" style={{fontSize: '20px'}}>
            Nuestra plataforma emite notificaciones por email y SMS en tiempo real.
          </p>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 justify-content-center no-gutters">
            <div className="col mb-2">
              <h1 className="text-primary font-weight-light text-left mb-3 animate__animated animate__fadeIn">Pacientes</h1>
              <div
                className="bg-primarymdv border-white w-75 mb-2 animate__animated animate__fadeInLeft animate__delay"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-2">
                  <h5 className="text-white mb-0 pb-0">
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid mr-2"
                    />
                    Agenda citas médicas
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 mb-2 animate__animated animate__fadeInLeft animate__delay-2s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-2">
                  <h5 className="text-white mb-0 pb-0">
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid mr-2"
                    />
                    Acceso a directorio médico
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 mb-2 animate__animated animate__fadeInLeft animate__delay-3s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-2">
                  <h5 className="text-white mb-0 pb-0">
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid mr-2"
                    />
                    Expediente clínico
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 mb-2 animate__animated animate__fadeInLeft animate__delay-4s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-2">
                  <h5 className="text-white mb-0 pb-0">
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid mr-2"
                    />
                    Agrega dependientes
                  </h5>
                </div>
              </div>
            </div>
            <div className="col mb-2">
              <h1 data-aos="fade-right" className="text-primary font-weight-light text-right mb-3 ">Médicos</h1>
              <div
                className="bg-primarymdv border-white w-75 float-right mb-2 animate__animated animate__fadeInRight animate__delay"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-2">
                  <h5 className="text-white mb-0 pb-0 text-right">
                    Historial de Consultas
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid ml-2"
                    />
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 float-right mb-2 animate__animated animate__fadeInRight animate__delay-2s"
                style={{borderRadius: '2rem'}}
              >
                <div className="text-center p-2">
                  <h5 className="text-white mb-0 pb-0 text-right">
                    Recetas médicas
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid ml-2"
                    />
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 float-right mb-2 animate__animated animate__fadeInRight animate__delay-3s"
                style={{borderRadius: '2rem'}}
              >
                <div className="text-center p-2">
                  <h5 className="text-white mb-0 pb-0 text-right">
                    Revisa tu agenda de citas
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid ml-2"
                    />
                  </h5>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white w-75 float-right mb-2 animate__animated animate__fadeInRight animate__delay-4s"
                style={{borderRadius: '2rem'}}
              >
                <div className="text-center p-2">
                  <h5 className="text-white mb-0 pb-0 text-right">
                    Estadísticas de seguimiento
                    <img
                      src={require('../../assets/img/vectores/comprobado.svg')}
                      alt="doctor"
                      width="30"
                      className="img-fluid ml-2"
                    />
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* vista mobile */}
      <section className="section-benefits d-block d-sm-block d-md-none" id="benefits">
        <div className="container">
          <h3 className="text-center text-primary font-weight-bold animate__animated animate__fadeIn">Soluciones para tí</h3>
          <p className="center animate__animated animate__fadeIn" style={{fontSize: '15px'}}>
            Nuestra plataforma emite notificaciones por email y SMS en tiempo real.
          </p>
          <div className="row row-cols-2 justify-content-center">
            <div className="col mb-2">
              <h4 className="text-primary font-weight-light mb-3">Pacientes</h4>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInLeft animate__delay"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Agenda citas médicas
                  </p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInLeft animate__delay-2s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>Acceso a directorio médico</p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInLeft animate__delay-3s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Expediente clínico
                  </p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInLeft animate__delay-4s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Agrega dependientes
                  </p>
                </div>
              </div>
            </div>
            <div className="col mb-2">
              <h4 className="text-primary font-weight-light mb-3">Médicos</h4>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInRight animate__delay"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Historial de Consultas
                  </p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInRight animate__delay-2s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Recetas médicas
                  </p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInRight animate__delay-3s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Revisa tu agenda de citas
                  </p>
                </div>
              </div>
              <div
                className="bg-primarymdv border-white mb-2 animate__animated animate__fadeInRight animate__delay-4s"
                style={{borderRadius: '2rem'}}
              >
                <div className="p-1 d-flex">
                  <img
                    src={require('../../assets/img/vectores/comprobado.svg')}
                    alt="doctor"
                    width="18"
                    className="img-fluid mr-2"
                  />
                  <p className="text-white mb-0 pb-0" style={{fontSize: '12px'}}>
                    Estadísticas de seguimiento
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PatientBenefits;
