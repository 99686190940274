import axios from 'axios';
import { ADD_LIFESTYLE, GET_LIFESTYLE, DELETE_LIFESTYLE } from '../types';

export const getlifestyle = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/lifestyle/${patient}`);
    dispatch({
      type: GET_LIFESTYLE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addlifestyle = (name, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    name: name,
    patientID: patientID
  });

  try {
    const res = await axios.post('/api/patient/history/lifestyle', doc, config);

    dispatch({
      type: ADD_LIFESTYLE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deletelifestyle = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/lifestyle/${id}`);
    dispatch({
      type: DELETE_LIFESTYLE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};
