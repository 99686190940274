import axios from 'axios';
import {GET_EXAMS, UPLOAD_EXAM, DELETE_EXAM} from '../types';
import createAlert from './alert.action';

export const uploadExam = (file, title, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    const res = await axios.post(`/api/patient/history/exam/${title}/${patientID}`, file, config);
    dispatch({
      type: UPLOAD_EXAM,
      payload: res.data,
    });
    dispatch(getExams());
  } catch (error) {
    createAlert('Error!', 'error al intentar guardar el archivo', 'error');
  }
};

export const getExams = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/exam/${patient}`);
    dispatch({
      type: GET_EXAMS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteExam = (filename) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/exam/${filename}`);
    dispatch({
      type: DELETE_EXAM,
      payload: filename,
    });
    dispatch(getExams());
  } catch (error) {
    console.log(error);
  }
};

export const updateExam = (filename, title) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    title: title,
    filename: filename,
  });
  try {
    await axios.put('/api/patient/history/exam/update', body, config);
  } catch (error) {
    console.log(error);
  }
};
