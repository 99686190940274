import { HANDLE_CHANGE_PAGINATION } from '../types';

const initialState = {
  actualPage: 1,
  prev: 1,
  center: 2,
  next: 3,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HANDLE_CHANGE_PAGINATION:
      return {
        ...state,
        actualPage: payload.actualPage,
        prev: payload.prev,
        center: payload.center,
        next: payload.next,
      };

    default:
      return state;
  }
}
