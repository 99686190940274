/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import { ADD_PHYSICAL_EXAM, GET_PHYSICAL_EXAM } from '../types';
import createAlert from './alert.action';

export const addPhysicalExam = ({
  idAppointment,
  type,
  neurological,
  heart,
  head,
  eyes,
  neck,
  orl,
  lugs,
  abdomen,
  genitals,
  extremities,
  skin,
  others,
}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    idAppointment,
    type,
    neurological,
    heart,
    head,
    eyes,
    neck,
    orl,
    lugs,
    abdomen,
    genitals,
    extremities,
    skin,
    others,
  });

  try {
    const res = await axios.post('/api/physical/exams/create', body, config);
    dispatch({
      type: ADD_PHYSICAL_EXAM,
      payload: res.data,
    });
    createAlert('¡Correcto!', 'Se ha guardado el examen físico.', 'success');
  } catch (error) {
    createAlert('¡Error!', 'Error al intentar guardar el examen físico.', 'error');
    console.log(error);
  }
};

export const getPhysicalExam = (doc) => (dispatch) => {
  dispatch({
    type: GET_PHYSICAL_EXAM,
    payload: doc,
  });
};
