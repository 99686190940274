import axios from 'axios';

export const url = '/api/plans';

export const getAllPackage = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${url}/all`)
      .then((res) => {
        const { data } = res;
        console.log({
          data,
        });
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((error) => reject(error));
  });

export const getAllPackageCompany = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/packagektm/getAllPackageCompany`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((error) => error);
  });

export const createCashPlan = ({
  idPackageKtm,
  namePatient,
  emailPatient,
  token,
  orderNumber,
  phonePatient,
}) =>
  new Promise((resolve, reject) => {
    if (idPackageKtm && namePatient && emailPatient && token && orderNumber) {
      axios
        .post(
          `${url}/createCashPlan`,
          {
            idPlan: idPackageKtm,
            namePatient,
            emailPatient,
            orderNumber,
            phonePatient,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!idPackageKtm) {
      reject({
        status: 'error',
        info: 'Paquete no sta definido',
      });
    } else if (!namePatient) {
      reject({
        status: 'error',
        info: 'Nombre del paciente no definido',
      });
    } else if (!emailPatient) {
      reject({
        status: 'error',
        info: 'Correo del paciente no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const patientGetPlan = ({ code, token }) =>
  new Promise((resolve, reject) => {
    if (code && token) {
      axios
        .post(
          `${url}/activeCashPlan`,
          {
            code,
          },
          {
            Authorization: token,
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!code) {
      reject({
        status: 'error',
        info: 'Codigo no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });
