/* eslint-disable func-names */
import {
  GET_PROFILE,
  PROFILE_ERROR,
  UPLOAD_PROFILE_DOCTOR_CV,
  ADD_CONSULTATION,
  REDUCE_CONSULTATION,
  ADDTIMETABLE,
  DELETETIMETABLE,
  GET_SEARCH_PATIENT,
  GET_SEARCH_PATIENT_COUNT,
  SEARCH_PATIENT,
  SEARCH_PATIENT_PROFILE,
  SEARCH_PATIENT_IMG,
  DELETE_PROFILE,
  DELETE_REFERENCE_SEARCH_PATIENT,
} from '../types';

const initialState = {
  profile: null,
  profileLoaded: false,
  timetable: [],
  searchPatien: [],
  searchPatienCount: null,
  searchPatientID: null,
  searchPatientIMG: null,
  searchPatientProfile: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case DELETE_REFERENCE_SEARCH_PATIENT:
      return {
        ...state,
        searchPatien: [],
        searchPatientID: null,
        searchPatientIMG: null,
        searchPatientProfile: null,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        profileLoaded: true,
      };

    case PROFILE_ERROR:
      return {
        ...state,
        profile: null,
        profileLoaded: false,
      };

    case DELETE_PROFILE:
      return {
        ...state,
        profile: null,
        profileLoaded: false,
      };

    case UPLOAD_PROFILE_DOCTOR_CV:
      return {
        ...state,
        profile: {
          ...state.profile,
          documents: payload,
        },
      };
    case ADD_CONSULTATION:
    case REDUCE_CONSULTATION:
      return {
        ...state,
        profile: payload,
      };

    case GET_SEARCH_PATIENT:
      return {
        ...state,
        searchPatien: payload,
      };
    case GET_SEARCH_PATIENT_COUNT:
      return {
        ...state,
        searchPatienCount: payload,
      };
    case SEARCH_PATIENT:
      return {
        ...state,
        searchPatientID: payload,
      };
    case SEARCH_PATIENT_PROFILE:
      return {
        ...state,
        searchPatientProfile: payload,
      };
    case SEARCH_PATIENT_IMG:
      return {
        ...state,
        searchPatientIMG: payload,
      };
    case ADDTIMETABLE:
    case DELETETIMETABLE:
    default:
      return state;
  }
}
