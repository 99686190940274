import { useDispatch } from "react-redux"
import { getReference, getProfileReference } from "../../../../redux/actions/references";
export const useItemState = ()=>{
    const dispatch = useDispatch();

    const handleSelectedUser=(id)=>{
        dispatch(
            getReference(id)
        )
    }
    const handleSelectedProfile=(id)=>{
        dispatch(
            getProfileReference(id)
        )
    }
    
    return {handleSelectedUser, handleSelectedProfile}
}