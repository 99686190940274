import React, { useState } from "react";
import { Row, Container, Accordion, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { addPresentialPhysical } from "../../../redux/actions/presential.action";
import exam from "../../../utils/physicalExam";

const AddPhysicalTest = ({ addPresentialPhysical }) => {
  const [exams, setExams] = useState(exam);

  const handleChangeDescription = (e, index) => {
    const tempArray = [...exams];
    tempArray[index].description = e.target.value;
    setExams(tempArray);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addPresentialPhysical(exams);
    Swal.fire({
      icon: "success",
      title: "Se guardo",
    });
  };

  return (
    <div>
      <div className="profile-header">
        <h3 className="text-white text-center pt-4">Agregar Prueba Física</h3>
      </div>
      <Row>
        <Container>
          <h5
            style={{
              padding: 20,
              color: "white",
              fontWeight: 500,
              backgroundColor: "#0093D4",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            Órgano/Aparato/Sistema
          </h5>
        </Container>
      </Row>

      <Row>
        <Container style={{ paddingBottom: 0 }}>
          <Accordion className="mb-4">
            {exams.map((e, index) => (
              <Card key={e.id}>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={e.id}
                  style={{ color: "#0093d4", fontWeight: 500 }}
                >
                  {e.label}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={e.id}>
                  <Card.Body>
                    <label className="float-left">Describa</label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      value={e.description}
                      name="description"
                      onChange={(e) => handleChangeDescription(e, index)}
                      style={{ borderRadius: 10 }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
          <div className="center">
            <button
              type="submit"
              className="btn btn-primary mb-3 col-12 col-md-4"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </Container>
      </Row>
    </div>
  );
};

export default connect(null, {
  addPresentialPhysical,
})(AddPhysicalTest);
