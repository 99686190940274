/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import {
  CLOSE_MODAL_SCHEDULE_DOCTOR_LIST,
  OPEN_MODAL_SCHEDULE_DOCTOR_LIST,
  OPEN_MODAL_ADD_SCHEDULING,
  CLOSE_MODAL_ADD_SCHEDULING,
  OPEN_MODAL_CANCEL_APPOINTMENT,
  OPEN_MODAL_REAGENDAR,
  CLOSE_MODAL_REAGENDAR,
  CLOSE_MODAL_CANCEL_APPOINTMENT,
  OPEN_MODAL_SCHEDULING_DETAILS,
  CLOSE_MODAL_SCHEDULING_DETAILS,
  OPEN_MODAL_PROFILE_IMAGE,
  CLOSE_MODAL_PROFILE_IMAGE,
  OPEN_MODAL_PHYSICAL_EXAM,
  SET_MODAL_SEARCH_PATIENT,
  SET_MODAL_CARNET,
  OPEN_MODAL_CALLCENTER_PLAN,
  HANDLE_CHANGE_FOLLOW_UP_MODAL,
} from '../types';

export const setModalSearchPatient = (value) => ({
  type: SET_MODAL_SEARCH_PATIENT,
  payload: value,
});

export const handleChangeFollowUpModal = (value) => ({
  type: HANDLE_CHANGE_FOLLOW_UP_MODAL,
  payload: value,
});

export const setModalCallCenterPlan = (value) => ({
  type: OPEN_MODAL_CALLCENTER_PLAN,
  payload: value,
});

export const setModalCarnet = (value) => ({
  type: SET_MODAL_CARNET,
  payload: value,
});

export const closeModalScheduleDoctorList = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_SCHEDULE_DOCTOR_LIST,
    payload: false,
  });
};
export const openModalScheduleDoctorList = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_SCHEDULE_DOCTOR_LIST,
    payload: true,
  });
};

export const openModalAddScheduling = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_ADD_SCHEDULING,
    payload: true,
  });
};

export const closeModalAddScheduling = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_ADD_SCHEDULING,
    payload: false,
  });
};

export const openModalCancelAppointment = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_CANCEL_APPOINTMENT,
    payload: true,
  });
};

export const closeModalCancelAppointment = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_CANCEL_APPOINTMENT,
    payload: false,
  });
};

export const openModalReagendar = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_REAGENDAR,
    payload: true,
  });
};

export const closeModalReagendar = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_REAGENDAR,
    payload: false,
  });
};

export const closeModalScheduleDetails = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_SCHEDULING_DETAILS,
    payload: false,
  });
};

export const openModalScheduleDetails = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_SCHEDULING_DETAILS,
    payload: true,
  });
};
export const openModalProfileImage = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_PROFILE_IMAGE,
    payload: true,
  });
};
export const closeModalProfileImage = () => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL_PROFILE_IMAGE,
    payload: false,
  });
};

export const openModalPhysicalExam = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_PHYSICAL_EXAM,
    payload: true,
  });
};
export const closeModalPhysicalExam = () => (dispatch) => {
  dispatch({
    type: OPEN_MODAL_PHYSICAL_EXAM,
    payload: false,
  });
};
