/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
import socket from './socket';

// Enviar nueva cita al doctor
export const sendNewAppointmentToDoctor = (schedule) => {
  socket.emit('sendNewAppointmentToDoctor', schedule);
};

// enviar cita reagendada
export const sendReScheduleAppointmentPatient = (schedule) => {
  socket.emit('reScheduleAppointmentToPatient', schedule);
};
// eliminar por parte dle pa iente una cita
export const socketDeleteConsultationToPatient = ({
  userId,
  idCita,
  firstName,
  lastName,
}) => {
  socket.emit('deleteConsutationToPatient', {
    userId,
    idCita,
    firstName,
    lastName,
  });
};

// observar si el paciente elimino alguna cita por parte del doctor
export const observeDeleteConsultationToPatient = ({userId}, callback) => {
  socket.on(`${userId}/delete/appointment/patient`, callback);
};

// revisar si el doctor tiene una nueva cita agendada por algun paciente
export const observeNewAppointmentToDoctor = ({uid}, callback) => {
  socket.on(`${uid}/appointment/doctor`, callback);
};

// revisar si el doctor tiene una nueva cita agendada por algun paciente
export const observeReScheduleAppointmentToDoctor = ({uid}, callback) => {
  socket.on(`${uid}/appointment/reschedule`, callback);
};

// cambiar el estado de una cita
export const changeStatusOfAppointmentToDoctor = (userId, status, idCita) => {
  socket.emit('changeStatusAppointmentToDoctor', {userId, status, idCita});
};

// observar si ha cambiado el estado de la cita del paciente
export const observeStatusAppointment = ({userId}, callback) => {
  socket.on(`${userId}/status`, callback);
};
