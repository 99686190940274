import React, { useState } from 'react';
import {
  Row,
  Col,
  Spinner,
  Form,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { addPresentialPatient } from '../../../redux/actions/presential.action';
import createAlert from '../../../redux/actions/alert.action';
import { countries } from '../../../utils/countries';
import { checkcellphone, loadUser } from '../../../redux/actions/auth.action';
import ModalCellphoneVerify from '../../Modal/ModalCellphoneVerify';

const AddPresentialPatient = ({
  id,
  type,
  addPresentialPatient,
  checkcellphone,
}) => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dni: id,
    idType: type,
    gender: 'female',
    email: '',
    bornAt: '2024-03-03',
    cellphone: '',
    otherContact: '',
    country: countries[0].name,
    dialCode: countries[0].dial_code,
    registerType: 'email',
  });

  const {
    firstName,
    lastName,
    dni,
    idType,
    gender,
    email,
    bornAt,
    cellphone,
    otherContact,
    country,
    dialCode,
    registerType,
  } = formData;
  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };
  const handleChange = (e) => {
    if (e.target.name === 'country') {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
      });
    } else if (e.target.name === 'registerType' && e.target.value === 'email') {
      setFormData({
        ...formData,
        registerType: 'email',
        cellphone: '',
        dialCode: '',
        country: '',
      });
    } else if (
      e.target.name === 'registerType' &&
      e.target.value === 'cellphone'
    ) {
      setFormData({
        ...formData,
        registerType: 'cellphone',
        email: '',
        country: countries[0].name,
        dialCode: countries[0].dial_code,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoader(true);

      const tempDni = dni.replace(/-/g, '').replace(/_/g, '');

      if (
        dni !== '' &&
        firstName !== '' &&
        lastName !== '' &&
        gender !== '' &&
        idType !== '' &&
        bornAt !== ''
      ) {
        if (
          (idType === 'national' && tempDni.length === 13) ||
          (idType === 'rtn' && tempDni.length === 14) ||
          (idType === 'foreign' && dni.length >= 8)
        ) {
          if (registerType === 'email' && email !== '') {
            await addPresentialPatient(formData);
          } else if (registerType === 'email' && email === '') {
            createAlert('Error', 'Rellene todos los campos', 'error');
          }

          if (registerType === 'cellphone' && cellphone !== '') {
            await checkcellphone(formData);
          } else if (registerType === 'cellphone' && cellphone === '') {
            createAlert('Error', 'Rellene todos los campos', 'error');
          }
        } else {
          createAlert('Error', 'Número de identificación incorrecto');
        }
      } else {
        createAlert('Error', 'Rellene todos los campos', 'error');
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <ModalCellphoneVerify formData={formData} presential />
      <div className="profile-header">
        <h3 className="text-white text-center pt-4">Agregar paciente</h3>
      </div>
      <Row style={{ paddingTop: 15 }}>
        <Col md={6} xs={12}>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Nombre
          </label>
          <div className="form-group">
            <input
              type="text"
              className="form-control center"
              id="inputEmail"
              name="firstName"
              value={firstName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Apellido
          </label>
          <div className="form-group">
            <input
              type="email"
              className="form-control center"
              id="inputEmail"
              name="lastName"
              value={lastName}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Identidad
          </label>
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              name="idType"
              value={idType}
              onChange={(e) => handleChange(e)}
              placeholde="Tipo de identificación"
              disabled
            >
              <option value="national">ID nacional</option>
              <option value="foreign">ID extranjero</option>
              <option value="rtn">RTN</option>
            </select>
          </div>
          <div className="form-group">
            {idType === 'foreign' ? (
              <input
                type="text"
                className="form-control center"
                name="dni"
                value={dni}
                onChange={handleChange}
                disabled
              />
            ) : (
              <NumberFormat
                className="form-control center"
                format={`${setFormat()}`}
                mask="_"
                name="dni"
                value={dni}
                onChange={(e) => handleChange(e)}
                disabled
              />
            )}
          </div>
          <div className="form-group">
            <label
              className="text-secondarymdv font-weight-light"
              style={{ textAlign: 'center', margin: 0, width: '100%' }}
            >
              Otro contacto
            </label>
            <div className="form-group">
              <InputGroup>
                <FormControl
                  className="form-control"
                  placeholder="Telefono o email"
                  name="otherContact"
                  value={otherContact}
                  onChange={(e) => handleChange(e)}
                />
              </InputGroup>
            </div>
          </div>
        </Col>
        <Col md={6} xs={12}>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Fecha de nacimiento
          </label>
          <div className="form-group">
            <input
              type="date"
              className="form-control center"
              placeholder="Fecha de Nacimiento"
              name="bornAt"
              onChange={(e) => handleChange(e)}
              value={bornAt === '2024-03-03' ? '' : bornAt}
            />
          </div>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Género
          </label>
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              name="gender"
              value={gender}
              onChange={(e) => handleChange(e)}
              placeholde="Género"
            >
              <option value="female">Femenino</option>
              <option value="male">Masculino</option>
            </select>
          </div>
          <label
            className="text-secondarymdv font-weight-light"
            style={{ textAlign: 'center', margin: 0, width: '100%' }}
          >
            Tipo de registro
          </label>
          <select
            className="form-control"
            name="registerType"
            value={registerType}
            onChange={(e) => handleChange(e)}
            placeholde="Tipo de registro"
          >
            <option value="email">Correo electrónico</option>
            <option value="cellphone"> Teléfono</option>
          </select>
          {registerType === 'email' ? (
            <>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Correo Electrónico
              </label>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control center"
                  id="inputEmail"
                  name="email"
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </>
          ) : (
            <>
              <label
                className="text-secondarymdv font-weight-light"
                style={{ textAlign: 'center', margin: 0, width: '100%' }}
              >
                Pais
              </label>
              <div>
                <Form.Control
                  as="select"
                  style={{ marginBottom: 15 }}
                  onChange={(e) => handleChange(e)}
                  name="country"
                  value={country}
                >
                  {countries.map((country, key) => (
                    <option key={country.code}>{country.name}</option>
                  ))}
                </Form.Control>
              </div>
              <div className="form-group">
                <InputGroup>
                  <InputGroup.Prepend
                    value={dialCode}
                    name="dialCode"
                    onChange={(e) => handleChange(e)}
                  >
                    <InputGroup.Text>{dialCode}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="form-control"
                    placeholder="Telefono"
                    name="cellphone"
                    value={cellphone}
                    onChange={(e) => handleChange(e)}
                  />
                </InputGroup>
              </div>
            </>
          )}
        </Col>
      </Row>
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-primary mt-4 mb-3 pl-5 pr-5"
          onClick={handleSubmit}
        >
          {loader ? (
            <Spinner
              style={{
                width: 25,
                height: 25,
                color: '#ffff',
                fontSize: 8,
              }}
              animation="border"
            />
          ) : (
            'GUARDAR'
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(null, {
  addPresentialPatient,
  checkcellphone,
})(AddPresentialPatient);
