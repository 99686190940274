import React, { useState, useEffect } from "react";
import { countries } from "../../../../utils/countries";

export const useFormData = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "EmpresarialK2022",
    passwordConfirm: "EmpresarialK2022",
    email: "",
    role: "company",
    job: "",
    gender: "female",
    dni: "",
    idType: "national",
    idCompany: "0000000000000",
    bornAt: "",
    registerType: "email",
    cellphone: "",
    country: countries[0].name,
    dialCode: countries[0].dial_code,
  });

  const {
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    gender,
    dni,
    idType,
    idCompany,
    bornAt,
    registerType,
    cellphone,
    dialCode,
    country,
    job,
  } = formData;

  const handleChange = (e) => {
    if (e.target.name === "country") {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return {
    handleChange,
    setFormData,
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    gender,
    dni,
    idType,
    idCompany,
    bornAt,
    registerType,
    cellphone,
    dialCode,
    country,
    job,
    formData,
  };
};
