import axios from 'axios';

export const url = '/api/services';

export const getAllServices = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/all`, {
      headers: {
        Authorization: token,
      }
    }).then(res => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const searchServices = ({
  token, name 
}) => new Promise((resolve, reject) => {
  if (token && name) {
    axios.post(`${url}/search`, {
      name
    }, {
      headers: {
        Authorization: token
      }
    }).then(res => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(err => reject(err));
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'Nombre no definido'
    });
  }
});
