import { SET_FOLLOW_UP, TYPE_FOLLOW_UP } from '../types';

const initialState = {
  endFollowUp: false,
  type: null,
};
export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_FOLLOW_UP:
      return {
        ...state,
        endFollowUp: payload,
      };

    case TYPE_FOLLOW_UP:
      return {
        ...state,
        type: payload,
      };

    default:
      return state;
  }
};
