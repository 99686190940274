import React from 'react';
import { Spinner } from 'react-bootstrap';
import usePlansState from './state/usePlansState';

const Plans = () => {
  const { plansRef, handleSubmit, loading } = usePlansState();

  return (
    <div style={{ marginTop: 10 }}>
      <div className="row justify-content-center mb-2">
        {plansRef?.map(
          (item) =>
            (item.codeNumber === 5 || item.codeNumber === 6) && (
              <div key={item._id} className="col-12 col-md-3 col-sm-3 mb-3 p-4">
                <div
                  className="card border-0 shadow-sm animate__animated animate__fadeIn "
                  style={{ borderRadius: '1rem' }}
                >
                  <div
                    className="text-center bg-primarymdv"
                    style={{ borderRadius: '1rem' }}
                  >
                    <span
                      className="h5 w-70 mx-auto px-4 pb-1 pt-1 bg-yellow text-primary shadow-sm"
                      style={{
                        borderBottomRightRadius: '0.8rem',
                        borderBottomLeftRadius: '0.8rem',
                      }}
                    >
                      {item.name}
                    </span>

                    <button
                      type="button"
                      className="btn btn-light rounded-pill text-primary font-weight-bold col-10 col-md-7 mb-4 mt-4"
                      onClick={() => handleSubmit(item)}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: 20,
                            height: 20,
                            fontSize: 8,
                          }}
                          animation="border"
                        />
                      ) : (
                        ' Seleccionar'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Plans;
