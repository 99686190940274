/* eslint-disable global-require */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import 'moment/locale/es';
import toastr from 'toastr';
import { AiFillDelete } from 'react-icons/all';
import { IconContext } from 'react-icons';
import CardModal from './History/CardModal';
import ResumenModal from '../Payment/PaymentResumeModal';
import {
  setDefaultCreditCard,
  deleteCreditCard,
} from '../../redux/actions/credit.card.action';
import visaImg from '../../assets/img/visa.svg';
import mastercardImg from '../../assets/img/mastercard.svg';
import { getProfile } from '../../redux/actions/profile.action';
import {
  payCreditCardTelemedicinePlan,
  getKielsaCash,
} from '../../api/payment';
import '../../stylesheets/credit.cards.css';
import '../../stylesheets/consultations.css';
import kielsacash from '../../assets/kielsacash.png';
import createAlert from '../../redux/actions/alert.action';

function Invoice({
  cards,
  setDefaultCreditCard,
  deleteCreditCard,
  history,
  token,
  telemedicinePlan,
  getProfile,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openResumeModal, setOpenResumenModal] = useState(false);
  const [section, setSection] = useState('credit');
  const [loadingPay, setLoadinPay] = useState(false);
  const [amountKielsa, setAmountKielsa] = useState(0);
  useEffect(() => {
    getKielsaCash({ token }).then((res) => {
      const { monto } = res;
      setAmountKielsa(monto);
    });
  }, [token]);

  const handleCheckChange = (id) => {
    setDefaultCreditCard(id);
  };

  const handlePayCredit = () => {
    if (section === 'credit') {
      setLoadinPay(true);
      payCreditCardTelemedicinePlan({
        token: token,
        idTelemedicinePlan: telemedicinePlan._id,
      })
        .then(() => {
          getProfile('patient');
          createAlert(
            '¡Completado!',
            'Se realizo el pago con éxito.',
            'success'
          );
          history.push('/dashboard');
          setLoadinPay(false);
        })
        .catch((error) => {
          console.log('error', error);
          if (error.info) {
            toastr.error(error.info);
          } else {
            createAlert(
              '¡Error!',
              'Lo sentimos, no se pudo efectuar la transacción.',
              'error'
            );
          }
          setLoadinPay(false);
        });
    } else {
      createAlert('¡Error!', 'Pago no valido', 'error');
    }
  };

  const handlePayKielsaCash = () => {
    if (section === 'kielsacash') {
      /* if (amountKielsa > packagektm?.price) {
        payKielsaCashPackage({token: token, idPackage: packagektm?._id})
          .then(() => {
            createAlert(
              '¡Completado!',
              'Se realizo el pago con éxito.',
              'success'
            );
            // toastr.success('Se realizo el pago');
            history.push('/dashboard');
          })
          .catch((error) => {
            if (error.info) {
              toastr.error(error.info);
            } else {
              createAlert(
                '¡Error!',
                'Error al realizar la transacción.',
                'error'
              );
              // toastr.error('Error al realizar la transacción');
            }
          });
      } else {
        createAlert('¡Error!', 'No posees saldo suficiente.', 'warning');
        // toastr.error('No posees saldo suficiente.');
      } */
    }
  };

  return (
    <div>
      <CardModal open={openModal} setOpen={setOpenModal} />
      <ResumenModal open={openResumeModal} setOpen={setOpenResumenModal} />
      <div className='container-fluid'>
        <div className='p-2'>
          {/* Web View */}
          <div className='d-none d-sm-block'>
            <h2 className='text-primary mt-5 mb-4'>Resumen de compra </h2>
          </div>
          {/* Fin Web View */}
          {/* Mobile View */}
          <div className='d-block d-sm-none text-center'>
            <h5 className='text-primary mt-5 mb-4 font-weight-bold'>
              Resumen de compra
            </h5>
          </div>
          {/* Fin Mobile View */}
          <div className='row'>
            <div className='col-12 col-md-6 col-sm-12 col-xs-12 mb-3'>
              <div className='sales'>
                <span className='h4 header-resume-buy-telemedicina text-white pt-4 pl-4 font-weight-bold'>
                  Plan {telemedicinePlan?.name}
                </span>
                <span className='f-right text-secondarymdv pt-2'>
                  {moment().locale('es').format('L')}
                </span>
                <ul
                  className='list-group list-group-flush p-3'
                  style={{
                    marginTop: '55px',
                    borderTopLeftRadius: '1rem ',
                    borderBottomLeftRadius: '1rem',
                  }}
                >
                  <li
                    className='list-group-item d-flex border-0 p-2'
                    style={{ fontSize: '15px', color: '#797979' }}
                  >
                    <img
                      src={require('../../assets/img/vectores/comprobado2.svg')}
                      alt='check'
                      width='20'
                      className='img-fluid mr-2'
                    />
                    {telemedicinePlan?.name}
                  </li>
                </ul>
                <div className='card-footer bg-white mt-2 border-0'>
                  <div className='float-right'>
                    <h2 className='text-primary font-weight-bold'>
                      <b style={{ fontSize: '17px' }}> Monto a Pagar: L.</b>{' '}
                      {telemedicinePlan?.price.toFixed(2)}
                    </h2>
                    <br />
                    <button
                      type='button'
                      className='btn btn-info mb-2 mt-3 btn-block'
                      data-target='#exampleModal'
                      data-whatever='@getbootstrap'
                      style={{ borderRadius: '0.8rem' }}
                      onClick={() => setOpenModal(true)}
                    >
                      Añadir Nueva Tarjeta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-sm-12 col-xs-12 mb-3'>
              <ul
                className='nav nav-pills nav-justified'
                id='pills-tab'
                role='tablist'
              >
                <li
                  onClick={() => setSection('credit')}
                  className='nav-item'
                  role='presentation'
                >
                  <a
                    className='nav-link active text-capitalize h5'
                    id='pills-credit-tab'
                    data-toggle='pill'
                    href='#pills-home'
                    role='tab'
                    aria-controls='pills-home'
                    aria-selected='true'
                  >
                    Tarjeta de Crédito o Débito
                  </a>
                </li>
                {/* <li onClick={() => setSection('paypal')} className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-paypal-tab"
                    data-toggle="pill"
                    href="#pills-paypal"
                    role="tab"
                    aria-controls="pills-paypal"
                    aria-selected="false"
                  >
                    PAYPAL
                  </a>
                </li>
                <li onClick={() => setSection('kielsacash')} className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-kielsa-tab"
                    data-toggle="pill"
                    href="#pills-kielsa"
                    role="tab"
                    aria-controls="pills-kielsa"
                    aria-selected="false"
                  >
                    Kielsa Cash
                  </a>
                </li> */}
              </ul>
              {section === 'credit' && (
                <div
                  className='tab-pane fade show active'
                  id='pills-home'
                  role='tabpanel'
                  aria-labelledby='pills-credit-tab'
                >
                  {/* Start credit card info */}
                  <h5 className='text-secondarymdv mt-4 mb-3 font-weight-bold'>
                    Seleccionar Tarjeta
                  </h5>
                  {cards.length > 0 ? (
                    cards.map((item) => (
                      <>
                        <div
                          key={item._id}
                          className={`credit-card-container bg-white shadow-sm ${
                            item.selected ? 'credit-card-selected' : null
                          }`}
                        >
                          <img
                            src={item.type === 'visa' ? visaImg : mastercardImg}
                            alt='card'
                            className='credit-card-image'
                          />
                          <h6 className='card-item-name text-primary'>
                            {item.name}
                          </h6>
                          <p className='card-item-prefix'>{`termina en   *** ${item.numberPrefix}`}</p>
                          <label
                            className='checkbox-card-item'
                            id='card-item-checkbox'
                          >
                            <input
                              type='checkbox'
                              checked={item.selected}
                              onChange={() => handleCheckChange(item._id)}
                            />
                          </label>
                          <IconContext.Provider
                            value={{
                              size: '20px',
                              color: 'red',
                            }}
                          >
                            <AiFillDelete
                              onClick={() => deleteCreditCard(item._id)}
                            />
                          </IconContext.Provider>
                        </div>
                      </>
                    ))
                  ) : (
                    <p className='text-center p-5'>
                      No tienes agregado ningún método de pago.
                    </p>
                  )}
                  {/* end credit card info */}
                </div>
              )}
              {section === 'paypal' && (
                <div
                  className='tab-pane fade show active'
                  id='pills-paypal'
                  role='tabpanel'
                  aria-labelledby='pills-paypal-tab'
                >
                  {/* Inicio de paypal */}
                  {/* <div className="mt-4 d-flex justify-content-center">
                    <PayPalButton
                      amount={packagektm?.price.toFixed(2)}
                      // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                      onSuccess={(details, data) => {
                        console.log(details, data);
                        // OPTIONAL: Call your server to save the transaction
                        return payPaypalPackage({
                          token: token,
                          paypalOrderId: data.orderID,
                          paypalPayerId: data.payerID,
                          paypalEmail: details.payer.email_address,
                          amount: packagektm?.price,
                          idPackage: packagektm?._id,
                        })
                          .then(() => {
                            toastr.success(
                              'Se realizo el pago por medio de paypal de manera exitosa'
                            );
                            history.push('/dashboard');
                          })
                          .catch((error) => {
                            console.log(error);
                            toastr.error(
                              'Ocurrió un error, por favor comunicarse con servicio al cliente'
                            );
                          });
                      }}
                    />
                  </div> */}
                  {/* Fin de paypal */}
                </div>
              )}
              {section === 'kielsacash' && (
                <div
                  className='tab-pane fade show active'
                  id='pills-kielsa'
                  role='tabpanel'
                  aria-labelledby='pills-kielsa-tab'
                >
                  {/* Inicio de paypal */}
                  <div className='mt-4'>
                    <div className='d-flex justify-content-center'>
                      <img
                        src={kielsacash}
                        alt='Kielsa Cash'
                        style={{ width: '250px' }}
                      />
                    </div>
                    <p className='mt-3 text-center'>
                      <b>Monto:</b>
                      {` L. ${amountKielsa.toFixed(2)}`}
                    </p>
                  </div>
                  {/* Fin de paypal */}
                </div>
              )}
              {(section === 'credit' || section === 'kielsacash') && (
                <>
                  {loadingPay ? (
                    <div className='text-center mt-3'>
                      <Spinner
                        animation='border'
                        role='status'
                        className='text-secondarymdv'
                      >
                        <span className='sr-only'>Loading ...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <button
                      className='btn btn-primary mt-4 mb-4 f-right'
                      type='button'
                      onClick={() => {
                        if (section === 'credit') {
                          handlePayCredit();
                        } else if (section === 'kielsacash') {
                          handlePayKielsaCash();
                        }
                      }}
                      disabled={
                        telemedicinePlan?.price === 0 || cards.length === 0
                      }
                    >
                      Realizar Pago
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  shopping: state.shopping,
  cards: state.creditCards.items,
  packagektm: state.packagektm.packageKtm,
  telemedicinePlan: state.telemedicinePlan.telemedicinePlan,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  setDefaultCreditCard,
  deleteCreditCard,
  getProfile,
})(Invoice);
