import React, { useState, useEffect } from 'react';
import { getTransactions } from '../../redux/actions/auth.action';
import useFarinterSelector from '../../hooks/useFarinterSelector';
import moment from 'moment';
function EnterpriseHistory() {
  const [transacciones, setTransacciones] = useState([]);
  const { auth } = useFarinterSelector();
  const { user } = auth;
  useEffect(() => {
    cargarDatos();
  }, []);

  const cargarDatos = async () => {
    const datos = await getTransactions(user?._id);
    setTransacciones(datos);
  };

  return (
    <div className='container-fluid'>
      {/* Web View */}
      <div className='d-none d-sm-block'>
        <h2 className='text-primary mt-5 mb-4'>Historial de transacciones</h2>
      </div>
      {/* Fin Web View */}
      {/* Mobile View */}
      <div className='d-block d-sm-none text-center'>
        <h5 className='text-primary mt-5 mb-4 font-weight-bold'>
          Historial de transacciones
        </h5>
      </div>
      {/* Fin Mobile View */}
      {/*<div className="row mb-4 align-items-center">
      <div className="col-12 col-md-5 col-lg-5 mb-3">
        <div className="d-flex align-items-center">
          <span className="text-black-50 mr-3">Desde: </span>
          <input type="date" className="form-control" name="desde" />
        </div>
      </div>
      <div className="col-12 col-md-5 col-lg-5 mb-3">
      <div className="d-flex align-items-center">
          <span className="text-black-50 mr-3">Hasta: </span>
          <input type="date" className="form-control" name="hasta" />
        </div>
      </div>
      <div className="col-12 col-md-2 col-lg-2 mb-3">
        <button
          type="submit"
          className="btn btn-primary btn-block"
          style={{ height: "33px" }}
        >
          Actualizar
        </button>
      </div>
</div>*/}
      <div className='table-responsive'>
        <>
          <table className='table table-light table-borderless table-striped'>
            {/*<caption>Última vez actualizado el 05/04/22</caption>*/}
            <thead className='font-weight-bold bg-primarymdv text-center text-uppercase'>
              <tr>
                <th scope='col' className='title-header-table'>
                  Fecha
                </th>
                <th scope='col' className='title-header-table'>
                  Producto
                </th>
                <th scope='col' className='title-header-table'>
                  Estado
                </th>
                <th scope='col' className='title-header-table'>
                  Monto
                </th>
              </tr>
            </thead>

            <tbody className='text-center'>
              {transacciones.map((data, index) => (
                <tr>
                  <td>{moment(data.fecha).format('YYYY-MM-DD')} </td>
                  <td>
                    <div className='text-dark'>
                      {data.detalles.map((temp, index) => (
                        <p className='m-0 font-weight-bold'>{temp}</p>
                      ))}
                    </div>
                  </td>
                  <td>
                    <span
                      className={`label-active-plan ${
                        data.estado === 'ERROR' ? 'bg-danger' : 'bg-success'
                      }`}
                    >
                      {data.estado === 'ERROR' ? 'FALLIDO' : 'CORRECTO'}
                    </span>
                  </td>
                  <td className='font-weight-bold h5'>L. {data.monto}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    </div>
  );
}

export default EnterpriseHistory;
