import{
    GET_INCOME,
    ERROR_INCOME,
    GET_TYPE,
    ERROR_TYPE,
    GET_GENDER,
    ERROR_GENDER,
    GET_COUNTRY,
    ERROR_COUNTRY,
    GET_DURATION,
    ERROR_DURATION,
    GET_RATING,
    ERROR_RATING,
    GET_AGE,
    ERROR_AGE,
    GET_QUERY_DETAIL,
    DETAIL_HISTORY,
    ERROR_QUERY_DETAIL,
    DETAIL_SELECT
} from '../types';

const initialState = {
    graphicIncome:{
        data: []
    },
    graphicType: {
        data: []
    },
    graphicGender: {
        data: []
    },
    graphicCountry: {
        data: []
    },
    graphicDuration: {
        data: []
    },
    graphicRating: {
        data: []
    },
    graphicAge: {
        data: []
    },
    queryDetail: {
        data: [],
        selectedItem: null,
    },
    detailHistory: {
        data: []
    }
};

export default function ( state = initialState, action) {
    const { type, payload } = action

    switch(type){

        case GET_INCOME:
            return {
                ...state,
                graphicIncome: {
                    ...state.graphicIncome,
                    data: payload
                }
            }

        case ERROR_INCOME: 
            return state;

        case GET_TYPE:
            return {
                ...state,
                graphicType: {
                    ...state.graphicType,
                    data: payload
                }
            }

        case ERROR_TYPE: 
            return state;
        
        case GET_GENDER:
            return {
                ...state,
                graphicGender: {
                    ...state.graphicGender,
                    data: payload
                }
            }
            
        case ERROR_GENDER: 
            return state;
        
        case GET_COUNTRY:
            return {
                ...state,
                graphicCountry: {
                    ...state.graphicCountry,
                    data: payload
                }
            }
            
        case ERROR_COUNTRY: 
            return state;

        case GET_DURATION:
            return {
                ...state,
                graphicDuration: {
                    ...state.graphicDuration,
                    data: payload
                }
            }
            
        case ERROR_DURATION: 
            return state;

        case GET_RATING:
            return {
                ...state,
                graphicRating: {
                    ...state.graphicRating,
                    data: payload
                }
            }
            
        case ERROR_RATING: 
            return state;

        case GET_AGE:
            return {
                ...state,
                graphicAge: {
                    ...state.graphicAge,
                    data: payload
                }
            }
            
        case ERROR_AGE: 
            return state;

        case GET_QUERY_DETAIL:
            return {
                ...state,
                queryDetail: {
                    ...state.queryDetail,
                    data: payload
                }
            }

            case DETAIL_HISTORY:
                return {
                    ...state,
                    detailHistory: {
                        ...state.detailHistory,
                        data: payload
                    }
                }
        
        case DETAIL_SELECT:
            return{
                ...state,
                queryDetail:{
                    ...state.queryDetail,
                    selectedItem: payload
                }
            }

        case ERROR_QUERY_DETAIL: 
            return state;
        
        default:
            return state;
    }
}