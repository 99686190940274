/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { AiFillDelete } from 'react-icons/all';
import { IconContext } from 'react-icons';
import { Form, FormControl, Spinner } from 'react-bootstrap';
import { countries } from '../../utils/countries';
import { tipo_sangres } from '../../utils/blood_types';
import {
  setDefaultCreditCard,
  deleteCreditCard,
} from '../../redux/actions/credit.card.action';
import {
  updateProfile,
  resetpassword,
} from '../../redux/actions/profile.action';
import createAlert from '../../redux/actions/alert.action';
import {
  getDependentPatient,
  removeDependentPatient,
} from '../../api/dependentPatient';
import CardModal from './History/CardModal';
import visaImg from '../../assets/img/visa.svg';
import mastercardImg from '../../assets/img/mastercard.svg';
import arrayRelations from '../../utils/relations';
import { usePasswordShown } from '../PatientForm/state/usePasswordShown';
import { passRegex } from '../../utils/regex';
import DependentPatiens from './ComponetsHome/DependentPatiens';

const UserPerfil = ({
  user: { firstName, lastName, email, gender, role, dni },
  profile: { cellphone, country, bornAt, weight, height, blood_type, dialCode },
  updateProfile,
  token,
  resetpassword,
  cards,
  setDefaultCreditCard,
  deleteCreditCard,
}) => {
  const [loaderButton, setLoaderButton] = useState(false);
  const [formData, setFormData] = useState({
    nombre: firstName,
    apellido: lastName,
    correo: email,
    tel: cellphone,
    altura: height,
    peso: weight,
    tipo_sangre: blood_type === undefined ? tipo_sangres[0].name : blood_type,
    pais: country === undefined ? countries[0].name : country,
    genero: gender === 'male' ? 'Masculino' : 'Femenino',
    identidad: dni,
    oldPass: '',
    newPass: '',
    rPass: '',
    dial: dialCode,
    born: bornAt === undefined ? '' : bornAt.slice(0, 10),
  });
  const [patients, setPatients] = useState([]);
  const [loadingPatient, setLoadingPatient] = useState(true);
  const { passwordShown, togglePassword } = usePasswordShown();

  useEffect(() => {
    getDependentPatient({ token })
      .then((res) => {
        const { data } = res;
        setPatients(data);
        setLoadingPatient(false);
      })
      .catch((error) => {
        if (error.info) {
          toastr.error(error.info);
        }
        setLoadingPatient(false);
      });
  }, [token]);

  const updateDependentesPatient = () => {
    getDependentPatient({ token })
      .then((res) => {
        const { data } = res;
        setPatients(data);
        setLoadingPatient(false);
      })
      .catch((error) => {
        if (error.info) {
          toastr.error(error.info);
        }
        setLoadingPatient(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === 'pais') {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dial: tempCountry[0].dial_code,
        pais: tempCountry[0].name,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const {
    nombre,
    apellido,
    correo,
    tel,
    altura,
    peso,
    tipo_sangre,
    pais,
    genero,
    identidad,
    oldPass,
    newPass,
    rPass,
    dial,
    born,
  } = formData;

  const updateData = async (e) => {
    setLoaderButton(true);
    e.preventDefault();
    if (
      nombre !== '' &&
      apellido !== '' &&
      (correo !== '' || tel !== '') &&
      born !== '' &&
      pais !== '' &&
      genero !== '' &&
      identidad !== '' &&
      altura !== '' &&
      peso !== '' &&
      tipo_sangre !== ''
    ) {
      await updateProfile(formData, role);
      setLoaderButton(false);
      if (genero === 'Masculino') {
        setFormData({ ...formData, genero: 'male' });
      }
      if (genero === 'Femenino') {
        setFormData({ ...formData, genero: 'female' });
      }
      createAlert('¡Exito!', 'Información actualizada', 'success');
    } else {
      setLoaderButton(false);
      createAlert('¡Error!', '¡No puede dejar campos vacíos!', 'error');
    }
  };

  const changepass = async (e) => {
    e.preventDefault();
    if (oldPass !== '' && newPass !== '' && rPass !== '') {
      if (newPass === rPass) {
        if (!newPass.match(passRegex)) {
          await createAlert(
            '¡Error!',
            'La contraseña no cumple con los requisitos establecidos',
            'error'
          );
          return;
        }
        resetpassword(oldPass, newPass);
      } else {
        createAlert('¡Error!', '¡Las contraseñas no coinciden!', 'error');
      }
    }
  };

  const handleKeypress = (e) => {
    const invalidChars = ['-', '+', 'e', 'E'];
    if (invalidChars.indexOf(e.key) !== -1) {
      e.preventDefault();
    }
  };

  const handleCheckChange = (id) => {
    setDefaultCreditCard(id);
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className='container-fluid'>
      <CardModal open={openModal} setOpen={setOpenModal} />
      <h2 className='text-primary mt-5 mb-5'>
        Perfil
        <b className='text-secondarymdv'>
          {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
        </b>
      </h2>
      <div className='grid-layout-dashboard'>
        <div className='sales'>
          <h4 className='text-center text-primary mt-3 mb-4'>
            <i className='fas fa-user-edit' /> Información Personal
          </h4>
          <div className='row'>
            <form id='Login1' className='justify-content-start'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label
                    htmlFor='nombre'
                    className='font-weight-bold text-secondary'
                  >
                    Nombres
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    placeholder='Nombre'
                    name='nombre'
                    value={nombre}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='apellidos'
                    className='font-weight-bold text-secondary'
                  >
                    Apellidos
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-user'
                    id='lastName2'
                    placeholder='Apellido'
                    name='apellido'
                    value={apellido}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='correo'
                    className='font-weight-bold text-secondary'
                  >
                    Correo Electrónico
                  </label>
                  <input
                    type='email'
                    className='form-control form-control-user'
                    id='email1'
                    placeholder='Correo Electrónico'
                    name='correo'
                    value={correo}
                    disabled
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='born'
                    className='font-weight-bold text-secondary'
                  >
                    Fecha de Nacimiento
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-user'
                    placeholder='Fecha de Nacimiento'
                    name='born'
                    value={born}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='pais'
                    className='font-weight-bold text-secondary'
                  >
                    País
                  </label>
                  <Form.Control
                    as='select'
                    className='form-control form-control-user'
                    style={{ marginBottom: 15 }}
                    onChange={(e) => handleChange(e)}
                    name='pais'
                    value={pais}
                    disabled
                  >
                    {countries.map((country, key) => (
                      <option key={country.code}>{country.name}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='tel'
                    className='font-weight-bold text-secondary'
                  >
                    Télefono
                  </label>
                  <div className='row justify-content-center'>
                    <div className='input-group col-md-7'>
                      <div
                        className='input-group-prepend'
                        value={dial}
                        name='dial'
                        onChange={(e) => handleChange(e)}
                        disabled
                      >
                        <span
                          className='input-group-text border-left-rounded'
                          id='basic-addon2'
                        >
                          {dial}
                        </span>
                      </div>
                      <FormControl
                        className='input-group'
                        placeholder='Telefono'
                        name='tel'
                        value={tel}
                        onChange={(e) => handleChange(e)}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='genero'
                    className='font-weight-bold text-secondary'
                  >
                    Género
                  </label>
                  <select
                    className='form-control form-control-user'
                    id='exampleFormControlSelect1'
                    placeholder='Genero'
                    value={genero}
                    name='genero'
                    onChange={(e) => handleChange(e)}
                  >
                    <option value='Femenino'>Femenino</option>
                    <option value='Masculino'>Masculino</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='identidad'
                    className='font-weight-bold text-secondary'
                  >
                    Número de Identidad
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-user'
                    id='dni'
                    placeholder='Número de Identidad'
                    name='identidad'
                    value={identidad}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='altura'
                    className='font-weight-bold text-secondary'
                  >
                    Altura M
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-user'
                    defaultValue={altura || 0}
                    pattern='^[0-9]'
                    min='1'
                    step='.01'
                    name='altura'
                    value={altura}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleKeypress(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='peso'
                    className='font-weight-bold text-secondary'
                  >
                    Peso lb
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-user'
                    defaultValue={peso || 0}
                    pattern='^[0-9]'
                    min='1'
                    step='.01'
                    name='peso'
                    value={peso}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleKeypress(e)}
                  />
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='tipo_sangre'
                    className='font-weight-bold text-secondary'
                  >
                    Tipo de Sangre
                  </label>
                  <select
                    className='form-control form-control-user'
                    placeholder='Tipo de Sangre'
                    value={tipo_sangre}
                    name='tipo_sangre'
                    onChange={(e) => handleChange(e)}
                  >
                    {tipo_sangres.map((sangre) => (
                      <option key={sangre.value} value={sangre.value}>
                        {sangre.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-7'>
                    <label
                      htmlFor='dependientes'
                      className='font-weight-bold text-secondary'
                    >
                      {!loadingPatient
                        ? 'Dependientes'
                        : 'No tiene dependientes agregados.'}
                    </label>
                    {loadingPatient ? (
                      <Spinner color='primary' />
                    ) : (
                      patients.map((user) => (
                        <div className='card' key={user._id}>
                          <div className='p-2'>
                            <ul className='list-group list-group-flush'>
                              <li className='list-group-item d-flex justify-content-between align-items-center'>
                                <h6 className='text-primary'>
                                  {`${user.data.firstName} ${user.data.lastName}`}
                                  <br />
                                  {user.data.isDependent && (
                                    <small className='text-black-50'>
                                      {user.data.email} -{' '}
                                    </small>
                                  )}
                                  <small className='text-black-50'>
                                    {
                                      arrayRelations.find(
                                        ({ value }) => value === user.relation
                                      ).label
                                    }
                                  </small>
                                </h6>
                                <span>
                                  <div className='d-flex'>
                                    {/* <button type="button" className="btn btn-light btn-sm mr-2">
                                            <i className="fa fa-edit text-secondarymdv" aria-hidden="true" />
                                          </button> */}
                                    <button
                                      type='button'
                                      className='btn btn-light btn-sm'
                                      onClick={() => {
                                        Swal.fire({
                                          text: 'Desea eliminar este registro?',
                                          confirmButtonText: 'Si',
                                          showCancelButton: true,
                                          cancelButtonText: 'No',
                                        }).then((res) => {
                                          if (res.isConfirmed) {
                                            removeDependentPatient({
                                              token: token,
                                              _id: user._id,
                                            })
                                              .then(() => {
                                                updateDependentesPatient();
                                              })
                                              .catch((error) => {
                                                if (error.info) {
                                                  createAlert(
                                                    '¡Error!',
                                                    error.info,
                                                    'error'
                                                  );
                                                } else {
                                                  createAlert(
                                                    '¡Error!',
                                                    'Lo sentimos no se pudo eliminar el dato.',
                                                    'error'
                                                  );
                                                }
                                              });
                                          }
                                        });
                                      }}
                                    >
                                      <i
                                        className='fas fa-trash text-danger'
                                        aria-hidden='true'
                                      />
                                    </button>
                                  </div>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <button
                  type='button'
                  className='btn btn-primary mt-3 mb-4'
                  style={{ width: '55%' }}
                  onClick={updateData}
                >
                  {loaderButton ? (
                    <Spinner
                      style={{
                        width: 20,
                        height: 20,
                        color: '#ffff',
                        fontSize: 8,
                      }}
                      animation='border'
                    />
                  ) : (
                    'Guardar'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
          {/* sección de cambio de contraseña */}
          <div className='sales'>
            {' '}
            <h4 className='text-center text-primary mt-3 mb-4'>
              <i className='fas fa-key' /> Cambio de Contraseña
            </h4>
            <form id='Login' style={{ paddingLeft: 25, paddingRight: 25 }}>
              <div className='form-group'>
                <label className='font-weight-bold text-secondary'>
                  Contraseña Anterior
                </label>
                <input
                  type='password'
                  //className="form-control center col-md-7 m-auto"
                  className='form-control center m-auto'
                  id='inputPassword10'
                  placeholder='****'
                  name='oldPass'
                  value={oldPass}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='form-group'>
                <label className='font-weight-bold text-secondary'>
                  Nueva Contraseña
                </label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span
                      className='input-group-text border-left-rounded bg-light'
                      id='basic-addon2'
                    >
                      <img
                        src={require('../../assets/img/icons/candado.png')}
                        alt='imagen'
                        width='18'
                        className='img-fluid'
                      />
                    </span>
                  </div>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className='form-control center m-auto'
                    //className="form-control center col-md-7 m-auto"
                    value={newPass}
                    id='inputPassword20'
                    placeholder='***'
                    name='newPass'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='input-group-append'>
                    <button
                      className='btn eye-pass'
                      type='button'
                      onClick={togglePassword}
                    >
                      <img
                        src={require('../../assets/img/icons/ojo.png')}
                        alt='imagen'
                        width='20'
                        className='img-fluid'
                      />
                    </button>
                  </div>
                </div>
                <span className='text-dark font12'>
                  - Debe contener al menos 8 catacteres, 1 dígito, 1 minúscula,
                  1 mayúscula y un caracter especial
                </span>
              </div>
              <div className='form-group'>
                <label className='font-weight-bold text-secondary'>
                  Repetir Contraseña
                </label>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span
                      className='input-group-text border-left-rounded bg-light'
                      id='basic-addon2'
                    >
                      <img
                        src={require('../../assets/img/icons/candado.png')}
                        alt='imagen'
                        width='18'
                        className='img-fluid'
                      />
                    </span>
                  </div>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className='form-control center m-auto'
                    //className="form-control center col-md-7 m-auto"
                    id='inputPassword3'
                    placeholder='***'
                    name='rPass'
                    value={rPass}
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='input-group-append'>
                    <button
                      className='btn eye-pass'
                      type='button'
                      onClick={togglePassword}
                    >
                      <img
                        src={require('../../assets/img/icons/ojo.png')}
                        alt='imagen'
                        width='20'
                        className='img-fluid'
                      />
                    </button>
                  </div>
                </div>
                {/* <input
                  type="password"
                  //className="form-control center col-md-7 m-auto"
                  className="form-control center m-auto"
                  id="inputPassword3"
                  placeholder="***"
                  name="rPass"
                  value={rPass}
                  onChange={(e) => handleChange(e)}
                  />*/}
              </div>
              <button
                className='btn btn-primary mb-4 col-md-7'
                onClick={changepass}
                type='button'
              >
                Cambiar
              </button>
            </form>
          </div>
          {/* sección de tarjetas de crédito */}
          <div className='sales mt-4 mb-4'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className='text-primary mt-3 mb-4'>
                <i className='fas fa-credit-card' />
                <span>{' Métodos de Pago'}</span>
              </h4>
              <button
                type='button'
                className='btn btn-primary'
                data-target='#exampleModal'
                data-toggle='modal'
                data-whatever='@getbootstrap'
                onClick={() => setOpenModal(true)}
                style={{ height: 40 }}
              >
                <i className='fas fa-plus' />
                Agregar
              </button>
            </div>
            <div className='list-group'>
              {cards.length > 0 ? (
                cards.map((item) => (
                  <div
                    key={item._id}
                    className={`credit-card-container ${
                      item.selected ? 'credit-card-selected' : null
                    }`}
                  >
                    <img
                      src={item.type === 'visa' ? visaImg : mastercardImg}
                      alt='card'
                      className='credit-card-image'
                    />
                    <h6 className='card-item-name text-primary'>{item.name}</h6>
                    <p className='card-item-prefix'>{`termina en   *** ${item.numberPrefix}`}</p>
                    <label
                      className='checkbox-card-item'
                      id='card-item-checkbox'
                    >
                      <input
                        type='checkbox'
                        style={{ accentColor: '#041689' }}
                        checked={item.selected}
                        onChange={() => handleCheckChange(item._id)}
                      />
                    </label>
                    <IconContext.Provider
                      value={{
                        size: '20px',
                        color: 'red',
                      }}
                    >
                      <AiFillDelete
                        onClick={() => deleteCreditCard(item._id)}
                      />
                    </IconContext.Provider>
                  </div>
                ))
              ) : (
                <p className='text-center'>
                  No tienes agregado ningún método de pago.
                </p>
              )}
            </div>
          </div>

          <div className='sales mt-4 mb-4'>
            <h4 className='text-primary mt-3 mb-4'>
              <span>Dependientes</span>
              {/* búsqueda de pacientes dependientes */}
              <DependentPatiens />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  profile: state.profile.profile,
  cards: state.creditCards.items,
});

export default connect(mapStateToProps, {
  updateProfile,
  resetpassword,
  setDefaultCreditCard,
  deleteCreditCard,
})(UserPerfil);
