import { useState } from 'react';

export const useFormData = () =>{
    const [formData, setFormData] = useState({
        password: '',
        userInput: '',
      });

    const { userInput, password } = formData;
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      return {
        handleChange,
        userInput, 
        password,
        formData 
      }
}