import { useState } from 'react';

const useOpenModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(!open);
  };

  return {
    open,
    handleOpenModal,
  };
};

export default useOpenModal;
