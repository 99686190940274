/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DoctorForm from '../../Components/DoctorForm';
import Layout from '../../layouts/PrincipalLayout/PrincipalLayout';

const RegisterDoctor = ({ isAuth }) => {
  if (isAuth) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Layout>
      <DoctorForm />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});
export default connect(mapStateToProps)(RegisterDoctor);
