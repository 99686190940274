import axios from 'axios';

export const url = '/api/connection';

export const createConnection = ({ socketId, token }) => new Promise((resolve, reject) => {
  if (socketId && token) {
    axios.post(`${url}/create`, {
      socketId,
    }, {
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => {
      reject(error);
    });
  } else if (!socketId) {
    reject({
      status: 'error',
      info: 'Identificador del socker no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'token no definido',
    });
  }
});

export const closeConnection = ({ socketId, token }) => new Promise((resolve, reject) => {
  if (socketId && token) {
    axios.post(`${url}/close`, {
      socketId,
    }, {
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => {
      reject(error);
    });
  } else if (!socketId) {
    reject({
      status: 'error',
      info: 'Identificador del socket no esta definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
