export const dias = [
  { number: 0, abbr: 'Dom' },
  { number: 1, abbr: 'Lun' },
  { number: 2, abbr: 'Mar' },
  { number: 3, abbr: 'Míe' },
  { number: 4, abbr: 'Jue' },
  { number: 5, abbr: 'Vie' },
  { number: 6, abbr: 'Sáb' },
];
export const meses = [
  { number: 0, abbr: 'Ene' },
  { number: 1, abbr: 'Feb' },
  { number: 2, abbr: 'Mar' },
  { number: 3, abbr: 'Abr' },
  { number: 4, abbr: 'May' },
  { number: 5, abbr: 'Jun' },
  { number: 6, abbr: 'Jul' },
  { number: 7, abbr: 'Ago' },
  { number: 8, abbr: 'Sep' },
  { number: 9, abbr: 'Oct' },
  { number: 10, abbr: 'Nov' },
  { number: 11, abbr: 'Dic' },
];
