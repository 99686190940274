import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Telemedicina from '../../../Components/Patient/Telemedicina';
import TelemedicinaMovil from '../../../Components/Patient/TelemedicinaMovil';
import Loader from '../../../Components/Spinner/Spinner';
import NoValidPatient from '../../NoValidPatient';
import { validUserPatient } from '../../../api/user';
import usePlans from '../../../hooks/plans/usePlans';
import { useReferences } from '../../../hooks/useReferences';

const Telellamada = ({ history, token, profile }) => {
  const { planRef } = useReferences();

  if (!planRef) {
    return <NoValidPatient />;
  }
  return (
    <>
      {/* Web View */}
      <div className='d-none d-sm-block'>
        <Telemedicina history={history} />
      </div>
      {/* Fin Web View */}
      {/* Mobile View */}
      <div className='d-block d-sm-none'>
        <TelemedicinaMovil history={history} />
      </div>
      {/* Fin Mobile View */}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  profile: state.profile.profile,
});

export default connect(mapStateToProps)(Telellamada);
