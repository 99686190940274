/* eslint-disable import/no-duplicates */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import {
  Map,
  GoogleApiWrapper,
  InfoWindow,
  Marker,
  Polyline,
} from 'google-maps-react';
import GoogleMapReact from 'google-map-react';
import compose from 'recompose/compose';
import toastr from 'toastr';
import { getConsultoryDoctors } from '../../../api/doctor';
import Spinner from '../../Spinner/Spinner';
import CardConsultingRoom from '../../Card/CardConsultingRoom';
import { sendCall } from '../../../websokect/patientWebSocket';
import {
  createVideoconference,
  lastConsultUser,
} from '../../../api/videoconference';
import { getAllConsultoryDoctors } from '../../../redux/actions/consultories.action';
import CardContactDoctor from '../../Card/CardContactDoctor';
import { upcomingScheduling } from '../../../api/scheduling';
import ModalAddSchedule from '../../../Components/Card/ModalAddconsulting/ModalAddConsulting';

import { getAllOffices } from '../../../api/doctor.office';
import { changeConsultDoctorOffice } from '../../../websokect/consultDoctorOfficeWebsocket';
import NoValidPatient from '../../../pages/NoValidPatient';

const apikey = 'AIzaSyAXi5nwGODPW5Lnc-sG8tihPsF2NMfwFK0';
const user2 = require('../../../assets/img/icons/user2.png');
const farmaciakielsa = require('../../../assets/img/icons/farmaciakielsa.svg');

const UserLogo = () => (
  <div>
    <img src={user2} alt='loginlogo' className='icon-h' data-aos='fade-right' />
  </div>
);

const OfficeMarker = () => (
  <div>
    <img
      src={farmaciakielsa}
      alt='loginlogo'
      className='icon-h'
      data-aos='fade-right'
    />
  </div>
);
class FacetoFaceConsultation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      doctor: undefined,
      textSearch: '',
      lastConsult: [],
      upcomingScheduling: [],
      doctorOffices: [],
      zoom: 12,
      activeMarker: null,
      officeName: '',
      loadingPage: true,
      addingCurrentPosition: false,
      latitude: 14.0818,
      longitude: -87.20681,
      pinlat: undefined,
      pinlng: undefined,
      userlat: 14.0580008,
      userlng: -87.1887002,
      triangleCoords: [],
    };
  }

  componentDidMount() {
    const {
      auth: { token },
    } = this.props;
    /* Obteniendo las utlimas consultas del paciente */
    lastConsultUser({ token })
      .then((response) => {
        const { data } = response;
        this.setState({
          lastConsult: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    /* Obteniendo el listados de citas proximas */
    upcomingScheduling({ token })
      .then((response) => {
        const { data } = response;
        this.setState({
          upcomingScheduling: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    getAllOffices({ token, name: '' })
      .then((data) => {
        this.setState({
          doctorOffices: data.data,
        });
      })
      .catch((e) => {
        console.log('Error al obtener los datos de los consultorios.');
      });
  }

  handleEndDirection = (event) => {
    const { addingCurrentPosition } = this.state;
    if (addingCurrentPosition) {
      this.setState({
        latitude: event.center.lat(),
        longitude: event.center.lng(),
        pinlat: undefined,
        pinlng: undefined,
      });
    }
  };

  handleDirection = (event) => {
    const { addingCurrentPosition } = this.state;
    if (addingCurrentPosition) {
      this.setState({
        pinlat: event.center.lat(),
        pinlng: event.center.lng(),
        userlat: event.center.lat(),
        userlng: event.center.lng(),
      });
    }
  };

  handleVideconference = (doctor) => {
    const {
      auth: { user: patient, token },
      history,
    } = this.props;

    if (doctor.profile.status === 'available') {
      createVideoconference({
        token: token,
        doctor: doctor._id,
        type: 'videocall',
      })
        .then((response) => {
          const { idVideo, idDoctorConsult } = response;
          changeConsultDoctorOffice({ idConsult: idDoctorConsult });
          sendCall(
            {
              doctorId: doctor._id,
              role: patient.role,
              idVideo: idVideo,
              type: 'videocall',
            },
            (data) => {
              if (data.status === 'success') {
                history.push(`/dashboard/waitingcall/${idVideo}`);
              } else {
                alert('Lo sentimos no pudimos crear la videollamada.');
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          alert('Lo sentimos no se pudo crear la videollamada.');
        });
    } else {
      alert('Lo sentimos, el médico no se encuentra disponible.');
    }
  };

  handleChatDoctor = (doctor) => {
    const {
      auth: { user: patient, token },
      history,
    } = this.props;

    if (doctor.profile.status === 'available') {
      createVideoconference({
        token: token,
        doctor: doctor._id,
        type: 'chat',
      })
        .then((response) => {
          const { idVideo, idDoctorConsult } = response;
          changeConsultDoctorOffice({ idConsult: idDoctorConsult });
          sendCall(
            {
              doctorId: doctor._id,
              role: patient.role,
              idVideo: idVideo,
              type: 'chat',
            },
            (data) => {
              if (data.status === 'success') {
                history.push(`/dashboard/waitingcall/${idVideo}`);
              } else {
                alert('Lo sentimos no pudimos crear la videollamada.');
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          alert('Lo sentimos no se pudo crear la videollamada.');
        });
    } else {
      alert('Lo sentimos, el médico no se encuentra disponible.');
    }
  };

  handleCallDoctor = (doctor) => {
    const {
      auth: { user: patient, token },
      history,
    } = this.props;

    if (doctor.profile.status === 'available') {
      createVideoconference({
        token: token,
        doctor: doctor._id,
        type: 'call',
      })
        .then((response) => {
          const { idVideo, idDoctorConsult } = response;
          changeConsultDoctorOffice({ idConsult: idDoctorConsult });
          sendCall(
            {
              doctorId: doctor._id,
              role: patient.role,
              idVideo: idVideo,
              type: 'call',
            },
            (data) => {
              if (data.status === 'success') {
                history.push(`/dashboard/waitingcall/${idVideo}`);
              } else {
                alert('Lo sentimos no pudimos crear la videollamada.');
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          alert('Lo sentimos no se pudo crear la videollamada.');
        });
    } else {
      alert('Lo sentimos, el médico no se encuentra disponible.');
    }
  };

  handleDataDoctor = (doctor) => {
    this.setState({
      doctor,
    });
  };

  handleSearchDoctor = (event) => {
    this.setState({
      textSearch: event.target.value,
    });
  };

  handleSearchOffice = (e) => {
    const {
      auth: { token },
    } = this.props;
    getAllOffices({ token, name: e.target.value })
      .then((data) => {
        this.setState({
          doctorOffices: data.data,
        });
      })
      .catch((e) => {
        console.log('Error al obtener los datos de los consultorios.', e);
      });
    this.setState({ officeName: e.target.value });
  };

  onSelectOffice = (office) => {
    const {
      auth: { token },
      google,
    } = this.props;
    const { userlat, userlng } = this.state;
    const directionsService = new google.maps.DirectionsService();
    const start = { lat: userlat, lng: userlng };
    const end = { lat: office.lat, lng: office.lng };
    const request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING',
    };
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        const triangleCoords = result.routes[0].overview_path.map((path) => ({
          lat: path.lat(),
          lng: path.lng(),
        }));
        this.setState({ triangleCoords });
      }
    });
    this.setState({
      latitude: office.lat,
      longitude: office.lng,
      selectedOffice: office,
      zoom: 17,
      showingInfoWindow: true,
    });
    this.setState({ loading: true });
    getConsultoryDoctors({
      token,
      consultoryId: office._id,
    })
      .then((res) => {
        const { doctors } = res;
        this.setState({
          doctors: doctors,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  };

  onMarkerClick = (props, marker, _) => {
    const { doctorOffices: filteredOffices, userlat, userlng } = this.state;
    const {
      auth: { token },
      google,
    } = this.props;
    const store = filteredOffices.find((item) => item.name === props.name);
    if (store) {
      this.handleSearchOffice({ target: { value: props.name } });
      // try {
      const directionsService = new google.maps.DirectionsService();
      const start = { lat: userlat, lng: userlng };
      const end = props.position;
      const request = {
        origin: start,
        destination: end,
        travelMode: 'DRIVING',
      };
      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          const triangleCoords = result.routes[0].overview_path.map((path) => ({
            lat: path.lat(),
            lng: path.lng(),
          }));
          this.setState({ triangleCoords });
        }
      });

      this.setState({
        selectedOffice: props,
        activeMarker: marker,
        showingInfoWindow: true,
        zoom: 17,
        latitude: props.position.lat,
        longitude: props.position.lng,
      });
      this.setState({ loading: true });
      getConsultoryDoctors({
        token,
        consultoryId: props._id,
      })
        .then((res) => {
          const { doctors } = res;
          this.setState({
            doctors: doctors,
            loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false,
          });
        });
    }
  };

  zoomChanges = (_, next) => {
    this.setState({ zoom: next.zoom });
  };

  clearMap = () => {
    const {
      auth: { token },
    } = this.props;
    this.setState({
      latitude: 14.0580008,
      longitude: -87.1887002,
      zoom: 12,
      activeMarker: null,
      officeName: '',
      doctors: [],
      showingInfoWindow: false,
      triangleCoords: [],
    });
    getAllOffices({ token, name: '' })
      .then((data) => {
        this.setState({
          doctorOffices: data.data,
        });
      })
      .catch((e) => {
        console.log('Error al obtener los datos de los consultorios.');
      });
  };

  toggleAddingCurrentPosition = () => {
    const { addingCurrentPosition } = this.state;
    this.setState({
      addingCurrentPosition: !addingCurrentPosition,
      triangleCoords: [],
    });
  };

  setUserLocation = (location) => {
    this.setState({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
      userlat: location.coords.latitude,
      userlng: location.coords.longitude,
    });
  };

  setAutomaticLocation = () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setUserLocation);
      }
    } catch (error) {
      toastr.error('No se pudo obtener la ubicación actual');
    }
  };

  render() {
    const {
      isOpenModalAddScheduling,
      google,
      doctorsConsultories,
      doctorReference,
      references: { planRef },
    } = this.props;

    const {
      loading,
      doctor: dataDoctor,
      textSearch,
      doctorOffices,
      officeName,
      zoom,
      activeMarker,
      showingInfoWindow,
      onCloseInfoWindow,
      selectedOffice,
      addingCurrentPosition,
      latitude,
      longitude,
      pinlat,
      pinlng,
      userlat,
      userlng,
      triangleCoords,
    } = this.state;

    const filteredOffices = doctorOffices;

    if (
      !planRef ||
      planRef?.plan?.type === 'telemedicine' ||
      planRef?.plan?.type === 'sessions_telemedicine'
    ) {
      return <NoValidPatient />;
    }
    return (
      <>
        <div className='container-fluid'>
          {isOpenModalAddScheduling && doctorReference !== null && (
            <ModalAddSchedule />
          )}
          <h2 className='text-primary mb-5 mt-5'>
            Consulta&nbsp;
            <b className='text-secondarymdv'>Presencial</b>
          </h2>
          <div className='grid-layout-dashboard'>
            {/* sección de consultorios */}
            <div>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'>
                  <div className='input-group mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      value={officeName}
                      onChange={this.handleSearchOffice}
                      placeholder='&nbsp;Buscar Consultorio'
                      aria-label='&nbsp;Buscar Consultorio'
                      aria-describedby='button-addon2'
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-light border border-right-rounded'
                        type='button'
                        id='button-addon2'
                        onClick={this.clearMap}
                      >
                        {' '}
                        {officeName ? (
                          <i className='fas fa-times text-secondarymdv' />
                        ) : (
                          <i className='fas fa-search text-secondarymdv' />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='scroll-consulting-room mt-2'>
                {doctorOffices.map((office) => (
                  <CardConsultingRoom
                    key={office._id}
                    office={office}
                    selectOffice={this.onSelectOffice}
                  />
                ))}
              </div>
            </div>
            {/* sección de mapas y doctores */}
            <div>
              <div className='sales scroll-date'>
                {/* aqui ira el mapa real */}
                <h4 className='text-secondarymdv font-weight-light mt-2'>
                  Mapa del Consultorio
                </h4>
                <div className='hr' />
                <div className='d-flex justify-content-between pb-2'>
                  {!addingCurrentPosition ? (
                    <button
                      className='btn btn-primary btn-sm'
                      type='button'
                      onClick={this.toggleAddingCurrentPosition}
                    >
                      <i className='fas fa-search' /> Ubicación manualmente
                    </button>
                  ) : (
                    <button
                      className='btn btn-primary btn-sm'
                      type='button'
                      onClick={this.toggleAddingCurrentPosition}
                    >
                      <i className='fas fa-map-marked-alt' /> Fijar Ubicación
                    </button>
                  )}
                  <button
                    className='btn btn-info btn-sm'
                    type='button'
                    onClick={this.setAutomaticLocation}
                    style={{ borderRadius: '0.5rem' }}
                  >
                    <i className='fas fa-map-marker-alt' /> Ubicación Automática
                  </button>
                </div>
                <div
                  className='rounded-5 px-0 d-block col-12'
                  style={{ height: '20rem' }}
                >
                  {!addingCurrentPosition ? (
                    // directions map
                    <Map
                      google={google}
                      zoom={zoom}
                      initialCenter={{
                        lat: latitude,
                        lng: longitude,
                      }}
                      center={{ lat: latitude, lng: longitude }}
                      onZoomChanged={this.zoomChanges}
                      style={{ width: '100%', maxHeight: '40rem' }}
                    >
                      {filteredOffices.map((data) => (
                        <Marker
                          id={data._id}
                          key={data._id}
                          onClick={this.onMarkerClick}
                          position={{ lat: data.lat, lng: data.lng }}
                          name={data.name}
                          title={data.name}
                          icon={{
                            url: farmaciakielsa,
                            scaledSize: new google.maps.Size(32, 32),
                          }}
                        />
                      ))}
                      <Marker
                        key='UserLocation'
                        position={{
                          lat: userlat,
                          lng: userlng,
                        }}
                        name='Mi ubicación'
                        icon={{
                          url: user2,
                          scaledSize: new google.maps.Size(32, 32),
                        }}
                      />
                      <InfoWindow
                        marker={activeMarker}
                        visible={showingInfoWindow}
                        google={google}
                        onClose={onCloseInfoWindow}
                      >
                        <div>
                          <h4>{selectedOffice?.name}</h4>
                        </div>
                      </InfoWindow>
                      <Polyline
                        path={triangleCoords}
                        strokeColor='#001689'
                        strokeOpacity={0.5}
                        strokeWeight={5}
                      />
                    </Map>
                  ) : (
                    // Drag and drop map
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: 'AIzaSyAXi5nwGODPW5Lnc-sG8tihPsF2NMfwFK0',
                      }}
                      defaultCenter={{
                        lat: latitude,
                        lng: longitude,
                      }}
                      center={{
                        lat: latitude,
                        lng: longitude,
                      }}
                      options={this.getMapOptions}
                      defaultZoom={zoom}
                      onDrag={this.handleDirection}
                      onDragEnd={this.handleEndDirection}
                    >
                      {filteredOffices.map((data) => (
                        <OfficeMarker
                          id={data._id}
                          key={data._id}
                          onClick={this.onMarkerClick}
                          lat={data.lat}
                          lng={data.lng}
                          name={data.name}
                          title={data.name}
                          icon={{
                            anchor: new google.maps.Point(32, 32),
                            scaledSize: new google.maps.Size(64, 64),
                          }}
                        />
                      ))}
                      <UserLogo
                        lat={pinlat || latitude || 14.0818}
                        lng={pinlng || longitude || -87.20681}
                        text='dirección'
                      />
                    </GoogleMapReact>
                  )}
                </div>
                <h4 className='text-secondarymdv font-weight-light mt-5 mb-3'>
                  Médicos Disponibles
                </h4>
                <div className='hr' />
                {loading && <Spinner />}
                {!loading && (
                  <>
                    {doctorsConsultories.length === 0 ? (
                      <p className='text-center text-black-50 pt-3 pb-2'>
                        Para agendar seleccione uno de los consultorios
                        disponibles.
                      </p>
                    ) : (
                      <div className='scroll-doctor'>
                        {doctorsConsultories.map((doctor) => {
                          if (textSearch) {
                            const stringRegex = textSearch.split(/ /).join('|');
                            const regexSearch = new RegExp(stringRegex, 'i');
                            if (
                              regexSearch.test(doctor.firstName) ||
                              regexSearch.test(doctor.lastName)
                            ) {
                              return (
                                <CardContactDoctor
                                  key={doctor._id}
                                  handleDataDoctor={() => {
                                    this.handleDataDoctor(doctor);
                                  }}
                                  image={
                                    doctor.image
                                      ? `/api/profile/image/${doctor.image}`
                                      : undefined
                                  }
                                  name={
                                    doctor.gender === 'male'
                                      ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                      : `Dra. ${doctor.firstName} ${doctor.lastName}`
                                  }
                                  specialty={doctor.profile.specialty}
                                  appointment={doctor.profile.appointment}
                                  status={doctor.profile.status}
                                />
                              );
                            } else {
                              return undefined;
                            }
                          } else {
                            return (
                              <CardContactDoctor
                                key={doctor._id}
                                handleDataDoctor={() =>
                                  this.handleDataDoctor(doctor)
                                }
                                image={
                                  doctor.image
                                    ? `/api/profile/image/${doctor.image}`
                                    : undefined
                                }
                                name={
                                  doctor.gender === 'male'
                                    ? `Dr. ${doctor.firstName} ${doctor.lastName}`
                                    : `Dra. ${doctor.firstName} ${doctor.lastName}`
                                }
                                doctorId={doctor._id}
                              />
                            );
                          }
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* start modal card info */}
        <div
          className='modal fade'
          id='perfildoctor'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-lg modal-dialog-centered'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title text-primary' id='exampleModalLabel'>
                  Información del Médico
                </h5>
                <button
                  type='button'
                  className='close text-secondarymdv'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='container db-social'>
                  <div className='jumbotron jumbotron-fluid' />
                  <div className='container-fluid'>
                    <div className='row justify-content-center'>
                      <div className='col-xl-12 col-md-12 d-flex justify-content-center'>
                        <span>
                          <i className='fas fa-star c-star center' /> 3/5
                        </span>
                      </div>
                      <div className='col-xl-11'>
                        <div className='widget head-profile has-shadow'>
                          <div className='widget-body pb-0'>
                            <div className='row d-flex align-items-center'>
                              <div className='col-xl-12 col-md-12 d-flex justify-content-center'>
                                <div className='image-default'>
                                  <img
                                    className='media-object img-circle img-doc-3'
                                    src={
                                      dataDoctor?.image
                                        ? `/api/profile/image/${dataDoctor.image}`
                                        : require('../../../assets/img/avatars/avatar-doc-male.png')
                                    }
                                    alt='Perfil'
                                  />
                                </div>
                                <div className='infos'>
                                  <h4 className='media-heading text-primary mt-5'>
                                    {dataDoctor && (
                                      <>
                                        {`${
                                          dataDoctor.gender === 'male'
                                            ? 'Dr.'
                                            : 'Dra.'
                                        } `}
                                        {dataDoctor.lastName}
                                      </>
                                    )}
                                  </h4>
                                  <span className='text-primary'>
                                    {' '}
                                    {dataDoctor?.profile.specialty}
                                  </span>
                                  <div className='location'>
                                    <div className='row center-div font-p'>
                                      <div className='outter2'>
                                        <h4 className='line mar-2 m-0 text-primary'>
                                          Perfil Profesional
                                        </h4>
                                        <div className='py-4'>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              Graduado de : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv'>
                                              {dataDoctor?.profile.university}
                                            </span>
                                          </p>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              Experiencia : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv'>
                                              {dataDoctor?.profile.experience}{' '}
                                              Años
                                            </span>
                                          </p>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              No de colegiatura : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv'>
                                              {dataDoctor?.profile.college}
                                            </span>
                                          </p>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              Idiomas : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv'>
                                              {dataDoctor?.profile.languages.map(
                                                (text) => (
                                                  <span key={text}>
                                                    {text},
                                                  </span>
                                                )
                                              )}
                                            </span>
                                          </p>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              Maestrías : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv'>
                                              {/* Maestria */}
                                              {dataDoctor?.profile.master}
                                            </span>
                                          </p>
                                          <p className='clearfix'>
                                            <span className='font-blue-r'>
                                              Sub Especialidades : &nbsp;
                                            </span>
                                            <span className='text-secondarymdv '>
                                              {dataDoctor?.profile.subSpecialties.map(
                                                (text) => (
                                                  <span key={text}>
                                                    {text},
                                                  </span>
                                                )
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='row center-div font-p'>
                                      <div className='outter2'>
                                        <h4 className='line mar-2 m-0 text-primary'>
                                          Mensaje del Médico
                                        </h4>
                                        <li className='text-secondarymdv'>
                                          {dataDoctor?.profile.about}
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => ({
  auth: state.auth,
  isOpenModalAddScheduling: state.ui.schedulingModal.open,
  doctorsConsultories: state.consultories.doctors,
  doctorReference: state.reference.userRef,
  references: state.reference,
});

export default compose(
  connect(mapStatetoProps, {
    getAllConsultoryDoctors,
  }),
  GoogleApiWrapper({
    apiKey: apikey,
  })
)(FacetoFaceConsultation);
