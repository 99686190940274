/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
import React from 'react';

import { Link } from 'react-router-dom';

function Navbar({ image }) {
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light nav-overlapping bg-primary-movil'>
        {image}
        <Link to='/' className='navbar-brand'>
          <img
            src={require('../assets/img/KCL.png')}
            alt='logo'
            className='img-fluid animate__animated animate__fadeInDown d-none d-sm-none d-md-block'
            style={{ width: '140px' }}
          />
          <img
            src={require('../assets/img/KCL-White.png')}
            alt='logo'
            className='img-fluid animate__animated animate__fadeInDown mb-3 d-block d-sm-block d-md-none'
            style={{ width: '140px' }}
          />
        </Link>
        <button
          className='navbar-toggler border-0 p-1'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <i className='fas fa-bars' style={{ fontSize: '32px' }} />
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav text-uppercase bg-primarymdv nav-short'>
            <a
              href='/#location'
              className='nav-link d-none d-sm-none d-md-block animate__animated animate__fadeInDown'
            >
              UBICA TU CLINICA
            </a>
            <a
              href='/#quienesomos'
              className='nav-link d-none d-sm-none d-md-block animate__animated animate__fadeInDown'
            >
              ¿QUIÉNES SOMOS?
            </a>
            <a
              href='/#comofunciona'
              className='nav-link d-none d-sm-none d-md-block animate__animated animate__fadeInDown'
            >
              ¿CÓMO FUNCIONA?
            </a>
            <a
              href='/#benefits'
              className='nav-link d-none d-sm-none d-md-block animate__animated animate__fadeInDown'
            >
              BENEFICIOS
            </a>
            <Link
              className='nav-link animate__animated animate__fadeInDown'
              to='/planes'
            >
              Planes
            </Link>
            <a href='#pharmakielsa'>
              <img
                src={require('../assets/img/icons/farmaciakielsaacceso.png')}
                className='img-fluid ml-1 d-none d-sm-none d-md-block animate__animated animate__fadeInDown'
                alt='farmacias kielsa'
                style={{ width: '150px' }}
              />
              <img
                src={require('../assets/img/icons/farmaciakielsaacceso.png')}
                className='img-fluid d-block ml-1 d-sm-block d-md-none animate__animated animate__fadeInDown'
                alt='farmacias kielsa'
                style={{ width: '130px' }}
              />
            </a>
            <Link
              className='nav-link animate__animated animate__fadeInDown'
              to='/login'
            >
              Iniciar Sesión
            </Link>
            <Link
              className='nav-link animate__animated animate__fadeInDown'
              to='/registerPacient'
            >
              Registro
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
