/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import {
  GET_SHOPPING_ITEMS,
  ADD_SHOPPING_ITEM,
  DELETE_SHOPPING_ITEM,
  ADD_TOTAL_ITEM,
  DELETE_TOTAL_ITEM,
  DELETE_ALL_SHOPPING_ITEMS,
} from '../types';
import createAlert from './alert.action';

export const getShoppingItems = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/shopping');
    dispatch({
      type: GET_SHOPPING_ITEMS,
      payload: res.data,
    });

    if (res.data.length > 0) {
      res.data.forEach((item) => {
        dispatch({
          type: ADD_TOTAL_ITEM,
          payload: item.consult.price,
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const addShoppingItem = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/shopping/${id}`);
    const shoppingItem = await axios.get(`/api/shopping/${res.data._id}`);
    dispatch({
      type: ADD_SHOPPING_ITEM,
      payload: shoppingItem.data,
    });
    dispatch({
      type: ADD_TOTAL_ITEM,
      payload: shoppingItem.data.consult.price,
    });
    createAlert('¡Correcto!', 'Consulta agregada al carrito.', 'success');
  } catch (error) {
    console.log(error);
  }
};
export const deleteShoppingItem = (id) => async (dispatch) => {
  try {
    const shoppingItem = await axios.get(`/api/shopping/${id}`);
    await axios.delete(`/api/shopping/${id}`);
    dispatch({
      type: DELETE_SHOPPING_ITEM,
      payload: id,
    });
    dispatch({
      type: DELETE_TOTAL_ITEM,
      payload: shoppingItem.data.consult.price,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllShoppingItems = () => async (dispatch) => {
  try {
    await axios.delete('/api/shopping/all/items');
    dispatch({
      type: DELETE_ALL_SHOPPING_ITEMS,
      payload: [],
    });
  } catch (error) {
    console.log(error);
  }
};
