/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MdModeEdit } from 'react-icons/all';

import { Form, FormControl, Row } from 'react-bootstrap';
import { countries } from '../../utils/countries';
import Autocomplete from '../Autocomplete';
import {
  updateProfile,
  getProfile,
  addProfileDoctorCvFile,
} from '../../redux/actions/profile.action';
import {
  deleteBankAccount,
  addSelectedItem,
} from '../../redux/actions/bank.accounts.action';
import { banks } from '../../utils/banks';
import Modal from './ModalAccount';

const DoctorPerfil = ({
  user: { firstName, lastName, gender, email, role, dni },
  profile: {
    cellphone,
    country,
    bornAt,
    specialty,
    subSpecialties,
    languages,
    documents,
    college,
    master,
    university,
    experience,
    about,
    appointment,
  },

  specialties,
  updateProfile,
  accounts,
  deleteBankAccount,
  addSelectedItem,
  addProfileDoctorCvFile,
}) => {
  // state local
  // formato de los lenguajes
  const lenguajes = [];
  if (languages.lenght !== 0) {
    languages.forEach((lenguaje) => {
      lenguajes.push({ value: lenguaje, label: lenguaje });
    });
  }

  // subespecialidades
  const propsEspecialidades = [];
  if (subSpecialties.lenght !== 0) {
    subSpecialties.forEach((especialidad) => {
      propsEspecialidades.push({
        value: especialidad,
        label: especialidad,
      });
    });
  }

  // obtener epecialidad
  const tempEspecialidad =
    specialty === undefined
      ? ''
      : {
          value: specialty,
          label: specialty,
        };
  const [formData, setFormData] = useState({
    nombre: firstName,
    apellido: lastName,
    genero: gender === 'male' ? 'Masculino' : 'Femenino',
    correo: email,
    pais: country === undefined ? countries[0].name : country,
    identidad: dni,
    born: bornAt.slice(0, 10),
    tel: cellphone,
    cita: appointment === undefined ? false : appointment,
    dialCode:
      cellphone === undefined
        ? countries[0].dial_code
        : countries.filter((pais) => pais.name === country)[0].dial_code,
    especialidad: tempEspecialidad.value,
    subEspecialidad: subSpecialties.lenght === 0 ? [] : propsEspecialidades,
    idiomas: languages.lenght === 0 ? [] : languages,
    colegiatura: college === undefined ? undefined : college,
    universidad: university === undefined ? undefined : university,
    experiencia: experience === undefined ? undefined : experience,
    me: about === undefined ? null : about,
    maestria: master === undefined ? undefined : master,
  });

  const [show, setShow] = useState(false);

  const {
    nombre,
    apellido,
    genero,
    correo,
    pais,
    born,
    tel,
    cita,
    especialidad,
    dialCode,
    subEspecialidad,
    idiomas,
    colegiatura,
    universidad,
    experiencia,
    me,
    maestria,
  } = formData;

  const handleChange = (e) => {
    if (e.target.name === 'pais') {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        pais: tempCountry[0].name,
      });
    }
    if (e.target.name === 'cita') {
      setFormData({
        ...formData,
        cita: !cita,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmitPersonalInformation = (e) => {
    e.preventDefault();
    updateProfile(formData, role);
  };

  const handleUploadCvFiles = () => {
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileDoctorCvFile(formData, documents);
    };
    input.click();
  };
  // obtener la lista de sub especialidades seleccionada y los idiomas
  const handleChangeAutocomplete = (e, name) => {
    if (name === 'subSpecialties') {
      if (e !== null) {
        setFormData({
          ...formData,
          subEspecialidad: e,
        });
      } else {
        setFormData({
          ...formData,
          subEspecialidad: [],
        });
      }
    } else if (name === 'idiomas') {
      if (e !== null) {
        setFormData({
          ...formData,
          idiomas: e,
        });
      } else {
        setFormData({
          ...formData,
          idiomas: [],
        });
      }
    } else if (name === 'especialidad') {
      if (e !== null) {
        setFormData({
          ...formData,
          especialidad: e.value,
        });
      }
    }
  };

  const languagesArray = [
    { value: 'Español', label: 'Español' },
    { value: 'Ingles', label: 'Inglés' },
    { value: 'Francés', label: 'Francés' },
    { value: 'Ruso', label: 'Ruso' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Mandarín', label: 'Mandarín' },
    { value: 'Bengalí', label: 'Bengalí' },
    { value: 'Árabe', label: 'Árabe' },
    { value: 'Portugués', label: 'Portugués' },
    { value: 'Indonesio', label: 'Indonesio' },
    { value: 'Alemán', label: 'Alemán' },
  ];
  const data = [];

  specialties.forEach((option) => {
    data.push({ value: option.name, label: option.name });
  });

  return (
    <div>
      <div className="container-fluid">
        <div className="col-md-12 col-sm-12">
          <div className="user-dashboard">
            <h2 className="text-primary mt-5 mb-5">
              Perfil&nbsp;
              {role === 'nurse' ? (
                <>
                  {gender === 'male' ? 'Enfro.' : 'Enfra.'}
                  <b className="text-secondarymdv">
                    {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
                  </b>
                </>
              ) : (
                <>
                  {gender === 'male' ? 'Dr.' : 'Dra.'}
                  <b className="text-secondarymdv">
                    {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
                  </b>
                </>
              )}
            </h2>
            <div className="row mt-5">
              <div className="col-md-6 col-sm-12 col-xs-12 gutter">
                <div className="sales report marb3">
                  <h4 className="text-center text-primary mt-3 mb-4">
                    <i className="fas fa-user-edit" /> Información Personal
                  </h4>
                  <div className="row">
                    <form id="Login1">
                      <div className="col-md-12">
                        <label className="font-weight-bold text-secondary">
                          Nombres
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control form-control-user"
                            placeholder="Nombre"
                            name="nombre"
                            value={nombre}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold text-secondary">
                            Apellidos
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-user"
                            id="lastName"
                            placeholder="Apellido"
                            name="apellido"
                            value={apellido}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold text-secondary">
                            Correo Electrónico
                          </label>
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="email"
                            // placeholder="Correo Electrónico"
                            name="correo"
                            value={correo}
                            onChange={(e) => handleChange(e)}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold text-secondary">
                            Fecha de Nacimiento
                          </label>
                          <input
                            type="Date"
                            className="form-control form-control-user"
                            id="date"
                            // placeholder="Fecha de Nacimiento"
                            name="born"
                            value={born}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-weight-bold text-secondary">
                            País
                          </label>
                          <Form.Control
                            className="form-control form-control-user"
                            as="select"
                            style={{ marginBottom: 15 }}
                            onChange={(e) => handleChange(e)}
                            name="pais"
                            value={pais}
                          >
                            {countries.map((country, key) => (
                              <option key={country.code}>{country.name}</option>
                            ))}
                          </Form.Control>
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="tel"
                            className="font-weight-bold text-secondary"
                          >
                            Télefono
                          </label>
                          <div className="row justify-content-center">
                            <div className="input-group col-md-7">
                              <div
                                className="input-group-prepend"
                                value={dialCode}
                                name="dialCode"
                                onChange={(e) => handleChange(e)}
                              >
                                <span
                                  className="input-group-text border-left-rounded"
                                  id="basic-addon2"
                                >
                                  {dialCode}
                                </span>
                              </div>
                              <FormControl
                                classname="input-group"
                                placeholder="Telefono"
                                name="tel"
                                value={tel}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold text-secondary">
                            Género
                          </label>
                          <select
                            className="form-control form-control-user"
                            id="exampleFormControlSelect1"
                            name="genero"
                            value={genero}
                            onChange={(e) => handleChange(e)}
                          >
                            <option>Femenino</option>
                            <option>Masculino</option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-xs-12 gutter">
                <div className="sales marb3">
                  <h4 className="text-center text-primary mt-3 mb-4">
                    <i className="fas fa-user-edit" /> Información Profesional
                  </h4>
                  <div className="row">
                    <form id="Login">
                      <div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-group col-12 col-md-7 center-div">
                              <label className="font-weight-bold text-secondary">
                                Especialidad
                              </label>
                              <Autocomplete
                                placeholder="Seleccione especialidad"
                                options={data}
                                handleChangeAutocomplete={
                                  handleChangeAutocomplete
                                }
                                name="especialidad"
                                defOptions={especialidad}
                                multi={false}
                              />
                            </div>
                          </div>
                          <div className="form-group col-12 col-md-7 center-div">
                            <label className="font-weight-bold text-secondary">
                              Sub-especialidades
                            </label>
                            <Autocomplete
                              placeholder="Sub-especialidades"
                              options={data}
                              handleChangeAutocomplete={
                                handleChangeAutocomplete
                              }
                              name="subSpecialties"
                              defOptions={subEspecialidad}
                              multi
                            />
                          </div>
                          <div className="form-group toppad">
                            <label className="font-weight-bold text-secondary">
                              Número de Colegiatura Médica
                            </label>
                            <input
                              className="form-control form-control-user"
                              id="inputmed"
                              placeholder="Ej 15487"
                              name="colegiatura"
                              value={colegiatura}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="font-weight-bold text-secondary">
                              Universidad de Pregrado
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="inputuniversity"
                              // placeholder="Universidad de pregrado"
                              name="universidad"
                              value={universidad}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <label className="font-weight-bold text-secondary">
                              Maestría
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-user"
                              id="inputuniversity"
                              // placeholder="Maestría"
                              name="maestria"
                              value={maestria}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="font-weight-bold text-secondary">
                              Años de Experiencia
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-user"
                              id="inputexperience"
                              // placeholder="Años de Experiencia"
                              name="experiencia"
                              value={experiencia}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group col-12 col-md-7 center-div">
                            <label className="font-weight-bold text-secondary">
                              Idiomas
                            </label>
                            <Autocomplete
                              placeholder="Seleccione un idioma"
                              options={languagesArray}
                              handleChangeAutocomplete={
                                handleChangeAutocomplete
                              }
                              name="idiomas"
                              defOptions={idiomas}
                              multi
                            />
                          </div>
                          {documents !== null &&
                          documents !== '' &&
                          documents !== undefined ? (
                            <div className="form-group container-cv">
                              <label style={{ margin: '0' }}>
                                Certificados:
                              </label>
                              <div>
                                <a
                                  target="_blank"
                                  href={`/api/profile/doctor/cv/file/${documents}`}
                                  style={{ color: '#2D71A1', marginRight: 10 }}
                                >
                                  Documentos personales y certificados
                                </a>
                                <MdModeEdit
                                  color="red"
                                  size={17}
                                  style={{ cursor: 'pointer' }}
                                  onClick={handleUploadCvFiles}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="form-group toppad">
                              <label>Agregue certificados y CV &nbsp;</label>
                              <button
                                type="button"
                                className="cv-normal-button"
                                onClick={handleUploadCvFiles}
                              >
                                añadir archivo
                              </button>
                            </div>
                          )}
                          <div className="form-group">
                            <label className="font-weight-bold text-secondary">
                              Mensaje del médico
                            </label>
                            <textarea
                              className="form-control form-control-user"
                              name="me"
                              value={me}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary btn-block m-auto col-12 col-md-6"
                          onClick={handleSubmitPersonalInformation}
                        >
                          Actualizar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12">
                <div className="bank-accounts-container mb-5 mt-5">
                  <div className="col-md-12 col-sm-12 container-title-accounts">
                    <h4 className="text-center text-primary font-weight-light">
                      Cuentas de Banco
                    </h4>
                    <IconContext.Provider
                      value={{ className: 'icon-add-accounts' }}
                    >
                      <BiBookAdd onClick={() => setShow(true)} />
                    </IconContext.Provider>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <Row>
                      {accounts.length > 0 ? (
                        accounts.map((account) => (
                          <div
                            className="card-container-bank-account col-sm-12 col-md-6  col-lg-4"
                            key={account._id}
                          >
                            <div className="card card-element">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-12 mr-2 m-3 image-name-container-card">
                                    {banks.map(
                                      (bank) =>
                                        bank.name === account.bank && (
                                          <img
                                            src={require(`../../assets/img/${bank.imgUri}`)}
                                            alt={`${bank.name} logo`}
                                            className="img-size-bank-account"
                                            key={bank}
                                          />
                                        )
                                    )}
                                    <h5>
                                      {`${firstName.split(' ')[0]} ${
                                        lastName.split(' ')[0]
                                      }`}
                                    </h5>
                                  </div>
                                  <div className="col-8">
                                    <h6>{account.number}</h6>
                                    <p>
                                      <b>Tipo de cuenta:</b>
                                      {account.type}
                                    </p>
                                  </div>
                                  <div className="col-4">
                                    <button
                                      type="button"
                                      className="btn-t"
                                      data-toggle="modal"
                                      data-target="#exampleModal"
                                      data-whatever="@getbootstrap"
                                      onClick={() => {
                                        addSelectedItem(account);
                                        setShow(true);
                                      }}
                                    >
                                      <i className="far fa-edit edit-i" />
                                    </button>
                                    <button
                                      className="btn-t"
                                      type="button"
                                      onClick={() =>
                                        deleteBankAccount(account._id)
                                      }
                                    >
                                      <i className="far fa-times-circle close-i" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span className="ml-3">
                          No tienen ninguna cuenta de banco agregada
                        </span>
                      )}
                    </Row>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* start modal card info */}
      <Modal show={show} setShow={setShow} />
    </div>
  );
};

const mapStateToprops = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  profileLoaded: state.profile.profileLoaded,
  specialties: state.specialties.specialties,
  accounts: state.accounts.items,
});
export default connect(mapStateToprops, {
  updateProfile,
  getProfile,
  deleteBankAccount,
  addSelectedItem,
  addProfileDoctorCvFile,
})(DoctorPerfil);
