import axios from 'axios';

export const url = '/api/users';

export const logoutUser = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .post(
          `${url}/logout`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Faltan parametros',
      });
    }
  });

export const validUserPatient = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/valid/patient`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Toke no definido',
      });
    }
  });

export const validUserPatientId = ({ patientId }) =>
  new Promise((resolve, reject) => {
    if (patientId) {
      axios
        .get(`${url}/valid/patientId/${patientId}`)
        .then((res) => {
          const { data } = res;

          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Id de paciente no definido',
      });
    }
  });

export const validGroupPackageUser = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/valid/group`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });
