/* eslint-disable comma-dangle */
/* eslint-disable arrow-parens */
import axios from 'axios';
import familiarItems from '../../utils/antecedentes.familiares';
import {
  ADD_MEDICINE,
  GET_MEDICINES,
  ERROR_ADD_MEDICINE,
  DELETE_MEDICINE,
  UPDATE_MEDICINE,
  ADD_SELECTED_ITEM,
  DELETE_SELECTED_ITEM,
  ADD_HEALTH,
  DELETE_HEALTH,
  GET_HEALTH,
  GET_ALLERGIES,
  DELETE_ALLERGIE,
  ADD_ALLERGIE,
  ADD_FAMILIAR,
  GET_FAMILIAR,
  DELETE_FAMILIAR,
  ADD_SELECTED_FAMILIAR,
  DELETE_SELECTED_FAMILIAR,
  UPDATE_FAMILIAR,
  SELECTED_ALLERGIE,
  UPDATE_ALLERGIE,
  ENABLE_OTHER_FAMILIAR,
  DISABLE_OTHER_FAMILIAR,
  ADD_ITEMS_FRONTEND_FAMILIAR,
  UPDATE_ITEMS_FRONTEND_FAMILIAR,
  GET_VACCINATE_DEF,
  GET_ALLERGIES_DEF,
  GET_HEALTH_DEF,
  GET_FAMILIAR_DEF,
  GET_ALL_LIFE_STYLE_DEF,
  UPDATE_DEF_ALLERGIES_CHECK,
  UPDATE_DEF_FAMILIAR_CHECK,
  UPDATE_DEF_HEALTH_CHECK,
  UPDATE_DEF_VACCINATE_CHECK,
  UPDATE_DEF_LIFESTYLE_CHECK,
  SET_SELECTED_HEALTH,
  UPDATE_HEALTH,
} from '../types';
import createAlert from './alert.action';

// UPDATE HEALTH
export const updateHealth = (id, other) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    other: other,
  });

  try {
    const res = await axios.put(
      `/api/patient/history/health/${id}`,
      doc,
      config
    );
    dispatch({
      type: UPDATE_HEALTH,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedHealth = (item) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_HEALTH,
    payload: item,
  });
};

export const setTrueDefLifeStyles = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_LIFESTYLE_CHECK,
    payload: item,
  });
};

export const setFalseDefLifeStyles = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_LIFESTYLE_CHECK,
    payload: item,
  });
};

export const setFalseDefHealth = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_HEALTH_CHECK,
    payload: item,
  });
};

export const setTrueDefHealth = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_HEALTH_CHECK,
    payload: item,
  });
};

export const setTrueDefFamiliar = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_FAMILIAR_CHECK,
    payload: item,
  });
};

export const setFalseDefFamiliar = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_FAMILIAR_CHECK,
    payload: item,
  });
};

export const setTrueDefAllergies = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_ALLERGIES_CHECK,
    payload: item,
  });
};
export const setFalseDefAllergies = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_ALLERGIES_CHECK,
    payload: item,
  });
};

export const setTrueDefVaccinate = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_VACCINATE_CHECK,
    payload: item,
  });
};
export const setFalseDefVaccinate = () => (dispatch) => {
  dispatch({
    type: UPDATE_DEF_VACCINATE_CHECK,
    payload: false,
  });
};

export const getVaccinateDef = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/history/vaccinate/', config);
    dispatch({
      type: GET_VACCINATE_DEF,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllergiesDef = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/history/allergies/', config);
    dispatch({
      type: GET_ALLERGIES_DEF,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllHealthDef = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/history/health/', config);
    dispatch({
      type: GET_HEALTH_DEF,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllLifeStyleDef = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/history/lifeStyle/', config);
    dispatch({
      type: GET_ALL_LIFE_STYLE_DEF,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllFamiliarDef = () => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.get('/api/history/familiar/', config);
    dispatch({
      type: GET_FAMILIAR_DEF,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// SET SELECTED FAMILIAR ANTECEDENTS
export const setSelectedFamiliarAntecedents = (item) => (dispatch) => {
  dispatch({
    type: ADD_SELECTED_FAMILIAR,
    payload: item,
  });
};

// update itemsfamiliar
export const updateFrontendFamiliarItems = (item) => (dispatch) => {
  dispatch({
    type: UPDATE_ITEMS_FRONTEND_FAMILIAR,
    payload: item,
  });
};

export const getItemsFrontendFamiliar = () => (dispatch) => {
  dispatch({
    type: ADD_ITEMS_FRONTEND_FAMILIAR,
    payload: familiarItems,
  });
};

export const openModalFamiliar = () => (dispatch) => {
  dispatch({
    type: ENABLE_OTHER_FAMILIAR,
    payload: true,
  });
};

export const closeModalFamiliar = () => (dispatch) => {
  dispatch({
    type: DISABLE_OTHER_FAMILIAR,
    payload: false,
  });
};

export const updateDeleteAllergies = (id, other) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    other: other,
  });

  try {
    const res = await axios.put(
      `/api/patient/history/allergie/delete/other/${id}`,
      doc,
      config
    );
    dispatch({
      type: UPDATE_ALLERGIE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
// MEDICNES ACTIONS

export const addMedicine = (formData, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    formData: formData,
    patientID: patientID,
  });

  try {
    const res = await axios.post('/api/patient/history/medicines', doc, config);

    dispatch({
      type: ADD_MEDICINE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ADD_MEDICINE,
    });
  }
};

export const getMedicines = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/medicines/${patient}`);
    dispatch({
      type: GET_MEDICINES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteMedicine = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/patient/history/medicines/${id}`);
    dispatch({
      type: DELETE_MEDICINE,
      payload: id,
    });
    createAlert('Correcto!', res.data.message, 'success');
  } catch (error) {
    console.log(error);
  }
};

export const deleteSelectedItem = () => async (dispatch) => {
  dispatch({
    type: DELETE_SELECTED_ITEM,
    payload: null,
  });
};

export const addSelectedItem = (item) => async (dispatch) => {
  dispatch({
    type: ADD_SELECTED_ITEM,
    payload: item,
  });
};

export const updateMedicine = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axios.put(
      `/api/patient/history/medicines/${id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_MEDICINE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// HELATH ACTIONS

export const getHealth = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/health/${patient}`);
    dispatch({
      type: GET_HEALTH,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addHealth = (name, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    name: name,
    patientID: patientID,
  });

  try {
    const res = await axios.post('/api/patient/history/health', doc, config);

    dispatch({
      type: ADD_HEALTH,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteHealth = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/health/${id}`);
    dispatch({
      type: DELETE_HEALTH,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

// ALLERGIES ACTIONS
export const getAllergies = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/allergies/${patient}`);
    dispatch({
      type: GET_ALLERGIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addAllergie = (name, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    name: name,
    patientID: patientID,
  });

  try {
    const res = await axios.post('/api/patient/history/allergies', doc, config);

    dispatch({
      type: ADD_ALLERGIE,
      payload: res.data,
    });
    if (res.data.name === 'Otros') {
      dispatch({
        type: SELECTED_ALLERGIE,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllergie = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/allergies/${id}`);
    dispatch({
      type: DELETE_ALLERGIE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setSelectedAllergie = (item) => (dispatch) => {
  dispatch({
    type: SELECTED_ALLERGIE,
    payload: item,
  });
};

// Familiar Actions
export const getFamiliarAntecedents = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/familiar/${patient}`);
    dispatch({
      type: GET_FAMILIAR,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addFamiliarAntecedents =
  (formData, patientID) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const doc = JSON.stringify({
      name: formData,
      patientID: patientID,
    });

    try {
      const res = await axios.post(
        '/api/patient/history/familiar',
        doc,
        config
      );

      dispatch({
        type: ADD_FAMILIAR,
        payload: res.data,
      });
      dispatch({
        type: ADD_SELECTED_FAMILIAR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const updateFamiliarAntecedent =
  (id, { relation, name, other }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const doc = JSON.stringify({
        other: other,
        relation: relation,
        name: name,
      });

      const res = await axios.put(
        `/api/patient/history/familiar/${id}`,
        doc,
        config
      );

      dispatch({
        type: UPDATE_FAMILIAR,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
// UPDATE ALLERGIE
export const updateAllergies = (id, other) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    other: other,
  });

  try {
    const res = await axios.put(
      `/api/patient/history/allergies/${id}`,
      doc,
      config
    );
    dispatch({
      type: UPDATE_ALLERGIE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteFamiliarAntecedents = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/familiar/${id}`);
    dispatch({
      type: DELETE_FAMILIAR,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSelectedFamiliarItem = (item) => (dispatch) => {
  dispatch({
    type: ADD_SELECTED_FAMILIAR,
    payload: item,
  });
};

export const deleteSelectedFamiliarItem = () => (dispatch) => {
  dispatch({
    type: DELETE_SELECTED_FAMILIAR,
    payload: null,
  });
};
