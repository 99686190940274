/* eslint-disable no-restricted-syntax */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import createAlert from '../../redux/actions/alert.action';
import { addConsultation } from '../../redux/actions/profile.action';
import { deleteAllShoppingItems } from '../../redux/actions/shopping.cart.action';
import { payConsult } from '../../api/payment';
import '../Patient/History/history.css';

const PaymentResumeModal = ({
  open,
  setOpen,
  total,
  shoppingCart,
  addConsultation,
  deleteAllShoppingItems,
  token,
}) => {
  const [loading, setLoading] = useState(false);
  const handlePay = async () => {
    if (!loading) {
      setLoading(true);
      payConsult({ token, shoppingCart }).then(() => {
        createAlert('Compra se realizo exitosamente', 'success');
        deleteAllShoppingItems();
        setOpen(false);
        setLoading(false);
      }).catch(error => {
        console.log(error);
        if (error.info) {
          createAlert(error.info, 'error');
        } else {
          createAlert('Lo sentimos no se pudo realizar la transaccion', 'error');
        }
        setLoading(false);
      });
    } else {
      createAlert('Esta cargando la transaccion', 'error');
    }
  };

  return (
    <div>
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <Modal.Header>
          <h5 className="modal-title text-primary mx-auto" id="exampleModalLabel">
            Resumen de Compra
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="text-uppercase text-primary">
                <tr>
                  <th>Tipo de Consulta</th>
                  <th>Cantidad</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {shoppingCart.map((item) => (
                  <tr key={item._id}>
                    <td className="col-12 col-md-6">
                      {item.consult.type === 'specialty'
                        ? 'Especialidad'
                        : 'Medicina General'}
                    </td>
                    <td className="col-12 col-md-6 text-center">{item.consult.quantity}</td>
                    <td className="col-12 col-md-6 text-center">
                      L.
                      {' '}
                      {item.consult.price}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td className="text-right">
                    <p>
                      Sub Total:
                    </p>
                    <p>
                      Descuento:
                    </p>
                  </td>
                  <td>
                    <p>
                      L.
                      {' '}
                      {total}
                    </p>
                    <p>
                      L. 0
                    </p>
                  </td>
                </tr>
                <tr>
                  <td />
                  <td className="text-right text-secondarymdv">
                    <p className="text-secondarymdv font-weight-bold">
                      Monto a Pagar:
                    </p>
                  </td>
                  <td className="text-left">
                    <p>
                      <strong>
                        L.
                        {' '}
                        {total}
                      </strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">
                    Loading ...
                  </span>
                </Spinner>
              </div>
            ) : (
              <div className="d-flex mr-3">
                <button
                  type="button"
                  className="btn btn-link btn-sm text-secondarymdv font-weight-bold mr-3"
                  onClick={() => setOpen(false)}
                  >
                  Cancelar
                </button>
                <button type="button" className="btn btn-primary btn-sm" onClick={handlePay}>
                  Pagar Ahora
                </button>
              </div>
            )
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  shoppingCart: state.shopping.items,
  total: state.shopping.total,
  token: state.auth.token
});

export default connect(mapStateToProps, {
  addConsultation,
  deleteAllShoppingItems,
})(PaymentResumeModal);
