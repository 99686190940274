import React from 'react';
import HomePacient from '../../../Components/Patient/HomePacient';
import HomePacientMovil from '../../../Components/Patient/HomePacientMovil';

const Home = () => (
  <>
    {/* Web View */}
    <div className="d-none d-sm-block">
      <HomePacient />
    </div>
    {/* Fin Web View */}
    {/* Mobile View */}
    <div className="d-block d-sm-none">
      <HomePacientMovil />
    </div>
    {/* Fin Mobile View */}
  </>
);

export default Home;
