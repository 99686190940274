import React, { useEffect } from 'react';
import { Button, InputGroup, Modal, Form, FormControl } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useUI } from '../../../hooks/useUI';
import { useSearchModalState } from './state/useSearchModalState';

const SearchModal = () => {
  const { modalSearchPatient, handleCloseSearchModal } = useUI();
  const {
    email,
    dni,
    idType,
    searchType,
    cellphone,
    dialCode,
    country,
    setFormat,
    handleChange,
    handleSubmit,
    countries,
    clearForm,
    reStartStateForm,
  } = useSearchModalState();

  const handleClose = () => {
    reStartStateForm();
    handleCloseSearchModal();
  };

  return (
    <Modal
      show={modalSearchPatient}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='align-items-center'>
        <h3 className='mt-2 text-primary ml-4'>Buscar un paciente</h3>
      </Modal.Header>
      <Modal.Body style={{ padding: 40 }}>
        <div className='form-group'>
          <label className='text-primary font-weight-bold float-left'>
            Buscar por:
          </label>
          <select
            className='form-control bg-light'
            id='exampleFormControlSelect1'
            name='searchType'
            value={searchType}
            onChange={handleChange}
            placeholde='Tipo de registro'
          >
            <option value='email'>Correo electrónico</option>
            <option value='dni'>Identidad</option>
            <option value='cellphone'>Teléfono</option>
          </select>
        </div>

        <>
          {searchType === 'dni' && (
            <>
              <div className='form-group'>
                <label className='text-primary font-weight-bold float-left'>
                  Identidad
                </label>
                <select
                  className='form-control bg-light'
                  id='exampleFormControlSelect1'
                  name='idType'
                  value={idType}
                  onChange={handleChange}
                  placeholde='Tipo de identificación'
                >
                  <option value='national'>ID nacional</option>
                  <option value='foreign'>ID extranjero</option>
                  <option value='rtn'>RTN</option>
                </select>
              </div>
              <div className='form-group'>
                {idType === 'ID extranjero' ? (
                  <input
                    type='text'
                    className='form-control bg-light'
                    name='dni'
                    value={dni}
                    onChange={handleChange}
                  />
                ) : (
                  <NumberFormat
                    className='form-control bg-light'
                    format={`${setFormat()}`}
                    mask='_'
                    name='dni'
                    value={dni}
                    onChange={handleChange}
                  />
                )}
              </div>
            </>
          )}
          {searchType === 'cellphone' && (
            <>
              <label className='text-primary font-weight-bold float-left'>
                País
              </label>
              <div>
                <Form.Control
                  as='select'
                  style={{
                    marginBottom: 15,
                  }}
                  onChange={handleChange}
                  name='country'
                  value={country}
                >
                  {countries.map((co) => (
                    <option key={co.code}>{co.name}</option>
                  ))}
                </Form.Control>
              </div>
              <div className='form-group'>
                <InputGroup>
                  <InputGroup.Prepend
                    value={dialCode}
                    name='dialCode'
                    onChange={handleChange}
                  >
                    <InputGroup.Text
                      style={{
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                      }}
                    >
                      {dialCode}
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className='form-control'
                    placeholder='Telefono'
                    name='cellphone'
                    value={cellphone}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
            </>
          )}

          {searchType === 'email' && (
            <>
              <label className='text-primary font-weight-bold float-left'>
                Correo Electrónico
              </label>
              <div className='input-group'>
                <input
                  type='email'
                  className='form-control bg-light'
                  placeholder='Ingrese su correo electrónico'
                  id='inputEmail'
                  name='email'
                  value={email}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </>

        <Button
          onClick={handleSubmit}
          disabled={
            (email === '' && searchType === 'email') ||
            (cellphone === '' && searchType === 'cellphone') ||
            (dni === '' && searchType === 'dni')
          }
          className='mt-4'
        >
          Buscar <i className='fa fa-search ml-2'></i>
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SearchModal;
