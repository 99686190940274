import axios from 'axios';

const uri = '/api/status-videoconference-logs';

export const createStatusVideconferenceLog = async ({
  patientId,
  doctorId,
  videoconferenceId,
  action,
  message,
}) => {
  try {
    const res = await axios.post(`${uri}/create`, {
      patientId,
      doctorId,
      videoconferenceId,
      action,
      message,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
