/* eslint-disable func-names */
import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE,
  UPLOAD_PROFILE_PHOTO,
  FORGOT_PASSWORD,
  UPDATE_USER,
} from '../types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuth: false,
  userLoaded: false,
  user: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuth: true,
        userLoaded: true,
        user: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          firstName: payload.firstName,
          lastName: payload.lastName,
          gender: payload.gender,
        },
      };
    case UPLOAD_PROFILE_PHOTO:
      return {
        ...state,
        user: {
          ...state.user,
          image: payload,
        },
      };

    case CLEAR_PROFILE:
      return {
        ...state,
        isAuth: false,
        userLoaded: false,
        user: null,
      };

    case REGISTER_FAIL:
    case AUTH_ERROR:
    case FORGOT_PASSWORD:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuth: false,
        userLoaded: false,
        user: null,
      };

    default:
      return state;
  }
}
