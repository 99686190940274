import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import createAlert from '../../redux/actions/alert.action';
import { addLabFileById, updateFileName } from '../../api/lab.file';
import { deleteReference, getReference } from '../../redux/actions/references';

const ModalUploadLabFile = ({ show, handleClose, token }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { videoconference } = useSelector((state) => state.videoconference);

  useEffect(() => {
    dispatch(getReference(videoconference?.patient));
    return () => {
      dispatch(deleteReference());
    };
  }, [dispatch, videoconference]);

  const { userRef } = useSelector((state) => state.reference);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSelectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    if (userRef) {
      addLabFileById({
        token,
        formData,
        id: userRef._id,
      })
        .then((res) => {
          updateFileName(res.lab, name)
            .then(() => {
              createAlert(
                '¡Creado!',
                'El archivo fue creado correctamente.',
                'success'
              );
            })
            .catch(() => {
              createAlert(
                '¡Error!',
                'El archivo no pudo ser guardado',
                'error'
              );
            });
        })
        .catch((e) => {
          createAlert(
            '¡Error!',
            'Ocurrió un error al intentar subir el archivo.',
            'error'
          );
        })
        .finally(() => {
          setUploading(false);
          handleClose();
        });
    }
    setFile(null);
    setName('');
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            color: '#011688',
          }}
        >
          Selecciona un documento JPG o PDF:
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
        }}
      >
        <form
          onSubmit={handleSubmit}
          className='row justify-content-center mb-5'
        >
          <div className='col-12'>
            <div className='input-group input-group-lg mb-3'>
              <input
                type='text'
                name='name'
                value={name}
                onChange={handleChange}
                placeholder='Nombre del archivo'
                style={{
                  padding: 10,
                }}
              />
              <input
                type='file'
                className='form-control border-right-0 mt-0'
                placeholder='Selecciona un archivo...'
                aria-label='Selecciona un archivo...'
                aria-describedby='basic-addon2'
                name='labfile'
                accept='.jpg,.jpeg,.pdf'
                style={{
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                }}
                id='input-file'
                onChange={handleSelectFile}
              />
              <div className='input-group-append'>
                <span
                  className='input-group-text border-left-0 border-right-rounded'
                  id='basic-addon2'
                >
                  <img
                    src={require('../../assets/img/vectores/upload.svg')}
                    alt='logo'
                    width='20'
                    className='img-fluid'
                  />
                </span>
              </div>
            </div>
          </div>
          <div className='col-3 mb-3 mt-3'>
            {uploading ? (
              <Spinner
                animation='border'
                role='status'
                variant='primary'
                style={{
                  width: '3rem',
                  height: '3rem',
                }}
              />
            ) : (
              <button
                type='submit'
                className='btn btn-primary btn-block'
                disabled={name.trim().length < 3 || !file}
              >
                Subir
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalUploadLabFile;
