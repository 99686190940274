import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { CgClose } from 'react-icons/all';
import moment from 'moment';
import { Col, Row, Spinner, Table } from 'react-bootstrap';

const CodeValidationSuccess = ({
  giftCards,
  handleClearGiftCardsState,
  handleSubmit,
  isLoading,
  name,
  cellphone,
  email,
  handleChangeName,
  handleChangeCellphone,
  handleChangeEmail,
}) => {
  return (
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div
        className="card border-0 shadow-sm"
        style={{
          borderRadius: '2rem',
        }}
      >
        <div className="card-body-sub" style={{ textAlign: 'center' }}>
          <div className="panel" style={{ textAlign: 'center' }}>
            <label className="text-primary font-weight-bold ml-2 float-center">
              Número de factura
            </label>
          </div>

          <div className="mb-3" style={{ textAlign: 'center' }}>
            <label className="text-primary font-weight-bold ml-2 float-center">
              {giftCards[0]?.Factura_Numero_CAI}
            </label>
          </div>

          <Table striped bordered hover>
            <thead style={{ borderBottom: 'transparent' }}>
              <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {giftCards.map((card) => (
                <tr key={card.id}>
                  <td>{card.Detalle_Articulo_Nombre}</td>
                  <td>{card.Detalle_Cantidad}</td>
                  <td>
                    {
                      <NumberFormat
                        value={card.Detalle_Total * card.Detalle_Cantidad}
                        className="foo"
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'L.'}
                        renderText={(value, props) => (
                          <div {...props}>{value}</div>
                        )}
                      />
                    }
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={2}>Total</td>
                <td>
                  {
                    <NumberFormat
                      value={giftCards
                        .map((card) =>
                          card.Detalle_Cantidad === 1
                            ? card.Detalle_Total
                            : card.Detalle_Total * card.Detalle_Cantidad
                        )
                        .reduce((prev, current) => prev + current, 0)}
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'L.'}
                      renderText={(value, props) => (
                        <div {...props}>{value}</div>
                      )}
                    />
                  }
                </td>
              </tr>
            </tbody>
          </Table>

          {/* <div
            className="mb-3"
            style={{ textAlign: 'center', fontSize: '2.5em' }}
          >
            {giftCards.length > 1 && (
              <label
                className="text-primary font-weight-bold"
                style={{
                  fontSize: 20,
                }}
              >
                Total
              </label>
            )}
            <NumberFormat
              value={giftCards
                .map((card) => card.Detalle_Total)
                .reduce((prev, current) => prev + current, 0)}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={'L.'}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </div> */}
          {/* <Row>
            <Col>
              <label
                className="text-primary font-weight-bold ml-2"
                style={{ fontSize: 20 }}
              >
                Cantidad de Giftcard:{' '}
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {Detalle_Cantidad}
                </span>
              </label>
            </Col>
          </Row> */}

          <Row>
            <Col xs={5} style={{ textAlign: 'start' }}>
              <label>Fecha de compra:</label>
            </Col>
            <Col xs={7} style={{ textAlign: 'start' }}>
              <label className="text-primary font-weight-bold ml-2 float-center">
                {moment(giftCards[0]?.Factura_Fecha).format('LLLL')}
              </label>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6}>
              <div className="form-group">
                <label className="text-primary font-weight-bold float-left">
                  Nombre del cliente
                </label>
                <input
                  type="name"
                  className="form-control bg-light"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleChangeName}
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="form-group">
                <label className="text-primary font-weight-bold float-left">
                  Email
                </label>
                <input
                  type="name"
                  className="form-control bg-light"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="form-group">
                <label className="text-primary font-weight-bold float-left">
                  Telefono
                </label>
                <input
                  type="name"
                  className="form-control bg-light"
                  id="cellphone"
                  name="cellphone"
                  value={cellphone}
                  onChange={handleChangeCellphone}
                />
              </div>
            </Col>
          </Row>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              disabled={
                cellphone.trim().length === 0 ||
                email.trim().length === 0 ||
                name.trim().length === 0
              }
              className="btn btn-primary btn-block"
              style={{ margin: 0, width: '60%' }}
              onClick={() => {
                handleSubmit(giftCards, name, cellphone, email);
              }}
            >
              {isLoading ? (
                <Spinner
                  style={{
                    width: 20,
                    height: 20,
                    color: '#ffff',
                    fontSize: 8,
                  }}
                  animation="border"
                />
              ) : (
                'CANJEAR'
              )}
            </button>
          </div>
          <br />
        </div>
      </div>
      <div
        style={{ position: 'absolute', top: 20, right: 50, cursor: 'pointer' }}
        onClick={() => {
          handleClearGiftCardsState();
        }}
      >
        <CgClose />
      </div>
    </div>
  );
};

export default connect(null)(CodeValidationSuccess);
