import axios from 'axios';

export const urlProfile = '/api/profile';

export const getListOnlineDoctor = ({ token, page }) =>
  new Promise((resolve, reject) => {
    if (token && page !== undefined) {
      axios
        .get(`${urlProfile}/doctor/online/${page}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Error verifique que todos los parametros se hallan enviado',
      });
    }
  });

export const getConsultoryDoctors = ({ token, consultoryId }) =>
  new Promise((resolve, reject) => {
    if (token && consultoryId) {
      axios
        .get(`${urlProfile}/doctor/consultory/${consultoryId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Error verifique que todos los parametros se hayan enviado',
      });
    }
  });

export const getProfileDoctorLoggedIn = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${urlProfile}/profile/doctor/loggedin`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Error verifique los parametros enviados',
      });
    }
  });

export const getListAvailableDoctor = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${urlProfile}/doctor/available`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject({
        status: 'error',
        info: 'Error verifique los parametros enviados',
      });
    }
  });
