import { useDispatch } from 'react-redux';
import useFarinterSelector from '../../../hooks/useFarinterSelector';
import { setPagination } from '../../../redux/actions/pagination.action';

const usePagiantion = () => {
  const dispatch = useDispatch();
  const { next, prev, center, actualPage } = useFarinterSelector().pagination;

  const handleChangePage = (page) => {};

  const handleNextPage = () => {
    if (actualPage === next) {
      dispatch(
        setPagination({
          actualPage: actualPage + 1,
          next: actualPage + 1,
          prev: prev + 1,
          center: center + 1,
        })
      );
    } else {
      dispatch(
        setPagination({
          actualPage: actualPage + 1,
          next,
          prev,
          center,
        })
      );
    }
  };
  const handleBackPage = () => {
    if (actualPage === prev) {
      dispatch(
        setPagination({
          actualPage: actualPage - 1,
          next: next - 1,
          prev: prev - 1,
          center: center - 1,
        })
      );
    } else {
      dispatch(
        setPagination({
          actualPage: actualPage - 1,
          next,
          prev,
          center,
        })
      );
    }
  };

  return {
    handleChangePage,
    handleNextPage,
    handleBackPage,
  };
};

export default usePagiantion;
