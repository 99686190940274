/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addProfileImage } from '../../../redux/actions/profile.action';
import imgAvatarTemp from '../../../assets/img/avatars/avatar-doc-male.png';
import imgAvatarFemale from '../../../assets/img/avatars/avatar-pat-female.png';
import '../../../stylesheets/profile.css';
import { upcomingScheduling } from '../../../api/scheduling';
import { getListAvailableDoctor } from '../../../api/doctor';
import { observerDoctorOnline } from '../../../websokect/patientWebSocket';
import { openModalProfileImage } from '../../../redux/actions/ui.action';
import ModalProfileImagen from '../../Modal/AddProfileImageModal';
import { url, showAdsFile } from '../../../api/ads';
import AddDepedentModal from '../AddDependentModal';
import { validGroupPackageUser } from '../../../api/user';
import ModalDescriptionProduct from '../../Modal/ModalDescriptionProduct';

const MedicalOptions = ({ user: { gender }, token }) => {
  const [, setUpcomingScheduling] = useState([]);
  const [dataAds, setDataAds] = useState([]);
  const [, setGenderImage] = useState(null);
  const [, setDoctorList] = useState([]);
  const [, setValidGroup] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    validGroupPackageUser({ token }).then(() => {
      setValidGroup(true);
    });
    // Valid gender image profile
    if (gender === 'male') {
      setGenderImage(imgAvatarTemp);
    } else {
      setGenderImage(imgAvatarFemale);
    }
    /* trayendo las proximas consultas del paciente */
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        setUpcomingScheduling(data);
      })
      .catch((error) => {
        console.log(error);
      });
    getListAvailableDoctor({ token })
      .then((res) => {
        const { doctors } = res;
        setDoctorList(doctors);
      })
      .catch((error) => {
        console.log(error);
      });
    observerDoctorOnline(() => {
      getListAvailableDoctor({ token })
        .then((res) => {
          const { doctors } = res;
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    showAdsFile({ token }).then((res) => {
      const { data } = res;
      setDataAds(data);
    });
  }, [token, gender]);

  return (
    <>
      <ModalDescriptionProduct />
      <ModalProfileImagen />
      <AddDepedentModal show={open} onClose={() => setOpen(!open)} />
      {/* consultas medicas */}
      <div className='sales h-100'>
        <div className='main-section text-center'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12 col-12 profile-header'>
              <h3 className='text-white text-center mt-4'>Consultas Médicas</h3>
            </div>
          </div>
          <div className='grid-layout-auto justify-content-center mb-3'>
            <div>
              <Link
                to='/dashboard/telellamada'
                style={{ textDecoration: 'none' }}
                // className="text-right"
                role='button'
                aria-disabled='true'
              >
                <div className='bg-home-tele shadow-sm h-100'>
                  <h5 className='font-weight-bold text-primary'>
                    <img
                      src={require('../../../assets/img/icons/camera.png')}
                      alt='logo'
                      width='50'
                      className='img-fluid mb-2 mt-2'
                    />
                    <br />
                    Tele
                    <br />
                    Medicina
                  </h5>
                </div>
              </Link>
            </div>
            <div>
              <Link
                to='/dashboard/consulta-presencial'
                style={{ textDecoration: 'none' }}
                // className="text-right"
                role='button'
                aria-disabled='true'
              >
                <div className='bg-home-tele shadow-sm h-100'>
                  <h5 className='font-weight-bold text-primary'>
                    <img
                      src={require('../../../assets/img/icons/add-heart.svg')}
                      alt='logo'
                      width='50'
                      className='img-fluid mb-2 mt-2'
                    />
                    <br />
                    Consulta
                    <br />
                    Presencial
                  </h5>
                </div>
              </Link>
            </div>
            <div>
              <Link
                to='/dashboard/laboratorio'
                style={{ textDecoration: 'none' }}
                // className="text-right"
                role='button'
                aria-disabled='true'
              >
                <div className='bg-home-tele shadow-sm h-100'>
                  <h5 className='font-weight-bold text-primary'>
                    <img
                      src={require('../../../assets/img/icons/microscopio2.svg')}
                      alt='logo'
                      width='55'
                      className='img-fluid mb-2 mt-2'
                    />
                    <br />
                    Laboratorio
                  </h5>
                </div>
              </Link>
            </div>
          </div>
          <div
            id='carouselExampleControls'
            className='carousel slide m-3'
            data-ride='carousel'
          >
            {dataAds.length === 0 ? (
              <div className='carousel-inner p-2 justify-content-center'>
                <div className='carousel-item active'>
                  <img
                    src={require('../../../assets/img/banner-inicial.jpg')}
                    className='d-block w-100'
                    alt='...'
                  />
                </div>
              </div>
            ) : (
              <div className='carousel-inner p-2 justify-content-center'>
                {dataAds.map((doc, i) => (
                  <div
                    key={doc._id}
                    className={`carousel-item ${i === 0 ? 'active' : ''}`}
                  >
                    <img
                      src={`${url}/file/${doc.filename}`}
                      className='d-block w-100'
                      alt='...'
                    />
                  </div>
                ))}
              </div>
            )}
            <a
              className='carousel-control-prev'
              href='#carouselExampleControls'
              role='button'
              data-slide='prev'
            >
              <i
                className='fas fa-caret-square-left fa-2x arrow-left-app'
                aria-hidden='true'
              />
              <span className='sr-only'>Previous</span>
            </a>
            <a
              className='carousel-control-next'
              href='#carouselExampleControls'
              role='button'
              data-slide='next'
            >
              <i
                className='fas fa-caret-square-right fa-2x arrow-right-app'
                aria-hidden='true'
              />
              <span className='sr-only'>Next</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
  planRef: state.reference.planRef,
});

export default connect(mapStateToProps, {
  addProfileImage,
  openModalProfileImage,
})(MedicalOptions);
