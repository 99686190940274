/* eslint-disable arrow-parens */
import React from 'react';
import {connect} from 'react-redux';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

const PrincipalLayout = ({children}) => (
  <div className="App">
    <Navbar />
    {children}
    <Footer />
  </div>
);

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(PrincipalLayout);
