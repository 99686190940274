import axios from 'axios';
export const getEmployeeById = (dni) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/companies/employee/by/dni/${dni}`, {
        headers: {
          'Content-Type': 'Application/json',
        },
      })
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((error) => reject(error));
  });

export const addEmployeeToCompany = (companyId, userId) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/companies/employee/add/${companyId}/${userId}`, {
        headers: {
          'Content-Type': 'Application/json',
        },
      })
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((error) => reject(error));
  });
