import React from 'react';

function ChatHEA({ hea, handleHea }) {
  return (
    <div>
      <div className='panel-group '>
        <div className='container'>
          <textarea
            className='form-control w-100'
            onChange={(e) => handleHea(e.target.value)}
            value={hea}
            rows={5}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatHEA;
