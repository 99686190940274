/* eslint-disable arrow-parens */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Autocomplete from "../../Autocomplete";
import {
  updateFamiliarAntecedent,
  deleteSelectedFamiliarItem,
  deleteFamiliarAntecedents,
  setFalseDefFamiliar,
  closeModalFamiliar,
} from "../../../redux/actions/patient.history.action";
import "./history.css";

const relationArray = [
 "Padres" ,
  "Tíos" ,
   "Abuelos" ,
   "Hermanos" ,
];

const ModalFamiliarAntecedents = ({
  selectedItem,
  deleteSelectedFamiliarItem,
  updateFamiliarAntecedent,
  deleteFamiliarAntecedents,
  setFalseDefFamiliar,
  openModal,
  closeModalFamiliar,
}) => {
 
  const array = [];
  if (selectedItem && selectedItem.relation) {
    selectedItem.relation.forEach((relation) => {
      array.push({
        value: relation,
        label: relation,
      });
    });
  }
 
  const [defaultOptions, setDefaultOptions] = useState(array);
  const [selectedRelations, setSelectedRelations] = useState([]);
  useEffect(() => {
    //console.log("DefaultOptions actualizados:", defaultOptions);
  }, [defaultOptions,selectedRelations]);
  const handleHide = () => {
    if (selectedItem.relation.length !== 0) {
      deleteSelectedFamiliarItem();
    } else {
      deleteFamiliarAntecedents(selectedItem._id);
      selectedItem.checked = false;
      setFalseDefFamiliar(selectedItem);
      deleteSelectedFamiliarItem();
    }
    closeModalFamiliar();
  };
  const handleChangeAutocomplete = (items) => {
    if (items === null) {
      setDefaultOptions([]);
    } else {
      setDefaultOptions(prevOptions => {
        let newOptions = [...prevOptions];
        let newRelations = [...selectedRelations];
        const newItems = {
          value: items,
          label: items,
        };
        const exists = prevOptions.some(item => item.value === newItems.value);
  
        if (!exists) {
          newRelations.push(newItems.value);
          newOptions.push(newItems);
          setSelectedRelations(newRelations);
        }
        return newOptions;
      });
    }
  };

  const removeOption = (valueToRemove) => {
    setDefaultOptions(prevOptions =>
      prevOptions.filter(option => option.value !== valueToRemove)
    );
    setSelectedRelations(prevRelations =>
      prevRelations.filter(relation => relation !== valueToRemove)
    );
  };

  const handleUpdate = () => {
    const tempValues = [];
    defaultOptions.forEach((item) => {
      tempValues.push(item.value);
    });
    const formData = {
      relation: tempValues,
    };
    updateFamiliarAntecedent(selectedItem._id, formData);
    setDefaultOptions(array);
    setSelectedRelations([]);
    closeModalFamiliar();
  };

  return (
    <Modal show={openModal} onHide={handleHide} centered>
      <Modal.Header>
        <h4 className="text-primary mx-auto">{selectedItem.name}</h4>
      </Modal.Header>
      <Modal.Body>
        <Autocomplete
          placeholder="Relación"
          options={relationArray}
          handleChangeAutocomplete={handleChangeAutocomplete}
          name="relations"
          defOptions={defaultOptions}
          multi={true}
          removeOption={removeOption}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex mr-3">
          <button
            type
            onClick={handleHide}
            className="btn btn-link btn-sm text-secondarymdv font-weight-bold mr-3"
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={handleUpdate}
            disabled={selectedRelations.length === 0}
          >
            Aceptar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  selectedItem: state.history.familiar.selectedItem,
  openModal: state.history.familiar.modal,
});

export default connect(mapStateToProps, {
  deleteSelectedFamiliarItem,
  updateFamiliarAntecedent,
  deleteFamiliarAntecedents,
  setFalseDefFamiliar,
  closeModalFamiliar,
})(ModalFamiliarAntecedents);
