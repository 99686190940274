import Axios from 'axios';
import { useEffect, useState } from 'react';

const usePlans = () => {
  const url = '/api/plans';

  const [plans, setPlans] = useState([]);
  const getAllPlans = async () => {
    const res = await Axios.get(`${url}/all`);
    setPlans(res.data);
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  return {
    plans,
  };
};

export default usePlans;
