import React from 'react';
import HomeManageEmployees from '../../Components/Enterprise/HomeManageEmployees';

const ManageEmployees = ({history}) => (

  <HomeManageEmployees history={history}/>

);

export default ManageEmployees;
