import axios from 'axios';

export const url = '/api/presale';

export const addUpdatePresale = ({
  token,
  idVideo,
  arrayProducts,
  arrayServices,
}) =>
  new Promise((resolve, reject) => {
    if (token && idVideo && arrayProducts && arrayServices) {
      axios
        .post(`${url}/add/update`, {
          idVideo,
          arrayProducts,
          arrayServices,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((err) => reject(err));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else if (!idVideo) {
      reject({
        status: 'error',
        info: 'Consulta no definida',
      });
    } else if (!arrayProducts) {
      reject({
        status: 'error',
        info: 'Productos no definido',
      });
    } else if (!arrayServices) {
      reject({
        status: 'error',
        info: 'Servicios no definidos',
      });
    } else {
      reject({
        status: 'error',
        info: 'Debe de poseer por lo menos un servicio o producto',
      });
    }
  });

export const changePresalePlan = async ({ code, userId }) => {
  try {
    const response = await axios.post(`/api/plans/presale-active-plan`, {
      code,
      userId,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
