import { useState } from 'react';
import { veryfyOrderNumber, createGiftCard } from '../../../../api/giftCard';
import createAlert from '../../../../redux/actions/alert.action';
import { exportComponentAsJPEG } from 'react-component-export-image';
const useGifcardState = () => {
  const [orderNumber, setOrderNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGetCode, setIsLoadingGetCode] = useState(false);
  const [giftCards, setGiftcards] = useState([]);
  const [isDisableValidButton, setIsDisableValidButton] = useState(false);
  const [isDisableInputOrderNumber, setIsDisableOrderNumber] = useState(false);
  const [giftCard, setGiftCard] = useState(null);
  const [name, setName] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');

  const handleValidOrderNumber = (e) => {
    e.preventDefault();
    setIsLoading(true);

    veryfyOrderNumber(orderNumber)
      .then((res) => {
        if (res.data.length === 0) {
          createAlert(
            'Ups!',
            'Al parecer este número de factura ha sido canjeado, no continene ningun producto gifcard o ya no se encuentra disponible',
            'info'
          );
          setOrderNumber('');
        } else {
          setGiftcards(res.data);
          handleChangeDisbleButtons();
        }
      })
      .catch((error) => {
        createAlert('Error!', error?.response?.data?.message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePrintGiftCards = (gifCardRef) => {
    if (gifCardRef) {
      exportComponentAsJPEG(gifCardRef, {
        fileName: `GiftCard ${name.split(' ')[0]} ${name.split(' ')[0]}`,
      });
    }
  };

  const handleChangeOrderNumber = ({ target }) => {
    setOrderNumber(target.value);
  };

  const handleChangeName = ({ target }) => {
    setName(target.value);
  };

  const handleChangeCellphone = ({ target }) => {
    setCellphone(target.value);
  };

  const handleChangeEmail = ({ target }) => {
    setEmail(target.value);
  };

  const handleClearGiftCardsState = () => {
    setGiftcards([]);
    handleChangeDisbleButtons();
  };

  const handleChangeDisbleButtons = () => {
    setIsDisableOrderNumber(!isDisableInputOrderNumber);
    setIsDisableValidButton(!isDisableValidButton);
  };

  const handleGetCode = (giftCards, name, cellphone, email) => {
    setIsLoadingGetCode(true);
    createGiftCard(giftCards, name, cellphone, email)
      .then((res) => {
        createAlert(
          'Hecho!',
          'Se ha generado el codigo de la giftcard',
          'success'
        );
        setGiftCard(res.data);
      })
      .catch((error) => {
        createAlert('Error!', error?.response?.data?.message, 'error');
      })
      .finally(() => {
        setIsLoadingGetCode(false);
      });
  };

  return {
    handleValidOrderNumber,
    handleChangeOrderNumber,
    orderNumber,
    isLoading,
    giftCards,
    handleClearGiftCardsState,
    isDisableValidButton,
    isDisableInputOrderNumber,
    handleChangeDisbleButtons,
    handleGetCode,
    isLoadingGetCode,
    giftCard,
    name,
    cellphone,
    email,
    handleChangeName,
    handleChangeCellphone,
    handleChangeEmail,
    handlePrintGiftCards,
  };
};

export default useGifcardState;
