/* eslint-disable global-require */
/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Hero from '../../Components/Hero';
import HowWorks from '../../Components/HomePage/HowWorks';
import Features from '../../Components/HomePage/Features';
import Benefits from '../../Components/HomePage/Benefits';
import PharmaKielsa from '../../Components/HomePage/PharmaKielsa';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

const Home = ({ isAuth }) => {
  if (isAuth) {
    return <Redirect to='/dashboard' />;
  }
  return (
    <>
      <Navbar
        image={
          <img
            src={require('../../assets/img/foto-doctora-3.png')}
            alt='foto-doctora-kcl'
            className='img-fluid imga d-none d-sm-none d-md-block'
          />
        }
      />
      <Hero />
      <Features />
      <HowWorks />
      <Benefits />
      <PharmaKielsa />
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(Home);
