import React from 'react';
import ReactStars from 'react-rating-stars-component';

const RatingModal = ({ handleSubmit, ratingChanged }) => (
  <div
    className="modal fade"
    id="ratingModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            CALIFICACIÓN DEL MÉDICO
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
            >
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
              >
              <div className="form-group text-center">
                <ReactStars
                  classNames="mx-auto"
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  emptyIcon={<i className="far fa-star" />}
                  halfIcon={<i className="fa fa-star-half-alt" />}
                  fullIcon={<i className="fa fa-star" />}
                  activeColor="#ffd700"
                  />
                ,
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="4"
                  name="commentPatient"
                  placeholder="Algún comentario"
                  />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: '200px' }}
                  >
                  Finalizar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RatingModal;
