/* eslint-disable implicit-arrow-linebreak */
import Swal from 'sweetalert2';

const alert = (title, message, type) =>
  Swal.fire({
    title: title,
    icon: type,
    text: message,
    showClass: {
      popup: 'animate__animated animate__bounceIn',
    },
    hideClass: {
      popup: 'animate__animated animate__bounceOut',
    },
  });

export default alert;
