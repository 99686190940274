/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import moment from 'moment';
import 'moment/locale/es';

function ChatDetails({
  patient,
  dataVideoconference,
  reason,
  diagnosis,
  handleChangeReason,
  handleChangeDiagnosis,
  handleOnBlurReason,
  handleOnBlurDiagnosis,
}) {
  return (
    <table className="table table-striped">
      <thead>
        <h5 className="text-secondarymdv text-start">Detalles de la Cita</h5>
      </thead>
      <tbody>
        <tr>
          <td className="text-secondarymdv font-weight-bold">Fecha:</td>
          <td className="text-black-50 text-center">
            {dataVideoconference &&
              `${moment(dataVideoconference.createdAt)
                .locale('es')
                .format('LLLL')}`}
          </td>
        </tr>
        <tr>
          <td className="text-secondarymdv font-weight-bold">
            Nombre del Paciente:
          </td>
          <td className="text-black-50 text-center">
            {patient &&
              `${patient.publicData.firstName} ${patient.publicData.lastName}`}
          </td>
        </tr>
        <tr>
          <td className="text-secondarymdv font-weight-bold">
            Tipo de Contacto:
          </td>
          <td className="text-black-50 text-center">Chat</td>
        </tr>
        <tr>
          <td className="text-secondarymdv font-weight-bold">Motivo:</td>
          <td>
            {' '}
            <input
              type="text"
              className="form-control"
              name="nombre"
              value={reason}
              onChange={(e) => handleChangeReason(e.target.value)}
              onBlur={(e) => handleOnBlurReason(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td className="text-secondarymdv font-weight-bold">Diagnóstico:</td>
          <td>
            <textarea
              type="text"
              value={diagnosis}
              className="form-control"
              aria-label="With textarea"
              name="nombre"
              onChange={(e) => handleChangeDiagnosis(e.target.value)}
              onBlur={(e) => handleOnBlurDiagnosis(e.target.value)}
            />{' '}
            {/* <input
                type="text"
                value={diagnosis}
                className="form-control-search"
                name="nombre"
                onChange={(e) => handleChangeDiagnosis(e.target.value)}
              /> */}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ChatDetails;
