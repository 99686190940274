/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import createAlert from '../../../redux/actions/alert.action';
import { addProfileImage } from '../../../redux/actions/profile.action';
import imgAvatarTemp from '../../../assets/img/avatars/avatar-doc-male.png';
import imgAvatarFemale from '../../../assets/img/avatars/avatar-pat-female.png';

import '../../../stylesheets/profile.css';
import { upcomingScheduling } from '../../../api/scheduling';
import { getListAvailableDoctor } from '../../../api/doctor';
import {
  observerDoctorOnline,
  sendCall,
} from '../../../websokect/patientWebSocket';
import { createVideoconference } from '../../../api/videoconference';
import { openModalProfileImage } from '../../../redux/actions/ui.action';
import { isDependentPatient } from '../../../api/dependentPatient';
import { showAdsFile } from '../../../api/ads';
import { changeConsultDoctorOffice } from '../../../websokect/consultDoctorOfficeWebsocket';
import CardCashPackageKtm from '../../CardCashPackageKtm';
import { validUserPatient, validGroupPackageUser } from '../../../api/user';
import { Spinner } from 'react-bootstrap';
import { createStatusVideconferenceLog } from '../../../api/status-videconference-logs/index';
import { statusvideconferenceEnum } from '../../../enums/status-videconference-logs.enum';

const Doctors = ({ user: { gender, role, _id }, token, planRef }) => {
  const [, setUpcomingScheduling] = useState([]);
  const [, setValidPatient] = useState(true);
  const [, setDataAds] = useState([]);
  const [, setGenderImage] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [, setIsDependent] = useState(false);
  const [, setValidGroup] = useState(false);
  const [callingDoctor, setCallingDoctor] = useState(false);
  useEffect(() => {
    validGroupPackageUser({ token }).then(() => {
      setValidGroup(true);
    });
    // Valid gender image profile
    if (gender === 'male') {
      setGenderImage(imgAvatarTemp);
    } else {
      setGenderImage(imgAvatarFemale);
    }
    /* trayendo las proximas consultas del paciente */
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        setUpcomingScheduling(data);
      })
      .catch((error) => {
        console.log(error);
      });
    getListAvailableDoctor({ token })
      .then((res) => {
        const { doctors } = res;
        setDoctorList(doctors);
      })
      .catch((error) => {
        console.log(error);
      });
    observerDoctorOnline(() => {
      getListAvailableDoctor({ token })
        .then((res) => {
          const { doctors } = res;
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    showAdsFile({ token }).then((res) => {
      const { data } = res;
      setDataAds(data);
    });
    validUserPatient({ token })
      .then(() => {
        setValidPatient(true);
      })
      .catch(() => setValidPatient(false));
    isDependentPatient({ token }).then(() => {
      setIsDependent(true);
    });
  }, [gender, token]);

  const handleVideoconference = (doctor) => {
    if (doctor.profile.status === 'available') {
      createVideoconference({
        token: token,
        doctor: doctor._id,
        type: 'videocall',
      })
        .then((response) => {
          const { idVideo, idDoctorConsult } = response;

          createStatusVideconferenceLog({
            patientId: _id,
            doctorId: doctor._id,
            videoconferenceId: idVideo,
            action: statusvideconferenceEnum.patientStartVideoconference,
            message: 'El paciente comenzó una nueva videollamada',
          });

          changeConsultDoctorOffice({
            idConsult: idDoctorConsult,
          });
          sendCall(
            {
              doctorId: doctor._id,
              role: role,
              idVideo: idVideo,
              type: 'videocall',
            },
            (data) => {
              if (data.status === 'success') {
                window.location = `/dashboard/waitingcall/${idVideo}`;
              } else {
                setCallingDoctor(false);
                createAlert(
                  '¡Error!',
                  'Lo sentimos no pudimos crear la videollamada.',
                  'error'
                );
              }
            }
          );
        })
        .catch((error) => {
          setCallingDoctor(false);
          createAlert(
            '¡Error!',
            'Lo sentimos no pudimos crear la videollamada.',
            'error'
          );
        });
    } else {
      setCallingDoctor(false);
      createAlert(
        '¡Advertencia!',
        'Lo sentimos, el médico no se encuentra disponible.',
        'warning'
      );
    }
  };

  const validColor = (doctor) => {
    const { status } = doctor?.profile;
    if (status === 'available') {
      return (
        <img
          className='img-fluid img-circle img-doc-p state-online'
          src={
            doctor.image ? `/api/profile/image/${doctor.image}` : imgAvatarTemp
          }
          alt='Perfil'
        />
      );
    } else if (status === 'occupied') {
      return (
        <img
          className='img-fluid img-circle img-doc-p state-busy'
          src={
            doctor.image ? `/api/profile/image/${doctor.image}` : imgAvatarTemp
          }
          alt='Perfil'
        />
      );
    } else {
      return (
        <div className='overlay-doc img-fluid mx-auto'>
          <img
            id='overlay-doctor'
            className='img-fluid img-circle img-doc-p state-offline'
            src={
              doctor.image
                ? `/api/profile/image/${doctor.image}`
                : imgAvatarTemp
            }
            alt='Perfil'
          />
        </div>
      );
    }
  };
  return (
    <>
      {/* médicos a un clic */}
      {planRef && (
        <div className='sales h-100'>
          <h4 className='text-secondarymdv font-weight-light mt-2 mb-4'>
            Médicos a un Clic
          </h4>
          {callingDoctor ? (
            <di>
              <div className='row flex-row flex-nowrap scroll-h mar-2'>
                <div
                  className='col-12 col-sm-6 col-md-4'
                  style={{
                    padding: '5%',
                    paddingLeft: '45%',
                    paddingRight: '25%',
                  }}
                >
                  <Spinner animation='border' variant='primary' />
                  <div
                    style={{
                      paddingRight: '45%',
                    }}
                  >
                    Procesando llamada...
                  </div>
                </div>
              </div>
            </di>
          ) : (
            <div className='row flex-row flex-nowrap scroll-h mar-2'>
              {doctorList.length > 0 ? (
                doctorList.some(
                  (doctor) => doctor.profile.status === 'available'
                ) ? (
                  doctorList.map((doctor) => {
                    if (doctor.profile.status === 'available') {
                      return (
                        <div
                          key={doctor._id}
                          onClick={() => {
                            setCallingDoctor(true);
                            handleVideoconference(doctor);
                          }}
                          className='pointer text-center mr-2'
                        >
                          {validColor(doctor)}
                          <h6 className='text-black-50'>
                            Dr. {doctor.lastName}
                          </h6>
                          <span>{doctor.specialty}</span>
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className='col-12 text-center'>
                    No hay médicos disponibles por el momento...
                  </div>
                )
              ) : (
                <div className='col-12 text-center'>
                  No hay médicos disponibles...
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* médicos a un clic */}
      {!planRef && (
        <>
          <div />
          <CardCashPackageKtm />
          <br />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
  planRef: state.reference.planRef,
});

export default connect(mapStateToProps, {
  addProfileImage,
  openModalProfileImage,
})(Doctors);
