/* eslint-disable func-names */
import {
  GET_ALL_DOCTORS_BY_CONSULTORY,
  DELETE_ALL_DOCTORS_BY_CONSULTORY,
  GET_CONSULTORIES,
  DELETE_CONSULTORIES,
  SELECT_CONSULTORY,
  RESET_SELECT_CONSULTORY,
} from "../types";

const initialState = {
  items: [],
  doctors: [],
  selectedConsultory: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_DOCTORS_BY_CONSULTORY:
      return {
        ...state,
        doctors: payload,
      };

    case DELETE_ALL_DOCTORS_BY_CONSULTORY:
      return {
        ...state,
        doctors: [],
      };
    case GET_CONSULTORIES:
      return {
        ...state,
        items: payload,
      };
    case DELETE_CONSULTORIES:
      return {
        ...state,
        items: [],
      };

    case SELECT_CONSULTORY:
      return {
        ...state,
        selectedConsultory: payload,
      };
    case RESET_SELECT_CONSULTORY:
      return {
        ...state,
        selectedConsultory: null,
      };

    default:
      return state;
  }
}
