/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import createAlert from '../../../redux/actions/alert.action';
import { addProfileImage } from '../../../redux/actions/profile.action';
import imgAvatarTemp from '../../../assets/img/avatars/avatar-doc-male.png';
import imgAvatarFemale from '../../../assets/img/avatars/avatar-pat-female.png';

import '../../../stylesheets/profile.css';
import { upcomingScheduling } from '../../../api/scheduling';
import { getListAvailableDoctor } from '../../../api/doctor';
import { observerDoctorOnline } from '../../../websokect/patientWebSocket';
import { openModalProfileImage } from '../../../redux/actions/ui.action';
import ModalProfileImagen from '../../Modal/AddProfileImageModal';
import {
  searchPatient,
  createDependentPatient,
  isDependentPatient,
} from '../../../api/dependentPatient';
import { showAdsFile } from '../../../api/ads';
import AddDepedentModal from '../AddDependentModal';
import { validUserPatient, validGroupPackageUser } from '../../../api/user';
import ModalDescriptionProduct from '../../Modal/ModalDescriptionProduct';

const DependentPatients = ({ user: { gender }, token, planRef }) => {
  const [, setUpcomingScheduling] = useState([]);
  const [, setValidPatient] = useState(true);
  const [, setDataAds] = useState([]);
  const [, setGenderImage] = useState(null);
  const [, setDoctorList] = useState([]);
  const [, setIsDependent] = useState(false);
  const [patients, setPatients] = useState([]);
  const [loadingPatient, setLoadingPatient] = useState(false);
  const [, setValidGroup] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    validGroupPackageUser({ token }).then(() => {
      setValidGroup(true);
    });
    // Valid gender image profile
    if (gender === 'male') {
      setGenderImage(imgAvatarTemp);
    } else {
      setGenderImage(imgAvatarFemale);
    }
    /* trayendo las proximas consultas del paciente */
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        setUpcomingScheduling(data);
      })
      .catch((error) => {
        console.log(error);
      });
    getListAvailableDoctor({ token })
      .then((res) => {
        const { doctors } = res;
        setDoctorList(doctors);
      })
      .catch((error) => {
        console.log(error);
      });
    observerDoctorOnline(() => {
      getListAvailableDoctor({ token })
        .then((res) => {
          const { doctors } = res;
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    showAdsFile({ token }).then((res) => {
      const { data } = res;
      setDataAds(data);
    });
    validUserPatient({ token })
      .then(() => {
        setValidPatient(true);
      })
      .catch(() => setValidPatient(false));
    isDependentPatient({ token }).then(() => {
      setIsDependent(true);
    });
  }, [token, gender]);

  return (
    <>
      <ModalDescriptionProduct />
      <ModalProfileImagen />
      <AddDepedentModal
        dataEmployee={{}}
        employeedUser={false}
        show={open}
        onClose={() => setOpen(!open)}
      />
      {/* búsqueda de pacientes dependientes */}
      {/*planRef &&
        (planRef?.plan.coverage === 'familiar' ||
          planRef?.plan.coverage === 'group') && (*/}
      <div className='sales h-100'>
        <h4 className='text-secondarymdv font-weight-light mt-2 mb-0'>
          Búsqueda de Pacientes Dependientes
        </h4>
        <small>No es obligatorio que agregues un dependiente a tu plan.</small>
        <div className='row'>
          <div className='col-sm-10'>
            <form
              className='input-group mb-1 mt-3'
              onSubmit={(e) => {
                e.preventDefault();
                setLoadingPatient(true);
                searchPatient({
                  token: token,
                  text: e.target.searchPatient.value,
                })
                  .then((res) => {
                    const { data } = res;
                    setPatients(data);
                    setLoadingPatient(false);
                  })
                  .catch((error) => {
                    if (error.info) {
                      createAlert('¡Error!', error.info, 'error');
                    } else {
                      createAlert(
                        '¡Error!',
                        'Lo sentimos, no se pudo realizar la búsqueda de los pacientes.',
                        'error'
                      );
                    }
                    setLoadingPatient(false);
                  });
              }}
            >
              <input
                type='text'
                className='form-control'
                id='Search'
                // onChange=""
                // value="1"
                name='searchPatient'
                placeholder='&nbsp;Buscar Dependiente'
                aria-label='&nbsp;Buscar Dependiente'
                aria-describedby='button-addon2'
              />
              <div className='input-group-append'>
                <button
                  className='btn btn-light border border-right-rounded'
                  type='submit'
                  id='button-addon2'
                >
                  {' '}
                  <i className='fas fa-search text-secondarymdv' />
                </button>
              </div>
            </form>
          </div>
          <div className='col-sm-2'>
            <button
              onClick={() => setOpen(true)}
              className='btn btn-primary mt-3'
              type='button'
            >
              <i className='fas fa-user-plus' />
            </button>
          </div>
        </div>
        {loadingPatient ? (
          <Spinner color='primary' />
        ) : (
          <ul className='list-group list-group-flush container-dependent'>
            {patients.length > 0 ? (
              patients.map((user) => (
                <li className='list-group-item'>
                  <img
                    src='https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png'
                    className='rounded-circle mr-2'
                    alt='...'
                    width='35'
                  />
                  {`${user.firstName} ${user.lastName}`}
                  <div className='float-right'>
                    <div className='d-flex'>
                      <select
                        id='relationPatient'
                        className='form-control mr-3'
                        style={{ width: '140px' }}
                      >
                        <option value='son'>Hij@</option>
                        <option value='grandpa'>Abuelo</option>
                        <option value='grandma'>Abuela</option>
                        <option value='mother'>Madre</option>
                        <option value='father'>Padre</option>
                        <option value='uncle'>Tí@</option>
                        <option value='other'>Otro</option>
                      </select>
                      <button
                        type='button'
                        className='btn btn-light btn-sm text-primary font-weight-bold'
                        onClick={() => {
                          const relation =
                            window.document.getElementById(
                              'relationPatient'
                            ).value;
                          if (relation === 'other') {
                            Swal.fire({
                              title: 'Ingrese la relación',
                              input: 'text',
                              inputAttributes: {
                                autocapitalize: 'off',
                              },
                              showCancelButton: true,
                              confirmButtonText: 'Agregar',
                              cancelButtonText: 'Cancelar',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                createDependentPatient({
                                  dependentPatient: user._id,
                                  relation:
                                    window.document.getElementById(
                                      'relationPatient'
                                    ).value,
                                  token: token,
                                  otherName: result.value,
                                })
                                  .then(() => {
                                    createAlert(
                                      '¡Listo!',
                                      'Se creo la relación.',
                                      'success'
                                    );
                                  })
                                  .catch((error) => {
                                    if (error.info) {
                                      createAlert(
                                        '¡Error!',
                                        error.info,
                                        'error'
                                      );
                                    } else {
                                      createAlert(
                                        '¡Error!',
                                        'Lo sentimos no se pudo crear el registro.',
                                        'error'
                                      );
                                    }
                                  });
                              }
                            });
                          } else {
                            createDependentPatient({
                              dependentPatient: user._id,
                              relation:
                                window.document.getElementById(
                                  'relationPatient'
                                ).value,
                              token: token,
                            })
                              .then(() => {
                                createAlert(
                                  '¡Listo!',
                                  'Se creo la relación.',
                                  'success'
                                );
                              })
                              .catch((error) => {
                                if (error.info) {
                                  createAlert('¡Error!', error.info, 'error');
                                } else {
                                  createAlert(
                                    '¡Error!',
                                    'Lo sentimos no se pudo crear el registro.',
                                    'error'
                                  );
                                }
                              });
                          }
                        }}
                      >
                        Añadir
                      </button>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li className='list-group-item' />
            )}
          </ul>
        )}
      </div>
      {/*)*/}
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
  planRef: state.reference.planRef,
});

export default connect(mapStateToProps, {
  addProfileImage,
  openModalProfileImage,
})(DependentPatients);
