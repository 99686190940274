import React from 'react';

function FooterLogin() {
  const date = new Date();
  const yyyy = date.getFullYear();
  return (
    <div className="container-fluid footer-simple pt-5 animate__animated animate__fadeIn">
      <h3 className="text-dark font-weight-bold">
        Importante:
      </h3>
      <h4 className="text-dark text-responsive">
        Si usted tiene una emergencia llame al 911.
      </h4>
      <div className="text-primary">
        Copyright ©
        {' '}
        {yyyy}
        {' '}
        <b>KIELSA CLÍNICA & LAB</b>
        . Todos los Derechos Reservados.
      </div>
    </div>
  );
}

export default FooterLogin;
