/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState} from 'react';
import {connect} from 'react-redux';
import createAlert from '../../redux/actions/alert.action';
import {searchProduct} from '../../api/productKielsa';

function ChatIndications({
  handleSubmitIndication,
  indications,
  handleRemoveIndications,
  token,
}) {
  const [dataProducts, setDataProduct] = useState([]);

  const handleChangeMedicine = (e) => {
    searchProduct({token: token, name: e.target.value}).then((res) => {
      const {data} = res;
      setDataProduct(data);
    });
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const doc = {
            medicine: e.target.name.value,
            dose: e.target.dose.value,
            frequency: e.target.frequency.value,
          };
          if (doc.medicine && doc.dose && doc.frequency) {
            handleSubmitIndication(doc);
            createAlert('¡Correcto!', 'Se agrego la indicación.', 'success');
          } else if (!doc.medicine) {
            createAlert('¡Error!', 'Medicamento no definido.', 'error');
          } else if (!doc.dose) {
            createAlert('¡Error!', 'Dosis no definido.', 'error');
          } else if (!doc.frequency) {
            createAlert('¡Error!', 'Frecuencia no definida.', 'error');
          } else {
            createAlert(
              '¡Error!',
              'Por favor ingrese todos los campos del formulario.',
              'error',
            );
          }
        }}
      >
        <div className="col-md-12">
          <div className="form-group">
            <label>Nombre del Medicamento</label>
            <input
              type="text"
              className="form-control center"
              name="name"
              autoComplete="off"
              list="suggestions"
              onChange={handleChangeMedicine}
            />
            <datalist key="suggestions" id="suggestions">
              {
                dataProducts.map(({Articulo_Nombre, _id}) => (
                  <option key={_id} value={Articulo_Nombre}>
                    {Articulo_Nombre}
                  </option>
                ))
              }
            </datalist>
          </div>
          <div className="form-group">
            <label>Dosis</label>
            <input
              type="text"
              className="form-control center"
              name="dose"
            />
          </div>
          <div className="form-group">
            <label>Frecuencia</label>
            <input
              type="text"
              className="form-control center"
              name="frequency"
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            style={{width: '40%'}}
          >
            AÑADIR +
          </button>
        </div>
      </form>

      <div className="row mar-2">
        {indications.map((doc, i) => (
          <div className="btn-lg-modal-2">
            <i
              style={{cursor: 'pointer'}}
              onClick={() => handleRemoveIndications(i)}
              className="fas fa-times i-close"
            />
            <span>
              {doc.medicine}
              <br />
              <small>{doc.dose}</small>
              <br />
              <small>{doc.frequency}</small>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ChatIndications);
