import { useFormik } from 'formik';
import { useState } from 'react';
import {
  addEmployeeToCompany,
  getEmployeeById,
} from '../../../../api/companies';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useJoinEmployeeState = () => {
  const [user, setUser] = useState(undefined);
  const [dni, setDni] = useState('');
  const [isRegister, setIsRegister] = useState(false);

  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  const handleChange = (e) => {
    if (user) setUser(undefined);
    setDni(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getEmployeeById(dni)
      .then((res) => setUser(res))
      .catch((err) => {
        setUser(undefined);
        setIsRegister(true);
        setTimeout(() => {
          setIsRegister(false);
        }, 3000);
      });
  };

  const handleAddEmployee = () => {
    addEmployeeToCompany(auth.user._id, user._id)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return {
    dni,
    handleChange,
    handleSubmit,
    user,
    isRegister,
    handleAddEmployee,
  };
};

export default useJoinEmployeeState;
