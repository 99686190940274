/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const ModalVitalSigns = ({
  hadleSignsVital,
  temperatura,
  presion,
  frecuencia_resp,
  frecuencia_card,
  saturacion,
  peso,
  estatura,
  IMC,
}) => (
  <div
    className='modal fade'
    id='ModalVitalSigns'
    tabIndex='-1'
    role='dialog'
    aria-labelledby='exampleModalLabel'
    aria-hidden='true'
  >
    <div
      className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
      role='document'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5
            className='modal-title mx-auto text-primary'
            id='exampleModalLabel'
          >
            SIGNOS VITALES
          </h5>
        </div>
        <div className='modal-body'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              hadleSignsVital(e);
            }}
            className='row'
          >
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='float-left'>Temperatura (C°)</label>
                <input
                  type='text'
                  className='form-control'
                  name='temperatura'
                  defaultValue={temperatura}
                  placeholder='C°'
                />
              </div>
              <div className='form-group'>
                <div className='text-left'>
                  <label>Presión Arterial (mmHg)</label>
                </div>
                <div className='row mt-0'>
                  <div className='col-6'>
                    <input
                      type='text'
                      className='form-control'
                      name='presion_initial'
                      defaultValue={presion?.initial}
                      placeholder='mmHg'
                    />
                  </div>
                  <div className='col-6'>
                    <input
                      type='text'
                      className='form-control'
                      name='presion_final'
                      defaultValue={presion?.final}
                      placeholder='mmHg'
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='float-left'>
                  Frecuencia Respiratoria (rpm)
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='frecuencia_resp'
                  defaultValue={frecuencia_resp}
                  placeholder='rpm'
                />
              </div>
              <div className='form-group'>
                <label className='float-left'>Frecuencia Cardíaca (ppm)</label>
                <input
                  type='text'
                  className='form-control'
                  name='frecuencia_card'
                  defaultValue={frecuencia_card}
                  placeholder='ppm'
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='float-left'>Saturación de Oxígeno (%)</label>
                <input
                  type='text'
                  className='form-control'
                  name='saturacion'
                  defaultValue={saturacion}
                  placeholder='%'
                />
              </div>
              <div className='form-group'>
                <label className='float-left'>Peso (Kg)</label>
                <input
                  type='text'
                  className='form-control'
                  name='peso'
                  defaultValue={peso}
                  placeholder='Kg'
                />
              </div>
              <div className='form-group'>
                <label className='float-left'>Estatura (Mts)</label>
                <input
                  type='text'
                  className='form-control'
                  name='estatura'
                  defaultValue={estatura}
                  placeholder='Mts'
                />
              </div>
              <div className='form-group'>
                <label className='float-left'>IMC (IMC)</label>
                <input
                  type='text'
                  className='form-control'
                  name='IMC'
                  defaultValue={IMC}
                  placeholder='IMC'
                />
              </div>
            </div>
            <div className='col-12'>
              <button
                type='submit'
                className='btn btn-primary m-auto col-12 col-md-7'
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

export default ModalVitalSigns;
