/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Button } from 'react-bootstrap';
import exam from '../../utils/physicalExam';
import { closeModalPhysicalExam } from '../../redux/actions/ui.action';
import { addPhysicalExam } from '../../redux/actions/physicalExam.action';

const ModalPhysicalExam = ({
  open,
  closeModalPhysicalExam,
  addPhysicalExam,
  videoconference,
}) => {
  const [formData, setFormData] = useState({
    idAppointment: videoconference?._id,
    type: 'videoconference',
    neurological: undefined,
    heart: undefined,
    head: undefined,
    eyes: undefined,
    neck: undefined,
    orl: undefined,
    lugs: undefined,
    abdomen: undefined,
    genitals: undefined,
    extremities: undefined,
    skin: undefined,
    others: undefined,
  });
  const [exams, setExams] = useState(exam);

  const handleChangeNormal = (index) => {
    const tempArray = [...exams];
    tempArray[index].normal = !tempArray[index].normal;
    if (tempArray[index].normal === true) {
      tempArray[index].description = null;
    }
    setExams(tempArray);
  };
  const handleChangeDescription = (e, index) => {
    const tempArray = [...exams];
    tempArray[index].description = e.target.value;
    setExams(tempArray);
  };

  const handleCloseModal = () => {
    closeModalPhysicalExam();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    exams.forEach((examen) => {
      if (examen.description !== null) {
        setFormData({ ...formData, [examen.name]: examen.description });
      }
    });

    addPhysicalExam(formData);
    closeModalPhysicalExam();
  };

  return (
    <Modal show={open} onHide={handleCloseModal} fade>
      <Modal.Header closeButton style={{ borderBottom: 'none' }}>
        <Modal.Title className='modal-title text-primary mx-auto'>
          EXAMEN FÍSICO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table style={{ marginBottom: 0, borderBottom: 0, borderTop: 'none' }}>
          <thead>
            <tr style={{ backgroundColor: 'transparent', border: 'none' }}>
              <td />
              <td colSpan='2' style={{ textAlign: 'center', fontWeight: 500 }}>
                NORMAL
              </td>
            </tr>
            <tr className='bg-secondarymdv'>
              <th>Órgano/Aparato/Sistema</th>
              <th style={{ textAlign: 'center' }}>SI</th>
              <th style={{ textAlign: 'center' }}>NO</th>
            </tr>
          </thead>
          <tbody>
            {exams.map((e, index) => (
              <>
                <tr key={e.id}>
                  <td>{e.label}</td>
                  <td>
                    <input
                      type='radio'
                      checked={e.normal}
                      onChange={() => handleChangeNormal(index)}
                    />
                  </td>
                  <td>
                    <input
                      type='radio'
                      checked={!e.normal}
                      onChange={() => handleChangeNormal(index)}
                    />
                  </td>
                </tr>
                {!e.normal && (
                  <tr>
                    <td colSpan='3'>
                      <div id={e.name}>
                        <div className='form-group'>
                          <label className='float-left'>Describa</label>
                          <textarea
                            className='form-control'
                            id='exampleFormControlTextarea1'
                            rows='1'
                            value={e.description}
                            name='description'
                            onChange={(e) => handleChangeDescription(e, index)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  open: state.ui.modalPhysicalExam,
  videoconference: state.videoconference.videoconference,
});
export default connect(mapStateToProps, {
  closeModalPhysicalExam,
  addPhysicalExam,
})(ModalPhysicalExam);
