import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineCloudDownload, RiDeleteBin7Line } from 'react-icons/all';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  getLabFileShowOlder,
  getLabFileShow,
  getLabFilesById,
  addLabFileById,
  updateFileName,
  deleteFile,
  deleteFileOlder,
  addLabFile,
} from '../../api/lab.file';
import { getReference, deleteReference } from '../../redux/actions/references';
import createAlert from '../../redux/actions/alert.action';

const LabImageShow = ({ token, labOrder, type }) => {
  const [file, setFile] = useState(null);
  const { filename } = labOrder;

  useEffect(() => {
    if (labOrder.title) {
      getLabFileShowOlder({ token, filename })
        .then((res) => {
          setFile(res);
        })
        .catch((e) => {
          setFile(null);
        });
    } else {
      getLabFileShow({ token, filename })
        .then((res) => {
          setFile(res);
        })
        .catch((e) => {
          setFile(null);
        });
    }
  }, [filename]);
  const download = () => {
    const splitted = labOrder.filename.split('.');
    if (splitted[splitted.length - 1] === 'pdf') {
      const blob = new Blob([file], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'png') {
      const blob = new Blob([file], {
        type: 'image/png',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'jpeg') {
      const blob = new Blob([file], {
        type: 'image/jpeg',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'jpg') {
      const blob = new Blob([file], {
        type: 'image/jpg',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };

  if (type === 'button') {
    return (
      <a
        href="#"
        onClick={download}
        style={{
          textDecoration: 'none',
          color: '#fff',
        }}
      >
        <AiOutlineCloudDownload />
      </a>
    );
  } else {
    return (
      <a href="#" onClick={download}>
        {labOrder.originalName ? labOrder.originalName : labOrder.title}
      </a>
    );
  }
};

const ModalLabHistory = ({ show, handleClose, token }) => {
  const dispatch = useDispatch();
  const [labOrders, setLabOrders] = useState([]);
  const { userRef } = useSelector((state) => state.reference);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [name, setName] = useState('');
  const { videoconference } = useSelector((state) => state.videoconference);

  useEffect(() => {
    dispatch(getReference(videoconference?.patient));
    return () => {
      dispatch(deleteReference());
    };
  }, [dispatch, videoconference]);

  const handleDeleteFile = async (file) => {
    Swal.fire({
      text: '¿Desea eliminar este archivo?',
      confirmButtonText: 'Si',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then(async (res) => {
      setUploading(true);
      if (res.isConfirmed) {
        if (file.title) {
          await deleteFileOlder(file);
        } else {
          await deleteFile(file);
        }
      }
      setUploading(false);
    });
  };

  useEffect(() => {
    if (userRef) {
      getLabFilesById({
        token,
        id: userRef._id,
      })
        .then((res) => {
          const tempOrders = [];
          res.older.forEach((item) => {
            tempOrders.push(item);
          });
          res.lab.forEach((item) => {
            tempOrders.push(item);
          });

          setLabOrders(tempOrders);
        })
        .catch((err) => {
          setLabOrders([]);
          console.log({
            status: 'como estas',
            res: err,
          });
        });
    }
  }, [uploading, userRef]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            color: '#011688',
          }}
        >
          Exámenes de Laboratorio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No.</th>
              <th>Nombre archivo</th>
              <th>Fecha de creación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {labOrders.map((file, index) => (
              <tr key={file._id}>
                <td>{index + 1}</td>
                <td>
                  <LabImageShow token={token} labOrder={file} />
                </td>
                <td>{moment(file.createAt).format('LL')}</td>
                <td
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    style={{
                      width: 'auto',
                      margin: 2,
                    }}
                  >
                    <LabImageShow token={token} labOrder={file} type="button" />
                  </Button>
                  <Button
                    style={{
                      width: 'auto',
                      backgroundColor: 'red',
                      margin: 2,
                    }}
                    onClick={() => handleDeleteFile(file)}
                  >
                    <RiDeleteBin7Line />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLabHistory;
