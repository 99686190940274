import React from 'react';
import UserProfile from '../../../Components/Patient/UserPerfil';
import UserProfileMovil from '../../../Components/Patient/UserPerfilMovil';

const Profile = () => (
  <>
    {/* Web View */}
    <div className="d-none d-sm-block">
      <UserProfile />
    </div>
    {/* Fin Web View */}
    {/* Mobile View */}
    <div className="d-block d-sm-none">
      <UserProfileMovil />
    </div>
    {/* Fin Mobile View */}
  </>
);

export default Profile;
