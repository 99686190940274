import { Modal } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const MedicalAppointmentResumeModal = ({
  isOpen,
  handleHide,
  reason,
  diagnosis,
  hea,
  indications,
  role,
}) => {
  const handleClose = () => {
    if (role === 'doctor') {
      handleHide();
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/dashboard/rate';
      handleHide();
    }
  };
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='align-items-center'>
        <h3
          className='text-primary'
          style={{
            textAlign: 'center',
            flex: 1,
          }}
        >
          resumen de consulta
        </h3>
      </Modal.Header>
      <Modal.Body style={{ padding: 40 }}>
        <div>
          <h5>Motivo:</h5>
          <p>{reason}</p>
        </div>
        <div>
          <h5>HEA:</h5>
          <p>{hea}</p>
        </div>
        <div>
          <h5>Diagnóstico:</h5>
          <p>{diagnosis}</p>
        </div>
        <div>
          <h5>Indicaciones:</h5>

          {indications.map((indication, index) => (
            <div
              key={indication?.medicine}
              style={{ display: 'flex', flexDirection: 'row', gap: 2 }}
            >
              <p>{indication?.medicine}</p>/<p>{indication?.dose}</p>/
              <p>{indication?.frequency}</p>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

MedicalAppointmentResumeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleHide: PropTypes.func.isRequired,
  hea: PropTypes.object.isRequired,
  reason: PropTypes.object.isRequired,
  diagnosis: PropTypes.object.isRequired,
  indications: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default MedicalAppointmentResumeModal;
