/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MdCancel } from 'react-icons/all';
import {
  addMedicine,
  deleteSelectedItem,
  updateMedicine,
} from '../../../redux/actions/patient.history.action';
import './history.css';

function AddMedicine({
  addMedicine,
  selectedItem,
  deleteSelectedItem,
  updateMedicine,
  patientID,
  userRef,
}) {
  const [formData, setFormData] = useState({
    name: '',
    dose: '',
    frequency: '',
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: selectedItem === null ? '' : selectedItem.name,
      dose: selectedItem === null ? '' : selectedItem.dose,
      frequency: selectedItem === null ? '' : selectedItem.frequency,
    });
  }, [selectedItem, formData]);

  const { name, dose, frequency } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (userRef) {
      addMedicine(formData, userRef._id);
    } else {
      addMedicine(formData, patientID);
    }

    setFormData({
      ...formData,
      name: '',
      dose: '',
      frequency: '',
    });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    updateMedicine(selectedItem._id, formData);
    deleteSelectedItem();
  };

  return (
    <div className='sales mt-3'>
      <div className='media'>
        <div className='media-body'>
          <h5 className='font-blue mt-3 text-center'>
            Agregar prescripción médica
          </h5>
          <form className='p-4'>
            <div className='form-group'>
              <label>Nombre del Medicamento</label>
              <input
                type='text'
                className='form-control m-auto col-12 col-md-7'
                name='name'
                value={name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='form-group'>
              <label>Dosis (Opcional)</label>
              <input
                type='text'
                className='form-control m-auto col-12 col-md-7'
                name='dose'
                value={dose}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='form-group'>
              <label>Frecuencia</label>
              <input
                type='text'
                className='form-control m-auto col-12 col-md-7'
                name='frequency'
                value={frequency}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {selectedItem !== null ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  type='button'
                  className='btn btn-primary update-button-medicine'
                  onClick={handleUpdate}
                >
                  ACTUALIZAR
                </button>
                <button
                  type='button'
                  className='button-medicines'
                  onClick={() => deleteSelectedItem()}
                >
                  <MdCancel className='cancel-update-buttons' />
                </button>
              </div>
            ) : (
              <div className='form-group mt-3'>
                <button
                  type='button'
                  className='btn btn-primary btn-block m-auto col-12 col-md-7'
                  onClick={handleSubmit}
                >
                  AGREGAR
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  medicines: state.history.medicines.items,
  selectedItem: state.history.medicines.selectedItem,
  patientID: state.profile.searchPatientID,
  userRef: state.reference.userRef,
});

export default connect(mapStateToProps, {
  addMedicine,
  deleteSelectedItem,
  updateMedicine,
})(AddMedicine);
