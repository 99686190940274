import socket from './socket';

export const onLineDoctor = ({ doctorId, role }, callback) => {
  socket.emit('joinDoctor', { doctorId, role }, callback);
};

export const changeStatusDoctor = ({ doctorId, role, status }, callback) => {
  socket.emit('changeStatusDoctor', {
    doctorId, role, status,
  }, callback);
};

export const disconnectDoctor = ({ doctorId, role }, callback) => {
  socket.emit('disconnetDoctor', { doctorId, role }, callback);
};

export const observerNotificationCall = ({ doctorId }, callback) => {
  socket.on(`${doctorId}/notificationCall`, callback);
};
