import {
  OPEN_MODAL_CELLPHONE_VERIFICATION,
  CLOSE_MODAL_CELLPHONE_VERIFICATION,
} from '../types';

const initialState = {
  open: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case OPEN_MODAL_CELLPHONE_VERIFICATION:
    case CLOSE_MODAL_CELLPHONE_VERIFICATION:
      return {
        ...state,
        open: payload,
      };

    default:
      return state;
  }
};
