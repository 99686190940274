import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { addProfileImage } from '../../../redux/actions/profile.action';
import imgAvatarTemp from '../../../assets/img/avatars/avatar-doc-male.png';
import imgAvatarFemale from '../../../assets/img/avatars/avatar-pat-female.png';

import '../../../stylesheets/profile.css';
import CardUpcomingScheduling from '../../Card/CardUpcomingScheduling';
import { upcomingScheduling } from '../../../api/scheduling';
import { getListAvailableDoctor } from '../../../api/doctor';
import { observerDoctorOnline } from '../../../websokect/patientWebSocket';
import { openModalProfileImage } from '../../../redux/actions/ui.action';
import ModalProfileImagen from '../../Modal/AddProfileImageModal';
import { isDependentPatient } from '../../../api/dependentPatient';
import { showAdsFile } from '../../../api/ads';
import AddDepedentModal from '../AddDependentModal';
import { validUserPatient, validGroupPackageUser } from '../../../api/user';
import ModalDescriptionProduct from '../../Modal/ModalDescriptionProduct';
import Badge from 'react-bootstrap/Badge';
import { getLocalCurrency } from '../../../utils/currency';

const BasicInfo = ({
  user: {
    firstName,
    lastName,
    email,
    gender,
    image,
    role,
    idCompany,
    position,
  },
  profile: { cellphone, dni, bornAt, balance },
  openModalProfileImage,
  token,
  planRef,
}) => {
  const [upcommingSchedule, setUpcomingScheduling] = useState([]);
  const [validPatient, setValidPatient] = useState(true);
  const [dataAds, setDataAds] = useState([]);
  const [genderImage, setGenderImage] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [isDependent, setIsDependent] = useState(false);
  const [validGroup, setValidGroup] = useState(false);
  const [open, setOpen] = useState(false);
  const genero = gender === 'male' ? 'Masculino' : 'Femenino';
  const nombre = `${firstName.split(' ')[0]} ${lastName.split(' ')[0]}`;
  const tel = cellphone === undefined ? '' : `Cel. ${cellphone}`;
  const identidad = dni === undefined ? '' : dni;

  useEffect(() => {
    validGroupPackageUser({ token }).then(() => {
      setValidGroup(true);
    });
    // Valid gender image profile
    if (gender === 'male') {
      setGenderImage(imgAvatarTemp);
    } else {
      setGenderImage(imgAvatarFemale);
    }
    /* trayendo las proximas consultas del paciente */
    upcomingScheduling({ token })
      .then((res) => {
        const { data } = res;
        setUpcomingScheduling(data);
      })
      .catch((error) => {
        console.log(error);
      });
    getListAvailableDoctor({ token })
      .then((res) => {
        const { doctors } = res;
        setDoctorList(doctors);
      })
      .catch((error) => {
        console.log(error);
      });
    observerDoctorOnline(() => {
      getListAvailableDoctor({ token })
        .then((res) => {
          const { doctors } = res;
          setDoctorList(doctors);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    showAdsFile({ token }).then((res) => {
      const { data } = res;
      setDataAds(data);
    });
    isDependentPatient({ token }).then(() => {
      setIsDependent(true);
    });
  }, []);

  const handleuploadImage = () => {
    openModalProfileImage();
  };

  const dateBorn = new Date(bornAt);
  return (
    <>
      <ModalDescriptionProduct />
      <ModalProfileImagen />
      <AddDepedentModal show={open} onClose={() => setOpen(!open)} />
      {/* informacion del paciente */}
      <div className='sales h-100 text-center'>
        <div className='row'>
          <div className='col-lg-12 col-sm-12 col-12 profile-header' />
        </div>
        <div className='row user-detail'>
          <div className='col-lg-12 col-sm-12 col-12'>
            {/* imagen de perfil */}
            <OverlayTrigger
              key='bottom'
              placement='bottom'
              overlay={<Tooltip id='tooltip-bottom'>Cambiar foto</Tooltip>}
            >
              <button
                type='button'
                className='avatar-button-container'
                onClick={handleuploadImage}
              >
                <div
                  style={{
                    backgroundImage: `${
                      image === null || image === '' || image === undefined
                        ? `url(${genderImage})`
                        : `url(/api/profile/image/${image})`
                    }`,
                    backgroundColor: 'white',
                  }}
                  className='image-container shadow-sm'
                />
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <h5 className='text-primary mb-2 mt-2'>{nombre}</h5>
        {isDependent && (
          <h5>
            <span className='badge bg-light rounded-pill text-secondarymdv mb-2'>
              Dependiente
            </span>
          </h5>
        )}
        <div className='text-center mb-2'>
          <div className='d-inline mr-1'>
            {email ? (
              <span
                className='badge text-secondary bg-light rounded-pill'
                style={{ fontSize: '14px' }}
              >
                <i className='fas fa-envelope-open-text mr-1 text-secondarymdv' />
                {email}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='d-inline mr-1'>
            {bornAt ? (
              <span
                className='badge text-secondary bg-light rounded-pill'
                style={{ fontSize: '14px' }}
              >
                <i className='fas fa-calendar-check mr-1 text-secondarymdv' />
                {`${dateBorn.getDate() + 1}/${
                  dateBorn.getMonth() + 1
                }/${dateBorn.getFullYear()}`}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='d-inline mr-1'>
            {genero ? (
              <span
                className='badge text-secondary bg-light rounded-pill'
                style={{ fontSize: '14px' }}
              >
                <i className='fas fa-venus-mars mr-1 text-secondarymdv' />
                {genero}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='d-inline mr-1'>
            {tel ? (
              <span
                className='badge text-secondary bg-light rounded-pill'
                style={{ fontSize: '14px' }}
              >
                <i className='fas fa-phone-alt mr-1 text-secondarymdv' />
                {tel}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='d-inline mr-1'>
            {identidad ? (
              <span
                className='badge text-secondary bg-light rounded-pill'
                style={{ fontSize: '14px' }}
              >
                <i className='far fa-id-badge mr-1 text-secondarymdv' />
                {identidad}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        {planRef && (
          <div className='mt-2 mb-3'>
            <Link
              className='btn btn-info'
              to='/dashboard/details'
              style={{
                borderRadius: '0.8rem',
              }}
            >
              <i className='fas fa-eye mr-1' />
              Historial de Consultas
            </Link>
          </div>
        )}
        <div>
          <Badge
            className='bg-primary p-1 font-color-red'
            style={{
              color: 'white',
              padding: '10px 0px',
              fontSize: 14,
            }}
          >{`Tu saldo es: ${getLocalCurrency(balance) ?? 0}`}</Badge>
        </div>
        <div className='hr' />

        {idCompany && planRef && (
          <span className='label-enterprise-patient'>
            Plan Empresarial activo
            <span className='label-enterprise-patient-2'>{position}</span>
          </span>
        )}

        {planRef && (
          <>
            <h4 className='text-center text-primary font-weight-light mt-4 mb-2'>
              Próxima Cita
            </h4>
            {upcommingSchedule.length > 0 ? (
              upcommingSchedule.map(({ doctor, day, month, year, hour }) => (
                <>
                  <CardUpcomingScheduling
                    image={
                      doctor.image
                        ? `/api/profile/image/${doctor.image}`
                        : undefined
                    }
                    lastName={doctor.lastName}
                    date={`${moment(new Date(year, month - 1, day)).format(
                      'LL'
                    )}, ${hour}`}
                  />
                </>
              ))
            ) : (
              <p>No hay próximas citas..</p>
            )}
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
  planRef: state.reference.planRef,
});

export default connect(mapStateToProps, {
  addProfileImage,
  openModalProfileImage,
})(BasicInfo);
