import {useState} from 'react';

export const useListUpcomingScheduling = () => {
    const [listUpcomingScheduling, setListUpcomingScheduling] = useState([]);

      return {
        listUpcomingScheduling, 
        setListUpcomingScheduling      
    }
}
