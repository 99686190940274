import axios from 'axios';

export const url = '/api/ads';

export const showAdsFile = ({token}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/all`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
