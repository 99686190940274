import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useReferences } from '../../../hooks/useReferences';
import { useUI } from '../../../hooks/useUI';
import moment from 'moment';
import { useCarnetModalState } from './state/useCarnetModalState';

const CarnetModal = () => {
  const { modalCarnet } = useUI();
  const { userRef, planRef, dependentsRef } = useReferences();
  const {
    carnet,
    carnetBack,
    handleGetRelation,
    handlePrintCarnet,
    handlePrintCarnetBack,
    handleClose,
  } = useCarnetModalState();

  return (
    <Modal
      show={modalCarnet}
      onHide={handleClose}
      id='ModalCarnet'
      tabIndex='-1'
      role='dialog'
      centered
      size={!planRef ? 'sm' : ''}
    >
      <Modal.Body>
        {!planRef ? (
          <div className='align-items-center justify-content-center p-3 text-center'>
            <h5 className='text-dark mb-3 mt-3 font-weight-bold'>
              ¡Lo sentimos!
            </h5>
            <img
              src={require('../../../assets/img/vectores/noplan.svg')}
              alt='no plan icon'
              width={90}
              loading='lazy'
            />
            <p className='text-gray mb-3 mt-3'>El usuario no posee plan.</p>
            <Button
              onClick={handleClose}
              className='col-12 col-lg-6 col-sm-6 col-md-6'
            >
              Aceptar
            </Button>
          </div>
        ) : (
          <>
            <div className='container d-none d-sm-none d-md-none d-lg-block'>
              <button
                type='button'
                className='btn btn-primary font-weight-bold mb-3 btn-sm'
                onClick={handlePrintCarnet}
              >
                <i className='fa fa-print mr-2' />
                Imprimir carnet frontal
              </button>

              {/* primera parte carnet */}
              <div className='position-relative ' ref={carnet}>
                <img
                  src={require('../../../assets/img/carnet/carnet-02.png')}
                  className='img-fluid image-card'
                  alt='carnet 02 farmacias kielsa'
                  loading='lazy'
                />
                <div className='datos-carnet-patient-modal'>
                  <div className='background-title-plan'>
                    <h4 className='text-primary text-uppercase'>
                      Plan <b>{`${planRef.name}`}</b>
                    </h4>
                  </div>
                  <h6 className='text-primary'>
                    <b>{`Nombre: ${userRef?.firstName} ${userRef?.lastName}`}</b>
                  </h6>
                  <h6 className='text-primary'>
                    <b>{`Número ID: ${userRef?.dni}`}</b>
                  </h6>
                  <h6 className='text-primary'>
                    <b>{`Alta: ${moment(planRef?.plan?.createdAt).format(
                      'DD/MM/YYYY'
                    )}`}</b>
                  </h6>
                  <h6 className='text-primary'>
                    <b>
                      {`Vigencia: ${moment(planRef?.plan?.dueDate).format(
                        'DD/MM/YYYY'
                      )}`}
                    </b>
                  </h6>
                </div>
              </div>
              <br />
              <button
                type='button'
                className='btn btn-primary font-weight-bold mb-3 btn-sm'
                onClick={handlePrintCarnetBack}
              >
                <i className='fa fa-print mr-2' />
                Imprimir reverso carnet
              </button>
              {/* segunda parte carnet */}
              <div className='position-relative mt-1' ref={carnetBack}>
                <img
                  src={require('../../../assets/img/carnet/carnet-03.png')}
                  className='img-fluid  image-card'
                  alt='carnet 03 farmacias kielsa'
                  loading='edge'
                />
                <div className='datos-carnet-02-patient-modal'>
                  <div className='background-benefits'>
                    <h6 className='text-primary font-weight-bold'>
                      Beneficios:
                    </h6>
                  </div>
                  <ul className='text-black pl-4 text-bold'>
                    {planRef?.products?.map((item) => (
                      <li className='carnet-modal-item-dependent' key={item}>
                        {item}
                      </li>
                    ))}
                  </ul>
                  {dependentsRef?.length > 0 && (
                    <div className='background-dependents'>
                      <h6 className='text-primary font-weight-bold'>
                        Dependientes:
                      </h6>
                    </div>
                  )}

                  {dependentsRef?.map((item) => (
                    <div
                      className='d-flex justify-content-between align-items-center text-uppercase h6 text-primary text-dependent-modal'
                      key={item?._id}
                    >
                      {`${item?.dependentPatient?.firstName?.split(' ')[0]} ${
                        item?.dependentPatient?.lastName?.split(' ')[0]
                      }`}
                      <span style={{ width: 200 }}>
                        {handleGetRelation(item?.relation)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='text-center mt-5 d-block d-sm-block d-md-block d-lg-none'>
              <h4 className='text-dark'>
                Carnet disponible para impresión solo desde una PC o laptop.
              </h4>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CarnetModal;
