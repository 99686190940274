/* eslint-disable arrow-parens */
/* eslint-disable comma-dangle */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { registerDoctor } from "../redux/actions/auth.action";
import createAlert from "../redux/actions/alert.action";
import { countries } from "../utils/countries";

const DoctorForm = ({ registerDoctor }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    email: "",
    role: "doctor",
    dni: "",
    bornAt: "",
    cellphone: "",
    country: countries[0].name,
    gender: "Femenino",
    dialCode: countries[0].dial_code,
  });
  const [terms, setTerms] = useState(false);
  const {
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    dni,
    bornAt,
    cellphone,
    country,
    gender,
    dialCode,
  } = formData;

  const handleChange = (e) => {
    if (e.target.name === "country") {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName !== "" &&
      lastName !== "" &&
      password !== "" &&
      passwordConfirm !== "" &&
      email !== "" &&
      dni !== "" &&
      bornAt !== "" &&
      cellphone !== "" &&
      country !== "" &&
      gender !== ""
    ) {
      if (password === passwordConfirm) {
        if (terms) {
          registerDoctor(formData);
        } else {
          createAlert(
            "Error",
            "Debe de aceptar los terminos y condiciones",
            "error"
          );
        }
      } else {
        createAlert("Error", "Las contraseñas no coinciden", "error");
      }
    } else {
      createAlert("Error", "Rellene todos los campos", "error");
    }
  };

  return (
    <div>
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-10 ">
                  <div className="main-div2">
                    <img
                      src={require("../assets/img/loginlogo.png")}
                      alt="loginlogo"
                      className="img-fluid loginlogo2"
                      data-aos="fade-right"
                    />
                    <div className="panel">
                      <h6 className="center mg-5">Registro Doctor</h6>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <form id="Login2">
                          <div className="form-group">
                            <input
                              className="form-control center"
                              placeholder="Nombre"
                              name="firstName"
                              value={firstName}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              className="form-control center"
                              placeholder="Apellido"
                              name="lastName"
                              value={lastName}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control center"
                              placeholder="Fecha de Nacimiento"
                              value={bornAt}
                              onChange={(e) => handleChange(e)}
                              name="bornAt"
                            />
                          </div>
                          <div>
                            <Form.Control
                              as="select"
                              style={{ marginBottom: 15 }}
                              onChange={(e) => handleChange(e)}
                              name="country"
                              value={country}
                            >
                              {countries.map((country, key) => (
                                <option key={country.code}>
                                  {country.name}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <div className="form-group">
                            <InputGroup>
                              <InputGroup.Prepend
                                value={dialCode}
                                name="dialCode"
                                onChange={(e) => handleChange(e)}
                              >
                                <InputGroup.Text>{dialCode}</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Telefono"
                                name="cellphone"
                                value={cellphone}
                                onChange={(e) => handleChange(e)}
                              />
                            </InputGroup>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <form id="login1">
                          <div className="form-group">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              name="gender"
                              value={gender}
                              onChange={(e) => handleChange(e)}
                              placeholde="Género"
                            >
                              <option>Femenino</option>
                              <option>Masculino</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              className="form-control center"
                              id="inputEmail"
                              placeholder="Número de Identificación Personal"
                              name="dni"
                              value={dni}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control center"
                              id="inputEmail"
                              placeholder="Correo Electronico"
                              value={email}
                              name="email"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control center"
                              id="inputPassword1"
                              placeholder="Contraseña"
                              name="password"
                              value={password}
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="font12 marl-3">
                              - Debe contener al menos 1 digito, 1 minúscula y 1
                              mayúscula
                            </span>
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control center"
                              id="inputPassword2"
                              placeholder="Confirmar contraseña"
                              value={passwordConfirm}
                              name="passwordConfirm"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                          <br />
                        </form>
                      </div>
                      <div className="form-check form-check-inline marl-3 color-mdv">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          onChange={() => {
                            setTerms(!terms);
                          }}
                          checked={terms}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          He leído y aceptado los Terminos y Condiciones
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary2"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        REGISTRAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect(null, { registerDoctor })(DoctorForm);
