import React from 'react';
import EnterpriseInvoice from '../../Components/Enterprise/EnterpriseInvoice';

const EnterpriseInvoicePage = () => (

  <EnterpriseInvoice />

);

export default EnterpriseInvoicePage;
