/* eslint-disable no-shadow */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {getPrices} from '../../../redux/actions/price.action';
import CheckPayment from '../../../Components/Patient/CheckPayment';
import MedicalPlanDetails from "../../../Components/Patient/MedicalPlandetails"

const Check = ({getPrices, history,planRef}) => {
  useEffect(() => {
    getPrices();
  }, []);
  return planRef?.packageKtm ? <MedicalPlanDetails/>:<CheckPayment history={history} />
     
} 
const mapStateToProps = (state) => ({
  planRef: state.reference.planRef,
});
export default connect(mapStateToProps, {getPrices})(Check);
