/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import {
  GET_PRICES_CONSULTING,
  DEFAULT_PRICE_SELECTED,
  DELETE_DEFAULT_PRICE_SELECTED,
} from '../types';

export const getPrices = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/prices');
    dispatch({
      type: GET_PRICES_CONSULTING,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSelectedPrice = (doc) => (dispatch) => {
  dispatch({
    type: DEFAULT_PRICE_SELECTED,
    payload: doc,
  });
};
export const deleteSelectedPrice = () => (dispatch) => {
  dispatch({
    type: DELETE_DEFAULT_PRICE_SELECTED,
    payload: null,
  });
};
