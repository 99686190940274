import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import {
  getGraphicGender,
  getGraphicAge,
} from '../../redux/actions/graphic.action';

function PatientDetails({ gender, age, getGraphicAge, getGraphicGender }) {
  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });
  // grafica de genero
  let m = 0;
  let f = 0;
  gender.data.forEach((element) => {
    m = element.male;
    f = element.female;
  });

  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getGraphicAge(desde, hasta);
    getGraphicGender(desde, hasta);
  };

  const datagenero = {
    labels: ['Masculino', 'Femenino'],
    datasets: [
      {
        label: 'Genero',
        data: [m, f],
        backgroundColor: ['rgb(91, 44, 111)', 'rgb(24, 106, 59)'],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  // grafica titular
  const datatitular = {
    labels: ['Titulares', 'Dependientes'],
    datasets: [
      {
        label: 'Tipo paciente',
        data: [60, 20],
        backgroundColor: ['rgb(91, 44, 111)', 'rgb(24, 106, 59)'],
        borderWidth: 1,
      },
    ],
  };
  // grafica edades
  let rango1 = 0;
  let rango2 = 0;
  let rango3 = 0;
  let rango4 = 0;
  let rango5 = 0;

  age.data.forEach((element) => {
    if (element._id === 'menor 18') {
      rango1 = element.count;
    }
    if (element._id === '18-30') {
      rango2 = element.count;
    }
    if (element._id === '31-45') {
      rango3 = element.count;
    }
    if (element._id === '46-59') {
      rango4 = element.count;
    }
    if (element._id === 'mayor 60') {
      rango5 = element.count;
    }
  });
  const dataedad = {
    labels: ['<17', '18-30', '31-45', '46-59', '60>'],
    datasets: [
      {
        label: 'Edad',
        data: [rango1, rango2, rango3, rango4, rango5],
        backgroundColor: [
          'rgb(91, 44, 111)',
          'rgb(24, 106, 59)',
          'rgb(21, 67, 96)',
          'rgb(211, 37, 0)',
          'rgb(142, 131, 128)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const datallamada = {
    labels: ['Masculino', 'Femenino'],
    datasets: [
      {
        label: 'Llamadas por Genero',
        data: [70, 120],
        fill: false,
        backgroundColor: 'rgb(5, 17, 17)',
        borderColor: 'rgb(146, 43, 33)',
      },
    ],
  };
  return (
    <div className='container-fluid'>
      <div className='user-dashboard'>
        <div className='row mt-4'>
          <div className='col-md-6 col-sm-12 col-xs-12 mb-2'>
            <div className='sales'>
              <div className='row mb-4 mt-2'>
                <div className='col-12 col-md-5 mb-2'>
                  <div className='d-flex'>
                    <label className='text-black-50'>Desde: </label>
                    <input
                      type='date'
                      name='desde'
                      value={desde}
                      className='form-control'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-5 mb-2'>
                  <div className='d-flex'>
                    <label className='text-black-50'>Hasta: </label>
                    <input
                      type='date'
                      name='hasta'
                      value={hasta}
                      className='form-control'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <div className='d-flex'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      onClick={handleSubmit}
                      style={{ height: '33px' }}
                    >
                      Ver
                    </button>
                  </div>
                </div>
              </div>

              <div className='container'>
                {/* <span className="center font-blue">Género</span> */}
                <Pie data={datagenero} />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12 mb-2'>
            <div className='sales'>
              <div className='container'>
                {/* <span className="center font-blue">
                      Titular, Dependiente
                    </span> */}
                <Pie data={datatitular} />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12 mb-2'>
            <div className='sales'>
              <div className='container'>
                {/* <span className="center font-blue">Rango de Edad</span> */}
                <Bar data={dataedad} options={options} />
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12 mb-2'>
            <div className='sales'>
              <div className='container'>
                {/* <span className="center font-blue">Llamada por género</span> */}
                <Line data={datallamada} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToprops = (state) => ({
  gender: state.graphic.graphicGender,
  age: state.graphic.graphicAge,
});

export default connect(mapStateToprops, {
  getGraphicAge,
  getGraphicGender,
})(PatientDetails);
