import React,{useEffect} from 'react'
import { connect } from 'react-redux';
import ModalProfileImage from '../../../Components/Modal/AddProfileImageModal';
import CardDetails from './CardDetails';
import NextAppoints from './NextAppoints';
import MyAppoints from './MyAppoints';
import Spinner from '../../Spinner/Spinner';
import MyFigures from './MyFigures';

const HomeDoctor = ({auth}) =>{

    return (
        <div>
            <ModalProfileImage />
            <div className="container-fluid display-table">
                <div className="col-md-12 col-sm-12 display-table-cell v-align">
                    <div className="user-dashboard">
                        <h2 className="text-primary mb-4 mt-2">
                            {`Bienvenid${auth.user.gender === 'male' ? 'o Dr.' : 'a Dra.'}`}{' '}
                            <b className="text-secondarymdv">{`${auth.user.firstName.split(' ',1)} ${auth.user.lastName.split(' ', 1)}`}</b>
                        </h2>
                        <br/>
                        <div className="row">
                            <div className="col-md-7 col-sm-12 col-xs-12 gutter">
                                <CardDetails/>
                                <NextAppoints/>
                            </div>
                            <div className="col-md-5 col-sm-12 col-xs-12 gutter">
                                <MyAppoints/>
                                <MyFigures/>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        </div>   
    )

}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(HomeDoctor);