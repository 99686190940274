/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
import axios from 'axios';
import Swal from 'sweetalert2';
import createAlert from './alert.action';
import setAuthToken from '../../utils/auth.token.config';
import { getProfile } from './profile.action';
import { createConnection } from '../../api/connections';
import { getCurrentConnection } from '../../extra/arrayConnection';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import { closeAllConnections } from '../../websokect/notificationsWebsocket';
import {
  getPlanReference,
  changeAddUserFlag,
  deleteReference,
  getAllDependents,
  getPlanReferenceById,
} from './references';
import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE,
  DELETE_PROFILE,
  UPDATE_USER,
  OPEN_MODAL_CELLPHONE_VERIFICATION,
  ADD_USER_REFERENCE,
  ADD_USER_FLAG_BY_CHECKER,
  CLEAN_PLAN_REFERENCE,
  LIST_EMPLOYEES,
  GIVE_EMPLOYEE,
  DOWN_EMPLOYEE,
  GIVE_ALL_EMPLOYEES,
  DOWN_ALL_EMPLOYEES,
  CHANGE_MODAL_DEPENDENTS,
  LIST_DEPENDENTS_EMPLOYEE,
} from '../types';
import { addPresentialPatient } from './presential.action';
import { setModalCarnet, setModalSearchPatient } from './ui.action';
import { $ } from 'jquery';

export const giveEmployee = (item) => async (dispatch) => {
  dispatch({
    type: GIVE_EMPLOYEE,
    payload: item,
  });
};
export const giveAllEmployees = (item) => async (dispatch) => {
  dispatch({
    type: GIVE_ALL_EMPLOYEES,
    payload: item,
  });
};

export const downEmployee = (item) => async (dispatch) => {
  dispatch({
    type: DOWN_EMPLOYEE,
    payload: item,
  });
};

export const downAllEmployees = () => async (dispatch) => {
  dispatch({
    type: DOWN_ALL_EMPLOYEES,
  });
};

export const changeModalDependentEmployee = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_MODAL_DEPENDENTS,
    payload: payload,
  });
};

export const getEmployeesSearch =
  (IdCompany, searching) => async (dispatch) => {
    var array = [];
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ IdCompany });
    try {
      const res = await axios.post('/api/companies/getEmployees', body, config);
      for (var i = 0; i < res.data.length; i++) {
        if (
          res.data[i].dni.includes(searching.replace(/ /g, '')) ||
          res.data[i].email.includes(searching.replace(/ /g, '')) ||
          res.data[i].firstName.includes(searching.replace(/ /g, '')) ||
          res.data[i].lastName.includes(searching.replace(/ /g, ''))
        ) {
          array.push(res.data[i]);
        }
        var temp = res.data[i].firstName + res.data[i].lastName;
        if (temp.replace(/ /g, '') == searching.replace(/ /g, '')) {
          array.push(res.data[i]);
        }
      }
      dispatch({
        type: LIST_EMPLOYEES,
        payload: array,
      });
    } catch (error) {
      console.log(error);
    }
  };
export const getEmployees = (IdCompany) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ IdCompany });
  try {
    const res = await axios.post('/api/companies/getEmployees', body, config);
    dispatch({
      type: LIST_EMPLOYEES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getDependentEmployee = (_id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ _id });
  try {
    const res = await axios.post(
      '/api/dependent/patient/allEmployeeDependents',
      body,
      config
    );
    dispatch({
      type: LIST_DEPENDENTS_EMPLOYEE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSubscription =
  (user, selectedPackage, total) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ user, selectedPackage, total });
    try {
      const res = await axios.post(
        '/api/subscription/addSubscription',
        body,
        config
      );
      await createAlert('Exito', 'paso ', 'success');
      if (res.status === 200) {
        await createAlert('Exito', 'Suscripción Realizada', 'success');
        await dispatch(getPlanReference());
      } else {
        await createAlert(
          'Error',
          'No se pudo realizar la suscripción',
          'error'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

export const cancelSubscription = (idPlan) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `/api/subscription/cancelSubscription/${idPlan}`,
      config
    );
    if (res.status === 200) {
      await createAlert('Exito', 'Suscripción cancelada', 'success');
      await dispatch(getPlanReference());
    } else {
      await createAlert('Error', 'No se pudo cancelar la suscripción', 'error');
    }
  } catch (error) {
    console.log(error);
    await createAlert('Error', 'No se pudo actualizar suscripción', 'error');
  }
};
export const updateToSubscription = (idPlan) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `/api/subscription/updateToSubscription/${idPlan}`,
      config
    );

    if (res.status === 200) {
      await createAlert(
        'Exito',
        'Felicidades has actualizado a suscripcion',
        'success'
      );
      await dispatch(getPlanReference());
    } else {
      await createAlert('Error', 'No se pudo actualizar suscripción', 'error');
    }
  } catch (error) {
    console.log(error);
    await createAlert('Error', 'No se pudo actualizar suscripción', 'error');
  }
};

export const updateContactPatient =
  (idPatient, otherContact) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ otherContact });

    try {
      const res = await axios.put(
        `/api/users//updateOtherContact/${idPatient}`,
        body,
        config
      );

      if (res.status === 200) {
        await createAlert(
          'Exito',
          'Felicidades has actualizado el contacto',
          'success'
        );
        await dispatch(getPlanReference());
      } else {
        await createAlert(
          'Error',
          'No se pudo actualizar el contacto',
          'error'
        );
      }
    } catch (error) {
      console.log(error);
      await createAlert('Error', 'No se pudo actualizar suscripción', 'error');
    }
  };

// modal verificar numero de telefono
export const openModalCellphone = () => ({
  type: OPEN_MODAL_CELLPHONE_VERIFICATION,
  payload: true,
});

export const closeModalCellphone = () => ({
  type: OPEN_MODAL_CELLPHONE_VERIFICATION,
  payload: false,
});

// VERIFICAR SI EL NUMERO DE TELEFONO ESTA DISPONIBLE
export const checkcellphone =
  ({ cellphone, dialCode, idType, dni }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let tempDni;

    if (idType === 'national') {
      tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    } else if (idType === 'foreign') {
      tempDni = dni;
    } else {
      tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    }

    const body = JSON.stringify({
      cellphone,
      dialCode,
      idType,
      dni: tempDni,
    });

    try {
      const res = await axios.post('/api/users/check/cellphone', body, config);
      if (res.data.status === 'success') {
        dispatch(openModalCellphone());
      } else {
        createAlert('Error!', res?.data?.message, 'error');
        return;
      }
    } catch (error) {
      createAlert('Error!', error?.response?.message, 'error');
    }
  };

// autentificar usuario
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/users');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch(getProfile(res.data.role));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// registrar usuario doctor
export const registerDoctor =
  ({
    firstName,
    lastName,
    password,
    email,
    dni,
    bornAt,
    cellphone,
    country,
    gender,
    role,
    idType,
    dialCode,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // genero
    let temp;
    if (gender === 'Masculino') {
      temp = 'male';
    } else {
      temp = 'female';
    }

    let tempType;
    let tempDni;

    if (idType === 'ID nacional') {
      tempType = 'national';
      tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    } else if (idType === 'ID extranjero') {
      tempType = 'foreign';
      tempDni = dni;
    } else {
      tempType = 'rtn';
      tempDni = dni.replace(/-/g, '').replace(/_/g, '');
    }

    // tipo de registro

    const body = JSON.stringify({
      firstName: firstName,
      idType: tempType,
      dni: tempDni,
      lastName: lastName,
      password: password,
      email: email,
      gender: temp,
      profile: {
        bornAt: bornAt,
        cellphone: cellphone,
        country: country,
        dialCode: dialCode,
      },
      role: role,
    });

    try {
      const res = await axios.post(`/api/users/register/${role}`, body, config);
      const { token } = res.data;
      const socketId = getCurrentConnection();
      await createConnection({
        token,
        socketId,
      });
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      // cargas datos de usuario
      await dispatch(loadUser());
    } catch (err) {
      if (err.response !== null) {
        const errors = err.response.data;
        createAlert('¡Error!', errors.message, 'error');
      }
      console.log(err);
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const closeAllUserSesions = (email) => async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    email,
  });
  try {
    const res = await axios.put(
      '/api/users/close/all/login/sesions',
      body,
      config
    );
    closeAllConnections(res.data._id);
  } catch (error) {
    console.log(error);
  }
};

// registrar usuario paciente
export const registerPatient =
  ({
    firstName,
    lastName,
    email,
    password,
    role,
    registerType,
    gender,
    idType,
    dni,
    bornAt,
    dialCode,
    cellphone,
    country,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // tipo de registro
    const body = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      role: role,
      registerType: registerType,
      gender: gender,
      dni: dni,
      idType: idType,
      bornAt: bornAt,
      dialCode: dialCode,
      cellphone: cellphone,
      country: country,
    });

    try {
      const res = await axios.post(`/api/users/register/${role}`, body, config);

      const { token } = res.data;
      const socketId = getCurrentConnection();
      await createConnection({ socketId, token });

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const registerPatientCompany =
  ({
    firstName,
    lastName,
    email,
    role,
    registerType,
    gender,
    idType,
    dni,
    bornAt,
    dialCode,
    cellphone,
    country,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // tipo de registro
    const body = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role,
      registerType: registerType,
      gender: gender,
      dni: dni,
      idType: idType,
      bornAt: bornAt,
      dialCode: dialCode,
      cellphone: cellphone,
      country: country,
    });

    try {
      const res = await axios.post(
        `/api/users/registerCompanyUser/${role}`,
        body,
        config
      );
      if (res.status === 200) {
        createAlert('Bienvenido!', res.data?.message, 'success').finally(() => {
          window.location.href = '/login';
        });
      } else {
        createAlert('Error!', 'Error al crear el usuario', 'error');
      }
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//register employed company, registrar empleado de empres en español
export const registerEmployedCompany =
  ({
    firstName,
    lastName,
    password,
    email,
    role,
    gender,
    dni,
    idType,
    bornAt,
    registerType,
    cellphone,
    dialCode,
    country,
    job,
    idCompany,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let tempType = 'national';
    let tempDni = dni.replace(/-/g, '').replace(/_/g, '');

    // tipo de registro
    const body = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      role: role,
      registerType: registerType,
      gender: gender,
      dni: tempDni,
      idType: tempType,
      bornAt: bornAt,
      dialCode: dialCode,
      cellphone: cellphone,
      country: country,
      job: job,
      idCompany: idCompany,
    });
    console.log(body);
    try {
      const res = await axios.post(`/api/companies/register`, body, config);
      await dispatch(getEmployees(idCompany));
      return res;
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
      return err;
    }
  };

export const registerMasiveEmployedCompany =
  ({
    firstName,
    lastName,
    password,
    email,
    role,
    gender,
    dni,
    idType,
    bornAt,
    registerType,
    cellphone,
    status,
    dialCode,
    country,
    // job,
    idCompany,
    numberReference,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let tempType = 'national';
    let tempDni = dni.replace(/-/g, '').replace(/_/g, '');

    // tipo de registro
    const body = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      role: role,
      registerType: registerType,
      gender: gender,
      dni: tempDni,
      idType: tempType,
      bornAt: bornAt,
      dialCode: dialCode,
      cellphone: cellphone,
      status: status === 'activo' ? true : false,
      country: country,
      //job: job,
      idCompany: idCompany,
      numberReference: numberReference,
    });

    try {
      const res = await axios.post(
        `/api/companies/registerMassive`,
        body,
        config
      );
      await dispatch(getEmployees(idCompany));
      return res;
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
      return err;
    }
  };

export const updateStatusEmployee =
  (usuarioRef, statusEmployee, idCompany) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      userRef: usuarioRef,
      status: statusEmployee,
    });
    try {
      const res = await axios.put(`/api/companies/updateStatus`, body, config);
      if (res.status === 200) {
        await dispatch(getEmployees(idCompany));
      } else {
        await createAlert('Error', 'No se pudo modificar el estatus', 'error');
      }
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
    }
  };
export const updatePackageEmployee =
  (user, idPackage, token, idCompany) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      };
      const body = JSON.stringify({
        user,
        idPackage,
      });
      const res = await axios.put('/api/companies/updatePackage', body, config);
      if (res.status === 200) {
        await createAlert(
          'Exito',
          'Plan de empleado actualizado exitosamente',
          'success'
        );

        await dispatch(getEmployees(idCompany));
      } else {
        await createAlert('Error', 'No se po', 'error');
      }
    } catch (error) {
      await createAlert(
        'Error',
        'No se pudo actualizar el plan del empleado',
        'error'
      );
    }
  };
export const removePackageEmployee =
  (idUser, token, idCompany) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      };
      const body = JSON.stringify({
        idUser,
      });
      const res = await axios.put('/api/companies/removePackage', body, config);
      if (res.status === 200) {
        await createAlert(
          'Exito',
          'Plan de empleado eliminado exitosamente',
          'success'
        );
        await dispatch(getEmployees(idCompany));
      } else {
        await createAlert(
          'Error',
          'No se pudo eliminar el plan del empleado',
          'error'
        );
      }
    } catch (error) {
      await createAlert(
        'Error',
        'No se pudo eliminar el plan del empleado',
        'error'
      );
    }
  };
//updat eemployed company
export const UpdateEmployedCompany =
  ({
    usuarioRef,
    firstName,
    lastName,
    email,
    gender,
    dni,
    idType,
    bornAt,
    position,
    packageKtmID,
    idCompany,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let tempType = 'national';
    let tempDni = dni.replace(/-/g, '').replace(/_/g, '');

    // tipo de registro
    const body = JSON.stringify({
      userRef: usuarioRef,
      firstName: firstName,
      lastName: lastName,
      email: email,
      gender: gender,
      dni: tempDni,
      idType: tempType,
      bornAt: bornAt,
      position: position,
      packagektm: packageKtmID,
    });

    try {
      const res = await axios.put(`/api/companies/update`, body, config);
      if (res.status === 200) {
        await createAlert(
          'Exito',
          'Usuario actualizado exitosamente',
          'success'
        );

        await dispatch(deleteReference());
        return res;
      } else {
        await createAlert('Error', 'No se pudo actulizar el usuario', 'error');
        return res;
      }
      await dispatch(getEmployees(idCompany));
    } catch (err) {
      createAlert('Error!', err.response?.data?.message, 'error');
    }
  };

export const getTransactions = async (IdCompany) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ idCompany: IdCompany });
  try {
    const res = await axios.get('/api/companies/getTransactions', config);
    //console.log(JSON.stringify(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// hacer Login

export const login =
  ({ userInput, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      userInput,
      password,
    });

    try {
      const res = await axios.post('/api/users/auth', body, config);

      const { token, user } = res.data;
      const socketId = getCurrentConnection();
      await createConnection({
        token,
        socketId,
      });

      /*if (user.role === 'doctor') {
        changeStatusDoctor({
          doctorId: user._id,
          status: 'available',
          role: user.role,
        });
      }*/
      dispatch(downAllEmployees());
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      if (err.response !== null) {
        const errors = err.response.data;
        if (errors.sesion) {
          dispatch(closeAllUserSesions(userInput)).then(async () => {
            const res2 = await axios.post('/api/users/auth', body, config);

            const { token, user } = res2.data;
            const socketId = getCurrentConnection();
            await createConnection({
              token,
              socketId,
            });

            dispatch(downAllEmployees());
            dispatch({
              type: LOGIN_SUCCESS,
              payload: res2.data,
            });

            dispatch(loadUser());
          });

          /*Swal.fire({
            title: 'Sesión duplicada',
            text: 'Al parecer estas logueado actualmente con otra cuenta. ¿Deseas cerrar sesión en todos tus dispositivos?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(closeAllUserSesions(userInput));
            } else {
              createAlert(
                '¡Error!',
                'No se puede iniciar sesión en multiples dispositivos.',
                'error'
              );
            }
          });*/
        } else {
          createAlert('¡Error!', errors.message, 'error');
        }
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// forgot password
export const forgoPassword =
  ({ email, cellphone, dialCode, country, registerType }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const url = window.location.origin;
      const body = JSON.stringify({
        email,
        cellphone,
        dialCode,
        country,
        registerType,
        url,
      });
      const res = await axios.post('/api/users/forgotpass', body, config);
      if (res.data.status === 'success') {
        if (registerType === 'email') {
          createAlert(
            '¡Correcto!',
            'Te hemos enviado un correo con las intrucciones para cambiar tu contraseña.',
            'success'
          ).then(() => {
            window.location.href = '/login';
          });
        } else {
          createAlert(
            '¡Correcto!',
            'Te hemos enviado un mensaje a tu número telefónico con las intrucciones para cambiar tu contraseña.',
            'success'
          ).then(() => {
            window.location.href = '/login';
          });
        }
      }
    } catch (error) {
      createAlert('¡Error!', error.response.data?.message, 'error');
      console.log(error);
    }
  };
export const changePasswordByEmail = (password, token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({
      password,
      token,
    });
    const res = await axios.post('/api/users/changepass', body, config);
    if (res.data.status === 'success') {
      createAlert(
        '¡Correcto!',
        'Se ha cambiado su contraseña de forma exitosa.',
        'success'
      ).then(() => {
        window.location.href = '/login';
      });
    }
  } catch (error) {
    if (error.response.data) {
      createAlert('¡Error!', error.response.data.message, 'error');
    }
  }
};

// actualizar datos del usuario

export const updateUser = (doc) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: doc,
  });
};
// cerrar sesion

export const logout = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
  dispatch({
    type: DELETE_PROFILE,
  });
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: CLEAN_PLAN_REFERENCE,
  });
};

export const validateCellphoneCode =
  (formData, code, presential) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const {
      bornAt,
      cellphone,
      country,
      dialCode,
      dni,
      email,
      firstName,
      gender,
      idType,
      lastName,
      password,
      passwordConfirm,
      registerType,
      role,
    } = formData;

    const body = JSON.stringify({
      bornAt,
      cellphone,
      country,
      dialCode,
      dni,
      email,
      firstName,
      gender,
      idType,
      lastName,
      password,
      passwordConfirm,
      registerType,
      role,
      code,
    });

    try {
      const res = await axios.post('/api/users/verify/cellphone', body, config);

      if (res.data.status === 'success') {
        if (presential) {
          await dispatch(addPresentialPatient(formData));
          await dispatch({
            type: ADD_USER_REFERENCE,
            payload: formData,
          });
          await dispatch({
            type: ADD_USER_FLAG_BY_CHECKER,
            payload: false,
          });
        } else {
          await dispatch(registerPatient(formData));
        }

        dispatch(closeModalCellphone());
      } else {
        createAlert('Error!', res?.data?.message, 'error');
      }
    } catch (error) {
      createAlert('Error!', 'El código es invalido', 'error');
    }
  };

export const getUserByEmailOrCellphoneOrDni =
  ({ cellphone, dialCode, dni, idType, email }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cellphone) {
        const body = JSON.stringify({
          cellphone,
          dialCode,
        });
        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        }
      }

      if (email) {
        const body = JSON.stringify({
          email,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);

        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        }
      }

      if (dni) {
        const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
        const body = JSON.stringify({
          dni: tempDni,
          idType,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else {
          dispatch(
            createAlert(
              'Error',
              'El usuario no existe o esta registrado como otro tipo de usuario.',
              'error'
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getUserByEmailOrCellphoneOrDniByDoctor =
  ({ cellphone, dialCode, dni, idType, email }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cellphone) {
        const body = JSON.stringify({
          cellphone,
          dialCode,
        });
        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
          dispatch(getPlanReferenceById({ patientId: res.data._id }));
          dispatch(getAllDependents(res.data._id));
          dispatch(setModalCarnet(true));
          dispatch(setModalSearchPatient(false));
        } else {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        }
      }

      if (email) {
        const body = JSON.stringify({
          email,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);

        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
          dispatch(getPlanReferenceById({ patientId: res.data._id }));
          dispatch(getAllDependents(res.data._id));
          dispatch(setModalCarnet(true));
          dispatch(setModalSearchPatient(false));
        } else {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        }
      }

      if (dni) {
        const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
        const body = JSON.stringify({
          dni: tempDni,
          idType,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
          dispatch(getPlanReferenceById({ patientId: res.data._id }));
          dispatch(getAllDependents(res.data._id));
          dispatch(setModalCarnet(true));
          dispatch(setModalSearchPatient(false));
        } else {
          dispatch(
            createAlert(
              'Error',
              'El usuario no existe o esta registrado como otro tipo de usuario.',
              'error'
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const addUserByCheckerFromSearch =
  ({ cellphone, dialCode, dni, idType, email }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (cellphone) {
        const body = JSON.stringify({
          cellphone,
          dialCode,
        });
        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else if (res.data) {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        } else {
          Swal.fire({
            title: 'Agregar usuario',
            text: 'El usuario no existe.  ¿desea agregarlo?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((res) => {
            if (res.isConfirmed) {
              dispatch(changeAddUserFlag(true));
            }
          });
        }
      }

      if (email) {
        const body = JSON.stringify({
          email,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else if (res.data) {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        } else {
          Swal.fire({
            title: 'Agregar usuario',
            text: 'El usuario no existe.  ¿desea agregarlo?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((res) => {
            if (res.isConfirmed) {
              dispatch(changeAddUserFlag(true));
            }
          });
        }
      }

      if (dni) {
        const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
        const body = JSON.stringify({
          dni: tempDni,
          idType,
        });

        const res = await axios.post('/api/users/get/user/ref', body, config);
        if (res.data && res.data.role === 'patient') {
          dispatch({
            type: ADD_USER_REFERENCE,
            payload: res.data,
          });
        } else if (res.data) {
          createAlert(
            'Error',
            'El usuario no existe o esta registrado como otro tipo de usuario.',
            'error'
          );
        } else {
          Swal.fire({
            title: 'Agregar usuario',
            text: 'El usuario no existe.  ¿desea agregarlo?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((res) => {
            if (res.isConfirmed) {
              dispatch(changeAddUserFlag(true));
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
