import React from 'react';

const ShowHistoryModal = ({historyPatient}) => (
  <div
    className="modal fade"
    id="Modalhistorial"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            HISTORIAL MÉDICO
          </h5>
        </div>
        <div className="modal-body">
          <div
            id="carouselExample"
            className="carousel slide"
            data-ride="carousel"
            data-interval="2000"
          >
            <div className="panel-group wid100">
              <div className="panel panel-info">
                <div className="panel-heading">Medicamentos que toma actualmente</div>
                {
                  historyPatient?.medicines.length === 0 ? (
                    <div className="panel-body">
                      Ninguno
                    </div>
                  ) : historyPatient?.medicines.map((doc) => (
                    <div className="panel-body">
                      {' '}
                      {doc.name}
                      {' '}
                      <span>
                        /
                        {doc.dose}
                        ,
                        {' '}
                        {doc.frequency}
                      </span>
                    </div>
                  ))
                }
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Antecedentes de salud</div>
                <div className="panel-body">
                  {
                    historyPatient?.healthHistories.length === 0
                      ? 'Ninguno' : historyPatient?.healthHistories.map((doc) => (
                        doc.name
                      ))
                  }
                </div>
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Antecedentes familiares</div>
                {
                  historyPatient?.familiarAntecedents.length === 0
                    ? 'Ninguno' : historyPatient?.familiarAntecedents.map((doc) => (
                      <div className="panel-body">
                        {doc.name}
                        {' '}
                        <span>
                          {' '}
                          /
                          {doc.relation.map((name) => name)}
                        </span>
                      </div>
                    ))
                }
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Alergias</div>
                <div className="panel-body">
                  {
                    historyPatient?.allergies.length === 0
                      ? 'Ninguno' : historyPatient?.allergies.map((doc) => (
                        doc.name
                      ))
                  }
                </div>
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Vacunas</div>
                <div className="panel-body">
                  {
                    historyPatient?.vaccinate.length === 0
                      ? 'Ninguno' : historyPatient?.vaccinate.map((doc) => (
                        doc.name
                      ))
                  }
                </div>
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Antecedentes quirúrgicos</div>
                <div className="panel-body">
                  {
                    historyPatient?.surgeries.length === 0
                      ? 'Ninguno' : historyPatient?.surgeries.map((doc) => (
                        doc.name
                      ))
                  }
                </div>
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Historial clínico conductual</div>
                <div className="panel-body">Ataques de panico</div>
              </div>
              <div className="panel panel-info">
                <div className="panel-heading">Estilo de vida</div>
                <div className="panel-body">
                  {' '}
                  {
                    historyPatient?.surgeries.length === 0
                      ? 'Ninguno' : historyPatient?.surgeries.map((doc) => (
                        <>
                          <span>
                            {doc.name}
                          </span>
                          <br />
                        </>
                      ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ShowHistoryModal;
