/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import jspdf from 'jspdf';
import { toPng } from 'html-to-image';
import {
  getQueryHistory,
  SelectItemHistory,
} from '../../../redux/actions/searchPatient.action';
import { Spinner } from 'react-bootstrap';
function QueryHistory({
  patientID,
  consultas,
  selectedItem,
  getQueryHistory,
  SelectItemHistory,
  user,
}) {
  const [loaderButton, setLoaderButton] = useState(false);
  useEffect(() => {
    if (patientID) {
      getQueryHistory(desde, hasta, patientID);
    } else {
      getQueryHistory(desde, hasta, user._id);
    }
  }, []);

  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });

  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    if (patientID) {
      setLoaderButton(true);
      e.preventDefault();
      await getQueryHistory(desde, hasta, patientID);
      setLoaderButton(false);
    } else {
      setLoaderButton(true);
      e.preventDefault();
      await getQueryHistory(desde, hasta, user._id);
      setLoaderButton(false);
    }
  };

  const showData = (data) => {
    SelectItemHistory(data);
  };

  const downloadHEA = () => {
    toPng(document.getElementById('descargarHEA'))
      .then((dataUrl) => {
        const doc = new jspdf();

        // Dimensiones de la ventana del navegador
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Dimensiones deseadas de la imagen en relación con la pantalla
        const imgWidth = screenWidth * 0.07; // Puedes ajustar el factor según tus necesidades
        const imgHeight = screenHeight * 0.4; // Puedes ajustar el factor según tus necesidades

        // Mantener la relación de aspecto
        const aspectRatio = imgWidth / imgHeight;

        // Calcular el ancho y alto reales para mantener la relación de aspecto
        let actualImgWidth = imgWidth;
        let actualImgHeight = imgWidth / aspectRatio;

        if (actualImgHeight > imgHeight) {
          actualImgHeight = imgHeight;
          actualImgWidth = imgHeight * aspectRatio;
        }

        // Calcular la posición para centrar la imagen en la página PDF
        const xPosition = (screenWidth - actualImgWidth) / 2;
        const yPosition = (screenHeight - actualImgHeight) / 2;
        doc.setProperties({
          pageWidth: actualImgWidth + 10,
          pageHeight: actualImgHeight * 1.5,
        });
        doc.addImage(dataUrl, 'PNG', 40, 20, actualImgWidth, actualImgHeight);
        doc.save('Resumen.pdf');
      })
      .catch((error) => {
        console.log(error);
        alert('Lo sentimos ocurrió un error al descargar el HEA');
      });
  };

  const downloadRecipe = () => {
    toPng(document.getElementById('descargarReceta'))
      .then((dataUrl) => {
        const doc = new jspdf();

        // Dimensiones de la ventana del navegador
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        // Dimensiones deseadas de la imagen en relación con la pantalla
        const imgWidth = screenWidth * 0.14; // Puedes ajustar el factor según tus necesidades
        const imgHeight = screenHeight * 0.4; // Puedes ajustar el factor según tus necesidades

        // Mantener la relación de aspecto
        const aspectRatio = imgWidth / imgHeight;

        // Calcular el ancho y alto reales para mantener la relación de aspecto
        let actualImgWidth = imgWidth;
        let actualImgHeight = imgWidth / aspectRatio;

        if (actualImgHeight > imgHeight) {
          actualImgHeight = imgHeight;
          actualImgWidth = imgHeight * aspectRatio;
        }

        // Calcular la posición para centrar la imagen en la página PDF
        const xPosition = (screenWidth - actualImgWidth) / 2;
        const yPosition = (screenHeight - actualImgHeight) / 2;
        doc.setProperties({
          pageWidth: actualImgWidth + 10,
          pageHeight: actualImgHeight * 1.5,
        });
        doc.addImage(dataUrl, 'PNG', 40, 20, actualImgWidth, actualImgHeight);
        doc.save('RecetaMedica.pdf');
      })
      .catch((error) => {
        console.log(error);
        alert('Lo sentimos ocurrió un error al descargar la receta médica');
      });
  };

  return (
    <div className='sales mt-3'>
      <h6 className='font-blue mt-2 mb-4 text-center'>
        Historial de consultas médicas
      </h6>
      <div className='container scroll-doctor'>
        <div className='row mb-4'>
          <div className='col-12 col-md-5 mb-2'>
            <div className='d-flex'>
              <label className='text-black-50'>Desde: </label>
              <input
                type='date'
                className='form-control'
                name='desde'
                value={desde}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className='col-12 col-md-5 mb-2'>
            <div className='d-flex'>
              <label className='text-black-50'>Hasta: </label>
              <input
                type='date'
                className='form-control'
                name='hasta'
                value={hasta}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className='col-12 col-md-2 mb-2'>
            <button
              type='submit'
              className='btn btn-primary btn-block'
              onClick={handleSubmit}
              style={{ height: '33px' }}
              disabled={loaderButton}
            >
              {loaderButton ? (
                <Spinner
                  style={{
                    width: 20,
                    height: 20,
                    color: '#ffff',
                    fontSize: 8,
                  }}
                  animation='border'
                />
              ) : (
                'Enviar'
              )}
            </button>
          </div>
        </div>
        <div className='row'>
          {' '}
          <div className='table-responsive container'>
            <table className='table table-light table-borderless table-striped'>
              <thead className='font-weight-bold bg-primarymdv text-center'>
                <tr>
                  <th scope='col' className='title-header-table2'>
                    Fecha/Hora
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Relación
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Tipo Consulta
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Médico
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Diagnóstico
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Indicaciones
                  </th>
                  <th scope='col' className='title-header-table2'>
                    Ver
                  </th>
                </tr>
              </thead>
              <tfoot />
              <tbody className='text-center text-secondarymdv'>
                {consultas.length > 0
                  ? consultas?.map((consulta) => (
                      <tr>
                        <td>
                          {`${consulta.createdAt.slice(0, 10)} / ${
                            consulta?.hour
                              ? consulta.hour
                              : consulta.createdAt.slice(11, 16)
                          }`}
                        </td>
                        <td>{`${
                          consulta.followRef ? 'Vinculada' : 'Inicial'
                        }`}</td>
                        <td>{consulta.type}</td>
                        <td>
                          {`${consulta.profileDoctor.firstName} ${consulta.profileDoctor.lastName}`}
                        </td>
                        <td>{consulta.diagnosis}</td>
                        <td>
                          {consulta.indications.length > 0 ? (
                            <button
                              type='button'
                              className='btn btn-info mt-2 btn-sm rounded-pill'
                              data-toggle='modal'
                              data-target='#receta'
                              onClick={(e) => {
                                showData(consulta);
                              }}
                            >
                              Descargar receta
                            </button>
                          ) : (
                            <div>No hay receta</div>
                          )}
                        </td>

                        <button
                          type='button'
                          className='btn btn-info mt-2 btn-sm rounded-pill'
                          data-toggle='modal'
                          data-target='#consultas'
                          onClick={(e) => {
                            showData(consulta);
                          }}
                        >
                          <i className='fas fa-eye' aria-hidden='true' />
                        </button>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            {consultas.length === 0 ? (
              <h1 className='text-primary font-weight-bold flex-justify-center-align-center empty-company-list-container'>
                No tiene consultas en estas fechas
              </h1>
            ) : null}
          </div>
        </div>
      </div>
      {/* modal ver detalle */}
      <div
        className='modal fade'
        id='consultas'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='col-md-12 col-sm-12 col-xs-12 gutter'>
              <div className='bg-detail'>
                <div className='sales'>
                  <div className='row  mar-2'>
                    <div className='card-r2' id='descargarHEA'>
                      <div className='card-body bbbb'>
                        <h6 className='white center'>Resumen de Consultas</h6>
                        {selectedItem != null && (
                          <h6 className='white center'>
                            {`Paciente: ${selectedItem.profile.firstName} ${selectedItem.profile.lastName}`}
                          </h6>
                        )}

                        <center className='m-t-30'>
                          {' '}
                          <img
                            className='img-circle-2 img-fluid'
                            src={
                              selectedItem?.profile?.image
                                ? `/api/profile/image/${selectedItem.profile.image}`
                                : require('../../../assets/img/avatars/avatar-pat-female.png')
                            }
                            alt='profile'
                          />
                        </center>
                      </div>
                      {selectedItem != null ? (
                        <div className='cont-r2'>
                          <h6 className='font-blue-r'>Motivo</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.reason
                              ? selectedItem.reason
                              : 'No disponible'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>HEA</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.hea
                              ? selectedItem.hea
                              : 'No disponible'}
                          </h6>{' '}
                          <hr />
                          {/* <h6 className="font-blue-r">Examen Fisico</h6>
                          <h6 className="font-blue-l" />
                          {' '}
                          <hr /> */}
                          <h6 className='font-blue-r'>Su Diagnostico</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.diagnosis
                              ? selectedItem?.diagnosis
                              : 'No disponibles'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>Indicaciones Médicas</h6>
                          {selectedItem.indications.length > 0 ? (
                            <h6 className='font-blue-l'>
                              {selectedItem.indications
                                ? selectedItem?.indications?.map(
                                    (item) =>
                                      `(${item.medicine},${item.dose},${item.frequency}), `
                                  )
                                : 'No disponibles'}
                            </h6>
                          ) : (
                            <div />
                          )}
                        </div>
                      ) : (
                        <div className='cont-r2'>
                          <h6 className='font-blue-r'>Motivo</h6>
                          <h6 className='font-blue-r'>HEA</h6>
                          <h6 className='font-blue-r'>Examen Fisico</h6>
                          <h6 className='font-blue-r'>Su Diagnostico</h6>
                          <h6 className='font-blue-r'>Indicaciones Médicas</h6>
                          <h6 className='font-blue-r'>Próxima Cita</h6>
                          <h6 className='font-blue-r'>Medico Tratante</h6>
                        </div>
                      )}

                      {selectedItem && (
                        <div className='cont-r2' style={{ marginTop: 10 }}>
                          <h6 style={{ marginBottom: 15, textAlign: 'center' }}>
                            EXAMEN FÍSICO
                          </h6>
                          {selectedItem?.physicalTest
                            ? selectedItem?.physicalTest?.map((test) => (
                                <div key={test.id}>
                                  <h6 className='font-blue-r'>{test.label}</h6>
                                  <h6 className='font-blue-l'>
                                    {test.description}
                                  </h6>
                                  <hr />
                                </div>
                              ))
                            : 'No disponibles'}
                        </div>
                      )}

                      {selectedItem && (
                        <div className='cont-r2' style={{ marginTop: 10 }}>
                          <h6 style={{ marginBottom: 15, textAlign: 'center' }}>
                            SIGNOS VITALES
                          </h6>
                          <h6 className='font-blue-r'>Temperatura (C°)</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.temperature
                              ? `${selectedItem?.vitalSings?.temperature}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>
                            Presión Arterial (mmHg)
                          </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.pressure?.initial
                              ? `${selectedItem?.vitalSings?.pressure?.initial}`
                              : 'No definido'}{' '}
                            /{' '}
                            {selectedItem?.vitalSings?.pressure?.final
                              ? `${selectedItem?.vitalSings?.pressure?.final}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>
                            Frecuencia Respiratoria (rpm)
                          </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.breathingFrequency
                              ? `${selectedItem?.vitalSings?.breathingFrequency}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>
                            Frecuencia Cardíaca (ppm)
                          </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.heartRate
                              ? `${selectedItem?.vitalSings?.heartRate}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>
                            Saturación de Oxígeno (%)
                          </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.saturation
                              ? `${selectedItem?.vitalSings?.saturation}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>Peso (Kg)</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.weight
                              ? `${selectedItem?.vitalSings?.weight}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>Estatura (Mts) </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.height
                              ? `${selectedItem?.vitalSings?.height}`
                              : 'No definido'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>
                            Índice de masa corporal (imc)
                          </h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.vitalSings?.imc
                              ? `${selectedItem?.vitalSings?.imc}`
                              : 'No definido'}
                          </h6>
                          <hr />
                        </div>
                      )}
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary mt-2 btn-sm'
                      onClick={downloadHEA}
                    >
                      Descargar Historial
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer mt-2 pb-3'>
              <div className='d-flex'>
                <button
                  type='button'
                  className='btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold'
                  data-dismiss='modal'
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Modal de receta medica */}
      <div
        className='modal fade'
        id='receta'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='col-md-12 col-sm-12 col-xs-12 gutter'>
              <div className='bg-detail'>
                <div className='sales'>
                  <div className='row  mar-2'>
                    <div className='card-r2' id='descargarReceta'>
                      <div className='card-body bbbb'>
                        <h6 className='white center'>Receta médica</h6>
                        {selectedItem != null && (
                          <>
                            <h6 className='white center'>
                              {`Paciente: ${selectedItem.profile.firstName} ${selectedItem.profile.lastName}`}
                            </h6>
                            <h6 className='white center'>
                              {`Doctor: ${selectedItem.profileDoctor.firstName} ${selectedItem.profileDoctor.lastName}`}
                            </h6>
                          </>
                        )}

                        <center className='m-t-30'>
                          {' '}
                          <img
                            className='img-circle-2 img-fluid'
                            src={
                              selectedItem?.profile?.image
                                ? `/api/profile/image/${selectedItem.profile.image}`
                                : require('../../../assets/img/avatars/avatar-pat-female.png')
                            }
                            alt='profile'
                          />
                        </center>
                      </div>
                      {selectedItem != null ? (
                        <div className='cont-r2'>
                          <hr />
                          <h6 className='font-blue-r'>HEA</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.hea
                              ? selectedItem.hea
                              : 'No disponible'}
                          </h6>{' '}
                          <hr />
                          {/* <h6 className="font-blue-r">Examen Fisico</h6>
                          <h6 className="font-blue-l" />
                          {' '}
                          <hr /> */}
                          <h6 className='font-blue-r'>Su Diagnostico</h6>
                          <h6 className='font-blue-l'>
                            {selectedItem?.diagnosis
                              ? selectedItem?.diagnosis
                              : 'No disponibles'}
                          </h6>
                          <hr />
                          <h6 className='font-blue-r'>Indicaciones Médicas</h6>
                          {selectedItem.indications.length > 0 ? (
                            <h6 className='font-blue-l'>
                              {selectedItem.indications
                                ? selectedItem?.indications?.map(
                                    (item) =>
                                      `(${item.medicine},${item.dose},${item.frequency}), `
                                  )
                                : 'No disponibles'}
                            </h6>
                          ) : (
                            <div />
                          )}
                        </div>
                      ) : (
                        <div className='cont-r2'>
                          <h6 className='font-blue-r'>HEA</h6>
                          <h6 className='font-blue-r'>Su Diagnostico</h6>
                          <h6 className='font-blue-r'>Indicaciones Médicas</h6>
                          <h6 className='font-blue-r'>Medico Tratante</h6>
                        </div>
                      )}
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary mt-2 btn-sm'
                      onClick={downloadRecipe}
                    >
                      Descargar receta
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer mt-2 pb-3'>
              <div className='d-flex'>
                <button
                  type='button'
                  className='btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold'
                  data-dismiss='modal'
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  patientID: state.profile.searchPatientID,
  consultas: state.history.queryHistory.items,
  selectedItem: state.history.queryHistory.selectedItem,
  user: state.auth.user,
});
export default connect(mapStateToProps, { getQueryHistory, SelectItemHistory })(
  QueryHistory
);
