/* eslint-disable global-require */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import '../../stylesheets/notifications.css';
import { Badge, Col, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { FiMail, MdClose } from 'react-icons/all';
import createAlert from '../../redux/actions/alert.action';
import { openModalScheduleDetails } from '../../redux/actions/ui.action';
import {
  addSelectedDate,
  showDetails,
} from '../../redux/actions/scheduling.action';
import { deleteNotification } from '../../redux/actions/notifications.action';
import { getReference } from '../../redux/actions/references';
import Modal from '../Patient/Scheduling/ScheduleDetailsModal';

const Notifications = ({
  notifications,
  getReference,
  schedule,
  addSelectedDate,
  showDetails,
  deleteNotification,
  openModalScheduleDetails,
}) => {
  const [, toggleHover] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClickSeeButton = (create_by, schedule_ref, id) => {
    toggleHover(false);
    if (history.location.pathname !== '/dashboard/agenda') {
      history.push('/dashboard/agenda');
    }
    const cita = schedule.filter((item) => item._id === schedule_ref);
    if (cita && cita.length > 0) {
      openModalScheduleDetails();
      getReference(create_by);
      const initial = new Date(moment(cita[0].date));
      initial.setHours(Number(cita[0].hour.substr(0, 2)));
      showDetails(cita[0]);
      addSelectedDate(initial);
      setTimeout(() => {
        setOpen(true);
      }, 1000);
    } else {
      createAlert('Error', '¡Ya no existe la cita!', 'error');
    }
    deleteNotification(id);
  };

  const handleDeleteNotification = (id) => {
    deleteNotification(id);
  };

  return (
    <div>
      <Modal open={open} setOpen={setOpen} />
      <div className='container'>
        <Dropdown>
          <Dropdown.Toggle
            id='dropdown-basic'
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              margin: 0,
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            <img
              src={require('../../assets/img/vectores/bell.svg')}
              className='img-fluid'
              alt='notificacion campana'
              width='28'
              loading='lazy'
            />
            {notifications && notifications.length > 0 && (
              <Badge className='badge-container'>
                {notifications && notifications.length}
              </Badge>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              width: 220,
              maxHeight: 600,
              overflow: 'auto',
              boxShadow: '10px 10px 32px -49px rgba(0,0,0,0.07)',
              padding: 0,
            }}
          >
            <Dropdown.Header
              style={{
                backgroundColor: '#0c87e7',
                color: '#fff',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Col xs={10}> Notificaciones</Col>
              <Col xs={2}>
                <Badge style={{ backgroundColor: 'white', color: '#0c87e7' }}>
                  {notifications.length}
                </Badge>
              </Col>
            </Dropdown.Header>
            {notifications.length === 0 ? (
              <div
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  padding: 20,
                }}
              >
                No tienes notificaciones pendientes
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification._id}
                  className='individual-notification-container'
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: 5,
                      fontSize: 13,
                      top: 0,
                    }}
                  >
                    <MdClose
                      color='#D4D4D4'
                      onClick={() => handleDeleteNotification(notification._id)}
                    />
                  </div>
                  <Col xs={3}>
                    <FiMail size={25} />
                  </Col>
                  <Col xs={9}>
                    <div className='notification-message'>
                      {notification.message}
                      <button
                        type='button'
                        className='button-ver'
                        onClick={() => {
                          handleClickSeeButton(
                            notification.create_by,
                            notification.schedule_ref,
                            notification._id
                          );
                        }}
                      >
                        ver
                      </button>
                      <div className='notification-date'>
                        {moment(notification.createAt).fromNow()}
                      </div>
                    </div>
                  </Col>
                </div>
              ))
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  notifications: state.notifications.items,
  schedule: state.scheduling.items,
});
export default connect(mapStateToProps, {
  getReference,
  addSelectedDate,
  deleteNotification,
  showDetails,
  openModalScheduleDetails,
})(Notifications);
