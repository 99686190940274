import {
GET_AMOUNT_AVAILABLE,
GET_QUERY_RETREATS
} from '../types'

const initialState = {
    amountAvailable: 0,
    queryRetreats: {
        consultas: [],
        retiros: []
    }
};

export default function (state = initialState, action){
    const { type, payload } = action
    switch(type){
        case GET_AMOUNT_AVAILABLE:
            return {
                ...state,
                amountAvailable: payload
            }
        case GET_QUERY_RETREATS:
            return {
                ...state,
                queryRetreats:{
                    ...state.queryRetreats,
                    consultas: payload.consultas,
                    retiros: payload.retiros
                }
            }
        default:
            return state;
    }
}