import { HANDLE_CHANGE_PAGINATION } from '../types';

export const setPagination = ({ center, prev, next, actualPage }) => ({
  type: HANDLE_CHANGE_PAGINATION,
  payload: {
    prev,
    center,
    next,
    actualPage,
  },
});
