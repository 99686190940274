/* eslint-disable arrow-parens */
import axios from 'axios';
import createAlert from './alert.action';
import {
  DELETE_BANKS_ACCOUNTS,
  GET_BANKS_ACCOUNTS,
  UPDATE_BANKS_ACCOUNTS,
  ADD_BANKS_ACCOUNTS,
  ADD_SELECTED_BANK,
  DELETE_SELECTED_BANK,
} from '../types';

export const getBankAccounts = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/accounts');
    dispatch({
      type: GET_BANKS_ACCOUNTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addBankAccount = (bank, number, type) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    bank: bank,
    number: number,
    type: type,
  });

  try {
    const res = await axios.post('/api/accounts', body, config);
    dispatch({
      type: ADD_BANKS_ACCOUNTS,
      payload: res.data,
    });
    createAlert('¡Correcto!', 'Cuenta agregada con éxito.', 'success');
  } catch (err) {
    if (err.response !== null) {
      const errors = err.response.data;
      createAlert('Error', errors.message, 'error');
    }
  }
};

export const deleteBankAccount = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/accounts/${id}`);
    dispatch({
      type: DELETE_BANKS_ACCOUNTS,
      payload: id,
    });
    createAlert('¡Correcto!', 'Cuenta eliminada con éxito', 'success');
  } catch (err) {
    if (err.response !== null) {
      const errors = err.response.data;
      createAlert('Error', errors.message, 'error');
    }
  }
};

export const updateBankAccount = (item, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    bank: item.bank,
    number: item.number,
    type: item.type,
  });

  try {
    const res = await axios.put(`/api/accounts/${id}`, body, config);
    dispatch({
      type: UPDATE_BANKS_ACCOUNTS,
      payload: res.data,
    });
    createAlert('¡Correcto!', 'Cuenta actualizada con éxito.', 'success');
  } catch (err) {
    if (err.response !== null) {
      const errors = err.response.data;
      createAlert('¡Error!', errors.message, 'error');
    }
  }
};

export const addSelectedItem = (item) => async (dispatch) => {
  dispatch({
    type: ADD_SELECTED_BANK,
    payload: item,
  });
};

export const deleteSelectedItem = () => async (dispatch) => {
  dispatch({
    type: DELETE_SELECTED_BANK,
    payload: null,
  });
};
