import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { searchPatientID } from '../../redux/actions/searchPatient.action';
import { Spinner } from 'react-bootstrap';
import {
  searchPatientsPagination,
  clearSearchPatientsPagination,
} from '../../redux/actions/searchPatient.action';
import { Modal } from 'react-bootstrap';
const ModalSearchPatientName = ({ show, onHide }) => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(true);
  const [selectedButton, setSelectedButton] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let patients = useSelector((state) => state.profile.searchPatien);
  const patientsCount = useSelector((state) => state.profile.searchPatienCount);
  const buscandoPaciente = useSelector(
    (state) => state.searchingPatient.searching
  );
  const itemsPerPage = 10;
  const totalPages = Math.ceil(patientsCount / itemsPerPage);
  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    fontWeight: 'bold',
  };

  useEffect(() => {
    dispatch(searchPatientsPagination(buscandoPaciente, page));
  }, [page]);
  //console.log({ patients });
  const handleClick = (e) => {
    dispatch(
      searchPatientID(e.value, e.profile, e.image, e.label, e.gender, history)
    ).then(() => {
      patients = null;
      onHide();
      //setShowModal(false); // Cierra el modal una vez que la acción se completa
    });
  };

  const nextPage = () => {
    if (page < totalPages) {
      setLoading(true);
      setSelectedButton(page + 1);
      setPage((prevPage) => prevPage + 1);
      setLoading(false);
    }
  };
  const prevPage = () => {
    if (page >= 2) {
      setLoading(true);
      setSelectedButton(page - 1);
      setPage((prevPage) => prevPage - 1);
      setLoading(false);
    }
  };

  /*const clearPatients = () => {
    patients = [];
    dispatch(clearSearchPatientsPagination());
  };*/
  const selectedPage = (index) => {
    setLoading(true);
    setSelectedButton(index);
    setPage(index);
    setLoading(false);
  };
  return (
    <Modal
      show={show && showModal}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/*<div
        //className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
        className="modal-dialog modal-dialog-centered modal-dialog modal-xl"
        role="document"
  >*/}
      <div className="modal-content">
        <div className="modal-header">
          <h3
            className="modal-title text-primary m-auto"
            id="ModalSearchPatientName"
          >
            Buscar paciente
          </h3>
        </div>
        <div className="modal-body text-justify">
          <h5 className="text-secondarymdv font-weight-bold text-center mt-2 mb-5">
            Elige tu paciente
          </h5>

          <div style={{ overflowY: 'scroll', height: 400 }}>
            {patientsCount !== null && !loading && patients !== null ? (
              <>
                {patients.length > 0 ? (
                  patients.map((patient) => [
                    <li
                      key={patient.dni}
                      data-dismiss="modal"
                      role="button"
                      onClick={() => handleClick(patient)}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <h5>{patient.label}</h5>
                        <h6 style={{ color: '#808080' }}>{patient?.dni}</h6>
                      </div>

                      <p>
                        Edad:{' '}
                        {patient.profile.bornAt
                          ? ` ${moment().diff(
                              patient.profile.bornAt,
                              'years',
                              false
                            )} años`
                          : ' sin espeficar'}
                      </p>
                    </li>,
                  ])
                ) : (
                  <h2 className="text-center">
                    No hay resultados para tu busqueda
                  </h2>
                )}
              </>
            ) : (
              <>
                <Spinner
                  style={{
                    width: 70,
                    height: 70,
                    //color: '#ff8f',
                    fontSize: 8,
                    marginLeft: '45%',
                    marginTop: '8%',
                  }}
                  variant="primary"
                  animation="border"
                />
                <div style={{ marginLeft: '41%', marginTop: 20 }}>
                  Cargando pacientes.....
                </div>
              </>
            )}
          </div>
          {patients?.length > 0 ? (
            <div style={{ padding: 5 }}>
              <button onClick={prevPage} disabled={page <= 0}>
                Prev
              </button>
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => selectedPage(index + 1)}
                  style={selectedButton === index + 1 ? buttonStyle : {}}
                >
                  {index + 1}
                </button>
              ))}
              <button onClick={nextPage}>Next</button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="modal-footer p-2">
          <button
            type="button"
            className="btn btn-info mr-3"
            data-dismiss="modal"
            style={{ borderRadius: '0.8rem' }}
            onClick={onHide}
          >
            Cerrar
          </button>
        </div>
      </div>
      {/*</div>*/}
    </Modal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(ModalSearchPatientName);
