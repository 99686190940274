/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import '../../stylesheets/profile.css';
import $ from 'jquery';
import { Spinner, Table, Button } from 'react-bootstrap';
import moment from 'moment';
import { AiOutlineCloudDownload, RiDeleteBin7Line } from 'react-icons/all';

import Swal from 'sweetalert2';
import {
  addLabFile,
  getLabFiles,
  getLabFileShow,
  addLabFileById,
  getLabFilesById,
  deleteFile,
  updateFileName,
  getLabFileShowOlder,
  deleteFileOlder,
} from '../../api/lab.file';
import createAlert from '../../redux/actions/alert.action';
import LabDoctorAddUserData from '../Lab/LabDoctorAddUserData';
import { deleteReference } from '../../redux/actions/references';

const LabImageShow = ({ token, labOrder, type }) => {
  const [file, setFile] = useState(null);
  const { filename } = labOrder;
  useEffect(() => {
    if (labOrder.title) {
      getLabFileShowOlder({ token, filename })
        .then((res) => {
          setFile(res);
        })
        .catch((e) => {
          setFile(null);
        });
    } else {
      getLabFileShow({ token, filename })
        .then((res) => {
          setFile(res);
        })
        .catch((e) => {
          setFile(null);
        });
    }
  }, [filename, token, labOrder.title]);

  const getUrl = () => {
    const splitted = labOrder.filename.split('.');
    if (splitted[splitted.length - 1] === 'pdf') {
      const blob = new Blob([file], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);

      return url;
    } else if (splitted[splitted.length - 1] === 'png') {
      const blob = new Blob([file], {
        type: 'image/png',
      });
      const url = window.URL.createObjectURL(blob);
      return url;
    } else if (splitted[splitted.length - 1] === 'jpeg') {
      const blob = new Blob([file], {
        type: 'image/jpeg',
      });
      const url = window.URL.createObjectURL(blob);
      return url;
    } else if (splitted[splitted.length - 1] === 'jpg') {
      const blob = new Blob([file], {
        type: 'image/jpg',
      });
      const url = window.URL.createObjectURL(blob);
      return url;
    }
  };

  const download = () => {
    const splitted = labOrder.filename.split('.');
    if (splitted[splitted.length - 1] === 'pdf') {
      const blob = new Blob([file], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'png') {
      const blob = new Blob([file], {
        type: 'image/png',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'jpeg') {
      const blob = new Blob([file], {
        type: 'image/jpeg',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (splitted[splitted.length - 1] === 'jpg') {
      const blob = new Blob([file], {
        type: 'image/jpg',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${labOrder.originalName ? labOrder.originalName : labOrder.title}`
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };

  if (type === 'button') {
    return (
      <a
        href='#'
        onClick={download}
        style={{ textDecoration: 'none', color: '#fff' }}
      >
        <AiOutlineCloudDownload />
      </a>
    );
  } else {
    return (
      <a href={'' + getUrl()} target='_blank'>
        {labOrder.originalName ? labOrder.originalName : labOrder.title}
      </a>
    );
  }
};

const Laboratorio = ({ token, user }) => {
  const [uploading, setUploading] = useState(false);
  const [labOrders, setLabOrders] = useState([]);
  const { userRef } = useSelector((state) => state.reference);
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleDeleteFile = async (file) => {
    Swal.fire({
      text: '¿Desea eliminar este archivo?',
      confirmButtonText: 'Si',
      showCancelButton: true,
      cancelButtonText: 'No',
    }).then(async (res) => {
      setUploading(true);
      if (res.isConfirmed) {
        if (file.title) {
          await deleteFileOlder(file);
        } else {
          await deleteFile(file);
        }
      }
      setUploading(false);
    });
  };

  useEffect(() => {
    if (userRef) {
      getLabFilesById({
        token,
        id: userRef._id,
      })
        .then((res) => {
          const tempOrders = [];
          res.older.forEach((item) => {
            tempOrders.push(item);
          });
          res.lab.forEach((item) => {
            tempOrders.push(item);
          });

          setLabOrders(tempOrders);
        })
        .catch((err) => {
          setLabOrders([]);
        });
    } else {
      getLabFiles({
        token,
      })
        .then((res) => {
          const tempOrders = [];
          res.older.forEach((item) => {
            tempOrders.push(item);
          });
          res.lab.forEach((item) => {
            tempOrders.push(item);
          });

          setLabOrders(tempOrders);
        })
        .catch((err) => {
          setLabOrders([]);
        });
    }
  }, [uploading, userRef, token]);

  // delete user reference when component unmount
  useEffect(() => () => dispatch(deleteReference()), [dispatch, userRef]);

  const handleSelectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setUploading(true);
    if (userRef) {
      addLabFileById({
        token,
        formData,
        id: userRef._id,
      })
        .then((res) => {
          updateFileName(res.lab, name)
            .then(() => {
              createAlert(
                '¡Creado!',
                'El archivo fue creado correctamente.',
                'success'
              );
            })
            .catch(() => {
              createAlert(
                '¡Error!',
                'El archivo no pudo ser guardado',
                'error'
              );
            });
        })
        .catch((e) => {
          createAlert(
            '¡Error!',
            'Ocurrió un error al intentar subir el archivo.',
            'error'
          );
        })
        .finally(() => {
          setUploading(false);
          $('#modalOrdenMedica').modal('hide');
        });
    } else {
      addLabFile({
        token,
        formData,
      })
        .then((res) => {
          updateFileName(res.lab, name)
            .then(() => {
              createAlert(
                '¡Creado!',
                'El archivo fue creado correctamente.',
                'success'
              );
            })
            .catch(() => {
              createAlert(
                '¡Error!',
                'No se logró cambiar el nombre al archivo',
                'error'
              );
            });
        })
        .catch((e) => {
          createAlert(
            '¡Error!',
            'Ocurrió un error al intentar Guardar el archivo.',
            'error'
          );
        })
        .finally(() => {
          setUploading(false);
          $('#modalOrdenMedica').modal('hide');
        });
    }
    setFile(null);
    setName('');
  };

  return (
    <div className='container-fluid'>
      <h2 className='text-primary mb-4 mt-5'>Laboratorio</h2>
      <br />
      <h2 className='text-primary text-center mt-5 mb-5 font-weight-bold'>
        {user.role === 'patient' ? '¿Qué deseas hacer?' : 'Busca un paciente'}
      </h2>
      <div className='container mb-3'>
        {user.role !== 'patient' && <LabDoctorAddUserData />}
        <div className='grid-layout-auto'>
          {user.role === 'patient' && (
            <>
              <div
                className='card h-100 card-body border-0 pointer shadow-sm animate__animated animate__fadeIn'
                style={{ borderRadius: '1rem' }}
                data-toggle='modal'
                data-target='#modalContactanos'
              >
                <div className='d-flex justify-content-between align-items-center m-auto d-block p-3'>
                  <img
                    src={require('../../assets/img/vectores/phone.svg')}
                    alt='logo'
                    width='50'
                    className='img-fluid mr-2'
                  />
                  <p className='text-primary font-weight-bold text-reponsive mt-3'>
                    Contáctanos
                  </p>
                </div>
              </div>
              <div
                className='card h-100 card-body border-0 align-items-center pointer shadow-sm animate__animated animate__fadeIn'
                data-toggle='modal'
                data-target='#modalOrdenMedica'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <div className='d-flex justify-content-between align-items-center m-auto d-block p-3'>
                  <img
                    src={require('../../assets/img/vectores/upload.svg')}
                    alt='logo'
                    width='50'
                    className='img-fluid mr-2'
                  />
                  <p className='text-primary font-weight-bold text-reponsive mt-3'>
                    Subir Orden Médica
                  </p>
                </div>
              </div>
              <div
                className='card h-100 card-body border-0 align-items-center pointer shadow-sm animate__animated animate__fadeIn'
                data-toggle='modal'
                data-target='#modalHistorial'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <div className='d-flex justify-content-between align-items-center m-auto d-block p-3'>
                  <img
                    src={require('../../assets/img/vectores/history-medical.svg')}
                    alt='logo'
                    width='55'
                    className='img-fluid mr-2'
                  />
                  <p className='text-primary font-weight-bold text-reponsive mt-3'>
                    Historial
                  </p>
                </div>
              </div>
            </>
          )}

          {user.role !== 'patient' && userRef && (
            <>
              <div
                className='card h-100 card-body border-0 align-items-center pointer shadow-sm animate__animated animate__fadeIn'
                data-toggle='modal'
                data-target='#modalOrdenMedica'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <div className='d-flex justify-content-between align-items-center m-auto d-block p-3'>
                  <img
                    src={require('../../assets/img/vectores/upload.svg')}
                    alt='logo'
                    width='50'
                    className='img-fluid mr-2'
                  />
                  <p className='text-primary font-weight-bold text-reponsive mt-3'>
                    Subir Orden Médica
                  </p>
                </div>
              </div>
              <div
                className='card h-100 card-body border-0 align-items-center pointer shadow-sm animate__animated animate__fadeIn'
                data-toggle='modal'
                data-target='#modalHistorial'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <div className='d-flex justify-content-between align-items-center m-auto d-block p-3'>
                  <img
                    src={require('../../assets/img/vectores/history-medical.svg')}
                    alt='logo'
                    width='55'
                    className='img-fluid mr-2'
                  />
                  <p className='text-primary font-weight-bold text-reponsive mt-3'>
                    Historial
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* modal contáctanos */}
      <div
        className='modal fade'
        id='modalContactanos'
        tabIndex='-1'
        aria-labelledby='modalContactanosLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i
                  className='far fa-times-circle text-primary'
                  aria-hidden='true'
                />
              </button>
            </div>
            <div className='modal-body'>
              <div className='container text-center'>
                <h3 className='text-primary mb-5 font-weight-bold'>
                  Puedes llamarnos o escribirnos:
                </h3>
                <div className='row justify-content-center mb-4'>
                  <div className='col-12 col-md-4 col-sm-4 mb-3'>
                    <h4 className='text-primary font-weight-light'>
                      <img
                        src={require('../../assets/img/vectores/phone.svg')}
                        alt='kielsa-tienda'
                        width='30'
                        className='img-fluid mr-2'
                      />
                      2239-3984
                    </h4>
                  </div>
                  <div className='col-12 col-md-4 col-sm-4 mb-3'>
                    <h4 className='text-primary font-weight-light'>
                      <img
                        src={require('../../assets/img/vectores/whatsapp.svg')}
                        alt='kielsa-tienda'
                        width='30'
                        className='img-fluid mr-2'
                      />
                      95340234
                    </h4>
                  </div>
                  <div className='col-12 col-md-6 col-sm-5 mb-3'>
                    <h4 className='text-primary font-weight-light'>
                      <img
                        src={require('../../assets/img/vectores/email.svg')}
                        alt='kielsa-tienda'
                        width='30'
                        className='img-fluid mr-2'
                      />
                      clinicas&lab@kielsa.com
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal orden médica */}
      <div
        className='modal fade'
        id='modalOrdenMedica'
        tabIndex='-1'
        aria-labelledby='modalOrdenMedicaLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i
                  className='far fa-times-circle text-primary'
                  aria-hidden='true'
                />
              </button>
            </div>
            <div className='modal-body'>
              <div className='container text-center'>
                <h3 className='text-primary mb-5 font-weight-bold'>
                  Selecciona un documento JPG o PDF:
                </h3>
                <form
                  onSubmit={handleSubmit}
                  className='row justify-content-center mb-5'
                >
                  <div className='col-12'>
                    <div className='input-group input-group-lg mb-3'>
                      <input
                        type='text'
                        name='name'
                        value={name}
                        onChange={handleChange}
                        placeholder='Nombre del archivo'
                        style={{
                          padding: 10,
                        }}
                      />
                      <input
                        type='file'
                        className='form-control border-right-0 mt-0'
                        placeholder='Selecciona un archivo...'
                        aria-label='Selecciona un archivo...'
                        aria-describedby='basic-addon2'
                        name='labfile'
                        accept='.jpg,.jpeg,.pdf'
                        style={{
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                        }}
                        id='input-file'
                        onChange={handleSelectFile}
                      />
                      <div className='input-group-append'>
                        <span
                          className='input-group-text border-left-0 border-right-rounded'
                          id='basic-addon2'
                        >
                          <img
                            src={require('../../assets/img/vectores/upload.svg')}
                            alt='logo'
                            width='20'
                            className='img-fluid'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-3 mb-3 mt-3'>
                    {uploading ? (
                      <Spinner
                        animation='border'
                        role='status'
                        variant='primary'
                        style={{
                          width: '3rem',
                          height: '3rem',
                        }}
                      />
                    ) : (
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                        disabled={name.trim().length < 3 || !file}
                      >
                        Subir
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Historial */}
      <div
        className='modal fade'
        id='modalHistorial'
        tabIndex='-1'
        aria-labelledby='modalHistorial'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl'>
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <h5 className='modal-title text-primary font-weight-bold ml-auto'>
                Exámenes de Laboratorio
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <i
                  className='far fa-times-circle text-primary'
                  aria-hidden='true'
                />
              </button>
            </div>
            <div className='modal-body'>
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Nombre archivo</th>
                      <th>Fecha de creación</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labOrders.map((file, index) => (
                      <tr key={file._id}>
                        <td>{index + 1}</td>
                        <td>
                          <LabImageShow token={token} labOrder={file} />
                        </td>
                        <td>{moment(file.createAt).format('LL')}</td>
                        <td
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            style={{
                              width: 'auto',
                              margin: 2,
                            }}
                          >
                            <LabImageShow
                              token={token}
                              labOrder={file}
                              type='button'
                            />
                          </Button>
                          <Button
                            style={{
                              width: 'auto',
                              backgroundColor: 'red',
                              margin: 2,
                            }}
                            onClick={() => handleDeleteFile(file)}
                          >
                            <RiDeleteBin7Line />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  token: state.auth.token,
});

export default connect(mapStateToProps)(Laboratorio);
