import React from 'react';

const ModalPrivacyPolicies = () => (
  <div
    className="modal fade"
    id="ModalPrivacyPolicies"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="ModalPrivacyPolicies"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3
            className="modal-title text-primary m-auto"
            id="ModalPrivacyPolicies"
          >
            POLÍTICAS DE PRIVACIDAD
          </h3>
        </div>
        <div className="modal-body text-justify">
          <h5 className="text-secondarymdv font-weight-bold">
            POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN.
          </h5>
          <p>
            La privacidad de la información allegada por los usuarios de Kielsa
            Clínica y Lab en su plataforma virtual o de forma presencial es de
            carácter confidencial, lo cual constituye un elemento y derecho
            fundamental del usuario. Por lo que se toman las precauciones
            necesarias y previstas por la ley para cumplir con la protección
            efectiva a la información recaudada con ocasión al uso de la
            plataforma.
            <br />
            <br />
            La plataforma es administrada en su totalidad por KIELSA
            FARMACEUTICA, S. A. DE C. V., sociedad comercial constituida bajo
            las leyes Honduras, con domicilio en la ciudad de Tegucigalpa,
            M.D.C.
            <br />
            <br />
            El uso de la plataforma virtual de Kielsa Clínica y Lab lleva a que
            naturalmente se presenten una serie de requerimientos mínimos por
            parte de los usuarios donde deben proveer información necesaria y
            suficiente para llevar a cabo los servicios en salud para el usuario
            según lo establecido por Kielsa Clínica y Lab en la página web de o
            de forma presencial. Quien haga uso de los servicios ofrecidos
            mediante la plataforma virtual o presencial debe aceptar los
            términos y condiciones de uso, así como aceptar de manera inequívoca
            que al inscribirse en el sitio virtual y/o consulta presencial está
            proporcionando información personal fidedigna, que será recolectada
            por parte de Kielsa Clínica y Lab y que guardará estándares de
            seguridad eficaces y conforme a la ley. La inscripción implica el
            conocimiento y la aceptación de las políticas aquí contenidas.
            <br />
            <br />
            DEFINICIONES
            <br />
            a) “Autorización”: Es el consentimiento previo, expreso e informado
            del Titular para llevar a cabo el Tratamiento de sus Datos
            Personales.
            <br />
            <br />
            b) “Base de Datos”: Es el conjunto organizado de Datos Personales
            que sean objeto de Tratamiento, electrónico o no, cualquiera que
            fuere la modalidad de su formación, almacenamiento, organización y
            acceso.
            <br />
            <br />
            c) “Dato Personal”: Es cualquier información de cualquier tipo,
            vinculada o que pueda asociarse a una o varias personas naturales o
            jurídicas determinadas o determinables.
            <br />
            <br />
            d) “Dato Público”: Es el Dato Personal calificado como tal según los
            mandatos de la ley o de la Constitución de la Republica y aquel que
            no sea semiprivado, privado o sensible. Son públicos, entre otros,
            los datos relativos al Registro Civil de las personas, a su
            profesión u oficio, a su calidad de comerciante o de servidor
            público y aquellos que puedan obtenerse sin reserva alguna. Por su
            naturaleza, los datos públicos pueden estar contenidos, entre otros,
            en registros públicos, documentos públicos, gacetas y boletines
            oficiales, sentencias judiciales debidamente ejecutoriadas que no
            estén sometidas a reserva.
            <br />
            <br />
            e) “Dato Sensible”: Es el Dato Personal que afecta la intimidad del
            Titular o cuyo uso indebido puede generar su discriminación, tales
            como aquellos que revelen afiliaciones sindicales, el origen racial
            o étnico, la orientación política, las convicciones religiosas,
            morales o filosóficas, la pertenencia a sindicatos , organizaciones
            sociales, de derechos humanos o que promueva intereses de cualquier
            partido político o que garanticen los derechos y garantías de
            partidos políticos de oposición, así como los datos relativos a la
            salud, a la vida sexual, y los datos biométricos.
            <br />
            <br />
            f) “Encargado del Tratamiento”: Es la persona natural o jurídica,
            pública o privada, que por sí misma o en asocio con otros, realice
            el Tratamiento de Datos Personales por cuenta del Responsable del
            Tratamiento.
            <br />
            <br />
            g) “Autorizado”: Es la sociedad y todas las personas bajo la
            responsabilidad de la sociedad, que por virtud de la Autorización y
            de estas Políticas tienen legitimidad para Tratar los Datos
            Personales del Titular. El Autorizado incluye al género de los
            Habilitados.
            <br />
            <br />
            h) “Habilitación”: Es la legitimación que expresamente y por escrito
            mediante contrato o documento que haga sus veces, otorgue Kielsa
            Clínica y Lab a terceros, en cumplimiento de la Ley aplicable, para
            el Tratamiento de Datos Personales, convirtiendo a tales terceros en
            Encargados del Tratamiento de los Datos Personales entregados o
            puestos a disposición.
            <br />
            <br />
            i) “Responsable de Tratamiento”: Es la persona natural o jurídica,
            pública o privada, que por sí misma o en asocio con otros, decida
            sobre la Base de Datos y/o el Tratamiento de los Datos Personales.
            <br />
            <br />
            j) “Titular” del Dato Personal: Es la persona natural o jurídica a
            quien se refiere la información que reposa en una Base de Datos, y
            quien es el sujeto del derecho de hábeas data.
            <br />
            <br />
            k) “Transmisión”: Es el Tratamiento de Datos Personales que implica
            la comunicación de los mismos dentro o fuera del territorio de la
            República de Honduras cuando tenga por objeto la realización de un
            Tratamiento por el Encargado por cuenta del Usuario.
            <br />
            <br />
            l) “Transferencia”: La transferencia de datos tiene lugar cuando el
            responsable y/o Encargado del Tratamiento de datos personales,
            ubicado en Honduras, envía la información o los datos personales a
            un receptor, que a su vez es Responsable del Tratamiento y se
            encuentra dentro o fuera del país.
            <br />
            <br />
            m) “Tratamiento de Datos Personales”: Es toda operación y
            procedimiento sistemático, electrónico o no, que permita la
            recolección, conservación, ordenamiento, almacenamiento,
            modificación, relacionamiento, uso, circulación, evaluación,
            bloqueo, destrucción y en general, el procesamiento de Datos
            Personales, así como también su transferencia a terceros a través de
            comunicaciones, consultas, interconexiones, cesiones o mensajes de
            datos.
            <br />
            <br />
            1. Derechos de acceso, retiro y rectificación de la información
            personal Los usuarios tienen los siguientes derechos respecto de la
            información personal que proporcionan:
            <br />
            <br />
            <br />
            A. Conocer, actualizar, retirar y rectificar sus datos personales.
            <br />
            B. Ser informado respecto del uso que se da a sus datos personales.
            <br />
            C. Revocar la autorización del uso de datos personales.
            <br />
            D. Presentar quejas, peticiones y reclamos mediante los mecanismos
            de comunicación dispuestos por Kielsa Clínica y Lab.
            <br />
            E. Una vez haya realizado la inscripción como usuario de la
            plataforma virtual o presencial de Kielsa Clínica y Lab puede el
            usuario revisar y cambiar la información depositada en el proceso de
            inscripción, sin embargo, se advierte sobre la conservación de la
            información anterior por parte de Kielsa Clínica y Lab por motivos
            de seguridad y fraude.
            <br />
            <br />
            2. Deberes del usuario
            <br />
            A. Garantizar y responder por la veracidad, exactitud, vigencia y
            autenticidad de la información personal entregada.
            <br />
            B. Mantener la información debidamente actualizada.
            <br />
            C. Ser responsable en el uso de la clave y usuario que tiene para el
            acceso a la plataforma virtual.
            <br />
            <br />
            3. Tratamiento de datos personales.
            <br />
            En virtud de la trasmisión de datos personales, las partes acuerdan
            que el tratamiento que deberá otorgárseles a éstos como encargado
            por Kielsa Clínica y Lab, será la ejecución de las operaciones de
            almacenamiento temporal, uso de acuerdo a la finalidad por la que
            hayan sido transmitidos los datos, de acuerdo a los criterios de
            retención o acopio de la información que sean establecidos por
            Kielsa Clínica y Lab como responsable de la información, previa
            autorización de tratamiento de los datos por quien sea titular de
            éstos.
            <br />
            <br />
            Sin perjuicio de lo anterior, Kielsa Clínica y Lab y los médicos que
            conozcan en razón del servicio de datos personales de los usuarios
            se obligan a dar tratamiento a los datos personales conforme a los
            principios rectores establecidos en el Código de Salud de la
            República de Honduras, demás normas legales aplicables y vigentes en
            la República de Honduras; salvaguardar la seguridad de las bases de
            datos en los que se contengan datos personales; y guardar
            confidencialidad respecto del tratamiento de los datos personales;
            otorgar seguridad a los registros evitando su adulteración, pérdida,
            consulta, uso o acceso no autorizado o fraudulento; así mismo deberá
            dar aviso a Kielsa Clínica y Lab sobre cualquier fuga de información
            o cualquier situación que pueda poner en riesgo la información.
            <br />
            <br />
            KIELSA FARMACEUTICA, S. A. DE C. V. se obliga que el tercero que
            reciba la información entregada por el usuario se comprometa a
            tratarla conforme con los principios rectores del manejo de la
            información, obligándose a guardar la reserva, disponibilidad,
            confidencialidad y seguridad de la misma.
            <br />
            <br />
            Así mismo si con ocasión del uso de la plataforma virtual Kielsa
            Clínica y Lab llegase a entregar información al usuario de un
            tercero, éste debe hacer uso de la información con el fin con el que
            específicamente se le otorgo, manteniendo siempre los principios
            rectores nombrados precedentemente.
            <br />
            <br />
            4. Datos Personales de Menores
            <br />
            El Tratamiento de Datos Personales de menores o adolescentes por
            parte de Kielsa Clínica y Lab se llevará a cabo respetando siempre
            los siguientes requisitos:
            <br />
            1. Siempre responder y respetar el interés superior de los niños,
            niñas y adolescentes.
            <br />
            2. Siempre asegurar el respeto a sus derechos fundamentales.
            <br />
            3. Explicar las consecuencias que conlleva el Tratamiento.
            <br />
            <br />
            5. Autorizaciones
            <br />
            El usuario de manera previa expresa e informada autoriza a KIELSA
            FARMACEUTICA, S. A. DE C. V., para que, directamente o a través de
            sus encargados, lleve a cabo la recolección, almacenamiento, uso,
            transferencia y transmisión (el “Tratamiento”) de sus datos
            personales y de los datos personales de terceros que está entregando
            con ocasión de la relación contractual. Esto, exclusivamente para
            las finalidades propias de la ejecución del contrato y con las que
            resulten similares o análogas a este.
            <br />
            <br />
            La autorización se dará en el momento de la inscripción, mediante
            documento electrónico, sin perjuicio de que pueda otorgarse a través
            de otros medios que permitan garantizar que se consigne la
            autorización al tratamiento de datos personales del usuario,
            aceptando además que KIELSA FARMACEUTICA, S. A. DE C. V. Recogerá,
            almacenará y utilizará la información otorgada únicamente para poder
            desarrollar los objetivos del uso de la plataforma virtual y/o
            atención presencial.
            <br />
            <br />
            6. Medidas de seguridad
            <br />
            KIELSA FARMACEUTICA, S. A. DE C. V. está obligado a cumplir con la
            normativa aplicable y vigente en materia de medidas de seguridad
            aplicables a la información personal, contando con los estándares y
            las medidas técnicas y administrativas que sean necesarias para
            otorgar seguridad a la información de los usuarios, manteniendo la
            información sin ningún tipo de modificación y garantizando la
            veracidad de la misma.
            <br />
            <br />
            7. Envío de información comercial
            <br />
            Kielsa Clínica y Lab quiere, además mantener a los usuarios al tanto
            de promociones, novedades, cambios, información de interés etc., los
            usuarios podrán aceptar o no el envío de información especial a sus
            datos de contacto, podrán además elegir el medio electrónico por
            medio del cual desea recibir la información de Kielsa Clínica y Lab.
            Así mismo podrá negarse a recibir información adicional igualmente
            podrá revocar la autorización del envío de la misma.
            <br />
            <br />
            8. Contacto con el área encargada de las peticiones, consultas,
            quejas y reclamos
            <br />
            KIELSA FARMACEUTICA, S. A. DE C. V. ofrecerá un área de servicio que
            estará a cargo de recibir peticiones, consultas, quejas o reclamos
            de los titulares de la información, contacto en el que además se
            podrán hacer solicitudes especiales respecto de la autorización y
            seguimiento de la protección de los datos entregados. Las quejas,
            reclamos o sugerencias podrán enviarse al correo electrónico
            info@kielsaclinica.com Las quejas y reclamos deberán acompañarse de
            la siguiente información:
            <br />
            <br />
            –Deberá contener el nombre y documento de identificación del
            Titular.
            <br />
            –Deberá contener una descripción de los hechos que dan lugar al
            reclamo y el objetivo perseguido (actualización, corrección o
            supresión, o cumplimiento de deberes).
            <br />
            –Deberá indicar la dirección y datos de contacto e identificación
            del reclamante.
            <br />
            –Deberá acompañarse por toda la documentación que el reclamante
            quiera hacer valer.
            <br />
            <br />
            10. Entrada en vigor y modificación del presente documento La
            presente política aplica desde el primero momento de inscripción y
            registro como usuario del sitio web www.kielsaclinica.com podrá
            unilateralmente modificar el presente documento, así como demás
            consideraciones que se hayan realizado con ocasión al uso de la
            plataforma virtual, comunicando mediante la actualización del sitio
            web página www.kielsaclinica.com
            <br />
            <br />
          </p>
        </div>
        <div className="modal-footer p-2">
          <button
            type="button"
            className="btn btn-info mr-3"
            data-dismiss="modal"
            style={{borderRadius: '0.8rem'}}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ModalPrivacyPolicies;
