/* eslint-disable no-shadow */
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import Avatar from 'react-avatar-edit';
import {closeModalProfileImage} from '../../redux/actions/ui.action';
import {addProfileImage} from '../../redux/actions/profile.action';

const AddProfileImageModal = ({
  modalProfileImage,
  closeModalProfileImage,
  addProfileImage,
  user: {image},
}) => {
  const handleClose = () => {
    closeModalProfileImage();
  };
  const [src, setSrc] = useState('');
  const [preview, setPreview] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const [enabled, setEnabled] = useState(false);

  const handleOnCrop = (pv) => {
    setPreview(pv);
  };

  const b64toBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: 'image/jpeg'});
  };

  const handleOnBeforeFileLoad = (e) => {
    // if (e.target.files[0].size > 71680) {
    //   alert('File is too big!');
    //   e.target.value = '';
    // }
    setTempFile(e.target.files[0]);
  };
  const handleOnclose = () => {
    setPreview(null);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    const blob = b64toBlob(preview);
    formData.append('file', blob);
    addProfileImage(formData, image);
    closeModalProfileImage();
  };
  const handleLoadFile = (file) => {
    setTempFile(file);
    setEnabled(true);
  };
  return (
    <Modal
      show={modalProfileImage}
      onHide={handleClose}
      centered
      backdropClassName="backdrop-style"
    >
      <Modal.Header style={{border: 'none'}} />
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            label="Seleccionar archivo"
            width={300}
            height={300}
            mimeTypes="image/jpeg,image/png"
            onCrop={handleOnCrop}
            exportAsSquare
            onClose={handleOnclose}
            onBeforeFileLoad={handleOnBeforeFileLoad}
            src={src}
            onFileLoad={handleLoadFile}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-link btn-sm font-weight-bold mr-2 tetx-secondarymdv"
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={handleSubmit}
            disabled={!enabled}
          >
            Aceptar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  modalProfileImage: state.ui.modalProfileImage.open,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  closeModalProfileImage,
  addProfileImage,
})(AddProfileImageModal);
