import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { totalVideoconference } from '../../../api/videoconference';
import {useTotalVideoconference} from './state/useTotalVideoconference';
import {useTotalVideocall} from './state/useTotalVideocall';
import {useTotalChat} from './state/useTotalChat';


const MyFigures = ({auth}) =>{
    const {total, setTotal } = useTotalVideoconference();
    const {totalVideocall, setTotalVideocall} = useTotalVideocall();
    const {totalChat, setTotalChat} = useTotalChat();

    useEffect(() => {
        const token = auth.token;
        
        totalVideoconference({ token })
        .then((response) => {
          const { totalVideoconference, totalVideo, totalChat } = response;
          setTotal(totalVideoconference);
          setTotalVideocall(totalVideo);
          setTotalChat(totalChat);
        })
        .catch((error) => console.log(error));


    }, [])
    return (
        <div className="sales report">
            <div className="row  mar-2">
            <h2 className="text-primary">Mis Cifras</h2>
            </div>
            <div className="counter">
            <div className="row">
                
                <div className="col-6 col-lg-4">
                <div className="count-data text-center">
                    <h6
                    className="count h2 font-blue"
                    data-to="150"
                    data-speed="150"
                    >
                    {total}
                    </h6>
                    <p className="m-0px font-w-600">Consultas</p>
                </div>
                </div>
                <div className="col-6 col-lg-4">
                <div className="count-data text-center">
                    <h6
                    className="count h2 font-blue"
                    data-to="850"
                    data-speed="850"
                    >
                    {totalVideocall}
                    </h6>
                    <p className="m-0px font-w-600">Llamadas</p>
                </div>
                </div>
                <div className="col-6 col-lg-4">
                <div className="count-data text-center">
                    <h6
                    className="count h2 font-blue"
                    data-to="190"
                    data-speed="190"
                    >
                    {totalChat}
                    </h6>
                    <p className="m-0px font-w-600">Chats</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(MyFigures);