/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getQueryHistory,
  SelectItemHistory,
} from "../../../redux/actions/searchPatient.action";
import { Spinner } from "react-bootstrap";
function NursingNotes({
  patientID,
  consultas,
  selectedItem,
  getQueryHistory,
  SelectItemHistory,
  user,
}) {
  const [loaderButton, setLoaderButton] = useState(false);
  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });

  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoaderButton(true);
    e.preventDefault();
    await getQueryHistory(desde, hasta, patientID);
    setLoaderButton(false);
  };

  const showData = (data) => {
    SelectItemHistory(data);
  };

  return (
    <div>
      <div className="sales mt-3">
        <h5 className="font-blue mt-2 mb-4 text-center">Notas de Enfermería</h5>
        <div className="container scroll-doctor">
          <div className="row mb-4">
            <div className="col-12 col-md-5 mb-2">
              <div className="d-flex">
                <label className="text-black-50">Desde: </label>
                <input
                  type="date"
                  name="desde"
                  className="form-control"
                  value={desde}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-5 mb-2">
              <div className="d-flex">
                <label className="text-black-50">Hasta: </label>
                <input
                  type="date"
                  name="hasta"
                  className="form-control"
                  value={hasta}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2 mb-2">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                style={{ height: "33px" }}
                onClick={handleSubmit}
                disabled={loaderButton}
              >
                {loaderButton ? (
                  <Spinner
                    style={{
                      width: 20,
                      height: 20,
                      color: "#ffff",
                      fontSize: 8,
                    }}
                    animation="border"
                  />
                ) : (
                  "Enviar"
                )}
              </button>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="table-responsive container">
              <table className="table table-light table-borderless table-striped">
                <thead className="font-weight-bold bg-primarymdv text-center">
                  <tr>
                    <th scope="col" className="title-header-table2">
                      Fecha/Hora
                    </th>
                    <th scope="col" className="title-header-table2">
                      Tipo Consulta
                    </th>
                    <th scope="col" className="title-header-table2">
                      Médico
                    </th>
                    <th scope="col" className="title-header-table2">
                      Diagnóstico
                    </th>
                    <th scope="col" className="title-header-table2">
                      Ver
                    </th>
                  </tr>
                </thead>
                <tfoot />
                <tbody className="text-center text-secondarymdv">
                  {consultas.length > 0 ? (
                    consultas.map((consulta) => (
                      <tr>
                        <td>{`${consulta.createdAt.slice(
                          0,
                          10
                        )} / ${consulta.createdAt.slice(11, 16)}`}</td>
                        <td>{consulta.type}</td>
                        <td>{`${consulta.profileDoctor.firstName} ${consulta.profileDoctor.lastName}`}</td>
                        <td>{consulta.diagnosis}</td>
                        <button
                          className="transp eye-b"
                          type="button"
                          className="btn btn-primary mt-2 btn-sm"
                          data-toggle="modal"
                          data-target="#consultas"
                          onClick={(e) => {
                            showData(consulta);
                          }}
                        >
                          <i className="fas fa-eye" aria-hidden="true" />
                        </button>
                      </tr>
                    ))
                  ) : (
                    <tr>No tiene consultas en estas fechas</tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="consultas"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="col-md-12 col-sm-12 col-xs-12 gutter">
                <div className="bg-detail">
                  <div className="sales">
                    <div className="row  mar-2">
                      <div className="card-r2">
                        <div className="card-body bbbb">
                          <h6 className="white center">Nota de enfermeria</h6>
                          {selectedItem != null ? (
                            <h6 className="white center">
                              Paciente:{" "}
                              {`${selectedItem.profile.firstName} ${selectedItem.profile.lastName}`}
                            </h6>
                          ) : (
                            <h6 />
                          )}

                          <center className="m-t-30">
                            {" "}
                            <img
                              className="img-circle-2 img-fluid"
                              src={
                                selectedItem != null
                                  ? selectedItem.profile?.image
                                    ? `/api/profile/image/${selectedItem.profile.image}`
                                    : require(user.gender === "female"
                                        ? "../../../assets/img/avatars/avatar-pat-female.png"
                                        : "../../../assets/img/avatars/avatar-pat-male.png")
                                  : require(user.gender === "female"
                                      ? "../../../assets/img/avatars/avatar-pat-female.png"
                                      : "../../../assets/img/avatars/avatar-pat-male.png")
                              }
                            />
                          </center>
                        </div>
                        {selectedItem != null ? (
                          <div className="cont-r2">
                            {" "}
                            <h6 className="font-blue-r">Nota de enfermeria</h6>
                            <h6 className="font-blue-l">
                              {selectedItem.nursingNotes}
                            </h6>{" "}
                            <hr />
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-2 pb-3">
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  patientID: state.profile.searchPatientID,
  consultas: state.history.queryHistory.items,
  selectedItem: state.history.queryHistory.selectedItem,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getQueryHistory, SelectItemHistory })(
  NursingNotes
);
