import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import FormLogin from '../../Components/FormLogin/FormLogin';
import Navbar from '../../Components/Navbar';
import FooterLogin from './FooterLogin';

const Login = ({isAuth}) => {
  if (isAuth) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <>
      <Navbar image={(
        <img
          src={require('../../assets/img/foto-fondo-gris.png')}
          alt="fondo-gris"
          className="img-fluid imga d-none d-sm-none d-md-block"
        />
      )}
      />
      <FormLogin />
      <FooterLogin />
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});
export default connect(mapStateToProps)(Login);
