/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import axios from 'axios';
import { GET_STATUS_TIME_DOCTOR, DISABLE_LOADED_STATUS_TIME } from '../types';

export const getStatusTimeDoctor = (week, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({
    week,
  });

  try {
    const res = await axios.post(`/api/time/status/${id}`, body, config);
    dispatch({
      type: GET_STATUS_TIME_DOCTOR,
      payload: res.data,
    });
  } catch (err) {
    console.log({ status: 'al parecer hay error', error: err });
  }
};
export const disableLoadedStatusTime = () => (dispatch) => {
  dispatch({
    type: DISABLE_LOADED_STATUS_TIME,
    payload: false,
  });
};
