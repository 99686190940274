import React from 'react';
import { Link } from 'react-router-dom';

function SelectUser() {
  return (
    <div>
      <section className="hero-section" id="hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-7 ">
                  <div className="main-div">
                    <img
                      src={require('../assets/img/loginlogo.png')}
                      alt="loginlogo"
                      className="img-fluid loginlogo"
                      data-aos="fade-right"
                    />
                    <div className="panel">
                      <h6 className="center mg-5">
                        Selecciona tu tipo de registro
                      </h6>
                    </div>
                    <form id="Login">
                      <Link
                        to="/registerDoctor"
                        className="btn btn-primary"
                        role="button"
                        aria-disabled="true"
                      >
                        DOCTOR
                      </Link>
                      <br />
                      <Link
                        to="/registerPacient"
                        className="btn btn-primary"
                        role="button"
                        aria-disabled="true"
                      >
                        PACIENTE
                      </Link>
                    </form>
                  </div>
                </div>
                <div className="col-lg-5 text-center text-lg-right mt-20">
                  <h5 data-aos="fade-right">¿Cómo Empezar?</h5>
                  <p
                    className="mb-5"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    Registrate en nuestra plataforma y comienza a recibir
                    orientación médica en segundos, con Médicos Generales,
                    Médicos Especialistas, Acreditados y Certificado
                  </p>
                  <img
                    src={require('../assets/img/medico2.png')}
                    alt="loginlogo"
                    className="img-med2 img-fluid"
                    data-aos="fade-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SelectUser;
