import { ADD_PRESENTIAL_PHYSICAL } from '../types';

const initialState = {
  exams: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_PRESENTIAL_PHYSICAL:
      return {
        ...state,
        exams: payload,
      };
    default:
      return state;
  }
}
