import {
  ADD_VERIFY_CONSULT,
  END_LOADING_VERIFY_CONSULT,
  LOADING_VERIFY_CONSULT,
  REMOVE_VERIFY_CONSULT,
  ADD_VIDEOCONFERENCE,
  DELETE_VIDEOCONFERENCE,
} from '../types';

const initialState = {
  videoconference: null,
  verifyVideo: undefined,
  loadingVerifyConsult: true,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ADD_VIDEOCONFERENCE:
    case DELETE_VIDEOCONFERENCE:
      return {
        ...state,
        videoconference: payload,
      };
    case ADD_VERIFY_CONSULT:
      return {
        ...state,
        verifyVideo: payload,
      };
    case LOADING_VERIFY_CONSULT:
      return {
        ...state,
        loadingVerifyConsult: true,
      };
    case END_LOADING_VERIFY_CONSULT:
      return {
        ...state,
        loadingVerifyConsult: false,
      };
    case REMOVE_VERIFY_CONSULT:
      return {
        ...state,
        verifyVideo: undefined,
      };
    default:
      return state;
  }
};
