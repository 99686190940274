export const tipo_sangres = [
  { name: 'A Positivo', value: 'A+' },
  { name: 'A Negativo', value: 'A-' },
  { name: 'B Positivo', value: 'B+' },
  { name: 'B Negativo', value: 'B-' },
  { name: 'O Positivo', value: 'O+' },
  { name: 'O Negativo', value: 'O-' },
  { name: 'AB Positivo', value: 'AB+' },
  { name: 'AB Negativo', value: 'AB-' },
];
