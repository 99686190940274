import React from 'react';

const CardUpcomingScheduling = ({ lastName, date, image }) => (
  <>
    {/* Start next date */}
    {/* Web View */}
    <div className="d-none d-sm-block">
      <div className="list-group m-0">
        <div className="list-group-item border-top-0 border-right-0 border-left-0">
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex">
              <img
                className="img-fluid rounded-circle"
                style={{ width: '60px', height: '60px' }}
                src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
                alt="Perfil"
                />
              <h6 className="text-left mb-1 ml-3 text-secondarymdv mt-2">
                Cita Médica con Dr.
                {' '}
                {lastName}
                <br />
                <small className="text-black-50">
                  {date}
                </small>
              </h6>
            </div>
            <small className="mt-2 font-weight-bold">
              {' '}
              <i
                className="far fa-calendar-alt mr-2"
                aria-hidden="true"
                />
              Pendiente
            </small>
          </div>
        </div>
      </div>
    </div>
    {/* Mobile View */}
    <div className="d-block d-sm-none">
      <div className="list-group m-0">
        <div className="list-group-item border-top-0 border-right-0 border-left-0">
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex">
              <img
                className="img-fluid rounded-circle"
                style={{ width: '40px', height: '40px' }}
                src={image || require('../../assets/img/avatars/avatar-doc-male.png')}
                alt="Perfil"
                />
              <small className="text-left mb-1 ml-2 text-secondarymdv">
                Cita Médica con Dr.
                {' '}
                {lastName}
                <br />
                <small className="text-black-50">
                  {date}
                </small>
              </small>
            </div>
            <small>
              <span className="badge bg-primarymdv">
                Pendiente
              </span>
            </small>
          </div>
        </div>
      </div>
    </div>
    {/* Fin Mobile View */}
  </>
);

export default CardUpcomingScheduling;
