/* eslint-disable comma-dangle */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
import axios from 'axios';
import toast from 'toastr';
import {
  DELETE_NOTIFICATION,
  READ_NOTIFICATION,
  GET_NOTIFICATION,
  ADD_NOTIFICATION,
} from '../types';

import {sendNotification} from '../../websokect/notificationsWebsocket';

export const getNotifications = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/notifications');
    dispatch({
      type: GET_NOTIFICATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// agregar nueva anotificacion
export const addNotification = ({
  title, message, schedule_ref, send_to, types
}) => async () => {
  const body = JSON.stringify({
    title: title,
    message: message,
    types: types,
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const notification = await axios.post(
      `/api/notifications/${send_to}/${schedule_ref}`,
      body,
      config
    );

    // socket actualizar en tiempo real las notificaciones del usuario
    sendNotification(notification.data);
  } catch (error) {
    console.log(error);
  }
};
export const deleteNotification = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/notifications/${id}`);
    dispatch({
      type: DELETE_NOTIFICATION,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateReadNotification = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/notifications/${id}`);
    dispatch({
      type: READ_NOTIFICATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// notification by socket
export const addSocketNotification = (notification) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
    const user = await axios.get(`/api/users/${notification.create_by}`);

    if (user?.data.role === 'patient') {
      if (notification.types === 'addSchedule') {
        toast.success(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} quiere agendar una cita contigo`,
          'Nueva cita médica'
        );
      } else if (notification.types === 'reSchedule') {
        toast.info(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha reagendado una cita contigo`,
          'Reagendar cita médica'
        );
      } else if (notification.types === 'deleteSchedule') {
        toast.error(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha eliminado una cita `,
          'Eliminación cita médica'
        );
      } else if (notification.types === 'rejectSchedule') {
        toast.error(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha rechazado una cita contigo`,
          'Cita actualizada'
        );
      } else if (notification.types === 'agreeSchedule') {
        toast.success(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha aceptado tu cita`,
          'Cita actualizada'
        );
      } else if (notification.types === 'cancelSchedule') {
        toast.error(
          `${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha cancelado tu cita`,
          'Cita cancelada'
        );
      }
    } else if (user?.data.role === 'doctor') {
      if (notification.types === 'addSchedule') {
        toast.success(
          `${
            user.data.gender === 'male' ? 'Dr. ' : 'Dra.'
          } ${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha agregado una nueva cita contigo`,
          'Cita médica'
        );
      } else if (notification.types === 'deleteSchedule') {
        toast.error(
          `${
            user.data.gender === 'male' ? 'Dr. ' : 'Dra.'
          } ${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha eliminado una cita contigo`,
          'Cita médica'
        );
      } else if (notification.types === 'rejectSchedule') {
        toast.error(
          `${
            user.data.gender === 'male' ? 'Dr. ' : 'Dra.'
          } ${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha rechazado tu cita`,
          'Cita medica rechazada'
        );
      } else if (notification.types === 'agreeSchedule') {
        toast.success(
          `${
            user.data.gender === 'male' ? 'Dr. ' : 'Dra.'
          } ${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha aceptado tu cita medica`,
          'Cita actualizada'
        );
      } else if (notification.types === 'cancelSchedule') {
        toast.error(
          `${
            user.data.gender === 'male' ? 'Dr. ' : 'Dra.'
          } ${user.data.firstName.split(' ', 1)} ${user.data.lastName.split(
            ' ',
            1
          )} ha rechazado tu cita medica`,
          'Cita rechazada'
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};
