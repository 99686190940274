/* eslint-disable global-require */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { usePackage } from './ListEmployees/state/usePackages';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import 'moment/locale/es';
import toastr from 'toastr';
import { AiFillDelete } from 'react-icons/all';
import { IconContext } from 'react-icons';
import CardModal from '../Patient/History/CardModal';
import ResumenModal from '../Payment/PaymentResumeModal';
import { downAllEmployees } from '../../redux/actions/auth.action';
import {
  setDefaultCreditCard,
  deleteCreditCard,
} from '../../redux/actions/credit.card.action';
import visaImg from '../../assets/img/visa.svg';
import mastercardImg from '../../assets/img/mastercard.svg';
import { getProfile } from '../../redux/actions/profile.action';
import {
  payCreditCardPackageEnterprise,
  getKielsaCash,
} from '../../api/payment';
import '../../stylesheets/credit.cards.css';
import '../../stylesheets/consultations.css';
import createAlert from '../../redux/actions/alert.action';
import { useHistory } from 'react-router-dom';

function EnterpriseInvoice({
  cards,
  setDefaultCreditCard,
  deleteCreditCard,
  token,
  selection,
  downAllEmployees,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [openResumeModal, setOpenResumenModal] = useState(false);
  const [section] = useState('credit');
  const [loadingPay, setLoadinPay] = useState(false);
  const [, setAmountKielsa] = useState(0);

  let historyDom = useHistory();

  useEffect(() => {
    if (selection.length === 0) {
      historyDom.push('/dashboard/manage-employees');
    }
  }, [historyDom, selection]);

  useEffect(() => {
    getKielsaCash({ token }).then((res) => {
      const { monto } = res;
      setAmountKielsa(monto);
    });
  }, [token]);

  const handleCheckChange = (id) => {
    setDefaultCreditCard(id);
  };

  const handlePayCredit = () => {
    if (section === 'credit') {
      setLoadinPay(true);
      payCreditCardPackageEnterprise({
        token: token,
        employees: selection,
        total: sumDatos(),
      })
        .then(() => {
          downAllEmployees();
          createAlert(
            '¡Completado!',
            'Se realizo el pago con éxito.',
            'success'
          );
          setLoadinPay(false);
          historyDom.push('/dashboard/manage-employees');
        })
        .catch((error) => {
          setLoadinPay(false);
          console.log('error', error);
          if (error.info) {
            toastr.error(error.info);
          } else {
            setLoadinPay(false);
            createAlert(
              '¡Error!',
              'Lo sentimos, no se pudo efectuar la transacción.',
              'error'
            );
          }
          setLoadinPay(false);
        });
    } else {
      setLoadinPay(false);
      createAlert('¡Error!', 'Pago no valido', 'error');
    }
  };
  const { packages } = usePackage();

  const sumDatos = () => {
    var total = 0;
    for (var i = 0; i < selection.length; i++) {
      total += getPrice(selection[i].idPackage);
    }
    return total;
  };

  const getPrice = (id) => {
    var price = 0;
    for (var i = 0; i < packages.length; i++) {
      if (id === packages[i]._id) {
        price = packages[i].price;
      }
    }
    return price;
  };
  return (
    <div>
      <CardModal open={openModal} setOpen={setOpenModal} />
      <ResumenModal open={openResumeModal} setOpen={setOpenResumenModal} />
      <div className='container-fluid'>
        <div className='p-2'>
          {/* Web View */}
          <div className='d-none d-sm-block'>
            <h2 className='text-primary mt-5 mb-4'>Resumen de compra</h2>
          </div>
          {/* Fin Web View */}
          {/* Mobile View */}
          <div className='d-block d-sm-none text-center'>
            <h5 className='text-primary mt-5 mb-4 font-weight-bold'>
              Resumen de compra
            </h5>
          </div>
          {/* Fin Mobile View */}
          <div className='row'>
            <div className='col-12 col-md-12 col-sm-12 col-xs-12 mb-3 col-lg-8'>
              <div className='sales p-0'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 className='font-weight-bold text-white title-plan-payment'>
                    Plan Empresarial
                  </h4>
                  <small className='ml-auto pt-3 pr-3'>
                    Fecha de compra
                    <br />
                    {moment().locale('es').format('L')}
                  </small>
                </div>
                <div className='row p-3 mt-3'>
                  <div className='col-lg-12'>
                    <div className='base-structure'>
                      <h5 className='text-secondarymdv'>No de empleados</h5>
                      <h5 className='text-secondarymdv'>Total a pagar</h5>
                    </div>
                    <div className='scroll-table-payment'>
                      <div className='item-table border-0 bg-light shadow-none'>
                        <div className='base-structure'>
                          <div className='text-dark'>{selection.length}</div>
                          <div className='text-dark'>
                            <h5>L. {sumDatos()}.00 </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-12 col-sm-12 col-xs-12 col-lg-4 mb-3'>
              <h4 className='text-primary font-weight-bold'>Método de pago</h4>
              {section === 'credit' && (
                <>
                  {/* Start credit card info */}
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h5 className='text-secondarymdv mt-4 mb-3 font-weight-bold'>
                      Seleccionar tarjeta
                      <br />
                      <small>Crédito/Débito</small>
                    </h5>
                    <button
                      type='button'
                      className='btn btn-info'
                      data-target='#exampleModal'
                      data-whatever='@getbootstrap'
                      style={{ borderRadius: '0.8rem' }}
                      onClick={() => setOpenModal(true)}
                    >
                      Añadir Nueva Tarjeta
                    </button>
                  </div>
                  {cards.length > 0 ? (
                    cards.map((item) => (
                      <>
                        <div
                          key={item._id}
                          className={`credit-card-container bg-white border ${
                            item.selected ? 'credit-card-selected' : null
                          }`}
                        >
                          <img
                            src={item.type === 'visa' ? visaImg : mastercardImg}
                            alt='card'
                            className='credit-card-image'
                          />
                          <h6 className='card-item-name text-primary text-uppercase'>
                            {item.name}
                          </h6>
                          <p className='card-item-prefix'>{`termina en   *** ${item.numberPrefix}`}</p>
                          <label
                            className='checkbox-card-item'
                            id='card-item-checkbox'
                          >
                            <input
                              type='checkbox'
                              checked={item.selected}
                              onChange={() => handleCheckChange(item._id)}
                            />
                          </label>
                          <IconContext.Provider
                            value={{
                              size: '20px',
                              color: 'red',
                            }}
                          >
                            <AiFillDelete
                              onClick={() => deleteCreditCard(item._id)}
                            />
                          </IconContext.Provider>
                        </div>
                      </>
                    ))
                  ) : (
                    <p className='text-center p-5'>
                      No tienes agregado ningún método de pago.
                    </p>
                  )}
                </>
              )}
              {section === 'credit' && (
                <>
                  {loadingPay ? (
                    <div className='text-center mt-3'>
                      <Spinner
                        animation='border'
                        role='status'
                        className='text-secondarymdv'
                      >
                        <span className='sr-only'>Loading ...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <button
                      className='btn btn-primary mt-4 mb-4 f-right'
                      type='button'
                      onClick={() => {
                        if (section === 'credit') {
                          handlePayCredit();
                        }
                      }}
                      disabled={cards.length === 0 && sumDatos() !== 0}
                    >
                      <img
                        src={require('../../assets/img/vectores/cart-add.svg')}
                        alt='shopping plan'
                        width={28}
                        loading='lazy'
                        className='img-fluid'
                      />{' '}
                      Realizar Pago
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  selection: state.enterprise.selectedEmployee,
  shopping: state.shopping,
  cards: state.creditCards.items,
  packagektm: state.packagektm.packageKtm,
  telemedicinePlan: state.telemedicinePlan.telemedicinePlan,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  setDefaultCreditCard,
  deleteCreditCard,
  downAllEmployees,
  getProfile,
})(EnterpriseInvoice);
