import {ADD_PACKAGEKTM, REMOVE_PACKAGEKTM} from '../types';

const initialState = {
  packageKtm: localStorage.getItem('packagektm')
    ? JSON.parse(localStorage.getItem('packagektm')) : undefined,
};

export default (state = initialState, action) => {
  const {payload, type} = action;
  switch (type) {
    case ADD_PACKAGEKTM:
      localStorage.setItem('packagektm', JSON.stringify(payload));
      return {
        ...state,
        packageKtm: payload,
      };
    case REMOVE_PACKAGEKTM:
      localStorage.removeItem('packagektm');
      return {
        ...state,
        packageKtm: undefined,
      };
    default:
      return state;
  }
};
