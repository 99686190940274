/* eslint-disable import/prefer-default-export */

export const banks = [
  { name: 'Banco Atlantida', imgUri: 'atlantida-logo.svg' },
  { name: 'Banco Bac Honduras', imgUri: 'bac-logo.png' },
  { name: 'Banco Azteca', imgUri: 'azteca-logo.png' },
  { name: 'Banco Banadesa', imgUri: 'banadesa-logo.jpeg' },
  { name: 'Banco Banrural', imgUri: 'banrural-logo.png' },
  { name: 'Banco Central de Honduras', imgUri: 'banco-central-logo.png' },
  { name: 'Banco Davivienda', imgUri: 'davivienda-logo.png' },
  { name: 'Banco de los Trabajadores', imgUri: 'trabajadores-logo.png' },
  { name: 'Banco de Occidente', imgUri: 'occidente-logo.png' },
  { name: 'Banco del Pais', imgUri: 'banpais-logo.png' },
  { name: 'Banco Ficensa', imgUri: 'ficensa-logo.png' },
  { name: 'Banco Ficohsa', imgUri: 'ficohsa-logo.png' },
  { name: 'Banco Hondureño del Cafe', imgUri: 'banhcafe-logo.png' },
  { name: 'Banco Lafise', imgUri: 'lafise-logo.png' },
  { name: 'Banco Popular', imgUri: 'popular-logo.png' },
  { name: 'Banco Promerica', imgUri: 'promerica-logo.png' },
];
