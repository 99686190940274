import socket from './socket';

export const sendMessage = ({
  idVideo, message, type, sendUser,
  idFile, uniqueNameFile, nameFile,
}, callback) => {
  socket.emit('sendMessage', {
    idVideo,
    message,
    type,
    sendUser,
    idFile,
    uniqueNameFile,
    nameFile,
  }, callback);
};

export const observerMessagePatient = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/patient`, callback);
};

export const observerMessageDoctor = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/doctor`, callback);
};

export const observerEndConsult = ({ idVideo }, callback) => {
  socket.on(`${idVideo}/endCall`, callback);
}

export const endChatConsult = ({ idVideo }, callback) => {
  socket.emit('endChatConsult', { idVideo }, callback);
};
