import React from 'react';

function PaySuccess() {
  return (
    <div>
      <div className="container-fluid display-table">
        <div className="col-md-12 col-sm-12 display-table-cell v-align">
          <div className="user-dashboard">
            <div className="sales">
              <div className="row center-div center">
                <h1>Tu pago se realizó correctamente</h1>
                <h3 className="padding3">
                  Tu número de referencia es:
                  <br />
                  9328587
                </h3>
                <i className="far fa-check-circle i-success" />
                <h3>
                  Monto L.300.00
                  <br />
                  debitado de tu tarjeta
                </h3>
                <div className="padding3">
                  <img
                    src={require('../../assets/img/visa.png')}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '100px' }}
                  />
                  <span className="font-blue">Andrea López</span>
                  <br />
                  <span>XXXX-XXXX-XXXX</span>
                </div>
                <button type="button" className="btn btn-primary3">
                  Guardar y Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaySuccess;
