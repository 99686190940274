import React from 'react';
import Avatar from 'react-avatar';

import { Modal, Button, Form, Col, Row, Container } from 'react-bootstrap';
import useJoinEmployeeState from './state/useJoinEmployeeState';

const JoinEmployeeToCompany = ({ open, handleOpenModal }) => {
  const {
    handleChange,
    dni,
    handleSubmit,
    user,
    isRegister,
    handleAddEmployee,
  } = useJoinEmployeeState();
  return (
    <Modal show={open} onHide={handleOpenModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Buscar un empleado existente</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 40 }}>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Ingrese número de documento (sin guiones)</Form.Label>
            <Form.Control
              value={dni}
              onChange={handleChange}
              name="dni"
              required
            />
            {isRegister && (
              <Row
                style={{
                  display: 'flex',
                  color: 'red',
                  fontWeight: 600,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                }}
              >
                El usuario no se encuentra registrado
              </Row>
            )}
          </Form.Group>
        </Form>

        {user && (
          <Container style={{ display: 'flex', flexDirection: 'row' }}>
            <Col xs={3}>
              <Avatar round src={`/api/profile/image/${user.image}`} />
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Row>
                <Col
                  xs={12}
                  style={{ color: '#000', fontWeight: '600', fontSize: 17 }}
                >{`${user?.firstName} ${user?.lastName}`}</Col>
                <Col xs={12}>{user.email}</Col>
                <Col xs={12}>{user.dni}</Col>
              </Row>
            </Col>
          </Container>
        )}
      </Modal.Body>

      <Modal.Footer>
        {!user && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={dni === ''}
          >
            Buscar
          </Button>
        )}
        {user && (
          <Button variant="primary" onClick={handleAddEmployee}>
            Añadir a la empresa
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default JoinEmployeeToCompany;
