/* eslint-disable func-names */
import {
  OPEN_MODAL_SCHEDULE_DOCTOR_LIST,
  CLOSE_MODAL_SCHEDULE_DOCTOR_LIST,
  CLOSE_MODAL_ADD_SCHEDULING,
  OPEN_MODAL_ADD_SCHEDULING,
  OPEN_MODAL_CANCEL_APPOINTMENT,
  CLOSE_MODAL_CANCEL_APPOINTMENT,
  OPEN_MODAL_REAGENDAR,
  CLOSE_MODAL_REAGENDAR,
  OPEN_MODAL_SCHEDULING_DETAILS,
  CLOSE_MODAL_SCHEDULING_DETAILS,
  OPEN_MODAL_PROFILE_IMAGE,
  CLOSE_MODAL_PROFILE_IMAGE,
  OPEN_MODAL_PHYSICAL_EXAM,
  SET_MODAL_CARNET,
  SET_MODAL_SEARCH_PATIENT,
  OPEN_MODAL_CALLCENTER_PLAN,
  HANDLE_CHANGE_FOLLOW_UP_MODAL,
} from '../types';

const initialState = {
  doctorModalList: {
    open: false,
  },
  schedulingModal: {
    open: false,
  },
  modalCancelAppointment: {
    open: false,
  },
  modalReagendar: {
    open: false,
  },
  modalScheduleDetails: {
    open: false,
  },
  modalProfileImage: {
    open: false,
  },
  modalPhysicalExam: false,
  modalSearchPatient: false,
  modalCarnet: false,
  modalCallCenterPlan: false,
  modalFollowUpAppointment: false,
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPEN_MODAL_SCHEDULE_DOCTOR_LIST:
      return {
        ...state,
        doctorModalList: {
          ...state.doctorModalList,
          open: payload,
        },
      };
    case HANDLE_CHANGE_FOLLOW_UP_MODAL:
      return {
        ...state,
        modalFollowUpAppointment: payload,
      };
    case OPEN_MODAL_CALLCENTER_PLAN:
      return {
        ...state,
        modalCallCenterPlan: payload,
      };
    case SET_MODAL_CARNET:
      return {
        ...state,
        modalCarnet: payload,
      };

    case SET_MODAL_SEARCH_PATIENT:
      return {
        ...state,
        modalSearchPatient: payload,
      };
    case CLOSE_MODAL_SCHEDULE_DOCTOR_LIST:
      return {
        ...state,
        doctorModalList: {
          ...state.doctorModalList,
          open: payload,
        },
      };
    case OPEN_MODAL_ADD_SCHEDULING:
      return {
        ...state,
        schedulingModal: {
          ...state.schedulingModal,
          open: payload,
        },
      };
    case CLOSE_MODAL_ADD_SCHEDULING:
      return {
        ...state,
        schedulingModal: {
          ...state.schedulingModal,
          open: payload,
        },
      };
    case OPEN_MODAL_CANCEL_APPOINTMENT:
      return {
        ...state,
        modalCancelAppointment: {
          ...state.modalCancelAppointment,
          open: payload,
        },
      };
    case CLOSE_MODAL_CANCEL_APPOINTMENT:
      return {
        ...state,
        modalCancelAppointment: {
          ...state.modalCancelAppointment,
          open: payload,
        },
      };
    case OPEN_MODAL_REAGENDAR:
      return {
        ...state,
        modalReagendar: {
          ...state.modalReagendar,
          open: payload,
        },
      };
    case CLOSE_MODAL_REAGENDAR:
      return {
        ...state,
        modalReagendar: {
          ...state.modalReagendar,
          open: payload,
        },
      };
    case OPEN_MODAL_SCHEDULING_DETAILS:
      return {
        ...state,
        modalScheduleDetails: {
          ...state.modalScheduleDetails,
          open: payload,
        },
      };
    case CLOSE_MODAL_SCHEDULING_DETAILS:
      return {
        ...state,
        modalScheduleDetails: {
          ...state.modalScheduleDetails,
          open: payload,
        },
      };
    case OPEN_MODAL_PROFILE_IMAGE:
    case CLOSE_MODAL_PROFILE_IMAGE:
      return {
        ...state,
        modalProfileImage: {
          open: payload,
        },
      };
    case OPEN_MODAL_PHYSICAL_EXAM:
      return {
        ...state,
        modalPhysicalExam: payload,
      };

    default:
      return state;
  }
}
