/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import jspdf from 'jspdf';
import { toPng } from 'html-to-image';
import {
  getGraphicRating,
  getQueryDetail,
  addSelectItem,
  getdetailHistory,
} from '../../redux/actions/graphic.action';
import createAlert from '../../redux/actions/alert.action';

function Medicaldetails({
  rating,
  detail,
  role,
  consultas,
  getGraphicRating,
  getQueryDetail,
  addSelectItem,
  getdetailHistory,
}) {
  const [formData, setFormData] = useState({
    desde: new Date().toISOString().slice(0, 10),
    hasta: new Date().toISOString().slice(0, 10),
  });
  const [desc, setDesc] = useState(true);
  const [numeroConsulta, setNumero] = useState(0);
  const firstName =
    detail.selectedItem === null ? '' : detail.selectedItem.profile.firstName;
  const lastName =
    detail.selectedItem === null ? '' : detail.selectedItem.profile.lastName;
  const fecha =
    detail.selectedItem === null ? '' : detail.selectedItem.createdAt;
  /*const hora =
    detail?.selectedItem?.hour === null
      ? detail.selectedItem.createdAt.slice(11, 16)
      : detail.selectedItem.hour;*/
  const motivo = detail.selectedItem === null ? '' : detail.selectedItem.reason;
  const diagnostico =
    detail.selectedItem === null ? '' : detail.selectedItem.diagnosis;
  const cita = detail.selectedItem === null ? '' : detail.selectedItem.cita;
  const NurseNotes =
    detail.selectedItem === null ? '' : detail.selectedItem.nursingNotes;
  const indicaciones =
    detail.selectedItem === null ? '' : detail.selectedItem.indications;
  let lunes = 0;
  let martes = 0;
  let miercoles = 0;
  let jueves = 0;
  let viernes = 0;
  let sabado = 0;
  let domingo = 0;

  const { desde, hasta } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (desde <= hasta) {
      getGraphicRating(desde, hasta);
      getQueryDetail(desde, hasta, role);
    } else {
      createAlert(
        '¡Error!',
        'La fecha final debe ser mayor o igual a la inicial.',
        'error'
      );
    }
  };

  rating.data.forEach((element) => {
    if (element._id === 1) {
      domingo = element.totalRating / element.count;
    }
    if (element._id === 2) {
      lunes = element.totalRating / element.count;
    }
    if (element._id === 3) {
      martes = element.totalRating / element.count;
    }
    if (element._id === 4) {
      miercoles = element.totalRating / element.count;
    }
    if (element._id === 5) {
      jueves = element.totalRating / element.count;
    }
    if (element._id === 6) {
      viernes = element.totalRating / element.count;
    }
    if (element._id === 7) {
      sabado = element.totalRating / element.count;
    }
  });
  const data = {
    labels: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    datasets: [
      {
        label: 'Rating',
        data: [domingo, lunes, martes, miercoles, jueves, viernes, sabado],
        fill: false,
        backgroundColor: 'rgb(5, 17, 17)',
        borderColor: 'rgb(146, 43, 33)',
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const showData = (index, id) => {
    setNumero(index + 1);
    setDesc(false);
    addSelectItem(detail.data[index]);
    getdetailHistory(id);
  };

  const downloadHEA = () => {
    toPng(document.getElementById('descargarHEA'))
      .then((dataUrl) => {
        const doc = new jspdf();
        doc.addImage(dataUrl, 'PNG', 15, 40, 180, 220);
        doc.save('Resumen.pdf');
      })
      .catch((error) => {
        console.log(error);
        createAlert(
          '¡Error!',
          'Lo sentimos ocurrio un error al descargar el HEA.',
          'error'
        );
      });
  };

  return (
    <div>
      <div className='container-fluid'>
        <div className='user-dashboard'>
          <div className='row mt-4'>
            <div className='col-12 col-md-8 col-sm-12 col-xs-12 gutter'>
              <div className='sales scroll-doctor'>
                <div className='row mb-4 mt-2'>
                  <div className='col-12 col-md-5 mb-2'>
                    <div className='d-flex'>
                      <label className='text-black-50'>Desde: </label>
                      <input
                        type='date'
                        name='desde'
                        value={desde}
                        className='form-control'
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-5 mb-2'>
                    <div className='d-flex'>
                      <label className='text-black-50'>Hasta: </label>
                      <input
                        type='date'
                        name='hasta'
                        className='form-control'
                        value={hasta}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-2 mb-2'>
                    <div className='d-flex'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-block'
                        onClick={handleSubmit}
                        style={{ height: '33px' }}
                      >
                        Ver
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='table-responsive container'>
                    <table className='table table-light table-borderless table-striped'>
                      <thead className='font-weight-bold bg-primarymdv text-center'>
                        <tr>
                          <th className='title-header-table2'>Fecha</th>
                          <th className='title-header-table2'># de consulta</th>
                          <th className='title-header-table2'>Paciente</th>
                          <th className='title-header-table2'>
                            Tipo de llamada
                          </th>
                          <th scope='col' className='title-header-table2'>
                            Ver
                          </th>
                        </tr>
                      </thead>
                      <tfoot />
                      <tbody className='text-center text-secondarymdv'>
                        {detail.data.map((item, index) => (
                          <tr>
                            <td>{item.createdAt.slice(0, 10)}</td>
                            <td>{index + 1}</td>
                            <td>
                              {`${item.profile.firstName} ${item.profile.lastName}`}
                            </td>
                            <td>{item.type}</td>
                            <td>
                              <button
                                onClick={(e) => {
                                  showData(index, item.patient);
                                }}
                                className='btn btn-info btn-sm rounded-pill'
                                type='button'
                              >
                                <i className='fas fa-eye' aria-hidden='true' />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {role === 'doctor' ? (
                <div className='sales'>
                  <Line data={data} options={options} />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className='col-md-4 col-sm-12 col-xs-12 gutter'>
              <div className='bg-detail'>
                <div className='sales'>
                  <div className='row mar-2'>
                    <div className='card-r' id='descargarHEA'>
                      <div className='card-body bbbb'>
                        <h5 className='white center'>Resumen de Consulta</h5>
                        {numeroConsulta > 0 ? (
                          <h4 className='white center'>#{numeroConsulta}</h4>
                        ) : (
                          <h4 />
                        )}

                        <h6 className='white center'>
                          Paciente {`${firstName} ${lastName}`}
                        </h6>
                        <center className='m-t-30'>
                          {' '}
                          <img
                            className='img-circle-2 img-fluid'
                            src={
                              detail?.selectedItem?.profile?.image
                                ? `/api/profile/image/${detail.selectedItem.profile.image}`
                                : require('../../assets/img/avatars/avatar-pat-female.png')
                            }
                            alt='Profile'
                          />
                          <div className='row text-center justify-content-md-center'>
                            <div className='col-4'>
                              <font className='font-medium'>
                                {fecha.slice(0, 10)}
                              </font>
                            </div>
                            <div className='col-4'>
                              <font className='font-medium'>
                                {detail?.selectedItem?.hour
                                  ? detail.selectedItem.hour
                                  : fecha.slice(11, 16)}
                              </font>
                            </div>
                          </div>
                        </center>
                      </div>
                      <div className='cont-r'>
                        {' '}
                        <h5 className='font-blue-r'>Motivo</h5>
                        <h5 className='font-blue-l'>{motivo}</h5> <hr />
                        <h5 className='font-blue-r'>HEA</h5>
                        <h5 className='font-blue-l'>
                          {detail?.selectedItem?.hea}
                        </h5>{' '}
                        <hr />
                        <h5 className='font-blue-r'>Examen Físico</h5>
                        {detail?.selectedItem?.physicalTest?.map?.((test) =>
                          test.description ? (
                            <>
                              {test.label}
                              <h5 className='font-blue-l'>
                                {test.description}
                              </h5>
                            </>
                          ) : (
                            <></>
                          )
                        )}{' '}
                        <hr />
                        <h5 className='font-blue-r'>Su Diagnóstico</h5>
                        <h5 className='font-blue-l'>{diagnostico}</h5>
                        <hr />
                        <h5 className='font-blue-r'>Indicaciones Médicas</h5>
                        <h5 className='font-blue-l'>
                          {indicaciones.length > 0 ? (
                            <td>
                              {indicaciones.map((item) => `${item.medicine}, `)}
                            </td>
                          ) : (
                            <td />
                          )}
                        </h5>
                        <hr />
                        <h5 className='font-blue-r'>Próxima Cita</h5>
                        <h5 className='font-blue-l'>{cita}</h5> <hr />
                        <h5 className='font-blue-r'>Notas de enfermeria</h5>
                        <h5 className='font-blue-l'>{NurseNotes}</h5> <hr />
                      </div>
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary mt-2 btn-sm'
                      data-toggle='modal'
                      data-target='#consultas'
                      disabled={desc}
                    >
                      Historial Médico
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal detalle de consultas */}
      <div
        className='modal fade'
        id='consultas'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable'>
          <div className='modal-content'>
            <div className='modal-header border-bottom-0 mt-2'>
              <h5 className='modal-title text-primary font-weight-bold m-auto'>
                Historial de Consultas
              </h5>

              {/* <button
                type="button"
                className="close text-primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className='modal-body'>
              <h5 className='text-primary font-weight-bold mb-2'>
                Paciente: {`${firstName} ${lastName}`}
              </h5>
              <table className='table table-sm table-borderless'>
                <thead className='bg-light'>
                  <tr className='text-primary text-center'>
                    <th scope='col'>Fecha</th>
                    <th scope='col'>Motivo</th>
                    <th scope='col'>Diagnóstico</th>
                    <th scope='col'>Indicaciones</th>
                    <th scope='col'>Id Preorden</th>
                  </tr>
                </thead>
                <tbody className='text-center text-black-50'>
                  {consultas.data.length > 0 ? (
                    consultas.data.map((consulta) => (
                      <tr>
                        <td>{consulta.createdAt.slice(0, 10)}</td>
                        <td>{consulta.reason}</td>
                        <td>{consulta.diagnosis}</td>
                        {consulta.indications.length > 0 ? (
                          <td>
                            {consulta.indications.map(
                              (item) =>
                                `(${item.medicine},${item.dose},${item.frequency}), `
                            )}
                          </td>
                        ) : (
                          <td />
                        )}
                        <td>{consulta?.preorder?.Preventa_Id}</td>
                      </tr>
                    ))
                  ) : (
                    <tr />
                  )}
                </tbody>
              </table>
            </div>
            <div className='modal-footer mt-2 pb-3'>
              <div className='d-flex'>
                <button
                  type='button'
                  className='btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold'
                  data-dismiss='modal'
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToprops = (state) => ({
  rating: state.graphic.graphicRating,
  detail: state.graphic.queryDetail,
  role: state.auth.user.role,
  consultas: state.graphic.detailHistory,
});

export default connect(mapStateToprops, {
  getGraphicRating,
  getQueryDetail,
  addSelectItem,
  getdetailHistory,
})(Medicaldetails);
