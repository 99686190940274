import axios from 'axios';

export const url = '/api/doctor/office';

export const getDoctorOffice = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => reject(err));
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const getAllOffices = ({ token, name }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(
          `${url}/getAll/${name}`,
          {
            token,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Error en la autenticación del usuario',
      });
    }
  });
