import React,{useEffect} from 'react';
import {useListUpcomingScheduling} from './state/useListUpcomingScheduling';
import { connect } from 'react-redux';
import { upcomingScheduling } from '../../../api/scheduling';
import moment from 'moment';

const NextAppoints = ({auth}) => {
    const {listUpcomingScheduling,setListUpcomingScheduling} = useListUpcomingScheduling();
    
    useEffect(() => {
        const token = auth.token
        upcomingScheduling({ token })
        .then((res) => {
          const { data } = res;
          setListUpcomingScheduling(data);
        })
        .catch((error) => {
          console.log(error);
        });

    }, [])

    return (
        <div className="sales report mt-3 mb-3">
            <div className="row mar-2">
                <h2 className="text-primary">Próximas Citas</h2>
            </div>
                {listUpcomingScheduling.length > 0 ? (
                    listUpcomingScheduling.map(
                    ({ patient, hour, day, month, year }) => (
                    <>
                            <div className="media">
                                <a className="pull-left" href="#">
                                    <img
                                        className="media-object img-circle img-doc-2"
                                        src={
                                        patient.image
                                        ? `/api/profile/image/${patient.image}`
                                        : require('../../../assets/img/avatars/avatar-doc-male.png')
                                    }
                                    />
                                </a>
                                <div className="media-body">
                                    <div className="row margr-2">
                                        <span>
                                            Cita con paciente{' '}
                                            {`${patient.firstName} ${patient.lastName}`}
                                        </span>
                                    </div>
                                    <div className="row margr-2">
                                        <span>
                                            {`${moment(
                                            new Date(year, month - 1, day)
                                            ).format('LL')}, ${hour}`}
                                        </span>
                                    </div>
                              </div>
                              <div className="media-languaje">
                                <div className="row">
                                  <i
                                    className="far fa-calendar-alt i-plus-d"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="i-plus-l">
                                    {' '}
                                    &nbsp; Pendiente{' '}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        )
                      )
                    ) : (
                      <p className="pl-3">No hay Datos...</p>
                )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  export default connect(mapStateToProps, {})(NextAppoints);
  