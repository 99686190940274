import React from 'react';
import AddPhysicalTest from '../Patient/Presential/AddPhysicalTest';

const AddPhysicalTestModal = () => (
  <div
    className="modal fade"
    id="ModalPhysicalTest"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <AddPhysicalTest />
        </div>
      </div>
    </div>
  </div>
);

export default AddPhysicalTestModal;
