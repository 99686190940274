import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { getTelemedicinePlans } from '../../../../api/telemedicine.plan';
import {
  addSelectedPlan,
  deleteReference,
  getPlansReference,
} from '../../../../redux/actions/references';
import { setModalCallCenterPlan } from '../../../../redux/actions/ui.action';
import useCallCenterPlanModalState from '../../CallcenterPlanModal/state/useCallCenterPlanModalState';

const usePlansState = () => {
  const { userRef, plansRef } = useSelector((state) => state.reference);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getTelemedicinePlans().then(({ data }) => {
      dispatch(getPlansReference(data));
    });
  }, []);

  const handleSubmit = (item) => {
    dispatch(addSelectedPlan(item));
    dispatch(setModalCallCenterPlan(true));
  };

  return { userRef, plansRef, handleSubmit, loading, setLoading };
};

export default usePlansState;
