const estilo_vida = [
  { name: 'Duermes durante 7 horas al día', id: 1, check: false },
  { name: 'Haces ejercicio', id: 2, check: false },
  { name: 'Fumas o usas tabaco', id: 3, check: false },
  { name: 'Bebes alcohol', id: 4, check: false },
  { name: 'Utilizas drogas', id: 5, check: false },
  {
    name: 'Trabajas con productos químicos peligrosos o tóxicos',
    id: 6,
    check: false,
  },
];

export default estilo_vida;
