/* eslint-disable func-names */
/* eslint-disable arrow-parens */
import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  READ_NOTIFICATION,
  GET_NOTIFICATION,
} from '../types';

const initialState = {
  items: [],
};
export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        items: payload,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload),
      };
    case READ_NOTIFICATION:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === payload._id) {
            return {
              ...item,
              read: true,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
}
