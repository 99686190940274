import axios from 'axios';
import { ADD_CLINICAL, GET_CLINICAL, DELETE_CLINICAL } from '../types';

export const getclinical = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/clinical/${patient}`);
    dispatch({
      type: GET_CLINICAL,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addclinical = (name, patientID) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const doc = JSON.stringify({
    name: name,
    patientID: patientID
  });

  try {
    const res = await axios.post('/api/patient/history/clinical', doc, config);

    dispatch({
      type: ADD_CLINICAL,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteclinical = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/patient/history/clinical/${id}`);
    dispatch({
      type: DELETE_CLINICAL,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};
