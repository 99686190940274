import React from "react";
import { Link } from "react-router-dom";

function HEA() {
  return (
    <div>
      <div className="container-fluid display-table">
        <div className="col-md-12 col-sm-12 display-table-cell v-align">
          <div className="user-dashboard">
            <h4 className="text-dash">Detalle HEA de consultas</h4>
            <div className="row f-right mar-2">
              <div className="btn-group">
                <button
                  className="btn-call btn-lg dropdown-toggle"
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>Filtrar:</span> Por Fecha
                </button>
                <div className="dropdown-menu">
                  <a href="#">No disponible</a>
                  <a href="#">En Espera</a>
                  <a href="#">De Vacaciones</a>
                </div>
              </div>
            </div>
            <div className="sales">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-hea border-primary mb-3">
                    <div className="card-header">
                      <h6> Diagnostico de la consulta: Gastritis Aguda</h6>
                      <span>21/08/2020</span>
                    </div>
                    <div className="card-body text-primary">
                      <h5 className="card-title"> Enfermedad Actual:</h5>
                      <p className="card-text">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.{" "}
                      </p>
                      <Link href="#" className="btn btn-primary">
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-hea border-primary mb-3">
                    <div className="card-header">
                      <h6> Diagnostico de la consulta: Gastritis Aguda</h6>
                      <span>21/08/2020</span>
                    </div>
                    <div className="card-body text-primary">
                      <h5 className="card-title"> Enfermedad Actual:</h5>
                      <p className="card-text">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.{" "}
                      </p>
                      <Link href="#" className="btn btn-primary">
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card-hea border-primary mb-3">
                    <div className="card-header">
                      <h6> Diagnostico de la consulta: Gastritis Aguda</h6>
                      <span>21/08/2020</span>
                    </div>
                    <div className="card-body text-primary">
                      <h5 className="card-title"> Enfermedad Actual:</h5>
                      <p className="card-text">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.{" "}
                      </p>
                      <Link href="#" className="btn btn-primary">
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  f-right mar-2">
                <button
                  className="btn-call btn-lg"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  data-whatever="@getbootstrap"
                >
                  <span className="font-blue">Agregar nueva HEA</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* start modal card info */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Agregar nueva HEA
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <label for="cardExpiry">
                  <span className="hidden-xs">FECHA</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recipient-name"
                />

                <label for="cardExpiry">
                  <span className="hidden-xs">DIAGNOSTICO DE LA CONSULTA</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recipient-name"
                />

                <div className="form-group">
                  <label for="exampleFormControlTextarea1">
                    ENFERMEDAD ACTUAL
                  </label>
                  <textarea
                    className="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    rows="10"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer center">
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "200px" }}
              >
                GUARDAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HEA;
