/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
import {
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  GET_SCHEDULE,
  SHOW_DETAILS,
  HIDE_DETAILS,
  SET_STATUS_SCHEDULE,
  SELECTED_DATE,
  SOCKET_UPDATE_SCHEDULE,
  SELECT_ONLINE_CONSULTATION,
  SELECT_PRESENTIAL_CONSULTATION,
  REAGENDAR,
} from '../types';

const initialState = {
  items: [],
  loaded: false,
  details: null,
  selectedDate: null,
  presentialConsultation: false,
  onlineConsultation: false,
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_SCHEDULE:
      return {
        ...state,
        items: payload,
        loaded: true,
      };
    case DELETE_SCHEDULE:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== payload),
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        items: [...state.items, payload],
      };
    case SHOW_DETAILS:
    case HIDE_DETAILS:
      return {
        ...state,
        details: payload,
      };
    case SET_STATUS_SCHEDULE:
      return {
        ...state,
        details: {
          ...state.details,
          status: payload.status,
        },
        items: state.items.map((item) => {
          if (item._id === payload._id) {
            return {
              ...item,
              status: payload.status,
            };
          }
          return item;
        }),
      };
    case SELECTED_DATE:
      return {
        ...state,
        selectedDate: payload,
      };
    case SOCKET_UPDATE_SCHEDULE:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === payload.idCita) {
            return {
              ...item,
              status: payload.status,
            };
          }
          return item;
        }),
      };
    case SELECT_PRESENTIAL_CONSULTATION:
      return {
        ...state,
        presentialConsultation: payload,
      };
    case REAGENDAR:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === payload._id) {
            return {
              ...item,
              date: payload.date,
              hour: payload.hour,
              day: payload.day,
              month: payload.month,
              year: payload.year,
              status: payload.status,
            };
          }
          return item;
        }),
      };
    case SELECT_ONLINE_CONSULTATION:
      return {
        ...state,
        onlineConsultation: payload,
      };
    default:
      return state;
  }
}
