import {ADD_PACKAGEKTM, REMOVE_PACKAGEKTM} from '../types';

export const addPackageKtm = (payload) => (dispatch) => dispatch({
  payload: payload,
  type: ADD_PACKAGEKTM,
});

export const removePackageKtm = () => (dispatch) => dispatch({
  type: REMOVE_PACKAGEKTM,
});
