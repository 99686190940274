import axios from 'axios';

export const url = '/api/cashpackagektm';

export const createCashPackageKtm = ({
  idPackageKtm,
  namePatient,
  emailPatient,
  token,
  orderNumber,
  phonePatient,
}) =>
  new Promise((resolve, reject) => {
    if (idPackageKtm && namePatient && emailPatient && token && orderNumber) {
      axios
        .post(
          `${url}/create`,
          {
            idPackageKtm,
            namePatient,
            emailPatient,
            orderNumber,
            phonePatient,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!idPackageKtm) {
      reject({
        status: 'error',
        info: 'Paquete no sta definido',
      });
    } else if (!namePatient) {
      reject({
        status: 'error',
        info: 'Nombre del paciente no definido',
      });
    } else if (!emailPatient) {
      reject({
        status: 'error',
        info: 'Correo del paciente no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const createCashPackageKtmGiftCard = ({
  idPackageKtm,
  namePatient,
  emailPatient,
  token,
  orderNumber,
  phonePatient,
}) =>
  new Promise((resolve, reject) => {
    if (idPackageKtm && namePatient && emailPatient && token && orderNumber) {
      axios
        .post(
          `${url}/create/GiftCard`,
          {
            idPackageKtm,
            namePatient,
            emailPatient,
            orderNumber,
            phonePatient,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!idPackageKtm) {
      reject({
        status: 'error',
        info: 'Paquete no sta definido',
      });
    } else if (!namePatient) {
      reject({
        status: 'error',
        info: 'Nombre del paciente no definido',
      });
    } else if (!emailPatient) {
      reject({
        status: 'error',
        info: 'Correo del paciente no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const patientGetPackageKtm = ({ code, token }) =>
  new Promise((resolve, reject) => {
    if (code && token) {
      axios
        .post(
          `${url}/code/patient`,
          {
            code,
          },
          {
            Authorization: token,
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!code) {
      reject({
        status: 'error',
        info: 'Codigo no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });

export const doctorAsignPatientPackageKTM = ({ token, code, idPatient }) =>
  new Promise((resolve, reject) => {
    if (token && code && idPatient) {
      axios
        .post(
          `${url}/asign/code/patient`,
          {
            code,
            idPatient,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else if (!token) {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    } else if (!code) {
      reject({
        status: 'error',
        info: 'Codigo no definido',
      });
    } else {
      reject({
        status: 'error',
        info: 'Paciente no definido',
      });
    }
  });

export const searchPatientEmail = ({ token, email }) =>
  new Promise((resolve, reject) => {
    if (email && token) {
      axios
        .post(
          `${url}/search/patient/email`,
          { email },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Correo es requerido',
      });
    }
  });

export const getCheckerCashPackage = ({ token }) =>
  new Promise((resolve, reject) => {
    if (token) {
      axios
        .get(`${url}/cash/checker`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => reject(error));
    } else {
      reject({
        status: 'error',
        info: 'Token no definido',
      });
    }
  });
