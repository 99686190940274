import React, { useState, useEffect } from 'react';
import { Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import AddPatientChecker from '../../Components/Checker/AddPatientChecker';
import InforPatient from '../../Components/Checker/InfoPatient';
import useCallCenterState from './state/useCallCenterState';
import PlansContainer from '../../Components/Callcenter/Plans/Plans';
import './styles.css';
import useCallCenterPlanModalState from '../../Components/Callcenter/CallcenterPlanModal/state/useCallCenterPlanModalState';
import CallCenterPlanModal from '../../Components/Callcenter/CallcenterPlanModal/CallCenterPlanModal';

const Callcenter = () => {
  const [loading, setLoading] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);

  const {
    email,
    dni,
    idType,
    searchType,
    cellphone,
    dialCode,
    country,
    handleChange,
    countries,
    userRef,
    addUserFlag,
    handleClickCleanButton,
    handleSubmit,
  } = useCallCenterState();

  const { open } = useCallCenterPlanModalState();

  return (
    <div className='user-dashboard mt-4'>
      {open && <CallCenterPlanModal />}
      <h2 className='text-primary '>
        Agregar planes
        <b className='text-secondarymdv'>{` ${'Telemedicina'}`}</b>
      </h2>
      <div className='row mt-3'>
        <div className='col-md-6 col-xs-12'>
          <div className='sales mb-4'>
            <div className='profile-header'>
              <h3 className='text-white text-center pt-4'>Agregar paciente</h3>
            </div>
            <div className='container mt-3'>
              <form style={{ padding: 20 }} onSubmit={handleSubmit}>
                {searchType === 'cellphone' && (
                  <>
                    <div className='form-group'>
                      <InputGroup>
                        <InputGroup.Prepend
                          value={dialCode}
                          name='dialCode'
                          onChange={handleChange}
                        >
                          <InputGroup.Text
                            style={{
                              borderTopLeftRadius: 20,
                              borderBottomLeftRadius: 20,
                            }}
                          >
                            {dialCode}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          className='form-control'
                          placeholder='Telefono'
                          name='cellphone'
                          value={cellphone}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </div>
                  </>
                )}

                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bold btn-block'
                    onClick={handleSubmit}
                    disabled={
                      (cellphone === '' && searchType === 'cellphone') ||
                      (dni === '' && searchType === 'dni') ||
                      addUserFlag ||
                      userRef
                    }
                    style={{
                      opacity:
                        ((cellphone === '' && searchType === 'cellphone') ||
                          addUserFlag ||
                          userRef) &&
                        '80%',
                      width: 'auto',
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    {loaderButton ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation='border'
                      />
                    ) : (
                      'AGREGAR'
                    )}
                  </button>
                  {(userRef || addUserFlag) && (
                    <button
                      type='button'
                      className='btn btn-primary font-weight-bold btn-block btn btn-danger'
                      style={{
                        width: 'auto',
                        marginTop: 0,
                        margin: 5,
                        backgroundColor: '#FF6E61',
                      }}
                      onClick={handleClickCleanButton}
                    >
                      LIMPIAR
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Mostrando resultado de la busqueda */}
        <div className='col-md-6 col-xs-12'>
          {addUserFlag && (
            <AddPatientChecker
              emailPatient={email}
              dialCodePatient={dialCode}
              cellphonePatient={cellphone}
              idTypePatient={idType}
              dniPatient={dni}
              searchType={searchType}
              ischecker={false}
            />
          )}
          {/* show information of patient */}
          {userRef && <InforPatient userRef={userRef} loading={loading} />}
        </div>
      </div>
      {userRef && (
        <div className='row mt-3 px-3 plans-container'>
          <h2 className='text-primary'>Planes telemedicina</h2>
          <PlansContainer />
        </div>
      )}
    </div>
  );
};

export default Callcenter;
