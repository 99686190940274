import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserByEmailOrCellphoneOrDniByDoctor } from "../../../../redux/actions/auth.action";
import { countries } from "../../../../utils/countries";

export const useSearchModalState = () => {
  const [formData, setFormData] = useState({
    email: "",
    dni: "",
    idType: "national",
    cellphone: "",
    country: "",
    dialCode: countries[0].dial_code,
    searchType: "email",
  });

  const dispatch = useDispatch();

  const clearForm = () => {
    setFormData({
      ...formData,
      email: "",
      dni: "",
      idType: "national",
      cellphone: "",
      country: "",
      dialCode: countries[0].dial_code,
    });
  };

  const reStartStateForm = () => {
    setFormData({
      ...formData,
      email: "",
      dni: "",
      idType: "national",
      cellphone: "",
      country: "",
      dialCode: countries[0].dial_code,
      searchType: "email",
    });
  };

  const setFormat = () => {
    if (idType === "national") {
      return "####-####-#####";
    } else if (idType === "rtn") {
      return "####-####-######";
    }
  };

  const handleChange = (e) => {
    if (e.target.value === "idType") {
      clearForm();
    }

    if (e.target.name === "country") {
      const tempCountry = countries.filter(
        (pais) => pais.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        country: tempCountry[0].name,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchType === "email") {
      dispatch(
        getUserByEmailOrCellphoneOrDniByDoctor({
          email,
        })
      );
    } else if (searchType === "cellphone") {
      dispatch(
        getUserByEmailOrCellphoneOrDniByDoctor({
          dialCode,
          cellphone,
        })
      );
    } else {
      dispatch(
        getUserByEmailOrCellphoneOrDniByDoctor({
          dni: dni.replace(/-/g, "").replace(/_/g, ""),
          idType,
        })
      );
    }
  };

  const { email, dni, idType, searchType, cellphone, dialCode, country } =
    formData;

  return {
    email,
    dni,
    idType,
    searchType,
    cellphone,
    dialCode,
    country,
    setFormat,
    handleChange,
    handleSubmit,
    countries,
    reStartStateForm,
  };
};
