/* eslint-disable func-names */
import {
  GET_PRESENTIAL_USER_REFERENCE,
  DELETE_PRESENTIAL_USER_REFERENCE,
  SET_TRUE_PRESENTIAL_VITAL_SIGNS,
  SET_FALSE_PRESENTIAL_VITAL_SIGNS,
  SET_TRUE_PRESENTIAL_ADD_USER,
  SET_FALSE_PRESENTIAL_ADD_USER,
  SET_TRUE_COMPLETE,
  SET_FALSE_COMPLETE,
  RESET_PRESENTIAL_STATE,
  SET_TRUE_PRESENTIAL_PROFILE,
  SET_FALSE_PRESENTIAL_PROFILE,
  ADD_PRESENTIAL_USER,
  ADD_PRESENTIAL_VITALS,
  DELETE_PRESENTIAL_VITALS,
  TRUE_LOADING_PACKAGE,
  FALSE_LOADING_PACKAGE,
  HANDLE_CHANGE_SERVICE,
} from '../types';

const initialState = {
  user: null,
  service: 'presential',
  vitalFlag: false,
  addUserFlag: false,
  profileFlag: false,
  complete: false,
  preClinic: {
    vitalSings: null,
  },
  loadingPackage: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRESENTIAL_USER_REFERENCE:
    case ADD_PRESENTIAL_USER:
      return {
        ...state,
        user: payload,
      };

    case ADD_PRESENTIAL_VITALS:
      return {
        ...state,
        preClinic: {
          ...state.preClinic,
          vitalSings: payload,
        },
      };

    case DELETE_PRESENTIAL_VITALS:
      return {
        ...state,
        preClinic: {
          ...state.preClinic,
          vitalSings: null,
        },
      };
    case HANDLE_CHANGE_SERVICE:
      return {
        ...state,
        service: payload,
      };

    case SET_FALSE_PRESENTIAL_VITAL_SIGNS:
      return {
        ...state,
        vitalFlag: false,
      };
    case SET_TRUE_PRESENTIAL_VITAL_SIGNS:
      return {
        ...state,
        vitalFlag: true,
      };

    case SET_TRUE_PRESENTIAL_ADD_USER:
      return {
        ...state,
        addUserFlag: true,
      };

    case SET_FALSE_PRESENTIAL_ADD_USER:
      return {
        ...state,
        addUserFlag: false,
      };

    case DELETE_PRESENTIAL_USER_REFERENCE:
      return {
        ...state,
        user: null,
      };

    case SET_TRUE_COMPLETE:
      return {
        ...state,
        complete: true,
      };
    case SET_FALSE_COMPLETE:
      return {
        ...state,
        complete: false,
      };
    case SET_TRUE_PRESENTIAL_PROFILE:
      return {
        ...state,
        profileFlag: true,
      };
    case SET_FALSE_PRESENTIAL_PROFILE:
      return {
        ...state,
        profileFlag: false,
      };
    case TRUE_LOADING_PACKAGE:
      return {
        ...state,
        loadingPackage: true,
      };
    case FALSE_LOADING_PACKAGE:
      return {
        ...state,
        loadingPackage: false,
      };
    case RESET_PRESENTIAL_STATE:
      return {
        ...state,
        user: null,
        vitalFlag: false,
        profileFlag: false,
        complete: false,
        preClinic: {
          vitalSings: null,
        },
      };

    default:
      return state;
  }
}
