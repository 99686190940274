import axios from 'axios';
import { ADD_SIGNS_VITAL } from '../types';
 
export const addSignsVital = (data, idconference) => async (dispatch) =>{
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({
    data, 
    idconference
  })
  try {
    const res = await axios.post('/api/signs/vitals/add', body, config)
    dispatch({
      type: ADD_SIGNS_VITAL,
      payload: res.data
    })
  } catch (error) {
    console.log(error)
  }
}