import React from 'react';
import moment from 'moment';

const ShowHeaModal = ({ heaHistory }) => (
  <>
    {/* start modal HEA */}
    <div
      className="modal fade"
      id="ModalHEA"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary mx-auto" id="exampleModalLabel">
              VER HEA
            </h5>
          </div>
          <div className="modal-body">
            <div
              id="carouselExample"
              className="carousel slide"
              data-ride="carousel"
              data-interval="2000"
              >
              <div className="carousel-inner row w-100 mx-auto" role="listbox">
                {
                  heaHistory?.length === 0 ? (
                    <div className="carousel-item active">
                      <div className="card-hea w-100 mb-3">
                        <div className="card-header">
                          <p className="text-black-50">No hay datos...</p>
                          <span />
                        </div>
                        <div className="card-body" />
                      </div>
                    </div>
                  ) : heaHistory?.map((doc, i) => (
                    <div className={`carousel-item w-100 pl-5 pr-5 ${i === 0 ? 'active' : ''}`}>
                      <div className="card-hea w-100 mb-3">
                        <div className="card-header">
                          <h6 className="text-primary">
                            {' '}
                            Motivo de la consulta:
                            {' '}
                            {doc.reason}
                          </h6>
                          <span className="text-black-50">
                            {moment(doc.createdAt).format('DD/MM/YYYY')}
                          </span>
                        </div>
                        <div className="card-body">
                          <h6 className="card-title text-secondarymdv">Diagnóstico:</h6>
                          <p className="card-text text-black-50">
                            {doc.diagnosis}
                            {' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

              <a
                className="carousel-control-prev"
                href="#carouselExample"
                role="button"
                data-slide="prev"
                >
                <i className="fa fa-chevron-circle-left fa-2x text-secondarymdv" />

                <span className="sr-only">Previous</span>
              </a>

              <a
                className="carousel-control-next text-faded"
                href="#carouselExample"
                role="button"
                data-slide="next"
                >
                <i className="fa fa-chevron-circle-right fa-2x text-secondarymdv" />

                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Finish modal HEA */}
  </>
);

export default ShowHeaModal;
