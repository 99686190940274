/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from '../Spinner/Spinner';
import {
  cancelVideoconferenceDoctor,
  acceptVideoconferenceDoctor,
  observerCancelVideoconferencePatient,
} from '../../websokect/notificationsWebsocket';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';
import { getOneVideoconference } from '../../api/videoconference';
import { observerPatientInJitsi } from '../../websokect/notificationsWebsocket';
import { createStatusVideconferenceLog } from '../../api/status-videconference-logs/index';
import { statusvideconferenceEnum } from '../../enums/status-videconference-logs.enum';

import { useDispatch } from 'react-redux';
const IncomingCall = ({ history, match, token, user }) => {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState(undefined);
  const [videoconference, setVideoconference] = useState(undefined);
  const [sound] = useState(new Audio('/sound.mp3'));
  const dispatch = useDispatch();
  useEffect(() => {
    const { roomId: idVideo } = match.params;
    sound.play();

    observerPatientInJitsi({ idVideo }, () => {
      localStorage.setItem('patientIntoJitsi', true);
    });
    getOneVideoconference({ token, idVideo })
      .then((res) => {
        const { patient, videoconference } = res;
        setPatient(patient);
        setVideoconference(videoconference);
        setLoading(false);

        observerCancelVideoconferencePatient({ idVideo }, () => {
          alert('El paciente ha cancelado la llamada');
          changeStatusDoctor({
            doctorId: user._id,
            role: user.role,
            status: 'available',
          });
          sound.pause();
          history.push('/dashboard');
        });
      })
      .catch((error) => {
        console.log(error);
        alert('Error al completar la llamada');
        history.push('/dashboard');
      });

    changeStatusDoctor({
      doctorId: user._id,
      role: user.role,
      status: 'occupied',
    });
  }, [history, match.params, sound, token, user, dispatch]);

  const handleAccept = () => {
    if (!videoconference) return;

    createStatusVideconferenceLog({
      patientId: videoconference.patient,
      doctorId: videoconference.doctor,
      videoconferenceId: videoconference._id,
      action: statusvideconferenceEnum.doctorAcceptVideconference,
      message: 'El doctor acepto la llamada',
    });
    acceptVideoconferenceDoctor({ idVideo: videoconference._id }, (data) => {
      if (data.status === 'success') {
        sound.pause();
        if (videoconference.type === 'videocall') {
          window.location.href = `/videoconference/${videoconference._id}`;
        } else if (videoconference.type === 'chat') {
          history.push(`/dashboard/chat/${videoconference._id}`);
        } else if (videoconference.type === 'call') {
          window.location.href = `/call/${videoconference._id}`;
        } else {
          alert('Dato erroneo');
        }
      } else {
        alert('Lo sentimos no se pudo aceptar la llamada');
      }
    });
  };

  const handleCancel = () => {
    if (!videoconference) return;
    createStatusVideconferenceLog({
      patientId: videoconference.patient,
      doctorId: videoconference.doctor,
      videoconferenceId: videoconference._id,
      action: statusvideconferenceEnum.doctorCancelVideconference,
      message: 'El doctor canceló la videconferencia',
    });
    cancelVideoconferenceDoctor({ idVideo: videoconference._id }, (data) => {
      if (data.status === 'success') {
        sound.pause();
        changeStatusDoctor({
          doctorId: user._id,
          role: user.role,
          status: 'available',
        });
        history.push('/dashboard');
      } else {
        alert('Lo sentimos no se pudo cancelar la llamada');
      }
    });
  };

  if (loading && !patient && !videoconference) {
    return <Spinner />;
  }

  return (
    <div className='call mt-5 shadow'>
      <header>
        <h1>Llamada Entrante</h1>
      </header>
      <div className='user'>
        <div className='row user-name'>
          {patient.publicData.gender === 'male' ? (
            <img
              src={require('../../assets/img/male.png')}
              alt='profile-image'
              className='media-object img-circle img-doc-3 center-div'
            />
          ) : (
            <img
              src={require('../../assets/img/female.png')}
              alt='profile-image'
              className='media-object img-circle img-doc-3 center-div'
            />
          )}
        </div>
        <div className='user-name p-1'>
          <b className='text-secondarymdv'>{`${patient.publicData?.firstName} ${patient.publicData?.lastName}`}</b>{' '}
          está llamando para una consulta.
        </div>
        <div className='status'>
          <div className='circle' />
          <div className='circle' />
          <div className='circle' />
          <div className='circle' />
          <div className='circle last' />
        </div>
        <div className='d-flex justify-content-center mt-4'>
          <div className='mr-2'>
            <button
              type='button'
              onClick={handleCancel}
              className='btn btn-danger rounded-pill btn-sm'
            >
              Cancelar
            </button>
          </div>
          <div className='ml-2'>
            <button
              type='button'
              onClick={handleAccept}
              className='btn btn-primary btn-sm'
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => state.auth;

export default connect(mapStatetoProps)(IncomingCall);
