/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-syntax */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import { Modal, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import {
  FaRegCalendarAlt,
  MdChatBubbleOutline,
  AiOutlineClockCircle,
  ImCancelCircle,
  AiOutlineCheckCircle,
  RiHospitalFill,
} from 'react-icons/all';
import Swal from 'sweetalert2';
import {
  deleteReference,
  deleteReferenceTimeDoctor,
  getReferenceTimeDoctor,
  deleteConsultoriesReference,
} from '../../../redux/actions/references';
import {
  hideDetails,
  deleteScheduleItem,
  setStatusSchedule,
} from '../../../redux/actions/scheduling.action';
import {
  openModalReagendar,
  openModalCancelAppointment,
  closeModalScheduleDetails,
} from '../../../redux/actions/ui.action';
import '../../../stylesheets/scheduling.css';
import imgAvatarTemp from '../../../assets/img/avatar1.jpg';

const ScheduleDetailsModal = ({
  hideDetails,
  details,
  userRef,
  deleteReference,
  deleteScheduleItem,
  deleteReferenceTimeDoctor,
  setStatusSchedule,
  openModalReagendar,
  closeModalScheduleDetails,
  modalSchedule,
  getReferenceTimeDoctor,
  user,
  deleteConsultoriesReference,
}) => {
  const handleSelectBackgroundColorClass = () => {
    if (details) {
      if (details.status === 'accept') {
        return 'container-avatar modal-header-content-enabled';
      } else if (details.status === 'waiting') {
        return 'container-avatar modal-header-content-disabled';
      } else {
        return 'container-avatar modal-header-content-reject';
      }
    }
  };
  const handleStatusReject = () => {
    setStatusSchedule(
      details._id,
      'reject',
      userRef.role,
      user.firstName,
      user.lastName,
      user.gender,
      details.date,
      details.hour
    );
    closeModalScheduleDetails();
  };
  const handleStatusSchedule = () => {
    if (details) {
      if (details.status === 'accept') {
        return 'Aprobada';
      } else if (details.status === 'waiting') {
        return 'Aprobación pendiente';
      } else if (details.status === 'reject') {
        return 'Rechazada';
      }
    }
  };

  const handleDeleteSchedule = () => {
    Swal.fire({
      title: '¿Cancelar cita?',
      text: 'Esta seguro que desea cancelar cita',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteScheduleItem(details, user?.firstName, user?.lastName);
        closeModalScheduleDetails();
        hideDetails();
        deleteReference();
      }
    });
  };

  const handleStatusAccept = () => {
    setStatusSchedule(
      details._id,
      'accept',
      userRef.role,
      user.firstName,
      user.lastName,
      user.gender,
      details.date,
      details.hour
    );
    closeModalScheduleDetails();
  };

  const handleOnHide = () => {
    closeModalScheduleDetails();
    setTimeout(() => {
      hideDetails();
      deleteReference();
      deleteReferenceTimeDoctor();
      deleteConsultoriesReference();
    }, 300);
  };

  const handleReagendarCita = () => {
    openModalReagendar();
    getReferenceTimeDoctor(details.doctor, details.consultoryId);
  };

  return (
    <div>
      <Modal
        show={modalSchedule}
        onHide={handleOnHide}
        centered
        dialogClassName="dialog-modal"
        backdropClassName="backdrop-style"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div className={handleSelectBackgroundColorClass()}>
            <div
              style={{
                backgroundImage: `${
                  userRef?.image === null ||
                  userRef?.image === '' ||
                  userRef?.image === undefined
                    ? `url(${imgAvatarTemp})`
                    : `url(/api/profile/image/${userRef?.image})`
                }`,
              }}
              className="avatar-modal"
            />
            <div className="doctor-content-modal">
              <div style={{ color: '#fff', fontSize: 14 }}>
                {userRef?.role === 'doctor' &&
                  `Dr${userRef?.gender === 'female' ? 'a. ' : '. '}`}
                {`${userRef?.firstName} ${userRef?.lastName}`}
              </div>
              <div style={{ color: '#fff', width: '100%', fontSize: 14 }}>
                {userRef && userRef?.email}
              </div>
            </div>
          </div>
          <div className="date-modal-container">
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                color: '#001689',
                fontSize: 14,
                fontWeight: 500,
                marginBottom: 10,
              }}
            >
              Resumen cita médicas
            </div>
            <Row>
              <Col xs={2} />
              <Col xs={8}>
                <div
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    backgroundColor: '#051D3E',
                    borderRadius: 10,
                    color: 'white',
                    marginBottom: 10,
                  }}
                >
                  {details?.consultationType === 'presential' &&
                    'Cita presencial'}
                  {details?.consultationType === 'call' &&
                    'Cita mediante llamada'}
                  {details?.consultationType === 'chat' && 'cita mediante chat'}
                  {details?.consultationType === 'videocall' &&
                    'Cita mediante Videollamada'}
                  {details?.consultationType === 'whatsapp' &&
                    'Cita mediante whatsapp'}
                  {details?.consultationType === 'lab' &&
                    'Servicio laboratorio'}
                </div>
              </Col>
              <Col xs={2} />
            </Row>
            <div style={{ padding: 20 }}>
              <Row
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                }}
              >
                <Col
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RiHospitalFill size={20} />
                </Col>
                <Col xs={10}>{details?.consultoryName}</Col>
              </Row>
              <Row
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                }}
              >
                <Col
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaRegCalendarAlt size={20} />
                </Col>
                <Col xs={10}>{`${moment(details?.date).format('LL')}`}</Col>
              </Row>
              <Row
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                }}
              >
                <Col
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AiOutlineClockCircle size={20} />
                </Col>
                <Col xs={10}>
                  {parseInt(details?.hour, 10) < 12
                    ? `${details?.hour} AM`
                    : `${details?.hour} PM`}
                </Col>
              </Row>
              <Row
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                }}
              >
                <Col
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MdChatBubbleOutline size={20} />
                </Col>
                <Col xs={10}>{details?.reason}</Col>
              </Row>
              {userRef?.role === 'doctor' && (
                <Row
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                  }}
                >
                  <Col
                    xs={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {details?.status === 'accept' ? (
                      <AiOutlineCheckCircle size={20} />
                    ) : (
                      <ImCancelCircle size={20} />
                    )}
                  </Col>
                  <Col xs={10}>{handleStatusSchedule()}</Col>
                </Row>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="m-0 p-2">
          {user.role === 'patient' && (
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-link btn-sm font-weight-bold mr-2 tetx-secondarymdv"
                onClick={handleDeleteSchedule}
              >
                Cancelar cita
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleReagendarCita}
              >
                Reagendar
              </button>
            </div>
          )}
          <div className="d-flex">
            {details &&
              details.status === 'waiting' &&
              userRef?.role === 'patient' && (
                <>
                  <button
                    type="button"
                    className="btn btn-link btn-sm font-weight-bold mr-2 tetx-secondarymdv"
                    onClick={handleStatusAccept}
                  >
                    Aprobar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleStatusReject}
                  >
                    Rechazar
                  </button>
                </>
              )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  details: state.scheduling.details,
  userRef: state.reference.userRef,
  modalSchedule: state.ui.modalScheduleDetails.open,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  hideDetails,
  deleteReference,
  deleteScheduleItem,
  deleteReferenceTimeDoctor,
  setStatusSchedule,
  openModalReagendar,
  openModalCancelAppointment,
  closeModalScheduleDetails,
  getReferenceTimeDoctor,
  deleteConsultoriesReference,
})(ScheduleDetailsModal);
