/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import createAlert from '../../../redux/actions/alert.action';
import { Spinner } from 'react-bootstrap';
import { registerEmployedCompany } from '../../../redux/actions/auth.action';
import $ from 'jquery';
import moment from 'moment';
import { useLoaderButton } from './states/useLoaderButton';
import { usePasswordShown } from './states/usePasswordShown';
import { useFormData } from './states/useFormData';

function ModalAddNewEmployed({ registerEmployedCompany }) {
  const { loaderButton, setLoaderButton } = useLoaderButton();
  const {
    firstName,
    lastName,
    password,
    passwordConfirm,
    email,
    job,
    gender,
    dni,
    idType,
    bornAt,
    cellphone,
    formData,
    handleChange,
    setFormData,
  } = useFormData();

  const setFormat = () => {
    if (idType === 'national') {
      return '####-####-#####';
    } else if (idType === 'rtn') {
      return '####-####-######';
    }
  };

  // agregar usuario
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoaderButton(true);

      // revisar si el formulario no esta vacio

      if (
        firstName === '' ||
        lastName === '' ||
        dni === '' ||
        idType === '' ||
        password === '' ||
        passwordConfirm === '' ||
        job === '' ||
        bornAt === '' ||
        cellphone === ''
      ) {
        await createAlert('¡Error!', 'Rellene todos los campos', 'error');
        setLoaderButton(false);
        return;
      }

      const actualTime = moment(new Date()).format('YYYY-MM-DD');
      const timeReference = moment(bornAt).format('YYYY-MM-DD');
      if (timeReference > actualTime) {
        await createAlert(
          '¡Error!',
          'La fecha de nacimiento debe ser menor a la fecha actual',
          'error'
        );
        setLoaderButton(false);
        return;
      }
      // validar si la identidad cumple requisitos

      const tempDni = dni.replace(/-/g, '').replace(/_/g, '');
      if (
        (idType === 'national' && tempDni.length !== 13) ||
        (idType === 'rtn' && tempDni.length !== 14) ||
        (idType === 'foreign' && dni.length < 8)
      ) {
        await createAlert(
          '¡Error!',
          'Número de identidad incorrecto.',
          'error'
        );
        setLoaderButton(false);
        return;
      }

      // validar si la scontraseñas sonaceptadas
      if (password !== passwordConfirm) {
        await createAlert(
          '¡Error!',
          'Las contraseñas no coinciden o no cumple con los requisitos establecidos',
          'error'
        );
        setLoaderButton(false);
        return;
      }

      // validar si la contrasea cumple con la regex
      if (
        !password.match(
          /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=_!|-}{)(/])(.{8,})$/s
        )
      ) {
        await createAlert(
          '¡Error!',
          'Las contraseñas no coinciden o no cumple con los requisitos establecidos',
          'error'
        );
        setLoaderButton(false);
        return;
      }

      const res = await registerEmployedCompany(formData);
      if (res.status === 200) {
        await createAlert(
          'Exito',
          'Usuario registrado exitosamente',
          'success'
        );
        setFormData({
          ...formData,
          firstName: '',
          lastName: '',
          dni: '',
          email: '',
          job: '',
          bornAt: '',
          password: 'EmpresarialK2022',
          passwordConfirm: 'EmpresarialK2022',
          cellphone: '',
        });
        setLoaderButton(false);
      } else {
        await createAlert(
          'Verifique datos',
          'No se pudo procesar el registro',
          'error'
        );
        setLoaderButton(false);
      }
      return;
    } catch (e) {
      await createAlert('Error', 'No se pudo registrar el usuario', 'error');
      setLoaderButton(false);
      return;
    }
  };

  function cerrar() {
    firstName = '';
    lastName = '';
    job = '';
    dni = '';
    bornAt = '';
    gender = '';
    email = '';
    password = '';
    passwordConfirm = '';
    cellphone = '';
    $('#ModalAddNewEmployed').modal('hide');
  }

  return (
    <div
      className='modal fade'
      id='ModalAddNewEmployed'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
      //show={show}
    >
      <div
        className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
        role='document'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5
              className='modal-title mx-auto text-primary'
              id='exampleModalLabel'
            >
              AGREGAR NUEVO EMPLEADO
            </h5>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='float-left'>Identidad</label>
                    <NumberFormat
                      className='form-control bg-light'
                      format={`${setFormat()}`}
                      mask='_'
                      name='dni'
                      value={dni}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Nombre</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputFirstName'
                      name='firstName'
                      value={firstName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Apellido</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputLastName'
                      name='lastName'
                      value={lastName}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Fecha de Nacimiento</label>
                    <input
                      type='date'
                      className='form-control bg-light'
                      placeholder='Fecha de Nacimiento'
                      name='bornAt'
                      value={bornAt}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Géneros</label>
                    <div className='dropdown'>
                      <select
                        className='form-control bg-light'
                        id='exampleFormControlSelect0'
                        name='gender'
                        value={gender}
                        onChange={handleChange}
                      >
                        <option value='female'>Femenino</option>
                        <option value='male'>Maculino</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='float-left'>Puesto</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputJob'
                      name='job'
                      value={job}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Numero de teléfono </label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputCellPhone'
                      name='cellphone'
                      value={cellphone}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Correo Electrónico</label>
                    <input
                      type='email'
                      className='form-control bg-light'
                      id='inputEmail'
                      name='email'
                      value={email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='float-left'> Contraseña</label>
                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputPassword1'
                      name='password'
                      value={password}
                      onChange={handleChange}
                    />

                    <span className='text-dark font12'>
                      -Contener al menos 8 catacteres, 1 dígito, 1 minúscula y 1
                      mayúscula
                    </span>
                  </div>
                  <div className='form-group'>
                    <label className='float-left'>Confirmar Contraseña</label>

                    <input
                      type='text'
                      className='form-control bg-light'
                      id='inputPassword'
                      name='passwordConfirm'
                      value={passwordConfirm}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='modal-footer d-flex justify-content-center p-2'>
            <button
              type='submit'
              className='btn btn-primary font-weight-bold btn-block col-md-6 mb-3'
              onClick={handleSubmit}
              disabled={loaderButton}
              style={{ opacity: '100%' }}
            >
              {loaderButton ? (
                <Spinner
                  style={{
                    width: 20,
                    height: 20,
                    color: '#ffff',
                    fontSize: 8,
                  }}
                  animation='border'
                />
              ) : (
                'REGISTRAR'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { registerEmployedCompany })(ModalAddNewEmployed);
