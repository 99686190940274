import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import createAlert from '../redux/actions/alert.action';
import { changePasswordByEmail } from '../redux/actions/auth.action';

const ChangePassword = ({ changePasswordByEmail, match }) => {
  const [form, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [loaderButton, setLoaderButton] = useState(false);
  const { password, passwordConfirm } = form;

  const handleChange = (e) => {
    setFormData({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== '' && passwordConfirm !== '') {
      if (password.match(passwordConfirm)) {
        setLoaderButton(true);
        changePasswordByEmail(password, match.params.token);
        setLoaderButton(false);
      } else {
        createAlert('¡Error!', 'Las contraseñas no coinciden.', 'error');
      }
    } else {
      createAlert('¡Error!', 'Rellene todos los campos.', 'error');
    }
  };

  return (
    <section className="hero-section-beat-registro">
      <div className="container forgot-pass-overlapping">
        <div className="row center-on-tablets">
          <div className="col-12 col-sm-5 col-md-5 col-lg-5">
            <div
              className="card border-0 shadow-sm"
              style={{
                borderBottomLeftRadius: '2rem',
                borderBottomRightRadius: '2rem',
              }}
            >
              <img
                src={require('../assets/img/loginKCL.png')}
                alt="login-logo"
                className="img-fluid loginlogo2"
                data-aos="fade-right"
              />
              <div className="card-body border-0">
                <h4 className="text-primary text-center mb-3">
                  Cambio de Contraseña
                </h4>
                <form id="changepass">
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control center"
                      placeholder="Nueva Contraseña"
                      name="password"
                      value={password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control center"
                      placeholder="Confirmar contraseña"
                      name="passwordConfirm"
                      value={passwordConfirm}
                      onChange={handleChange}
                    />
                  </div>
                </form>
                <div
                  className="pr-2 pl-2"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary col-md-10 col-12"
                    onClick={handleSubmit}
                    style={{
                      opacity: `${
                        loaderButton ||
                        !(
                          password === passwordConfirm &&
                          password.trim().length > 0
                        )
                          ? '80%'
                          : '100%'
                      }`,
                    }}
                    disabled={
                      loaderButton ||
                      !(
                        password === passwordConfirm &&
                        password.trim().length > 0
                      )
                    }
                  >
                    {loaderButton ? (
                      <Spinner
                        style={{
                          width: 20,
                          height: 20,
                          color: '#ffff',
                          fontSize: 8,
                        }}
                        animation="border"
                      />
                    ) : (
                      'Restablecer'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, {
  changePasswordByEmail,
})(ChangePassword);
