const antecedentesFamiliares = [
  { name: 'Hipertensión', id: 1, check: false },
  { name: 'Diabetes', id: 2, check: false },
  { name: 'Asma', id: 3, check: false },
  { name: 'Problemas Renales', id: 4, check: false },
  { name: 'Migraña', id: 5, check: false },
  { name: 'Infecciones urinarias', id: 6, check: false },
  { name: 'Ansiedad', id: 7, check: false },
  { name: 'Psoriasis', id: 8, check: false },
  { name: 'Cáncer', id: 9, check: false },
  { name: 'Arritmia', id: 10, check: false },
  { name: 'Epilepsia', id: 11, check: false },
  { name: 'Paro cardíaco', id: 12, check: false },
  {
    name: 'Depresión',
    id: 13,
    check: false,
  },
  {
    name: 'Hipotiroidismo',
    id: 14,
    check: false,
  },
  {
    name: 'Otros',
    id: 15,
    check: false,
  },
];

export default antecedentesFamiliares;
