/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import NumberFormat from 'react-number-format';
import { visa, mastercard } from '../../../utils/regex';
import { addCreditCard } from '../../../redux/actions/credit.card.action';
import 'react-credit-cards/es/styles-compiled.css';
import createAlert from '../../../redux/actions/alert.action';

import './history.css';

const CardModal = ({ open, setOpen, addCreditCard }) => {
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [selected, setSelected] = useState(false);

  const handleFormatNumber = () => {
    // quitar los espacios en el formato de numeros de la tarjeta
    let tempNumber = number;
    tempNumber = tempNumber.replace(/\s+/g, '');
    // convertir datos de entrada en fechas

    const actualYear = new Date().getFullYear().toString().slice(2, 4);
    const actualMonth = new Date().getMonth();
    const expiryYear = expiry.slice(3, 5);
    const expiryMonth = expiry.slice(0, 2);

    if (tempNumber.match(visa) || tempNumber.match(mastercard)) {
      if (name !== '') {
        if (
          (actualYear === expiryYear && expiryMonth <= actualMonth) ||
          expiryMonth > 12 ||
          expiry === '' ||
          actualYear > expiryYear
        ) {
          createAlert(
            '¡Error!',
            'La fecha de caducidad es incorrecta.',
            'error'
          );
        } else if (cvc !== '' && cvc.length === 3) {
          addCreditCard(tempNumber, name, expiry, cvc, selected);
          setOpen(false);
          setNumber('');
          setCvc('');
          setExpiry('');
          setName('');
          setSelected(false);
          setFocus(number);
        } else {
          createAlert('¡Error!', 'Código cvc incorrecto', 'error');
        }
      } else {
        createAlert('¡Error!', 'Escriba el nombre del titular.', 'error');
      }
    } else {
      createAlert('¡Error!', 'Número de tarjeta invalido.', 'error');
    }
  };

  return (
    <div>
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <Modal.Header>
          <h5
            className="modal-title text-primary font-weight-bold mx-auto"
            id="exampleModalLabel"
          >
            Tarjeta de crédito o débito
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row center">
            <Cards
              number={number}
              name={name}
              expiry={expiry}
              cvc={cvc}
              focused={focus}
              placeholders={{ name: 'NOMBRE APELLIDO' }}
              acceptedCards={['visa', 'mastercard']}
            />
          </div>
          <div className="row center">
            <span className="center-div mt-1" style={{ color: '#e27481' }}>
              *Se aceptan solamente tarjetas Visa o Mastercard.
            </span>
          </div>
          <form className="p-1">
            <NumberFormat
              name="number"
              type="tel"
              value={number}
              className="form-control input-card-modal-form"
              id="recipient-name"
              onChange={(e) => setNumber(e.target.value)}
              onFocus={(e) => setFocus(e.target.name)}
              placeholder="Número tarjeta"
              format="#### #### #### ####"
            />
            <input
              type="text"
              className="form-control input-card-modal-form"
              id="recipient-name"
              value={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
              onFocus={(e) => setFocus(e.target.name)}
              placeholder="Nombre titular"
            />
            <Row>
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <label className="m-0 text-secondarymdv">Vencimiento</label>
              </Col>
            </Row>
            <Row>
              <Col xs={7}>
                <NumberFormat
                  className="form-control input-card-modal-form"
                  id="recipient-name"
                  value={expiry}
                  name="expiry"
                  onChange={(e) => setExpiry(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                  placeholder="MM/YY"
                  format="##/##"
                />
              </Col>
              <Col xs={5}>
                <NumberFormat
                  type="text"
                  className="form-control input-card-modal-form"
                  id="recipient-name"
                  value={cvc}
                  name="cvc"
                  onChange={(e) => setCvc(e.target.value)}
                  onFocus={(e) => setFocus(e.target.name)}
                  placeholder="CVC"
                  maxLength="3"
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <label
                  className="checkbox-card-item text-secondarymdv"
                  id="card-item-checkbox"
                >
                  Seleccionar tarjeta por defecto
                </label>
                <input
                  type="checkbox"
                  checked={selected}
                  style={{ flex: 1 }}
                  onChange={() => {
                    setSelected(!selected);
                  }}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <div className="modal-footer">
          <div className="d-flex mr-3">
            <button
              type="button"
              className="btn btn-link btn-sm text-secondarymdv font-weight-bold mr-3"
              onClick={() => {
                setNumber('');
                setCvc('');
                setExpiry('');
                setName('');
                setOpen(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleFormatNumber}
            >
              Añadir Tarjeta
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  selectedItem: state.history.familiar.selectedItem,
});

export default connect(mapStateToProps, { addCreditCard })(CardModal);
