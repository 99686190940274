/* eslint-disable global-require */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable arrow-parens */
import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import createAlert from '../redux/actions/alert.action';
import '../stylesheets/dashboard.css';
import { connect } from 'react-redux';
import logo from '../assets/img/KCL.png';
import DashboardNav from './DashboardNav';
import {
  observeNewAppointmentToDoctor,
  observeStatusAppointment,
  observeDeleteConsultationToPatient,
  observeReScheduleAppointmentToDoctor,
} from '../websokect/scheduleWebSocket';
import { logout } from '../redux/actions/auth.action';
import { disconnectDoctor } from '../websokect/doctorWebsockets';
import {
  observeNotifications,
  observeCloseSession,
} from '../websokect/notificationsWebsocket';
import { addSocketNotification } from '../redux/actions/notifications.action';
import {
  addScheduleBySocket,
  socketUpdateStateToAppointment,
  socketActionToDeleteScheduleToPatient,
  reScheduleBySocket,
} from '../redux/actions/scheduling.action';
import { logoutUser } from '../api/user';

const DashboardMenu = ({
  logout,
  children,
  token,
  user: { email, firstName, lastName, _id, role },
  user,
  addScheduleBySocket,
  addSocketNotification,
  socketUpdateStateToAppointment,
  socketActionToDeleteScheduleToPatient,
  reScheduleBySocket,
}) => {
  useEffect(() => {
    observeNewAppointmentToDoctor({ uid: user._id }, (schedule) => {
      addScheduleBySocket(schedule);
    });
    observeReScheduleAppointmentToDoctor({ uid: user._id }, (schedule) => {
      reScheduleBySocket(schedule);
    });

    observeNotifications({ uid: user._id }, (notification) => {
      addSocketNotification(notification);
    });
    observeStatusAppointment({ userId: user._id }, (data) => {
      socketUpdateStateToAppointment(data);
    });
    observeDeleteConsultationToPatient({ userId: user._id }, (data) => {
      socketActionToDeleteScheduleToPatient(data);
    });
    observeCloseSession(
      {
        userId: user._id,
      },
      () => {
        logout();
        window.location.href = '/login';
      }
    );
  }, [
    user._id,
    addScheduleBySocket,
    addSocketNotification,
    socketUpdateStateToAppointment,
    socketActionToDeleteScheduleToPatient,
    reScheduleBySocket,
    logout,
  ]);

  return (
    <div style={{ display: 'flex' }}>
      <div className='menu-div'>
        <div className='s-layout'>
          <div className='s-layout__sidebar'>
            <a className='s-sidebar__trigger' href='#0'>
              <i className='fa fa-bars text-secondarymdv fa-2x' />
            </a>
            <nav className='s-sidebar__nav border-right'>
              <div className='links-container'>
                {/* Web View */}
                <div className='d-none d-sm-block text-center'>
                  {role === 'company' && (
                    <>
                      <NavLink
                        to='/dashboard/manage-employees'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/vectores/icon-employeds.svg')}
                          alt='loginlogo'
                          loading='lazy'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Gestión de Usuarios
                        </span>
                      </NavLink>
                      <NavLink
                        to='/dashboard/history'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/vectores/icon-history-process.svg')}
                          alt='loginlogo'
                          loading='lazy'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Historial de Transacciones
                        </span>
                      </NavLink>
                      {/*<NavLink to="/dashboard/log" className="nav-link-menu">
                        <img
                          src={require('../assets/img/vectores/icon-binnacle.svg')}
                          alt="loginlogo"
                          loading="lazy"
                          data-aos="fade-right"
                        />
                        <span className="hidden-xs hidden-sm menu-f text-primary">
                          Bitácora
                        </span>
                  </NavLink>*/}
                    </>
                  )}

                  {role !== 'company' && (
                    <NavLink to='/dashboard/' className='nav-link-menu' h>
                      <img
                        src={require('../assets/img/icons/home.svg')}
                        alt='loginlogo'
                        width='40'
                        data-aos='fade-right'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        Inicio
                      </span>
                    </NavLink>
                  )}

                  {role !== 'callcenter' &&
                    role !== 'company' &&
                    role !== 'checker' && (
                      <Link
                        to={{ pathname: 'https://www.kielsa.com' }}
                        target='_blank'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/icons/farmaciakielsa.svg')}
                          alt='loginlogo'
                          width='40'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Farmacia
                        </span>
                      </Link>
                    )}
                  {role !== 'checker' &&
                    role !== 'callcenter' &&
                    role !== 'company' && (
                      <NavLink
                        to='/dashboard/perfil'
                        className='nav-link-menu'
                        h
                      >
                        <img
                          src={require('../assets/img/icons/usuario.svg')}
                          alt='loginlogo'
                          width='40'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Perfil
                        </span>
                      </NavLink>
                    )}
                  {role === 'checker' && (
                    <>
                      <NavLink
                        to='/dashboard/patients'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/icons/usuario.svg')}
                          alt='loginlogo'
                          width='40'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Paciente
                        </span>
                      </NavLink>
                      <NavLink
                        to='/dashboard/giftCards'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/icons/giftcards.png')}
                          alt='giftcards'
                          width='40'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          GiftCards
                        </span>
                      </NavLink>
                    </>
                  )}
                  {role === 'patient' && (
                    <NavLink
                      to='/dashboard/telellamada'
                      className='nav-link-menu'
                    >
                      <img
                        src={require('../assets/img/icons/camera.svg')}
                        alt='camera'
                        width='40'
                        className='img-fluid'
                        data-aos='fade-right'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        Tele Medicina
                      </span>
                    </NavLink>
                  )}
                  {role !== 'callcenter' && role !== 'company' && (
                    <NavLink
                      to={
                        role === 'doctor' ||
                        role === 'nurse' ||
                        role === 'checker'
                          ? '/dashboard/cita-presencial'
                          : '/dashboard/consulta-presencial'
                      }
                      className='nav-link-menu'
                    >
                      <img
                        src={require('../assets/img/icons/add-heart.svg')}
                        alt='loginlogo'
                        width='40'
                        className='img-fluid'
                        data-aos='fade-right'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        {role === 'patient' ? ' Cita presencial' : 'Servicios'}
                      </span>
                    </NavLink>
                  )}
                  {role !== 'checker' &&
                    role !== 'callcenter' &&
                    role !== 'company' && (
                      <NavLink
                        to='/dashboard/laboratorio'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/icons/microscopio2.svg')}
                          alt='loginlogo'
                          width='40'
                          className='img-fluid'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Laboratorio
                        </span>
                      </NavLink>
                    )}
                  {role === 'patient' && (
                    <NavLink to='/dashboard/consulta' className='nav-link-menu'>
                      <img
                        src={require('../assets/img/icons/clipboard.svg')}
                        alt='loginlogo'
                        width='40'
                        className='img-fluid'
                        data-aos='fade-right'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        Planes Médicos
                      </span>
                    </NavLink>
                  )}

                  {role === 'patient' &&
                    !user.isRegisterByDomain &&
                    !user.idCompany && (
                      <NavLink
                        to='/dashboard/giftcard'
                        className='nav-link-menu'
                      >
                        <img
                          src={require('../assets/img/icons/giftcards.png')}
                          alt='giftcards'
                          width='40'
                          data-aos='fade-right'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          GiftCards
                        </span>
                      </NavLink>
                    )}

                  {role !== 'callcenter' && role !== 'company' && (
                    <NavLink
                      to={
                        role === 'doctor' || role === 'nurse'
                          ? '/dashboard/details'
                          : '/dashboard/historial'
                      }
                      className='nav-link-menu'
                    >
                      <img
                        src={require('../assets/img/icons/folder.svg')}
                        alt='loginlogo'
                        width='40'
                        className='img-fluid'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        {role === 'doctor' ||
                        role === 'nurse' ||
                        role === 'checker'
                          ? 'Historial'
                          : 'Historial Médico'}
                      </span>
                    </NavLink>
                  )}
                  {(user.role === 'doctor' ||
                    user.role === 'nurse' ||
                    user.role === 'checker') && (
                    <NavLink
                      to='/dashboard/monitor-de-consultas'
                      className='nav-link-menu'
                    >
                      <img
                        src={require('../assets/img/icons/medical-report.png')}
                        alt='loginlogo'
                        width='40'
                        className='img-fluid'
                        data-aos='fade-right'
                      />
                      <span className='hidden-xs hidden-sm menu-f text-primary'>
                        Monitor
                      </span>
                    </NavLink>
                  )}
                  {role !== 'checker' &&
                    role !== 'callcenter' &&
                    role !== 'company' && (
                      <NavLink to='/dashboard/agenda' className='nav-link-menu'>
                        <img
                          src={require('../assets/img/icons/calendar.svg')}
                          alt='loginlogo'
                          width='40'
                          className='img-fluid'
                        />
                        <span className='hidden-xs hidden-sm menu-f text-primary'>
                          Agenda
                        </span>
                      </NavLink>
                    )}
                </div>
                {/* Fin Web View */}
                {/* Mobile View */}
                <div className='d-block d-sm-none'>
                  <NavLink to='/dashboard'>
                    <img
                      src={logo}
                      alt='logo'
                      className='img-fluid center-logo mt-3'
                    />
                  </NavLink>
                  <div className='text-center'>
                    <h6 className='text-primary m-0 pt-2'>
                      ¡Hola,{' '}
                      {`${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}!
                    </h6>
                    <small className='text-black-50'>{email}</small>
                    <br />
                    <span className='badge-type-profile'>
                      <img
                        src={require('../assets/img/vectores/check-profile.svg')}
                        className='img-fluid mr-1'
                        alt='check profile plan'
                        loading='lazy'
                      />{' '}
                      Perfil empresarial
                    </span>
                  </div>
                  <ul className='list-group list-group-flush pt-5'>
                    <Link
                      to='/dashboard/'
                      className='list-group-item list-group-item-action border-bottom-0 d-flex justify-content-between align-items-center'
                    >
                      Inicio
                    </Link>
                    <Link
                      to={{ pathname: 'https://app.kielsa.com/' }}
                      target='_blank'
                      className='list-group-item list-group-item-action border-bottom-0 d-flex justify-content-between align-items-center'
                    >
                      Farmacia Kielsa
                    </Link>
                    <div className='hr-blue mr-1 ml-1' />
                    {role !== 'checker' && role !== 'callcenter' && (
                      <Link
                        to='/dashboard/perfil'
                        className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                      >
                        Mi Perfil
                        <span className='text-secondarymdv'>&gt;</span>
                      </Link>
                    )}
                    {role === 'patient' && (
                      <>
                        <Link
                          to='/dashboard/telellamada'
                          className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                        >
                          Telemedicina
                          <span className='text-secondarymdv'>&gt;</span>
                        </Link>
                        <Link
                          to='/dashboard/consulta'
                          className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                        >
                          Planes Médicos
                          <span className='text-secondarymdv'>&gt;</span>
                        </Link>
                      </>
                    )}
                    <Link
                      to={
                        role === 'doctor' || role === 'nurse'
                          ? '/dashboard/cita-presencial'
                          : '/dashboard/consulta-presencial'
                      }
                      className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                    >
                      {role === 'doctor' || role === 'nurse'
                        ? 'Cita Presencial'
                        : 'Consulta Presencial'}
                      <span className='text-secondarymdv'>&gt;</span>
                    </Link>
                    {role !== 'checker' && role !== 'callcenter' && (
                      <Link
                        to='/dashboard/laboratorio'
                        className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                      >
                        Laboratorio
                        <span className='text-secondarymdv'>&gt;</span>
                      </Link>
                    )}
                    {role !== 'checker' &&
                      role !== 'callcenter' &&
                      role !== 'company' && (
                        <Link
                          to={
                            role === 'doctor' || role === 'nurse'
                              ? '/dashboard/details'
                              : '/dashboard/historial'
                          }
                          className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                        >
                          {role === 'doctor' || role === 'nurse'
                            ? 'Historial'
                            : 'Historial Médico'}
                          <span className='text-secondarymdv'>&gt;</span>
                        </Link>
                      )}
                    {/* <div className="hr-blue" /> */}
                    {(role === 'doctor' || role === 'nurse') && (
                      <Link
                        to='/dashboard/monitor-de-consultas'
                        className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                      >
                        Monitor de Consultas
                        <span className='text-secondarymdv'>&gt;</span>
                      </Link>
                    )}
                    {role !== 'checker' &&
                      role !== 'callcenter' &&
                      role !== 'company' && (
                        <Link
                          to='/dashboard/agenda'
                          className='list-group-item list-group-item-action d-flex border-bottom-0 justify-content-between align-items-center'
                        >
                          Agenda
                          <span className='text-secondarymdv'>&gt;</span>
                        </Link>
                      )}
                  </ul>
                  <div className='footer-sidebar'>
                    <div className='hr-blue' />
                    <button
                      type='button'
                      className='btn btn-link btn-block text-secondarymdv mb-2 text-left font-weight-bold'
                      onClick={() => {
                        logoutUser({ token })
                          .then(() => {
                            disconnectDoctor({
                              doctorId: _id,
                              role: role,
                            });
                            logout();
                          })
                          .catch(() => {
                            createAlert(
                              'Error!',
                              'Lo sentimos, no se pudo cerrar la sesión.',
                              'error'
                            );
                          });
                      }}
                    >
                      Cerrar Sesión
                    </button>
                  </div>
                </div>
                {/* Fin Mobile View */}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className='main-container'>
        <DashboardNav />
        <div className='principal-layout'>
          <Container fluid>{children}</Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
});

export default connect(mapStateToProps, {
  addScheduleBySocket,
  addSocketNotification,
  socketUpdateStateToAppointment,
  logout,
  reScheduleBySocket,
  socketActionToDeleteScheduleToPatient,
})(DashboardMenu);
