/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-parens */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BiBookAdd, MdModeEdit } from 'react-icons/all';
import { IconContext } from 'react-icons';
import { Form, InputGroup, FormControl, Row } from 'react-bootstrap';
import $ from 'jquery';
import { countries } from '../../utils/countries';
import Autocomplete from '../Autocomplete';
import { hours, dias } from '../../utils/scheduling';
import createAlert from '../../redux/actions/alert.action';
import {
  updateProfile,
  getProfile,
  addProfileDoctorCvFile,
  addTimetable,
  deleteTimetable,
} from '../../redux/actions/profile.action';
import {
  deleteBankAccount,
  addSelectedItem,
} from '../../redux/actions/bank.accounts.action';
import { banks } from '../../utils/banks';
import Modal from './ModalAccount';

const DoctorPerfilMovil = ({
  user: { firstName, lastName, gender, email, role, dni },
  profile: {
    cellphone,
    country,
    bornAt,
    specialty,
    subSpecialties,
    languages,
    documents,
    college,
    master,
    university,
    experience,
    about,
    appointment,
  },
  timetables,
  specialties,
  updateProfile,
  accounts,
  deleteBankAccount,
  addSelectedItem,
  addProfileDoctorCvFile,
  addTimetable,
  deleteTimetable,
}) => {
  // state local
  // formato de los lenguajes
  const lenguajes = [];
  if (languages.lenght !== 0) {
    languages.forEach((lenguaje) => {
      lenguajes.push({ value: lenguaje, label: lenguaje });
    });
  }

  // subespecialidades
  const propsEspecialidades = [];
  if (subSpecialties.lenght !== 0) {
    subSpecialties.forEach((especialidad) => {
      propsEspecialidades.push({
        value: especialidad,
        label: especialidad,
      });
    });
  }

  // obtener epecialidad
  const tempEspecialidad =
    specialty === undefined
      ? ''
      : {
          value: specialty,
          label: specialty,
        };

  const [formData, setFormData] = useState({
    nombre: firstName,
    apellido: lastName,
    genero: gender === 'male' ? 'Masculino' : 'Femenino',
    correo: email,
    pais: country === undefined ? countries[0].name : country,
    identidad: dni,
    born: bornAt.slice(0, 10),
    tel: cellphone,
    cita: appointment === undefined ? false : appointment,
    dialCode:
      cellphone === undefined
        ? countries[0].dial_code
        : countries.filter((pais) => pais.name === country)[0].dial_code,
    especialidad: tempEspecialidad,
    subEspecialidad: subSpecialties.lenght === 0 ? [] : propsEspecialidades,
    idiomas: languages.lenght === 0 ? [] : lenguajes,
    colegiatura: college === undefined ? undefined : college,
    universidad: university === undefined ? undefined : university,
    experiencia: experience === undefined ? undefined : experience,
    me: about === undefined ? null : about,
    maestria: master === undefined ? undefined : master,
  });

  const [horario, setHorario] = useState({
    dia: '1',
    desde: '06:00',
    hasta: '06:00',
  });

  const [show, setShow] = useState(false);

  const {
    nombre,
    apellido,
    genero,
    correo,
    pais,
    born,
    tel,
    cita,
    especialidad,
    dialCode,
    subEspecialidad,
    idiomas,
    colegiatura,
    universidad,
    experiencia,
    me,
    maestria,
  } = formData;

  const { dia, desde, hasta } = horario;

  const handleChange = (e) => {
    if (e.target.name === 'pais') {
      const tempCountry = countries.filter(
        (country) => country.name === e.target.value
      );
      setFormData({
        ...formData,
        dialCode: tempCountry[0].dial_code,
        pais: tempCountry[0].name,
      });
    }
    if (e.target.name === 'cita') {
      setFormData({
        ...formData,
        cita: !cita,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleSubmitPersonalInformation = (e) => {
    e.preventDefault();
    updateProfile(formData, role);
  };

  const handleUploadCvFiles = () => {
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      addProfileDoctorCvFile(formData, documents);
    };
    input.click();
  };
  // obtener la lista de sub especialidades seleccionada y los idiomas
  const handleChangeAutocomplete = (e, name) => {
    if (name === 'subSpecialties') {
      if (e !== null) {
        setFormData({
          ...formData,
          subEspecialidad: e,
        });
      } else {
        setFormData({
          ...formData,
          subEspecialidad: [],
        });
      }
    } else if (name === 'idiomas') {
      if (e !== null) {
        setFormData({
          ...formData,
          idiomas: e,
        });
      } else {
        setFormData({
          ...formData,
          idiomas: [],
        });
      }
    }
  };

  const languagesArray = [
    { value: 'Español', label: 'Español' },
    { value: 'Ingles', label: 'Inglés' },
  ];
  const data = [];

  specialties.forEach((option) => {
    data.push({ value: option.name, label: option.name });
  });

  const horarioChange = (e) => {
    setHorario({
      ...horario,
      [e.target.name]: e.target.value,
    });
  };

  const horarioSubmit = (e) => {
    e.preventDefault();
    if (hasta > desde) {
      addTimetable(horario);
      horarioClear();
      //  $('#horariomodal').modal('hide');
    } else {
      createAlert(
        '¡Error!',
        'La hora final debe ser mayor a la inicial',
        'error'
      );
    }
  };

  const horarioDelete = (id) => {
    deleteTimetable(id);
  };

  const horarioClear = (e) => {
    setHorario({
      dia: '1',
      desde: '06:00',
      hasta: '06:00',
    });
  };

  return (
    <div className="container-fluid">
      <h5 className="text-primary mt-5 mb-4">
        Perfil&nbsp;
        {role === 'nurse' ? (
          <>
            <b className="text-secondarymdv">
              {gender === 'male' ? 'Enfro.' : 'Enfra.'}
              {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
            </b>
          </>
        ) : (
          <b className="text-secondarymdv">
            {gender === 'male' ? 'Dr.' : 'Dra.'}
            {` ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
          </b>
        )}
      </h5>
      <div className="row row-cols-2">
        <div className="col mb-4">
          <a data-toggle="modal" data-target="#modalinformation" href="#">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: '1rem' }}
            >
              <div className="p-2">
                <img
                  src={require('../../assets/img/icons/usuario.svg')}
                  className="card-img-top pb-2 pt-1"
                  alt="user"
                  style={{ width: '43px' }}
                />
                <br />
                <small
                  className="text-primary m-0 p-0"
                  style={{ fontSize: '11px' }}
                >
                  Información Personal
                </small>
              </div>
            </div>
          </a>
        </div>
        <div className="col mb-4">
          <a data-toggle="modal" data-target="#modalinformationpro" href="#">
            <div
              className="card text-center shadow-sm border-0 h-100"
              style={{ borderRadius: '1rem' }}
            >
              <div className="p-2">
                <img
                  src={require('../../assets/img/vectores/medical.svg')}
                  className="card-img-top pb-2 pt-1"
                  alt="user"
                  style={{ width: '43px' }}
                />
                <br />
                <small
                  className="text-primary m-0 p-0"
                  style={{ fontSize: '11px' }}
                >
                  Información Profesional
                </small>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* <!-- Modal user information--> */}
      <div
        className="modal fade"
        id="modalinformation"
        tabIndex="-1"
        aria-labelledby="modalinformationLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center text-primary font-weight-light mt-3 mb-3"
                id="modalinformationLabel"
              >
                {' '}
                Información Personal
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <label className="font-weight-light text-secondarymdv">
                  Nombres
                </label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control form-control-user"
                    placeholder="Nombre"
                    name="nombre"
                    value={nombre}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Apellidos
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-user"
                    id="lastName1"
                    placeholder="Apellido"
                    name="apellido"
                    value={apellido}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Correo Electrónico
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-user"
                    id="email"
                    // placeholder="Correo Electrónico"
                    name="correo"
                    value={correo}
                    onChange={(e) => handleChange(e)}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Fecha de Nacimiento
                  </label>
                  <input
                    type="Date"
                    className="form-control form-control-user"
                    id="date"
                    // placeholder="Fecha de Nacimiento"
                    name="born"
                    value={born}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    País
                  </label>
                  <Form.Control
                    className="form-control form-control-user"
                    as="select"
                    style={{ marginBottom: 15 }}
                    onChange={(e) => handleChange(e)}
                    name="pais"
                    value={pais}
                  >
                    {countries.map((country, key) => (
                      <option key={country.code}>{country.name}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Teléfono
                  </label>
                  <InputGroup>
                    <InputGroup.Prepend
                      classname="input-group-prepend-2"
                      value={dialCode}
                      name="dialCode"
                      onChange={(e) => handleChange(e)}
                    >
                      <InputGroup.Text>{dialCode}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      value={tel}
                      name="tel"
                      onChange={(e) => handleChange(e)}
                    />
                  </InputGroup>
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Género
                  </label>
                  <select
                    className="form-control form-control-user"
                    id="exampleFormControlSelect1"
                    // placeholder="Genero"
                    name="genero"
                    value={genero}
                    onChange={(e) => handleChange(e)}
                  >
                    <option>Femenino</option>
                    <option>Masculino</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={handleSubmitPersonalInformation}
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal user Profesional information--> */}
      <div
        className="modal fade"
        id="modalinformationpro"
        tabIndex="-1"
        aria-labelledby="#modalinformationproLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center text-primary font-weight-light mt-3 mb-3"
                id="#modalinformationproLabel"
              >
                {' '}
                Información Profesional
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Especialidad
                  </label>
                  <Autocomplete
                    placeholder="Sub-especialidades"
                    options={data}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    name="especialidad"
                    defOptions={especialidad}
                    multi
                  />
                </div>
                <div className="form-group col-12 col-md-7 center-div">
                  <label className="font-weight-light text-secondarymdv">
                    Sub-especialidades
                  </label>
                  <Autocomplete
                    placeholder="Sub-especialidades"
                    options={data}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    name="subSpecialties"
                    defOptions={subEspecialidad}
                    multi
                  />
                </div>
                <div className="form-group toppad">
                  <label className="font-weight-light text-secondarymdv">
                    Número de Colegiatura Médica
                  </label>
                  <input
                    className="form-control"
                    id="inputmed"
                    placeholder="Ej 15487"
                    name="colegiatura"
                    value={colegiatura}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Universidad de Pregrado
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputuniversity"
                    // placeholder="Universidad de pregrado"
                    name="universidad"
                    value={universidad}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Maestría
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputuniversity"
                    // placeholder="Maestría"
                    name="maestria"
                    value={maestria}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="font-weight-light text-secondarymdv">
                    Años de Experiencia
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="inputexperience"
                    // placeholder="Años de Experiencia"
                    name="experiencia"
                    value={experiencia}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-12 col-md-7 center-div">
                  <label className="font-weight-light text-secondarymdv">
                    Idiomas
                  </label>
                  <Autocomplete
                    placeholder="----"
                    options={languagesArray}
                    handleChangeAutocomplete={handleChangeAutocomplete}
                    name="idiomas"
                    defOptions={idiomas}
                    multi
                  />
                </div>
                {documents !== null &&
                documents !== '' &&
                documents !== undefined ? (
                  <div className="form-group container-cv">
                    <label style={{ margin: '0' }}>Certificados:</label>
                    <div>
                      <a
                        target="_blank"
                        href={`/api/profile/doctor/cv/file/${documents}`}
                        style={{ color: '#2D71A1', marginRight: 10 }}
                      >
                        Documentos personales y certificados
                      </a>
                      <MdModeEdit
                        color="red"
                        size={17}
                        style={{ cursor: 'pointer' }}
                        onClick={handleUploadCvFiles}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="form-group toppad">
                    <label>Agregue certificados y CV &nbsp;</label>
                    <button
                      type="button"
                      className="cv-normal-button"
                      onClick={handleUploadCvFiles}
                    >
                      añadir archivo
                    </button>
                  </div>
                )}
                <div className="form-group">
                  <label>Mensaje del médico</label>
                  <textarea
                    className="form-control"
                    name="me"
                    value={me}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={handleSubmitPersonalInformation}
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- finish Modal user Profesional information--> */}

      {/* <div className="row">
          <div className="col-md-12">
            <div className="sales report">
              <div className="col-md-12 col-sm-12 container-title-accounts">
                <div className="row mar-2">
                  <h2>Cuentas de Banco</h2>
                </div>
                <IconContext.Provider
                  value={{className: 'icon-add-accounts'}}
                >
                  <BiBookAdd onClick={() => setShow(true)} />
                </IconContext.Provider>
              </div>
              <div className="col-md-12 col-sm-12">
                <Row>
                  {accounts.length > 0 ? (
                    accounts.map((account) => (
                      <div
                        className="card-container-bank-account col-sm-12 col-md-6  col-lg-4"
                        key={account._id}
                      >
                        <div className="card card-element">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 mr-2 m-3 image-name-container-card">
                                {banks.map(
                                  (bank) =>
                                    bank.name === account.bank && (
                                      <img
                                        src={require(`../../assets/img/${bank.imgUri}`)}
                                        alt={`${bank.name} logo`}
                                        className="img-size-bank-account"
                                        key={bank}
                                      />
                                    ),
                                )}
                                <h5>
                                  {`${firstName.split(' ')[0]} ${
                                    lastName.split(' ')[0]
                                  }`}
                                </h5>
                              </div>
                              <div className="col-8">
                                <h6>{account.number}</h6>
                                <p>
                                  <b>Tipo de cuenta:</b>
                                  {account.type}
                                </p>
                              </div>
                              <div className="col-4">
                                <button
                                  type="button"
                                  className="btn-t"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  data-whatever="@getbootstrap"
                                  onClick={() => {
                                    addSelectedItem(account);
                                    setShow(true);
                                  }}
                                >
                                  <i className="far fa-edit edit-i" />
                                </button>
                                <button
                                  className="btn-t"
                                  type="button"
                                  onClick={() => deleteBankAccount(account._id)}
                                >
                                  <i className="far fa-times-circle close-i" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className="ml-3">
                      No tienen ninguna cuenta de banco agregada.
                    </span>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div> */}

      {/* start modal card info */}
      <Modal show={show} setShow={setShow} />
    </div>
  );
};

const mapStateToprops = (state) => ({
  user: state.auth.user,
  profile: state.profile.profile,
  profileLoaded: state.profile.profileLoaded,
  specialties: state.specialties.specialties,
  accounts: state.accounts.items,
  timetables: state.profile.timetable,
});
export default connect(mapStateToprops, {
  updateProfile,
  getProfile,
  deleteBankAccount,
  addSelectedItem,
  addProfileDoctorCvFile,
  addTimetable,
  deleteTimetable,
})(DoctorPerfilMovil);
