/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable global-require */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import Peer from 'peerjs';
// import toastr from 'toastr';
import $ from 'jquery';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import createAlert from '../../redux/actions/alert.action';
import socket from '../../websokect/socket';
import {
  endCallVideconference,
  getOneVideoconference,
  cancelVideoconference,
} from '../../api/videoconference';
import {
  videoconferencesSeconds,
  sendDiagnosisConsult,
  sendReasonConsult,
  sendSchedulePatient,
  sendIndicationPatient,
} from '../../websokect/videoconferenceWebsocket';
import {
  observerDiagnosisConsult,
  observerReasonConsult,
  observerIndicationConsult,
  observerScheduleConsult,
} from '../../websokect/patientWebSocket';
import textMinute from '../../extra/minutes';
import Loader from '../../Components/Spinner/Spinner';
import ShowHeaModal from '../../Components/Modal/ShowHeaModal';
import AddHistoryModal from '../../Components/Modal/AddHistoryModal';
import ShowHistoryModal from '../../Components/Modal/ShowHistoryModal';
import AddDiagnosis from '../../Components/Modal/AddDiagnosis';
import AddSchedule from '../../Components/Card/ModalAddconsulting/ModalAddConsulting';
import AddIndications from '../../Components/Modal/AddIndications';
import AddReason from '../../Components/Modal/AddReason';
import RatingModal from '../../Components/Modal/RatingModal';
import { getListMessages } from '../../api/chat';
import {
  addVerifyConsult,
  addVideoconference,
} from '../../redux/actions/videoconference.action';
import ModalMedicalHistory from '../../Components/Modal/ModalMedicalHistory';
// NEW MODALS
import ModalVitalSigns from '../../Components/Modal/ModalVitalSigns';
import ModalNursingNotes from '../../Components/Modal/ModalNursingNotes';
import ModalPhysicalExam from '../../Components/Modal/ModalPhysicalExam';
import ModalMedicalOrders from '../../Components/Modal/ModalMedicalOrders';
import AddHeaModal from '../../Components/Modal/addHEA';
import { addConsult } from '../../utils/isConsult';

import {
  getDoctorReference,
  getReference,
} from '../../redux/actions/references';
import { openModalAddScheduling } from '../../redux/actions/ui.action';
import {
  sendMessage,
  observerMessagePatient,
  observerMessageDoctor,
} from '../../websokect/chatWebsockers';
import { uploadChatFile } from '../../api/chat';
let one = 1;

const Videoconference = ({
  match,
  history,
  addVerifyConsult,
  token,
  user,
  getReference,
  openModalAddScheduling,
  videoconferenceRef,
  openAddSchedule,
  doctorReference,
  addVideoconference,
  getDoctorReference,
}) => {
  const { params } = match;
  const { roomId: ROOM_ID } = params;
  const [myVideoStream, setMyVideoStream] = useState(undefined);
  const [seconds, setSeconds] = useState(0);
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('');
  const [hea, setHea] = useState('');
  const [nursingNote, setNursingNote] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [indications, setIndications] = useState([]);
  const [historyPatient, setHistoryPatient] = useState(undefined);
  const [heaHistory, setHeaHistory] = useState([]);
  const [doctor, setDoctor] = useState();
  const [patient, setPatient] = useState();
  const [loading, setLoading] = useState(true);
  const [limitSeconds, setLimitSeconds] = useState();
  const [setScheduleVideo] = useState();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  useEffect(() => {
    let interval;
    getListMessages({ token: token, idVideo: ROOM_ID })
      .then((response) => {
        const { message } = response;
        setMessages(message);
      })
      .catch((error) => {
        console.log(error);
      });
    if (user?.role === 'doctor') {
      observerMessageDoctor({ idVideo: ROOM_ID }, (data) => {
        const messagesRef = messages;
        messagesRef.push(data);
        setMessages(messagesRef);
      });
    } else if (user?.role === 'patient') {
      observerMessagePatient({ idVideo: ROOM_ID }, (data) => {
        const messagesRef = messages;
        messagesRef.push(data);
        setMessages(messagesRef);
      });
    }
    getOneVideoconference({ token: token, idVideo: ROOM_ID })
      .then((response) => {
        const {
          videoconference,
          historyPatient: dataHistory,
          heaHistory: dataHea,
          patient: dataPatient,
          doctor: dataDoctor,
          maxSecondPatient,
          dataSchedule,
        } = response;

        // agregnado videconferencia a redux
        addVideoconference(videoconference);

        const { secondsPatient, secondsDoctor } = videoconference;
        setScheduleVideo(dataSchedule);
        setHistoryPatient(dataHistory);
        setHeaHistory(dataHea);
        setPatient(dataPatient);
        setDoctor(dataDoctor);
        setLimitSeconds(maxSecondPatient);
        setLoading(false);
        if (user?.role === 'patient') {
          setSeconds(secondsPatient);
        } else {
          setSeconds(secondsDoctor);
        }
        interval = setInterval(() => {
          setSeconds((seconds2) => {
            const total = seconds2 + 1;
            if (maxSecondPatient > total) {
              videoconferencesSeconds({
                roomId: ROOM_ID,
                second: total,
                userId: user?._id,
              });
            } else {
              const body = window.document.getElementById('video-grid');
              body.innerHTML = '';
            }
            return total;
          });
        }, 1000);
      })
      .catch(() => {
        alert('Error al ingresar a la videconferencia.');
        window.location.href = '/dashboard';
      });
    return () => clearInterval(interval);
  }, []);
  if (loading) {
    return <Loader />;
  }

  const handleSendMessage = () => {
    if (user.role === 'doctor') {
      sendMessageDoctor();
    } else {
      sendMessagePatient();
    }
  };
  const sendMessageDoctor = () => {
    if (message) {
      sendMessage(
        {
          idVideo: ROOM_ID,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            const messagesRef = messages;
            messagesRef.push(data.data);
            setMessages(messagesRef);

            const container =
              window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
            setMessage('');
          }
        }
      );
    }
  };
  const sendMessagePatient = () => {
    if (message) {
      sendMessage(
        {
          idVideo: ROOM_ID,
          type: 'text',
          message: message,
          role: user.role,
          sendUser: user._id,
        },
        (data) => {
          if (data.status === 'success') {
            const messagesRef = messages;
            messagesRef.push(data.data);
            setMessages(messagesRef);
            const container =
              window.document.getElementById('container-messages');
            container.scrollTo(0, container.scrollHeight);
            setMessage('');
          }
        }
      );
    }
  };
  const handleUploadFile = () => {
    const { document } = window;
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpg, .jpeg, .pdf';
    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      uploadChatFile({ token: token, file: formData })
        .then((response) => {
          const { file: dataFile } = response;
          sendMessage(
            {
              idVideo: ROOM_ID,
              type: 'file',
              sendUser: user._id,
              idFile: dataFile.id,
              uniqueNameFile: dataFile.filename,
              nameFile: dataFile.originalname,
            },
            (data) => {
              if (data.status === 'success') {
                const messagesRef = messages;
                messagesRef.push(data.data);
                setMessages(messagesRef);
                const container =
                  window.document.getElementById('container-messages');
                container.scrollTo(0, container.scrollHeight);
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    input.click();
  };

  setTimeout(() => {
    if (one === 1) {
      const videoGrid = document.getElementById('video-grid');
      const myPeer = new Peer(undefined, {
        path: '/peerjs',
        host: 'kielsaclinica.com',
        port: 443,
        secure: true,
        config: {
          iceServers: [
            {
              urls: ['stun:stun.l.google.com:19302'],
            },
            {
              urls: [
                'turn:kielsaclinica.com:3478?transport=udp',
                'turn:kielsaclinica.com:3478?transport=tcp',
                'turns:kielsaclinica.com:5349?transport=udp',
                'turns:kielsaclinica.com:5349?transport=tcp',
              ],
              username: 'kielsaclinicaturnserver',
              credential: '4but3GR9vOm4qNQ',
            },
          ],
        },
        debug: 3,
      });
      const myVideo = document.createElement('video');
      if (user.role === 'patient') {
        observerReasonConsult({ idVideo: ROOM_ID }, (reason) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Motivo',
            text: reason,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(reason, 'Motivo', {timeOut: 5000, positionClass: 'toast-bottom-right'});
        });
        observerDiagnosisConsult({ idVideo: ROOM_ID }, (diagnosis) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Diagnóstico',
            text: diagnosis,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(diagnosis, 'Diagnóstico', {timeOut: 5000, positionClass: 'toast-bottom-right'});
        });
        observerScheduleConsult({ idVideo: ROOM_ID }, (schedule) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Cita Próxima',
            text: `${schedule.reason}, ${schedule.date}, ${schedule.hour}`,
            showConfirmButton: false,
            timer: 5000,
          });
          // toastr.info(
          //   `${schedule.reason}, ${schedule.date}, ${schedule.hour}`,
          //   'Cita Próxima',
          //   {timeOut: 5000, positionClass: 'toast-bottom-right'},
          // );
        });
        observerIndicationConsult({ idVideo: ROOM_ID }, (indication) => {
          Swal.fire({
            position: 'top',
            icon: 'info',
            title: 'Indicación',
            text: `${indication.medicine}, ${indication.dose}, ${indication.frequency}`,
            showConfirmButton: false,
            timer: 3000,
          });
          // toastr.info(
          //   `${indication.medicine}, ${indication.dose}, ${indication.frequency}`,
          //   'Indicación',
          //   {timeOut: 5000, positionClass: 'toast-bottom-right'},
          // );
        });
      }
      myVideo.muted = true;
      const peers = {};
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((stream) => {
          stream.getVideoTracks()[0].enabled = false;
          setMyVideoStream(stream);
          addVideoStream(myVideo, stream);
          myPeer.on('call', (call) => {
            call.answer(stream);
            const video = document.createElement('video');
            call.on('stream', (userVideoStream) => {
              addVideoStream(video, userVideoStream);
            });
          });

          socket.on('user-connected', (userId) => {
            console.log('Conexión del usuario', userId);
            connectToNewUser(userId, stream);
          });
          // input value
          const text = $('input');
          // when press enter send message
          $('html').keydown((e) => {
            if (e.which === 13 && text.val().length !== 0) {
              socket.emit('message', {
                message: text.val(),
                userId: user._id,
              });
              text.val('');
            }
          });
          socket.on('createMessage', (doc) => {
            const { message, sendUser } = doc;
            let name = '';
            if (patient.publicData._id === sendUser) {
              name = `${patient.publicData.firstName} ${patient.publicData.lastName}`;
            } else {
              const dr = doctor.publicData.gender === 'male' ? 'Dr.' : 'Dra.';
              name = `${dr} ${doctor.publicData.firstName} ${doctor.publicData.lastName}`;
            }
            $('ul').append(
              `<li className="message"><b className="text-primary">${name}</b><br/>${message}</li>`
            );
            scrollToBottom();
          });
        });

      socket.on('user-disconnected', (userId) => {
        console.log('Se ha salido el usuario');
        if (user?.role === 'patient') {
          Swal.fire({
            position: 'top',
            icon: 'info',
            text: 'El médico ha salido de la llamada.',
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (user?.role === 'doctor') {
          Swal.fire({
            position: 'top',
            icon: 'info',
            text: 'El paciente ha salido de la llamada.',
            showConfirmButton: false,
            timer: 3000,
          });
        }
        if (peers[userId]) peers[userId].close();
      });

      myPeer.on('open', (id) => {
        console.log('Abriendo sala', id);
        socket.emit('join-room', ROOM_ID, id);
        getListMessages({ token: token, idVideo: ROOM_ID }).then((res) => {
          const { message: dataMessages } = res;
          dataMessages.forEach((doc) => {
            const { message, sendUser } = doc;
            let name = '';
            if (patient.publicData._id === sendUser) {
              name = `${patient.publicData.firstName} ${patient.publicData.lastName}`;
            } else {
              const dr = doctor.publicData.gender === 'male' ? 'Dr.' : 'Dra.';
              name = `${dr} ${doctor.publicData.firstName} ${doctor.publicData.lastName}`;
            }
            $('ul').append(
              `<li className="message"><b>${name}</b><br/>${message}</li>`
            );
            scrollToBottom();
          });
        });
      });

      myPeer.on('disconnected', (id) => {
        Swal.fire({
          icon: 'info',
          title: 'Usted se ha desconectado de la consulta ¿Desea reintentar?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            window.location.reload();
          }
        });
      });

      myPeer.on('connection', (id) => {
        console.log('Conexion');
        console.log(id);
      });

      myPeer.on('close', (id) => {
        console.log(id);
      });

      myPeer.on('error', (id) => {
        console.log(id);
        console.log('Error');
        Swal.fire({
          icon: 'info',
          title: 'Usted se ha desconectado de la consulta ¿Desea reintentar?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            window.location.reload();
          }
        });
      });

      function connectToNewUser(userId, stream) {
        const call = myPeer.call(userId, stream);
        const video = document.createElement('video');
        call.on('stream', (userVideoStream) => {
          addVideoStream(video, userVideoStream);
        });
        call.on('close', () => {
          video.remove();
        });

        peers[userId] = call;
      }

      function addVideoStream(video, stream) {
        const container = document.createElement('div');
        container.className = 'container-video-call';
        const imageBackground = document.createElement('img');
        imageBackground.className = 'img-call';
        imageBackground.src = 'https://i.imgur.com/WFCMYqx.png';
        video.srcObject = stream;
        video.addEventListener('loadedmetadata', () => {
          video.play();
        });
        container.append(video);
        container.append(imageBackground);
        videoGrid.append(container);
      }

      const scrollToBottom = () => {
        const d = $('.main__chat_window');
        d.scrollTop(d.prop('scrollHeight'));
      };
      one = 2;
    }
  }, 4000);

  const setPlayVideo = () => {
    const html = `
  <i className="fas fa-video-slash"></i>
    <span>Activar Video</span>
  `;
    document.querySelector('.main__video_button').innerHTML = html;
  };
  const setStopVideo = () => {
    const html = `
    <i className="fas fa-video"></i>
    <span>Quitar Video</span>
  `;
    document.querySelector('.main__video_button').innerHTML = html;
  };

  const setMuteButton = () => {
    const html = `
    <i className="fas fa-microphone"></i>
    <span>Silenciar</span>
  `;
    document.querySelector('.main__mute_button').innerHTML = html;
  };

  const setUnmuteButton = () => {
    const html = `
    <i className="fas fa-microphone-slash"></i>
    <span>Dejar de silenciar</span>
  `;
    document.querySelector('.main__mute_button').innerHTML = html;
  };

  const muteUnmute = () => {
    if (myVideoStream) {
      const { enabled } = myVideoStream.getAudioTracks()[0];
      if (enabled) {
        myVideoStream.getAudioTracks()[0].enabled = false;
        setUnmuteButton();
      } else {
        setMuteButton();
        myVideoStream.getAudioTracks()[0].enabled = true;
      }
    }
  };

  const playStop = () => {
    if (myVideoStream) {
      const { enabled } = myVideoStream.getVideoTracks()[0];
      if (enabled) {
        myVideoStream.getVideoTracks()[0].enabled = false;
        setPlayVideo();
      } else {
        setStopVideo();
        myVideoStream.getVideoTracks()[0].enabled = true;
      }
    }
  };

  const endCall = () => {
    if (user?.role === 'doctor') {
      if (!reason) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún motivo de la consulta.',
          'error'
        );
        return;
      } else if (!diagnosis) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún diagnóstico de la consulta.',
          'error'
        );
        return;
      } else if (!hea) {
        createAlert(
          '¡Error!',
          'No ha ingresado ningún HEA de la consulta.',
          'error'
        );
      }
      Swal.fire({
        icon: 'info',
        title: '¿Estás seguro de salir de la consulta?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((res) => {
        const { isConfirmed } = res;
        if (isConfirmed) {
          endCallVideconference({
            token: token,
            endCallDate: new Date(),
            idVideoconference: ROOM_ID,
            diagnosis: diagnosis,
            indications: indications,
            reason: reason,
            hea: hea,
            nursingNote,
            nursingNote,
          })
            .then(() => {
              addConsult();
              window.location.href = '/dashboard';
            })
            .catch((error) => {
              console.log(error);
              alert('Ocurrió un error al finalizar la videconferencia.');
            });
        }
      });
    } else {
      /* $('#ratingModal').modal('show'); */
      Swal.fire({
        icon: 'info',
        title: '¿Estás seguro de salir de la consulta?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      }).then((res) => {
        const { isConfirmed } = res;
        if (isConfirmed) {
          getOneVideoconference({ token, idVideo: ROOM_ID })
            .then((res) => {
              const { videoconference } = res;
              addVerifyConsult(videoconference);
              window.location.href = '/dashboard/rate';
            })
            .catch((error) => {
              alert('Ocurrió un error al finalizar la videollamada.');
            });
        }
      });
    }
  };

  const handleSubmitReason = (text) => {
    setReason(text);
    sendReasonConsult({ idVideo: ROOM_ID, reason: text });
    $('#Modalmotivo').modal('hide');
  };

  const handleSubmitHea = (text) => {
    setHea(text);
    $('#ModalHeaForm').modal('hide');
  };

  const handleSubmitnursingNote = (text) => {
    setNursingNote(text);
  };

  const handleChangeRating = (number) => {
    setRating(parseFloat(number));
  };

  const handleSubmitDiagnosis = (text) => {
    setDiagnosis(text);
    sendDiagnosisConsult({ idVideo: ROOM_ID, diagnosis: text });
    $('#Modaldiagnostico').modal('hide');
  };

  const handleSubmitIndications = (indication) => {
    sendIndicationPatient({ idVideo: ROOM_ID, indication: indication });
    indications.push(indication);
    setIndications(indications);
  };

  const handleRemoveIndications = (index) => {
    indications.splice(index, 1);
    setIndications(indications);
  };

  const handleSubmitRating = (e) => {
    const commentPatient = e.target.commentPatient.value;
    if (rating) {
      endCallVideconference({
        token: token,
        endCallDate: new Date(),
        idVideoconference: ROOM_ID,
        commentPatient: commentPatient,
        rating: rating,
      })
        .then(() => {
          $('#ratingModal').modal('hide');
          history.push('/dashboard');
        })
        .catch((error) => {
          console.log(error);
          alert('Ocurrió un error al finalizar la videconferencia.');
        });
    }
  };

  const handleUpdateData = () => {
    getOneVideoconference({ token: token, idVideo: ROOM_ID }).then((res) => {
      const { historyPatient } = res;
      setHistoryPatient(historyPatient);
    });
  };

  const handleSendSchedule = (schedule) => {
    sendSchedulePatient({ idVideo: ROOM_ID, schedule: schedule });
    setScheduleVideo(schedule);
  };

  const handleCancelVideoconference = () => {
    Swal.fire({
      title: 'Escriba el motivo de la cancelación.',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Si',
      showLoaderOnConfirm: true,
      cancelButtonText: 'No',
      preConfirm: (reasonCancel) =>
        cancelVideoconference({
          token: token,
          reasonCancel: reasonCancel,
          _id: ROOM_ID,
        })
          .then((res) => res)
          .catch((error) => {
            if (error.info) {
              Swal.showValidationMessage(error.info);
            } else {
              Swal.showValidationMessage(
                'Error al cancelar la consulta presencial.'
              );
            }
          }),
    }).then((result) => {
      if (result.isConfirmed) {
        addConsult();
        window.location.href = '/dashboard';
      }
    });
  };

  return (
    <div className='main'>
      {openAddSchedule && doctorReference !== null && <AddSchedule />}
      <div className='main__left'>
        <div className='main__videos'>
          {/* Web View */}
          <div className='d-none d-sm-block'>
            <div className='timer'>
              {' '}
              <span>
                {limitSeconds > seconds
                  ? textMinute(seconds)
                  : 'La consulta ya alcanzó el tiempo estimado.'}
              </span>
            </div>
          </div>
          {/* Fin Web View */}
          <div id='video-grid' />
        </div>
        <ModalMedicalHistory
          updateData={handleUpdateData}
          historyPatient={historyPatient}
          patientId={patient?.publicData._id}
          idVideo={ROOM_ID}
        />
        <AddHeaModal hea={hea} handleHea={handleSubmitHea} />
        <div className='container-fluid mt-4 mb-4'>
          {/* Mobile View */}
          <div className='d-block d-sm-none'>
            <p className='text-center'>
              Minutos de Llamada{' '}
              {limitSeconds > seconds
                ? textMinute(seconds)
                : 'La consulta ya alcanzó el tiempo estimado'}
            </p>
          </div>
          {/* Fin Mobile View */}
          <div className='row row-cols-2 row-cols-sm-4 row-cols-md-4 justify-content-center'>
            <div className='col mb-2'>
              <button
                type='button'
                onClick={() => muteUnmute()}
                className='btn btn-info btn-block main__mute_button'
                style={{ borderRadius: '0.8rem', height: '77px' }}
              >
                <i className='fas fa-microphone mr-2 fa-2x' />
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small> Silenciar</small>
                  </span>
                </div>
                {/* Fin Web View */}
              </button>
              {/* <button
              type="button"
              onClick={() => playStop()}
              className="main__controls__button main__video_button"
              >
              <i className="fas fa-video" />
              <span>Quitar Video</span>
            </button> */}
            </div>{' '}
            {user?.role === 'doctor' && (
              <>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#Modalmotivo'
                    data-whatever='@getbootstrap'
                  >
                    {reason && (
                      <i className='fas fa-check-square check-doctor fa-2x text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/motivo-consulta.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  {' '}
                  <button
                    className='btn btn-link btn-block border text-secondarymdv bg-white'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#ModalHeaForm'
                    data-whatever='@getbootstrap'
                  >
                    {hea && (
                      <i className='fas fa-check-square check-doctor fa-2x text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/HEA.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                {/* <div className="col mb-2">
              <button
                className="btn-video"
                type="button"
                data-toggle="modal"
                data-target="#Modalhistorial"
                data-whatever="@getbootstrap"
                >
                <i className="far fa-address-card edit-vi" />
                <span> &nbsp;Historial</span>
              </button>
            </div> */}
                {/* <div className="col mb-2">
                <button
                  className="btn btn-link btn-block border text-secondarymdv bg-white"
                  style={{ borderRadius: '0.8rem' }}
                  type="button"
                  data-toggle="modal"
                  data-target="#ModalVitalSigns"
                  data-whatever="@getbootstrap"
                  >
                  <img
                    src={require('../../assets/img/icons/signos-vitales.png')}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '100px' }}
                    />
                </button>
              </div> */}
                {/* <div className="col mb-2">
                <button
                  className="btn btn-link btn-block border text-secondarymdv bg-white"
                  style={{ borderRadius: '0.8rem' }}
                  type="button"
                  data-toggle="modal"
                  data-target="#ModalPhysicalExam"
                  data-whatever="@getbootstrap"
                  >
                  <img
                    src={require('../../assets/img/icons/examen-fisico.png')}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '100px' }}
                    />
                </button>
              </div> */}
                <div className='col mb-2'>
                  <button
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#Modaldiagnostico'
                    data-whatever='@getbootstrap'
                  >
                    {diagnosis && (
                      <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/Diagnóstico.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    type='button'
                    data-target='#Modalindicaciones'
                    data-whatever='@getbootstrap'
                  >
                    {indications.length > 0 && (
                      <i className='fas fa-check-square fa-2x check-doctor text-success mr-2' />
                    )}
                    <img
                      src={require('../../assets/img/icons/Indicación.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    type='button'
                    data-toggle='modal'
                    data-target='#ModalMedicalOrders'
                    data-whatever='@getbootstrap'
                  >
                    <img
                      src={require('../../assets/img/icons/ordenes-medicas.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                {/* <div className="col mb-2">
                <button
                  className="btn btn-link border text-secondarymdv bg-white btn-block"
                  style={{ borderRadius: '0.8rem' }}
                  type="button"
                  data-toggle="modal"
                  data-target="#ModalNursingNotes"
                  data-whatever="@getbootstrap"
                  >
                     {
                      nursingNote && (
                        <i className="fas fa-check-square fa-2x check-doctor text-success mr-2" />
                      )
                    }
                  <img
                    src={require('../../assets/img/icons/notas-enfermeria.png')}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '100px' }}
                    />
                </button>
              </div> */}
                {/* <div className="col mb-2">
                <button
                  className="btn btn-link border text-secondarymdv bg-white btn-block"
                  style={{ borderRadius: '0.8rem' }}
                  type="button"
                  data-toggle="modal"
                  data-target="#Modalagenda"
                  data-whatever="@getbootstrap"
                  >
                  <img
                    src={require('../../assets/img/icons/notas-enfermeria.png')}
                    alt="logo"
                    className="img-fluid"
                    style={{ width: '100px' }}
                    />
                </button>
              </div> */}
                <div className='col mb-2'>
                  <button
                    type='button'
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    onClick={() => {
                      openModalAddScheduling();
                      getDoctorReference(videoconferenceRef.doctor);
                      getReference(videoconferenceRef.patient);
                    }}
                  >
                    <img
                      src={require('../../assets/img/icons/agendar-cita.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
                <div className='col mb-2'>
                  <button
                    type='button'
                    className='btn btn-link border text-secondarymdv bg-white btn-block'
                    style={{ borderRadius: '0.8rem' }}
                    data-toggle='modal'
                    data-target='#ModalMedicalPatient'
                    onClick={() => {
                      getReference(videoconferenceRef.patient);
                    }}
                  >
                    <img
                      src={require('../../assets/img/icons/Historial.png')}
                      alt='logo'
                      className='img-fluid'
                      style={{ width: '100px' }}
                    />
                  </button>
                </div>
              </>
            )}
            <div className='col mb-2'>
              <button
                type='button'
                onClick={() => endCall()}
                className='btn btn-light btn-block border bg-danger text-white'
                style={{ borderRadius: '0.8rem', height: '77px' }}
              >
                <i className='fas fa-sign-out-alt fa-2x mr-2' />
                {/* Web View */}
                <div className='d-none d-sm-block'>
                  <span>
                    <small> Finalizar </small>
                  </span>
                </div>
                {/* Fin Web View */}
              </button>
            </div>
            {user?.role === 'doctor' && (
              <div className='col mb-2'>
                <button
                  type='button'
                  onClick={() => handleCancelVideoconference()}
                  className='btn btn-light btn-block border bg-secondary text-white'
                  style={{ borderRadius: '0.8rem', height: '77px' }}
                >
                  <i className='fas fa-power-off fa-2x text-white mr-2' />
                  {/* Web View */}
                  <div className='d-none d-sm-block'>
                    <span>
                      <small>Cancelar</small>
                    </span>
                  </div>
                  {/* Fin Web View */}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className='main__right'
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <div className='main__header'>
          {/* icono de mdv solo se muestra en web */}
          <div className='d-none d-sm-block'>
            <img
              src={require('../../assets/img/KCL.png')}
              alt='logo'
              className='img-fluid'
              style={{ width: '128px' }}
            />
          </div>
          <h5 className='text-primary font-weight-bold mt-2 mb-3'>Chat</h5>
        </div>
        <div
          className='main__chat_window'
          style={{ flex: 1, overflowY: 'auto' }}
        >
          <div id='container-messages' className='msg_history'>
            {messages.map((doc) => {
              if (doc.sendUser === user._id) {
                if (doc.type === 'text') {
                  return (
                    <div className='outgoing_msg'>
                      <div className='sent_msg'>
                        <p>{doc.message}</p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='outgoing_msg'>
                      <div className='sent_msg'>
                        <p>
                          <i className='fas fa-file-alt' />{' '}
                          <a
                            target='_blank'
                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                          >
                            {doc.nameFile}
                          </a>
                        </p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  );
                }
              } else if (doc.type === 'text') {
                return (
                  <div className='incoming_msg'>
                    <div className='incoming_msg_img'>
                      {' '}
                      <img
                        className='img-chat'
                        src={
                          patient?.publicData.image
                            ? `/api/profile/image/${patient?.publicData.image}`
                            : require('../../assets/img/avatars/avatar-doc-male.png')
                        }
                        alt='sunil'
                      />{' '}
                    </div>
                    <div className='received_msg'>
                      <div className='received_withd_msg'>
                        <p>{doc.message}</p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className='incoming_msg'>
                    <div className='incoming_msg_img'>
                      {' '}
                      <img
                        className='img-chat'
                        src={
                          patient?.publicData.image
                            ? `/api/profile/image/${patient?.publicData.image}`
                            : require('../../assets/img/avatars/avatar-doc-male.png')
                        }
                        alt='sunil'
                      />{' '}
                    </div>
                    <div className='received_msg'>
                      <div className='received_withd_msg'>
                        <p>
                          <i className='fas fa-file-alt' />{' '}
                          <a
                            target='_blank'
                            href={`/api/chat/file/${doc.uniqueNameFile}`}
                          >
                            {doc.nameFile}
                          </a>
                        </p>
                        <span className='time_date'>
                          {' '}
                          11:01 AM | Hoy{' '}
                          <i className='fas fa-check-double doublecheck' />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className='type_msg pt-3' style={{ display: 'flex' }}>
          <div className='align-i' style={{ flex: 0.1 }}>
            <button type='button' className='btn' onClick={handleUploadFile}>
              <i className='fas fa-paperclip text-secondarymdv fa-2x' />
            </button>
          </div>
          <div style={{ flex: 0.8 }}>
            <input
              id='chat_message'
              className='form-control form-control-lg'
              type='text'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Escriba un mensaje...'
            />
            <button className='btn msg_send_btn' onClick={handleSendMessage}>
              <i className='far fa-paper-plane text-white' />
            </button>
          </div>
          <div style={{ flex: 0.1, paddingTop: 30 }}>
            <button className='btn msg_send_btn' onClick={handleSendMessage}>
              <i className='far fa-paper-plane text-white' />
            </button>
          </div>
        </div>
      </div>
      <ModalVitalSigns />
      <ModalNursingNotes
        nursingNote={nursingNote}
        handleNursingNote={handleSubmitnursingNote}
      />
      <ModalPhysicalExam />
      <ModalMedicalOrders />
      <ShowHeaModal heaHistory={heaHistory} />
      <AddHistoryModal />
      <ShowHistoryModal historyPatient={historyPatient} />
      <AddDiagnosis
        handleDiagnosis={handleSubmitDiagnosis}
        diagnosis={diagnosis}
      />
      <AddIndications
        indications={indications}
        handleSubmitIndication={handleSubmitIndications}
        handleRemoveIndications={handleRemoveIndications}
      />
      <AddReason handleReason={handleSubmitReason} reason={reason} />
      <RatingModal
        handleSubmit={handleSubmitRating}
        ratingChanged={handleChangeRating}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  doctorReference: state.reference.doctor,
  openAddSchedule: state.ui.schedulingModal.open,
  videoconferenceRef: state.videoconference.videoconference,
});

let oneFather = 0;

const Father = (props) => {
  const { token, user } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  if (oneFather === 0) {
    console.log(user);
    if (user?.role === 'patient') {
      navigator.mediaDevices.getUserMedia(
        { video: true, audio: true },
        () => {
          setLoading(false);
          oneFather = 1;
        },
        () => {
          createAlert(
            '¡Error!',
            'Su computadora no posee cámara o micrófono.'
          ).then(() => {
            setError(true);
          });
        }
      );
    } else if (user?.role === 'doctor') {
      navigator.mediaDevices.getUserMedia(
        { audio: true, video: true },
        () => {
          setTimeout(() => {
            setLoading(false);
            oneFather = 1;
          }, 4000);
        },
        () => {
          createAlert(
            '¡Error!',
            'Su computadora no posee cámara o micrófono.'
          ).then(() => {
            setError(true);
          });
        }
      );
    }
  }
  if (!token || error) {
    return <Redirect to='/' />;
  }
  if (token && !user) {
    return <Loader />;
  }
  if (loading) {
    return <Loader />;
  }
  return <Videoconference {...props} />;
};

export default connect(mapStateToProps, {
  addVerifyConsult,
  getReference,
  openModalAddScheduling,
  addVideoconference,
  getDoctorReference,
})(Father);
