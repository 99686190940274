/* eslint-disable comma-dangle */
/* eslint-disable func-names */
/* eslint-disable arrow-parens */
import {
  GET_MEDICINES,
  DELETE_MEDICINE,
  UPDATE_MEDICINE,
  ADD_MEDICINE,
  ERROR_ADD_MEDICINE,
  DELETE_SELECTED_ITEM,
  ADD_SELECTED_ITEM,
  ADD_HEALTH,
  DELETE_HEALTH,
  GET_HEALTH,
  ADD_ALLERGIE,
  GET_ALLERGIES,
  DELETE_ALLERGIE,
  GET_SURGERIES,
  ADD_SURGERIES,
  DELETE_SURGERIES,
  UPDATE_SURGERIES,
  ERROR_SURGERIES,
  ADD_ITEM_SURGERIES,
  DELETE_ITEM_SURGERIES,
  GET_VACCINATE,
  ADD_VACCINATE,
  DELETE_VACCINATE,
  GET_LIFESTYLE,
  ADD_LIFESTYLE,
  DELETE_LIFESTYLE,
  GET_FAMILIAR,
  ADD_FAMILIAR,
  DELETE_FAMILIAR,
  UPDATE_FAMILIAR,
  DELETE_SELECTED_FAMILIAR,
  ADD_SELECTED_FAMILIAR,
  UPLOAD_EXAM,
  DELETE_EXAM,
  GET_EXAMS,
  ADD_CLINICAL,
  GET_CLINICAL,
  DELETE_CLINICAL,
  GET_QUERY_HISTORY,
  HISTORY_SELECT,
  UPDATE_VACCINATE,
  SELECTED_VACCINATE,
  UPDATE_ALLERGIE,
  SELECTED_ALLERGIE,
  UPDATE_OTHER_FAMILIAR,
  GET_VACCINATE_DEF,
  GET_ALLERGIES_DEF,
  GET_HEALTH_DEF,
  GET_FAMILIAR_DEF,
  GET_ALL_LIFE_STYLE_DEF,
  UPDATE_DEF_HEALTH_CHECK,
  UPDATE_DEF_FAMILIAR_CHECK,
  UPDATE_DEF_ALLERGIES_CHECK,
  UPDATE_DEF_VACCINATE_CHECK,
  UPDATE_DEF_LIFESTYLE_CHECK,
  ENABLE_OTHER_FAMILIAR,
  DISABLE_OTHER_FAMILIAR,
  SET_SELECTED_HEALTH,
  UPDATE_HEALTH,
  CLEAR_HISTORY,
} from '../types';

const initialState = {
  medicines: {
    items: [],
    selectedItem: null,
  },
  health: {
    items: [],
    selected: null,
    def: [],
  },
  allergies: {
    items: [],
    selected: null,
    def: [],
  },
  exams: {
    items: [],
  },
  vaccinate: {
    items: [],
    selected: null,
    def: [],
  },
  lifeStyle: {
    items: [],
    selected: null,
    def: [],
  },
  familiar: {
    items: [],
    selectedItem: null,
    modal: false,
    def: [],
  },
  clinical: {
    items: [],
  },
  surgeries: {
    items: [],
    selectedItem: null,
  },
  queryHistory: {
    items: [],
    selectedItem: null,
  },
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          selected: payload,
        },
      };

    case UPDATE_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          items: state.health.items.map((h) => {
            if (h.name === payload.name) {
              return {
                ...h,
                other: payload.other,
              };
            }
            return h;
          }),
        },
      };

    case ENABLE_OTHER_FAMILIAR:
    case DISABLE_OTHER_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          modal: payload,
        },
      };
    case UPDATE_DEF_LIFESTYLE_CHECK:
      return {
        ...state,
        lifeStyle: {
          ...state.lifeStyle,
          def: state.lifeStyle.def.map((life) => {
            if (life.name === payload.name) {
              return {
                ...life,
                checked: payload.checked,
              };
            }
            return life;
          }),
        },
      };
    case UPDATE_DEF_FAMILIAR_CHECK:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          def: state.familiar.def.map((familia) => {
            if (familia.name === payload.name) {
              return {
                ...familia,
                checked: payload.checked,
              };
            }
            return familia;
          }),
        },
      };

    case UPDATE_DEF_HEALTH_CHECK:
      return {
        ...state,
        health: {
          ...state.health,
          def: state.health.def.map((h) => {
            if (h.name === payload.name) {
              return {
                ...h,
                checked: payload.checked,
              };
            }
            return h;
          }),
        },
      };
    case UPDATE_DEF_ALLERGIES_CHECK:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          def: state.allergies.def.map((allergie) => {
            if (allergie.name === payload.name) {
              return {
                ...allergie,
                checked: payload.checked,
              };
            }
            return allergie;
          }),
        },
      };
    case UPDATE_DEF_VACCINATE_CHECK:
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          def: state.vaccinate.def.map((vaccinate) => {
            if (vaccinate.name === payload.name) {
              return {
                ...vaccinate,
                checked: payload.checked,
              };
            }
            return vaccinate;
          }),
        },
      };

    case UPDATE_OTHER_FAMILIAR:
      return {
        ...state,
        items: state.familiar.items.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              other: item.other,
            };
          }
          return item;
        }),
      };

    case GET_MEDICINES:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          items: payload,
        },
      };

    case SELECTED_VACCINATE:
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          selected: payload,
        },
      };

    case SELECTED_ALLERGIE:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          selected: payload,
        },
      };
    case ADD_MEDICINE:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          items: [...state.medicines.items, payload],
        },
      };
    case DELETE_MEDICINE:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          items: state.medicines.items.filter(
            (medicine) => medicine._id !== payload
          ),
        },
      };
    case UPDATE_MEDICINE:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          items: state.medicines.items.map((medicine) => {
            if (medicine.id === payload.id) {
              return {
                ...medicine,
                name: payload.name,
                dose: payload.dose,
                frequency: payload.frequency,
              };
            }
            return medicine;
          }),
        },
      };
    case ERROR_ADD_MEDICINE:
      return state;

    case ADD_SELECTED_ITEM:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          selectedItem: payload,
        },
      };

    case DELETE_SELECTED_ITEM:
      return {
        ...state,
        medicines: {
          ...state.medicines,
          selectedItem: null,
        },
      };

    // health reducers
    case ADD_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          items: [...state.health.items, payload],
        },
      };

    case GET_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          items: payload,
        },
      };

    case GET_HEALTH_DEF:
      return {
        ...state,
        health: {
          ...state.health,
          def: payload,
        },
      };

    case DELETE_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          items: state.health.items.filter((health) => health._id !== payload),
        },
      };

    // allergie reducers
    case ADD_ALLERGIE:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          items: [...state.allergies.items, payload],
        },
      };

    case GET_ALLERGIES:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          items: payload,
        },
      };

    case GET_ALLERGIES_DEF:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          def: payload,
        },
      };

    case DELETE_ALLERGIE:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          items: state.allergies.items.filter(
            (allergie) => allergie._id !== payload
          ),
        },
      };

    // exams
    case GET_EXAMS:
      return {
        ...state,
        exams: {
          ...state.exams,
          items: payload,
        },
      };

    case UPLOAD_EXAM:
      return state;

    case DELETE_EXAM:
      return state;

    // surgeries
    case GET_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          items: payload,
        },
      };
    case ADD_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          items: [...state.surgeries.items, payload],
        },
      };
    case DELETE_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          items: state.surgeries.items.filter(
            (surgerie) => surgerie._id !== payload
          ),
        },
      };
    case UPDATE_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          items: state.surgeries.items.map((surgerie) => {
            if (surgerie.id === payload.id) {
              return {
                ...surgerie,
                name: payload.name,
                year: payload.year,
              };
            }
            return surgerie;
          }),
        },
      };
    case ERROR_SURGERIES:
      return state;

    case ADD_ITEM_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          selectedItem: payload,
        },
      };

    case DELETE_ITEM_SURGERIES:
      return {
        ...state,
        surgeries: {
          ...state.surgeries,
          selectedItem: null,
        },
      };

    // antecedentes hereditarios
    case GET_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          items: payload,
        },
      };

    case GET_FAMILIAR_DEF:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          def: payload,
        },
      };

    case ADD_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          items: [...state.familiar.items, payload],
        },
      };

    case DELETE_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          items: state.familiar.items.filter(
            (familiar) => familiar._id !== payload
          ),
        },
      };

    case ADD_SELECTED_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          selectedItem: payload,
        },
      };
    case UPDATE_FAMILIAR: {
      return {
        ...state,
        familiar: {
          ...state.familiar,
          items: state.familiar.items.map((familia) => {
            if (familia._id === payload._id) {
              return {
                ...familia,
                relation: payload.relation,
                other: payload.other,
              };
            }
            return familia;
          }),
        },
      };
    }
    case ADD_VACCINATE: {
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          items: [...state.vaccinate.items, payload],
        },
      };
    }
    case UPDATE_VACCINATE:
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          items: state.vaccinate.items.map((vaccinate) => {
            if (vaccinate._id === payload._id) {
              return {
                ...vaccinate,
                other: payload.other,
              };
            }
            return vaccinate;
          }),
        },
      };
    case UPDATE_ALLERGIE:
      return {
        ...state,
        allergies: {
          ...state.allergies,
          items: state.allergies.items.map((allergie) => {
            if (allergie._id === payload._id) {
              return {
                ...allergie,
                other: payload.other,
              };
            }
            return allergie;
          }),
        },
      };
    case GET_VACCINATE: {
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          items: payload,
        },
      };
    }

    case GET_VACCINATE_DEF:
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          def: payload,
        },
      };

    case DELETE_VACCINATE: {
      return {
        ...state,
        vaccinate: {
          ...state.vaccinate,
          items: state.vaccinate.items.filter(
            (vaccinate) => vaccinate._id !== payload
          ),
        },
      };
    }
    case ADD_LIFESTYLE: {
      return {
        ...state,
        lifeStyle: {
          ...state.lifeStyle,
          items: [...state.lifeStyle.items, payload],
        },
      };
    }
    case GET_LIFESTYLE:
      return {
        ...state,
        lifeStyle: {
          ...state.lifeStyle,
          items: payload,
        },
      };

    case GET_ALL_LIFE_STYLE_DEF:
      return {
        ...state,
        lifeStyle: {
          ...state.lifeStyle,
          def: payload,
        },
      };

    case DELETE_LIFESTYLE: {
      return {
        ...state,
        lifeStyle: {
          ...state.lifeStyle,
          items: state.lifeStyle.items.filter(
            (lifeStyle) => lifeStyle._id !== payload
          ),
        },
      };
    }

    case DELETE_SELECTED_FAMILIAR:
      return {
        ...state,
        familiar: {
          ...state.familiar,
          selectedItem: payload,
        },
      };

    case ADD_CLINICAL: {
      return {
        ...state,
        clinical: {
          ...state.clinical,
          items: [...state.clinical.items, payload],
        },
      };
    }
    case GET_CLINICAL: {
      return {
        ...state,
        clinical: {
          ...state.clinical,
          items: payload,
        },
      };
    }
    case DELETE_CLINICAL: {
      return {
        ...state,
        clinical: {
          ...state.clinical,
          items: state.clinical.items.filter(
            (clinical) => clinical._id !== payload
          ),
        },
      };
    }
    case GET_QUERY_HISTORY: {
      return {
        ...state,
        queryHistory: {
          ...state.queryHistory,
          items: payload,
        },
      };
    }
    case HISTORY_SELECT:
      return {
        ...state,
        queryHistory: {
          ...state.queryHistory,
          selectedItem: payload,
        },
      };

    case CLEAR_HISTORY:
      return {
        ...state,
        medicines: {
          items: [],
          selectedItem: null,
        },
        health: {
          items: [],
          selected: null,
          def: [],
        },
        allergies: {
          items: [],
          selected: null,
          def: [],
        },
        exams: {
          items: [],
        },
        vaccinate: {
          items: [],
          selected: null,
          def: [],
        },
        lifeStyle: {
          items: [],
          selected: null,
          def: [],
        },
        familiar: {
          items: [],
          selectedItem: null,
          modal: false,
          def: [],
        },
        clinical: {
          items: [],
        },
        surgeries: {
          items: [],
          selectedItem: null,
        },
        queryHistory: {
          items: [],
          selectedItem: null,
        },
      };

    default:
      return state;
  }
}
