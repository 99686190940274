/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {Link} from 'react-router-dom';

function PharmaKielsa() {
  return (
    <section style={{paddingTop: '100px', paddingBottom: '100px'}} id="pharmakielsa">
      {/* vista Web */}
      <div className="container d-none d-sm-none d-md-block">
        <div className="row justify-content-center">
          <div className="col-6 col-md-5 mb-2 animate__animated animate__fadeInLeft">
            <img
              src={require('../../assets/img/kielsamovil.png')}
              alt="kielsamovil"
              width="300"
              style={{borderRadius: '1.6rem'}}
              className="img-fluid shadow-sm"
            />
          </div>
          <div className="col-12 col-md-7 mb-2 animate__animated animate__fadeInRight">
            <h1 className="mt-5 text-primary font-weight-bold display-4">¿Necesitas ofertas especiales?</h1>
            <p style={{fontSize: '20px'}} className="text-justify">
              Descarga nuestra app de Farmacias Kielsa donde reinventamos
              tu experiencia de compra. Encuentra un amplio surtido de medicamentos y productos a un solo clic.
            </p>
            <h5 className="text-secondarymdv font-weight-bold mb-3">¡Descárgala ya!</h5>
            <div className="d-flex">
              <Link
                to={{pathname: 'https://play.google.com/store/apps/details?id=com.kielsaecommerce'}}
                target="_blank"
                type="button"
              >
                <img
                  src={require('../../assets/img/vectores/playstore-white.svg')}
                  alt="googleplay"
                  width="150"
                  style={{borderRadius: '0.5rem'}}
                  className="img-fluid mr-2 shadow-sm"
                />
              </Link>
              <Link
                to={{pathname: 'https://apps.apple.com/hn/app/farmacias-kielsa/id1522065833'}}
                target="_blank"
                type="button"
              >
                <img
                  src={require('../../assets/img/vectores/applestore-white.svg')}
                  alt="applestore"
                  width="150"
                  style={{borderRadius: '0.5rem'}}
                  className="img-fluid mr-2 shadow-sm"
                />
              </Link>
              <Link
                to={{pathname: 'https://appgallery.huawei.com/#/app/C102750223?locale=es_US&source=appshare&subsource=C102750223'}}
                target="_blank"
                type="button"
              >
                <img
                  src={require('../../assets/img/vectores/appgallery-white.svg')}
                  alt="appgaleryhuawei"
                  style={{borderRadius: '0.5rem'}}
                  width="150"
                  className="img-fluid mr-2 shadow-sm"
                />
              </Link>
            </div>
            <p style={{fontSize: '20px'}} className="text-justify mt-4">
              También puedes comprar en línea a través de nuestro
              <h5 data-aos="fade-right" className="text-secondarymdv font-weight-bold">¡Sitio Web!</h5>
            </p>
            <Link
              to={{pathname: 'https://www.kielsa.com'}}
              target="_blank"
              type="button"
              className="btn btn-yellow shadow-sm btn-lg"
              style={{borderRadius: '0.5rem'}}
            >
              <img
                src={require('../../assets/img/icons/farmaciaskielsa.png')}
                alt="quienessomos"
                width="250"
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* vista móvil */}
      <div className="container d-block d-sm-block d-md-none">
        <div className="row justify-content-center">
          <div className="col-12 mb-2 animate__animated animate__fadeIn">
            <h3 className="text-primary text-center font-weight-bold ">¿Necesitas ofertas especiales?</h3>
            <p style={{fontSize: '15px'}} className="text-center">
              Descarga nuestra app de Farmacias Kielsa donde reinventamos
              tu experiencia de compra. Encuentra un amplio surtido de medicamentos y productos a un solo clic.
            </p>
            <img
              src={require('../../assets/img/kielsamovil.png')}
              alt="kielsamovil"
              width="200"
              style={{borderRadius: '1.6rem'}}
              className="img-fluid shadow-sm m-auto d-block"
            />
          </div>
          <div className="col-12 mb-2 animate__animated animate__fadeIn">
            <h5 className="text-secondarymdv font-weight-bold mb-3 mt-3 text-center">¡Descárgala ya!</h5>
            <div className="d-flex">
              <Link
                to={{pathname: 'https://play.google.com/store/apps/details?id=com.kielsaecommerce'}}
                target="_blank"
                type="button"
                className="mr-2"
              >
                <img
                  src={require('../../assets/img/vectores/playstore-white.svg')}
                  alt="googleplay"
                  width="150"
                  style={{borderRadius: '0.5rem'}}
                  className="img-fluid shadow-sm"
                />
              </Link>
              <Link
                to={{pathname: 'https://apps.apple.com/hn/app/farmacias-kielsa/id1522065833'}}
                target="_blank"
                type="button"
                className="mr-2"
              >
                <img
                  src={require('../../assets/img/vectores/applestore-white.svg')}
                  alt="applestore"
                  width="150"
                  style={{borderRadius: '0.5rem'}}
                  className="img-fluid shadow-sm"
                />
              </Link>
              <Link
                to={{pathname: 'https://appgallery.huawei.com/#/app/C102750223?locale=es_US&source=appshare&subsource=C102750223'}}
                target="_blank"
                type="button"
                className="mr-2"
              >
                <img
                  src={require('../../assets/img/vectores/appgallery-white.svg')}
                  alt="appgaleryhuawei"
                  style={{borderRadius: '0.5rem'}}
                  width="150"
                  className="img-fluid shadow-sm"
                />
              </Link>
            </div>
            <p style={{fontSize: '15px'}} className="text-center mt-3">
              También puedes comprar en línea a través de nuestro
              <h5 className="text-secondarymdv text-center font-weight-bold mt-3">¡Sitio Web!</h5>
            </p>
            <Link
              to={{pathname: 'https://www.kielsa.com'}}
              target="_blank"
              type="button"
              className="btn btn-yellow shadow-sm btn-lg m-auto d-block w-75"
              style={{borderRadius: '0.5rem'}}
            >
              <img
                src={require('../../assets/img/icons/farmaciaskielsa.png')}
                alt="quienessomos"
                width="180"
                className="img-fluid"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PharmaKielsa;
