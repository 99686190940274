import {useState,useEffect} from 'react';
import { getAllPackage } from '../../../../api/packagektm';


export const usePackage = () => {
    const [packages, setPackage] = useState([]);
    useEffect(() => {
        getAllPackage().then((res) => {
          setPackage(res.data);
        });
      }, []);
      return {
        packages
      }
}
