/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { CgClose } from 'react-icons/all';
import axios from 'axios';
import createAlert from '../../../redux/actions/alert.action';
import '../../../stylesheets/scheduling.css';
import {
  addSchedule,
  resetPresential,
  resetOnline,
} from '../../../redux/actions/scheduling.action';
import {
  getStatusTimeDoctor,
  disableLoadedStatusTime,
} from '../../../redux/actions/time.status.action';
import { closeModalAddScheduling } from '../../../redux/actions/ui.action';

import {
  deleteReference,
  deleteReferenceTimeDoctor,
  deleteConsultoriesReference,
  getReferenceTimeDoctor,
  deleteDoctorReference,
  clearAllAppointment,
} from '../../../redux/actions/references';

import {
  resetConsultory,
  deleteAllConsultoryDoctors,
} from '../../../redux/actions/consultories.action';

import { dias, meses } from '../../../utils/calendar';
import useModalAddConsultingState from './state/useModalAddConsultingState';
import useFarinterSelector from '../../../hooks/useFarinterSelector';

// eslint-disable-next-line arrow-body-style
const AddConsultingModal = ({
  addSchedule,
  modalAddScheduling,
  closeModalAddScheduling,
  user: { firstName, lastName },
  user,
  deleteReference,
  doctorReference,
  doctorTimetable,
  deleteReferenceTimeDoctor,
  resetPresential,
  resetOnline,
  getStatusTimeDoctor,
  loaded,
  disableLoadedStatusTime,
  timeStatusDoctor,
  deleteConsultoriesReference,
  consultories,
  getReferenceTimeDoctor,
  selectedConsultory,
  resetConsultory,
  deleteAllConsultoryDoctors,
  videoconferenceRef,
  deleteDoctorReference,
  clearAllAppointment,
}) => {
  // agregar la fecha por defecto en la fecha que se mostrará en el componente
  const [formData, setFormData] = useState({
    reason: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
    hour: '',
    consultationType: '',
  });
  const [tempDate, setTempDate] = useState('');
  const { reason, date, hour, consultationType } = formData;
  const [confirm, setConfirm] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [patient, setPatient] = useState();
  const [selectConsultory, setSelectConsultory] = useState(null);

  const {
    getAllUserAppointment,
    handleGetType,
    handleIsChecked,
    isChecked,
    handleChangeSelectedAppointment,
    handleDeleteAppointmentReference,
  } = useModalAddConsultingState();
  const { appointments } = useFarinterSelector().reference;

  useEffect(() => {
    if (selectedConsultory !== null) {
      doctorReference &&
        getReferenceTimeDoctor(doctorReference?._id, selectedConsultory._id);
      setSelectConsultory(selectedConsultory);
    }
  }, []);

  useEffect(() => {
    if (doctorTimetable !== null) {
      // recorrer horario de doctor
      const tempHorario = [];

      doctorTimetable.forEach((horario) => {
        // initial hour
        let initial = parseInt(horario.from, 10);
        const final = parseInt(horario.to, 10);

        while (initial < final) {
          // para los primeros 20 minutos
          const primerosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:00`
              : `${initial}:00`;
          tempHorario.push({
            day: horario.day,
            hour: primerosVeinteMinutos,
          });
          // para los siguientes 20 minutos
          const segundosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:20`
              : `${initial}:20`;
          tempHorario.push({
            day: horario.day,
            hour: segundosVeinteMinutos,
          });
          // ultimos 20 minutos
          const ultimosVeinteMinutos =
            initial.toString().length === 1
              ? `0${initial}:40`
              : `${initial}:40`;
          tempHorario.push({
            day: horario.day,
            hour: ultimosVeinteMinutos,
          });
          initial += 1;
        }
      });
      setHorarios(tempHorario);

      // colocar hora del dia seleccionado
      disableLoadedStatusTime();
      const tempDays = [];
      let temp = moment(date);

      // let temp = moment(new Date(pivot.clone().add(pivot.day() * -1, "day")));
      tempDays.push(temp);

      for (let i = 0; i < 6; i++) {
        temp = moment(new Date(temp.clone().add(1, 'day')));
        tempDays.push(temp);
      }
      setWeekDays(tempDays);
      getStatusTimeDoctor(tempDays, doctorReference._id);
    }
  }, [doctorTimetable]);

  useEffect(() => {
    const getPatient = async () => {
      const resp = await axios.get(
        `/api/users/id/${videoconferenceRef?.patient}`
      );
      setPatient(resp?.data);
    };
    getPatient();
  }, [videoconferenceRef?.patient]);

  // Opcion de consultorio seleccionada
  const handleSelectOption = (event) => {
    getReferenceTimeDoctor(doctorReference?._id, event.target.value);
    const tempConsultory = consultories.filter(
      (c) => c._id === event.target.value
    );

    if (tempConsultory.length === 1) {
      setSelectConsultory(tempConsultory[0]);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === 'date') {
      disableLoadedStatusTime();
      const tempDays = [];
      let temp = moment(e.target.value);

      // let temp = moment(new Date(pivot.clone().add(pivot.day() * -1, "day")));
      tempDays.push(temp);

      for (let i = 0; i < 6; i++) {
        temp = moment(new Date(temp.clone().add(1, 'day')));
        tempDays.push(temp);
      }

      setWeekDays(tempDays);
      getStatusTimeDoctor(tempDays, doctorReference._id);
    }
  };

  const handleSubmit = () => {
    const toDay = new Date();
    const temp = moment(tempDate, 'YYYY-MM-DD');
    const day = temp.format('D');
    const month = temp.format('M');
    const year = temp.format('YYYY');
    const selectedDate = new Date(
      year,
      parseInt(month, 10) - 1,
      day,
      parseInt(hour, 10)
    );

    if (selectedDate.getTime() <= toDay.getTime()) {
      createAlert(
        '¡Error!',
        'La fecha seleccionada ya no se encuentra disponible.',
        'error'
      );
      setConfirm(false);
    } else {
      if (videoconferenceRef === null) {
        const doc = {
          reason: reason,
          hour: hour,
          date: tempDate,
          doctorId: doctorReference._id,
          consultoryId: selectConsultory?._id,
          consultoryName: selectConsultory?.name,
          consultationType: consultationType,
          firstName: firstName,
          lastName: lastName,
          patientId: user._id,
          bySchedule: true,
        };
        addSchedule(doc, 'patient');
      } else {
        const doc = {
          reason: reason,
          hour: hour,
          date: tempDate,
          patientId: videoconferenceRef.patient,
          consultoryId: selectConsultory?._id,
          consultoryName: selectConsultory?.name,
          consultationType: consultationType,
          firstName: firstName,
          lastName: lastName,
          idVideo: videoconferenceRef._id,
          bySchedule: true,
        };
        addSchedule(doc, 'doctor');
      }

      setFormData({
        reason: '',
        date: '',
        hour: '',
      });
      setTempDate('');
      setConfirm(false);
      closeModalAddScheduling();
      deleteReference();
      deleteReferenceTimeDoctor();
      resetConsultory();
      deleteAllConsultoryDoctors();
      deleteConsultoriesReference();
    }
  };

  const handleConfirm = (selectedHour, day) => {
    const temp = weekDays.findIndex(
      (item) => item.day().toString() === day.toString()
    );
    const tempDateWithFormat = weekDays[temp].format('YYYY-MM-DD');

    setTempDate(tempDateWithFormat);

    if (reason === '' || date === '') {
      createAlert('¡Error!', 'Rellene todos los campos.', 'error');
    } else {
      setFormData({ ...formData, hour: selectedHour });
      setConfirm(true);
    }
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleClose = () => {
    deleteReference();
    deleteReferenceTimeDoctor();
    closeModalAddScheduling();
    resetOnline();
    resetPresential();
    deleteConsultoriesReference();
    resetConsultory();
    deleteAllConsultoryDoctors();
    deleteDoctorReference();
    handleDeleteAppointmentReference();
    clearAllAppointment();
  };

  return (
    <div>
      <Modal show={modalAddScheduling} onHide={handleClose} centered scrollable>
        <Modal.Header className="border-bottom-0 mt-2 mx-auto">
          <Modal.Title>
            <h5 className="modal-title text-primary font-weight-bold">
              Agendar una Cita
            </h5>
          </Modal.Title>
          <CgClose
            size={24}
            onClick={handleClose}
            style={{
              marginRight: 5,
              marginTop: 3,
              right: 15,
              cursor: 'pointer',
              position: 'absolute',
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <div style={{ margin: 20 }}>
            {!selectedConsultory ? (
              <div className="form-group">
                <label className="font-weight-light">
                  Seleccione un consultorio
                </label>
                <select
                  className="form-control form-control-user"
                  style={{ margin: 0 }}
                  id="exampleFormControlSelect1"
                  placeholder="consultorio"
                  onChange={handleSelectOption}
                >
                  <option value="" selected disabled hidden>
                    Seleccione un consultorio
                  </option>
                  {consultories.map((item) => (
                    <option key={item._id} value={item._id} item={item}>
                      {`${item.name}, ${item.direction}`}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <label className="font-weight-light">
                {`Consultorio: ${selectedConsultory.name}`}
              </label>
            )}

            <form>
              {selectConsultory && (
                <>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label
                        className="font-weight-light"
                        style={{ textAlign: 'left', width: '100%' }}
                      >
                        ¿Es una cita de seguimiento?
                      </label>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <label className="text-secondarymdv font-weight-light">
                            Si
                          </label>
                          <input
                            type="checkbox"
                            name="followUp"
                            style={{
                              width: 'auto',
                              marginLeft: 10,
                              fontSize: 20,
                              transform: 'scale(1.3)',
                            }}
                            onClick={getAllUserAppointment}
                            checked={isChecked}
                            onChange={handleIsChecked}
                          />
                        </div>
                        <div style={{ marginLeft: 20 }}>
                          <label className="text-secondarymdv font-weight-light">
                            No
                          </label>
                          <input
                            type="checkbox"
                            name="followUp"
                            style={{
                              width: 'auto',
                              marginLeft: 10,
                              fontSize: 20,
                              transform: 'scale(1.3)',
                            }}
                            onClick={handleDeleteAppointmentReference}
                            checked={!isChecked}
                            onChange={handleIsChecked}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                      {isChecked ? (
                        appointments.length > 0 ? (
                          <select
                            className="form-control form-control-user"
                            id="exampleFormControlSelect1"
                            style={{
                              margin: 0,
                              width: '100%',
                            }}
                            onChange={handleChangeSelectedAppointment}
                          >
                            <option value="" selected disabled hidden>
                              Seleccione una cita
                            </option>
                            {appointments.map((item) => (
                              <option key={item._id} value={item._id}>
                                {`${moment(item.createdAt).format('llll')} ${
                                  item.followRef ? ' - seguimiento' : ''
                                } - Dr. ${
                                  item.doctor.firstName.split(' ')[0]
                                } ${
                                  item.doctor.lastName.split(' ')[0]
                                } - ${handleGetType(item.type)}`}
                              </option>
                            ))}
                          </select>
                        ) : (
                          'Aún no posee ninguna cita previa'
                        )
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="font-weight-light"
                      style={{ textAlign: 'left', width: '100%' }}
                    >
                      Tipo de cita
                    </label>
                    <select
                      className="form-control form-control-user"
                      style={{ margin: 0, width: '80%' }}
                      name="consultationType"
                      value={consultationType}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" selected disabled hidden>
                        Seleccione tipo cita
                      </option>

                      <>
                        <option value="presential">Presencial</option>
                        <option value="call">Cita llamada telefónica</option>
                        <option value="whatsapp">
                          Llamada mediante whatsapp
                        </option>
                        <option value="lab">Servicio de laboratorio</option>
                      </>
                    </select>
                  </div>
                </>
              )}

              <div className="form-group">
                <textarea
                  className="form-control textarea-properties textarea"
                  placeholder="Motivo de la cita*"
                  name="reason"
                  value={reason}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group">
                <span style={{ display: 'flex' }} className="font-weight-light">
                  Fecha de cita
                </span>
                <input
                  type="date"
                  className="form-control center"
                  placeholder="Fecha de cita"
                  value={date}
                  onChange={(e) => handleChange(e)}
                  name="date"
                  min={moment(new Date()).format('YYYY-MM-DD')}
                />
              </div>
              <div className="font-weight-light">Horario disponible</div>
              <div
                className="section-scrollable mt-4"
                style={{ position: 'relative' }}
              >
                {weekDays.length !== 0 ? (
                  <>
                    {!loaded ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <table className="table table-sm table-borderless">
                        <thead>
                          <tr className="text-primary text-center">
                            {weekDays.map((day, index) => (
                              <th scope="col" key={index}>
                                {dias.map((dia) => {
                                  if (dia.number === day.day()) {
                                    return dia.abbr;
                                  }
                                  return null;
                                })}
                                <br />
                                <small className="text-black-50">
                                  {day.date()}
                                  {meses.map((mes) => {
                                    if (mes.number === day.month()) {
                                      return mes.abbr;
                                    }
                                    return null;
                                  })}
                                </small>
                              </th>
                            ))}
                          </tr>
                        </thead>

                        {/* MAPEADO DE LOS HORARIOS DEL DOCTOR */}
                        <tbody className="text-center text-black-50">
                          <tr>
                            {weekDays.map((week) => (
                              <td key={week.day()}>
                                {horarios.map(
                                  (horario, index) =>
                                    parseInt(week.day(), 10) + 1 ===
                                      parseInt(horario.day, 10) && (
                                      <tr key={index}>
                                        <td scope="row">
                                          <button
                                            type="button"
                                            className={`btn btn-light btn-sm text-secondarymdv font-weight-bold bg-blue-light button-disabled-hour
                                            ${
                                              timeStatusDoctor.some(
                                                (item) =>
                                                  item.hour.toString() ===
                                                    horario.hour &&
                                                  item.day.toString() ===
                                                    week.day().toString()
                                              ) && 'disable-button-hour'
                                            }`}
                                            disabled={timeStatusDoctor.some(
                                              (item) =>
                                                item.hour.toString() ===
                                                  horario.hour &&
                                                item.day.toString() ===
                                                  week.day().toString()
                                            )}
                                            onClick={() =>
                                              handleConfirm(
                                                horario.hour,
                                                week.day().toString()
                                              )
                                            }
                                          >
                                            {horario.hour}
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                )}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </>
                ) : (
                  <div className="font-weight-light">
                    seleccione un consultorio para poder visualizar los horarios
                    disponibles del Doctor
                  </div>
                )}
              </div>
            </form>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <div className="buttons-container-scheduling">
            <Button
              type="button"
              variant="link"
              className="btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-sm"
              type="button"
              onClick={(_e) => confirmShow()}
            >
              Agendar
            </Button>
          </div>
        </Modal.Footer> */}
      </Modal>

      <Modal show={confirm} onHide={handleConfirmClose} centered size="sm">
        <Modal.Header className="border-bottom-0 mx-auto">
          <h5 className="text-center text-primary pt-3 pb-0 font-weight-bold">
            {`Estimado(a) ${firstName.split(' ', 1)} ${lastName.split(' ', 1)}`}
          </h5>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <h6 className="text-center text-black-50 pt-0 pb-0">
            {user.role !== 'doctor'
              ? `¿Deseas confirmar tu cita con ${
                  doctorReference?.gender === 'male' ? 'el Dr.' : 'la Dra. '
                } ${doctorReference?.firstName.split(
                  ' ',
                  1
                )} ${doctorReference?.lastName.split(' ', 1)} `
              : `¿Deseas confirmar tu cita con ${
                  patient?.gender === 'male' ? 'el paciente ' : 'la paciente '
                } ${patient?.firstName.split(' ', 1)} ${patient?.lastName.split(
                  ' ',
                  1
                )} `}
            <b className="text-secondarymdv">
              el {moment(tempDate).format('DD/MM/YYYY')} a las {hour}
            </b>
            ?
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              type="button"
              variant="link"
              className="btn btn-link btn-sm mr-3 text-secondarymdv font-weight-bold"
              onClick={handleConfirmClose}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-sm"
              type="button"
              onClick={handleSubmit}
            >
              Confirmar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => ({
  selectedItem: state.accounts.selectedItem,
  modalAddScheduling: state.ui.schedulingModal.open,
  user: state.auth.user,
  doctorReference: state.reference.doctor,
  doctorTimetable: state.reference.timeTable,
  loaded: state.statusTime.loaded,
  timeStatusDoctor: state.statusTime.items,
  consultories: state.reference.consultories,
  selectedConsultory: state.consultories.selectedConsultory,
  videoconferenceRef: state.videoconference.videoconference,
});
export default connect(mapStateToProps, {
  addSchedule,
  closeModalAddScheduling,
  deleteReference,
  deleteReferenceTimeDoctor,
  resetOnline,
  getStatusTimeDoctor,
  resetPresential,
  disableLoadedStatusTime,
  deleteConsultoriesReference,
  getReferenceTimeDoctor,
  resetConsultory,
  deleteAllConsultoryDoctors,
  deleteDoctorReference,
  clearAllAppointment,
})(AddConsultingModal);
