import React, { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import Jspdf from 'jspdf';
import Logo from '../../assets/img/KCL.png';
import { useSelector } from 'react-redux';
import { changeStatusDoctor } from '../../websokect/doctorWebsockets';

function Presale({
  patient,
  doctor,
  nurse,
  hour,
  products,
  services,
  removeLastProduct,
  removeLastService,
  registerPresale,
}) {
  const [aplicar, setAplicar] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    return () => {
      changeStatusDoctor({
        doctorId: user?._id,
        role: 'doctor',
        status: 'available',
      });
    };
  }, []);

  const imprimirReceta = (arrayProducto, nombre, apellido) => {
    const paciente = 'Receta de pre-venta de ' + nombre + ' ' + apellido;
    const doc = new Jspdf('landscape', 'px', 'a4', 'false');
    doc.addImage(Logo, 'PNG', 250, 10, 180, 100);
    let informacion = '';
    doc.setFont('Helvertica', 'bold');
    doc.text(60, 140, 'Receta de preventa para ' + nombre + ' ' + apellido);
    arrayProducto.forEach((item) => {
      informacion += 'Producto: ' + item.name + '\n';
      informacion += 'Cantidad: ' + item.amount + '\n';
      informacion += 'Aplicar: ' + item.aplicado + '\n\n';
    });
    doc.setFont('Helvertica', 'normal');
    doc.text(60, 180, informacion);
    doc.save(paciente + '.pdf');
  };
  return (
    <>
      {/* Web View */}
      <div className='d-none d-sm-block'>
        <h2 className='text-primary mb-4 mt-4'>
          Productos y servicios de <b className='text-secondarymdv'>Preventa</b>
        </h2>
      </div>
      {/* Fin Web View */}
      {/* Mobile View */}
      <div className='d-block d-sm-none'>
        <h5 className='text-primary mt-4 font-weight-bold text-center'>
          Productos y servicios de
          <b className='text-secondarymdv'>Preventa</b>
        </h5>
      </div>
      {/* Fin Mobile View */}
      <div className='sales report mb-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-12 col-sm-12 col-xs-12'>
              <h4 className='text-secondarymdv font-weight-light text-center'>
                Detalle de consulta
              </h4>
              <ul className='list-group list-group-horizontal-sm mb-4 mt-3 justify-content-center text-center'>
                <li className='list-group-item list-group-item-light'>
                  <b className='text-muted'>Paciente:</b>{' '}
                  {`${patient?.publicData.firstName} ${patient?.publicData.lastName}`}
                </li>
                <li className='list-group-item list-group-item-light'>
                  <b className='text-muted'>Enfermera:</b>{' '}
                  {nurse
                    ? `${nurse.publicData.firstName} ${nurse.publicData.lastName}`
                    : 'No posee'}
                </li>
              </ul>
              <ul className='list-group list-group-horizontal-sm mb-4 mt-3 justify-content-center text-center'>
                <li className='list-group-item list-group-item-light'>
                  {' '}
                  <b className='text-muted'>Médico:</b>{' '}
                  {`${doctor?.publicData.firstName} ${doctor?.publicData.lastName}`}
                </li>
                <li className='list-group-item list-group-item-light'>
                  {' '}
                  <b className='text-muted'>Hora:</b> {hour}
                </li>
                <li className='list-group-item list-group-item-light'>
                  {' '}
                  <b className='text-muted'>Fecha:</b>{' '}
                  {moment(new Date()).format('DD/MM/YYYY')}
                </li>
              </ul>
            </div>
            {/* servicios */}
            {/* <div className="col-md-6 col-sm-12 col-xs-12 border-right">
              <h4 className="text-secondarymdv font-weight-light">
                Receta de Servicios
              </h4>
              <div className="hr" />
              <ul className="list-group list-group-flush text-center">
                {
                  services.length === 0 ? (
                    <li className="text-center list-group-item p-1">
                      No posee
                    </li>
                  ) : services.map(item => (
                    <li key={item._id} className="list-group-item p-1">
                      {`1 ${item.name}`}
                    </li>
                  ))
                }
              </ul>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-danger btn-sm mr-1"
                  style={{
                    borderRadius: '0.8rem',
                  }}
                  onClick={removeLastService}
                >
                  Eliminar
                </button>
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseServices"
                  aria-expanded="false"
                  aria-controls="collapseServices"
                  style={{
                    borderRadius: '0.8rem',
                  }}
                  className="btn btn-info btn-sm"
                >
                  Añadir
                </button>
              </div>
            </div> */}
            {/* productos */}
            <div className='col-12 col-md-12 col-sm-12 col-xs-12 gutter'>
              <h4 className='text-secondarymdv font-weight-light text-center'>
                Receta de Productos
              </h4>
              <div className='hr' />
              <ul className='list-group list-group-flush mt-2'>
                {products.length === 0 ? (
                  <li className='text-center list-group-item p-1'>No posee</li>
                ) : (
                  products.map((item) => (
                    <li key={item._id} className='list-group-item p-1'>
                      <span className='badge bg-primarymdv rounded-pill mr-2'>
                        {`${item.amount}`}
                      </span>{' '}
                      <span className='badge bg-primarymdv rounded-pill mr-2'>
                        {`${item.name}`}
                      </span>{' '}
                      <span
                        className={
                          item.aplicado === 'Aplicado al paciente'
                            ? 'badge bg-success rounded-pill mr-2'
                            : 'badge bg-warning rounded-pill mr-2'
                        }
                        style={{
                          color: 'black',
                        }}
                      >
                        {`${item.aplicado}`}
                      </span>{' '}
                    </li>
                  ))
                )}
              </ul>
              <div className='hr' />
              <div className='d-flex'>
                <button
                  type='button'
                  className='btn btn-danger btn-sm mr-1'
                  style={{
                    borderRadius: '0.8rem',
                  }}
                  onClick={removeLastProduct}
                >
                  Eliminar
                </button>
                <button
                  type='button'
                  data-toggle='collapse'
                  data-target='#collapseProduct'
                  aria-expanded='false'
                  aria-controls='collapseProduct'
                  style={{
                    borderRadius: '0.8rem',
                  }}
                  className='btn btn-info btn-sm mr-1'
                >
                  Añadir
                </button>
                <button
                  type='button'
                  onClick={() => {
                    imprimirReceta(
                      products,
                      patient?.publicData.firstName,
                      patient?.publicData.lastName
                    );
                  }}
                  style={{
                    borderRadius: '0.8rem',
                  }}
                  className='btn btn-secondary btn-sm'
                >
                  Imprimir receta
                </button>
              </div>
              <div className='hr' />
            </div>
          </div>
          <div className='float-right'>
            <div className='d-flex'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={registerPresale}
              >
                Registrar Preventa
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Presale;
