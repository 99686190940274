/* eslint-disable no-console */
/* eslint-disable arrow-parens */
import axios from "axios";
import {
  ADD_SURGERIES,
  ERROR_SURGERIES,
  GET_SURGERIES,
  UPDATE_SURGERIES,
  DELETE_SURGERIES,
  ADD_ITEM_SURGERIES,
  DELETE_ITEM_SURGERIES,
} from "../types";
import createAlert from "./alert.action";

// Surgeries
export const getSurgeries = (patient) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/patient/history/surgeries/${patient}`);
    dispatch({
      type: GET_SURGERIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addSurgeries = (formData, patientID) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const doc = JSON.stringify({
    formData: formData,
    patientID: patientID,
  });

  try {
    const res = await axios.post("/api/patient/history/surgeries", doc, config);

    dispatch({
      type: ADD_SURGERIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SURGERIES,
    });
  }
};

export const deleteSurgeries = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/patient/history/surgeries/${id}`);
    dispatch({
      type: DELETE_SURGERIES,
      payload: id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteItemSurgeries = () => async (dispatch) => {
  dispatch({
    type: DELETE_ITEM_SURGERIES,
    payload: null,
  });
};

export const addItemSurgeries = (item) => async (dispatch) => {
  dispatch({
    type: ADD_ITEM_SURGERIES,
    payload: item,
  });
};

export const updateSurgeries = (id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      `/api/patient/history/surgeries/${id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_SURGERIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
