import React from 'react';
import { Button } from 'react-bootstrap';
import useFarinterSelector from '../../hooks/useFarinterSelector';
import usePagiantion from './state/usePagiantion';
import './styles.css';

const Pagination = () => {
  const { pagination } = useFarinterSelector();

  const { handleBackPage, handleChangePage, handleNextPage } = usePagiantion();
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <Button
          className="buttons-pagination"
          disabled={pagination.actualPage === 1}
          onClick={handleBackPage}
        >
          Antes
        </Button>
        <div>
          <Button
            className={`buttons-pagination ${
              pagination.actualPage === pagination.prev &&
              'actual-page-pagination'
            }`}
          >
            {pagination.prev}
          </Button>
          <Button
            className={`buttons-pagination ${
              pagination.actualPage === pagination.center &&
              'actual-page-pagination'
            }`}
          >
            {pagination.center}
          </Button>
          <Button
            className={`buttons-pagination ${
              pagination.actualPage === pagination.next &&
              'actual-page-pagination'
            }`}
          >
            {pagination.next}
          </Button>
        </div>
        <Button className="buttons-pagination" onClick={handleNextPage}>
          Siguiente
        </Button>
      </ul>
    </nav>
  );
};

export default Pagination;
