export default [
  {
    label: 'Hij@',
    value: 'son',
  },
  {
    label: 'Abuelo',
    value: 'grandpa',
  },
  {
    label: 'Abuela',
    value: 'grandma',
  },
  {
    label: 'Madre',
    value: 'mother',
  },
  {
    label: 'Padre',
    value: 'father',
  },
  {
    label: 'Tí@',
    value: 'uncle',
  },
  {
    label: 'Otro',
    value: 'other',
  },
];
