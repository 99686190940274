import { useSelector } from 'react-redux';

const useFarinterSelector = () => {
  const { pagination, auth, ui, presential, reference } = useSelector(
    (state) => state
  );

  return {
    auth,
    pagination,
    ui,
    presential,
    reference,
  };
};

export default useFarinterSelector;
